import React, {useState} from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  ActionWrapper,
  ModalComponent,
  CommonButton,
} from "../../../../components";
import {
  Columns, smsTemplatesService,
} from "../../../../services/index.service";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";


function ListSMS(props) {
  const EquipmentOrderData = smsTemplatesService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "SMS Templates",
    },
  ];

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.smsTemplatesTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{  EditSMSTemplatePage() },
        },
      ]),
  });

  const navigate = useNavigate();
  const EditSMSTemplatePage = () => {
    navigate("/admin/sms-templates/edit");
  }

  const [shortCodes, setShortCodes] = useState(false);
  const shortCodesShow = () => {
    setShortCodes(true);
  };
  const shortCodesHide = () => {
    setShortCodes(false);
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="SMS Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['csvExport','create']}
            onHandleShow={ () => shortCodesShow() } 
            btnText="Short Codes"
            btnIcons="icon ni ni-info-fill"
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}
        showAction={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>SMS Templates module will list all sms on this page.</li>
                <li>Administrator can edit any sms template.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={shortCodes}
        onHandleCancel={shortCodesHide}
        title="SMS Short Codes"
        extraClassName="modal-xl"
      >
        <div className="table-responsive">
        <Table bordered size="md" className="mb-0">
              <thead>
                <tr>
                  <th>Short Codes</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#CUSTOMER_NAME#</td>
                  <td>Name of the Customer</td>
                </tr>
                <tr>
                  <td>#PROVIDER_NAME#</td>
                  <td>	Name of the Provider</td>
                </tr>
                <tr>
                  <td>#PROVIDER_PHONE_NUMBER#</td>
                  <td>Phone Number of the Provider</td>
                </tr>
                <tr>
                  <td>#SERVICE_NAME#</td>
                  <td>	Name of the Service (Ex:- same day emergency, scheduled service etc)</td>
                </tr>
                <tr>
                  <td>#JOB_NO#</td>
                  <td>Job Number or Booking Number</td>
                </tr>
                <tr>
                  <td>#BOOKING_DATE#</td>
                  <td>Job DateTime or Booking DateTime</td>
                </tr>
                <tr>
                  <td>#CANCEL_REASON#</td>
                  <td>Job cancel reason of provider or customer</td>
                </tr>
                <tr>
                  <td>#STATUS#</td>
                  <td>Job status (Ex:- Approve, Decline, Cancel etc)</td>
                </tr>
                <tr>
                  <td>#CUSTOMER_NAME#</td>
                  <td>Name of the Customer</td>
                </tr>
                <tr>
                  <td>#TOTAL_AMOUNT#</td>
                  <td>	Job total amount pay by customer</td>
                </tr>
                <tr>
                  <td>#PARENT_NAME#</td>
                  <td>Main Service Name (Ex:- HVAC,Plumbing,Electrician etc)</td>
                </tr>
                <tr>
                  <td>#RESET_URL#</td>
                  <td>Forgot or Reset password URL</td>
                </tr>
                <tr>
                  <td>#QUOTE_URL#</td>
                  <td>Quote Approve or Decline URL</td>
                </tr>
              </tbody>
        </Table>
        </div>
        <div className="text-end mt-3">
          <CommonButton className="btn btn-secondary" onClick = { () => shortCodesHide() }>Close</CommonButton>
        </div>
      </ModalComponent>
    </>
  );
}

export default ListSMS;
