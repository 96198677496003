import { Outlet } from 'react-router-dom';
import { AddProcedureLesson, AddQuestions, EditProcedureLesson, ListProcedureLesson } from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';
// import AddProcedureLesson from '../../views/OperatingProcedure/Create/index';

export default function route() {
  return [
    {
      path: accessRoute.OPERATING.path,
      private: true,
      name: 'Operating Procedures',
      key: "operatingprocedures",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.OPERATING.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.OPERATING.path,
          name: 'List',
          key: accessRoute.OPERATING.key,
          belongsToSidebar: false,
          icon: accessRoute.OPERATING.icon,
          element: <ListProcedureLesson />,
        },
        {
          private: true,
          path: accessRoute.OPERATING_ADD.path,
          name: 'Add',
          key: accessRoute.OPERATING_ADD.key,
          belongsToSidebar: false,
          icon: accessRoute.OPERATING_ADD.icon,
          element: <AddProcedureLesson />,
        },
        {
          private: true,
          path: accessRoute.OPERATING_EDIT.path,
          name: 'Edit',
          key: accessRoute.OPERATING_EDIT.key,
          belongsToSidebar: false,
          icon: accessRoute.OPERATING_EDIT.icon,
          element: <EditProcedureLesson />,
        },
        {
          private: true,
          path: accessRoute.ADD_QUESTIONS.path,
          name: 'Add Questions',
          key: accessRoute.ADD_QUESTIONS.key,
          belongsToSidebar: false,
          icon: accessRoute.ADD_QUESTIONS.icon,
          element: <AddQuestions />,
        },
        // {
        //   private: true,
        //   path: accessRoute.CUSTOMERS_ADD.path,
        //   name: 'List User',
        //   key: accessRoute.CUSTOMERS_ADD.path,
        //   belongsToSidebar: false,
        //   icon: accessRoute.CUSTOMERS_ADD.icon,
        //   element: <AddUser />,
        // },
        // {
        //   private: true,
        //   path: accessRoute.CUSTOMERS_EDIT.path,
        //   name: 'List User',
        //   key: accessRoute.CUSTOMERS_EDIT.key,
        //   belongsToSidebar: false,
        //   icon: accessRoute.CUSTOMERS_EDIT.icon,
        //   element: <EditUser />,
        // },
        // {
        //   private: true,
        //   path: accessRoute.CUSTOMERS_VIEW.path,
        //   name: 'List User',
        //   key: accessRoute.CUSTOMERS_VIEW.key,
        //   belongsToSidebar: false,
        //   icon: accessRoute.CUSTOMERS_VIEW.icon,
        //   element: <ViewUser />,
        // },
      ],
    },
  ];
}

export const accessRoute = {
  OPERATING: {
    path: '/admin/standard-operating-procedures',
    action: getActions('LIST'),
    key: 'operatingprocedures.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-list-fill" />
      </span>
    ),
  },
  // CUSTOMERS_EDIT: {
  //   path: '/admin/customers/:id',
  //   action: getActions('EDIT'),
  //   key: 'customers.list',
  //   icon: (
  //     <span className="nk-menu-icon">
  //       <em className="icon ni ni-grid-alt" />
  //     </span>
  //   ),
  // },
  // CUSTOMERS_VIEW: {
  //   path: '/admin/customers/edit/:id',
  //   action: getActions('VIEW'),
  //   key: 'customers.list',
  //   icon: (
  //     <span className="nk-menu-icon">
  //       <em className="icon ni ni-grid-alt" />
  //     </span>
  //   ),
  // },
  OPERATING_ADD: {
    path: '/admin/standard-operating-procedures/add',
    action: getActions('CREATE'),
    key: 'operatingprocedures.list',
  },
  OPERATING_EDIT: {
    path: '/admin/standard-operating-procedures/edit',
    action: getActions('EDIT'),
    key: 'operatingprocedures.list',
  },
  ADD_QUESTIONS: {
    path: '/admin/standard-operating-procedures/add-question',
    action: getActions('ADD QUESTIONS'),
    key: 'operatingprocedures.list',
  },
};
export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
