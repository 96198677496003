
import { InputFilter, SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});
const getStatusData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
};

export  const cityTableColumn = ({ Action }) => {
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "city",
      title: "City",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("city"),
    },
    {
      dataIndex: "state",
      title: "State",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("state"),
    },
    {
      dataIndex: "country",
      title: "Country",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("country"),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render:()=><Toggle isDropDown/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
};