import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ImageElement,
  CommonButton,
  SweetAlert,
  FormElementWrapper,
  TextInput,
 
} from '../../../../components';
import { modalNotification } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
function ReviewList(props) {
  const onConfirmAlert = () => {
    modalNotification({ type: 'success', message: 'reply Deleted Successfully' });
    return true;
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);
   
  const [editReply, setEditreply] = useState(false);
  const handleEditReplyChange = () => {
      setEditreply(true)
  };
  const handlePostReplyChange = () => {
      setEditreply(false)
  };

      const breadcrumb = [
        {
          path: '/admin/dashboard',
          name: 'Dashboard',
        },
        {
          path: '/admin/google-gmb',
          name: 'Google My Business',
        },
        {
          path: '#',
          name: 'Google Review List',
        },
      ];
      const navigate = useNavigate();
      const backPage = () =>{
        navigate('/admin/google-gmb')
      } 
      
      return (        
      <div className="wide-md mx-auto reviewSec">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Google Review List">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton']}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
          <div className='nk-block'>
              <div className='card'>
                  <div className='card-inner'>
                    {/* Review Section Head */}
                      <div className="reviewSec_head  text-center">
                          <ImageElement
                            source="customer-review.svg"
                            className="img-fluid mb-2"
                            alt="customer-review"
                          />
                              <h6 className='mb-1'>Show customers you care, one response at a time</h6>
                              <p>Your customers now get notified when you reply to their review</p>
                    </div>
                      {/* Review Section Body */}
                      <div className="reviewSec_body card border">

                        {/* Review Section Item */}
                        <div className="reviewSec_item">
                          <div className="d-flex reviewSec_item_inner position-relative">
                            <div className="reviewSec_item_img">
                            <div className="span rounded-circle  bg-primary">                              L
                              </div>
                            </div>
                            <div>
                              <h6 className="mb-0">Louisa Johnson</h6>
                              <div className="reviewSec_item_rating">
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <span className="date ms-1">Aug 27, 2022</span>
                              </div>
                              <p>AC unit mid afternoon stopped working. I had reached out to 10 other businesses for same day. None of them could help me or I could afford the price. Mike immediately answered and also came out as soon as he could after an already long work day! He had my unit fixed in less than 5 minutes. Told me what to expect and things that are changing upcoming in the business for AC. Fast and easy pay option. He will be my go-to for all AC/Heating from now on!</p>
                            </div>
                          </div>
                          <div className="d-flex reviewSec_item_inner position-relative">
                          <div className="reviewSec_item_img">
                              <ImageElement
                                source="small-logo.svg"
                                className="img-fluid rounded-circle w-100 h-100"
                                alt="gghs"
                              />
                          </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Tékhnē Heating & Air Conditioning</h6>
                              <div className="reviewSec_item_rating">
                                <span className="date">Aug 27, 2022</span>
                              </div>
                              <p>Thank you for sharing! Glad we could help quickly and effectively!
                                </p>
                                {editReply &&
                                 <>
                                  <FormElementWrapper className="mb-3"
                                    label="Your Reply"
                                    mandatory="true"
                                    element={(formik) => (
                                      <TextInput
                                        name="monthlyprice"
                                        placeholder="Enter your reply"
                                        value="Thank you for sharing! Glad we could help quickly and effectively!"
                                        formik={formik}
                                      />
                                    )}
                                  />
                                  <CommonButton className="btn btn-primary" onClick={() => handlePostReplyChange()}>Post Reply </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={handlePostReplyChange}>Cancel</CommonButton> 
                                  </>
                                }
                                {!editReply &&
                                  <>
                                  <CommonButton className="btn btn-primary" onClick={() => handleEditReplyChange()}>Edit </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={() => setIsAlertVisible(true)} >Delete</CommonButton> 
                                  </> 
                                }                        
                            </div>
                          </div>
                        </div>
                        <div className="reviewSec_item">
                          <div className="d-flex reviewSec_item_inner position-relative">
                            <div className="reviewSec_item_img">
                            <div className="span rounded-circle">           
                                               A
                              </div>
                            </div>
                            <div>
                              <h6 className="mb-0">Anise Stead</h6>
                              <div className="reviewSec_item_rating">
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <span className="date ms-1">Aug 27, 2022</span>
                              </div>
                              <p>AC unit mid afternoon stopped working. I had reached out to 10 other businesses for same day. None of them could help me or I could afford the price. Mike immediately answered and also came out as soon as he could after an already long work day! He had my unit fixed in less than 5 minutes. Told me what to expect and things that are changing upcoming in the business for AC. Fast and easy pay option. He will be my go-to for all AC/Heating from now on!</p>
                            </div>
                          </div>
                          <div className="d-flex reviewSec_item_inner position-relative">
                          <div className="reviewSec_item_img">
                              <ImageElement
                                source="small-logo.svg"
                                className="img-fluid rounded-circle w-100 h-100"
                                alt="gghs"
                              />
                          </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Tékhnē Heating & Air Conditioning</h6>
                              <div className="reviewSec_item_rating">
                                <span className="date">Aug 27, 2022</span>
                              </div>
                              <p>Thank you for sharing! Glad we could help quickly and effectively!
                                </p>
                                {editReply &&
                                 <>
                                  <FormElementWrapper className="mb-3"
                                    label="Your Reply"
                                    mandatory="true"
                                    element={(formik) => (
                                      <TextInput
                                        name="monthlyprice"
                                        placeholder="Enter your reply"
                                        value="Thank you for sharing! Glad we could help quickly and effectively!"
                                        formik={formik}
                                      />
                                    )}
                                  />
                                  <CommonButton className="btn btn-primary" onClick={() => handlePostReplyChange()}>Post Reply </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={handlePostReplyChange}>Cancel</CommonButton> 
                                  </>
                                }
                                {!editReply &&
                                  <>
                                  <CommonButton className="btn btn-primary" onClick={() => handleEditReplyChange()}>Edit </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={() => setIsAlertVisible(true)} >Delete</CommonButton> 
                                  </> 
                                }                    
                            </div>
                          </div>
                        </div> <div className="reviewSec_item">
                          <div className="d-flex reviewSec_item_inner position-relative">
                            <div className="reviewSec_item_img">
                              <ImageElement
                                source="uploadImg.png"
                                className="img-fluid rounded-circle w-100 h-100"
                                alt="gghs"
                              />
                          </div>
                            <div>
                              <h6 className="mb-0">Paurav Lokesh</h6>
                              <div className="reviewSec_item_rating">
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <em className="ni ni-star-fill text-primary"></em>
                                <span className="date ms-1">Aug 27, 2022</span>
                              </div>
                              <p>AC unit mid afternoon stopped working. I had reached out to 10 other businesses for same day. None of them could help me or I could afford the price. Mike immediately answered and also came out as soon as he could after an already long work day! He had my unit fixed in less than 5 minutes. Told me what to expect and things that are changing upcoming in the business for AC. Fast and easy pay option. He will be my go-to for all AC/Heating from now on!</p>
                            </div>
                          </div>
                          <div className="d-flex reviewSec_item_inner position-relative">
                          <div className="reviewSec_item_img">
                              <ImageElement
                                source="small-logo.svg"
                                className="img-fluid rounded-circle w-100 h-100"
                                alt="gghs"
                              />
                          </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Tékhnē Heating & Air Conditioning</h6>
                              <div className="reviewSec_item_rating">
                                <span className="date">Aug 27, 2022</span>
                              </div>
                              <p>Thank you for sharing! Glad we could help quickly and effectively!
                                </p>
                                {editReply &&
                                 <>
                                  <FormElementWrapper className="mb-3"
                                    label="Your Reply"
                                    mandatory="true"
                                    element={(formik) => (
                                      <TextInput
                                        name="monthlyprice"
                                        placeholder="Enter your reply"
                                        value="Thank you for sharing! Glad we could help quickly and effectively!"
                                        formik={formik}
                                      />
                                    )}
                                  />
                                  <CommonButton className="btn btn-primary" onClick={() => handlePostReplyChange()}>Post Reply </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={handlePostReplyChange}>Cancel</CommonButton> 
                                  </>
                                }
                                {!editReply &&
                                  <>
                                  <CommonButton className="btn btn-primary" onClick={() => handleEditReplyChange()}>Edit </CommonButton>
                                  <CommonButton className="btn btn-light ms-2" onClick={() => setIsAlertVisible(true)} >Delete</CommonButton> 
                                  </> 
                                }                         
                            </div>
                          </div>
                        </div>
                      </div>
                      <CommonButton className="btn btn-primary float-end mt-3">Next </CommonButton>
                  </div>
              </div>
          </div>
          <SweetAlert
            title="Are you sure"
            text="you want to delete this reply?"
            show={isAlertVisible}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={setIsAlertVisible}
            // showLoaderOnConfirm
            // loading={loading}
            onConfirmAlert={onConfirmAlert}
          />
        </div>
        
      );
}

export default ReviewList;
