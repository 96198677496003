import { Dashboard } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'OneView Dashboard',
      key: accessRoute.DASHBOARD.path,
      belongsToSidebar: true,
      icon: accessRoute.DASHBOARD.icon,
      element: <Dashboard />,
    },
  ];
}

export const accessRoute = {
  DASHBOARD: {
    path: '/admin/dashboard',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
