const TextFormatter = (heading, para) => {
    return (
      <>
        <div className="postDescription">
            <b>{heading} </b> {para}
        </div>
      </>
    );
  };

export const RequestsTableColumn = () => {
  return [
    {
      dataIndex: "id",
      title: "Id",
      headerClasses: "w_70",
    },
    {
      dataIndex: "userName",
      title: "UserName",
      sorter: true,
    },
    {
      dataIndex: "phoneNumber",
      title: "Phone Number",
      sorter: true,
    },
    {
        dataIndex: "serviceTypeHeading",
        dataIndex1: "serviceTypePara",
        title: "Service Type",
        sorter: true,
        render: (cell, row) => TextFormatter(row?.serviceTypeHeading, row?.serviceTypePara),
      },
      {
        dataIndex: "location",
        title: "Location",
        sorter: true,
      },
      {
        dataIndex: "providerName",
        title: "Provider Name",
        sorter: true,
      },
      {
        dataIndex: "providerPhoneNumber",
        title: "Phone Number",
        sorter: true,
      },
      
      {
        dataIndex: "dateTime",
        title: "DateTime",
        sorter: true,
      },
      {
        dataIndex: "requestType",
        title: "Request Type",
        sorter: true,
      },
  ];
};
