// import './App.css';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes/index.routes';
import 'antd/dist/antd.min.css';
import './helpers/index.helper';

function RouteLayout({ path }) {
  const element = useRoutes(path);
  return element;
}

function App() {
  return (
    <div className="App">
      <Suspense fallback="">
        <RouteLayout path={routes()} />
      </Suspense>
    </div>
  );
}

export default App;
