import { Outlet } from 'react-router-dom';
import { ImagesInventory, ListInventory } from '../../views/HomeInventory';



export default function route() {
  return [
    {
      path: accessRoute.HOME_INVENTORY.path,
      private: true,
      name: 'Home Inventory',
      key: "homeInventory",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.HOME_INVENTORY.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.HOME_INVENTORY.path,
        name: 'Truck Stock Ordering',
        key: accessRoute.HOME_INVENTORY.key,
        belongsToSidebar: false,
        icon: accessRoute.HOME_INVENTORY.icon,
        element: <ListInventory />,
      },
      {
        private: true,
        path: accessRoute.IMAGES_INVENTORY.path,
        name: 'View Images',
        key: accessRoute.IMAGES_INVENTORY.key,
        belongsToSidebar: false,
        icon: accessRoute.IMAGES_INVENTORY.icon,
        element: <ImagesInventory/>,
      },
    ]
    },
  ];
}

export const accessRoute = {
  HOME_INVENTORY: {
    path: '/admin/home-inventory',
    key:"homeInventory.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-home-fill" />
      </span>
    ),
  },
  IMAGES_INVENTORY: {
    path: '/admin/home-inventory/view-images',
    key:"imagesInventory.images",
      },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
