import React, { useState } from "react";
import {
  ModalComponent,
  InputType,
  AntSelect,
  SweetAlert,
  ActionWrapper,
  Breadcrumb,
  ListingHeader,
  PageHeader,
  TablePlus,
  Toggle
} from '../../../../../../components';
import {
  Columns,
  getCityService,
} from '../../../../../../services/index.service';
import { Link } from 'react-router-dom';
import { modalNotification } from "../../../../../../utils";

function ListCity(props) {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "City Deleted Successfully", })
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "City",
    },
  ];

  const columns = Columns.cityTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler:editcityActionFilterShow
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          type:"redirect",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
  });

  const customerData = getCityService();
  const [cityActionFilter, setCityActionFilter] = useState(false);

  const cityActionFilterShow = () => {
    setCityActionFilter(true);
  };
  const cityActionFilterHide = () => {
    setCityActionFilter(false);
  };
  const [editcityActionFilter, seteditCityActionFilter] = useState(false);

  const editcityActionFilterShow = () => {
    seteditCityActionFilter(true);
  };
  const editcityActionFilterHide = () => {
    seteditCityActionFilter(false);
  };
  const countryData = [
    {
      id: "1",
      name: "India",
    },
    {
      id: "2",
      name: "United State",
    },
  ];
  const stateData = [
    {
      id: "1",
      name: "MP",
    },
    {
      id: "2",
      name: "UP",
    },
  ];
  const statusData = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Inactive",
    },
  ];

  const statusActionOption = [
    {
      id: 1,
        name: 'Select Action'
      },
      {
        id: 2,
        name: 'Make Active'
      },
      {
        id: 3,
        name: 'Make Inactive'
      },
      {
        id: 4,
        name: 'Make Delete'
      }
  ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="City">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ "create", "csvExport"]}
            btnText="Add City"
            onHandleShow={cityActionFilterShow}
            // popover={<LocalizationFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        showAction={false}
        isSearch={false}
        statusAction
        statusActionOption={statusActionOption}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
       <SweetAlert
        title="Are you sure"
        text="you want to delete this city"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>City module will list all countries on this page.</li>
                <li>Can Activate / Deactivate / Delete any city.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={cityActionFilter}
        onHandleCancel={cityActionFilterHide}
        title="Add City"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Country Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select country"
                  options={countryData}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select state"
                  options={stateData}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  City Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter city name"
                  name="CityName"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Status
                </label>
                {/* <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select status"
                  options={statusData}
                /> */}
                <Toggle />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => cityActionFilterHide()}
                className="btn btn-primary me-2"
              >
                Add
              </Link>
              <Link
                href="#"
                className="btn btn-light me-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => cityActionFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
            </div>
          </form>
        </ModalComponent>
        <ModalComponent
        backdrop
        show={editcityActionFilter}
        onHandleCancel={editcityActionFilterHide}
        title="Edit City"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Country Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select country"
                  options={countryData}
                  defaultValue="India"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select state"
                  options={stateData}
                  defaultValue="MP"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  City Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter city name"
                  name="CityName"
                  disabled={false}
                  variant="standard"
                  type="text"
                  defaultValue="Indore"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Status
                </label>
                {/* <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select status"
                  options={statusData}
                  defaultValue="Active"
                /> */}
                <Toggle />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => editcityActionFilterHide()}
                className="btn btn-primary me-1 me-sm-2"
              >
                Update
              </Link>
              <Link
                href="#"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => editcityActionFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
            </div>
          </form>
        </ModalComponent>
    </>
  );
}

export default ListCity;
