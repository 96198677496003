import {  Select } from "antd";
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea
} from "../../../../../components";

function EditServiceCategory(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/service-category')
  } 
  const newLocationPage = () =>{
    navigate('/admin/manage-location');
  }
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/service-category",
      name: "Service Category",
    },
    {
      path: "#",
      name: "Edit Service Category",
    },
  ];
  const statusData = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'atlantic',
      value: 'Atlantic',
    },
    {
      name: 'centralIndiana',
      value: 'Central Indiana',
    },
  ]
  const displayOrderData = [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
  ]
  const customerTagData = [
    {
      name: 'AirCleaner',
      value: 'Air Cleaner',
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'HomeWarranty',
      value: 'Home Warranty',
    },
  ]
  const jobTagData = [
    {
      name: 'AirPurifier',
      value: 'Air Purifier',
    },
    {
      name: 'HeatingTune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'qwerty1',
      value: 'Qwerty1',
    },
  ]

  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Service Category">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={6}
            label="Select Location "
            actionHandler={ newLocationPage()}
            tooltip={true}
            tooltipText="Select the location in which you would like to appear this Services. For example 'car washing' services to appear for any specific city or state or may be for whole country. You can define these locations from 'Manage Locations >> Geo Fence Location' section"
            link="Enter New Location"
            mandatory={true}
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select location "
                options={locationData}
                showSearch
                formik={formik}
              />
            )}
          />
          
          <FormElementWrapper
            md={6}
            label="Category"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="category"
                placeholder="Enter category"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Category Description "
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter category description *"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Logo"
            element={(formik) => (
              <TextInput
                name="firstName"
                placeholder="Select logo"
                formik={formik}
                type="file"
              />
            )}
            imgPreviewBox={true}
            formnote= "[Note: Upload only png image size of 360px*360px.]"
          />
          <FormElementWrapper
            md={6}
            label="Display Order"
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select display order "
                options={displayOrderData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Status "
            mandatory={true}
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select status "
                options={statusData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <h6 className="title mb-0">Auto Tag</h6>
          <FormElementWrapper
            md={6}
            label="Customer Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select customer tag "
                options={customerTagData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Job Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select job tag "
                options={jobTagData}
                showSearch
                formik={formik}
              />
            )}
          />         
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </CommonButton>
                {/* <CommonButton
                  type="submit"
                  className="btn btn-light"
                >
                  Cancel
                </CommonButton> */}
              </>
            )}
          />
        </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditServiceCategory;
