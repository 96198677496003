import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Toggle,
  ActionWrapper,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TablePlus,
  InputFilter,
  SelectFilter,
} from "../../../../components";
import { Link } from "react-router-dom";
import { Radio } from "antd";
import { FilterFilled  } from "@ant-design/icons";

function RoleListing(props) {

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Roles",
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });  
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  }
  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
    id: 3,
    name: 'Make Inactive'
    }
  ]  

  const columns = [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "roles",
      title: "Roles ",
      headerClasses: "sorting",
       sorter: true,
       ...getColumnSearchProps("roles")
    },
    {
      dataIndex: "rolesassigned",
      title: "Role Assigned ",
      headerClasses: "sorting",
       sorter: true,
       ...getColumnSearchProps("roles assigned")
    },
    {
      dataIndex: "MentalHealthPermission",
      title: "Mental Health Permission",
      headerClasses: "sorting",
       sorter: true,
      render:()=><Toggle />,
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
       sorter: true,
      render:()=><Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
     
    },
  ];
  const Action = () => actionFormatter([
    {
      name: "Edit",
      icon: "icon ni ni-edit",
      handler:()=>{ editAdminModalShow(true);}
    },
    {
      name: "Assign Module",
      icon: "icon ni ni-file-text",
      path: "/admin/assign-modules"
    },
  ])
  const adminData = [
    {
      id: 1,
      roles: "	Default Plan",
      rolesassigned: "	NCA",
    },
    {
      id: 2,
      roles: "	abc company",
      rolesassigned: "		SubAdmin",
    },
    {
      id: 3,
      roles: "	Billing Admin",
      rolesassigned: "		SubAdmin",
    },
    {
      id: 4,
      roles: "	disdpatch",
      rolesassigned: "	NCA",
    },
    {
      id: 5,
      roles: "	Dispatcher Admin",
      rolesassigned: "	NCA",
    },
  ];
  const [addAdminModal, setaddAdminModal] = useState(false);
  const addAdminModalShow = () => {
    setaddAdminModal(true);
  };
  const addAdminModalHide = () => {
    setaddAdminModal(false);
  };
  const [editAdminModal, seteditAdminModal] = useState(false);
  const editAdminModalShow = () => {
    seteditAdminModal(true);
  };
  const editAdminModalHide = () => {
    seteditAdminModal(false);
  };
  const [rediovalue, setrediovalue] = useState(1);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Roles">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create", "csvExport"]}
            btnText="Add Role"
            onHandleShow={addAdminModalShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <ModalComponent
        backdrop
        show={addAdminModal}
        onHandleCancel={addAdminModalHide}
        title="Add Role"
        extraClassName="modal-lg"
      >
        <FormWrapper
          row={true}
          extraClassName="g-3"
        >
          <FormElementWrapper
            md={12}
            label="Role Name"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="roleName"
                placeholder="Enter role name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label=""
            element={(formik) => (
              <Radio.Group value={rediovalue}>
                  <Radio value={1} onClick={() => setrediovalue(1)}>SubAdmin </Radio>
                  <Radio value={2} onClick={() => setrediovalue(2)}>NCA</Radio>
              </Radio.Group>
            )}
          />
            {
              rediovalue === 1  && 
              <>
                
              </>
            }
            {
              rediovalue === 2 && 
              <>
                <FormWrapper
                  row={true}
                  extraClassName="mt-3 g-3"
                >
                  <FormElementWrapper
                    md={6}
                    label="Monthly Price"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="monthlyprice"
                        placeholder="Enter monthly price"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Yearly Price"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="yearlyprice"
                        placeholder="Enter yearly price"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Price per active service provider"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="priceService"
                        placeholder="Enter price per service"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Total Setteled sales percentage (%)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="setteledpercentage"
                        placeholder="Enter total setteled sales percentage (%)"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Percentage of total sale (credit card)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="totalsale"
                        placeholder="Enter percentage total sale"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Cost per transaction (credit card)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransaction"
                        placeholder="Enter cast per transaction"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (check) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSale "
                        placeholder="Enter percentage total sale "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (check) "
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransactionCheck"
                        placeholder="Enter cast per transaction "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (financing) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSaleFinancing"
                        placeholder="Enter Percentage of total sale (financing) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (financing) "
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransactionFinancing"
                        placeholder="Enter Cost per transaction (financing) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (cash) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSaleCash"
                        placeholder="Enter Percentage of total sale (cash) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (cash) "
                    element={(formik) => (
                      <TextInput
                        name="CostTransactionCash"
                        placeholder="Cost per transaction (cash) "
                        formik={formik}
                      />
                    )}
                  />
                </FormWrapper>
              </>
            }
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                type="submit"
                className="btn btn-primary me-2"
                onClick={() => addAdminModalHide()}
              >
                Add
              </CommonButton>
                <CommonButton
                type="submit"
                className="btn btn-light me-2"
              >
                Reset
              </CommonButton>
              <CommonButton
                type="submit"
                className="btn btn-secondary"
                onClick={() => addAdminModalHide()}
              >
                Cancel
              </CommonButton>
              </>
            )}
          />
      </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editAdminModal}
        onHandleCancel={editAdminModalHide}
        title="Edit Role"
        extraClassName="modal-lg"
      >
        <FormWrapper
          row={true}
          extraClassName="g-3"
        >
          <FormElementWrapper
            md={12}
            label="Role Name"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="roleName"
                placeholder="Enter role name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label=""
            element={(formik) => (
              <Radio.Group value={rediovalue}>
                  <Radio value={1} onClick={() => setrediovalue(1)}>SubAdmin </Radio>
                  <Radio value={2} onClick={() => setrediovalue(2)}>NCA</Radio>
              </Radio.Group>
            )}
          />
            {
              rediovalue === 1  && 
              <>
                
              </>
            }
            {
              rediovalue === 2 && 
              <>
              <FormWrapper
                  row={true}
                  extraClassName="mt-3 g-3"
                >
                  <FormElementWrapper
                    md={6}
                    label="Monthly Price"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="monthlyprice"
                        placeholder="Enter monthly price"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Yearly Price"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="yearlyprice"
                        placeholder="Enter yearly price"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Price per active service provider"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="priceService"
                        placeholder="Enter price per service"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Total Setteled sales percentage (%)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="setteledpercentage"
                        placeholder="Enter total setteled sales percentage (%)"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Percentage of total sale (credit card)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="totalsale"
                        placeholder="Enter percentage total sale"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Cost per transaction (credit card)"
                    mandatory="true"
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransaction"
                        placeholder="Enter cast per transaction"
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (check) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSale "
                        placeholder="Enter percentage total sale "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (check) "
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransactionCheck"
                        placeholder="Enter cast per transaction "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (financing) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSaleFinancing"
                        placeholder="Enter Percentage of total sale (financing) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (financing) "
                    element={(formik) => (
                      <TextInput
                        name="CostPerTransactionFinancing"
                        placeholder="Enter Cost per transaction (financing) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Percentage of total sale (cash) "
                    element={(formik) => (
                      <TextInput
                        name="PercentageOfTotalSaleCash"
                        placeholder="Enter Percentage of total sale (cash) "
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=" Cost per transaction (cash) "
                    element={(formik) => (
                      <TextInput
                        name="CostTransactionCash"
                        placeholder="Cost per transaction (cash) "
                        formik={formik}
                      />
                    )}
                  />
                </FormWrapper>
              </>
            }
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                type="submit"
                className="btn btn-primary me-1 me-sm-2"
                onClick={() => editAdminModalHide()}
              >
                Update
              </CommonButton>
              <CommonButton
                type="submit"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </CommonButton>
              <CommonButton
                type="submit"
                className="btn btn-secondary"
                onClick={() => editAdminModalHide()}
              >
                Cancel
              </CommonButton>
              </>
            )}
          />
      </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default RoleListing;
