import { APIRequest } from '../../helpers/index.helper';
import { LOGIN_ENDPOINT } from '../../apiEndpoints/auth.endpoint';

export const LoginService = async (body) => {
  try {
    const payload = {
      ...LOGIN_ENDPOINT,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

const actions = {
  EDIT: {
    value: 'edit',
  },
  LIST: {
    value: 'list',
  },
  CREATE: {
    value: 'create',
  },
  DELETE: {
    value: 'delete',
  },
  VIEW: {
    value: 'delete',
  },
  STATUS: {
    value: 'status',
  },
  WILDCARD: {
    value: '*',
  },
};
export const getActions = (action) => {
  return actions[action];
};
