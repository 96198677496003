import React from "react";
import {
  Breadcrumb,
  PageHeader,
  TablePlus,
} from "../../../components";
import {
  Columns,
   NotificationsService,
} from "../../../services/index.service";

function ListNotification(props) {
  const NotificationsOrderData = NotificationsService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Notifications",
    },
  ];
  const columns = Columns.NotificationTableColumn();

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Notifications">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={NotificationsOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isResetBtn= {false}
        showAction={false}
      />
    </>
  );
}

export default ListNotification;
