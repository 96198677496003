// import { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input as FormInput } from 'antd';
import { useField } from 'formik';

function Password({ name, icon, setFieldValue, formik, extraClassName = '', ...rest }) {
  // const [field, meta] = useField(name);
  // const config = { ...field, ...rest };

  // if (meta && meta?.touched && meta?.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }

  // function onChange(e) {
  //   formik.handleChange(e);
  // }

  return (
    <Form.Item
      // label={rest?.label}
      // name={name}
      // help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
      // validateStatus={config?.error ? 'error' : 'success'}
    >
      <FormInput.Password
        onChange={formik.handleChange}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        {...rest}
        className={`form-control ${extraClassName && extraClassName}`}
      />
    </Form.Item>
  );
}

export default Password;
