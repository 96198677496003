import {MultiSelectFilter, Toggle, SelectFilter, InputFilter, AmountFilter, DateFilter, AntSelect } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const orderManagementTableColumn = ({ Action }) => {
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectDateProps = (dataIndex, dateType) => ({
    filterDropdown: (props) => (<DateFilter dateType={dateType} dataIndex={dataIndex} {...props}/> ),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getTypeData = () => {
    return [
      {
        name: 'Self Pickup',
        value: 'Self Pickup',
      },
      {
        name: 'Shipping',
        value: 'Shipping',
      },
    ]
  }
  const getStatusData = () => {
    return [      
      {
        name: 'Placed',
        value: 'Placed',
      },
      {
        name: 'Processed',
        value: 'Processed',
      },
      {
        name: 'Delivered',
        value: 'Delivered',
      },
      {
        name: 'Cancelled',
        value: 'Cancelled',
      },
      {
        name: 'Refunded',
        value: 'Refunded',
      },
    ];
  };
  const PermitStatus = [
    {
      id: 'Placed',
      name: 'Placed',
    },
    {
      id: 'Processed',
      name: 'Processed',
    },
    {
      id: 'Delivered',
      name: 'Delivered',
    },
    {
      id: 'Cancelled',
      name: 'Cancelled',
    },
    {
      id: 'Refunded',
      name: 'Refunded',
    },
  ];
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "orderID",
      title: "Order ID",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("order id"),
    },
    {
      dataIndex: "SPName",
      title: "SP Name",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("SP name"),
    },
    {
      dataIndex: "email",
      title: "Email",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email"),
    },
    {
      dataIndex: "OrderType",
      title: "Order Type",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("order type", getTypeData()),
    },
    {
      dataIndex: "shippingAddress",
      title: "Shipping Address",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("shipping address"),
    },
    {
      dataIndex: "totalCost",
      title: "Total cost",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("total cost"),
    },
    {
      dataIndex: "orderDate",
      title: "Order date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("order date", ""),
    },
    {
      dataIndex: "status",
      title: "Order Status",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("order status", getStatusData()),
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>
            <AntSelect
                size="medium"
                id="status"
                extraClassName="form-control"
                name="statusId"
                disabled={false}
                variant="standard"
                placeholder="Select order status"
                options={PermitStatus}
            />
      </>
    }
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />
    },
  ];
};
