import {MultiSelectFilter, Toggle, SelectFilter, InputFilter, AmountFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const CategoryManagementTableColumn = ({ Action }) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getActionData = () => {
    return [      
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    // {
    //   dataIndex: "categoryImage",
    //   title: "Category Image",
    //   headerClasses: "sorting",
    //   sorter: true,
    //   render: (cell, row) => {
    //       return <>
    //           <div className="table-img">
    //               <img src='/assets/images/admin/product-image.jpg' alt="img" />
    //           </div>
    //       </>
    //   }
    // },
    {
      dataIndex: "categoryName",
      title: "Category Name",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("category name"),
    },
      {
        dataIndex: "status",
        title: "Status",
        headerClasses: "sorting",
        render:()=><Toggle isDropDown/>,
        sorter: true,
        ...getColumnSelectSearchProps("status", getActionData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
