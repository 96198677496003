import { Outlet } from 'react-router-dom';
import GMBPhoto from '../../views/GoogleConnect/GMB/Photos/List';
import GMBPost from '../../views/GoogleConnect/GMB/Post/List';
import {
  GMBPhotoAdd,
  GMBPostAdd,
  ListAdword,
  ListGMB,
  ListGoogleConnectService,
  ListLeadService,
  ReviewList,
} from '../../views/index.view';


export default function route() {
  return [
    {
      private: true,
      name: "Google Connect",
      key: "google",
      belongsToSidebar: true,
      icon: accessRoute.GOOGLE_GMB.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.GOOGLE_GMB.name,
          key:"google.gmb",
          path:accessRoute.GOOGLE_GMB.path,
          belongsToSidebar: true,
          element: <ListGMB/>
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_PHOTO.path,
          name: 'Google GMB photo',
          key: "google.gmb",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_GMB_PHOTO.icon,
          element: <GMBPhoto />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_PHOTO_ADD.path,
          name: 'Google GMB photo Add',
          key: "google.gmb",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_GMB_PHOTO_ADD.icon,
          element: <GMBPhotoAdd />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_POST.path,
          name: 'Google GMB Post',
          key: "google.gmb",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_GMB_POST.icon,
          element: <GMBPost />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_POST_ADD.path,
          name: 'Google GMB Post Add',
          key: "google.gmb",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_GMB_POST_ADD.icon,
          element: <GMBPostAdd />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_GMB_REVIEW.path,
          name: 'Google GMB Review',
          key: "google.gmb",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_GMB_REVIEW.icon,
          element: <ReviewList />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_ADWORDS.path,
          name: 'Google Adwords',
          key: "google.adwords",
          belongsToSidebar: true,
          // icon: accessRoute.GOOGLE_ADWORDS.icon,
          element: <ListAdword />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_SERVICE.path,
          name: 'Google Service',
          key: "google.service",
          belongsToSidebar: true,
          // icon: accessRoute.GOOGLE_SERVICE.icon,
          element: <ListGoogleConnectService />,
        },
        {
          private: true,
          path: accessRoute.GOOGLE_LEAD_SERVICE.path,
          name: 'Google Lead Service',
          key: "google.service",
          belongsToSidebar: false,
          // icon: accessRoute.GOOGLE_LEAD_SERVICE.icon,
          element: <ListLeadService />,
        },
      ]
    },
  ];
}

export const accessRoute = {
  GOOGLE_GMB: {
    path: "/admin/google-gmb",
    name: "Google GMB",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-google" />
      </span>
    )
  },
  GOOGLE_GMB_POST: {
    path: "/admin/google-gmb/post",
    name: "Google GMB Post",
  },
  GOOGLE_GMB_POST_ADD: {
    path: "/admin/google-gmb/post/add",
    name: "Google GMB Post Add",
  },
  GOOGLE_GMB_PHOTO: {
    path: "/admin/google-gmb/photo",
    name: "Google GMB Photo",
  },
  GOOGLE_GMB_PHOTO_ADD: {
    path: "/admin/google-gmb/photo/add",
    name: "Google GMB Photo Add",
  },
  GOOGLE_GMB_REVIEW: {
    path: "/admin/google-gmb/review",
    name: "Google GMB Review",
  },
  GOOGLE_ADWORDS: {
    path: "/admin/google-adwords",
    name: "Google Adwords",
  },
  GOOGLE_SERVICE: {
    path: "/admin/google-service",
    name: "Google Service",
  },
  GOOGLE_LEAD_SERVICE: {
    path: "/admin/google-service/lead",
    name: "Google Lead Service",
  },
  // GOOGLE_ADWORDS: {
  //   path: '/admin/google-adwords',
  //   action: getActions('LIST'),
  //   moduleKey: 'google-adwords',
  //   icon: (
  //     <span className="nk-menu-icon">
  //       <em className="icon ni ni-grid-alt" />
  //     </span>
  //   ),
  // },
  // GOOGLE_SERVICE: {
  //   path: '/admin/google-service',
  //   action: getActions('LIST'),
  //   moduleKey: 'google-service',
  //   icon: (
  //     <span className="nk-menu-icon">
  //       <em className="icon ni ni-grid-alt" />
  //     </span>
  //   ),
  // },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
