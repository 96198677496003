import React, { useState } from 'react';
import { Input,  Select } from 'antd';
import { Link } from 'react-router-dom';
import {
  businessTypeData,
  homeAddress,
  industry,
} from '../../../../../services/Register';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput, 
  CommonButton,
  DatePicker,
  InputType
} from '../../../../index';
import UploadInput from '../../../../UIElements/Upload';
import { initValues, validation } from './validation';

function AdminBusinessType({ onSubmit, businessModal = true }) {
  const [businessType, setBusinessType] = useState(true);
  const businessData = businessTypeData();
  const homeAddressData = homeAddress();
  const industryData = industry();
  const handleChange = (e) => {
    if (e === 'Individual') setBusinessType(true);
    else setBusinessType(false);
  };
  const PhoneNumberData = [
    {
      name: '***-**-',
      value: '***-**-',
    },
    {
      name: '***-**-',
      value: '***-**-',
    },
  ]
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={() => validation(businessType, businessModal)}
      initialValues={initValues()}
      row={true}
      extraClassName="g-3"
    >
      <FormElementWrapper
        visible={businessModal}
        md={6}
        label="Business Type"
        // mandatory="true"
        element={(formik) => (
          <Select
            name="businessType"
            placeholder="Select business type"
            options={businessData}
            showSearch
            formik={formik}
            setFieldValue={handleChange}
            size="large"
          />
        )}
      />
      <FormElementWrapper
        visible={!businessType}
        md={6}
        label="Company"
        mandatory="true"
        element={(formik) => (
          <TextInput name="company" placeholder="Enter company" formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        visible={!businessType}
        md={6}
        label="Employer Identification Number"
        mandatory="true"
        element={(formik) => (
          <TextInput
            inputType="number"
            name="employerIdNumber"
            placeholder="Enter employer identification number"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={!businessType}
        md={6}
        label="Doing Business As"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="doingBusiness"
            placeholder="Enter doing business as"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={!businessType}
        md={6}
        label="Industry"
        mandatory="true"
        element={(formik) => (
          <Select
            mode="multiple"
            allowClear
            name="industry"
            placeholder="Select industry"
            options={industryData}
            formik={formik}
            size="large"
          />
        )}
      />
      <FormElementWrapper
        visible={!businessType}
        md={6}
        label="Registered Business Address"
        mandatory="true"
        element={(formik) => (
          <Select
            name="businessAddress"
            placeholder="Select registered business address"
            options={homeAddressData}
            formik={formik}
            size='large'
          />
        )}
      />
      <FormElementWrapper
        visible={businessType}
        md={6}
        label="First Name "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="firstName"
            placeholder="Enter first name"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={businessType}
        md={6}
        label="Last Name"
        mandatory="true"
        element={(formik) => (
          <TextInput name="lastName" placeholder="Enter last name" formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        visible={businessType}
        md={6}
        label="Email"
        mandatory="true"
        element={(formik) => (
          <TextInput name="email" placeholder="Enter email" formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        visible={businessType}
        md={6}
        label="Job Title "
        mandatory="true"
        element={(formik) => (
          <TextInput name="jobTitle" placeholder="Enter job title" formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        visible={businessType}
        md={6}
        label="Country "
        mandatory="true"
        element={(formik) => (
          <Select
            name="homeAddress"
            placeholder="Select country"
            options={homeAddressData}
            showSearch
            formik={formik}
            size="large"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Address Line 1 "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="addressLine1"
            placeholder="Enter address line 1"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Address Line 2"
        element={(formik) => (
          <TextInput
            name="addressLine2"
            placeholder="Enter address line 2"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="State "
        mandatory="true"
        element={(formik) => (
          <TextInput name="state" placeholder="Enter state " formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        md={6}
        label="City "
        mandatory="true"
        element={(formik) => (
          <TextInput name="city" placeholder="Enter city  " formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        md={6}
        label="Zip Code "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="zipCode"
            inputType="number"
            placeholder="Enter zip code"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Business Phone Number "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="phoneNumber"
            placeholder="Enter phone number"
            inputType="number"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
            md={6}
            label="Last 4 Digit Of Social Security Number"
            mandatory={true}
            element={(formik) => (
              <div className="form-control-wrap">
                <div className="input-group flex-nowrap">
                  <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">***-**-</span>
                  </div>
                  <InputType
                    className="form-control"
                    placeholder="Last 4 Digit Of Social Security Number"
                    name="phoneNumber"
                    disabled={false}
                    type="text"
                  />
                </div>
            </div>
            )}
          />   
      <FormElementWrapper
        md={6}
        label="Date Of Birth"
        mandatory="true"
        tooltip={true}
        tooltipText="Managers and owners must be at least 18 years old"
        element={(formik) => (
          <DatePicker
              name="startDate"
              className="form-control form-control-lg date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Upload Identity Document Front "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="DocumentFront"
            placeholder="select identity document front"
            formik={formik}
            extraClassName="form-control-lg"
            type="file"
          />
        )}
        imgPreviewBox={true}
      />
      <FormElementWrapper
        md={6}
        label="Upload Identity Document Back "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="DocumentBack"
            placeholder="select identity document back"
            formik={formik}
            extraClassName="form-control-lg"
            type="file"
          />
        )}
        imgPreviewBox={true}
      />
      <FormElementWrapper
        md={12}
        className="text-center"
        element={() => (
          <Link type="button">Skip & Complete Later</Link>
        )}
      />
      <FormElementWrapper
        md={12}
        element={() => (
          <>
            <div className='my-2'>
              <h5>Q: Why do we need this personal information?</h5>
              <h6  className='text-soft'>A: KYC compliance policy requires us to "Know Your Customer" when processing payments such as credit card transactions, financing, etc.</h6>
            </div>
          </>
        )}
      />
      <FormElementWrapper
        md={12}
        className="text-center"
        element={() => (
          <Link to="/nca/register-bank-details"
            type="submit"
            className="btn btn-lg btn-primary"
          >
            Save & Next
          </Link>
        )}
      />
    </FormWrapper>
  );
}

export default AdminBusinessType;
