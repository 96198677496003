import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../UIElements/DatePicker';
import { AntSelect } from '../../UIElements/index.element';
import { FormElementWrapper, FormWrapper } from '../../Wrappers';


function DiscountFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex }) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const selectOptions = [
    {
        id: 'All',
        name: 'All',
      },
      {
        id: 'Flat (in $)',
        name: 'Flat (in $)',
      },
      {
        id: 'Percent (%)',
        name: 'Percent (%)',
      },
  ]

  return (
    <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        
        <AntSelect
            size="medium"
            id="status"
            extraClassName="form-control"
            name="statusId"
            disabled={false}
            variant="standard"
            placeholder="Select"
            options={selectOptions}
            style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          className="ant-input form-control"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className='btn btn-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 90,
            }}
          >
            Filter
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            className='btn btn-info'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    // <FormWrapper row={true} extraClassName="g-3">
    //     <FormElementWrapper
    //       md={6}
    //       label="From Date"
    //       extraLabelClass="overline-title overline-title-alt"
    //       element={(formik) => (
    //         <DatePicker
    //           name="startDate"
    //           className="form-control date-picker shadow-none"
    //           placeholder="DD/MM/YY"
    //         />
    //       )}
    //     />
    //     <FormElementWrapper
    //       md={6}
    //       label="To Date"
    //       extraLabelClass="overline-title overline-title-alt"
    //       element={(formik) => (
    //         <DatePicker
    //           name="EndDate"
    //           className="form-control date-picker shadow-none"
    //           placeholder="DD/MM/YY"
    //         />
    //       )}
    //     />
        
    // </FormWrapper>
  );
}

export default DiscountFilter;
