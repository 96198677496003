import React from 'react';
import { Select as AntSelect, Form } from 'antd';
import './style.scss';
import { useField } from 'formik';

const { Option } = AntSelect;

function Select({
  children,
  options = [],
  label = '',
  name,
  setFieldValue,
  size = '',
  ...rest
}) {
  // const [field, meta, helpers] = useField(name);
  // const config = { ...field, ...rest };

  // if (meta && meta.touched && meta.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }

  // const handleChangeSelect = (value) => {
  //   // helpers.setValue(value);
  //   if (setFieldValue) setFieldValue(value);
  // };

  return (
    <Form.Item
      className="selectComponent"
      label={label}
      // help={meta.error && meta?.error && meta?.touched ? meta.error : ''}
      // validateStatus={config.error ? 'error' : 'success'}
    >
    <div style={{ position: 'relative' }}>
      <AntSelect size={size} {...rest} getPopupContainer={trigger => trigger.parentElement}>
        {children ??
          options.map((item, key) => (
            <Option
              key={key}
              value={item.value || item.id}
              disabled={item?.disabled || false}
            >
              {item.name}
            </Option>
          ))}
      </AntSelect>
    </div>
    </Form.Item>
  );
}

export default Select;
