import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  ModalComponent,
  ImageElement,
  TablePlus,
} from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns,
  customersService,
} from "../../../services/index.service";
import { modalNotification } from "../../../utils"; 

function ListUsers(props) {
  const navigate = useNavigate()

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Customers",
    },
  ];

  const statusActionOption = [
    {
      id: 1,
      name: 'Select Atcion'
      },
     {
     id: 2,
     name: 'Make Active'
     },
     {
     id: 3,
     name: 'Make Inactive'
     },
     {
     id: 4,
     name: 'Make Delete'
     }
  ]
    

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Customer Deleted Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const [profileView, setprofileView] = useState(false);
  const profileViewShow = () => {
    setprofileView(true);
  };

  const columns = Columns.CustomersTableColumn({ 
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{EditCustomers()},
        },
        // {
        //   name: "View",
        //   icon: "icon ni ni-eye",
        //   handler: () =>{profileViewShow(true)},
        // },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
      profileView:profileViewShow
  });

  const customerData = customersService();
  const EditCustomers = () =>{
    navigate('/admin/customers/:id')
  }
 
  const profileViewHide = () => {
    setprofileView(false);
  };
  const ViewEditProviders = (e) =>{
        e.preventDefault();
    navigate('/admin/customers/edit');
    setprofileView(false);
    // EditProviders();
  }
  const addUserPage = () =>{
    navigate('/admin/customers/add');
  }
  const BookNowPage = (e) =>{
    e.preventDefault();
    navigate('/admin/add-booking');
    setprofileView(false);
  }
  return(
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Customers">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ "create", "csvExport"]}
            btnText="Add Customer"            
            // popover={<CustomersFilterForm/>}
            onHandleShow={ addUserPage}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false} 
        showAction={false}
         statusAction
         statusActionOption={statusActionOption}
        
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Customers module will list all Customers on this page.</li>
                <li> Administrator can Activate / Deactivate / Delete any Customers</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Customer"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={profileView}
        onHandleCancel={profileViewHide}
        title=" Customer Details"
      >
      <div>
        <div className="user-card user-card-s2 p-0 mb-2">
            <div className="user-avatar lg bg-primary">
                <ImageElement source="uploadImg.png" alt="img"/>
            </div>
        </div>
        <div className="row g-3">
            <div className="col-6">
                <span className="sub-text">Name</span>
                <span>	joe testing</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Email</span>
                <span>	joe@tekhne.app</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Phone Number</span>
                <span>+1-3178214104</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Rating</span>
                <span>	No ratings received</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Status</span>
                <span className="badge badge-xs rounded-pill bg-success">Active</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Country</span>
                <span>United States</span>
            </div>
            <div className="col-12">
                <span className="sub-text">Registration Date</span>
                <span>	Monday, Oct 31 st 2022</span>
            </div>
        </div>
        <div className="text-end mt-2"> 
        <Link
                href="#"
                className="btn btn-light me-1 me-sm-2 mb-1 mb-sm-0"
                onClick={ (e) => BookNowPage(e)}
            >
            Book Now
            </Link>            
            <Link
                href="#"
                onClick={(e) => ViewEditProviders(e) }
                className="btn btn-primary me-1 me-sm-2 mb-1 mb-sm-0"
            >
            Edit
            </Link>
           
            <Link
                href="#"
                onClick={(e) => profileViewHide(e) }
                className="btn btn-secondary mb-1 mb-sm-0"
            >
            Cancel
            </Link>
        </div>
      </div>
      </ModalComponent>         
    </>
  );
}

export default ListUsers;
