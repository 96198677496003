import { Radio, Select } from 'antd';
import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AntSelect,
  Breadcrumb,
  InputType,
  ListingHeader,
  PageHeader,
} from '../../../../../components';

function ProfileTab(props) {
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/provider',
      name: 'Providers',
    },
    {
      path: '#',
      name: 'Add Provider',
    },
  ];
  const countryData = [
    {
      id: '1',
      name: 'India',
    },
    {
      id: '2',
      name: 'United State',
    },
  ];
  const googleCampaign = [
    {
      id: '1',
      name: 'Select',
    },
    {
      id: '2',
      name: 'Select',
    },
  ];
  const stateData = [
    {
      id: '1',
      name: 'Alabama',
    },
    {
      id: '2',
      name: 'Alaska',
    },
    {
      id: '3',
      name: 'American Samoa',
    },
  ];
  const cityData = [
    {
      id: '1',
      name: 'Adak',
    },
    {
      id: '2',
      name: 'Afognak',
    },
    {
      id: '3',
      name: 'Aguikchuk',
    },
  ];
  const scheduleJobData = [
    {
      id: '1',
      name: '5',
    },
    {
      id: '2',
      name: '10',
    },
    {
      id: '3',
      name: '15',
    },
    {
      id: '4',
      name: 'Other',
    },
  ];
  const emergencyJobData = [
    {
      id: '1',
      name: '5',
    },
    {
      id: '2',
      name: '10',
    },
    {
      id: '3',
      name: '15',
    },
    {
      id: '4',
      name: 'Other',
    },
  ];
  const accountData = [
    {
      id: '1',
      name: ' Good Guys Heating and Air Conditioning of Lafayette(1897973164) ',
    },
    {
      id: '2',
      name: ' Tekhne of Fort Worth, Texas(3134076415) ',
    },
    {
      id: '3',
      name: ' Good Guys Heating & Air Conditioning(3257228609) ',
    },
    {
      id: '4',
      name: ' Good Guys Heating & Air Conditioning(3404387068) ',
    },
  ];
  const backPage = () => {
    navigate('/admin/provider');
  };
  const [value, setValue] = useState("Percentage");
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Provider">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton']}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter first name"
                        name="fname"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter last name"
                        name="lname"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Password</label>
                      <InputType
                        className="form-control"
                        placeholder="Enter password"
                        name="Password"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Country <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="Country"
                        disabled={false}
                        variant="standard"
                        placeholder="Select country"
                        options={countryData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="State"
                        disabled={false}
                        variant="standard"
                        placeholder="Select state"
                        options={stateData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="City"
                        disabled={false}
                        variant="standard"
                        placeholder="Select city "
                        options={cityData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter address "
                        name="Address"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Zip Code <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter zip code  "
                        name="ZipCode"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group groupnum">
                      <label className="form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                        <div className="input-group flex-nowrap">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              1
                            </span>
                          </div>
                          <InputType
                            className="form-control"
                            placeholder="Enter phone  "
                            name="Phone"
                            disabled={false}
                            variant="standard"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Profile Picture</label>
                      <div className="form-control-wrap">
                        <div className="form-file">
                          <input
                            type="file"
                            className="form-file-input"
                            id="customFile"
                          />
                          <label
                            className="form-file-label"
                            htmlFor="customFile"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label w-100">
                        Gender  
                        </label>
                        <Radio.Group value={value} onChange={onChange}>
                          <Radio value={'Male'}>Male</Radio>
                          <Radio value={'Female'}>Female</Radio>
                        </Radio.Group>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Schedule Job Radius{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="ScheduleJobRadius"
                        disabled={false}
                        variant="standard"
                        placeholder="Select schedule job radius "
                        options={scheduleJobData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Emergency Job Radius{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="EmergencyJobRadius"
                        disabled={false}
                        variant="standard"
                        placeholder="Select emergency job radius "
                        options={emergencyJobData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label w-100">Fee  </label>
                        <Radio.Group value={value} onChange={onChange}>
                          <Radio value={ 'Percentage' }>Percentage</Radio>
                          <Radio value={ 'Hourly' }>Hourly (In Flat USD)</Radio>
                        </Radio.Group>
                    </div>
                  </div>
                  {value === 'Hourly' && (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Service Provider fees per Minute (In USD)
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Commission (Per Minute In USD) "
                        name="ServiceProviderFees"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  )}
                  {value === 'Percentage' && (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Admin Commission fees (In percentage)
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Admin Commission fees (In percentage)"
                        name="ServiceProviderFees"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Google Ad Account</label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="GoogleAdAccount"
                        disabled={false}
                        variant="standard"
                        placeholder="Select google ad account "
                        options={accountData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Google Campaign</label>
                    <AntSelect
                    name="googlecampaign"
                    placeholder="Select google campaign"
                    options={googleCampaign}
                    showSearch
                    mode="multiple"
                    allowClear
                  />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary me-2">
                      Add
                    </Link>
                    <Link href="#" className="btn btn-light">
                      Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileTab;
