import React, {useState} from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  SweetAlert,
  ScheduledBookingsFilterForm,
  Charts,
  ModalComponent,
  CommonButton,
  FormWrapper,
  FormElementWrapper,
  Select,
  DatePicker,
} from "../../../components";
import {
  Columns,
   scheduledBookingsServices,
} from "../../../services/index.service";
import { Link, useNavigate } from "react-router-dom";
import { modalNotification } from "../../../utils";

function ListScheduledBooking(props) {
  const EquipmentOrderData = scheduledBookingsServices();
  const columns = Columns.scheduledBookingsTableColumn({viewInVoicePage, BookingCancelModal});

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Scheduled Bookings",
    },
  ];

  const navigate = useNavigate()
  function viewInVoicePage() {
    navigate("/admin/scheduled-bookings/invoice");
  }
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Data Updated Successfully", })
        return true;
    };
    const [isAlertVisible1, setIsAlertVisible1] = useState(false);
    const onConfirmAlert1 = () => {
        setIsAlertVisible1(false);
        return true;
    };
    const [graphModal, setGraphModal] = useState(false);
  const graphModalShow = () => {
    setGraphModal(true);
  };
  const graphModalHide = () => {
    setGraphModal(false);
  };
  const [bookingCancel, setBookingCancel] = useState(false);
  function BookingCancelModal() {
    setBookingCancel(true)
  }
  const bookingCancelHide = () => {
    setBookingCancel(false)
  }
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  const TimeSlotData = [
    {
      name: '05 AM-08 AM',
      value: '05 AM-08 AM',
    },
    {
      name: '08 AM-11 AM',
      value: '08 AM-11 AM',
    },
    {
      name: '11 AM-02 PM',
      value: '11 AM-02 PM',
    },
  ]
  const [ShowHideGraph, setShowHideGraph] = useState(false)
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  }
  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
    }
  }
  const jobData = {
    labels: [
      'Jan 21',
      'Feb 21',
      'Mar 21',
      'Apr 21',
      'May 21',
      'Jun 21',
      'Jul 21',
      'Aug 21',
      'Sep 21',
      'Oct 21',
      'Nov 21',
      'Dec 21',
    ],
    datasets: [
      {
        label: 'COLLECTED',
        data: [9, 20, 15,17,22,17,12,18,13,11,3,7],
        backgroundColor: [
          '#1EE0AC',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'UNCOLLECTED',
        data: [19, 13, 17,12,8,9,11,16,17,22,5,11,10],
        backgroundColor: [
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Scheduled Bookings">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport',]}
            btnText="Show / Hide Graph"
            onHandleShow={ () => ShowHideGraphShow() }
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="timeslot"
                        placeholder="Select time slot"
                        options={TimeSlotData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="state"
                        placeholder="Select location"
                        options={locationData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
      </div>
      {
        ShowHideGraph &&
        <div className='card my-3'>
          <div className='card-inner'>
            <div className='d-lg-flex align-items-center'>
              <div style={{ maxWidth: '1100px', width: '100%', height: '350px'}}>
                <Charts type="bar" data={jobData} options={ chartOptionsData}/>
              </div>
              <ul className="nk-store-statistics mt-0 mt-lg-4 ms-lg-4">
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">COLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                      $20115773.28
                      <span className="change down me-3">
                        <em className="ni ni-arrow-long-down"></em>2696 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-info-dim ni ni-activity-round"></em>
                </li>
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">UNCOLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                    $751732.75
                      <span className="change up">
                        <em className="ni ni-arrow-long-up"></em>534 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-purple-dim ni ni-activity-round"></em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      <div className="nk-block">
        <div className="card card-full">
          <div className="card-inner border-bottom">
            <h6 className="title mb-3">Busy Providers</h6>
            <div className="timeline">
              <ul className="timeline-list d-flex flex-wrap">
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>05 AM-08 AM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>08 AM-11 AM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>11 AM-02 PM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">76</h6>
                    <div className="timeline-des">
                      <p>02 PM-05 PM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">64</h6>
                    <div className="timeline-des">
                      <p>05 PM-08 PM</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-inner">
            <h6 className="title mb-3">Available Providers</h6>
            <div className="timeline">
              <ul className="timeline-list d-flex flex-wrap">
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>05 AM-08 AM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>08 AM-11 AM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">0</h6>
                    <div className="timeline-des">
                      <p>11 AM-02 PM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">76</h6>
                    <div className="timeline-des">
                      <p>02 PM-05 PM</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-item pb-0">
                  <div className="timeline-data">
                    <h6 className="timeline-title mb-1">64</h6>
                    <div className="timeline-des">
                      <p>05 PM-08 PM</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
      
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        isSearch={false}
        showAction={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Bookings module will list all Bookings on this page.</li>
                <li>Administrator can Activate / Deactivate / Delete any booking.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to save changes ?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <SweetAlert
        title="Are you sure"
        text=" You want to discard changes ?"
        show={isAlertVisible1}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible1}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert1}
      />
        <ModalComponent
        backdrop
        show={graphModal}
        onHandleCancel={graphModalHide}
        title="Graph"
      >
        <div style={{ maxWidth: '500px', width: '100%', margin: '0 auto' }}>
          <Charts type="bar" data={jobData} />
        </div>
        <ul className="nk-store-statistics mt-4">
          <li className="item pt-0">
            <div className="info me-3">
              <div className="title">COLLECTED</div>
              <div className="count d-flex flex-wrap align-items-center">
                $20115773.28
                <span className="change down">
                  <em className="ni ni-arrow-long-down"></em>2696 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-info-dim ni ni-activity-round"></em>
          </li>
          <li className="item pt-0">
            <div className="info me-3">
              <div className="title">UNCOLLECTED</div>
              <div className="count d-flex flex-wrap align-items-center">
              $751732.75
                <span className="change up">
                  <em className="ni ni-arrow-long-up"></em>534 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-purple-dim ni ni-activity-round"></em>
          </li>
        </ul>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={bookingCancel}
        onHandleCancel={bookingCancelHide}
        title="Booking Cancel Reason"
      >
      <ul className="list list-sm list-checked">
        <li><b>Cancel By:</b> Provider</li>
        <li><b>Cancel Reason:</b> test</li>
      </ul>
      <CommonButton onClick={ () => bookingCancelHide() }  className="btn btn-secondary float-end">Cancel</CommonButton>
      </ModalComponent> 
    </>
  );
}

export default ListScheduledBooking;
