import React from "react";
import ImageElement from "../../../components/UIElements/ImageElement";


function DontHavePermission(props) {
  return (
    <>
        <div className="bg-white dontPermission w-100 position-relative overflow-hidden d-flex">
            <div className="dontPermission_inner d-flex flex-column align-items-center justify-content-center w-100 h-100 m-auto text-center">
                <ImageElement source="don't-have-permission.svg" alt="don't-have-permission"/>
                <h4 className="mt-3 mb-3">You Don't Have Permission</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
        </div>
    </>
  );
}

export default DontHavePermission;
