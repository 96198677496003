import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Toggle,
  ActionWrapper,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { FilterFilled  } from "@ant-design/icons";


function ListBank(props) {

  const navigate = useNavigate();
  const AddFinancingBank = () =>{
    navigate('/admin/financing-bank/add')
  } 
  const EditFinancingBank = () =>{
    navigate('/admin/financing-bank/edit')
  } 
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Financing Bank',
    },
  ];

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    }  
  ]

  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
       title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'banklogo',
       title: 'Bank Logo',
      render: (cell, row) => logoFormatter(row?.banklogo,"Image"),
    },
    {
      dataIndex: 'bankName',
       title: 'Bank Name',
      sorter: true,
      ...getColumnSearchProps("bank name")
    },
    {
      dataIndex: 'url',
       title: 'URL',
      ...getColumnSearchProps("url")
    },
    {
      dataIndex: 'description',
       title: 'Description',
      ...getColumnSearchProps("description")
    },
    {
      dataIndex: 'status',
       title: 'Status',
      sorter: true,
      render: () => <Toggle isDropDown />,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
       title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () => {EditFinancingBank() }
      }
    ]);
  const adminData = [
    {
      id: 1,
      banklogo: '/assets/images/admin/small-logo.svg',
      bankName: 'Good Guys Home Services (GGHS)',
      url: '	http://www.goodguyshomeservices.com/',
      description: 'GGHS'
    },
    {
      id: 2,
      banklogo: '/assets/images/admin/small-logo.svg',
      bankName: '	Test Bank',
      url: 'http://www.goodguyshomeservices.com/',
      description: 'Test Description'
    },
    {
      id: 3,
      banklogo: '/assets/images/admin/small-logo.svg',
      bankName: 'Good Guys Home Services (GGHS)',
      url: '	http://www.goodguyshomeservices.com/',
      description: 'Test Description'
    }
  ];

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Financing Bank">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'create']}
            btnText="Add Financing Bank"
            onHandleShow={AddFinancingBank}
            // popover={<FinancingBankFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
    </>
  );
}

export default ListBank;
