import {
  LoginParentView,
  LoginView,
  AdminLogin,
  NCALogin,
  ForgetView,
  NCASignUp
} from '../../views/index.view';

export default function route() {
  return [
    {
      // path: accessRoute.LOGIN.path,
      private: false,
      name: 'Login',
      // key: accessRoute.LOGIN.path,
      belongsToSidebar: false,
      icon: accessRoute.LOGIN.icon,
      element: <LoginParentView />,
      children: [
        {
          path: accessRoute.LOGIN.path,
          private: false,
          name: 'Login',
          key: accessRoute.LOGIN.path,
          belongsToSidebar: false,
          icon: accessRoute.LOGIN.icon,
          element: <LoginView />,
        },
        {
          path: accessRoute.NCA_LOGIN.path,
          private: false,
          name: 'NCA Login',
          key: accessRoute.NCA_LOGIN.path,
          belongsToSidebar: false,
          icon: accessRoute.NCA_LOGIN.icon,
          element: <NCALogin />,
        },
        {
          path: accessRoute.ADMIN_LOGIN.path,
          private: false,
          name: 'Admin Login',
          key: accessRoute.ADMIN_LOGIN.path,
          belongsToSidebar: false,
          icon: accessRoute.ADMIN_LOGIN.icon,
          element: <AdminLogin />,
        },
        {
          path: accessRoute.FORGET_PASSWORD.path,
          private: false,
          name: 'Forget Password',
          key: accessRoute.FORGET_PASSWORD.path,
          belongsToSidebar: false,
          icon: accessRoute.FORGET_PASSWORD.icon,
          element: <ForgetView />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  LOGIN: {
    path: '/login',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  NCA_LOGIN: {
    path: '/nca-login',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  ADMIN_LOGIN: {
    path: '/admin-login',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  FORGET_PASSWORD: {
    path: '/forget-password',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};

export const getLoginRoutes = () => {
  return route()[0].children;
};
