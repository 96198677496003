export const proceduresService = () => {
  return [
    {
      id: 1,
      lessonTitle: "Testing 1",
      typeSop: "Service Provider",
      companyServices: "Plumbing",
      adminModules: "N/A",
      questionsNo: "0",
      requiredNo: "No",
    },
    {
      id: 2,
      lessonTitle: "TEST ED",
      typeSop: "Service Provider",
      companyServices: "HVAC",
      adminModules: "N/A",
      questionsNo: "1",
      requiredNo: "Yes",
    },
    {
      id: 3,
      lessonTitle: "Testing 1",
      typeSop: "Service Provider",
      companyServices: "Plumbing",
      adminModules: "N/A",
      questionsNo: "0",
      requiredNo: "No",
    },
    {
      id: 4,
      lessonTitle: "TEST ED",
      typeSop: "Service Provider",
      companyServices: "HVAC",
      adminModules: "N/A",
      questionsNo: "1",
      requiredNo: "Yes",
    },
    {
      id: 5,
      lessonTitle: "TEST ED",
      typeSop: "Service Provider",
      companyServices: "HVAC",
      adminModules: "N/A",
      questionsNo: "1",
      requiredNo: "Yes",
    },
  ];
};
