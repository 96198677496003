import { Outlet } from 'react-router-dom';
import EditHomePage from '../../views/AdminSetting/Utility/EditHomePage/Edit';
import ListHomePage from '../../views/AdminSetting/Utility/EditHomePage/List';
import {
  EditPage,
  ListCity,
  ListCountry,
  ListFaq,
  ListPage,
  ListState,
  NotificationTemplate,
  SendNotification,
  Banner,
  ListIndustry,
  Information,
  Financing,
  Tips,
  QuoteInformation,
  ListNCA,
  NCAPaymentReport,
  NCADocuments,
  EditNCA,
  NCALogReport,
} from '../../views/index.view';
import {viewPageService} from "../../services/index.service";

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Super Admin Setting',
      key: 'admin',
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.LIST_CITY.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          name: 'Utility',
          key: 'admin.utility',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              name: 'Localization',
              key: 'admin.utility.localization',
              belongsToSidebar: true,
              // icon: accessRoute.DASHBOARD.icon,
              element: <Outlet />,
              submenu: true,
              children: [
                {
                  private: true,
                  name: accessRoute.LIST_COUNTRY.name,
                  key: accessRoute.LIST_COUNTRY.key,
                  path: accessRoute.LIST_COUNTRY.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_COUNTRY.icon,
                  element: <ListCountry />,
                },

                {
                  private: true,
                  name: accessRoute.LIST_STATE.name,
                  key: accessRoute.LIST_STATE.key,
                  path: accessRoute.LIST_STATE.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_STATE.icon,
                  element: <ListState />,
                },
                {
                  private: true,
                  name: accessRoute.LIST_CITY.name,
                  key: accessRoute.LIST_CITY.key,
                  path: accessRoute.LIST_CITY.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_CITY.icon,
                  element: <ListCity />,
                },
              ],
            },
            {
              private: true,
              path: accessRoute.LIST_PAGE.path,
              name: 'Pages',
              key: accessRoute.LIST_PAGE.key,
              belongsToSidebar: true,
              // icon: accessRoute.LIST_PAGE.icon,
              element: <ListPage />,
            },
            {
              private: true,
              path: accessRoute.LIST_PAGE_EDIT.path,
              name: 'edit-page',
              key: accessRoute.LIST_PAGE.key,
              belongsToSidebar: false,
              // icon: accessRoute.LIST_PAGE_EDIT.icon,
              element: <EditPage />,
            },
            {
              private: true,
              path: accessRoute.LIST_HOMEPAGE.path,
              name: 'Edit Home Page',
              key: accessRoute.LIST_HOMEPAGE.key,
              belongsToSidebar: true,
              icon: accessRoute.LIST_HOMEPAGE.icon,
              element: <ListHomePage />,
            },
            {
              private: true,
              path: accessRoute.EDIT_HOMEPAGE.path,
              name: 'Edit Home Page',
              key: accessRoute.EDIT_HOMEPAGE.key,
              belongsToSidebar: false,
              // icon: accessRoute.EDIT_HOMEPAGE.icon,
              element: <EditHomePage />,
            },
            {
              private: true,
              path: accessRoute.LIST_FAQ.path,
              name: 'Faq',
              key: accessRoute.LIST_FAQ.key,
              belongsToSidebar: true,
              icon: accessRoute.LIST_FAQ.icon,
              element: <ListFaq />,
            },
            {
              private: true,
              path: accessRoute.SEND_NOTIFICATION.path,
              name: 'Send Push-Notification',
              key: accessRoute.SEND_NOTIFICATION.key,
              belongsToSidebar: true,
              icon: accessRoute.SEND_NOTIFICATION.icon,
              element: <SendNotification />,
            },
          ],
        },
        {
          private: true,
          name: 'App Text',
          key: 'admin.appText',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.Important_Info.path,
              name: 'Information',
              key: accessRoute.Important_Info.key,
              belongsToSidebar: true,
              // icon: accessRoute.Important_Info.icon,
              element: <Information />,
            },
            {
              private: true,
              path: accessRoute.Financing.path,
              name: 'Financing',
              key: accessRoute.Financing.key,
              belongsToSidebar: true,
              icon: accessRoute.Financing.icon,
              element: <Financing />,
            },
            {
              private: true,
              path: accessRoute.Tips.path,
              name: 'Tips',
              key: accessRoute.Tips.key,
              belongsToSidebar: true,
              icon: accessRoute.Tips.icon,
              element: <Tips />,
            },
            {
              private: true,
              path: accessRoute.Quote_Info.path,
              name: 'Quote Information',
              key: accessRoute.Quote_Info.key,
              belongsToSidebar: true,
              icon: accessRoute.Quote_Info.icon,
              element: <QuoteInformation />,
            },
          ],
        },
        {
          private: true,
          name: 'Customer App Settings',
          key: 'admin.customerappsettings',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.Notification_Template.path,
              name: 'Notification Templates',
              key: accessRoute.Notification_Template.key,
              belongsToSidebar: true,
              // icon: accessRoute.Notification_Template.icon,
              element: <NotificationTemplate />,
            },
            {
              private: true,
              path: accessRoute.Banner_View.path,
              name: 'Banner',
              key: accessRoute.Banner_View.key,
              belongsToSidebar: true,
              icon: accessRoute.Banner_View.icon,
              element: <Banner />,
            },
          ],
        },
        {
          private: true,
          path: accessRoute.LIST_NCA.path,
          name: 'NCA',
          key: accessRoute.LIST_NCA.key,
          belongsToSidebar: true,
          // icon: accessRoute.LIST_NCA.icon,
          element: <ListNCA />,
        },
        {
          private: true,
          path: accessRoute.LIST_NCA_PAYMENT_REPORT.path,
          name: 'NCA',
          key: accessRoute.LIST_NCA_PAYMENT_REPORT.key,
          belongsToSidebar: false,
          // icon: accessRoute.LIST_NCA_PAYMENT_REPORT.icon,
          element: <NCAPaymentReport />,
        },
        {
          private: true,
          path: accessRoute.LIST_NCA_DOCUMENTS.path,
          name: 'NCA',
          key: accessRoute.LIST_NCA_DOCUMENTS.key,
          belongsToSidebar: false,
          // icon: accessRoute.LIST_NCA_DOCUMENTS.icon,
          element: <NCADocuments />
        },
        {
          private: true,
          path: accessRoute.EDIT_NCA.path,
          name: 'NCA',
          key: accessRoute.EDIT_NCA.key,
          belongsToSidebar: false,
          // icon: accessRoute.EDIT_NCA.icon,
          element: <EditNCA />
        },
        {
          private: true,
          path: accessRoute.NCA_LOG_REPORT.path,
          name: 'NCA',
          key: accessRoute.NCA_LOG_REPORT.key,
          belongsToSidebar: false,
          // icon: accessRoute.NCA_LOG_REPORT.icon,
          element: <NCALogReport />
        },
        {
          private: true,
          path: accessRoute.LIST_INDUSTRY.path,
          name: 'Industry',
          key: accessRoute.LIST_INDUSTRY.key,
          belongsToSidebar: true,
          // icon: accessRoute.LIST_INDUSTRY.icon,
          element: <ListIndustry />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  LIST_CITY: {
    path: '/admin/city',
    name: 'City',
    key: 'admin.utility.localization.city',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-account-setting-fill" />
      </span>
    ),
  },
  LIST_STATE: {
    path: '/admin/state',
    name: 'State',
    key: 'admin.utility.localization.state',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_COUNTRY: {
    path: '/admin/country',
    name: 'Country',
    key: 'admin.utility.localization.country',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_FAQ: {
    path: '/admin/faq',
    name: 'Faq',
    key: 'admin.utility.faq',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  LIST_PAGE: {
    path: '/admin/page',
    name: 'Page',
    key: 'admin.utility.page',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_PAGE_EDIT: {
    path: '/admin/edit-page',
    key: 'admin.utility.page',
    name: 'Edit-Page',
  },

  LIST_NCA: {
    path: '/admin/nca',
    name: 'NCA',
    key: 'admin.nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_NCA_PAYMENT_REPORT: {
    path: '/admin/nca/payment-report',
    name: 'NCA Payment Report',
    key: 'admin.nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_NCA_DOCUMENTS: {
    path: '/admin/nca/documents',
    name: 'NCA Documents',
    key: 'admin.nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  EDIT_NCA: {
    path: '/admin/nca/edit',
    name: 'Edit NCA',
    key: 'admin.nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  NCA_LOG_REPORT: {
    path: '/admin/nca/log-report',
    name: 'NCA Log Report',
    key: 'admin.nca',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_INDUSTRY: {
    path: '/admin/industry',
    key: 'admin.industry',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  LIST_HOMEPAGE: {
    path: '/admin/homepage',
    key: 'admin.utility.homepage',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  EDIT_HOMEPAGE: {
    path: '/admin/edit-homepage',
    key: 'admin.utility.homepage',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  SEND_NOTIFICATION: {
    path: '/admin/notification',
    key: 'admin.utility.notification',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  Notification_Template: {
    path: '/admin/notification-template',
    key: 'admin.customerappsettings.notification-template',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  Banner_View: {
    path: '/admin/banner',
    key: 'admin.customerappsettings.banner',
  },
  Important_Info: {
    path: '/admin/important-info',
    key: 'admin.appText.important-info',
  },
  Financing: {
    path: '/admin/financing',
    key: 'admin.appText.financing',
  },
  Tips: {
    path: '/admin/tips',
    key: 'admin.appText.tips',
  },
  Quote_Info: {
    path: '/admin/quote-info',
    key: 'admin.appText.quote-info',
  },
};

/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
  return [...route()[0]?.children];
};
