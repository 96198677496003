import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
    Breadcrumb,
    PageHeader,
    ListingHeader,
    ActionWrapper,
    ModalComponent,
    InputType,
    AntTextArea,
    TablePlus,
    InputFilter,
  } from "../../../../../components";
import { modalNotification } from "../../../../../utils";
import { FilterFilled  } from "@ant-design/icons";

function NotificationTemplate(props) {
  const onConfirmAlert = () => {
    modalNotification({ type: "success", message: "Edit Notification Update Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
    const breadcrumb = [
        {
          path: "/admin/dashboard",
          name: "Dashboard",
        },
        {
          path: "#",
          name: "Notification Templates",
        },
    ];
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
      filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilterDropdownVisibleChange: (visible) => {
        // if (visible) {
        //   setTimeout(() => this.searchInput.select());
        // }
      },
    });
    const columns = [
    {
        dataIndex: "id",
        title: "S.No.",
        headerClasses: "w_70",
    },
    {
        dataIndex: "notificationpurpose",
        title: "Notification Purpose ",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("notification purpose")
    },
    {
        dataIndex: "notificationmessage",
        title: "Notification Message",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("notification message")
    },
    {
        dataIndex: "Action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />,
    },
    ];
    const Action = () => actionFormatter([
      {
        name: "Edit",
        icon: "icon ni ni-edit",
        handler:editNotificationFilterShow,
        // path: "/admin/dashboard"
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      }
    ])
    const [editNotificationFilter, seteditNotificationFilter] = useState(false);

    const editNotificationFilterShow = () => {
      seteditNotificationFilter(true);
    };
    const editNotificationFilterHide = () => {
      seteditNotificationFilter(false);
    };
    const editNotificationFilterUpdate = () =>{
      seteditNotificationFilter(false);
      onConfirmAlert();
    }
    const pageData = [
    {
      id: 1,
      notificationpurpose: "When job booking time is coming soon",
      notificationmessage: "You have a service appointment coming up soon.",
    },
    {
        id: 2,
        notificationpurpose: "Admin Active providers",
        notificationmessage: "Congratulations! You are now a Part of Good Guys"
    },
    ];
  return(
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Notification Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["csvExport"]}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        isSearch={false}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Notification Templates module will list all emails on this page.</li>
                <li>Administrator can edit any Notification template.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={editNotificationFilter}
        onHandleCancel={editNotificationFilterHide}
        title="Edit Notification Template"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Purpose <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter purpose"
                  defaultValue="When job booking time is coming soon "
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Body <span className="text-danger"> *</span>
                </label>
                <AntTextArea
                  className="form-control"
                  placeholder="Enter body"
                  defaultValue="You have a service appointment coming up soon."
                />
                <p className="form-note mt-1"><b>Note:</b>Please Don't Replace Variable Between # Sign.</p>
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => editNotificationFilterUpdate()}
                className="btn btn-primary me-1 me-sm-2"
              >
                Edit
              </Link>
              <Link
                href="#"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => editNotificationFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}

export default NotificationTemplate;