export const membershipService = () => {
    return [
      {
        id: 1,
        title: "Plan 1",
        monthlyCost: "14.50",
        yearlyOneTimeCost: "$150",
        additionalDiscount: "10%",
        // lengthOfMembership: "Monthly",
        displayOrder: "1",
        description: 'Furnace cleaning plan'
      },
      {
        id: 2,
        title: "Plan 2",
        monthlyCost: "20",
        yearlyOneTimeCost: "$200",
        additionalDiscount: "15%",
        // lengthOfMembership: "Yearly",
        displayOrder: "10",
        description: 'Premium Plan'
      },
      {
        id: 3,
        title: "Plan 3",
        monthlyCost: "50",
        yearlyOneTimeCost: "$190",
        additionalDiscount: "16%",
        // lengthOfMembership: "Yearly",
        displayOrder: "3",
        description: 'Cleaning Premium Plan'
      },
      {
        id: 4,
        title: "Plan 4",
        monthlyCost: "10",
        yearlyOneTimeCost: "$156",
        additionalDiscount: "19%",
        // lengthOfMembership: "Yearly",
        displayOrder: "4",
        description: 'AC Plan'
      },
      {
        id: 5,
        title: "Plan 5",
        monthlyCost: "15",
        yearlyOneTimeCost: "$200",
        additionalDiscount: "16%",
        // lengthOfMembership: "Monthly",
        displayOrder: "1",
        description: 'Furnace cleaning, AC cleaning'
      },
    ];
  };
  