import {  Select } from "antd";
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea
} from "../../../../../components";


function EditQuoteSystem(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/quote-management')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/quote-management",
      name: "Quote Management",
    },
    {
      path: "#",
      name: "Edit Quote",
    },
  ];

  const OptionStatusData = [
    {
      name: 'Good',
      value: 'Good',
    },
    {
      name: 'Better',
      value: 'Better',
    },
    {
      name: 'Best',
      value: 'Best',
    },
    {
      name: 'Premium',
      value: 'Premium',
    },
  ]
  const customerTagData = [
    {
      name: 'AirCleaner',
      value: 'Air Cleaner',
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'HomeWarranty',
      value: 'Home Warranty',
    },
  ]
  const jobTagData = [
    {
      name: 'AirPurifier',
      value: 'Air Purifier',
    },
    {
      name: 'HeatingTune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'qwerty1',
      value: 'Qwerty1',
    },
  ]

  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Quote">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6}
                label="Product Image 1 (Default Product Image)"
                element={(formik) => (
                  <TextInput
                    name="image"
                    placeholder="Select product image 1"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Product Image 2"
                element={(formik) => (
                  <TextInput
                    name="image"
                    placeholder="Select product image 2"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Product Image 3"
                element={(formik) => (
                  <TextInput
                    name="image"
                    placeholder="Select product image 3"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Product Image 4"
                element={(formik) => (
                  <TextInput
                    name="image"
                    placeholder="Select product image 4"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Product Image 5"
                element={(formik) => (
                  <TextInput
                    name="image"
                    placeholder="Select product image 5"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Company Name"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="companyname"
                    placeholder="Enter company name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Industry Type "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="industrytype"
                    placeholder="Enter industry type "
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Equipment, Material, IAQ "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="equipment"
                    placeholder="Enter equipment, material, IAQ "
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Quote Type"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="QuoteType"
                    placeholder="Enter quote type"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Brand"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="Brand"
                    placeholder="Enter brand"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="System BTU/Capacity"
                element={(formik) => (
                  <TextInput
                    name="systemBTU"
                    placeholder="Enter system BTU/Capacity"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Size Description"
                element={(formik) => (
                  <TextInput
                    name="sizedescription"
                    placeholder="Enter size description"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Directional"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="directional"
                    placeholder="Enter directional"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Minimum Blower Drive"
                element={(formik) => (
                  <TextInput
                    name="minimumblowerDrive"
                    placeholder="Enter minimum blower drive"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Material List Type"
                element={(formik) => (
                  <TextInput
                    name="MaterialListType"
                    placeholder="Enter material list type"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Material Name"
                element={(formik) => (
                  <TextInput
                    name="materialName"
                    placeholder="Enter material name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Material Additional Cost to Customer"
                element={(formik) => (
                  <TextInput
                    name="additionalcost"
                    placeholder="Enter material additional cost to customer"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Material Expense"
                element={(formik) => (
                  <TextInput
                    name="materialExpense"
                    placeholder="Enter material expense"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Material Auto Fill"
                element={(formik) => (
                  <TextInput
                    name="materialautofill"
                    placeholder="Enter material auto fill"
                    formik={formik}
                  />
                )}
              />
            <FormElementWrapper
              md={6}
              label="Option Status "
              mandatory={true}
              element={(formik) => (
                <Select
                  name="OptionStatus "
                  placeholder="Select service type "
                  options={OptionStatusData}
                  showSearch
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
                md={6}
                label="Gas Efficiency"
                element={(formik) => (
                  <TextInput
                    name="gasEfficiency"
                    placeholder="Enter gas efficiency"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Wifi"
                element={(formik) => (
                  <TextInput
                    name="wifi"
                    placeholder="Enter wifi"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Programmable Stat"
                element={(formik) => (
                  <TextInput
                    name="programmablestat"
                    placeholder="Enter programmable stat"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Other Rebates"
                element={(formik) => (
                  <TextInput
                    name="otherrebates"
                    placeholder="Enter other rebates"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Certified Reference Number"
                element={(formik) => (
                  <TextInput
                    name="AHRICertifiedReferenceNumber"
                    placeholder="Enter AHRI certified reference number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Old AHRI Reference Number"
                element={(formik) => (
                  <TextInput
                    name="oldAHRIReferenceNumber"
                    placeholder="Enter old AHRI reference number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Model Status"
                element={(formik) => (
                  <TextInput
                    name="ModelStatus"
                    placeholder="Enter model status"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Manufacturer Type"
                element={(formik) => (
                  <TextInput
                    name="ManufacturerType"
                    placeholder="Enter manufacturer type"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Type"
                element={(formik) => (
                  <TextInput
                    name="AHRIType"
                    placeholder="Enter AHRI type"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Phase"
                element={(formik) => (
                  <TextInput
                    name="Phase"
                    placeholder="Enter phase"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Series Name"
                element={(formik) => (
                  <TextInput
                    name="SeriesName"
                    placeholder="Enter series name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Outdoor Unit - Brand Name"
                element={(formik) => (
                  <TextInput
                    name="outdoorUnitBrand"
                    placeholder="Enter outdoor unit - brand name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Outdoor Unit - Model Number (Condenser or Single Package)"
                element={(formik) => (
                  <TextInput
                    name="outdoorUnitModel"
                    placeholder="Enter outdoor unit - model number (condenser or single package)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Indoor Unit - Brand Name"
                element={(formik) => (
                  <TextInput
                    name="IndoorUnitBrandName"
                    placeholder="Enter indoor unit - brand name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Indoor Unit - Model Number (Evaporator and/or Air Handler)"
                element={(formik) => (
                  <TextInput
                    name="IndoorUnitModelName"
                    placeholder="Enter indoor unit - model number (evaporator and/or air handler)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Furnace - Model Number"
                element={(formik) => (
                  <TextInput
                    name="furnaceModelNumber"
                    placeholder="Enter furnace - model number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Certified Ratings - Cooling Capacity (A2) - Single or High Stage (95F),btuh"
                element={(formik) => (
                  <TextInput
                    name="CoolingCapacity"
                    placeholder="Enter AHRI certified ratings - cooling capacity (A2) - single or high stage (95F),btuh"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Certified Ratings - EER (A2) - Single or High Stage (95F)"
                element={(formik) => (
                  <TextInput
                    name="EER"
                    placeholder="Enter AHRI certified ratings - EER (A2) - single or high stage (95F)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Certified Ratings - IEER"
                element={(formik) => (
                  <TextInput
                    name="IEER"
                    placeholder="Enter AHRI certified ratings - IEER"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="AHRI Certified Ratings - SEER"
                element={(formik) => (
                  <TextInput
                    name="SEER"
                    placeholder="Enter AHRI certified ratings - SEER"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Indoor Full-Load Air Volume Rate (A2 SCFM)"
                element={(formik) => (
                  <TextInput
                    name="airVolumeSCFM"
                    placeholder="Enter indoor full-load air volume rate (A2 SCFM)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Indoor Cooling Intermediate Air Volume Rate (Ev SCFM)"
                element={(formik) => (
                  <TextInput
                    name="coolingintermediate"
                    placeholder="Enter indoor cooling intermediate air volume rate (Ev SCFM)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Indoor Cooling Minimum Air Volume Rate (B1 SCFM)"
                element={(formik) => (
                  <TextInput
                    name="furnaceModelNumber"
                    placeholder="Enter indoor cooling minimum air volume rate (B1 SCFM)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Designated Tested Combination"
                element={(formik) => (
                  <TextInput
                    name=" designatedtested"
                    placeholder="Enter designated tested combination"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Sold in?"
                element={(formik) => (
                  <TextInput
                    name="Soldin"
                    placeholder="Enter sold in?"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Region"
                element={(formik) => (
                  <TextInput
                    name="region"
                    placeholder="Enter region"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Estimated National Average Operating Cooling Cost ($)"
                element={(formik) => (
                  <TextInput
                    name="NationalAverage"
                    placeholder="Enter estimated national average operating cooling cost ($)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Energy Guide Label"
                element={(formik) => (
                  <TextInput
                    name="EnergyGuideLabel"
                    placeholder="Enter energy guide label"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Eligible For Federal Tax Credit (installed between Feb 17,2009 and Dec 31,2016)"
                element={(formik) => (
                  <TextInput
                    name="taxcredit"
                    placeholder="Enter eligible for federal tax credit (installed between Feb 17,2009 and Dec 31,2016)"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Is Rerated"
                element={(formik) => (
                  <TextInput
                    name="IsRerated"
                    placeholder="Enter is rerated"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="System Part Warranty"
                element={(formik) => (
                  <TextInput
                    name="SystemPartWarranty"
                    placeholder="Enter system part warranty"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="System Heat Exchanger Part Warranty"
                element={(formik) => (
                  <TextInput
                    name="heatexchangerpartwarranty"
                    placeholder="Enter system heat exchanger part warranty"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Benefits"
                element={(formik) => (
                  <TextInput
                    name="Benefits"
                    placeholder="Enter benefits"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Quote Type Description"
                element={(formik) => (
                  <AntTextArea
                    name="QuoteTypeDescription"
                    className="form-control"
                    placeholder="Enter quote type description *"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Misc Rebate"
                element={(formik) => (
                  <TextInput
                    name="MiscRebate"
                    placeholder="Enter misc rebate"
                    formik={formik}
                  />
                )}
              />
              <h6 className="title mb-0">Auto Tag</h6>
              <FormElementWrapper
                md={6}
                label="Customer Tag "
                element={(formik) => (
                  <Select
                    name="adminType"
                    mode="multiple"
                    allowClear
                    placeholder="Select customer tag "
                    options={customerTagData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Job Tag "
                element={(formik) => (
                  <Select
                    name="adminType"
                    mode="multiple"
                    allowClear
                    placeholder="Select job tag "
                    options={jobTagData}
                    showSearch
                    formik={formik}
                  />
                )}
              />         
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Update
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
        </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditQuoteSystem;
