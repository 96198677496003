export const APP_NAME = process.env.REACT_APP_APP_NAME || 'React App';

export const APP_KEY = process.env.REACT_APP_APP_KEY || 'react-app';

export const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';

export const LANGUAGE = process.env.REACT_APP_LANG || 'en';

export const APP_DATE_FORMAT = 'MM/DD/YYYY';

export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_PATH || '/assets/images/admin';
