import { Outlet } from 'react-router-dom';
import { AddBank, AddHouseLocation, AddQuoteSystem, AddSubServiceCategory, EditBank, EditLineItem, EditQuoteSystem, EditServiceCategory, EditSubServiceCategory, ListAmountCalculation, ListBank, ListDiscount, ListHouseLocation, ListLineItem, ListOptionalItem, ListQuoteOrder, ListQuoteSystem, ListRebate, ListService, ListServiceCategory, ListSubServiceCategory, QuoteManagementEstimates, ServiceCategoryReport, ViewImages } from '../../views/PriceManagement';
import CreateLineItem from '../../views/PriceManagement/LineItem/Create';
import CreateServiceCategory from '../../views/PriceManagement/ManageService/ServiceCategory/Create';
import ListOption from '../../views/PriceManagement/QuoteManagement/Finance/Option/List';
import EditHouseLocation from '../../views/PriceManagement/QuoteManagement/SupplyHouseLocation/Edit';
import BulkUpload from '../../views/PriceManagement/QuoteManagement/UploadBulk';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Price Management',
      key: 'priceManagement',
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.List_SERVICE_CATEGORY.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          name: 'Manage Services',
          key: 'priceManagement.manageservices',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            // {
            //   private: true,
            //   // path: accessRoute.List_SERVICE_CATEGORY.path,
            //   name: 'Service Category',
            //   key: 'priceManagement.manageservices.serviceCategory',
            //   belongsToSidebar: true,
            //   submenu: true,
            //   // icon: accessRoute.List_SERVICE_CATEGORY.icon,
            //   element: <Outlet />,
            //   children: [
                {
                  private: true,
                  path: accessRoute.List_SERVICE_CATEGORY.path,
                  name: 'Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: true,
                  // icon: accessRoute.List_SERVICE_CATEGORY.icon,
                  element: <ListServiceCategory />,
                },
                {
                  private: true,
                  path: accessRoute.ADD_SERVICE_CATEGORY.path,
                  name: 'Add Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.ADD_SERVICE_CATEGORY.icon,
                  element: <CreateServiceCategory />,
                },
                {
                  private: true,
                  path: accessRoute.SERVICE_CATEGORY_REPORT.path,
                  name: 'Service Category Report',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.SERVICE_CATEGORY_REPORT.icon,
                  element: <ServiceCategoryReport />,
                },
                {
                  private: true,
                  path: accessRoute.EDIT_SERVICE_CATEGORY.path,
                  name: 'Edit Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.EDIT_SERVICE_CATEGORY.icon,
                  element: <EditServiceCategory />,
                },
                {
                  private: true,
                  path: accessRoute.LIST_SUB_SERVICE_CATEGORY.path,
                  name: 'Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.LIST_SUB_SERVICE_CATEGORY.icon,
                  element: <ListSubServiceCategory />,
                },
                {
                  private: true,
                  path: accessRoute.ADD_SUB_SERVICE_CATEGORY.path,
                  name: 'Add Sub Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.ADD_SUB_SERVICE_CATEGORY.icon,
                  element: <AddSubServiceCategory />,
                },
                {
                  private: true,
                  path: accessRoute.EDIT_SUB_SERVICE_CATEGORY.path,
                  name: 'Add Sub Service Category',
                  key: 'priceManagement.manageservices.serviceCategory.list',
                  belongsToSidebar: false,
                  // icon: accessRoute.EDIT_SUB_SERVICE_CATEGORY.icon,
                  element: <EditSubServiceCategory />,
                },
              // ]
            // },

            {
              private: true,
              path: accessRoute.LIST_SERVICE.path,
              name: 'Services',
              key: 'priceManagement.manageservices.service',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_SERVICE.icon,
              element: <ListService />,
            },
          ],
        },
        {
          private: true,
          path: accessRoute.LINE_ITEMS.path,
          name: 'Line Items',
          key: 'priceManagement.line',
          belongsToSidebar: true,
          // icon: accessRoute.LINE_ITEMS.icon,
          element: <ListLineItem />,
        },
        {
          private: true,
          path: accessRoute.ADD_LINE_ITEMS.path,
          name: 'Add Line Items',
          key: accessRoute.ADD_LINE_ITEMS.path,
          belongsToSidebar: false,
          // icon: accessRoute.ADD_LINE_ITEMS.icon,
          element: <CreateLineItem />,
        },
        {
          private: true,
          path: accessRoute.EDIT_LINE_ITEMS.path,
          name: 'Edit Line Items',
          key: accessRoute.EDIT_LINE_ITEMS.path,
          belongsToSidebar: false,
          // icon: accessRoute.EDIT_LINE_ITEMS.icon,
          element: <EditLineItem />,
        },
        {
          private: true,
          name: 'Quote Management',
          key: 'priceManagement.quote.management',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.UPLOAD_BULK_DATA.path,
              name: 'Upload Bulk Data',
              key: 'priceManagement.quote.management.bulk-upload',
              belongsToSidebar: true,
              // icon: accessRoute.UPLOAD_BULK_DATA.icon,
              element: <BulkUpload />,
            },
            {
              private: true,
              path: accessRoute.OPTIONAL_ITEMS.path,
              name: 'Optional Items',
              key: 'priceManagement.quote.management.optional',
              belongsToSidebar: true,
              // icon: accessRoute.OPTIONAL_ITEMS.icon,
              element: <ListOptionalItem />,
            },
            {
              private: true,
              path: accessRoute.QUOTE_SYSTEM.path,
              name: 'Quote System',
              key: 'priceManagement.quote.management.quote',
              belongsToSidebar: true,
              // icon: accessRoute.QUOTE_SYSTEM.icon,
              element: <ListQuoteSystem />,
            },
            {
              private: true,
              path: accessRoute.ADD_QUOTE_SYSTEM.path,
              name: 'Add Quote System',
             key: 'priceManagement.quote.management.quote',
              belongsToSidebar: false,
              // icon: accessRoute.ADD_QUOTE_SYSTEM.icon,
              element: <AddQuoteSystem />,
            },
            {
              private: true,
              path: accessRoute.EDIT_QUOTE_SYSTEM.path,
              name: 'Edit Quote System',
                key: 'priceManagement.quote.management.quote',
              belongsToSidebar: false,
              // icon: accessRoute.EDIT_QUOTE_SYSTEM.icon,
              element: <EditQuoteSystem />,
            },
            {
              private: true,
              path: accessRoute.QUOTE_IMAGE_VIEW.path,
              name: 'View Image',
               key: 'priceManagement.quote.management.quote',
              belongsToSidebar: false,
              // icon: accessRoute.QUOTE_IMAGE_VIEW.icon,
              element: <ViewImages />,
            },
            {
              private: true,
              path: accessRoute.QUOTE_ORDER.path,
              name: 'Quote Order & Admin Profit',
                key: 'priceManagement.quote.management.quote-order',
              belongsToSidebar: true,
              // icon: accessRoute.QUOTE_ORDER.icon,
              element: <ListQuoteOrder />,
            },
            {
              private: true,
              path: accessRoute.AMOUNT_CALCULATION.path,
              name: 'Amount Calculation',
                key: 'priceManagement.quote.management.amount-calculation',
              belongsToSidebar: true,
              // icon: accessRoute.AMOUNT_CALCULATION.icon,
              element: <ListAmountCalculation />,
            },
            {
              private: true,
              path: accessRoute.LIST_DISCOUNT.path,
              name: 'Discount',
               key: 'priceManagement.quote.management.discount',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_DISCOUNT.icon,
              element: <ListDiscount />,
            },
            {
              private: true,
              path: accessRoute.LIST_REBATE.path,
              name: 'Rebate',
               key: 'priceManagement.quote.management.rebate',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_REBATE.icon,
              element: <ListRebate />,
            },
            {
              private: true,
              path: accessRoute.LIST_HOUSE_LOCATION.path,
              name: 'Supply House Location',
                    key: 'priceManagement.quote.management.house-location',
              belongsToSidebar: true,
              // icon: accessRoute.LIST_HOUSE_LOCATION.icon,
              element: <ListHouseLocation />,
            },
            {
              private: true,
              path: accessRoute.ADD_HOUSE_LOCATION.path,
              name: 'Add Supply House Location',
                key: 'priceManagement.quote.management.house-location',
              belongsToSidebar: false,
              // icon: accessRoute.ADD_HOUSE_LOCATION.icon,
              element: <AddHouseLocation />,
            },
            {
              private: true,
              path: accessRoute.EDIT_HOUSE_LOCATION.path,
              name: 'Edit Supply House Location',
                key: 'priceManagement.quote.management.house-location',
              belongsToSidebar: false,
              // icon: accessRoute.EDIT_HOUSE_LOCATION.icon,
              element: <EditHouseLocation />,
            },
            {
              private: true,
              path: accessRoute.QUOTE_MANAGEMENT_ESTIMATES.path,
              name: 'Quote Management Estimates',
                key: 'priceManagement.quote.management.house-location',
              belongsToSidebar: false,
              // icon: accessRoute.QUOTE_MANAGEMENT_ESTIMATES.icon,
              element: <QuoteManagementEstimates />,
            },
            {
              private: true,
              name: 'Financing',
              key: 'priceManagement.quote.management.financing',
              belongsToSidebar: true,
              // icon: accessRoute.DASHBOARD.icon,
              element: <Outlet />,
              submenu: true,
              children: [
                {
                  private: true,
                  path: accessRoute.LIST_FINANCING_BANK.path,
                  name: 'Financing Bank',
              key: 'priceManagement.quote.management.financing.bank',
                  belongsToSidebar: true,
                  // icon: accessRoute.LIST_FINANCING_BANK.icon,
                  element: <ListBank />,
                },
                {
                  private: true,
                  path: accessRoute.ADD_FINANCING_BANK.path,
                  name: 'Add Financing Bank',
                  key: 'priceManagement.quote.management.financing.bank',
                  belongsToSidebar: false,
                  // icon: accessRoute.ADD_FINANCING_BANK.icon,
                  element: <AddBank />,
                },
                {
                  private: true,
                  path: accessRoute.EDIT_FINANCING_BANK.path,
                  name: 'Edit Financing Bank',
                 key: 'priceManagement.quote.management.financing.bank',
                  belongsToSidebar: false,
                  // icon: accessRoute.EDIT_FINANCING_BANK.icon,
                  element: <EditBank />,
                },
                {
                  private: true,
                  path: accessRoute.LIST_FINANCING_OPTION.path,
                  name: 'Financing Option',
                  key: 'priceManagement.quote.management.financing.finance-option',
                  belongsToSidebar: true,
                  // icon: accessRoute.LIST_FINANCING_OPTION.icon,
                  element: <ListOption />,
                },
              ]
            }
          ],
        },
      ],
    },
  ];
}

export const accessRoute = {
  List_SERVICE_CATEGORY: {
    path: '/admin/service-category',
    name: 'Service Category',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-coin-alt-fill" />
      </span>
    ),
  },
  ADD_SERVICE_CATEGORY: {
    path: '/admin/service-category/add',
    name: 'Add Service Category',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-coin-alt-fill" />
    //   </span>
    // ),
  },
  SERVICE_CATEGORY_REPORT: {
    path: '/admin/service-category/report',
    name: 'Service Category report',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-coin-alt-fill" />
    //   </span>
    // ),
  },
  EDIT_SERVICE_CATEGORY: {
    path: '/admin/service-category/Edit',
    name: 'Add Service Category',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-coin-alt-fill" />
    //   </span>
    // ),
  },
  LIST_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category',
    name: 'Service Category',
  },
  ADD_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category/add',
    name: 'Add Service Category',
  },
  EDIT_SUB_SERVICE_CATEGORY: {
    path: '/admin/subservice-category/edit',
    name: 'Add Service Category',
  },
  LIST_SERVICE: {
    path: '/admin/services-type',
    name: 'Service Type',
  },
  LINE_ITEMS: {
    path: '/admin/line-items',
    name: 'Line Items',
  },
  ADD_LINE_ITEMS: {
    path: '/admin/line-items/add',
    name: 'Add Line Items',
  },
  EDIT_LINE_ITEMS: {
    path: '/admin/line-items/edit',
    name: 'Edit Line Items',
  },
  UPLOAD_BULK_DATA: {
    path: '/admin/upload-data',
    name: 'Upload Bulk Data',
  },
  OPTIONAL_ITEMS: {
    path: '/admin/optional-items',
    name: 'Optional Items',
  },
  QUOTE_SYSTEM: {
    path: '/admin/quote-management',
    name: 'Quote System',
  },
  ADD_QUOTE_SYSTEM: {
    path: '/admin/quote-management/add',
    name: 'Add Quote System',
  },
  EDIT_QUOTE_SYSTEM: {
    path: '/admin/quote-management/edit',
    name: 'Edit Quote System',
  },
  QUOTE_IMAGE_VIEW: {
    path: '/admin/quote-management/view-image',
    name: 'View Image',
  },
  QUOTE_ORDER: {
    path: '/admin/quote-order',
    name: 'Quote Order & Admin Profit',
  },
  AMOUNT_CALCULATION: {
    path: '/admin/amount-calculation',
    name: 'Amount Calculation',
  },
  LIST_DISCOUNT: {
    path: '/admin/discount',
    name: 'Discount',
  },
  LIST_REBATE: {
    path: '/admin/rebate',
    name: 'Rebate',
  },
  LIST_HOUSE_LOCATION: {
    path: '/admin/equipment-location',
    name: 'Supply House Location',
  },
  ADD_HOUSE_LOCATION: {
    path: '/admin/equipment-location/add',
    name: 'Add Supply House Location',
  },
  EDIT_HOUSE_LOCATION: {
    path: '/admin/equipment-location/edit',
    name: 'Edit Supply House Location',
  },
  LIST_FINANCING_BANK: {
    path: '/admin/financing-bank',
    name: 'Financing Bank',
  },
  ADD_FINANCING_BANK: {
    path: '/admin/financing-bank/add',
    name: 'Add Financing Bank',
  },
  EDIT_FINANCING_BANK: {
    path: '/admin/financing-bank/edit',
    name: 'Edit Financing Bank',
  },
  LIST_FINANCING_OPTION: {
    path: '/admin/financing-option',
    name: 'Financing Option',
  },
  QUOTE_MANAGEMENT_ESTIMATES: {
    path: '/admin/estimates',
    name: 'Quote Management Estimates',
  }
};

/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
 return [...route()[0]?.children];
};
