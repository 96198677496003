import {  DateFilter, InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Image } from "antd";
import { Link } from "react-router-dom";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

const getColumnSelectDateProps = (dataIndex, dateType) => ({
  filterDropdown: (props) => (<DateFilter dateType={dateType} dataIndex={dataIndex} {...props}/> ),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});


const getProviderListData = () => {
  return [
    {
      name: 'abhishek Yadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditi Test',
      value: 'Aditi Test',
    },
  ];
};
const getEquipmentInstalledData = () => {
  return [
    {
      name: 'Air Conditioner',
      value: 'Air Conditioner',
    },
    {
      name: '80% Gas Furnace',
      value: '80% Gas Furnace',
    },
  ];
};
const getBrandData = () => {
  return [
    {
      name: 'Brand New',
      value: 'Brand New',
    },
    {
      name: 'Apple',
      value: 'Apple',
    },
  ];
};
const getModelData = () => {
  return [
    {
      name: '123',
      value: '123',
    },
    {
      name: '456',
      value: '456',
    },
  ];
};
const getSerialData = () => {
  return [
    {
      name: 'Test1',
      value: 'Test1',
    },
    {
      name: 'Test2',
      value: 'Test2',
    },
  ];
};
const getPartTitleData = () => {
    return [
        {
            name: 'Commercial',
            value: 'Commercial',
        },
        {
            name: 'Residential-Multi-Family',
            value: 'Residential-Multi-Family',
        },
    ];
}
const getTypePurchaseData = () => {
    return [
        {
            name: 'New',
            value: 'New',
        },
        {
            name: 'Replacement',
            value: 'Replacement',
        },
    ];
}
const getSystemConditionData = () => {
    return [
        {
            name: '	Average',
            value: '	Average',
        },
        {
            name: 'Good',
            value: 'Good',
        },
    ];
}
const logoFormatter = (path, text) => {
  return (
    <>
      {path ? (
        <div className="table-img">
          {/* <img src={path} alt="img" /> */}
          <Image
            width={200}
            src={path} alt="img"
          />
        </div>
      ) : (
        text
      )}
    </>
  );
};

const LinkFormatter = ( url , text) => {
  return (
      <>
          <Link to={url} className="text-primary">{text}</Link>
      </>
  );
};
export const HomeInventoryTableColumn = () => {
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
        dataIndex: "providerCompleted",
        title: "Provider Completed",
        headerClasses: "sorting",
        ...getColumnMultiSelectSearchProps("provider", getProviderListData()),
        sorter: true,
    },
    {
      dataIndex: "dateEquipmentAdded",
      title: "Date Equipment added",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("date", ""),
    },
    {
      dataIndex: "customerName",
      title: "Customer Name",
      headerClasses: "sorting",
      ...getColumnMultiSelectSearchProps("customer name", getProviderListData()),
      sorter: true,
    },
    {
        dataIndex: "customerNumber",
        title: "Customer Phone Number",
        headerClasses: "sorting",
        ...getColumnSearchProps("customer phone number"),
        sorter: true,
    },
    {
        dataIndex: "customerEmail",
        title: "Customer Email",
        headerClasses: "sorting",
        ...getColumnSearchProps("customer email"),
        sorter: true,
    },
    {
          dataIndex: "equipmentInstalled",
          title: "Equipment Installed",
          headerClasses: "sorting",
          ...getColumnMultiSelectSearchProps("equipment installed", getEquipmentInstalledData()),
          sorter: true,
    },
    {
      dataIndex: "brand",
      title: "Brand",
      headerClasses: "sorting",
      ...getColumnMultiSelectSearchProps("brand", getBrandData()),
      sorter: true,
    },
    {
      dataIndex: "model",
      title: "Model #",
      headerClasses: "sorting",
      ...getColumnMultiSelectSearchProps("model ", getModelData()),
      sorter: true,
    },
    {
      dataIndex: "serial",
      title: "Serial #",
      headerClasses: "sorting",
      ...getColumnMultiSelectSearchProps("serial ", getSerialData()),
      sorter: true,
    },
    {
      dataIndex: "installationDate",
      title: "Installation Date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("year", "year"),
    },
    {
      dataIndex: 'applicationType',
      title: 'Application Type',
      headerClasses: 'sorting',
      ...getColumnSelectSearchProps("application type", getPartTitleData()),
      sorter: true,
    },
    {
      dataIndex: 'typeOfPurchase',
      title: 'Type of Purchase',
      headerClasses: 'sorting',
      ...getColumnSelectSearchProps("type of purchase", getTypePurchaseData()),
      sorter: true,
    },
    {
        dataIndex: 'systemCondition',
        title: 'System Condition',
        headerClasses: 'sorting',
        ...getColumnSelectSearchProps("system condition", getSystemConditionData()),
        sorter: true,
    },
    {
        dataIndex: "systemNotes",
        title: "System Notes",
        headerClasses: "sorting",
        ...getColumnSearchProps("system notes"),
        sorter: true,
    },
    {
        dataIndex: "photosUploaded",
        title: "Links to photos uploaded",
        // render:(cell, row)=>{
        //   console.log("Details", cell)
        //   // return <>
        //   //   <Image
        //   //     width={200}
        //   //     src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        //   //   />
        //   // </>
        //   render: (cell, row) => logoFormatter(row?.iconImg,"Image"),
        // }
        render: (cell, row) => LinkFormatter(row?.photosUploaded,"View Images"),
    },
  ];
};
