import {Toggle, SelectFilter, InputFilter, DateFilter, DiscountFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Select } from "antd";

export const promoCodeTableColumn = ({ Action, handleJobTypeChange}) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectDateProps = (dataIndex) => ({
    filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const getColumnSelectDiscountProps = (dataIndex) => ({
    filterDropdown: (props) => (<DiscountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
      {
        name: 'Deleted',
        value: 'Deleted',
      },
    ];
  };

  const getValidityData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Permanent',
        value: 'Permanent',
      },
      {
        name: 'Custom',
        value: 'Custom',
      },
    ];
  };
  const selectActiveTypeData = [
    {
      name: 'Active',
      value: 'Active',
    },
  ]
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "giftCertificateCode",
      title: "Gift/Certificate Code",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("gift/certificate code")
    },
    {
      dataIndex: "discount",
      title: "Discount",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDiscountProps("discount")
    },
    {
        dataIndex: "companyName",
        title: "Name or Company Name",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("name or company name")
    },
    {
      dataIndex: "validity",
      title: "Validity",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("validity", getValidityData())
    },
    {
      dataIndex: "activationDate",
      title: "Activation Date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("")
    },
    {
        dataIndex: "expiryDate",
        title: "Expiry Date",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectDateProps("")
    },
    {
        dataIndex: "usageLimit",
        title: "Usage Limit",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("usage limit")
    },
      {
        dataIndex: "used",
        title: "Used",
        headerClasses: "sorting",
        render:(cell, row)=>{
            console.log("Details", cell)
            if(cell === "0"){ 
              return <>{cell} </>
            }else{
              if(cell !== "0"){
                return <>
                    <Link to="/admin/service">{cell}</Link>
                </>
              }
            }
        }
      },
      {
        dataIndex: "status",
        title: "Status",
        headerClasses: "sorting",
        render: () => <Toggle isDropDown shouldDeleteInclude /> ,
        ...getColumnSelectSearchProps("status", getStatusData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
