import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Notification({ t }) {
  return (
    <>
      <div className="dropdown-head">
        <span className="sub-title nk-dropdown-title">
          Notification
        </span>
        <Link to="#">Mark All as Read</Link>
      </div>
      <div className="dropdown-body">
        <div className="nk-notification">
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                You have requested to <span>Widthdrawl</span>
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                Your <span>Deposit Order</span> is placed
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                You have requested to <span>Widthdrawl</span>
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                Your <span>Deposit Order</span> is placed
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                You have requested to <span>Widthdrawl</span>
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
          <div className="nk-notification-item dropdown-inner">
            <div className="nk-notification-icon">
              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
            </div>
            <div className="nk-notification-content">
              <div className="nk-notification-text">
                Your <span>Deposit Order</span> is placed
              </div>
              <div className="nk-notification-time">2 hrs ago</div>
            </div>
          </div>
        </div>
      </div>
      <div className="dropdown-foot center">
        <Link to="#">View All</Link>
      </div>
    </>
  );
}

export default withTranslation()(Notification);
