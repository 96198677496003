export const text = {
  infoDescription: 'Information Description',
  infoUrl: 'Information Url',
  infoHeading: 'Important Information',
  financingDescription: 'Financing Description',
  financingInfoHeading: 'Financing Information',

  finDescriptionPlaceholder: 'Enter financing description',
  financeBankOne: 'Financing Bank1',
  financeBankTwo: 'Financing Bank2',
  financeBankUrlOne: 'Financing Bank1 Url',
  financeBankUrlTwo: 'Financing Bank2 Url',
  finBankUrlPlaceholder: 'Enter financing bank Url',
  finBankPlaceholder: 'Enter financing bank',
};
