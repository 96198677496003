import React from 'react';
import { Link } from 'react-router-dom';

export default function CsvImport() {
  return (
    <>
      <Link to="#" className="btn btn-white btn-outline-light">
        <em className="icon ni ni-upload-cloud" />
        <span>Import</span>
      </Link>
    </>
  );
}
