export const WillCallListServices = () => {
    return [
      {
        id: 1,
        icon: "/assets/images/admin/small-logo.svg",
        categoryName: "HVAC",
        subCategoryName: "Same Day Emergency Service1",
        locations:"Atlantic, Indianapolis, Indore",
      },
      {
        id: 2,
        icon: "/assets/images/admin/small-logo.svg",
        categoryName: "Free Delivery",
        subCategoryName: "Free Delivery",
        locations:"Indianapolis",
      },
      {
        id: 3,
        icon: "/assets/images/admin/small-logo.svg",
        categoryName: "Satellite",
        subCategoryName: "Satellite sub",
        locations:"All",
      },
      {
        id: 4,
        icon: "/assets/images/admin/small-logo.svg",
        categoryName: "HVAC",
        subCategoryName: "Same Day Emergency Service1",
        locations:"Atlantic, Indianapolis, Indore",
      },
      {
        id: 5,
        icon: "/assets/images/admin/small-logo.svg",
        categoryName: "Electrician",
        subCategoryName: "Service Repair",
        locations:"Indore",
      },
    ];
  };
  