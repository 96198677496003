import i18next from 'i18next';
import * as yup from 'yup';

export const initValues = () => {
  return {
    description: '',
    second_bank_name: '',
    second_bank_url: '',
    first_bank_name: '',
    first_bank_url: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    description: yup
      .string()
      .required(i18next.t('validation.appText.description')),
    first_bank_name: yup
      .string()
      .required(i18next.t('validation.appText.finBankName')),
    second_bank_name: yup
      .string()
      .required(i18next.t('validation.appText.finBankName')),
    first_bank_url: yup
      .string()
      .required(i18next.t('validation.appText.finBankUrl')),
    second_bank_url: yup
      .string()
      .required(i18next.t('validation.appText.finBankUrl')),
  });
};
