import {  AmountFilter, AntSelect, CommonButton, DateFilter, InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

const getColumnSelectDateProps = (dataIndex) => ({
  filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});
const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });


const getProviderListData = () => {
  return [
    {
      name: 'abhishek Yadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditi Test',
      value: 'Aditi Test',
    },
  ];
};
const getSupplyHouseNameData = () => {
  return [
    {
      name: 'IND House',
      value: 'IND House',
    },
    {
      name: 'MP House',
      value: 'MP House',
    },
  ];
};
const getFloodZoneData = () => {
  return [
    {
      name: 'Yes',
      value: 'Yes',
    },
    {
      name: 'No',
      value: 'No',
    },
  ];
};
const getPermitStatus = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'Needs Attention',
    },
    {
      name: 'Requested',
      value: 'Requested',
    },
    
    {
      name: 'Completed',
      value: 'Completed',
    },
    {
    name: 'N/A',
    value: 'NA',
  },
  ];
};
const getNeedInstallPermitData = () => {
  return [
    {
        id: 'Yes',
        name: 'Yes ',
      },
      {
        id: 'No',
        name: 'No',
      }
  ];
};
const getCityCountryData = () => {
    return [
        {
            id: ' Indore ',
            name: 'Indore',
          },
          {
            id: 'Bhopal  ',
            name: 'Bhopal',
          },
          {
            id: 'Dewas',
            name: 'Dewas',
          },
    ];
}
const getOrderedData = () => {
    return [
        {
            id: 'Needs Attention ',
            name: 'Needs Attention ',
          },
          {
            id: 'Ordered  ',
            name: 'Ordered',
          },
          {
            id: 'Will Call',
            name: 'Will Call',
          },
          {
            id: 'Picked Up  ',
            name: 'Picked Up',
          },
          {
            id: 'Staged',
            name: 'Staged',
          },
          {
            id: 'Installed  ',
            name: 'Installed',
          },
    ];
}

const ButtonFormatter = (url, text) => {
    return (
        <>
        <Link to={url} className="">{text}</Link>
        </>
    );
};

const ModalOpenFormatter = ( url , text) => {
  return (
      <>
          <Link to="#" onClick={url} className="text-primary">{text}</Link>
      </>
  );
};

  const PermitStatus = [
    {
      id: ' needsAttention ',
      name: ' Needs Attention ',
    },
    {
      id: ' requested ',
      name: ' Requested ',
    },
    {
      id: 'completed',
      name: 'completed',
    },
  ];
  const orderedData = [
    {
        id: 'Needs Attention',
        name: 'Needs Attention',
      },
      {
        id: 'Will Call  ',
        name: 'Will Call',
      },
      {
        id: 'Staged',
        name: 'Staged',
      },

  ]
export const EquipmentOrderTableColumn = ({saveUpdates, cancelUpdates, equipmentModalShow, materialListModalShow}) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'jobNumber',
            title: 'Job No',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ButtonFormatter("/admin/equipment-order/booking-log", "75833147"),
            ...getColumnSearchProps("job number"),
        },
        {
            dataIndex: 'serviceProviderName',
            title: 'Service Provider Name',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("service provider name", getProviderListData()),
        },
        {
            dataIndex: "serviceProviderNumber",
            title: "Service Provider Number",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer phone number"),
            sorter: true,
        },
        {
          dataIndex: "customerName",
          title: "Customer Name",
          headerClasses: "sorting",
          ...getColumnMultiSelectSearchProps("customer name", getProviderListData()),
          sorter: true,
        },
        {
            dataIndex: "customerNumber",
            title: "Customer Number",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer phone number"),
            sorter: true,
        },
        {
            dataIndex: "customerAddress",
            title: "Customer Address",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer address"),
            sorter: true,
        },
        {
            dataIndex: "needInstallPermit",
            title: "Need Install Permit",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("need install permit", getNeedInstallPermitData()),
            sorter: true,
        },
        {
            dataIndex: "CityCountyName",
            title: "City/County Name",
            headerClasses: "sorting",
            ...getColumnMultiSelectSearchProps("city/county name", getCityCountryData()),
            sorter: true,
        },
        {
            dataIndex: "floodZone",
            title: "Flood Zone",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("flood zone", getFloodZoneData()),
            sorter: true,
        },
        {
            dataIndex: "amountJobApprovedFor",
            title: "Amount Job Approved For",
            headerClasses: "sorting",
            ...getColumnSelectAmountProps(),
            sorter: true,
        },
        {
            dataIndex: "supplyHouseName",
            title: "Supply House Name",
            headerClasses: "sorting",
            ...getColumnMultiSelectSearchProps("supply house name", getSupplyHouseNameData()),
            sorter: true,
        },
        {
            dataIndex: "supplyHouseLocation",
            title: "Supply House Location",
            headerClasses: "sorting",
            ...getColumnSearchProps("supply house location"),
            sorter: true,
        },
        {
            dataIndex: "equipmentPickupDate",
            title: "Equipment Pickup Date",
            headerClasses: "sorting",
            ...getColumnSelectDateProps("date"),
            sorter: true,
        },
        {
            dataIndex: "equipment",
            title: "Equipment",
            headerClasses: "sorting",
            sorter: true,
            render: (cell, row) => ModalOpenFormatter(equipmentModalShow, "Link"),
        },
        {
            dataIndex: "materialList",
            title: "Material List",
            headerClasses: "sorting",
            sorter: true,
            render: (cell, row) => ModalOpenFormatter(materialListModalShow, "Link"),
        },
        {
            dataIndex: "otherNotes",
            title: "Other Notes",
            headerClasses: "sorting",
            ...getColumnSearchProps("other notes"),
            sorter: true,
        },
        {
            dataIndex: "permitStatus",
            title: "Permit Status",
            headerClasses: "sorting",
            sorter: true,
            ...getColumnSelectSearchProps("permit status", getPermitStatus()),
            render:(cell, row)=>{
                console.log("Details", cell)
                return <>
                    <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select status"
                        options={PermitStatus}
                    />
              </>
            }
        },
        {
            dataIndex: "ordered",
            title: "Ordered",
            headerClasses: "sorting",
            sorter: true,
            ...getColumnSelectSearchProps("ordered status", getOrderedData()),
            render:(cell, row)=>{
                console.log("Details", cell)
                return <>
                    <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select options"
                        options={orderedData}
                    />
              </>
            }
        },
        {
            dataIndex: "lastUpdatedSave",
            title: "Last Updated/Save",
            render:(cell, row)=>{
                console.log("Details", cell)
                if(cell !== ""){ 
                  return <>{cell} </>
                }else{
                  if(cell=== ""){
                    return <> <CommonButton onClick={ saveUpdates } className="btn btn-primary btn-sm">Save</CommonButton>
                        <CommonButton onClick={cancelUpdates} className="btn btn-secondary btn-sm ms-1">Cancel</CommonButton>
                    </>
                  }
                }
              }

        },
      ];
};
