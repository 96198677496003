import React, {useState} from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  SweetAlert,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
} from "../../../components";
import {
  Columns,
   tagsService,
} from "../../../services/index.service";
import { Link } from "react-router-dom";
import { modalNotification } from "../../../utils";

function ListTag(props) {
  const EquipmentOrderData = tagsService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Tags Master",
    },
  ];

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.TagTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{ editTagsShow()},
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
  });
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Tag Deleted Successfully", })
        return true;
    };
    const [addTags, setAddTags] = useState(false);
    const addTagsHide = () =>{
      setAddTags(false)
    }
    const addTagsShow = () =>{
      setAddTags(true)
    };
    const [editTags, setEditTags] = useState(false);
    const editTagsHide = () =>{
      setEditTags(false)
    }
    const editTagsShow = () =>{
      setEditTags(true)
    };
    const TagTypeData = [
      {
        name: 'Customer',
        value: 'Customer',
      },
      {
        name: 'Job',
        value: 'Job',
      },
    ];
    const statusActionOption = [
      {
        id: 1,
        name: 'Select Action'
      },
      {
        id: 2,
        name: 'Make Active'
      },
      {
        id: 3,
        name: 'Make Inactive'
      },
    ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Tags Master">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            btnText="Add Tag"
            onHandleShow={addTagsShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Tag"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
       <ModalComponent
        backdrop
        show={addTags}
        onHandleCancel={addTagsHide}
        title="Add Tags Action"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
          md={6}
          label="Tag Type"
          mandatory={true}
          element={(formik) => (
            <Select
              name="TagType"
              placeholder="Select tag type"
              options={TagTypeData}
              showSearch
              formik={formik}
            />
          )}
        />
      <FormElementWrapper
            md={6}
            label="Tag"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="Tag"
                placeholder="Enter tag name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addTagsHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addTagsHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editTags}
        onHandleCancel={editTagsHide}
        title="Edit Tags Action"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
          md={6}
          label="Tag Type"
          mandatory={true}
          element={(formik) => (
            <Select
              name="TagType"
              placeholder="Select tag type"
              options={TagTypeData}
              // showSearch
              formik={formik}
              defaultValue="Customer"
            />
          )}
        />
      <FormElementWrapper
            md={6}
            label="Tag"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="Tag"
                placeholder="Enter tag name"
                formik={formik}
                defaultValue="Air Cleaner"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-1 me-sm-2"
                  onClick={() => editTagsHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-1 me-sm-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editTagsHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>    
    </>
  );
}

export default ListTag;
