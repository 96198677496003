import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarWrapper } from '../../components';
import { SidebarFeature } from '../../features/index.feature';
import { getSidebarItems } from '../../routes/index.routes';
import { AppLayout } from '../index.layout';

function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const menuToggle = () => {
    setSidebarOpen(!sidebarOpen);
    const list = document.querySelector('body');
    const screen = window?.screen;
    if(screen?.width < 1200){
      list?.classList.toggle('nav-shown');
    }else{
      list?.classList.remove('nav-shown');
    }
  };

  return (
    <SidebarWrapper
      routes={getSidebarItems}
      sidebarOpen={sidebarOpen}
      menuToggle={menuToggle}
      nav={
        <SidebarFeature
          sidebarOpen={sidebarOpen}
          menuToggle={menuToggle}
          routes={getSidebarItems}
        />
      }
    >
      {/* <AppLayout> */}
      <Outlet />
      {/* </AppLayout> */}
    </SidebarWrapper>
  );
}

export default React.memo(AdminLayout);
