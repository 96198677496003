import React from 'react';
import { Link } from 'react-router-dom';
import {
  AntSelect,
  Breadcrumb,
  InputType,
  PageHeader,
  TextEditor,
} from '../../../../components';

function SendNotification(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Send Push Notification',
    },
  ];
  const userData = [
    {
      id: '1',
      name: 'All Providers',
    },
    {
      id: '2',
      name: 'All Users',
    },
    {
      id: '3',
      name: 'All Logged in Providers',
    },
    {
      id: '4',
      name: 'All Logged in Users',
    },
    {
      id: '5',
      name: 'All Inactive Providers',
    },
    {
      id: '6',
      name: 'All Inactive Users',
    },
  ];
  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Send Push Notification">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Select User Type <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        id="status"
                        size="medium"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select user type"
                        options={userData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Select Providers <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Select providers"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Message <span className="text-danger"> *</span>
                      </label>
                      {/* <TextEditor
                        placeholder="Enter message"
                        value=""
                      /> */}
                      <textarea
                      name="textarea"
                      placeholder="Enter message"                     
                      className="form-control"
                    />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary">
                      Send Notification
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendNotification;
