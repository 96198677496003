import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from '../../../../components';

function ServiceProviderSubLaborPayDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service-provider-payment',
      name: 'Service Provider Payment Report',
    },
    {
      path: '#',
      name: 'Sublabor Pay Details',
    },
  ];
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      sorter: true,
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'sublaborPayName',
      title: 'Sublabor Pay Name',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'total',
      title: 'Total',
      sorter: true,
      headerClasses: 'sorting',
    },
  ];
  const adminData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "HVAC - Same Day Emergency Service1",
      servicePrice: "$99.95",
      sublaborPayName: "akash provider",
      total: "$5.00"
    },
    {
      id: 2,
      jobNumber: "75833148",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$0.00",
      sublaborPayName: "akash provider",
      total: "$50.00"
    },
    {
      id: 3,
      jobNumber: "27881123",
      providerName: "Ron Test",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$10.00",
      sublaborPayName: "akash provider",
      total: "$500.00"
    },
    {
      id: 4,
      jobNumber: "36974681",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$20.00",
      sublaborPayName: "akash provider",
      total: "$55.00"
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Sublabor Pay Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/service-provider-payment')}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
    </>
  );
}

export default ServiceProviderSubLaborPayDetails;
