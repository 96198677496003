import React from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from '../../../../components';

function ProviderInvoiceUpdateDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/payment-report',
      name: 'Payment Report',
    },
    {
      path: '#',
      name: 'Provider Invoice Update Details',
    },
  ];
  const columns = [
    {
      dataIndex: 'id',
      title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
       sorter: true,
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
       sorter: true,
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
       sorter: true,
    },
    {
      dataIndex: 'purchaseOrderSubLaborReturned',
      title: 'Purchase Order / Sub-Labor / Returned',
       sorter: true,
    },
    {
      dataIndex: 'name',
      title: 'Name (Purchase Order / Sub-Labor / Returned)',
       sorter: true,
    },
    {
      dataIndex: 'total',
      title: 'Total',
       sorter: true,
    },
    {
      dataIndex: 'goodGuyFee',
      title: 'Good Guy fee',
       sorter: true,
    },
    {
      dataIndex: 'earnedAmount',
      title: 'Earned Amount',
       sorter: true,
    },
    {
      dataIndex: 'dateTime',
      title: 'DateTime',
       sorter: true,
    },
  ];
  const adminData = [
    {
      id: 1,
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00",
      purchaseOrderSubLaborReturned: 'Purchase Order',
      name: "trgj86433",
      total: "$ 400.00",
      goodGuyFee: "$0.00",
      earnedAmount: '	$4700.00',
      dateTime: 'Wed, November 16, 2022 04:20 AM'
    },
    {
      id: 2,
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00",
      purchaseOrderSubLaborReturned: 'Returned Amount',
      name: "flu7544",
      total: "$ 400.00",
      goodGuyFee: "$0.00",
      earnedAmount: '	$4700.00',
      dateTime: 'Wed, November 16, 2022 04:20 AM'
    },
    {
      id: 3,
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00",
      purchaseOrderSubLaborReturned: 'Sub-Labor Pay',
      name: "testing",
      total: "$ 400.00",
      goodGuyFee: "$0.00",
      earnedAmount: '	$4700.00',
      dateTime: 'Wed, November 16, 2022 04:20 AM'
    }
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Provider Invoice Update Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/payment-report')}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        showAction={false}

      />
    </>
  );
}

export default ProviderInvoiceUpdateDetails;
