
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import {  Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Charts,
  CommonButton,
  TablePlus,
  FormWrapper,
  FormElementWrapper,
  AntTextArea,
  ServiceProviderPaymentFilterForm,
  Select,
  DatePicker,
  TextInput,
  Tabs,
} from '../../../../components';
import { Columns } from '../../../../services/index.service';
import { PaymentReportServices } from '../../../../services/PaymentReport';


function PaymentReport(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Payment Report',
    },
  ];
  const [MarkSetteledModal, setMarkSetteledModal] = useState(false);
  const MarkSetteledModalShow = () => {
    setMarkSetteledModal(true);
  };
  const MarkSetteledModalHide = () => {
    setMarkSetteledModal(false);
  };
  const [checkPaymentMethodModal, setCheckPaymentMethodModal] = useState(false);
  const checkPaymentMethodModalShow = () => {
    setCheckPaymentMethodModal(true);
  };
  const checkPaymentMethodModalHide = () => {
    setCheckPaymentMethodModal(false);
  };
  const [FinancingPaymentMethodModal, setFinancingPaymentMethodModal] = useState(false);
  const FinancingPaymentMethodModalShow = () => {
    setFinancingPaymentMethodModal(true);
  };
  const FinancingPaymentMethodModalHide = () => {
    setFinancingPaymentMethodModal(false);
  };
  const [WarrantyPaymentMethodModal, setWarrantyPaymentMethodModal] = useState(false);
  const WarrantyPaymentMethodModalShow = () => {
    setWarrantyPaymentMethodModal(true);
  };
  const WarrantyPaymentMethodModalHide = () => {
    setWarrantyPaymentMethodModal(false);
  };
  const navigate = useNavigate()
  const PaymentMethodPage = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/payment-method');
  }
  // const AdminPaymentMethodPage = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/admin-payment-method');
  // }
  const InvoicePage = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/invoice'); 
  }
  // const JobProviderDiscountAdd = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/job-provider-discount');
  // }
  // const PurchaseOrderTotal = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/purchase-order');
  // }
  // const PurchaseOrderTotalAdd = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/purchase-order-add');
  // }
  // const SubLaborPayDetails = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/sublabor-pay');
  // }
  // const SubLaborPayDetailsAdd = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/sublabor-pay-add');
  // }
  // const ReturnAmountDetails = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/return-amount');
  // }
  // const ReturnAmountDetailsAdd = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/payment-report/return-amount-add');
  // }
  // const SplitPaymentDetails = (e) => {
  //   e.preventDefault();
  //   navigate('/admin/payment-report/split-payment');
  // }
  const ProviderInvoiceUpdateDetails = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report/provider-invoice-update');
  }

  {/* Job Provider Discount Amount Modal */}
  const [jobProviderModal, setJobProviderModal] = useState(false);
  const jobProviderModalShow = () => {
    setJobProviderModal(true);
  };
  const jobProviderModalHide = () => {
    setJobProviderModal(false);
  };

    {/* Job Provider Discount Amount Modal */}
    const [purchaseOrderModal, setPurchaseOrderModal] = useState(false);
    const purchaseOrderModalShow = () => {
      setPurchaseOrderModal(true);
    };
    const purchaseOrderModalHide = () => {
      setPurchaseOrderModal(false);
    };

    {/* Sub labor Discount Amount Modal */}
    const [sublaborModal, setSublaborModal] = useState(false);
    const sublaborModalShow = () => {
      setSublaborModal(true);
    };
    const sublaborModalHide = () => {
      setSublaborModal(false);
    };

    {/* Return Amount Modal */}
    const [returnAmountModal, setReturnAmountModal] = useState(false);
    const returnAmountModalShow = () => {
      setReturnAmountModal(true);
    };
    const returnAmountModalHide = () => {
      setReturnAmountModal(false);
    };

    {/* Split Payment Modal */}
    const [splitPaymentModal, setSplitPaymentModal] = useState(false);
    const splitPaymentModalShow = () => {
      setSplitPaymentModal(true);
    };
    const splitPaymentModalHide = () => {
      setSplitPaymentModal(false);
    };

     {/* Split Payment Modal */}
     const [paymentOptionModal, setPaymentOptionModal] = useState(false);
     const paymentOptionModalShow = () => {
       setPaymentOptionModal(true);
     };
     const paymentOptionModalHide = () => {
       setPaymentOptionModal(false);
     };

    
  const jobData = {
    labels: [
      'Jan 21',
      'Feb 21',
      'Mar 21',
      'Apr 21',
      'May 21',
      'Jun 21',
      'Jul 21',
      'Aug 21',
      'Sep 21',
      'Oct 21',
      'Nov 21',
      'Dec 21',
    ],
    datasets: [
      {
        label: 'COLLECTED',
        data: [9, 20, 15,17,22,17,12,18,13,11,3,7],
        backgroundColor: [
          '#1EE0AC',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'UNCOLLECTED',
        data: [19, 13, 17,12,8,9,11,16,17,22,5,11,10],
        backgroundColor: [
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const [totalJobAmountTable, setTotalJobAmountTable] = useState(false);
  const PaymentReportData = PaymentReportServices();
  // const columns = Columns.PaymentReportTableColumn({JobProviderDiscountAdd,PurchaseOrderTotalAdd,PurchaseOrderTotal,SubLaborPayDetailsAdd,SubLaborPayDetails,ReturnAmountDetailsAdd,ReturnAmountDetails,PaymentMethodPage,SplitPaymentDetails,ProviderInvoiceUpdateDetails,InvoicePage,checkPaymentMethodModalShow,FinancingPaymentMethodModalShow,WarrantyPaymentMethodModalShow,AdminPaymentMethodPage,jobProviderModalShow});
  const columns = Columns.PaymentReportTableColumn({PaymentMethodPage,ProviderInvoiceUpdateDetails,InvoicePage,checkPaymentMethodModalShow,FinancingPaymentMethodModalShow,WarrantyPaymentMethodModalShow,jobProviderModalShow, purchaseOrderModalShow,sublaborModalShow,returnAmountModalShow, splitPaymentModalShow, paymentOptionModalShow});
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  const [ShowHideGraph, setShowHideGraph] = useState(false)
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  }
  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
    }
  }

  {/* Job Provider Discount Table Data Column */}
  const jobcolumns = [
    {
      dataIndex: 'id',
      title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
      sorter: true,
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
      sorter: true,
    },
    {
      dataIndex: 'discount',
      title: 'Discount',
      sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> 
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                element={(formik) => (
                <TextInput
                  name="discount"
                  placeholder="Enter discount"
                  formik={formik}
                  type="number"
                />
              )}
            />
          </FormWrapper>
        </>
      }
    },
    {
      dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
      sorter: true,
    },
  ];
  const jobproviderData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      discount: "$",
      paymentTransferStatus: "Pending",
    },
  ];

  {/* Purchase Table Data Column */}
  const purchaseordercolumns = [
    {
      dataIndex: 'id',
      title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
       sorter: true,
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
       sorter: true,
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
       sorter: true,
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
       sorter: true,
    },
    {
      dataIndex: 'name',
      title: 'Name',
       sorter: true,
    },
    {
      dataIndex: 'totalFees',
      title: 'Total Fees',
       sorter: true,
    },
    {
      dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
       sorter: true,
    },
  ];
  const purchaseorderData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      name: "",
      totalFees: "",
      paymentTransferStatus: "Pending",
    },
  ];

   {/* Sub labor Table Data Column */}
  const sublaborcolumns = [
    {
      dataIndex: 'id',
       title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
       title: 'Job No',
      sorter: true,
    },
    {
      dataIndex: 'providerName',
       title: 'Provider Name',
      sorter: true,
    },
    {
      dataIndex: 'serviceType',
       title: 'Service Type',
      sorter: true,
    },
    {
      dataIndex: 'servicePrice',
       title: 'Service Price',
      sorter: true,
    },
    {
      dataIndex: 'name',
       title: 'Name',
      sorter: true,
    },
    {
      dataIndex: 'totalFees',
       title: 'Total Fees',
      sorter: true,
    },
    {
      dataIndex: 'paymentTransferStatus',
       title: 'Payment Transfer Status',
      sorter: true,
    },
  ];

  const sublaborData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      name: "",
      totalFees: "",
      paymentTransferStatus: "Pending",
    },
  ];

  {/* Return Amount Table Data Column */}
  const returnamountcolumns = [
    {
      dataIndex: 'id',
     title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
     title: 'Job No',
      sorter: true,
    },
    {
      dataIndex: 'providerName',
     title: 'Provider Name',
      sorter: true,
    },
    {
      dataIndex: 'serviceType',
     title: 'Service Type',
      sorter: true,
    },
    {
      dataIndex: 'servicePrice',
     title: 'Service Price',
      sorter: true,
    },
    {
      dataIndex: 'name',
     title: 'Name',
      sorter: true,
    },
    {
      dataIndex: 'totalFees',
     title: 'Total Fees',
      sorter: true,
    },
    {
      dataIndex: 'paymentTransferStatus',
     title: 'Payment Transfer Status',
      sorter: true,
    },
  ];
  const returnamountData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      name: "",
      totalFees: "",
      paymentTransferStatus: "Pending",
    },
  ];

    {/* Split Payment Table Data Column */}
  const splitpaymentcolumns = [
    {
      dataIndex: 'id',
      title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
       sorter: true,
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
       sorter: true,
    },
    {
      dataIndex: 'customerName',
      title: 'Customer Name',
       sorter: true,
    },
    {
      dataIndex: 'paidBy',
      title: 'Paid By',
       sorter: true,
    },
    {
      dataIndex: 'jobTotalAmount',
      title: 'Job Total Amount',
       sorter: true,
    },
    {
      dataIndex: 'splitPayment',
      title: 'Split Payment',
       sorter: true,
    },
    {
      dataIndex: 'paymentMethod',
      title: 'Payment Method',
       sorter: true,
    },
    {
      dataIndex: 'bankName',
      title: 'Bank Name',
       sorter: true,
    },
    {
      dataIndex: 'CheckFinancingNo',
      title: 'Check or Financing No',
       sorter: true,
    },
    {
      dataIndex: 'jobPaymentStatus',
      title: 'Job Payment Status',
       sorter: true,
    },
  ];

  /* Payment Method Tab Data */
  const [defaultKey, setDefaultKey] = useState('check');
  const monthsData = [
    {
      name: '01',
      value: '01',
    },
    {
      name: '02',
      value: '02',
    },
    {
    name: '03',
    value: '03',
    },
    {
    name: '04',
    value: '04',
    },
  ]
  const yearsData = [
    {
      name: '2019',
      value: '2019',
    },
    {
      name: '2020',
      value: '2020',
    },
    {
    name: '2021',
    value: '2021',
    },
    {
    name: '2022',
    value: '2022',
    },
  ]
  const tabContent = [
    {
      name: 'Check',
      key: 'check',
      content: (
        <div className='card shadow-none'>
            <div className='card-inner px-0 pb-0'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Bank Name"
                        element={(formik) => (
                            <TextInput
                            name="bankName"
                            placeholder="Enter bank name"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Check Number"
                        element={(formik) => (
                            <TextInput
                            name="CheckNumber"
                            placeholder="Enter check number"
                            formik={formik}
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
      ),
    },
    {
      name: 'Financing',
      key: 'financing',
      content: (
        <div className='card shadow-none'>
            <div className='card-inner px-0 pb-0'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                                name="amountToPay"
                                placeholder="Enter amount to pay"
                                formik={formik}
                                type="number"
                                defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Bank Name"
                        element={(formik) => (
                            <TextInput
                            name="bankName"
                            placeholder="Enter bank name"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Financing Confirmation Number"
                        element={(formik) => (
                            <TextInput
                            name="CheckNumber"
                            placeholder="Enter financing confirmation number"
                            formik={formik}
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
      ),
    },
    {
        name: 'Credit Card',
        key: 'creditCard',
        content: (
        <div className='card shadow-none'>
            <div className='card-inner  px-0 pb-0'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Card Holder"
                        element={(formik) => (
                            <TextInput
                            name="cardHolder"
                            placeholder="Enter card holder"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label=" Your Card Number"
                        element={(formik) => (
                            <TextInput
                            name="YourCardNumber"
                            placeholder="Enter your card number"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Exp. Month"
                        element={(formik) => (
                            <Select
                            name="expMonth"
                            placeholder="Select exp. month"
                            options={monthsData}
                            showSearch
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Exp. Year"
                        element={(formik) => (
                            <Select
                            name="expYear"
                            placeholder="Select exp. year"
                            options={yearsData}
                            showSearch
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="CVV"
                        element={(formik) => (
                            <TextInput
                            name="CVV"
                            placeholder="Enter cvv"
                            formik={formik}
                            type="number"
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
      {
        name: 'Cash',
        key: 'cash',
        content: (
        <div className='card shadow-none'>
            <div className='card-inner px-0 pb-0'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <h6 className='title text-primary mb-0'>Collecting Cash</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
      {
        name: 'Warranty/No Charge',
        key: 'warrantyNoCharge',
        content: (
            <div className='card shadow-none'>
            <div className='card-inner  px-0 pb-0'>
                    <p>This would allow admin to close out the job if the job has a zero for total.</p>
                    <h6 className='title text-primary mb-0'>Warranty/No Charge</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
            </div>
        </div>
        ),
      },
      {
        name: 'Admin Waive Cost',
        key: 'adminWaiveCost',
        content: (
            <div className='card shadow-none'>
            <div className='card-inner  px-0 pb-0'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <h6 className='title text-primary mb-0'>Admin Waived</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
  ];


  const splitpaymentData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      customerName: "Shane Watson",
      paidBy: "Provider",
      jobTotalAmount: "$5400.00",
      splitPayment: "$400.00",
      paymentMethod: "Card",
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus : 'Complete'
    },
    {
      id: 2,
      jobNumber: "75833147",
      providerName: "jorge neon",
      customerName: "Shane Watson",
      paidBy: "Provider",
      jobTotalAmount: "$5400.00",
      splitPayment: "$400.00",
      paymentMethod: "Card",
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus : 'Complete'
    },
  ];


  const statusActionOption = [
     {
     id: 1,
     name: 'Select Action'
     },
     {
     id: 2,
     name: 'Edit'
     },
     {
      id: 3,
      name: 'Delete'
      }
     ]
     
    
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Payment Report">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport', '']}
            btnText="Show / Hide Graph"
            onHandleShow={ () => ShowHideGraphShow() }
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
        
                <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="state"
                        placeholder="Select location"
                        options={locationData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
      </div>
      {
        ShowHideGraph &&
        <div className='card mb-3'>
          <div className='card-inner'>
            <div className='d-lg-flex align-items-center'>
              <div style={{ maxWidth: '1100px', width: '100%', height: '350px'}}>
                <Charts type="bar" data={jobData} options={ chartOptionsData}/>
              </div>
              <ul className="nk-store-statistics mt-0 mt-lg-4 ms-lg-4">
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">COLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                      $20115773.28
                      <span className="change down">
                        <em className="ni ni-arrow-long-down"></em>2696 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-info-dim ni ni-activity-round"></em>
                </li>
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">UNCOLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                    $751732.75
                      <span className="change up">
                        <em className="ni ni-arrow-long-up"></em>534 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-purple-dim ni ni-activity-round"></em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      <div className="tableScrollHide">
        <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={PaymentReportData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          selectRow={false}
          scrollTopPosition
          isSearch={false}
          showAction={false}
          scroll={{ y: 700 }}
        />
      </div>
      <div className='text-end my-4 d-flex align-items-start flex-wrap justify-content-end'>
          <CommonButton className="btn btn-primary mb-2 mb-sm-0" onClick= { () => setTotalJobAmountTable(!totalJobAmountTable) }>Total Jobs Amount Details</CommonButton>
          <CommonButton className="btn btn-secondary ms-2" onClick = { () => MarkSetteledModalShow() }>Mark As Settled</CommonButton>
      </div>
      {
        totalJobAmountTable && 
        <>
        <div className='nk-block'>
          <div className="card">
            <div className="card-inner">
              <h5 className="title mb-4">Total Jobs Amount Details</h5>
                <div className='table-responsive'>
                  <Table bordered size="md">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Services</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Earning from Customer </td>
                        <td>$5,400.00</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Total Platform fees collected from service provider </td>
                        <td>$0.00</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Total Promo Discount  </td>
                        <td>$ 0.00</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Total Provider Discount Amount (included) </td>
                        <td>$ 8.00</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Total Provider Payment  </td>
                        <td>$ 4700.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
            </div>
          </div>
        </div>
        </>
      }
      <ModalComponent
        backdrop
        show={checkPaymentMethodModal}
        onHandleCancel={checkPaymentMethodModalHide}
        title="Check Details"
      >
        <ul className="list list-sm list-checked">
          <li><b>Bank Name:- </b> chek payment</li>
          <li><b>Check Number:- </b> 159</li>
        </ul>
        <div className='text-end'>
          <CommonButton className="btn btn-secondary" onClick= { () => checkPaymentMethodModalHide() }>Close</CommonButton>
        </div>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={FinancingPaymentMethodModal}
        onHandleCancel={FinancingPaymentMethodModalHide}
        title="Financing Details"
      >
        <ul className="list list-sm list-checked">
          <li><b>Bank Name:- </b> Test</li>
          <li><b>Check Number:- </b> 8555</li>
        </ul>
        <div className='text-end'>
          <CommonButton className="btn btn-secondary" onClick= { () => FinancingPaymentMethodModalHide() }>Close</CommonButton>
        </div>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={WarrantyPaymentMethodModal}
        onHandleCancel={WarrantyPaymentMethodModalHide}
        title="Warranty/No Charge Reason"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={12}
            element={(formik) => (
              <AntTextArea
              name="warranty"
              className="form-control"
              placeholder="warranty/no charge reason"
              defaultValue="test"
            />
            )}
          />
        </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={MarkSetteledModal}
        onHandleCancel={MarkSetteledModalHide}
        title="Pay to Provider(s) ?"
      >
        <p>Are you sure you want to Pay To Provider(s)?</p>
        <div className='text-end'>
          <CommonButton className="btn btn-primary" onClick= { () => MarkSetteledModalHide() }>Yes</CommonButton>
          <CommonButton className="btn btn-light ms-2" onClick= { () => MarkSetteledModalHide() }>Not Now</CommonButton>
        </div>
      </ModalComponent>

      {/* Job Provider Discount Amount Modal */}
      <ModalComponent
        backdrop
        show={jobProviderModal}
        onHandleCancel={jobProviderModalHide}
        title="Provider Discount Details" extraClassName="modal-xxl"
      ><TablePlus
      onModal
      hasLimit
      noOfPage="1"
      sizePerPage="10"
      page="1"
      count="100"
      tableData={jobproviderData}
      tableColumns={jobcolumns}
      // tableLoader={isLoading}
      setSizePerPage=""
      selectRow={false}
      isSearch={false}
      showAction={false}
    />
        <div className='text-end'>
          <CommonButton className="btn btn-primary" onClick={ () => jobProviderModalHide() }>Submit</CommonButton>
        </div>
      </ModalComponent>

      {/* Purchase Order Modal */}
      <ModalComponent
        backdrop
        show={purchaseOrderModal}
        onHandleCancel={purchaseOrderModalHide}
        title="Purchase Order Details" extraClassName="modal-xxl"
      ><TablePlus
      onModal
      hasLimit
      noOfPage="1"
      sizePerPage="10"
      page="1"
      count="100"
      tableData={purchaseorderData}
      tableColumns={purchaseordercolumns}
      statusAction
      statusActionOption={statusActionOption}    
      // tableLoader={isLoading}
      setSizePerPage=""
      selectRow={true}
      isSearch={false}
      showAction={false}
    />
        <div className='text-end'>
        <CommonButton className="btn btn-primary">Add </CommonButton>
            <CommonButton className="btn btn-success ms-2">Submit</CommonButton>
        </div>
      </ModalComponent>

      {/* Sublabor Total Fees Modal */}
      <ModalComponent
              backdrop
              show={sublaborModal}
              onHandleCancel={sublaborModalHide}
              title="Sub-Labor Pay Details" extraClassName="modal-xxl"
            ><TablePlus
            onModal
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={sublaborData}
            tableColumns={sublaborcolumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={true}
            showAction={false}
           statusAction
           statusActionOption={statusActionOption}          
            
          />
           <div className="text-end">
            <CommonButton className="btn btn-primary">Add </CommonButton>
            <CommonButton className="btn btn-success ms-2">Submit</CommonButton>
          </div>
      </ModalComponent>

      {/* Return Amount Add Modal */}
      <ModalComponent
              backdrop
              show={returnAmountModal}
              onHandleCancel={returnAmountModalHide}
              title="Return Amount Details" extraClassName="modal-xxl"
            ><TablePlus
            onModal
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={returnamountData}
            tableColumns={returnamountcolumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={true}
            showAction={false}
           statusAction
           statusActionOption={statusActionOption}          
            
          />
           <div className="text-end">
            <CommonButton className="btn btn-primary">Add </CommonButton>
            <CommonButton className="btn btn-success ms-2">Submit</CommonButton>
          </div>
      </ModalComponent>

        {/* Split Payment Details Modal */}
        <ModalComponent
              backdrop
              show={splitPaymentModal}
              onHandleCancel={splitPaymentModalHide}
              title="Split Payment Details" extraClassName="modal-xxl"
            ><TablePlus
            onModal
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={splitpaymentData}
            tableColumns={splitpaymentcolumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            showAction={false}
            
          />
      </ModalComponent>

      {/* Payment Methods Modal */}
      <ModalComponent
              backdrop
              show={paymentOptionModal}
              onHandleCancel={paymentOptionModalHide}
              title="Payment Methods" extraClassName="modal-xxl"
            >
              <div className="tabsonModal">
                  <Tabs
                  tabContent={tabContent}
                  activeKey={defaultKey}
                  setActiveKey={setDefaultKey}
                  tabsOnModal
                  // onTabChange={onTabChange}
                  />
              </div>
              </ModalComponent>

    </>
  );
}

export default PaymentReport;
