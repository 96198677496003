export const emailTemplatesService = () => {
  return [
    {
      id: 1,
      emailSubject: "Bill generated successfully",
      emailCode: "ADMIN_EMAIL_FOR_BILLING_CYCLE_PAYMENT_SUCCESS",
    },
    {
      id: 2,
      emailSubject: "Contact Us Form Details",
      emailCode: "CONTACTUS",
    },
    {
      id: 3,
      emailSubject: "Customer Contact to Provider",
      emailCode: "ADMIN_PROVIDER_CONTACTUS",
    },
    {
      id: 4,
      emailSubject: "Document Expiry",
      emailCode: "CRON_EXPIRY_DOCUMENT_EMAIL",
    },
    {
      id: 5,
      emailSubject: "Document Uploaded",
      emailCode: "DOCCUMENT_UPLOAD_WEB",
    },
    {
      id: 6,
      emailSubject: "Email verification on Good Guys Home Service",
      emailCode: "APP_EMAIL_VERIFICATION_USER",
    }
  ];
};
