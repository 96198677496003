import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  ActionWrapper,
  ImageElement,
  ModalComponent,
} from "../../../../components";
import {
  Columns,
   NCAServices,
} from "../../../../services/index.service";


function ListNCA(props) {
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const NCAData = NCAServices();
  const columns = Columns.NCATableColumn({ userViewModal ,profileStatusModal,
    Action: () =>
    actionFormatter([
      {
        name: "Edit",
        icon: "icon ni ni-edit",
        handler: () =>{EditProviders()},
      },
    ]),
  });
  const navigate = useNavigate()
  const EditProviders = () =>{
    navigate('/admin/nca/edit')
  }
  const [viewModal, setViewModal] = useState(false);
  const viewModalHide = () =>{
      setViewModal(false)
  };
  function userViewModal(){
      setViewModal(true)
  }
  const [profileStatus, setProfileStatus] = useState(false);
  const profileStatusHide = () =>{
      setProfileStatus(false)
  };
  function profileStatusModal(){
      setProfileStatus(true)
  }
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "NCA",
    },
  ];
  const LogReportPage = (e) => {
    e.preventDefault();
    navigate('/admin/nca/log-report');
    profileStatusHide();
  }
  const EditNCAPage = (e) => {
    e.preventDefault();
    navigate('/admin/nca/edit');
    profileStatusHide();
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="NCA">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["csvExport"]}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={NCAData}
        tableColumns={columns}
        isSearch={false}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-unstyled">
                <li>Administrator module will list all NCA on this page.</li>
                <li>Administrator can Activate / Deactivate / Delete any NCA.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
      <ModalComponent
        backdrop
        show={viewModal}
        onHandleCancel={viewModalHide}
        title=" NCA Details"
      >
        <div>
        <div className="user-card user-card-s2 p-0 mb-2">
            <div className="user-avatar lg bg-primary">
                <ImageElement source="uploadImg.png" alt="img"/>
            </div>
        </div>
        <div className="row g-3">
            <div className="col-6">
                <span className="sub-text">NCA Name</span>
                <span>	abdul Hannan</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Email</span>
                <span>text@tekhne.app</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Phone Number</span>
                <span>+1-3178214104</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Company Name</span>
                <span>testcompany</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Status</span>
                <span className="badge badge-xs rounded-pill bg-success">Active</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Address</span>
                <span>United States</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Registration Date</span>
                <span>	Tuesday, Nov 08 th 2022</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Number Of Office Staff</span>
                <span>2</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Number Of Field Technicians/Installers</span>
                <span>254</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Go To Dashboard</span>
                <Link
                href="#"
                className="btn btn-primary me-1 me-sm-2 mb-1 mb-sm-0 mt-1"
            >
            Click Here
            </Link>
            </div>
        </div>
        <div className="text-end mt-4 d-flex align-items-center justify-content-end flex-wrap">
          <Link
                href="#"
                className="btn btn-primary me-1 me-sm-2 mb-1 mb-sm-0"
                onClick={ (e) =>EditNCAPage(e) }
            >
            Edit NCA
            </Link>
          <Link
                href="#"
                className="btn btn-light me-1 me-sm-2 mb-1 mb-sm-0"
                onClick={ (e) => LogReportPage(e) }
            >
            Go to Log Report
            </Link>
            
            <Link
                href="#"
                className="btn btn-secondary mb-1 mb-sm-0"
                onClick={ () => viewModalHide() }
            >
            Close
            </Link>
        </div>
      </div>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={profileStatus}
        onHandleCancel={profileStatusHide}
        title="Profile Status"
      >
        <div>
          <h6 className="title mb-4"><b>Company Name:</b> testcompany</h6>
        <div className="row g-3">
            <div className="col-12 col-sm-6">
                <span className="sub-text">NCA Profile</span>
                <span className="badge badge-xs bg-success">Complete</span>
            </div>
            <div className="col-12 col-sm-6">
                <span className="sub-text">Set Up Company Profile</span>
                <span className="badge badge-xs bg-success">Complete</span>
            </div>
            <div className="col-12 col-sm-6">
                <span className="sub-text">Set Up Card Details</span>
                <span className="badge badge-xs bg-success">Complete</span>
            </div>
            <div className="col-12 col-sm-6">
                <span className="sub-text">Set Up Business Details</span>
                <span className="badge badge-xs bg-success">Complete</span>
            </div>
            <div className="col-12 col-sm-6">
                <span className="sub-text">Set Up Banking Details</span>
                <span className="badge badge-xs bg-danger">Incomplete </span>
            </div>
            <div className="col-12">
              <p>If you want to notify to NCA to complete his profile click on below button to send profile reminder email.</p>
            </div>
        </div>
        <div className="text-end mt-2">
            <Link
                href="#"
                className="btn btn-primary"
                onClick={ () => profileStatusHide() }
            >
            Send Email
            </Link>
        </div>
      </div>
      </ModalComponent>  
    </>
  );
}

export default ListNCA;
