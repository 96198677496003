import React from 'react';
import { Table } from 'react-bootstrap';
import {   useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
} from '../../../components';

function MaterialList(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/equipment-order',
      name: 'Equipment Order',
    },
    {
      path: '#',
      name: 'Material List',
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Material List">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/equipment-order')}
          />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <h6 className="title mb-3">Quote Type: 90+% Furnace</h6>
            <h6 className="title mb-3">Quote Type: 80+% Furnace</h6>
            <h6 className="title mb-3">Quote Type: Apple iPhone</h6>
            <div className='table-responsive'>
            <Table bordered size="sm">
              <tbody>
                <tr>
                  <th>QA3</th>
                  <td>450.00</td>
                </tr>
                <tr>
                  <th>QA46</th>
                  <td>0.00</td>
                </tr>
                <tr>
                  <th>QA3</th>
                  <td>450.00</td>
                </tr>
                <tr>
                  <th>QA46</th>
                  <td>	0.00</td>
                </tr>
              </tbody>
            </Table>
            </div>
            
            </div>
          </div>
        </div>
    </>
  );
}

export default MaterialList;