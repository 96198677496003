import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  SweetAlert,
  Toggle,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListRebate(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Rebates Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Rebates',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getQuoteTypeData = () => {
    return [
      {
        name: '80% Gas Furnace',
        value: '80% Gas Furnace',
      },
      {
        name: 'Air Conditioner',
        value: 'Air Conditioner',
      },
      {
        name: 'Air Handlers',
        value: 'Air Handlers',
      },
    ];
  }
  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }    
  ]
  const getRebateTypeData = () => {
    return [
      {
        name: 'Seer',
        value: 'Seer',
      },
      {
        name: '	Gas efficiency',
        value: '	Gas efficiency',
      },
      {
        name: 'Misc',
        value: 'Misc',
      },
    ];
  }
  const columns = [
    {
       dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
       dataIndex: 'title',
      title: '	Title',
      sorter: true,
      ...getColumnSearchProps("title")
    },
    {
       dataIndex: 'cost',
      title: 'Cost',
      sorter: true,
      ...getColumnSearchProps("cost")
    },
    {
       dataIndex: 'quoteType',
      title: 'Quote Type',
      sorter: true,
      ...getColumnSelectSearchProps("quote type", getQuoteTypeData())
    },
    {
       dataIndex: 'rebateType',
      title: 'Rebate Type',
      sorter: true,
      ...getColumnSelectSearchProps("rebate type", getRebateTypeData())
    },
    {
       dataIndex: 'minRating',
      title: 'Min Rating',
      sorter: true,
      ...getColumnSearchProps("min rating")
    },
    {
       dataIndex: 'maxRating',
      title: 'Max Rating',
      sorter: true,
      ...getColumnSearchProps("max rating")
    },
    {
       dataIndex: 'description',
      title: 'Description',
      sorter: true,
      ...getColumnSearchProps("description")
    },
    {
       dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
       dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditRebateShow()},
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const adminData = [
    {
      id: 1,
      title: 'App Rebate',
      cost: '	50.00',
      quoteType: 'Apple,Apple iPhone,Apple MAC,Apple MAC1',
      rebateType: 'Seer',
      minRating: '12.00',
      maxRating: '400.00',
      description: '	This is App Rebate'
    },
    {
      id: 2,
      title: 'App1 Rebate',
      cost: '	60.00',
      quoteType: 'Apple,Apple iPhone,Apple MAC,Apple MAC1',
      rebateType: 'Gas efficiency',
      minRating: '12.00',
      maxRating: '400.00',
      description: 'This is App1 Rebate'
    },
    {
      id: 3,
      title: 'App2 Rebate',
      cost: '	70.00',
      quoteType: 'Apple,Apple iPhone,Apple MAC,Apple MAC1',
      rebateType: 'Misc',
      minRating: '12.00',
      maxRating: '400.00',
      description: '	This is App Rebate'
    },
  ];
  const [addRebate, setAddRebate] = useState(false);
  const addRebateHide = () =>{
    setAddRebate(false)
  }
  const addRebateShow = () =>{
    setAddRebate(true)
  }
  const [EditRebate, setEditRebate] = useState(false);
  const EditRebateHide = () =>{
    setEditRebate(false)
  }
  const EditRebateShow = () =>{
    setEditRebate(true)
  }
  const QuoteTypeData =[
    {
      name: '80% Gas Furnace',
      value: '80% Gas Furnace',
    },
    {
      name: '90+% Furnace',
      value: '90+% Furnace',
    },
  ]
  const RebateTypeData = [
    {
      name: 'Seer',
      value: 'Seer',
    },
    {
      name: 'Gas efficiency',
      value: 'Gas efficiency',
    },
    {
      name: 'Misc',
      value: 'Misc',
    },
  ]

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Rebates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create',]}
            btnText="Add Rebates"
            onHandleShow={addRebateShow}
            // popover={<RebateFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Rebates"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addRebate}
        onHandleCancel={addRebateHide}
        title="Add Rebates"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Rebate Title"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="rebatetitle"
                    placeholder="Enter rebate title "
                    formik={formik}
                  />
                )}
              />
                <FormElementWrapper
                md={6}
                label="Cost "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="cost"
                    placeholder="Enter cost"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Min Certified Rating"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="minCertifiedRating"
                    placeholder="Enter min certified rating "
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Max Certified Rating"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="maxCertifiedRating"
                    placeholder="Enter max certified rating "
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Quote Type"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="quoteType"
                    mode="multiple"
                    allowClear
                    placeholder="Select quote type"
                    options={QuoteTypeData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Rebate Type"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="rebateType"
                    placeholder="Select rebate type"
                    options={RebateTypeData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
            <FormElementWrapper
              md={12}
              label="Description"
              mandatory={true}
              element={(formik) => (
                <textarea
                  name="textarea"
                  placeholder="Enter description*"
                  formik={formik}
                  className="form-control"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() => addRebateHide()}
                  >
                    Add
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => addRebateHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={EditRebate}
        onHandleCancel={EditRebateHide}
        title="Edit Rebates"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Rebate Title"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="rebatetitle"
                    placeholder="Enter rebate title "
                    formik={formik}
                    defaultValue="App Rebate"
                  />
                )}
              />
                <FormElementWrapper
                md={6}
                label="Cost "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="cost"
                    placeholder="Enter cost"
                    formik={formik}
                    type="number"
                    defaultValue="50"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Min Certified Rating"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="minCertifiedRating"
                    placeholder="Enter min certified rating "
                    formik={formik}
                    type="number"
                    defaultValue="12"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Max Certified Rating"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="maxCertifiedRating"
                    placeholder="Enter max certified rating "
                    formik={formik}
                    type="number"
                    defaultValue="400"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Quote Type"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="quoteType"
                    mode="multiple"
                    allowClear
                    placeholder="Select quote type"
                    options={QuoteTypeData}
                    showSearch
                    formik={formik}
                    defaultValue="80% Gas Furnace"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Rebate Type"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="rebateType"
                    placeholder="Select rebate type"
                    options={RebateTypeData}
                    showSearch
                    formik={formik}
                    defaultValue="Seer"
                  />
                )}
              />
            <FormElementWrapper
              md={12}
              label="Description"
              mandatory={true}
              element={(formik) => (
                <textarea
                  name="textarea"
                  placeholder="Enter description*"
                  formik={formik}
                  className="form-control"
                  defaultValue="This is App Rebate"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-1 me-sm-2"
                    onClick={() => EditRebateHide()}
                  >
                    Update
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-1 me-sm-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => EditRebateHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListRebate;
