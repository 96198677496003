import {   CommonButton,  InputFilter, MultiSelectFilter} from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });


const getProviderListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};
const getUserListData = () => {
    return [
      {
        name: 'abhishekYadav',
        value: 'Abhishek Yadav',
      },
      {
        name: 'aditiTest',
        value: 'Aditi Test',
      },
    ];
  };
const getStatusData = () => {
  return [
    {
      name: 'Expired',
      value: 'Expired',
    },
    {
      name: 'Completed',
      value: 'Completed',
    },
    {
        name: 'NAC',
        value: 'NAC',
    },
  ];
};


const ButtonFormatter = (url, text) => {
    return (
        <>
        <Link to={url} className="">{text}</Link>
        </>
    );
};
const TextFormatter = (heading, text) => {
    return (
        <>
            <b>{heading}</b> <br /> {text}
        </>
    );
};
const DisplayButtonFormatter = (text) =>{
  return (
    <>
      <span className="btn btn-primary">{text}</span>
    </>
);
}

export const scheduledBookingsTableColumn = ({viewInVoicePage,BookingCancelModal}) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'bookingNumber',
            title: 'Booking No.',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ButtonFormatter("/admin/equipment-order/booking-log", "75833147"),
            ...getColumnSearchProps("booking number"),
        },
        {
            dataIndex: 'users',
            title: 'Users',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("users", getUserListData()),
        },
        {
            dataIndex: "bookingDate",
            title: "Booking Date ",
            headerClasses: "sorting",
            sorter: true,
        },
        {
          dataIndex: "expectedSourceLocation",
          title: "Expected Source Location",
          headerClasses: "sorting",
          sorter: true,
        },
        {
            dataIndex: "specialInstructions",
            title: "Special Instructions",
            headerClasses: "sorting",
            sorter: true,
        },
        {
            dataIndex: 'provider',
            title: 'Provider',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("provider", getProviderListData()),
            // render: (cell, row) => TextFormatter("Chris ios", row?.provider),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell === ""){ 
                return <> <Link to="/admin/add-booking" className="btn btn-primary" target="_blank">Assign Edit Provider</Link> <br/> ( Service Type : Cooling System Tune-up) </>
              }else{
                if(cell !== ""){
                  return <>
                      {TextFormatter("Chris ios", row?.provider)}
                  </>
                }
              }
          }
        },
        {
            dataIndex: 'jobDetails',
            title: 'Job Details',
            headerClasses: 'sorting',
            sorter: true,
            render:(cell, row)=>{
                console.log("Details", cell)
                if(cell === ""){ 
                  return <> --- </>
                }else{
                  if(cell !== ""){
                    return <>
                        <CommonButton onClick={ () => viewInVoicePage() } className="btn btn-primary btn-sm">View</CommonButton>
                    </>
                  }
                }
            }
        },
        {
            dataIndex: "status",
            title: "Status",
            headerClasses: "sorting",
            ...getColumnMultiSelectSearchProps("status", getStatusData()),
            sorter: true,
            render:(cell, row)=>{
                console.log("Details", cell)
                if(cell !== ""){ 
                  return <>{cell} </>
                }else{
                  if(cell === ""){
                    return <> Canceled By Provider
                        <br/>
                        <CommonButton onClick= { () => BookingCancelModal() } className="btn btn-light btn-sm">Cancel Reason</CommonButton>
                    </>
                  }
                }
            }
        },
        {
            dataIndex: "sendQuote",
            title: "Send Quote",
            headerClasses: "sorting",
            sorter: true,
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell === ""){ 
                return <>  </>
              }else{
                if(cell !== ""){
                  return <>
                      {DisplayButtonFormatter(row?.sendQuote)}
                  </>
                }
              }
          }
        },
        {
            dataIndex: "jobCompletionTime",
            title: "Job Completion Time",
            headerClasses: "sorting",
            sorter: true,
        },
      ];
};
