
import { Radio } from "antd";
import React, {useState} from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea,
  Select
} from "../../../../../../components";


function EditSubServiceCategory(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/subservice-category')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/subservice-category",
      name: "Sub Service Category",
    },
    {
      path: "#",
      name: "Edit Sub Service Category",
    },
  ];

  const statusData = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];

  const serviceTypeData = [
    {
      name: 'Scheduled Services',
      value: 'Scheduled Services',
    },
    {
      name: 'Same Day Services',
      value: 'Same Day Services',
    },
  ]
  const servicePriceData = [
    {
      name: 'Paid',
      value: 'Paid',
    },
    {
      name: 'Free',
      value: 'Free',
    },
  ]


  const displayOrderData = [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
  ]
  const customerTagData = [
    {
      name: 'AirCleaner',
      value: 'Air Cleaner',
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'HomeWarranty',
      value: 'Home Warranty',
    },
  ]
  const jobTagData = [
    {
      name: 'AirPurifier',
      value: 'Air Purifier',
    },
    {
      name: 'HeatingTune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'qwerty1',
      value: 'Qwerty1',
    },
  ]
  const serviceBlockData = [
    {
        name: '1',
        value: '1',
      },
      {
        name: '2',
        value: '2',
      },
      {
        name: '3',
        value: '3',
      },
  ]
  const [value, setValue] = useState('Hide');
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Sub Service Category">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
            md={6}
            label="Service Type"
            element={(formik) => (
              <Select
                name="ServiceType"
                placeholder="Select service type "
                options={serviceTypeData}
                showSearch
                formik={formik}
                defaultValue="Same Day Services"
                disabled
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Service Price"
            element={(formik) => (
              <Select
                name="ServicePrice"
                placeholder="Select service price "
                options={servicePriceData}
                showSearch
                formik={formik}
                disabled
                defaultValue="Paid"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Subcategory"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="Subcategory"
                placeholder="Enter subcategory"
                formik={formik}
              />
            )}
          />
          
          <FormElementWrapper
            md={6}
            label="Customer Visibility of Service"
            element={(formik) => (
                <Radio.Group value={value} onChange={onChange}>
                    <Radio value={'Visible'}>Visible</Radio>
                    <Radio value={'Hide'}>Hide</Radio>
                </Radio.Group>
            )}
          />
          <FormElementWrapper
            md={6}
            label="Service Quantity Availability"
            element={(formik) => (
                <Radio.Group value={value} onChange={onChange}>
                    <Radio value={'available'}>Available</Radio>
                    <Radio value={'notavailable'}>Not Available</Radio>
                </Radio.Group>
            )}
          />
          <FormElementWrapper
            md={6}
            label="Manual Booking"
            element={(formik) => (
                <Radio.Group value={value} onChange={onChange}>
                    <Radio value={'bookingVisible'}>Visible</Radio>
                    <Radio value={'bookingHide'}>Hide</Radio>
                </Radio.Group>
            )}
          />
          <FormElementWrapper
            md={12}
            label="Subcategory Description"
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="SubcategoryDescription"
                className="form-control"
                placeholder="Enter subcategory description *"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Service Block (No of Days)"
            element={(formik) => (
              <Select
                name="ServiceBlock"
                placeholder="Select service block"
                options={serviceBlockData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Subcategory Price "
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="SubcategoryPrice"
                placeholder="Enter subcategory price"
                formik={formik}
                type="number"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Display Order"
            element={(formik) => (
              <Select
                name="DisplayOrder"
                placeholder="Select display order "
                options={displayOrderData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Status "
            mandatory={true}
            element={(formik) => (
              <Select
                name="Status"
                placeholder="Select status "
                options={statusData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <h6 className="title mb-0">Auto Tag</h6>
          <FormElementWrapper
            md={6}
            label="Customer Tag "
            element={(formik) => (
              <Select
                name="CustomerTag"
                mode="multiple"
                allowClear
                placeholder="Select customer tag "
                options={customerTagData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Job Tag "
            element={(formik) => (
              <Select
                name="JobTag"
                mode="multiple"
                allowClear
                placeholder="Select job tag "
                options={jobTagData}
                showSearch
                formik={formik}
              />
            )}
          />         
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </CommonButton>
                
              </>
            )}
          />
        </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditSubServiceCategory;
