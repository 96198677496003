export const truckOrderServices = () => {
  return [
    {
      id: 1,
      orderNo: "232492",
      orderDate: "17th November, 2022",
      providerName: "Ron Test",
      partTitle: "HVAC- test ARJ",
      partNumber: "PART 3",
      partCost: "	2500.00",
      ordered: "Yes",
      adminOrder: "",
      supplyHouse: "Chillers and Compressor",
      poOption: 'N/A',
      paymentType: 'N/A',
      removeParts: "",
    },
    {
      id: 2,
      orderNo: "232492",
      orderDate: "17th November, 2022",
      providerName: "Ron Test",
      partTitle: "HVAC- test ARJ",
      partNumber: "PART 3",
      partCost: "	2500.00",
      ordered: "Yes",
      adminOrder: "Yes",
      supplyHouse: "Chillers and Compressor",
      poOption: 'New Part',
      paymentType: 'Credit Account',
      removeParts: "-",
    },
    {
      id: 3,
      orderNo: "232492",
      orderDate: "17th November, 2022",
      providerName: "Ron Test",
      partTitle: "HVAC- test ARJ",
      partNumber: "PART 3",
      partCost: "	2500.00",
      ordered: "Yes",
      adminOrder: "No",
      supplyHouse: "Chillers and Compressor",
      poOption: 'N/A',
      paymentType: 'N/A',
      removeParts: "-",
    },
    {
      id: 4,
      orderNo: "232492",
      orderDate: "17th November, 2022",
      providerName: "Ron Test",
      partTitle: "HVAC- test ARJ",
      partNumber: "PART 3",
      partCost: "	2500.00",
      ordered: "Yes",
      adminOrder: "Yes",
      supplyHouse: "Chillers and Compressor",
      poOption: 'Tools',
      paymentType: 'Cash',
      removeParts: "-",
    },
    {
      id: 5,
      orderNo: "232492",
      orderDate: "17th November, 2022",
      providerName: "Ron Test",
      partTitle: "HVAC- test ARJ",
      partNumber: "PART 3",
      partCost: "	2500.00",
      ordered: "Yes",
      adminOrder: "No",
      supplyHouse: "Chillers and Compressor",
      poOption: 'N/A',
      paymentType: 'N/A',
      removeParts: "-",
    },
  ];
};
