export const RequestsService = () => {
  return [
    {
      id: 1,
      userName: "Akash customer",
      phoneNumber: "915555555553",
      serviceTypeHeading: "HVAC - ",
      serviceTypePara: "Same day free emergency quote",
      location: "Vijay Nagar, Indore",
      providerName: "Chris ios",
      providerPhoneNumber: "914444444448",
      dateTime: "	Tue, December 6, 2022 09:49 AM",
      requestType: "Now"
    },
    {
      id: 2,
      userName: "Fread WEB",
      phoneNumber: "915555555553",
      serviceTypeHeading: "HVAC -",
      serviceTypePara: "Same day free emergency quote",
      location: "Vijay Nagar, Indore, Madhya Pradesh 452010, India",
      providerName: "Chris ios",
      providerPhoneNumber: "914444444448",
      dateTime: "Tue, December 6, 2022 09:49 AM",
      requestType: "Now"
    },
    {
      id: 3,
      userName: "test customer",
      phoneNumber: "914343434342",
      serviceTypeHeading: "QA NCA -",
      serviceTypePara: "Emergency Free New Quote",
      location: "Indore",
      providerName: "Broke Nca1	",
      providerPhoneNumber: "918319880401",
      dateTime: "Wed, December 7, 2022 04:00 PM",
      requestType: "Now"
    },
    {
      id: 4,
      userName: "Levis NCACUS",
      phoneNumber: "19755443354",
      serviceTypeHeading: "QA NCA -",
      serviceTypePara: "Same Day Paid",
      location: " 22 Test Street Name 452010,",
      providerName: "jorge neon",
      providerPhoneNumber: "918319880401",
      dateTime: "Tue, December 6, 2022 11:30 AM",
      requestType: "Now"
    },
    {
      id: 5,
      userName: "	NEW NCACUsc",
      phoneNumber: "914343434344",
      serviceTypeHeading: "HVAC -",
      serviceTypePara: "Have A Technician Contact You Today",
      location: "MRWJ GWF, Sector D, Sudama Nagar, Indore",
      providerName: "Randy NCA1",
      providerPhoneNumber: "918319880401",
      dateTime: "Tue, December 27, 2022 11:30 PM",
      requestType: "Now"
    },
    {
      id: 6,
      userName: "Levis NCACUS",
      phoneNumber: "917769981278",
      serviceTypeHeading: "QA NCA -",
      serviceTypePara: "Same Day Emergency Service1",
      location: "Indore",
      providerName: "James John",
      providerPhoneNumber: "918319880401",
      dateTime: "Thu, November 3, 2022 07:25 AM",
      requestType: "Now"
    },
  ];
};
