
import React from 'react';
import {
  Breadcrumb,
  PageHeader,
} from '../../components';

function HeatMap(props) {
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Heat Map View",
    }
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Heat Map View">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
        </div>
      </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
                <div className="w-100">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004069.896900944!2d-104.65611544442767!3d37.27565371492453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1667816322585!5m2!1sen!2sin"  
                    width="100%" height="650" style={{ border:"0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default HeatMap;
