import React from 'react';
import { Table } from 'react-bootstrap';
import {   useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from '../../../../components';

function NCALogReport(props) {
    const breadcrumb = [
        {
          path: '/admin/dashboard',
          name: 'Dashboard',
        },
        {
          path: '/admin/nca',
          name: 'NCA',
        },
        {
          path: '#',
          name: 'Log Report',
        },
      ];
      const TxtFormatter = ( text , Boldtext) => {
        return (
            <>
                <p> {text} <b>{Boldtext} </b></p>
            </>
        );
    }; 
      const columns = [
        {
          dataIndex: 'id',
          title: 'S.No.',
          headerClasses: 'w_70',
        },
        {
          dataIndex: 'date',
          title: 'Date',
        },
        {
          dataIndex: 'log',
          title: 'Log',
          render: (cell, row) => TxtFormatter(row?.log , row?.logBold),
        },
      ];
         
      const customerData = [
        {
          id: 1,
          date: '01/12/2023 at 04:10 AM',
          log: 'You have successfully switched your plan from ',
          logBold: "Default Plan (Monthly) to NCA Premium Pack (Monthly)."
        },
        {
          id: 2,
          date: '01/12/2023 at 03:43 AM',
          log: 'You have successfully switched your plan from',
          logBold: "Default Plan (Monthly) to NCA Premium Pack (Monthly)."
        },
        {
          id: 3,
          date: '02/12/2023 at 03:53 AM',
          log: 'You have successfully switched your plan from',
          logBold: "Default Plan (Monthly) to NCA Premium Pack (Monthly)."
        },
        {
          id: 4,
          date: '04/12/2023 at 05:43 AM',
          log: 'You have successfully switched your plan from',
          logBold: "Default Plan (Monthly) to NCA Premium Pack (Monthly)."
        },
      ];
      const navigate = useNavigate()
      return (
        <>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <PageHeader heading="Log Report">
                <Breadcrumb breadcrumb={breadcrumb} />
              </PageHeader>
              <ListingHeader
                btnArray={["extraButton"]}
                extraBtnText="Back"
                extraBtnClass="btn btn-outline-light bg-white"
                onExtraButtonHandleShow={ () => navigate('/admin/nca')}
              />
            </div>
          </div>
          <TablePlus
              hasLimit
              noOfPage="1"
              sizePerPage="10"
              page="1"
              count="100"
              tableData={customerData}
              tableColumns={columns}
              // tableLoader={isLoading}
              setSizePerPage=""
              selectRow={false}
              isSearch={false}
              isResetBtn= {false}
              showAction={false}
            />
        </>
      );
}

export default NCALogReport;
