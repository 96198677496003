import { InputFilter, SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}  placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getStatusData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
};

export const countryTableColumn = ({ Action }) => {
  console.log("Action", Action);
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "country",
      title: "Country",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("country"),
    },
    {
      dataIndex: "code",
      title: "Code",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("code"),
    },
    {
      dataIndex: "unit",
      title: "Unit(Miles/KMs)",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("unit"),
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
      render:()=><Toggle isDropDown/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
};
