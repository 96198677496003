import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FinancingInformationForm,
  PageContainerWrapper,
} from '../../../../../components';
import {
  Columns,
  financingInformationService,
} from '../../../../../services/index.service';
import { modalNotification } from '../../../../../utils';
function Financing(props) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function onSubmit(e) {
    setLoading(true);
    try {
      const res = await financingInformationService({
        ...e,
      });

      const { message, success } = res;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }
  const breadcrumb = Columns.financingInformationBreadcrumb();
  const onCancle = () => {};

  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          breadcrumb={breadcrumb}
          btnArray={[]}
          // addBtnText="Add Role"
          heading={t('text.appText.financingInfoHeading')}
        >
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                <FinancingInformationForm
                  onSubmit={onSubmit}
                  onCancle={onCancle}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default Financing;
