import { Outlet } from 'react-router-dom';
import { ViewNoDataChat } from '../../views/Chat';
import ViewChat from '../../views/Chat/View';

export default function route() {
  return [
    {
      path: accessRoute.CHAT.path,
      private: true,
      name: 'Chat',
      key: "chat",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.CHAT.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.CHAT.path,
        name: 'Chat',
        key: accessRoute.CHAT.key,
        belongsToSidebar: false,
        icon: accessRoute.CHAT.icon,
        element: <ViewChat />,
      },
      {
        private: true,
        path: accessRoute.NO_DATA_CHAT.path,
        name: 'No Data Chat',
        key: accessRoute.NO_DATA_CHAT.key,
        belongsToSidebar: false,
        icon: accessRoute.NO_DATA_CHAT.icon,
        element: <ViewNoDataChat />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  CHAT: {
    path: '/admin/chat',
    key:"chat.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-chat-circle-fill" />
      </span>
    ),
  },
  NO_DATA_CHAT: {
    path: '/admin/chat/nodata-chat',
    key:"chat.nodata",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
