import { Outlet } from 'react-router-dom';
import { AddMembership, EditMembership, ListMembership } from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';
// import AddProcedureLesson from '../../views/OperatingProcedure/Create/index';

export default function route() {
  return [
    {
      path: accessRoute.MEMBERSHIP.path,
      private: true,
      name: 'Membership',
      key: "membership",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.MEMBERSHIP.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.MEMBERSHIP.path,
          name: 'List',
          key: accessRoute.MEMBERSHIP.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP.icon,
          element: <ListMembership />,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_ADD.path,
          name: 'Add',
          key: accessRoute.MEMBERSHIP_ADD.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_ADD.icon,
          element: <AddMembership />,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_EDIT.path,
          name: 'Add',
          key: accessRoute.MEMBERSHIP_EDIT.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_EDIT.icon,
          element: <EditMembership />,
        },
      ],
    },
  ];
}

export const accessRoute = {
    MEMBERSHIP: {
    path: '/admin/membership',
    action: getActions('LIST'),
    key: 'membership.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-cc-alt-fill" />
      </span>
    ),
  },
  MEMBERSHIP_ADD: {
    path: '/admin/membership/add',
    action: getActions('CREATE'),
    key: 'membership.list',
  },
  MEMBERSHIP_EDIT: {
    path: '/admin/membership/edit',
    action: getActions('CREATE'),
    key: 'membership.list',
  },
};
export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
