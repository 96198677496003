import React from 'react';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Password,
} from '../../../index';
import { initValues, validation } from './validation';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function AdminLoginForm({ onSubmit, onBack, loading }) {
  const navigate = useNavigate();
  const loginPage = () => {
    navigate('/admin/dashboard');
  };
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validation}
      initialValues={initValues()}
    >
      {(props) => {
        return (
          <>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              xs={12}
              label="Super Administrator E-mail"
              element={(formik) => (
                <TextInput
                  name="emailOrMobile"
                  formik={props}
                  extraClassName="form-control-lg"
                  placeholder="Enter your email address"
                />
              )}
            />
            <FormElementWrapper
              xs={12}
              label="Password"
              element={(formik) => (
                <Password
                  name="password"
                  formik={props}
                  extraClassName="form-control-lg"
                  placeholder="Enter your password"
                />
              )}
            />
            <div className="form-group">
              <CommonButton
                type="submit"
                loading={loading}
                className="btn btn-lg btn-primary btn-block"
                onClick={loginPage}
              >
                LOGIN
              </CommonButton>
            </div>
            <div className="form-note-s2 text-center">
              <Link to="#" onClick={onBack} className="text-uppercase">
              Back
              </Link>
            </div>
            </FormWrapper>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default AdminLoginForm;
