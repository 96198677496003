import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  TablePlus,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
} from "../../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { modalNotification } from "../../../../../utils";
import { Columns } from "../../../../../services/index.service";
import { CategoryManagementService } from "../../../../../services/ShoppingCart/CategoryManagement.service";

function CategoryList(props) {
  // const navigate = useNavigate()

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Category Management",
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Category Deleted Successfully", })
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }

  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
      id: 4,
      name: 'Make Delete'
    }
  ]


  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const addCategoryModalShow = () => {
    setAddCategoryModal(true);
  };
  const addCategoryModalHide = () => {
    setAddCategoryModal(false);
  };

  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const editCategoryModalShow = () => {
    setEditCategoryModal(true);
  };
  const editCategoryModalHide = () => {
    setEditCategoryModal(false);
  };

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

   const multipleButton  = [
    
    {
      title: "Download sample",
      icons: 'icon ni ni-download',
      buttonClass: 'btn btn-outline-light'
    },
    {
      title: "Add Category",
      handler: () => { addCategoryModalShow() },
    },
  ]

  const columns = Columns.CategoryManagementTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () => { editCategoryModalShow() },
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler: () => {
            setIsAlertVisible(true);
            document.body.click()
          }
        },
      ]),
  });

  const categoryData = CategoryManagementService();
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Category Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ "create","csvExport", 'csvImport', 'downloadSample']}
            btnText="Add Category"
            onHandleShow={addCategoryModalShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={categoryData}
        tableColumns={columns}
        showAction={false}
        isSearch={true}
        // tableLoader={isLoading}
        setSizePerPage=""
      />
      <SweetAlert
        title="Are you sure"
        text=" you want to delete this Category"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addCategoryModal}
        onHandleCancel={addCategoryModalHide}
        title="Add Category"
      >
        <FormWrapper row={true} extraClassName="g-3">

          {/* <FormElementWrapper
            sm={12}
            label="Category Image"
            element={(formik) => (
              <TextInput
                name="categoryImage"
                placeholder="Select category image"
                formik={formik}
                type="file"
              />
            )}
          /> */}
          <FormElementWrapper
            sm={12}
            label="Category Name"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter category name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addCategoryModalHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addCategoryModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>

      <ModalComponent
        backdrop
        show={editCategoryModal}
        onHandleCancel={editCategoryModalHide}
        title="Edit Category"
      >
        <FormWrapper row={true} extraClassName="g-3">
          {/* <FormElementWrapper
            sm={12}
            label="Category Image"
            element={(formik) => (
              <TextInput
                name="categoryImage"
                placeholder="Select category image"
                formik={formik}
                type="file"
              />
            )}
          /> */}
          <FormElementWrapper
            sm={12}
            label="Category Name"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter category name"
                formik={formik}
                defaultValue="Electronics"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addCategoryModalHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addCategoryModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default CategoryList;
