import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../../../helpers/index.helper';

export const initValues = () => {
  return {
    businessType: null,
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    homeAddress: null,
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    socialSecurityNumber: '',
    dob: '',
    identityFront: '',
    identityBack: '',
    company: '',
    employerIdNumber: '',
    doingBusiness: '',
    industry: [],
    businessAddress: null,
  };
};

export const validation = (businessType, businessModal) => {
  let validationObj = {
    ...(businessModal && {
      businessType: yup
        .string()
        .typeError(i18next.t('validation.signup.businessType'))
        .required(i18next.t('validation.signup.businessType')),
    }),
    ...(!businessType && {
      company: yup.string().required(i18next.t('validation.signup.company')),
    }),
    ...(!businessType && {
      employerIdNumber: yup
        .string()
        .matches(Regex.minNineCharacter, {
          message: i18next.t('validation.signup.enterValidIdNumber'),
        })
        .required(i18next.t('validation.signup.employerIdNumber')),
    }),
    ...(!businessType && {
      doingBusiness: yup
        .string()
        .required(i18next.t('validation.signup.doingBusiness')),
    }),
    ...(!businessType && {
      industry: yup.array().min(1, i18next.t('validation.signup.industry')),
    }),
    ...(!businessType && {
      businessAddress: yup
        .string()
        .typeError(i18next.t('validation.signup.businessAddress'))
        .required(i18next.t('validation.signup.businessAddress')),
    }),
    ...(businessType && {
      firstName: yup
        .string()
        .required(i18next.t('validation.signup.firstName')),
    }),
    ...(businessType && {
      lastName: yup.string().required(i18next.t('validation.signup.lastName')),
    }),
    ...(businessType && {
      email: yup
        .string()
        .required(i18next.t('validation.login.email'))
        .email(i18next.t('validation.login.validEmail')),
    }),
    ...(businessType && {
      jobTitle: yup.string().required(i18next.t('validation.signup.jobTitle')),
    }),
    ...(businessType && {
      homeAddress: yup
        .string()
        .typeError(i18next.t('validation.signup.homeAddress'))
        .required(i18next.t('validation.signup.homeAddress')),
    }),
    addressLine1: yup
      .string()
      .required(i18next.t('validation.signup.addressLine1')),
    addressLine2: yup
      .string()
      .required(i18next.t('validation.signup.addressLine2')),
    state: yup.string().required(i18next.t('validation.signup.state')),
    city: yup.string().required(i18next.t('validation.signup.city')),
    zipCode: yup.string().required(i18next.t('validation.signup.zipCode')),
    phoneNumber: yup
      .string()
      .required(i18next.t('validation.signup.phoneNumber'))
      .matches(Regex.phoneNumber, {
        message: i18next.t('validation.signup.minPhoneNo'),
      }),
    socialSecurityNumber: yup
      .string()
      .matches(Regex.lastFourDigit, {
        message: i18next.t('validation.signup.enterValidNumber'),
      })
      .required(i18next.t('validation.signup.socialSecurityNumber')),
    dob: yup.string().required(i18next.t('validation.signup.dob')),
    identityFront: yup
      .string()
      .required(i18next.t('validation.signup.identityFront')),
    identityBack: yup
      .string()
      .required(i18next.t('validation.signup.identityBack')),
  };
  return yup.object().shape(validationObj);
};
