import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AdminLoginForm, ImageElement } from '../../../components';
import { LoginService } from '../../../services/index.service';
import { login } from '../../../redux/index.redux';
import { setLocalStorageToken } from '../../../utils';
import { getDefaultAuthRoute } from '../../../routes/index.routes';
import { Link } from 'react-router-dom';
import { accessRoute } from '../../../routes/Auth/login';

function Admin() {
  // const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function initLogin(e) {
    try {
      setLoading(true);
      const res = await LoginService({
        ...e,
        userRole: 'admin',
        deviceType: 'web',
        appVersion: 'v1',
        firebaseToken: ' ',
      });
      const { data, success } = res;
      if (success) {
        dispatch(login({ ...data }));
        setLocalStorageToken(res?.data?.token);
        navigate(getDefaultAuthRoute('admin'));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const onBack = (e) => {
    try {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      navigate(accessRoute.LOGIN.path);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Super Administrator</h5>
              <div className="nk-block-des">
                <p>Access the Good Guys panel using your email and password.</p>
              </div>
            </div>
          </div>
          <AdminLoginForm loading={loading} onSubmit={initLogin} onBack={onBack} />
        </div>
      </div>
    </>
  );
}

export default Admin;
