// import jQuery from 'jquery';
// import 'bootstrap/dist/css/bootstrap.css';
// // Bootstrap JS relies on a global varaible.
// // In ES6, all imports are hoisted to the top of the file
// // so if we used `import` to import Bootstrap, it would
// // execute earlier than we have assigned the global
// // variable. This is why we have to use CommonJS require()
// // here since it doesn't have the hoisting behavior.
// window.$ = jQuery;
// require('bootstrap');

import jquery from 'jquery';
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;
