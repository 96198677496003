import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  TablePlus,
  InputFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { FilterFilled  } from "@ant-design/icons";


function ListQuoteOrder(props) {

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Quote Order & Admin Profit',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'orderType',
      title: '	Order Type',
      sorter: true,
      ...getColumnSearchProps("order type")
    },
    {
      dataIndex: 'displayOrder',
      title: 'Display Order ',
      sorter: true,
    },
    {
      dataIndex: 'laborCost',
      title: 'Labor Cost (Flat In USD)',
      sorter: true,
    },
    {
      dataIndex: 'salesProfit',
      title: 'Sales Profit (Flat In USD)',
      sorter: true,
    },
    {
      dataIndex: 'adjustmentSeason',
      title: 'Adjustment Season',
      sorter: true,
      ...getColumnSearchProps("adjustment season")
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditQuoteOrderShow()},
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
    ]);
    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Delete'
      }    
    ]
  const adminData = [
    {
      id: 1,
      orderType: 'Best',
      displayOrder: '1',
      laborCost: '500.00',
      salesProfit: '	1500.00',
      adjustmentSeason: '+ 10'
    },
    {
      id: 2,
      orderType: 'Better',
      displayOrder: '2',
      laborCost: '0.25',
      salesProfit: '22.50',
      adjustmentSeason: '+ 10'
    },
    {
      id: 3,
      orderType: 'Good',
      displayOrder: '4',
      laborCost: '500.00',
      salesProfit: '	1200.00',
      adjustmentSeason: '+ 10'
    },
    {
      id: 4,
      orderType: '	Premium',
      displayOrder: '1',
      laborCost: '500.00',
      salesProfit: '	1300.00',
      adjustmentSeason: '- 10'
    },
  ];
  const [addQuoteOrder, setAddQuoteOrder] = useState(false);
  const addQuoteOrderHide = () =>{
    setAddQuoteOrder(false)
  }
  const addQuoteOrderShow = () =>{
    setAddQuoteOrder(true)
  }
  const [EditQuoteOrder, setEditQuoteOrder] = useState(false);
  const EditQuoteOrderHide = () =>{
    setEditQuoteOrder(false)
  }
  const EditQuoteOrderShow = () =>{
    setEditQuoteOrder(true)
  }
  const DisplayOrderAdd =[
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
  ]
  const AdjustmentSeasonData = [
    {
      name: '+',
      value: '+',
    },
    {
      name: '-',
      value: '-',
    },
  ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Quote Order & Admin Profit">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create',]}
            btnText="Add Order"
            onHandleShow={addQuoteOrderShow}
            // popover={<QuoteOrderFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Quote order & Admin Profit module will list all Quote Order & Admin Profit on this page.
                </li>
                <li>
                Administrator can Edit / Delete any Quote Order & Admin Profit.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={addQuoteOrder}
        onHandleCancel={addQuoteOrderHide}
        title="Add Quote Order & Admin Profit"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
              md={6}
              label="Provider Quote Order "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="providerQuoteOrder"
                  placeholder="Enter provider quote order "
                  formik={formik}
                />
              )}
            />
        <FormElementWrapper
            md={6}
            label="Display Order Add"
            element={(formik) => (
              <Select
                name="displayorder"
                placeholder="Select display order add"
                options={DisplayOrderAdd}
                // showSearch
                formik={formik}
              />
            )}
          />
            <FormElementWrapper
              md={6}
              label="Labor Cost (Flat In USD)"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="laborCost"
                  placeholder="Enter labor cost (flat in USD)"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Sales Profit (Flat In USD)"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="salesprofit"
                  placeholder="Enter sales profit (flat in USD)"
                  formik={formik}
                  type="number"
                />
              )}
            />
          <FormElementWrapper
            md={12}
            label="Adjustment For Season (In Percentage) "
            mandatory={true}
            element={(formik) => (
              <div className='inputAndSelect'>
                <div className="form-control-wrap">
                  <div className="input-group flex-nowrap">
                    <div className="input-group-prepend w-25">
                      <Select
                        name="displayorder"
                        placeholder="Select adjustment"
                        options={AdjustmentSeasonData}
                        // showSearch
                        formik={formik}
                        defaultValue=" + "
                      />
                    </div>
                    <TextInput
                      name="adjustmentSeason"
                      placeholder="Adjustment For Season (In Percentage) "
                      formik={formik}
                      type="number"
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addQuoteOrderHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addQuoteOrderHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={EditQuoteOrder}
        onHandleCancel={EditQuoteOrderHide}
        title="Edit Quote Order & Admin Profit"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
              md={6}
              label="Provider Quote Order "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="providerQuoteOrder"
                  placeholder="Enter provider quote order "
                  formik={formik}
                  defaultValue="Best"
                />
              )}
            />
        <FormElementWrapper
            md={6}
            label="Display Order Add"
            element={(formik) => (
              <Select
                name="displayorder"
                placeholder="Select display order add"
                options={DisplayOrderAdd}
                // showSearch
                formik={formik}
                defaultValue="1"
              />
            )}
          />
            <FormElementWrapper
              md={6}
              label="Labor Cost (Flat In USD)"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="laborCost"
                  placeholder="Enter labor cost (flat in USD)"
                  formik={formik}
                  type="number"
                  defaultValue="500.00"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Sales Profit (Flat In USD)"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="salesprofit"
                  placeholder="Enter sales profit (flat in USD)"
                  formik={formik}
                  type="number"
                  defaultValue="150.00"
                />
              )}
            />
          <FormElementWrapper
            md={12}
            label="Adjustment For Season (In Percentage) "
            mandatory={true}
            element={(formik) => (
              <div className='inputAndSelect'>
                <div className="form-control-wrap">
                  <div className="input-group flex-nowrap">
                    <div className="input-group-prepend w-25">
                      <Select
                        name="displayorder"
                        placeholder="Select adjustment"
                        options={AdjustmentSeasonData}
                        // showSearch
                        formik={formik}
                        defaultValue=" + "
                      />
                    </div>
                    <TextInput
                      name="adjustmentSeason"
                      placeholder="Adjustment For Season (In Percentage) "
                      formik={formik}
                      type="number"
                      defaultValue="10"
                    />
                  </div>
                </div>
                </div>
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-1 me-sm-2"
                  onClick={() => EditQuoteOrderHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-1 me-sm-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => EditQuoteOrderHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListQuoteOrder;
