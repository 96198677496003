import { InputFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const emailTemplatesTableColumn = ({ Action }) => {

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "emailSubject",
      title: "Email Subject",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email subject")
    },
    {
      dataIndex: "emailCode",
      title: "Email Code",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email code")
    },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
