import { Outlet } from 'react-router-dom';
import { AddMarketingEmailTemplate, AddMarketingSMSTemplate, AddPromoCode, AddStore, DocumentsView, EditMarketingEmailTemplate, EditMarketingSMSTemplate, EditPromoCode, EditStore, MarketingMessageSend, PromoCodeListing, ReviewTemplateReport, StoreListing, UnsubscribeUserList, ViewMarketingEmailTemplate, ViewMarketingReviewTemplate, ViewMarketingSMSTemplate } from '../../views/Marketing';



export default function route() {
  return [
    {
      private: true,
      name: "Marketing",
      key: "marketing",
      belongsToSidebar: true,
      icon: accessRoute.PROMO_CODE.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.PROMO_CODE.name,
          key:"marketing.promoCode",
          path:accessRoute.PROMO_CODE.path,
          belongsToSidebar: true,
          element: <PromoCodeListing/>
        },
        {
          private: true,
          name:accessRoute.ADD_PROMO_CODE.name,
          key:"marketing.promoCode",
          path:accessRoute.ADD_PROMO_CODE.path,
          belongsToSidebar: false,
          element: <AddPromoCode/>
        },
        {
          private: true,
          name:accessRoute.EDIT_PROMO_CODE.name,
          key:"marketing.promoCode",
          path:accessRoute.EDIT_PROMO_CODE.path,
          belongsToSidebar: false,
          element: <EditPromoCode/>
        },
        {
          private: true,
          name:accessRoute.STORE.name,
          key:"marketing.store",
          path:accessRoute.STORE.path,
          belongsToSidebar: true,
          element: <StoreListing/>
        },
        {
          private: true,
          name:accessRoute.ADD_STORE.name,
          key:"marketing.store",
          path:accessRoute.ADD_STORE.path,
          belongsToSidebar: false,
          element: <AddStore/>
        },
        {
          private: true,
          name:accessRoute.EDIT_STORE.name,
          key:"marketing.store",
          path:accessRoute.EDIT_STORE.path,
          belongsToSidebar: false,
          element: <EditStore/>
        },
        {
          private: true,
          name:accessRoute.VIEW_DOCUMENTS.name,
          key:"marketing.store",
          path:accessRoute.VIEW_DOCUMENTS.path,
          belongsToSidebar: false,
          element: <DocumentsView/>
        },
        {
          private: true,
          name: 'Marketing Template',
          key: 'marketing.marketingTemplate',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.Marketing_Email_Template.path,
              name: 'Marketing Email Template',
              key: 'marketing.marketingTemplate.email',
              belongsToSidebar: true,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <ViewMarketingEmailTemplate />,
            },
            {
              private: true,
              path: accessRoute.Add_Marketing_Email_Template.path,
              name: 'Add Marketing Email Template',
              key: 'marketing.marketingTemplate.email',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <AddMarketingEmailTemplate />,
            },
            {
              private: true,
              path: accessRoute.Edit_Marketing_Email_Template.path,
              name: 'Edit Marketing Email Template',
              key: 'marketing.marketingTemplate.email',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <EditMarketingEmailTemplate />,
            },
            {
              private: true,
              path: accessRoute.Marketing_SMS_Template.path,
              name: 'Marketing SMS Template',
              key: 'marketing.marketingTemplate.sms',
              belongsToSidebar: true,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <ViewMarketingSMSTemplate />,
            },
            {
              private: true,
              path: accessRoute.Add_Marketing_SMS_Template.path,
              name: 'Add Marketing SMS Template',
              key: 'marketing.marketingTemplate.sms',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <AddMarketingSMSTemplate />,
            },
            {
              private: true,
              path: accessRoute.Edit_Marketing_SMS_Template.path,
              name: 'Edit Marketing SMS Template',
              key: 'marketing.marketingTemplate.sms',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <EditMarketingSMSTemplate />,
            },
            {
              private: true,
              path: accessRoute.Marketing_Review_Template.path,
              name: 'Marketing Review Template',
              key: 'marketing.marketingTemplate.review',
              belongsToSidebar: true,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <ViewMarketingReviewTemplate />,
            },
            {
              private: true,
              path: accessRoute.Marketing_Review_Template_Report.path,
              name: 'Marketing Review Template Report',
              key: 'marketing.marketingTemplate.review',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <ReviewTemplateReport />,
            },
            {
              private: true,
              path: accessRoute.Marketing_Review_Template_Unsubscribe_User.path,
              name: 'Marketing Review Template Unsubscribe User',
              key: 'marketing.marketingTemplate.review',
              belongsToSidebar: false,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <UnsubscribeUserList />,
            },
            {
              private: true,
              path: accessRoute.Marketing_Message_Send.path,
              name: 'Marketing Message Send',
              key: 'marketing.marketingTemplate.messageSend',
              belongsToSidebar: true,
              // icon: accessRoute.List_SERVICE_CATEGORY.icon,
              element: <MarketingMessageSend />,
            },
          ],
        },
      ]
    },
  ];
}

export const accessRoute = {
  PROMO_CODE: {
    path: "/admin/promo-code",
    name: "Promo Code",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-offer-fill" />
      </span>
    )
  },
  ADD_PROMO_CODE: {
    path: "/admin/promo-code/add",
    name: "Add Promo Code",
  },
  EDIT_PROMO_CODE: {
    path: "/admin/promo-code/edit",
    name: "Edit Promo Code",
  },
  STORE: {
    path: "/admin/store",
    name: "Store",
  },
  ADD_STORE: {
    path: "/admin/store/add",
    name: "Add Store",
  },
  EDIT_STORE: {
    path: "/admin/store/edit",
    name: "Edit Store",
  },
  VIEW_DOCUMENTS: {
    path: "/admin/store/documents",
    name: "View Documents",
  },
  Marketing_Email_Template: {
    path: "/admin/marketing-template/email",
    name: "Marketing Email Template",
  },
  Add_Marketing_Email_Template: {
    path: "/admin/marketing-template/email/add",
    name: "Add Marketing Email Template",
  },
  Edit_Marketing_Email_Template: {
    path: "/admin/marketing-template/email/edit",
    name: "Edit Marketing Email Template",
  },
  Marketing_SMS_Template: {
    path: "/admin/marketing-template/sms",
    name: "Marketing SMS Template",
  },
  Add_Marketing_SMS_Template: {
    path: "/admin/marketing-template/sms/add",
    name: "Add Marketing SMS Template",
  },
  Edit_Marketing_SMS_Template: {
    path: "/admin/marketing-template/sms/edit",
    name: "Edit Marketing SMS Template",
  },
  Marketing_Review_Template: {
    path: "/admin/marketing-template/marketing-review",
    name: "Marketing Review Template",
  },
  Marketing_Review_Template_Report: {
    path: "/admin/marketing-template/marketing-review/report",
    name: "Marketing Review Template Report",
  },
  Marketing_Review_Template_Unsubscribe_User: {
    path: "/admin/marketing-template/marketing-review/unsubscribe-user",
    name: "Marketing Review Template Unsubscribe User",
  },
  Marketing_Message_Send: {
    path: "/admin/marketing-template/message-send",
    name: "Marketing Message Send",
  }
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
