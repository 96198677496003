export const NotificationsService = () => {
  return [
    {
      id: 1,
      userName: "Akash customer",
      phoneNumber: "14535344444",
      serviceTypeHeading: "Electrician -",
      serviceTypePara: "Quote (Electrical Panel, Whole House Generator, etc.)",
      location: "Vijay Nagar, Indore",
      providerName: "Ron Test",
      providerPhoneNumber: "918319880401",
      notification: "The service require your attention. Service 77980341 is under NAC listing from Past 5 minutes",
      dateTime: "Thu, December 8, 2022 05:30 AM",
    },
    {
      id: 2,
      userName: "Fread WEB",
      phoneNumber: "915555555553",
      serviceTypeHeading: "HVAC -",
      serviceTypePara: "Same day free emergency quote",
      location: "Indianapolis, IN 46204, USA",
      providerName: "Chris ios",
      providerPhoneNumber: "914444444448",
      notification: "The service require your attention. Service 77641243 is under NAC listing from Past 5 minutes.",
      dateTime: "Tue, December 6, 2022 09:49 AM",
    },
    {
      id: 3,
      userName: "test customer",
      phoneNumber: "11234567813",
      serviceTypeHeading: "HVAC -",
      serviceTypePara: "Schedule A Replacement Quote (Heating and Cooling Equipment)",
      location: "Indore",
      providerName: "James Watt",
      providerPhoneNumber: "918319880401",
      notification: "The service require your attention. Service 24984145 is under NAC listing from Past 5 minutes.",
      dateTime: "Wed, December 7, 2022 04:00 PM",
    },
    {
      id: 4,
      userName: "	abdul Hannan",
      phoneNumber: "19755443354",
      serviceTypeHeading: "Electrician -",
      serviceTypePara: "Cooling System Tune-up",
      location: " 22 Test Street Name 452010,",
      providerName: "jorge neon",
      providerPhoneNumber: "918319880401",
      notification: "The service require your attention. Service 78937803 is under NAC listing from Past 5 minutes",
      dateTime: "Tue, December 6, 2022 11:30 AM",
    },
    {
      id: 5,
      userName: "peter parker",
      phoneNumber: "917869981278",
      serviceTypeHeading: "HVAC -",
      serviceTypePara: " FL - Heating System Tuneup",
      location: "MRWJ GWF, Sector D, Sudama Nagar, Indore",
      providerName: "Randy NCA1",
      providerPhoneNumber: "918319880401",
      notification: "The quoted service 73975296 is not signed by custome",
      dateTime: "Tue, December 27, 2022 11:30 PM",
    },
    {
      id: 6,
      userName: "Levis NCACUS",
      phoneNumber: "917769981278",
      serviceTypeHeading: "QA NCA -",
      serviceTypePara: "Schedule Free Old Quote",
      location: "Indore",
      providerName: "James John",
      providerPhoneNumber: "918319880401",
      notification: "The quoted service 38837825 is not signed by customer",
      dateTime: "Thu, November 3, 2022 07:25 AM",
    },
  ];
};
