export const GoogleConnectAdwordsServices = () => {
    return [
      {
        id: 1,
        name: "2_GEN",
        click: "0",
        avgCost: '$0.00',
        cost: "$0.00",
        impressions: "0",
        budget: "$10.00",
        conversionValue: "0"
      },
      {
        id: 2,
        name: "My Campaign #demo",
        click: "1",
        avgCost: '$10.00',
        cost: "$20.00",
        impressions: "0",
        budget: "$20.00",
        conversionValue: "0"
      },
    ];
  };
  