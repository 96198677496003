import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AntSelect } from '../..';


function AmountFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex }) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const selectAction = [
    {
      id: 'Equal to',
      name: 'Equal to',
    },
    {
      id: 'or',
      name: 'Greater than or equal to',
    },
    {
      id: 'lessThanOrEqualTo',
      name: 'Less than or equal to',
    },
  ];
  const selectOptions = [
    {
        id: 'And',
        name: 'And',
      },
      {
        id: 'or',
        name: 'Or',
      },
  ]
  return (
    <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <AntSelect
            size="medium"
            id="status"
            extraClassName="form-control"
            name="statusId"
            disabled={false}
            variant="standard"
            placeholder="Select"
            options={selectAction}
            defaultValue='Equal to'
            style={{
            marginBottom: 8,
            display: 'block',
            
          }}
        />
        <Input
          ref={searchInput}
          placeholder="Enter amount"
          className="ant-input form-control"
          type="number"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <AntSelect
            size="medium"
            id="status"
            extraClassName="form-control"
            name="statusId"
            disabled={false}
            variant="standard"
            placeholder="Select"
            options={selectOptions}
            defaultValue='And'
            style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <AntSelect
            size="medium"
            id="status"
            extraClassName="form-control"
            name="statusId"
            disabled={false}
            variant="standard"
            placeholder="Select"
            options={selectAction}
            defaultValue='Equal to'
            style={{
            marginBottom: 8,
            display: 'block',
            
          }}
        />
        <Input
          ref={searchInput}
          placeholder="Enter amount"
          className="ant-input form-control"
          type="number"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className='btn btn-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 90,
            }}
          >
            SEARCH
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            className='btn btn-info'
            style={{
              width: 90,
            }}
          >
            RESET
          </Button>
        </Space>
      </div>
  );
}

export default AmountFilter;
