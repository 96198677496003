import React from 'react';
import { Link } from 'react-router-dom';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  AntTextArea as TextArea,
} from '../../../../index';
import UploadInput from '../../../../UIElements/Upload';
import { initValuesStepOne, validationStepOne } from './validation';

function CompanyProfileOne({ onSubmit }) {
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validationStepOne}
      initialValues={initValuesStepOne()}
      row={true}
      extraClassName="g-3"
    >
      <FormElementWrapper
        md={6}
        label="Company Name"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="companyName"
            placeholder="Enter company name"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Upload company Logo"
        mandatory="true"
        element={(formik) => (
          // <UploadInput name="identityFront" formik={formik} />
          <TextInput
            name="uploadLogo"
            placeholder="select company logo"
            formik={formik}
            extraClassName="form-control-lg"
            type="file"
          />
        )}
        imgPreviewBox={true}
      />
      <FormElementWrapper
        md={12}
        label="Company Address"
        mandatory="true"
        element={(formik) => (
          <TextArea
            name="companyAddress"
            formik={formik}
            className="form-control form-control-lg textarea"
            maxLength="500"
            minLength="25"
            placeholder="Enter company address "
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Number Of Office Staff"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="staffNumber"
            inputType="number"
            placeholder="Enter number of office staff"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Number Of Field Technicians/Installers"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="technicianNumber"
            inputType="number"
            placeholder="Enter number Of field Technicians/Installers"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={12}
        label="Area Served (enter zip codes or cities)"
        mandatory="true"
        element={(formik) => (
          <TextArea
            name="areaServed"
            formik={formik}
            className="form-control form-control-lg textarea"
            maxLength="500"
            minLength="25"
            placeholder="Seperate each with comma ',' "
          />
        )}
      />
      <div className='text-center'>
        <div className="form-group">
          <Link to="/nca/register-company-step-two"
            type="submit"
            className="btn btn-lg btn-primary"
          >
            Save & Next
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
}

export default CompanyProfileOne;
