export const validation = {
  address: 'Please enter address',
  gender: 'Please select gender',
  profileImage: 'Please upload profile image',
  country: 'Please select country',
  field: 'This field is required.',
  notes: 'Please enter notes',
  suiteNumber: 'Please enter suite number',
  state: 'Please select state',
  city: 'Please select city',
  zipCode: 'Please enter zip code',
  name: 'Please enter name',
  bankName: 'Please enter Bank Name',
  checkNumber: 'Please enter check number',
  cardHolder: 'Please enter card holder name',
  cardNumber: 'Please enter card number',
  cvv: 'Please enter cvv number',
  expireYear: 'Please select expire year',
  expireMonth: 'Please select expire month',
  dateOfBirth: 'Please select date of birth',
  companyName: 'Please enter company name',
  homeAddress: 'Please enter home address',
  employerId: 'Please enter employer id number',
  uploadDocument: 'Please upload documents',
  userRole: 'Please select user role',
  location: 'Please select location',
  documentName: 'Please enter document name',
  roleName: 'Please enter role name',
  roleAssign: 'Please select role assign',
  description: 'Please enter description',
  status: 'Please select status',
};
