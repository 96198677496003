import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActionWrapper,
  Breadcrumb,
  PageHeader,
  TablePlus,
} from '../../../../../components';

function ListHomePage(props) {
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Edit Home Page',
    },
  ];
  
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'title',
      title: 'Title',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/edit-homepage',
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
    ]);
  const pageData = [
    {
      id: 1,
      title: 'Home Page',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Home Page Content">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        isSearch={false}
        isResetBtn= {false}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                  Click on Action "Edit" icon to change the content for home page
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListHomePage;
