import React from 'react';
import { AdminHeader } from '../../../features/index.feature';
import { useLocation } from "react-router-dom";
import AdminFooter from '../../AdminFooter';

function SidebarWrapper({ menuToggle, children, sidebarOpen, nav }) {
  const location = useLocation();
  // console.log("Location", location)
  return (
    <div>
      <div className="nk-body bg-lighter npc-general has-sidebar">
        <div className="nk-app-root">
          <div className="nk-main ">
            {nav}
            
            {/* {sidebarOpen && <div class="nk-sidebar-overlay" data-target="sidebarMenu"/>} */}
            <div className="nk-wrap">
              <AdminHeader menuToggle={menuToggle} />
              <div className={`nk-content ${location?.pathname==="/admin/chat"?"p-0":""} ${location?.pathname==="/admin/permission"?"p-0":""} ${location?.pathname==="/admin/chat/nodata-chat"?"p-0":""}`}>
                <div className={`container-fluid ${location?.pathname==="/admin/chat"?"p-0": ""} ${location?.pathname==="/admin/permission"?"p-0":""} ${location?.pathname==="/admin/chat/nodata-chat"?"p-0":""}`}>
                  <div className="nk-content-inner">
                    <div className="nk-content-body">{children}</div>
                  </div>
                </div>
              </div>
              <AdminFooter/>
            </div>
          </div>
          {sidebarOpen ? <div onClick={() => menuToggle()} className="nk-sidebar-overlay"/> : ''}
        </div>
      </div>
    </div>
  );
}

export default SidebarWrapper;
