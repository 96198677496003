import React from 'react';
import { Form, DatePicker as AntDatePicker } from 'antd';
import { useField } from 'formik';

function DatePicker({ name, ...rest }) {
  // const [field, meta, helpers] = useField(name);
  // const config = { ...field, ...rest };
  // if (meta && meta.touched && meta.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }
  // const handleChangeDate = (value) => {
  //   helpers.setValue(value);
  //   helpers.setError('');
  //   helpers.setTouched(false);
  // };
  return (
    <Form.Item
      label={rest?.label}
      name={name}
      // help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
      // validateStatus={config?.error ? 'error' : 'success'}
    >
      <AntDatePicker
        style={{
          width: '100%',
        }}
        // onChange={handleChangeDate}
        {...rest}
      />
    </Form.Item>
  );
}

export default DatePicker;
