import React from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
} from '../../../components';

function NewEquipmentBookingLog(props) {
    const breadcrumb = [
        {
          path: '/admin/dashboard',
          name: 'Dashboard',
        },
        {
          path: '/admin/new-equipment',
          name: 'New Equipment Install',
        },
        {
          path: '#',
          name: 'Booking Log',
        },
      ];
    
      const navigate = useNavigate()
      return (
        <>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <PageHeader heading="Booking Log">
                <Breadcrumb breadcrumb={breadcrumb} />
              </PageHeader>
              <ListingHeader
                btnArray={["extraButton", 'create']}
                btnText="View Quote"
                onHandleShow={ () => navigate('/admin/new-equipment/view-quote')}
                extraBtnText="Back"
                extraBtnClass="btn btn-outline-light bg-white"
                onExtraButtonHandleShow={ () => navigate('/admin/new-equipment')}
              />
            </div>
          </div>
          <div className="nk-block">
              <div className="card">
                <div className="card-inner">
                <h5 className="title mb-4">Trip Booking No: 75833147</h5>
                  <div className='table-responsive'>
                    <Table bordered size="lg">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Date</th>
                          <th>Log</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>11/11/2022 at 03:50 PM</td>
                          <td>	Job Booked by Customer at 11/11/2022 at 03:50 PM (Shane Watson by Web)</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>11/11/2022 at 03:51 PM</td>
                          <td>Job sent to NAC by User for jorge neon<Link to="/admin/new-equipment/view-quote" >(View Invoice)</Link> </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>11/11/2022 at 03:51 PM</td>
                          <td>Send to NAC Notification sent to Provider jorge neon</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>11/11/2022 at 03:51 PM</td>
                          <td>Send to NAC SMS sent to Provider jorge neon</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>11/11/2022 at 04:00 PM</td>
                          <td>	System sent email to Admin notifying of 5 minutes job has been in NAC for jorge neon<Link to="/admin/new-equipment/view-quote" >(View Invoice)</Link> </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
        </>
      );
}

export default NewEquipmentBookingLog;
