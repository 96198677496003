import { Select } from "antd";
import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  GoogleGMBFilterForm,
  ActionWrapper,
  FormWrapper,
  FormElementWrapper,
  CommonButton,
} from "../../../../components";
import {
  Columns,
   GoogleConnectGMBServices,
} from "../../../../services/index.service";

function GMBListing(props) {
  const EquipmentOrderData = GoogleConnectGMBServices();


  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Google My Business",
    },
  ];
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }
  const statusActionOption = [
    {
      name: 'Johnnie Firari',
      value: 'Johnnie Firari'
    },
    {
      value: 'Indiana USA Tekhne',
      name: 'Indiana USA Tekhne'
    },
    {
      value: 'Virginia',
      name: 'Virginia'
    }
  ]
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const navigate = useNavigate()
  const columns = Columns.googleGMBServiceTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "See Your Profile",
          icon: "icon ni ni-eye",
        },
        {
          name: "Photos",
          icon: "icon ni ni-camera",
          handler: () =>{ navigate('/admin/google-gmb/photo')},
        },
        {
          name: "Post",
          icon: "icon ni ni-file-docs",
          handler: () =>{ navigate('/admin/google-gmb/post')},
        },
      ]),
  });
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Google My Business">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[""]}
            popover={<></>}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select google GMB"
                        options={statusActionOption}
                        showSearch
                        formik={formik}
                        defaultValue="Johnnie Firari"
                      />
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        isSearch={false}
        showTableCountNumber={false}
        isResetBtn={false}
      />
    </>
  );
}

export default GMBListing;
