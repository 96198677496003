import { Select, TimePicker } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  FormElementWrapper,
  FormWrapper,
  PageHeader,
} from "../../../components";


function TimeSlot(props) {

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Manage Slots",
    },
  ];
  const TimeIntervalData = [
    {
      name: '20 Min',
      value: '20 Min',
    },
    {
      name: '40 Min',
      value: '40 Min',
    },
    {
        name: '1 hr',
        value: '1 hr',
    },
  ];
  return (
    <>
    <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
            <PageHeader heading="Manage Slots">
                <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
        </div>
    </div>
    <div className="card">
        <div className="card-inner">
            <h5 className="title mb-3">Time Slot</h5>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper  extraClassName="w-100">
                                <FormElementWrapper
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Monday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">MONDAY</h6>
                    </div>
                    <ul className="d-flex mb-0 list-unstyled align-items-center flex-wrap">
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 08 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                08 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                11 AM - 02 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                02 PM - 05 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 10 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                07 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 PM - 04 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 AM - 04 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 08 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                08 AM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 01 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 08 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                08 AM - 07 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 PM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 06 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 AM - 04 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 PM - 11 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                08 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper extraClassName="w-100">
                                <FormElementWrapper
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Tuesday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">TUESDAY</h6>
                    </div>
                    <ul className="d-flex mb-0 list-unstyled align-items-center flex-wrap">
                        <li>
                            <div className="timeSlot_box">
                                03 AM - 04 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 08 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                08 AM - 07 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 PM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 AM - 06 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 10 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                07 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 PM - 04 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 PM - 04 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                09 PM - 11 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper  extraClassName="w-100">
                                <FormElementWrapper
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Wednesday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">WEDNESDAY</h6>
                    </div>
                    <ul className="d-flex mb-0 list-unstyled align-items-center flex-wrap">
                        <li>
                            <div className="timeSlot_box">
                                03 AM - 04 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                06 AM - 09 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                01 AM - 02 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                10 AM - 11 AM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                05 PM - 10 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                07 PM - 08 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 PM - 04 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                        <li>
                            <div className="timeSlot_box">
                                03 PM - 04 PM
                                <Link to="#" className="timeSlot_icon"><em className="icon ni ni-cross-sm"></em></Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper extraClassName="w-100">
                                <FormElementWrapper
                                    md={12}
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Thursday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">THURSDAY</h6>
                    </div>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper  extraClassName="w-100">
                                <FormElementWrapper
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Friday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">FRIDAY</h6>
                    </div>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper extraClassName="w-100">
                                <FormElementWrapper
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Saturday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">SATURDAY</h6>
                    </div>
                </div>
            </div>
            <div className="divider sm"></div>
            <div className="form-group">
                <Row className="align-items-center g-2 g-lg-4">
                    <Col sm={5}  md={5} lg={4} xxl={3} className="mb-1 mb-sm-0">
                        <div className="d-md-flex align-items-center">
                            <label className="form-label me-2 text-nowrap mb-0">Time Interval</label>
                            <FormWrapper  extraClassName=" w-100">
                                <FormElementWrapper
                                    md={12}
                                    element={(formik) => (
                                        <Select
                                        name="timeInterval"
                                        placeholder="Select time interval"
                                        options={TimeIntervalData}
                                        showSearch
                                        formik={formik}
                                        />
                                    )}
                                />
                            </FormWrapper>
                        </div>
                    </Col>
                    <Col sm={7} md={7} xl={5} xxl={4}>
                        <div className="d-md-flex align-items-center w-100">
                            <label className="form-label me-2 text-nowrap mb-0">Sunday</label>
                            <div className="d-flex flex-nowrap align-items-center w-100">
                                <TimePicker.RangePicker />
                                <Link to="#" className="ms-3 text-primary">
                                    Add
                                </Link> 
                            </div>
                        </div>
                    </Col>   
                </Row>
            </div>
            <div className="bq-note my-3 timeSlot">
                <div className="bq-note-text mt-2">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">SUNDAY</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default TimeSlot;
