import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  GoogleAdwordsFilterForm,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  DatePicker,
} from "../../../../components";
import {
  Columns,
   GoogleConnectAdwordsServices,
} from "../../../../services/index.service";

function AdwordListing(props) {
  const EquipmentOrderData = GoogleConnectAdwordsServices();


  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Google Adwords List",
    },
  ];
  const columns = Columns.googleAdwordsServiceTableColumn({budgetViewModal});
  const [addModal, setAddModal] = useState(false);
    const addModalHide = () =>{
        setAddModal(false)
    };
    function budgetViewModal(){
        setAddModal(true)
    }
    const ServiceFilterData = [
      {
        value: 'Tekhne of Fort Worth, Texas',
        name: 'Tekhne of Fort Worth, Texas'
      },
      {
        value: 'Good Guys Heating & Air Conditioning',
        name: 'Good Guys Heating & Air Conditioning'
      },
      {
        value: 'Tekhne Home Services of Bethel',
        name: 'Tekhne Home Services of Bethel'
      },
      {
        value: 'Tekhne of Port Orange, Florida',
        name: 'Tekhne of Port Orange, Florida'
      }
    ]
    const filterData = [
      {
        name: 'Last 30 Days',
        value: 'Last 30 Days',
      },
      {
        name: 'Today',
        value: 'Today',
      },
      {
        name: 'Yesterday',
        value: 'Yesterday',
      },
      {
        name: 'Last Business Week',
        value: 'Last Business Week',
      },
      {
        name: 'Last Month',
        value: 'Last Month',
      },
      {
        name: 'This Month',
        value: 'This Month',
      },
    ]
    const[globalFilter, setGlobalFilter ] = useState(false);
    const BackBtnFilter = () => {
      setGlobalFilter(false)
    }
    const OpenBtnFilter = () => {
      setGlobalFilter(true)
    }
    if (globalFilter) {
      document.querySelector('body').classList.add('toggle-shown');
    } else {
      document.querySelector('body').classList.remove('toggle-shown');
    }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Google Adwords List">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ "csvExport"]}
            popover={<></>}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="service"
                        placeholder="Select services"
                        options={ServiceFilterData}
                        showSearch
                        formik={formik}
                        defaultValue="Tekhne of Fort Worth, Texas"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        isSearch={false}
        showAction={false}
        isResetBtn={false}
          showTableCountNumber={false}
      />
      <ModalComponent
        backdrop
        show={addModal}
        onHandleCancel={addModalHide}
        title="Edit Budget"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <h6 className="title mb-0">2_GEN</h6>
          <FormElementWrapper
            md={12}
            label="Budget"
            element={(formik) => (
              <TextInput
                name="firstName"
                placeholder="Enter budget"
                formik={formik}
              />
            )}
            formnote= "Actual daily spend may vary"
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addModalHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default AdwordListing;
