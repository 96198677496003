import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link , useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Charts,
  PaymentReportFilterForm,
  CommonButton,
  TablePlus,
  InputFilter,
  SelectFilter,
  AmountFilter,
  MultiSelectFilter,
  
} from '../../../../components';
import { FilterFilled  } from "@ant-design/icons";

function ProviderDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service-provider-payment',
      name: 'Service Provider Payment Report',
    },
    {
      path: '#',
      name: 'Provider Payment Report Details',
    },
  ];
  const ButtonFormatter = (url, text) => {
    return (
      <>
        <Link to={url} className="">{text}</Link>
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getProviderListData = () => {
    return [
      {
        name: 'abhishekYadav',
        value: 'Abhishek Yadav',
      },
      {
        name: 'aditiTest',
        value: 'Aditi Test',
      },
    ];
  };
  const getPaymentMethodData = () => {
    return [
      {
        name: 'Cash',
        value: 'Cash',
      },
      {
        name: 'Card',
        value: 'Card',
      },
    ];
  };
  const getPaymentTypeData = () => {
    return [
      {
        name: 'Full Payment',
        value: 'Full Payment',
      },
      {
        name: 'Split Payment',
        value: 'Split Payment',
      },
    ];
  };
  const getProviderPaymentsStatusData = () => {
    return [
      {
        name: 'Settled',
        value: 'Settled',
      },
      {
        name: 'Unsettled',
        value: 'Unsettled',
      },
    ];
  };
  const getPaymentTransferStatusData = () => {
    return [
      {
        name: 'Pending',
        value: 'Pending',
      },
      {
        name: 'Transfered',
        value: 'Transfered',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      headerClasses: 'sorting',
       sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/payment-report/booking-log", "75833147"),
      ...getColumnSearchProps("job number"),
    },
    {
      dataIndex: 'provider',
      title: 'Provider',
      headerClasses: 'sorting',
       sorter: true,
      ...getColumnMultiSelectSearchProps("provider name", getProviderListData())
    },
    {
      dataIndex: 'user',
      title: 'User',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnMultiSelectSearchProps("user name", getProviderListData())
    },
    {
      dataIndex: 'jobDate',
      title: 'Job Date',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'location',
      title: 'Location',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'totalFees',
      title: 'A=Total Fees',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'platformFees',
      title: 'B=Platform Fees',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'promoCodeDiscount',
      title: 'C= Promo Code Discount',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'providerDiscountAmount',
      title: 'D=Job Provider Discount Amount',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'purchaseOrderTotal',
      title: 'E=Purchase Order Total',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> <CommonButton onClick= { (e) => PurchaseOrderTotal(e) } className="btn btn-primary btn-sm">View Details</CommonButton></>
      }
    },
    {
      dataIndex: 'sublaborTotalFees',
      title: 'F=Sublabor Total Fees ',
      headerClasses: 'sorting',
      ...getColumnSelectAmountProps(),
       sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> <CommonButton onClick= { (e) => SubLaborPayDetails(e) } className="btn btn-primary btn-sm">View Details</CommonButton></>
      }
    },
    {
      dataIndex: 'returnedPO',
      title: 'G = Returned PO',
      headerClasses: 'sorting',
      ...getColumnSelectAmountProps(),
       sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> <CommonButton onClick= { (e) => ReturnAmountDetails(e) } className="btn btn-primary btn-sm">View Details</CommonButton></>
      }
    },
    {
      dataIndex: 'providerPayAmount',
      title: 'Provider Pay Amount H =A-B-E-F+G',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'jobStatus',
      title: '	Job Status',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'jobStartTime',
      title: 'Job Start Time',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'jobEndTime',
      title: 'Job End Time',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'jobCompletionTime',
      title: 'Job Completion Time',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'paymentMethod',
      title: 'Payment method',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectSearchProps("payment method", getPaymentMethodData()),
    },
    {
      dataIndex: 'jobPaymentType',
      title: 'Job Payment Type',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectSearchProps("job payment type", getPaymentTypeData()),
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> <CommonButton onClick= { (e) => SplitPaymentDetails(e) } className="btn btn-primary btn-sm">Split Payments Details</CommonButton></>
      }
    },
    {
      dataIndex: 'providerPaymentStatus',
      title: 'Provider Payment Status',
      headerClasses: 'sorting',
      ...getColumnSelectSearchProps("provider payment status", getProviderPaymentsStatusData()),
       sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> <CommonButton onClick= { (e) => ProviderInvoiceUpdateDetails(e) } className="btn btn-primary btn-sm">View Updated Invoice Details </CommonButton></>
      }
    },
    {
      dataIndex: 'selectAll',
      title: 'Select All',
      headerClasses: 'sorting',
       sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <Checkbox /> <br/><CommonButton onClick={ (e) => InvoicePage(e)} className="btn btn-primary btn-sm">View Invoice</CommonButton></>
      }
    },
    {
      dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectSearchProps("status", getPaymentTransferStatusData()),
    },
  ];
  const adminData = [
    {
      id: 1,
      provider: "jorge neon",
      user: "Shane Watson",
      jobDate: "19th November, 2022 06:35 PM",
      location: "Vijay Nagar, Indore",
      totalFees: "$ 5,400.00",
      platformFees: "-",
      promoCodeDiscount: "$ 0.00	",
      providerDiscountAmount: "$ 8.00	",
      purchaseOrderTotal: "$5.00",
      sublaborTotalFees: "$ 500.00",
      returnedPO: "$ 200.00",
      providerPayAmount: "$ 4,700.00	",
      jobStatus: "Finished",
      jobStartTime: "15th November, 2022 06:35:59	",
      jobEndTime: "16th November, 2022 03:15:54",
      jobCompletionTime: "20h 39m 55s",
      paymentMethod: "Cash",
      jobPaymentType: "Split Payment",
      providerPaymentStatus: "Settled",
      paymentTransferStatus: "Transfered",
    },
  ];
  const [graphModal, setgraphModal] = useState(false);
  const graphModalShow = () => {
    setgraphModal(true);
  };
  const graphModalHide = () => {
    setgraphModal(false);
  };
  const navigate = useNavigate()
  const InvoicePage = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/invoice'); 
  }
  const PurchaseOrderTotal = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/purchase-order');
  }
  const SubLaborPayDetails = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/sublabor-pay');
  }
  const ReturnAmountDetails = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/return-amount');
  }
  const SplitPaymentDetails = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report/split-payment');
  }
  const ProviderInvoiceUpdateDetails = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report/provider-invoice-update');
  }
  const jobData = {
    labels: [
      'Jan 21',
      'Mar 21',
      'May 21',
      'Jul 21',
      'Sep 21',
    ],
    datasets: [
      {
        label: '# of jobData',
        data: [100000, 75000, 50000, 20000, 5000],
        backgroundColor: [
          '#09C2DE',
          '#1EE0AC',
          '#E5E9F2',
          '#F4BD0E',
          '#854FFF',
        ],
        borderWidth: 0,
      },
    ],
  };
  const [totalJobAmountTable, setTotalJobAmountTable] = useState(false);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Provider Payment Report Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['filter','create', 'csvExport', '']}
            btnText="Show / Hide Graph"
            onHandleShow={graphModalShow}
            popover={ <PaymentReportFilterForm/>}
          />
        </div>
      </div>
        {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
      <div className='text-end my-4'>
          <CommonButton className="btn btn-primary" onClick= { () => setTotalJobAmountTable(!totalJobAmountTable) }>Total Jobs Amount Details</CommonButton>
      </div>
      {
        totalJobAmountTable && 
        <>
        <div className='nk-block'>
          <div className="card">
            <div className="card-inner">
              <h5 className="title mb-4">Total Jobs Amount Details</h5>
                <div className='table-responsive'>
                  <Table bordered size="md">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Services</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Earning from Customer </td>
                        <td>$5,400.00</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Total Platform fees collected from service provider </td>
                        <td>$0.00</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Total Promo Discount  </td>
                        <td>$ 0.00</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Total Provider Discount Amount (included) </td>
                        <td>$ 8.00</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Total Sublabor Pay Amount </td>
                        <td>$ 800.00</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Total Deduct Amount (Sublabor Pay)</td>
                        <td>$ 80.00</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Total Deduct Amount (Purchase Order)	</td>
                        <td>$ 8.00</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Total Provider Payment  </td>
                        <td>$ 4700.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
            </div>
          </div>
        </div>
        </>
      }
      <ModalComponent
        backdrop
        show={graphModal}
        onHandleCancel={graphModalHide}
        title="Graph"
      >
        <div style={{ maxWidth: '500px', width: '100%', margin: '0 auto' }}>
          <Charts type="bar" data={jobData} />
        </div>
        <ul className="nk-store-statistics mt-4">
          <li className="item pt-0">
            <div className="info me-3">
              <div className="title">COLLECTED</div>
              <div className="count d-flex flex-wrap align-items-center">
                $20115773.28
                <span className="change down">
                  <em className="ni ni-arrow-long-down"></em>2696 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-info-dim ni ni-activity-round"></em>
          </li>
          <li className="item pt-0">
            <div className="info me-3">
              <div className="title">UNCOLLECTED</div>
              <div className="count d-flex flex-wrap align-items-center">
              $751732.75
                <span className="change up">
                  <em className="ni ni-arrow-long-up"></em>534 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-purple-dim ni ni-activity-round"></em>
          </li>
        </ul>
      </ModalComponent>
    </>
  );
}

export default ProviderDetails;
