import { Calendar, Radio, TimePicker } from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import {
  AntSelect,
  Breadcrumb,
  CommonButton,
  ListingHeader,
  ModalComponent,
  PageHeader,
  Tabs,
  Toggle,
  InputType,
} from '../../../../../components';

function EditAvailability(props) {
  const [defaultKey, setDefaultKey] = useState('availability');
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/provider',
      name: 'Providers',
    },
    {
      path: '#',
      name: 'Edit Availability',
    },
  ];
  const backPage = () => {
    navigate('/admin/provider');
  };
  const ApproveBookingData = [
    {
      id: '1',
      name: 'Select Auto Approve Booking',
    },
    {
      id: '2',
      name: 'If more than 2 days out',
    },
    {
      id: '3',
      name: 'If more than 3 days out',
    },
    {
      id: '4',
      name: 'If more than 5 days out',
    },
    {
      id: '5',
      name: 'If more than 7 days out',
    },
  ];
  const [showRebates, setRebates] = useState(false);
  const RebatesModal = () => {
    setRebates(true);
  };
  const WorkLocationData = [
    {
      id: '1',
      name: 'Any Location',
    },
  ];
  const JobRadiusData = [
    {
      id: '1',
      name: '5',
    },
    {
      id: '2',
      name: '10',
    },
    {
      id: '3',
      name: '15',
    },
    {
      id: '4',
      name: '31',
    },
    {
      id: '5',
      name: 'Other',
    },
  ];
  const [rediovalue, setrediovalue] = useState(1);
  const [someDaysRediovalue, setSomeDaysRediovalue] = useState('different');
  const tabContent = [
    {
      name: 'Availability',
      key: 'availability',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <h6 className="title nk-block-title">
                Service Provider Manual Working Time{' '}
                <span className="text-danger">*</span>
              </h6>
              <Radio.Group value={rediovalue}>
                <Radio value={1} onClick={() => setrediovalue(1)}>
                  No
                </Radio>
                <Radio value={2} onClick={() => setrediovalue(2)}>
                  Yes (Set Service Provider Working Time Manually)
                </Radio>
              </Radio.Group>
              {rediovalue === 1 && (
                <>
                  <div className="bq-note">
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">MONDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="monBtnCheck-no"
                              id="monBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="monBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="monBtnCheck-no"
                              id="monBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="monBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="monBtnCheck-no"
                              id="monBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="monBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="monBtnCheck-no"
                              id="monBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="monBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="monBtnCheck-no"
                              id="monBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="monBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">TUESDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="tuesBtnCheck-no"
                              id="tuesBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="tuesBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="tuesBtnCheck-no"
                              id="tuesBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="tuesBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="tuesBtnCheck-no"
                              id="tuesBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="tuesBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="tuesBtnCheck-no"
                              id="tuesBtnCheck4"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="tuesBtnCheck4"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="tuesBtnCheck-no"
                              id="tuesBtnCheck5"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="tuesBtnCheck5"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">WEDNESDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="wedBtnCheck-no"
                              id="wedBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="wedBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="wedBtnCheck-no"
                              id="wedBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="wedBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="wedBtnCheck-no"
                              id="wedBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="wedBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="wedBtnCheck-no"
                              id="wedBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="wedBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="wedBtnCheck-no"
                              id="wedBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="wedBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">THURSDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="thuBtnCheck-no"
                              id="thuBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="thuBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="thuBtnCheck-no"
                              id="thuBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="thuBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="thuBtnCheck-no
                              "
                              id="thuBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="thuBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="thuBtnCheck-no"
                              id="thuBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="thuBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="thuBtnCheck-no"
                              id="thuBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="thuBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">FRIDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="friBtnCheck-no"
                              id="friBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="friBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="friBtnCheck-no"
                              id="friBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="friBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="friBtnCheck-no"
                              id="friBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="friBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="friBtnCheck-no"
                              id="friBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="friBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="friBtnCheck-no"
                              id="friBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="friBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">SATURDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="satBtnCheck-no"
                              id="satBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="satBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="satBtnCheck-no"
                              id="satBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="satBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="satBtnCheck-no"
                              id="satBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="satBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="satBtnCheck-no"
                              id="satBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="satBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="satBtnCheck-no"
                              id="satBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="satBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="bq-note-text mt-2">
                      <div className="nk-block-head nk-block-head-sm nk-block-between">
                        <h6 className="title">SUNDAY</h6>
                      </div>
                      <ul className="custom-control-group">
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="sunBtnCheck-no"
                              id="sunBtnCheck1-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sunBtnCheck1-no"
                            >
                              05 AM - 08 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="sunBtnCheck-no"
                              id="sunBtnCheck2-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sunBtnCheck2-no"
                            >
                              08 AM - 11 AM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="sunBtnCheck-no"
                              id="sunBtnCheck3-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sunBtnCheck3-no"
                            >
                              11 AM - 02 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="sunBtnCheck-no"
                              id="sunBtnCheck4-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sunBtnCheck4-no"
                            >
                              02 PM - 05 PM
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="sunBtnCheck-no"
                              id="sunBtnCheck5-no"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sunBtnCheck5-no"
                            >
                              05 PM - 08 PM
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {rediovalue === 2 && (
                <>
                  <h6 className="title nk-block-title mt-3">
                    Please select time selection type{' '}
                    <span className="text-danger">*</span>
                  </h6>
                  <Radio.Group value={someDaysRediovalue}>
                    <Radio
                      value={'some'}
                      onClick={() => setSomeDaysRediovalue('some')}
                    >
                      Same for All days{' '}
                    </Radio>
                    <Radio
                      value={'different'}
                      onClick={() => setSomeDaysRediovalue('different')}
                    >
                      Different for All days
                    </Radio>
                  </Radio.Group>
                  {someDaysRediovalue === 'some' && (
                    <>
                      <div className="my-3">
                        <div className="form-group ">
                          <Row className="align-items-center">
                            <Col md={5} lg={4} xxl={2}>
                              <label className="form-label">
                                Set Service Provider Working Time{' '}
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={7} lg={7} xxl={5}>
                              <TimePicker.RangePicker className="w-75" />
                              <Link to="#" className="ms-3">
                                Reset
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="bq-note">
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">MONDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-same"
                                  id="monBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-same"
                                  id="monBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-same"
                                  id="monBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-same"
                                  id="monBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-same"
                                  id="monBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">TUESDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tueBtnCheck-same"
                                  id="tueBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tueBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tueBtnCheck-same"
                                  id="tueBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tueBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tueBtnCheck-same"
                                  id="tueBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tueBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tueBtnCheck-same"
                                  id="tueBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tueBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tueBtnCheck-same"
                                  id="tueBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tueBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">WEDNESDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-same"
                                  id="wedBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-same"
                                  id="wedBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-same"
                                  id="wedBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-same"
                                  id="wedBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-same"
                                  id="wedBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">THURSDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-same"
                                  id="thuBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-same"
                                  id="thuBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-same"
                                  id="thuBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-same"
                                  id="thuBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-same"
                                  id="thuBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">FRIDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="frifriBtnCheck-same"
                                  id="friBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-same"
                                  id="friBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-same"
                                  id="friBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-same"
                                  id="friBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-same"
                                  id="friBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">SATURDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-same"
                                  id="satBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-same"
                                  id="satBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-same"
                                  id="satBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-same"
                                  id="satBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-same"
                                  id="satBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">SUNDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-same"
                                  id="sunBtnCheck1-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck1-same"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-same"
                                  id="sunBtnCheck2-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck2-same"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-same"
                                  id="sunBtnCheck3-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck3-same"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-same"
                                  id="sunBtnCheck4-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck4-same"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-same"
                                  id="sunBtnCheck5-same"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck5-same"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                  {someDaysRediovalue === 'different' && (
                    <>
                      <div className="my-3">
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Monday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                              <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Tuesday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Wednesday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Thursday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Friday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Saturday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group mb-2">
                          <Row className="align-items-center">
                            <Col md={3} lg={2} xxl={1}>
                              <label className="form-label">Sunday</label>
                            </Col>
                            <Col md={8} lg={6} xxl={5}>
                            <TimePicker.RangePicker className="w-75" />
                              
                              <Link to="#" className="ms-3 text-primary">
                                Add
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="bq-note">
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">MONDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-diff"
                                  id="monBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-diff"
                                  id="monBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-diff"
                                  id="monBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-diff"
                                  id="monBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="monBtnCheck-diff"
                                  id="monBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="monBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">TUESDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tuesBtnCheck-diff"
                                  id="tuesBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tuesBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tuesBtnCheck-diff"
                                  id="tuesBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tuesBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tuesBtnCheck-diff"
                                  id="tuesBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tuesBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tuesBtnCheck-diff"
                                  id="tuesBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tuesBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="tuesBtnCheck-diff"
                                  id="tuesBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="tuesBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">WEDNESDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-diff"
                                  id="wedBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-diff"
                                  id="wedBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-diff"
                                  id="wedBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-diff"
                                  id="wedBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="wedBtnCheck-diff"
                                  id="wedBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="wedBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">THURSDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-diff"
                                  id="thuBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-diff"
                                  id="thuBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-diff"
                                  id="thuBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-diff"
                                  id="thuBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="thuBtnCheck-diff"
                                  id="thuBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="thuBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">FRIDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-diff"
                                  id="friBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-diff"
                                  id="friBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-diff"
                                  id="friBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-diff"
                                  id="friBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="friBtnCheck-diff"
                                  id="friBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="friBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">SATURDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-diff"
                                  id="satBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-diff"
                                  id="satBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-diff"
                                  id="satBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-diff"
                                  id="satBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="satBtnCheck-diff"
                                  id="satBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="satBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="bq-note-text mt-2">
                          <div className="nk-block-head nk-block-head-sm nk-block-between">
                            <h6 className="title">SUNDAY</h6>
                          </div>
                          <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-diff"
                                  id="sunBtnCheck1-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck1-diff"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-diff"
                                  id="sunBtnCheck2-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck2-diff"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-diff"
                                  id="sunBtnCheck3-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck3-diff"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-diff"
                                  id="sunBtnCheck4-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck4-diff"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="sunBtnCheck-diff"
                                  id="sunBtnCheck5-diff"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sunBtnCheck5-diff"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="text-end mt-4">
                <Link href="#" className="btn btn-primary">
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Unavailability',
      key: 'unavailability',
      content: (
        <>
          <div className="card unavailabilitySec">
            <div className="card-inner">
              <div className="card-bordered">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                      <div className="nk-block-text w-75">
                        <h6>Time Off</h6>
                        <p className="mb-0">
                          <b>
                            Set Day(s) or Time Frame(s) you are not available to
                            work.
                          </b>
                        </p>
                        <p className="mb-2">
                          To block off day(s) or time frame(s) hit the Select
                          Date button below. Choose Block Entire Day or select
                          the time frame(s) you need to block off for that date.
                        </p>
                        <CommonButton
                          variant="primary"
                          onClick={() => selectDateModalShow()}
                        >
                          Select Date
                        </CommonButton>
                        <p className="mb-0 mt-2">
                          When you are set to Online you will be sent emergency
                          service request 24|7. This should only be on if you
                          available to run the service request.
                        </p>
                      </div>
                      <div className="nk-block-actions text-sm-center w-25">
                        <Toggle /> <span>(Restrict for service provider)</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                      <div className="nk-block-text w-75">
                        <h6>
                        <Link to="#" onClick={() => RebatesModal()}>
                          <em className="icon ni ni-info me-1" />
                        </Link>
                        Set Auto Approve Booking</h6>
                        <form>
                          <div className="form-group ">
                            <label className="form-label">
                              Select Approve All Booking Automatically
                            </label>
                            <AntSelect
                              size="medium"
                              id="status"
                              extraClassName="form-control"
                              name="statusId"
                              disabled={false}
                              variant="standard"
                              placeholder="Select approve all booking automatically"
                              options={ApproveBookingData}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="nk-block-actions text-sm-center w-25">
                        <Toggle /> <span>(Restrict for service provider)</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                      <div className="nk-block-text w-75">
                        <h6>SP Work Location</h6>
                        <form>
                          <div className="form-group ">
                            <label className="form-label">
                              Work Location is the service area SP will provide
                              service.
                            </label>
                            <AntSelect
                              size="medium"
                              id="status"
                              extraClassName="form-control"
                              name="statusId"
                              disabled={false}
                              variant="standard"
                              placeholder="Select work location"
                              options={WorkLocationData}
                            />
                             <InputType
                              className="form-control mt-3"
                              placeholder="Enter your location"
                              defaultValue="5651 N Red Oak Dr, Greenfield, IN 46140, USA"
                              name="documentType"
                              disabled={false}
                              variant="standard"
                              type="text"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="nk-block-actions text-sm-center w-25">
                        <Toggle /> <span>(Restrict for service provider)</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner border-bottom-0">
                    <div className="between-center flex-wrap flex-md-nowrap g-3">
                      <div className="nk-block-text w-75">
                        <h6>SP Job Radius</h6>
                        <form>
                          <div className="row g-3">
                            <div className="col-md-12">
                              <div className="form-group ">
                                <label className="form-label">
                                  Schedule Job Radius
                                </label>
                                <AntSelect
                                  size="medium"
                                  id="status"
                                  extraClassName="form-control"
                                  name="statusId"
                                  disabled={false}
                                  variant="standard"
                                  placeholder="Select job radius"
                                  options={JobRadiusData}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group ">
                                <label className="form-label">
                                  Emergency Job Radius
                                </label>
                                <AntSelect
                                  size="medium"
                                  id="status"
                                  extraClassName="form-control"
                                  name="statusId"
                                  disabled={false}
                                  variant="standard"
                                  placeholder="Select emergency job radius"
                                  options={JobRadiusData}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="nk-block-actions text-sm-center w-25">
                        <Toggle /> <span>(Restrict for service provider)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 text-end">
                <Link href="#" className="btn btn-primary">
                  Update
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const [selectDateModal, setselectDateModal] = useState(false);
  const selectDateModalShow = () => {
    setselectDateModal(true);
  };
  const selectDateModalHide = () => {
    setselectDateModal(false);
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Availability">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <Tabs
        tabContent={tabContent}
        tabsFor="table"
        activeKey={defaultKey}
        setActiveKey={setDefaultKey}
        // onTabChange={onTabChange}
      />
      <ModalComponent
        backdrop
        show={selectDateModal}
        onHandleCancel={selectDateModalHide}
        title="Select Block Days"
        extraBodyClassName="p-0"
      >
        <div className="text-center selectBlockDays">
          <Calendar fullscreen={false} />
          <div className="divider sm mt-0" />
          <ul className="custom-control-group">
            <li>
              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="BtnCheck"
                  id="BtnCheck11"
                />
                <label className="custom-control-label" htmlFor="BtnCheck11">
                  05 AM - 08 AM
                </label>
              </div>
            </li>
            <li>
              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="BtnCheck"
                  id="BtnCheck22"
                />
                <label className="custom-control-label" htmlFor="BtnCheck22">
                  08 AM - 11 AM
                </label>
              </div>
            </li>
            <li>
              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="BtnCheck"
                  id="BtnCheck33"
                />
                <label className="custom-control-label" htmlFor="BtnCheck33">
                  11 AM - 02 PM
                </label>
              </div>
            </li>
          </ul>
          <div className="divider sm" />
          <div className="text-center mb-3">
            <Link
              href="#"
              onClick={() => selectDateModalHide()}
              className="btn btn-primary me-2"
            >
              Block Time Frame
            </Link>
            <Link
              href="#"
              onClick={() => selectDateModalHide()}
              className="btn btn-light"
            >
              Block Entire Day
            </Link>
          </div>
          <p className="form-note mb-3">
            <b>Note: (_) Is representing day/slot is blocked.</b>
          </p>
        </div>
      </ModalComponent>
      <ModalComponent
        closeButton={true}
        extraTitleClassName="justify-content-center"
        onHandleCancel={() => {
            setRebates(false);
        }}
        backdrop
        show={showRebates}
        title="Set Auto Approve Booking"
      >
        <p>Customers will be able to schedule you by selecting “Book Later” appointments through the App, on the day and time frames you have made available and do not have a appointment already scheduled. It is best to set “Approve All Bookings Automatically” to increase your chance of filling your work schedule. At times, you may want to not allow next day bookings. You are provided an option to set the following: Approve All Bookings Automatically,2 Days Out, 3 Days Out or 7 Days Out. These are calendar days, not business days. Any appointment beyond 7 days you would be required to accept the booking automatically. If a customer books an appointment earlier than what you have as “Auto Approved” you will have one hour to confirm the appointment, before it is sent to the next available service provider.</p>
      </ModalComponent>
    </>
  );
}

export default EditAvailability;
