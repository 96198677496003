import { APIRequest } from '../../helpers/index.helper';
import { APP_TEXT_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { logger } from '../../utils';
export const informationService = async (body) => {
  try {
    const payload = {
      ...APP_TEXT_ENDPOINT.importantInformation,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
