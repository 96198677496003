import { login, changePassword, signup } from './Auth';
import { appLanguage as common } from './Common/index.i18n';
import { appLanguage as localization } from './Localization/index.i18n';
import { appLanguage as appText } from './AppText/index.i18n';
export default function lang() {
  return {
    text: {
      common: common.text,
      login: login.text,
      changePassword: changePassword.text,
      signup: signup.text,
      localization: localization.text,
      appText: appText.text,
    },
    validation: {
      common: common.validation,
      login: login.validation,
      changePassword: changePassword.validation,
      signup: signup.validation,
      appText: appText.validation,
    },
  };
}
