export const orderManagementService = () => {
    return [
      {
        id: 1,
        orderID: '2',
        SPName: 'James Watt',
        email: 'johnt@mailinator.com',
        OrderType: 'Self pickup',
        shippingAddress: '-',
        totalCost: '$150',
        orderDate: '04/27/2022',
      },
      {
        id: 2,
        orderID: '5',
        SPName: 'Joe testing',
        email: 'johnt@mailinator.com',
        OrderType: 'Shipping',
        shippingAddress: 'Vijay Nagar, Indore',
        totalCost: '$180',
        orderDate: '04/18/2022',
      },
      {
        id: 3,
        orderID: '10',
        SPName: 'User',
        email: 'user@gmail.com',
        OrderType: 'Shipping',
        shippingAddress: 'Vijay Nagar, Indore',
        totalCost: '$70',
        orderDate: '01/24/2022',
      },
      {
        id: 4,
        orderID: '3',
        SPName: 'Admin',
        email: 'admin@mailinator.com',
        OrderType: 'Self pickup',
        shippingAddress: '-',
        totalCost: '$100',
        orderDate: '05/25/2022',
      },
      {
        id: 5,
        orderID: '2',
        SPName: 'James Watt',
        email: 'johnt@mailinator.com',
        OrderType: 'Shipping',
        shippingAddress: 'Vijay Nagar, Indore',
        totalCost: '$200',
        orderDate: '06/27/2022',
      },
    ];
  };
  