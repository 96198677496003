import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Toggle,
  ActionWrapper,
  SweetAlert,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../utils';
import Select from '../../../../components/UIElements/Select';
import { FilterFilled  } from "@ant-design/icons";

function AdminListing(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Delete Admin Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Admins',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getRolesData = () => {
    return [
      {
        name: 'Sub Admin',
        value: 'Sub Admin',
      },
      {
        name: 'Billing Admin',
        value: 'Billing Admin',
      },
      {
        name: '	Dispatcher Admin',
        value: 'Dispatcher Admin',
      },
    ];
  };

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
      },
    {
    id: 3,
    name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }
  ]  
  
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("name")
    },
    {
      dataIndex: 'email',
      title: 'Email',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("email")
    },
    {
      dataIndex: 'type',
      title: 'Type',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("type")
    },
    {
      dataIndex: 'roles',
      title: 'Roles',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectSearchProps("roles", getRolesData())
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/super-admin/edit',
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      name: '	Ravi Singh',
      email: 'rayspro145@gmail.com',
      type: 'admin',
      roles: 'Dispatcher Admin',
    },
    {
      id: 2,
      name: '	Rahul SubAdmin',
      email: 'Rahulsa@yopmail.com',
      type: 'admin',
      roles: 'abc company',
    },
    {
      id: 3,
      name: '	NT TN',
      email: 'Bon16jackman@gmail.com',
      type: 'admin',
      roles: 'role testing',
    },
    {
      id: 4,
      name: '	sachin vishw',
      email: 'sachinvish07@yopmail.com',
      type: 'admin',
      roles: 'New GGHS Test Role',
    },
    {
      id: 5,
      name: '	aditi test',
      email: 'kam11@mailinator.com',
      type: 'admin',
      roles: '	abc company',
    },
  ];
  const [addAdminModal, setaddAdminModal] = useState(false);
  const addAdminModalShow = () => {
    setaddAdminModal(true);
  };
  const addAdminModalHide = () => {
    setaddAdminModal(false);
  };
  const adminType = [
    {
      name: 'Sub Admin',
      value: 'Sub Admin',
    },
    {
      name: 'NCA',
      value: 'NCA',
    },
  ];
  const locationData = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Indore',
      value: 'Indore',
    },
  ];
  const roleData = [
    {
      name: 'Admin',
      value: 'Admin',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Admins">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport']}
            btnText="Add Subadmin"
            onHandleShow={addAdminModalShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this admin"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                  Administrator module will list all administrators on this
                  page.
                </li>
                <li>
                  Administrator can Activate / Deactivate / Delete any
                  administrator. Super Admin cannot be Activated / Deactivated /
                  Deleted.
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={addAdminModal}
        onHandleCancel={addAdminModalHide}
        title="Add Subadmin"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={6}
            label="Select Admin Type"
            mandatory="true"
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select admin type"
                options={adminType}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="First Name"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="firstName"
                placeholder="Enter first name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Last Name"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="lastName"
                placeholder="Enter last name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Email"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="email"
                placeholder="Enter email"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Password"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="password"
                placeholder="Enter password"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Select Location"
            mandatory="true"
            element={(formik) => (
              <Select
                name="locationType"
                placeholder="Select location"
                options={locationData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Select Role"
            mandatory="true"
            element={(formik) => (
              <Select
                name="roleType"
                placeholder="Select role"
                options={roleData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addAdminModalHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addAdminModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default AdminListing;
