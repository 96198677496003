import React, {useState} from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TextEditor,
  ModalComponent,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';

function EditMarketingSMSTemplate(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/sms',
      name: 'Marketing SMS Template',
    },
    {
      path: '#',
      name: 'Edit Marketing SMS Template',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/marketing-template/sms')
  }
  const [shortCodeModal, setShortCodeModal] = useState(false);
  const shortCodes = () =>{
    setShortCodeModal(true)
  }
  const shortCodesHide = () =>{
    setShortCodeModal(false)
  }
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Marketing SMS Template">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                label="SMS Purpose"
                mandatory={true}
                element={(formik) => (
                    <TextInput
                        name="SMS Purpose"
                        placeholder="Enter SMS Purpose"
                        formik={formik}
                        defaultValue="Marketing SMS Template1"
                    />
                )}
            />
            <FormElementWrapper
                md={12}
                label="SMS"
                mandatory={true}
                actionButtonHandler={ shortCodes}
                button=" Short Codes"
                element={(formik) => (
                    <TextEditor>
                    Dear Customer, We have an some offer for you, So please contact with me this contact number #PROVIDER_PHONE_NUMBER#.
Thanks!
                    </TextEditor> 
                )}
                textCount="124/320"
                formnote="Note : Please Don't Replace Variable Between # Sign."
            />
            <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                    <>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                            Update
                        </CommonButton>
                        <CommonButton
                            type="submit"
                            className="btn btn-light"
                            >
                            Reset
                        </CommonButton>
                    </>
                )}
            />
        </FormWrapper>
                    
          </div>
        </div>
      </div>
      </div>
      <ModalComponent
        backdrop
        show={shortCodeModal}
        onHandleCancel={shortCodesHide}
        title="Email Short Codes"
        extraClassName="modal-xl"
      >
       <div className="table-responsive">
        <Table bordered size="md" className="mb-0">
              <thead>
                <tr>
                  <th>Short Codes</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#CUSTOMER_NAME#</td>
                  <td>Name of the Customer</td>
                </tr>
                <tr>
                  <td>#PROVIDER_NAME#</td>
                  <td>	Name of the Provider</td>
                </tr>
                <tr>
                  <td>##PROVIDER_PHONE_NUMBER#</td>
                  <td>Phone Number of the Provider</td>
                </tr>
              </tbody>
        </Table>
        </div>
        <div className="text-end mt-3">
          <CommonButton className="btn btn-secondary" onClick = { () => shortCodesHide() }>Close</CommonButton>
        </div>
      </ModalComponent>  
    </>
  );
}

export default EditMarketingSMSTemplate;
