import { Outlet } from 'react-router-dom';
import CreateManualBooking from '../../views/ManualBooking/Create';

export default function route() {
  return [
    {
      path: accessRoute.ADD_BOOKING.path,
      private: true,
      name: 'Manual Booking',
      key: "booking",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.ADD_BOOKING.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.ADD_BOOKING.path,
        name: 'Add Booking',
        key: accessRoute.ADD_BOOKING.key,
        belongsToSidebar: false,
        icon: accessRoute.ADD_BOOKING.icon,
        element: <CreateManualBooking />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  ADD_BOOKING: {
    path: '/admin/add-booking',
    key:"booking.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-booking-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};

