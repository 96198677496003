import React from 'react';
import { addPersonList } from '../../../../../services/Register';
import {
  FormWrapper,
  FormElementWrapper,
  RadioComponent as Radio,
  CommonButton,
} from '../../../../index';
import { initValues, validation } from './validation';

function adminAddPerson({ onSubmit }) {
  const personList = addPersonList();
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validation}
      initialValues={initValues()}
      row={true}
    >
      <FormElementWrapper
        md={12}
        element={(formik) => (
          <Radio radioGroupArray={personList} name="addPerson" />
        )}
      />
      <FormElementWrapper
        md={12}
        element={() => (
          <CommonButton
            type="submit"
            className="btn btn-lg btn-primary btn-block"
          >
            Save & Continue
          </CommonButton>
        )}
      />
    </FormWrapper>
  );
}

export default adminAddPerson;
