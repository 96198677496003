
import { AmountFilter, CheckBox, CommonButton,  InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Checkbox } from '../../components/Antd';
import { Link } from 'react-router-dom';

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnSelectAmountProps = (dataIndex) => ({
  filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getProviderListData = () => {
  return [
    {
      name: 'abhishek Yadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditi Test',
      value: 'Aditi Test',
    },
  ];
};
const getPaymentMethodData = () => {
  return [
    {
      name: 'Cash',
      value: 'Cash',
    },
    {
      name: 'Card',
      value: 'Card',
    },
    {
      name: 'Check',
      value: 'Check',
    },
    {
      name: 'Financing',
      value: 'Financing',
    },
    {
      name: 'Request',
      value: 'Request',
    },
    {
      name: 'Warranty/No Charge',
      value: 'Warranty-No Charge',
    },
    {
      name: 'Admin Attention Required',
      value: 'Admin Attention Required',
    },
  ];
};
const getPaymentTypeData = () => {
  return [
    {
      name: 'Full Payment',
      value: 'Full Payment',
    },
    {
      name: 'Split Payment',
      value: 'Split Payment',
    },
  ];
};
const getProviderPaymentStatusData = () => {
  return [
    {
      name: 'Settled',
      value: 'Settled',
    },
    {
      name: 'Unsettled',
      value: 'Unsettled',
    },
  ];
};
const getPaymentTransferStatusData = () => {
  return [
    {
      name: 'Pending',
      value: 'Pending',
    },
    {
      name: 'Transfered',
      value: 'Transfered',
    },
  ];
};

const ButtonFormatter = (url, text) => {
    return (
      <>
        <Link to={url} className="">{text}</Link>
      </>
    );
  };

// export const PaymentReportTableColumn = ({JobProviderDiscountAdd,PurchaseOrderTotalAdd,PurchaseOrderTotal,SubLaborPayDetailsAdd,SubLaborPayDetails,ReturnAmountDetailsAdd,ReturnAmountDetails,PaymentMethodPage,SplitPaymentDetails,ProviderInvoiceUpdateDetails,InvoicePage,checkPaymentMethodModalShow,FinancingPaymentMethodModalShow,WarrantyPaymentMethodModalShow,AdminPaymentMethodPage,jobProviderModalShow}) => {
export const PaymentReportTableColumn = ({ProviderInvoiceUpdateDetails,InvoicePage,checkPaymentMethodModalShow,FinancingPaymentMethodModalShow,WarrantyPaymentMethodModalShow,AdminPaymentMethodPage,jobProviderModalShow,purchaseOrderModalShow,sublaborModalShow, returnAmountModalShow, splitPaymentModalShow,paymentOptionModalShow}) => {
    return [
        {
            dataIndex: 'id',
            title: 'S.No.',
            className: 'w_70',
            width: 60,
          },
          {
            dataIndex: 'jobNumber',
            title: 'Job No',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ButtonFormatter("/admin/payment-report/booking-log", "75833147"),
            ...getColumnSearchProps("job number"),
            width: 150,
          },
          {
            dataIndex: 'provider',
            title: 'Provider',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("provider name", getProviderListData()),
            width: 200,
          },
          {
            dataIndex: 'user',
            title: 'User',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("user name", getProviderListData()),
            width: 200,
          },
          {
            dataIndex: 'jobDate',
            title: 'Job Date',
            headerClasses: 'sorting',
            sorter: true,
            width: 220,
          },
          {
            dataIndex: 'location',
            title: 'Location',
            headerClasses: 'sorting',
            sorter: true,
            width: 200,
          },
          {
            dataIndex: 'totalFees',
            title: 'A=Total Fees',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            width: 150,
          },
          {
            dataIndex: 'platformFees',
            title: <span>B=Platform <br /> Fees </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            width: 150,
          },
          {
            dataIndex: 'promoCodeDiscount',
            title: <span>C= Promo <br /> Code  <br/>Discount </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            width: 150,
          },
          {
            dataIndex: 'providerDiscountAmount',
            title: <span>D=Job <br/> Provider <br/> Discount <br/>Amount </span> ,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== "$0.00"){ 
                return <>{cell} <br/> <CommonButton onClick= { () => jobProviderModalShow() } className="btn btn-primary btn-sm">Add / Edit</CommonButton></>
              }else{
                if(cell=== ""){
                  return <>{cell} $8.00</>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'purchaseOrderTotal',
            title: <span>E=Purchase <br/> Order Total </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== ""){ 
                return <>{cell} - <br/> <CommonButton onClick= { () => purchaseOrderModalShow() }  className="btn btn-primary btn-sm">Add / Edit</CommonButton></>
              }else{
                if(cell=== "$5.00"){
                  return <>{cell} <br/> <CommonButton onClick= { () => purchaseOrderModalShow() } className="btn btn-primary btn-sm">View Details</CommonButton></>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'sublaborTotalFees',
            title: <span>F=Sublabor <br/> Total Fees </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== ""){ 
                return <>{cell} - <br/> <CommonButton onClick= { () => sublaborModalShow() }  className="btn btn-primary btn-sm">Add / Edit</CommonButton></>
              }else{
                if(cell=== "$ 500.00"){
                  return <>{cell} <br/> <CommonButton onClick= { () => sublaborModalShow() } className="btn btn-primary btn-sm">View Details</CommonButton></>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'returnedPO',
            title: <span>G = <br/> Returned PO </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== ""){ 
                return <>{cell} - <br/> <CommonButton onClick= { () => returnAmountModalShow() }  className="btn btn-primary btn-sm">Add / Edit</CommonButton></>
              }else{
                if(cell=== "$ 200.00"){
                  return <>{cell} <br/> <CommonButton onClick= { () => returnAmountModalShow() } className="btn btn-primary btn-sm">View Details</CommonButton></>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'providerPayAmount',
            title: <span>Provider Pay<br/> Amount <br/> H = <br/> A-B-E-F+G </span>,
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
            width: 150,
          },
          {
            dataIndex: 'jobStatus',
            title: '	Job Status',
            headerClasses: 'sorting',
            sorter: true,
            width: 150,
          },
          {
            dataIndex: 'jobStartTime',
            title: 'Job Start Time',
            headerClasses: 'sorting',
            sorter: true,
            width: 220,
          },
          {
            dataIndex: 'jobEndTime',
            title: 'Job End Time',
            headerClasses: 'sorting',
            sorter: true,
            width: 220,
          },
          {
            dataIndex: 'jobCompletionTime',
            title: 'Job Completion Time',
            headerClasses: 'sorting',
            sorter: true,
            width: 200,
          },
          {
            dataIndex: 'paymentMethod',
            title: 'Payment method',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("payment method", getPaymentMethodData()),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== "Request"){ 
                return <>{cell} <br/> <CommonButton onClick={ () => paymentOptionModalShow()} className="btn btn-primary btn-sm">View Option</CommonButton></>
              }else if
              (cell=== "Card"){
                return <>{cell}</>
              }
              else if
              (cell=== "Check"){
                return <>{cell} <br/> <CommonButton onClick={ (e) => checkPaymentMethodModalShow(e)} className="btn btn-primary btn-sm">View Details</CommonButton></>
              }
              else if
              (cell=== "Financing"){
                return <>{cell} <br/> <CommonButton onClick={ (e) => FinancingPaymentMethodModalShow(e)} className="btn btn-primary btn-sm">View Details</CommonButton></>
              }
              else if
              (cell=== "Warranty"){
                return <>{cell} <br/> <CommonButton onClick={ (e) => WarrantyPaymentMethodModalShow(e)} className="btn btn-primary btn-sm">View Reason</CommonButton></>
              }
              else if
              (cell=== "Admin"){
                return <>{cell} <br/> <CommonButton onClick={ (e) => AdminPaymentMethodPage(e)} className="btn btn-primary btn-sm">View Reason</CommonButton></>
              }
              else{
                if(cell=== "Cash"){
                  return <>{cell}</>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'jobPaymentType',
            title: 'Job Payment Type',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectSearchProps("job payment type", getPaymentTypeData()),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== "Split Payment"){ 
                return <>{cell} <br/> <CommonButton onClick= { () => splitPaymentModalShow() } className="btn btn-primary btn-sm">Split Payments Details</CommonButton></>
              }else{
                if(cell=== "Full Payment"){
                  return <>{cell}</>
                }
              }
            },
            width: 200,
          },
          {
            dataIndex: 'providerPaymentStatus',
            title: 'Provider Payment Status',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectSearchProps("provider payment status", getProviderPaymentStatusData()),
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== "Unsettled"){ 
                return <>{cell}</>
              }else{
                if(cell=== "Settled"){
                  return <>{cell} <br/> <span onClick= { (e) => ProviderInvoiceUpdateDetails(e) } className="linkButton">View Updated Invoice Details </span></>
                }
              }
            },
            width: 220,
          },
          {
            dataIndex: 'selectAll',
            title: ()=><>Select All <CheckBox className="ms-1"/></>,
            headerClasses: 'sorting',
            sorter: true,
            render:(cell, row)=>{
              console.log("Details", cell);
              if(cell=== "View Invoice"){ 
                return <><CommonButton onClick={ (e) => InvoicePage(e)} className="btn btn-primary btn-sm">View Invoice</CommonButton></>
              }else{
                if(cell=== ""){
                  return <>{cell}
                    <div className="d-flex align-items-center">
                      <Checkbox /><CommonButton onClick={ (e) => InvoicePage(e)} className="btn btn-primary btn-sm ms-1">View Invoice</CommonButton>
                    </div>
                    </>
                }
              }
            },
            width: 150,
          },
          {
            dataIndex: 'paymentTransferStatus',
            title: 'Payment Transfer Status',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectSearchProps("status", getPaymentTransferStatusData()),
            render:(cell, row)=>{
              console.log("", cell)
              if(cell=== ""){ 
                return <> <div className='text-center'>{cell} -</div> </>
              }else{
                if(cell=== "Transfered"){
                  return <><div className='text-center'>{cell} </div> </>
                }
              }
            },
            width: 200,
          },
      ];
};
