import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  TablePlus,
  InputFilter,
} from '../../../../../components';
import { FilterFilled  } from "@ant-design/icons";


function ListPage(props) {
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Pages',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("page name")
    },
    {
      dataIndex: 'pagetitle',
      title: 'Page Title',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("page title")
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/edit-page',
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
    ]);
  const pageData = [
    {
      id: 1,
      name: '404-ERROR',
      pagetitle: '404-ERROR',
    },
    {
      id: 2,
      name: 'About Us',
      pagetitle: 'About Us',
    },
    {
      id: 3,
      name: 'How It Works',
      pagetitle: 'How It Works',
    },
    {
      id: 4,
      name: 'Privacy Policy',
      pagetitle: 'Privacy Policy',
    },
    {
      id: 5,
      name: 'Terms & Conditions',
      pagetitle: 'Terms & Conditions',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Pages">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['csvExport']}
            // extraBtnText="Reset All"
            // extraBtnClass="btn btn-white btn-outline-light"
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        showAction={false}
        isSearch={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Page module will list all pages on this page.</li>
                <li>Administrator can edit any page.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListPage;
