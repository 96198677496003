import React from 'react';
import { Link } from 'react-router-dom';
import { AntTextArea, Breadcrumb, PageHeader } from '../../../../../components';

function QuoteInformation(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/quote-info',
      name: 'Quote Information',
    },
  ];
  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Quote Information">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Quote Information{' '}
                        <span className="text-danger"> *</span>
                      </label>
                      <AntTextArea
                        className="form-control"
                        placeholder="Enter quote information"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary me-2">
                      Update
                    </Link>
                    <Link href="#" className="btn btn-light">
                      Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuoteInformation;
