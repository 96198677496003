import i18next from 'i18next';
import * as yup from 'yup';

export const initValues = () => {
  return {
    addPerson: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    addPerson: yup.number().required(i18next.t('validation.signup.addPerson')),
  });
};
