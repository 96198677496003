import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Toggle,
  ActionWrapper,
  TablePlus,
  InputFilter,
} from '../../../../../components';
import { Link,useNavigate } from 'react-router-dom';
import { FilterFilled  } from "@ant-design/icons";

function ViewMarketingEmailTemplate(props) {

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Marketing Email Template',
    },
  ];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'emailSubject',
      title: 'Email Subject  ',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("email subject")
    },
    {
        dataIndex: 'emailCode',
        title: 'Email Code ',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("email code")
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: () => <Toggle isDropDown/>,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/marketing-template/email/edit',
      },
    ]);

    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Active'
      },
      {
      id: 3,
      name: 'Make Inactive'
      },
    ] 

  const adminData = [
    {
      id: 1,
      emailSubject: 'Brand Awareness Email Campaigns',
      emailCode: 'MARKETING_120',
    },
    {
      id: 2,
      emailSubject: 'Co-marketing partnership email template',
      emailCode: '	MARKETING_118',
    },
    {
      id: 3,
      emailSubject: 'Customer re-engagement email',
      emailCode: 'MARKETING_115',
    },
    {
      id: 4,
      emailSubject: 'Customer welcome marketing email template',
      emailCode: 'MARKETING_130',
    },
    {
      id: 5,
      emailSubject: 'DUPLI',
      emailCode: 'MARKETING_170',
    },
  ];
  const navigate = useNavigate()
  const AddServiceCategory = () =>{
    navigate('/admin/marketing-template/email/add');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Marketing Email Template">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            btnText="Add Template"
            onHandleShow={AddServiceCategory}
            // popover={<ServiceCategoryFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}  
        showAction={false}
        statusAction={false}
        statusActionOption={statusActionOption}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Marketing Email Templates module will list all emails on this page.
                </li>
                <li>
                Administrator can add/edit any email template.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMarketingEmailTemplate;
