import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../../../helpers/regex.helper';

export const initValuesStepOne = () => {
  return {
    companyName: '',
    uploadLogo: '',
    companyAddress: '',
    staffNumber: '',
    technicianNumber: '',
    areaServed: '',
  };
};

export const initValuesStepTwo = () => {
  return {
    role: null,
    duration: null,
    cardHolder: '',
    cardNumber: '',
    expMonth: null,
    expYear: null,
    cvv: '',
  };
};

export const validationStepOne = () => {
  return yup.object().shape({
    companyName: yup
      .string()
      .required(i18next.t('validation.signup.companyName')),
    uploadLogo: yup
      .string()
      .required(i18next.t('validation.signup.companyLogo')),
    companyAddress: yup
      .string()
      .required(i18next.t('validation.signup.companyAddress')),
    staffNumber: yup
      .number()
      .required(i18next.t('validation.signup.officeStaff')),
    technicianNumber: yup
      .number()
      .required(i18next.t('validation.signup.techniciansInstallers')),
    areaServed: yup
      .string()
      .required(i18next.t('validation.signup.areaServed')),
  });
};

export const validationStepTwo = (cardInfo) => {
  let validationObj = {
    role: yup
      .string()
      .typeError(i18next.t('validation.signup.role'))
      .required(i18next.t('validation.signup.role')),
    duration: yup
      .string()
      .typeError(i18next.t('validation.signup.duration'))
      .required(i18next.t('validation.signup.duration')),
    ...(cardInfo && {
      cardHolder: yup
        .string()
        .required(i18next.t('validation.signup.cardHolder')),
    }),
    ...(cardInfo && {
      cardNumber: yup
        .string()
        .matches(Regex.cardNumber, {
          message: i18next.t('validation.signup.enterValidNumber'),
        })
        .required(i18next.t('validation.signup.cardNumber')),
    }),
    ...(cardInfo && {
      expMonth: yup
        .number()
        .typeError(i18next.t('validation.signup.expMonth'))
        .required(i18next.t('validation.signup.expMonth')),
    }),
    ...(cardInfo && {
      expYear: yup
        .number()
        .typeError(i18next.t('validation.signup.expYear'))
        .required(i18next.t('validation.signup.expYear')),
    }),
    ...(cardInfo && {
      cvv: yup
        .string()
        .matches(Regex.cvv, {
          message: i18next.t('validation.signup.enterValidNumber'),
        })
        .required(i18next.t('validation.signup.cvv')),
    }),
  };
  return yup.object().shape(validationObj);
};
