import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  SweetAlert,
  PaymentReportFilterForm,
  FormElementWrapper,
  FormWrapper,
  CommonButton,
  Select,
  TablePlus,
  DatePicker,
} from "../../../components";
import {
  Columns,
   truckOrderServices,
} from "../../../services/index.service";
import { modalNotification } from "../../../utils";

function ListTruckOrdering(props) {
  const [totalPartsAmountTable, setTotalPartsAmountTable] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAlertVisible1, setIsAlertVisible1] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Parts ordered successfully", })
    return true;
  };
  const onConfirmAlert1 = () => {
    setIsAlertVisible1(false);
    modalNotification({ type: "success", message: "Parts remove successfully", })
    return true;
  };
  const truckStockOrderData = truckOrderServices();
  const columns = Columns.TruckOrderTableColumn();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "PO / Truck Stock Ordering",
    },
  ];
  const [countryActionFilter, setCountryActionFilter] = useState(false);
  const countryActionFilterShow = () => {
    setCountryActionFilter(true);
  };
  const countryActionFilterHide = () => {
    setCountryActionFilter(false);
  };
  const multipleButton  = [
    {
      title: "Order Parts",
      handler: () => { setIsAlertVisible(true) },
    },
    {
      title: "Remove Parts",
      handler: () => { setIsAlertVisible1(true) },
    },
  ]
  const ProviderNameData = [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ]
  const PartTitleData = [
    {
      name: 'AmericanHomeShieldR22',
      value: 'American Home Shield - Repair R22',
    },
    {
      name: 'FLHVAC',
      value: 'FLHVAC - Replace Capacitor on Tune-up',
    },
  ]
  const SupplyHouseData = [
    {
      name: '	MPHouse',
      value: 'MP House',
    },
    {
      name: 'ChillersAndCompressor',
      value: 'Chillers and Compressor',
    },
  ]
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="PO / Truck Stock Ordering">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create",]}
            btnText="Add Part"
            onHandleShow={countryActionFilterShow}
            popover={<PaymentReportFilterForm />}
            multipleButton={multipleButton}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        page="1"
        count="100"
        tableData={truckStockOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        isSearch={false}
        showAction={false}
        // skeletonAnimation={true}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to order selected part ?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to remove selected part ?"
        show={isAlertVisible1}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={isAlertVisible1}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert1}
      />
      <div className='text-end my-4'>
          <CommonButton className="btn btn-primary" onClick= { () => setTotalPartsAmountTable(!totalPartsAmountTable) }>Total Parts Amount</CommonButton>
      </div>
      {
        totalPartsAmountTable && 
        <>
        <div className='nk-block'>
          <div className="card">
            <div className="card-inner">
              <h5 className="title mb-4">Total Parts Amount</h5>
              <div className="table-responsive">
                <Table bordered size="md">
                  <thead>
                    <tr>
                      <th>Total Part Amount (Ordered - Yes)</th>
                      <td>$ 94,428.00</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Total Part Amount (Ordered - No)</th>
                      <td>	$ 149.25</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              
            </div>
          </div>
        </div>
        </>
      }
      <ModalComponent
        backdrop
        show={countryActionFilter}
        onHandleCancel={countryActionFilterHide}
        title="Add Part"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Provider Name"
                element={(formik) => (
                <Select
                  name="providerName"
                  placeholder="Select provider name "
                  options={ProviderNameData}
                  showSearch
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
                md={6}
                label="Part Title"
                element={(formik) => (
                <Select
                  name="partTitle"
                  placeholder="Select part title"
                  options={PartTitleData}
                  showSearch
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
                md={6}
                label="Supply House"
                element={(formik) => (
                <Select
                  name="adminType"
                  placeholder="Select supply house"
                  options={SupplyHouseData}
                  showSearch
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick= { () => countryActionFilterHide() }
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick= { () => countryActionFilterHide() }
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default ListTruckOrdering;
