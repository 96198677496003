import { Outlet } from 'react-router-dom';
import { BookingInvoice, ListScheduledBooking } from '../../views/ScheduledBooking';


export default function route() {
  return [
    {
      path: accessRoute.SCHEDULED_BOOKING.path,
      private: true,
      name: 'Scheduled Bookings',
      key: "scheduledBookings",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.SCHEDULED_BOOKING.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.SCHEDULED_BOOKING.path,
        name: 'Scheduled Bookings',
        key: accessRoute.SCHEDULED_BOOKING.key,
        belongsToSidebar: false,
        icon: accessRoute.SCHEDULED_BOOKING.icon,
        element: <ListScheduledBooking />,
      },
      {
        private: true,
        path: accessRoute.INVOICE.path,
        name: 'Invoice',
        key: accessRoute.INVOICE.key,
        belongsToSidebar: false,
        icon: accessRoute.INVOICE.icon,
        element: <BookingInvoice />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  SCHEDULED_BOOKING: {
    path: '/admin/scheduled-bookings',
    key:"scheduledBookings.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-booking" />
      </span>
    ),
  },
  INVOICE: {
    path: '/admin/scheduled-bookings/invoice',
    key:"scheduledBookings.list",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
