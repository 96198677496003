import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../../helpers/index.helper';

export const initValues = () => {
  return {
    emailOrMobile: '',
    password: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    emailOrMobile: yup
      .string()
      .required(i18next.t('validation.landingPage.email'))
      .email(i18next.t('validation.landingPage.validEmail')),
    password: yup.string().required('Password Required'),
  });
};
