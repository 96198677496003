import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  ImageElement,
  InputType,
  ListingHeader,
  PageHeader,
  TextEditor,
} from "../../../../../components";

function EditHomePage(props) {
  const navigate = useNavigate()
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/homepage",
      name: "Home Page",
    },
    {
      path: "/admin/edit-homepage",
      name: "Edit Home Content",
    },
  ];
  const backPage = () =>{
    navigate('/admin/homepage')
  }
  return(
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Home Content">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  {/* <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Page title  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter page title"
                        name="Pagetitle"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Home Header First Label  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter first label"
                        name="HomeHeaderFirstLabel"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Header Second Label  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter second label"
                        name="HomeHeaderSecondLabel"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Home Left Banner Image  <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 1300px * 600px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Right Banner Image  <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 170px * 680px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Left Banner Text <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter banner text"
                        name="HomeLeftBannerText"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Right Banner Text <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter banner text"
                        name="HomeRightBannerText"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Section Title <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter title"
                        name="HomeThirdSectionTitle"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Section Description  <span className="text-danger"> *</span>
                      </label>
                      <TextEditor />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Image One <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 400px * 225px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Title One <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter title"
                        name="HomeThirdMiddleSectionTitleOne"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Description One  <span className="text-danger"> *</span>
                      </label>
                      <TextEditor />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Image Two <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 400px * 225px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Title Two <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter title"
                        name="HomeThirdMiddleSectionTitleTwo"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Description Two  <span className="text-danger"> *</span>
                      </label>
                      <TextEditor />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Image Three <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 400px * 225px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Title Three <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter title"
                        name="HomeThirdMiddleSectionTitleThree"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Third Middle Section Description Three  <span className="text-danger"> *</span>
                      </label>
                      <TextEditor />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Mobile App Section Background Image<span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 1350px * 650px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Mobile App Section Left Image<span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                          <div className="form-file">
                              <input type="file" multiple="" className="form-file-input" id="customFile" />
                              <label className="form-file-label" for="customFile">Choose file</label>
                          </div>
                      </div>
                      <div className="imgPreviewBox">
                        <ImageElement source="uploadImg.png" alt="img"/>
                        <div className="imgPreviewBox_icon">
                          <Link href="#" className="btn btn-primary">
                              <em className="icon ni ni-cross"></em>
                          </Link>
                        </div>
                      </div>
                      <p className="form-note"><b>[Note: For Better Resolution Upload only image size of 550px * 600px.]</b></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Mobile App Section Right Title  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter title"
                        name=" HomeMobileAppSectionRightTitle"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Mobile App Section Right Description  <span className="text-danger"> *</span>
                      </label>
                      <TextEditor />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Driver Section First Label  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter driver section label"
                        name="HomeDriverSectionFirstLabel"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Home Driver Section Second Label  <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter driver section label"
                        name="HomeDriverSectionSecondLabel"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary">
                    Update
                    </Link>
                    <Link href="#" className="btn btn-light ms-2">
                    Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EditHomePage;
