import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Toggle,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../../components';
import { Link } from 'react-router-dom';
import { FilterFilled  } from "@ant-design/icons";


function ListOption(props) {

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Financing Option',
    },
  ];

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    } 
  ]

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'title',
      title: '	Title',
      sorter: true,
      ...getColumnSearchProps("title")
    },
    {
      dataIndex: 'months',
      title: 'Months',
      sorter: true,
      ...getColumnSearchProps("months")
    },
    {
      dataIndex: 'minLoanAmount',
      title: 'Min. Loan Amount',
      sorter: true,
      ...getColumnSearchProps("min loan amount")
    },
    {
      dataIndex: 'paymentFactor',
      title: 'Payment Factor',
      sorter: true,
      ...getColumnSearchProps("payment factor")
    },
    {
      dataIndex: 'dealerFee',
      title: 'Dealer Fee',
      sorter: true,
      ...getColumnSearchProps("dealer fee")
    },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditFinancingOptionShow()},
      },
    ]);
  const adminData = [
    {
      id: 1,
      title: '11 Month',
      months: '15',
      minLoanAmount: '1000.00',
      paymentFactor: '2.50',
      dealerFee: '10.00',
    },
    {
      id: 2,
      title: '10 Month',
      months: '14',
      minLoanAmount: '1200.00',
      paymentFactor: '2.50',
      dealerFee: '13.00',
    },
    {
      id: 3,
      title: '9 Month',
      months: '12',
      minLoanAmount: '1000.00',
      paymentFactor: '2.50',
      dealerFee: '11.00',
    },
  ];
  const [addFinancingOption, setAddFinancingOption] = useState(false);
  const addFinancingOptionHide = () =>{
    setAddFinancingOption(false)
  }
  const addFinancingOptionShow = () =>{
    setAddFinancingOption(true)
  }
  const [EditFinancingOption, setEditFinancingOption] = useState(false);
  const EditFinancingOptionHide = () =>{
    setEditFinancingOption(false)
  }
  const EditFinancingOptionShow = () =>{
    setEditFinancingOption(true)
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Financing Option">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create',]}
            btnText="Add Financing Option"
            onHandleShow={addFinancingOptionShow}
            // popover={<FinancingOptionFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <ModalComponent
        backdrop
        show={addFinancingOption}
        onHandleCancel={addFinancingOptionHide}
        title="Add Financing Option"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              md={6}
              label="Financing Title"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="financingTitle"
                  placeholder="Enter financing title"
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Terms In Month"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="month"
                  placeholder="Enter terms in month"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Minimum Loan Amount"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="minimumLoanAmount"
                  placeholder="Enter minimum loan amount"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Payment Factor "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="paymentFactor "
                  placeholder="Enter payment factor "
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Dealer Fee"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="dealerFee "
                  placeholder="Enter dealer fee"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() => addFinancingOptionHide()}
                  >
                    Add
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => addFinancingOptionHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={EditFinancingOption}
        onHandleCancel={EditFinancingOptionHide}
        title="Edit Financing Option"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              md={6}
              label="Financing Title"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="financingTitle"
                  placeholder="Enter financing title"
                  formik={formik}
                  defaultValue="11 Month"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Terms In Month"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="month"
                  placeholder="Enter terms in month"
                  formik={formik}
                  type="number"
                  defaultValue="15"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Minimum Loan Amount"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="minimumLoanAmount"
                  placeholder="Enter minimum loan amount"
                  formik={formik}
                  type="number"
                  defaultValue="1000"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Payment Factor "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="paymentFactor "
                  placeholder="Enter payment factor "
                  formik={formik}
                  type="number"
                  defaultValue="2.5"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Dealer Fee"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="dealerFee "
                  placeholder="Enter dealer fee"
                  formik={formik}
                  type="number"
                  defaultValue="10"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-1 me-sm-2"
                    onClick={() => EditFinancingOptionHide()}
                  >
                    Update
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-1 me-sm-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => EditFinancingOptionHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListOption;
