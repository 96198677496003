import { Outlet } from 'react-router-dom';
import { AboutView, PrivacyPolicyView, TermsConditionView } from '../../views/CMS';

export default function route() {
  return [
    {
      private: true,
      name: "CMS",
      key: "cms",
      belongsToSidebar: true,
      icon: accessRoute.ABOUT_US_VIEW.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.ABOUT_US_VIEW.name,
          key:"cms.about",
          path:accessRoute.ABOUT_US_VIEW.path,
          belongsToSidebar: true,
          element: <AboutView/>
        },
        {
          private: true,
          name:accessRoute.PRIVACY_POLICY.name,
          key:"cms.privacyPolicy",
          path:accessRoute.PRIVACY_POLICY.path,
          belongsToSidebar: true,
          element: <PrivacyPolicyView/>
        },
        {
          private: true,
          name:accessRoute.TERMS_CONDITION.name,
          key:"cms.termsCondition",
          path:accessRoute.TERMS_CONDITION.path,
          belongsToSidebar: true,
          element: <TermsConditionView/>
        },
      ]
    },
  ];
}

export const accessRoute = {
    ABOUT_US_VIEW: {
    path: "/admin/about-us",
    name: "About Us",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-opt-alt-fill" />
      </span>
    )
  },
  PRIVACY_POLICY: {
    path: "/admin/privacy-policy",
    name: "Privacy Policy",
  },
  TERMS_CONDITION: {
    path: "/admin/terms-condition",
    name: "Terms And Condition",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
