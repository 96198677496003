import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'antd';

function defaultFn() { }

function FormWrapper({
  children,
  onSubmit = defaultFn,
  validation = defaultFn,
  initialValues = {},
  data,
  enableReinitialize,
  row = false,
  extraClassName = "",
}) {
  const form = useRef(null);
  const formProps = {
    initialValues: { ...initialValues },
    validationSchema: validation(),
    onSubmit: onSubmit,
    enableReinitialize: enableReinitialize
  };

  useEffect(()=>{
    if(data && form?.current?.setFieldsValue){
      form.current.setFieldsValue({...data})
    }
  },[data])

  // function onFinish(e, props) {
  //   props.submitForm(e);
  // }

  return (
    <Formik {...formProps}>
      {(props) => {
        return (
          <Form ref={form} onFinish={props.handleSubmit} className={`${extraClassName ? extraClassName : "" } ${row ? "row": ""}`} >
            {React.Children.map(
              typeof children === 'function' ? children(props) : children,

              (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, props);
                }
                return child;
              }
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormWrapper;
