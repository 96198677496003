import React, { useState } from "react";
import {
    Breadcrumb,
    PageHeader,
    ListingHeader,
    SweetAlert,
    ActionWrapper,
    TablePlus,
    ModalComponent,
    FormWrapper,
    FormElementWrapper,
    CommonButton,
    TextInput,
} from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
    Columns,
    VariantManagementService,
} from "../../../../services/index.service";
import { modalNotification } from "../../../../utils";

function VariantList(props) {

    const breadcrumb = [
        {
            path: "/admin/dashboard",
            name: "Dashboard",
        },
        {
            path: "#",
            name: "Variants Management",
        },
    ];

    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Variant Deleted Successfully", })
        return true;
    };
    function onAction(e, handler) {
        e.preventDefault();
        handler()
    }

    const statusActionOption = [
        {
            id: 1,
            name: 'Select Action'
        },
        {
            id: 2,
            name: 'Make Active'
        },
        {
            id: 3,
            name: 'Make Inactive'
        },
        {
            id: 4,
            name: 'Make Delete'
        }
    ]

    const actionFormatter = (options) => {
        return (
            <ActionWrapper>
                {options.map((item, key) => {
                    return (
                        <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
                            <Link to="#">
                                <em className={item.icon} />
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </ActionWrapper>
        );
    };

    const columns = Columns.VariantManagementTableColumn({
        Action: () =>
            actionFormatter([
                {
                    name: "Edit",
                    icon: "icon ni ni-edit",
                    handler: () =>{editVariantModalShow()},
                },
                {
                    name: "Delete",
                    icon: "icon ni ni-trash",
                    handler: () => {
                        setIsAlertVisible(true);
                        document.body.click()
                    }
                },
            ]),
    });

    const variantData = VariantManagementService();
    // const EditMembershipPage = () =>{
    //   navigate('/admin/membership/edit')
    // }
    // const AddMembershipPage = () =>{
    //   navigate('/admin/membership/add');
    // }
    const [addVariantModal, setAddVariantModal] = useState(false);
    const addVariantModalShow = () => {
        setAddVariantModal(true);
    };
    const addVariantModalHide = () => {
        setAddVariantModal(false);
    };

    const [editVariantModal, setEditVariantModal] = useState(false);
    const editVariantModalShow = () => {
        setEditVariantModal(true);
    };
    const editVariantModalHide = () => {
        setEditVariantModal(false);
    };
    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <PageHeader heading="Variants Management">
                        <Breadcrumb breadcrumb={breadcrumb} />
                    </PageHeader>
                    <ListingHeader
                        btnArray={["create", ""]}
                        btnText="Add Variant"
                        // popover={<CustomersFilterForm/>}
                        onHandleShow={addVariantModalShow}
                    />
                </div>
            </div>
            <TablePlus
                hasLimit
                noOfPage="1"
                sizePerPage="10"
                page="1"
                count="100"
                tableData={variantData}
                tableColumns={columns}
                showAction={false}
                isSearch={true}
                // tableLoader={isLoading}
                setSizePerPage=""
            />
            <SweetAlert
                title="Are you sure"
                text=" you want to delete this Variant"
                show={isAlertVisible}
                icon="warning"
                showCancelButton
                confirmButtonText="Yes"
                cancelButtonText="No"
                setIsAlertVisible={setIsAlertVisible}
                onConfirmAlert={onConfirmAlert}
            />
            <ModalComponent
                backdrop
                show={addVariantModal}
                onHandleCancel={addVariantModalHide}
                title="Add Variant"
            >
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={12}
                        label="Variant Name"
                
                        element={(formik) => (
                            <TextInput
                                name="variantName"
                                placeholder="Enter variant name"
                                formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        label="Variant Option"
                        element={(formik) => (
                        <div className="d-flex align-items-center">
                            <TextInput
                                name="Option"
                                placeholder="Enter variant option"
                                formik={formik}
                                className="w-100"
                            />
                            <div className="d-flex ms-2">
                                <Link to="#" className="btn btn-primary btn-sm ms-2"><em className="icon ni ni-plus"></em></Link>
                            </div>
                        </div>
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        label="Variant Option 1"
                        element={(formik) => (
                        <div className="d-flex align-items-center">
                            <TextInput
                                name="Option1"
                                placeholder="Enter variant option1"
                                formik={formik}
                                className="w-100"
                            />
                            <div className="d-flex ms-2">
                                <Link to="#" className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash"></em></Link>
                            </div>
                        </div>
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        className="text-end"
                        element={() => (
                            <>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={() => addVariantModalHide()}
                                >
                                    Add
                                </CommonButton>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-light me-2"
                                >
                                    Reset
                                </CommonButton>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-secondary"
                                    onClick={() => addVariantModalHide()}
                                >
                                    Cancel
                                </CommonButton>
                            </>
                        )}
                    />
                </FormWrapper>
            </ModalComponent>

            <ModalComponent
                backdrop
                show={editVariantModal}
                onHandleCancel={editVariantModalHide}
                title="Edit Variant"
            >
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={12}
                        label="Variant Name"
                
                        element={(formik) => (
                            <TextInput
                                name="variantName"
                                placeholder="Enter variant name"
                                formik={formik}
                                defaultValue="Color"
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        label="Option"
                        element={(formik) => (
                        <div className="d-flex align-items-center">
                            <TextInput
                                name="Option"
                                placeholder="Enter option"
                                formik={formik}
                                className="w-100"
                                defaultValue="red"
                            />
                            <div className="d-flex ms-2">
                                <Link to="#" className="btn btn-primary btn-sm ms-2"><em className="icon ni ni-plus"></em></Link>
                            </div>
                        </div>
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        label="Option 1"
                        element={(formik) => (
                        <div className="d-flex align-items-center">
                            <TextInput
                                name="Option1"
                                placeholder="Enter option1"
                                formik={formik}
                                className="w-100"
                                defaultValue="green"
                            />
                            <div className="d-flex ms-2">
                                <Link to="#" className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash"></em></Link>
                            </div>
                        </div>
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        className="text-end"
                        element={() => (
                            <>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={() => addVariantModalHide()}
                                >
                                    Update
                                </CommonButton>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-light me-2"
                                >
                                    Reset
                                </CommonButton>
                                <CommonButton
                                    type="submit"
                                    className="btn btn-secondary"
                                    onClick={() => addVariantModalHide()}
                                >
                                    Cancel
                                </CommonButton>
                            </>
                        )}
                    />
                </FormWrapper>
            </ModalComponent>
        </>
    );
}

export default VariantList;
