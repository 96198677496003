import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from "../../../../components";
import {
  Columns,
   NCAPaymentReportServices,
} from "../../../../services/index.service";

function NCAPaymentReport(props) {
      const NCAData = NCAPaymentReportServices();
      const columns = Columns.NCAPaymentReportTableColumn();   
      const breadcrumb = [
        {
          path: "/admin/dashboard",
          name: "Dashboard",
          
        },
        {
          path: "/admin/nca",
          name: "NCA",
        },
        {
            path: "#",
            name: "NCA Payment Report (Test NCA 17-10-2022)",
          },
      ];
      const navigate = useNavigate()
      const backPage = () =>{
        navigate('/admin/nca')
      }
      return (
        <>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <PageHeader heading="NCA Payment Report (Test NCA 17-10-2022)">
                <Breadcrumb breadcrumb={breadcrumb} />
              </PageHeader>
              <ListingHeader
                btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
              />
            </div>
          </div>
          <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={NCAData}
            tableColumns={columns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}
            showAction={false}
            showTableCountNumber={false}
          />
        </>
      );
}

export default NCAPaymentReport;
