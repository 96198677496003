import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  CommonButton,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

function GMBPhotoAdd(props) {

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '/admin/google-gmb/photo',
      name: 'Google Media List',
    },
    {
      path: '#',
      name: 'Add Google Media',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/google-gmb/photo')
  }
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Add Google Media">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={12}
                label="Post photos"
                // mandatory="true"
                element={(formik) => (
                  <Dragger>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    {/* <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p> */}
                    <p className="ant-upload-text">Drag photos here
                    <br />
                    Or, if you prefer...
                    <br />
                    Select photos from your computer</p>
                  </Dragger>
                )}
              />
                <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                    <>
                        <CommonButton
                        type="submit"
                        className="btn btn-primary"
                        >
                        Post
                        </CommonButton>
                        {/* <CommonButton
                        type="submit"
                        className="btn btn-light"
                        >
                        Cancel
                        </CommonButton> */}
                    </>
                    )}
                />  
            </FormWrapper>  
                    
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default GMBPhotoAdd;
