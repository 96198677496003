
import { Radio } from "antd";
import React, {useState} from "react";
import {  Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CheckBox,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  ListingHeader,
  ModalComponent,
  PageHeader,
  Select,
  SweetAlert,
  TextInput,
} from "../../../components";
import { modalNotification } from "../../../utils";

function AddQuestions(props) {
    const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/standard-operating-procedures')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/standard-operating-procedures",
      name: "Standard Operating Procedures",
    },
    {
      path: "#",
      name: "Add Question",
    },
    
  ];
  
  const multipleButton  = [
    {
      title: "Add Question",
      handler: () => { addQuestionShow() }
    },
    {
      title: "Send Notification",
      handler: () => { setIsAlertVisible(true) },
    },
  ]
  const [addQuestion, setAddQuestion] = useState(false);
  const addQuestionHide = () =>{
    setAddQuestion(false)
  }
  const addQuestionShow = () =>{
    setAddQuestion(true)
  }
  const [editQuestion, setEditQuestion] = useState(false);
  const editQuestionHide = () =>{
    setEditQuestion(false)
  }
  const editQuestionShow = () =>{
    setEditQuestion(true)
  }
  const QuestionTitle = [
    {
      name: 'Single Answer',
      value: 'Single Answer',
    },
    {
      name: 'Multiple Answer',
      value: 'Multiple Answer',
    },
  ];
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'You have to activate the lesson before sending notification.',
    });
    return true;
  };
  const [isAlertVisible1, setIsAlertVisible1] = useState(false);
  const onConfirmAlert1 = () => {
    modalNotification({
      type: 'success',
      message: 'Question Delete  Successfully',
    });
    return true;
  };
  return (
    <>
        <div className="wide-xl mx-auto">
            <div className="nk-block-head nk-block-head-sm d-md-block  d-inline-block">
            <div className="nk-block-between align-items-center">
                <PageHeader heading="Add Question">
                <Breadcrumb breadcrumb={breadcrumb} />                
                </PageHeader>                
              
              <div>
                <ListingHeader
                btnArray={["extraButton", "multipleButton"]}
                extraBtnText="Back"
                extraBtnClass="btn btn-outline-light bg-white"
                onExtraButtonHandleShow={backPage}
                multipleButton={multipleButton}
                />
                </div>
            </div>
            </div>
            <div className="nk-block">
            <div className="card">
                <div className="card-inner">
                  <p className="m-0">(After making any update please send notification)</p>
                    <div className="bq-note mt-3">
                        <div className="bq-note-text">
                        <div className="nk-block-head nk-block-head-sm nk-block-between g-1">
                            <h5 className="title">Test</h5>
                            <div className="d-flex">
                                <Link to="#" onClick={ () => editQuestionShow() } className="btn btn-primary btn-sm"><em className="icon ni ni-edit me-1"></em> Edit</Link>
                                <Link to="#" onClick={ () => setIsAlertVisible1(true) } className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash me-1"></em> Delete</Link>
                            </div>
                        </div>
                        <ul className="list list-sm list-checked">
                            <li>test 1</li>
                            <li>test 2</li>
                            <li>test 3</li>
                        </ul>
                        </div>
                    </div>
                    <div className="bq-note  mt-3">
                        <div className="bq-note-text">
                        <div className="nk-block-head nk-block-head-sm nk-block-between g-1">
                            <h5 className="title">Test1</h5>
                            <div className="d-flex">
                                <Link to="#" onClick={ () => editQuestionShow() } className="btn btn-primary btn-sm"><em className="icon ni ni-edit me-1"></em> Edit</Link>
                                <Link to="#" onClick={ () => setIsAlertVisible1(true) } className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash me-1"></em> Delete</Link>
                            </div>
                        </div>
                        <ul className="list list-sm list-checked">
                            <li>test 11</li>
                            <li>test 22</li>
                            <li>test 33</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-1 mb-0">Showing <b> 1</b> to <b>3</b> of <b>3</b> entries</p>
            </div>
        </div>
        <SweetAlert
            title="Are you sure"
            text="send notification after add or update the questions?"
            show={isAlertVisible}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={setIsAlertVisible}
            // showLoaderOnConfirm
            // loading={loading}
            onConfirmAlert={onConfirmAlert}
        />
        <SweetAlert
            title="Are you sure"
            text=" want to Delete this question"
            show={isAlertVisible1}
            icon="warning"
            showCancelButton
            confirmButtonText="Yes"
            cancelButtonText="No"
            setIsAlertVisible={setIsAlertVisible1}
            // showLoaderOnConfirm
            // loading={loading}
            onConfirmAlert={onConfirmAlert1}
        />
        <ModalComponent
        backdrop
        show={addQuestion}
        onHandleCancel={addQuestionHide}
        title="Add Question"
      >
      <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Question Title"
                element={(formik) => (
                <TextInput
                    name="ServiceType"
                    placeholder="Enter question title"
                    formik={formik}
                />
                )}
            />
            <FormElementWrapper
                md={6}
                label="Question Type"
                element={(formik) => (
                <Select
                    name="adminType"
                    placeholder="Select question type"
                    options={QuestionTitle}
                    showSearch
                    formik={formik}
                />
                )}
            />
            <Radio.Group name="rightAnswerEdit" className="col-md-12">
              <div className="row g-3">
                <FormElementWrapper
                    md={12}
                    label="Option 1"
                    element={(formik) => (
                    <div className="d-flex align-items-center">
                        <TextInput
                            name="Option1"
                            placeholder="Enter option value"
                            formik={formik}
                            className="w-100"
                        />
                        <div className="d-flex ms-2">
                            <Radio value={1}/>
                            <Link to="#" className="btn btn-primary btn-sm ms-2"><em className="icon ni ni-plus"></em></Link>
                            {/* <CommonButton className="btn btn-primary btn-sm ms-2"><em className="icon ni ni-plus"></em></CommonButton> */}
                        </div>
                    </div>
                    )}
                />
                <FormElementWrapper
                    md={12}
                    label="Option 2"
                    element={(formik) => (
                    <div className="d-flex align-items-center">
                        <TextInput
                            name="Option2"
                            placeholder="Enter option value"
                            formik={formik}
                            className="w-100"
                        />
                        <div className="d-flex ms-2">
                            <Radio value={2}/>
                            <Link to="#" className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash"></em></Link>
                            {/* <CommonButton className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash"></em></CommonButton> */}
                        </div>
                    </div>
                    )}
                />
              </div>
            </Radio.Group>
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addQuestionHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addQuestionHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>

         <ModalComponent
        backdrop
        show={editQuestion}
        onHandleCancel={editQuestionHide}
        title="Edit Question"
      >
      <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Question Title"
                element={(formik) => (
                <TextInput
                    name="ServiceType"
                    placeholder="Enter question title"
                    formik={formik}
                    defaultValue="test1"
                />
                )}
            />
            <FormElementWrapper
                md={6}
                label="Question Type"
                element={(formik) => (
                <Select
                    name="adminType"
                    placeholder="Select question type"
                    options={QuestionTitle}
                    showSearch
                    formik={formik}
                    defaultValue="Multiple Answer"
                />
                )}
            />
            <Radio.Group name="rightAnswerEdit" className="col-md-12">
            <div className="row g-3">
              <FormElementWrapper
                  md={12}
                  label="Option 1"
                  element={(formik) => (
                  <div className="d-flex align-items-center">
                      <TextInput
                          name="Option1"
                          placeholder="Enter option value"
                          formik={formik}
                          className="w-100"
                          defaultValue="10"
                      />
                      <div className="d-flex ms-2">
                          <Radio value={1} id="1"/>
                          <Link to="#" className="btn btn-primary btn-sm ms-2"><em className="icon ni ni-plus"></em></Link>
                      </div>
                  </div>
                  )}
              />
              <FormElementWrapper
                  md={12}
                  label="Option 2"
                  element={(formik) => (
                  <div className="d-flex align-items-center">
                      <TextInput
                          name="Option2"
                          placeholder="Enter option value"
                          formik={formik}
                          className="w-100"
                          defaultValue="20"
                      />
                      <div className="d-flex ms-2">
                          <Radio value={2} id="2"/>
                          <Link to="#" className="btn btn-danger btn-sm ms-2"><em className="icon ni ni-trash"></em></Link>
                      </div>
                  </div>
                  )}
              />
            </div>
            </Radio.Group>
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => editQuestionHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editQuestionHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>    
    </>
  );
}

export default AddQuestions;
