import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    ActionWrapper,
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  InputFilter,
  ListingHeader,
  PageHeader,
  SelectFilter,
  TablePlus,
  Toggle,
  Tabs,
  TextInput,
  SweetAlert,
  ModalComponent,
  ImageElement,
  DatePicker,
} from '../../../../components';
import { FilterFilled  } from "@ant-design/icons";
import { modalNotification } from '../../../../utils';
import { Accordion, Col, Row } from 'react-bootstrap';


function EditNCA(props) {

  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    }
  ]

  const statusActionOption1 = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    }
  ]

  const navigate = useNavigate();
  const [defaultKey, setDefaultKey] = useState('profile');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/nca',
      name: 'NCA',
    },
    {
      path: '#',
      name: 'Edit NCA',
    },
  ];
  const backPage = () => {
    navigate('/admin/nca');
  };
  const PhoneNumberData = [
    {
      name: '+1',
      value: '+1',
    },
    {
      name: '+91',
      value: '+91',
    },
  ]
  const ExMonthData = [
    {
        name: '12',
        value: '12',
      },
  ]
  const ExYearData = [
    {
        name: '2022',
        value: '2022',
    },
    {
        name: '2021',
        value: '2021',
    },
    {
        name: '2020',
        value: '2020',
    },
  ]
  const locationData = [
    {
        name: 'Indore',
        value: 'Indore',
    },
  ]
  const categoryData = [
    {
        name: 'HVAC',
        value: 'HVAC',
    },
    {
        name: 'Handyman',
        value: 'Handyman',
    },
    {
        name: 'Satellite',
        value: 'Satellite',
    },
  ]
   const IndustryTypeData = [
    {
      name: 'A',
      value: 'A',
    },
    {
      name: 'B',
      value: 'B',
    },
    {
      name: 'C',
      value: 'C',
    },
   ]
  const businessTypeData = [
    {
      name: 'Individual',
      value: 'Individual',
    },
    {
      name: 'Company-Sole Proprietorship',
      value: 'Company-Sole Proprietorship',
    },
    {
      name: 'Company-Single Member LLC',
      value: 'Company-Single Member LLC',
    },
    {
      name: 'Company-Multi Member LLC',
      value: 'Company-Multi Member LLC',
    },
    {
      name: 'Company-Private Partnership',
      value: 'Company-Private Partnership',
    },
    {
      name: 'Company-Private Corporation',
      value: 'Company-Private Corporation',
    },
    {
      name: 'Company-Unicorporated Association',
      value: 'Company-Unicorporated Association',
    },
  ]
  const [jobType, setJobType] = useState(true);
  const handleJobTypeChange = (value) => {
    if(value === 'Individual'){
      setJobType(true)
    }else{
      setJobType(false)
    }
  };
  const HomeAddressTypeData = [
    {
      name: 'india',
      value: 'india',
    },
    {
      name: 'USA',
      value: 'USA',
    },
  ]
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getLocationForData = () => {
    return [
      {
        name: 'Indore',
        value: 'Indore',
      },
      {
        name: 'Bhopal',
        value: 'Bhopal',
      },
    ];
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const geoLocationData = [
    {
      id: 1,
      locationName: 'Indore',
      country: 'India',
      locationFor: 'Service Type',
      pullPermitCost: '5.00'
    },
  ];
  const geoLocationColumns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'locationName',
      title: 'Location Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("location name"),
    },
    {
        dataIndex: 'country',
        title: 'Country',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("country"),
      },
      {
        dataIndex: 'locationFor',
        title: 'Location For',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSelectSearchProps("location for", getLocationForData())
      },
      {
        dataIndex: 'pullPermitCost',
        title: 'Pull Permit Cost ($)',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("pull permit cost ($)"),
      },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: () => <Toggle isDropDown />,
      sorter: true,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => actionFormatter([
        {
            name: 'Edit',
            icon: 'icon ni ni-edit',
            path: '/admin/manage-location/add'
          },
          {
            name: 'Delete',
            icon: 'icon ni ni-trash',
            type: 'redirect',
            handler: () => {
              setIsAlertVisible(true);
              document.body.click();
            },
          },
      ]),
    },
  ];
  const serviceCategoryData = [
    {
        id: 1,
        icon: '/assets/images/admin/icons.png',
        name: 'Category',
        subCategories: 'Service Type',
        displayOrder: '1'
      },
  ]
  const serviceCategoryColumns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'icon',
      title: 'Icon',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => logoFormatter(row?.icon,"Image"),
    },
    {
        dataIndex: 'name',
        title: 'Name',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("name"),
      },
      {
        dataIndex: 'subCategories',
        title: 'Sub Categories',
        headerClasses: 'sorting',
        sorter: true,
        render: (cell, row) => ButtonFormatter("/admin/subservice-category", "View"),
      },
      {
        dataIndex: 'displayOrder',
        title: 'Display Order ',
        headerClasses: 'sorting',
        sorter: true,
      },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: () => <Toggle isDropDown/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => actionFormatter([
        {
            name: 'Edit',
            icon: 'icon ni ni-edit',
            path: '/admin/service-category/Edit'
          },
          {
            name: 'Delete',
            icon: 'icon ni ni-trash',
            type: 'redirect',
            handler: () => {
              setIsAlertVisible1(true);
              document.body.click();
            },
          },
      ]),
    },
  ];
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const ButtonFormatter = (url, text) => {
    return (
      <>
        <div className="">
          <Link to={url} className="btn btn-primary">{text} <span className='badge ms-1 badge-circle align-items-center'>5</span></Link>
        </div>
      </>
    );
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const addLocationPage = () => {
    navigate('/admin/manage-location/add')
  }
  const addServiceCategoryPage = () => {
    navigate('/admin/service-category/add')
  }
  const LogReportPage = () => {
    navigate('/admin/nca/log-report')
  }
  const DashboardPage = () => {
    navigate('/admin/dashboard')
  }
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'location Delete  Successfully',
    });
    return true;
  };
  const onConfirmAlert1 = () => {
    modalNotification({
      type: 'success',
      message: 'Service Category Delete  Successfully',
    });
    return true;
  };
  const [addExistingCategory, setAddExistingCategory] = useState(false);
  const addExistingCategoryShow = () => {
    setAddExistingCategory(true);
  };
  const addExistingCategoryHide = () => {
    setAddExistingCategory(false);
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAlertVisible1, setIsAlertVisible1] = useState(false);
  const tabContent = [
    {
      name: 'NCA Profile ',
      key: 'profile',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="First Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="FirstName"
                      placeholder="Enter first name"
                      formik={formik}
                      defaultValue="abdul"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Last Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="lastName"
                      placeholder="Enter last name"
                      formik={formik}
                      defaultValue="Hannan"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Phone Number "
                  mandatory={true}
                  element={(formik) => (
                    <div className="inputAndSelect">
                      <Input.Group compact className='d-flex'>
                        <Select
                            name="PhoneNumber"
                            placeholder="Select phone number"
                            options={PhoneNumberData}
                            showSearch
                            formik={formik}
                            defaultValue=" +1 "
                            className='w-25'
                          />
                        <TextInput
                          name="PhoneNumber"
                          placeholder="Enter phone number"
                          formik={formik}
                          type="number"
                          defaultValue="2517413698"
                        />
                      </Input.Group>
                    </div>
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Email"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="email"
                      placeholder="Enter email"
                      formik={formik}
                      defaultValue="test@gghs.com"
                      type="email"
                    />
                  )}
                />
                 <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save Changes
                        </CommonButton>
                      </>
                    )}
                  />
              </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Setup Company Profile',
      key: 'companyprofile',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
                <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="Company Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="CompanyName"
                      placeholder="Enter company name"
                      formik={formik}
                      defaultValue="testcompany"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Upload Company Logo"
                  imgPreviewBox={true}
                  element={(formik) => (
                    <TextInput
                      name="lastName"
                      placeholder="Enter last name"
                      formik={formik}
                      type="file"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Company Address"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="companyAddress"
                      placeholder="Enter company address"
                      formik={formik}
                      defaultValue="sasas"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Number Of Office Staff"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="Number Of Office Staff"
                      placeholder="Enter number of office staff"
                      formik={formik}
                      defaultValue="2"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Number Of Field Technicians/Installers "
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="Number Of Field Technicians/Installers "
                      placeholder="Enter number of field technicians/installers "
                      formik={formik}
                      defaultValue="254"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Area Served (Enter Zipcodes or Cities)  "
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="AreaServed"
                      placeholder="Enter area served"
                      formik={formik}
                      defaultValue="test"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Role"
                  element={(formik) => (
                    <TextInput
                      name="role"
                      placeholder="Enter role"
                      formik={formik}
                      defaultValue="India make One"
                      disabled
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Payment Mode"
                  element={(formik) => (
                    <TextInput
                      name="PaymentMode"
                      placeholder="Enter payment mode"
                      formik={formik}
                      defaultValue="Monthly ($200)"
                      disabled
                    />
                  )}
                />
                 <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save Changes
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Setup Card Details',
      key: 'cardDetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="Card Holder"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="cardHolder"
                      placeholder="Enter card holder"
                      formik={formik}
                      defaultValue="test"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Card Number"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="CardNumber"
                      placeholder="Enter card number"
                      formik={formik}
                      type="number"
                      defaultValue="123456789"
                      disabled
                    />
                  )}
                />
                <FormElementWrapper
                    md={6}
                    label="Exp. Month"
                    mandatory={true}
                    element={(formik) => (
                        <Select
                            name="ExpMonth"
                            placeholder="Select exp. month"
                            options={ExMonthData}
                            showSearch
                            formik={formik}
                            defaultValue="12"
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Exp. Year"
                    mandatory={true}
                    element={(formik) => (
                        <Select
                            name="ExpYear"
                            placeholder="Select exp. year"
                            options={ExYearData}
                            showSearch
                            formik={formik}
                            defaultValue="2022"
                        />
                    )}
                />
                <FormElementWrapper
                  md={6}
                  label="CVV"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="CVV"
                      placeholder="CVV"
                      formik={formik}
                      type="number"
                    />
                  )}
                />
                 <FormElementWrapper
                        md={12}
                        className="text-end"
                        element={() => (
                        <>
                            <CommonButton
                            type="submit"
                            className="btn btn-primary"
                            >
                            Save Changes
                            </CommonButton>
                        </>
                        )}
                    />
                </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Setup Business Details',
      key: 'businessdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
                <div className="alert alert-danger alert-icon">
                    <em className="icon ni ni-alert-circle"></em> <b>Note:-</b> Please fill the form carefully because the form cannot be updated after successful submission.
                </div>
                <div className='ncaBusinessDetails'>
                    <h5 className='title mb-3'>Business Details</h5>
                    <div className='divider sm'></div>
                    <Row>
                        <Col lg={6}>
                            <ul className="list-unstyled mb-0">
                                <li><label className='sub-text'>Company Name</label>Codiant</li>
                                <li><label className='sub-text'>Phone Number</label>0856381212</li>
                                <li><label className='sub-text'>Address Line 1</label> 15251 Smithfield Dr, Westfield, IN 46074, USA</li>
                                <li><label className='sub-text'>State</label>Indiana</li>
                                <li><label className='sub-text'>Zipcode</label>46074</li>
                                <li><label className='sub-text'>Industry</label> <div className='d-flex flex-wrap'><span className="badge bg-light me-1">Financial Services</span> <span className="badge bg-light me-1">Real Estate</span></div> </li>
                                <li><label className='sub-text'>Ownership Type</label>This person is authorized as the primary representative of the account.</li>
                            </ul>
                        </Col>
                        <Col lg={6}>
                            <ul className="list-unstyled">
                                <li><label className='sub-text'>Employer Identification</label> 123456</li>
                                <li><label className='sub-text'>Reg. Business Address</label>US</li>
                                <li><label className='sub-text'>Address Line 2</label> NA</li>
                                <li><label className='sub-text'>City</label> Westfield</li>
                                <li><label className='sub-text'>Doing Business As</label> Consultant</li>
                                <li><label className='sub-text'>Business Website</label> <Link to="http://www.codiant.com">http://www.codiant.com</Link></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                    <Accordion defaultActiveKey="0" flush className='p-0 border'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div>
                                <h6>Ravi Singh</h6>
                                <p>Representative, Owner, CEO</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className='p-3'>
                                <Row className='mt-3'>
                                    <Col md={6}>
                                        <ul className="list-unstyled mb-0">
                                            <li><label className='sub-text'>Date of birth</label>November 2, 2022</li>
                                            <li><label className='sub-text'>SSN last four</label>1232</li>
                                            <li><label className='sub-text'>Home Address</label> US</li>
                                            <li><label className='sub-text'>Address Line 2</label>NA</li>
                                            <li><label className='sub-text'>State</label>Indiana</li>
                                            <li><label className='sub-text'>Zipcode</label>46074 </li>
                                            <li><label className='sub-text'>Business Tax ID</label> 123456789</li>
                                            <li><label className='sub-text'>Document Front </label> <span className='badge rounded-pill bg-primary'>Verified</span> </li>
                                            <li className='documentImg'>
                                                <ImageElement src="/assets/images/admin/scan.jpg"/>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <ul className="list-unstyled">
                                            <li><label className='sub-text'>Email</label>  king@king.com</li>
                                            <li><label className='sub-text'>Phone Number</label>9876543210</li>
                                            <li><label className='sub-text'>Address Line 1</label> 15251 Smithfield Dr, Westfield, IN 46074, USA</li>
                                            <li><label className='sub-text'>City</label> Westfield</li>
                                            <li><label className='sub-text'>Business VAT ID</label> 987654321</li>
                                            <li><label className='sub-text'>Business Tax ID</label> 123456789</li>
                                            <li><label className='sub-text'>Document Back </label> <span className='badge rounded-pill bg-primary'>Verified</span></li>
                                            <li className='documentImg'>
                                                <ImageElement src="/assets/images/admin/scan-2.png"/>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </Row>
                </div>
                <div className=''>
                <FormWrapper row={true} extraClassName="g-3">
                
                  <FormElementWrapper
                    md={6}
                    label="Business Type"
                    element={(formik) => (
                      <Select
                        name="businessType"
                        placeholder="Select business type"
                        options={businessTypeData}
                        showSearch
                        formik={formik}
                        defaultValue="Individual"
                        onChange={handleJobTypeChange}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label=""
                    element={(formik) => (
                      <></>
                    )}
                  />
                  {
                    jobType ?
                    <>
                      <FormElementWrapper
                      md={6}
                      label="First Name"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="FirstName"
                          placeholder="Enter first name"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Last Name"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="LastName"
                          placeholder="Enter last name"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Email"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Email"
                          placeholder="Enter email"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Job Title "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="JobTitle "
                          placeholder="Enter job title "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Home Address"
                      mandatory={true}
                      element={(formik) => (
                        <Select
                          name="HomeAddress"
                          placeholder="Select home address"
                          options={HomeAddressTypeData}
                          showSearch
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Address Line 1"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Address Line 1"
                          placeholder="Enter address line 1"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Address Line 2"
                      element={(formik) => (
                        <TextInput
                          name="Address Line 2"
                          placeholder="Enter address line 2"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="State"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="State"
                          placeholder="Enter state"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="City"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="City"
                          placeholder="Enter city"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Zip Code "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Zip Code "
                          placeholder="Enter zip code "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Business Phone Number "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Business Phone Number "
                          placeholder="Enter business phone number "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Last 4 Digit Of Social Security Number"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Last 4 Digit Of Social Security Number "
                          placeholder="Enter last 4 digit of social security number "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Upload Identity Document Front"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Front"
                          placeholder="Enter Upload Identity Document Front"
                          formik={formik}
                          type="file"
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Upload Identity Document Back"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Front"
                          placeholder="Enter Upload Identity Document Back"
                          formik={formik}
                          type="file"
                        />
                      )}
                    />    
                    <FormElementWrapper
                      md={6}
                      label="Date Of Birth"
                      mandatory={true}
                      element={(formik) => (
                        <DatePicker
                          name="Date Of Birth"
                          className="form-control date-picker shadow-none"
                          placeholder="Select date of birth"
                        />
                      )}
                    />
                    </>
                    :
                    <>
                      <FormElementWrapper
                      md={6}
                      label="Company "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Company "
                          placeholder="Enter company "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Employer Identityfication Number"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Employer Identityfication Number"
                          placeholder="Enter employer identityfication number"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Doing Business As"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Doing Business As"
                          placeholder="Enter doing business as"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                    md={6}
                    label="Industry"
                    mandatory={true}
                    element={(formik) => (
                      <Select
                        name="Industry"
                        placeholder="Select industry"
                        options={IndustryTypeData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                    <FormElementWrapper
                      md={6}
                      label="Registered Business Address"
                      mandatory={true}
                      element={(formik) => (
                        <Select
                          name="RegisteredBusinessAddress1"
                          placeholder="Select registered business address"
                          showSearch
                          formik={formik}
                          options={HomeAddressTypeData}

                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Address Line 1"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Address Line 1"
                          placeholder="Enter address line 1"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Address Line 2"
                      element={(formik) => (
                        <TextInput
                          name="Address Line 2"
                          placeholder="Enter address line 2"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="State"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="State"
                          placeholder="Enter state"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="City"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="City"
                          placeholder="Enter city"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Zip Code "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Zip Code "
                          placeholder="Enter zip code "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Business Phone Number "
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Business Phone Number "
                          placeholder="Enter business phone number "
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Business Website"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="BusinessWebsite"
                          placeholder="Enter business website"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Business Id Number (Tax Id)"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Business Id Number (Tax Id)"
                          placeholder="Enter Business Id Number (Tax Id)"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={6}
                      label="Vat Number"
                      mandatory={true}
                      element={(formik) => (
                        <TextInput
                          name="Vat Number"
                          placeholder="Enter vat number"
                          formik={formik}
                        />
                      )}
                    />

                    </>
                  }
                  <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save Changes
                        </CommonButton>
                      </>
                    )}
                  />
                  </FormWrapper>
                </div>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Setup Banking Details ',
      key: 'bankingdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
            <div className="alert alert-danger alert-icon">
                <em className="icon ni ni-alert-circle"></em> <b>Note:-</b> Please fill the form carefully because the form cannot be updated after successful submission.
            </div>
            <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="Bank Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="BankName"
                      placeholder="Enter bank name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Bank Account Holder Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="BankAccountHolderName"
                      placeholder="Enter bank account holder name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Account Number"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="Accountnumber"
                      placeholder="Enter account number"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Bank Account Routing Number"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="BankAccountRoutingNumber"
                      placeholder="Enter bank account routing number"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Bank Location "
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="bankLocation"
                      placeholder="Enter bank location "
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Email Address"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="EmailAddress"
                      placeholder="Enter email address"
                      formik={formik}
                    />
                  )}
                />
                 <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save Changes
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
                
                {/* <div className='ncaBusinessDetails'>
                      <h5 className='title mb-3'>Banking Details</h5>
                      <div className='divider sm'></div>
                      <Row>
                          <Col lg={6}>
                              <ul className="list-unstyled mb-0">
                                  <li><label className='sub-text'>Bank Name</label>State Bank</li>
                                  <li><label className='sub-text'>Account Number</label>000123456789</li>
                                  <li><label className='sub-text'>Bank Location</label> Indianapolis</li>
                                  
                              </ul>
                          </Col>
                          <Col lg={6}>
                              <ul className="list-unstyled">
                                <li><label className='sub-text'>A/C Holder Name</label>Harshal</li>
                                  <li><label className='sub-text'>Routing Number</label>110000000</li>
                                  <li><label className='sub-text'>Email Address</label> harshal58@yopmail.com </li>
                              </ul>
                          </Col>
                      </Row>
                  </div> */}
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Geo Fence Location ',
      key: 'geoFenceLocation',
      content: (
        <>
          <div className="card">
            <div className="card-inner px-0">
                <div className='text-end mx-4'>
                    <CommonButton className="btn btn-primary" onClick= { addLocationPage }>Add Location</CommonButton>
                </div>
                <TablePlus
                    hasLimit
                    noOfPage="1"
                    sizePerPage="10"
                    page="1"
                    count="100"
                    tableData={geoLocationData}
                    tableColumns={geoLocationColumns}
                    // tableLoader={isLoading}
                    setSizePerPage=""
                    selectRow={true}
                    isSearch={false}
                    showAction={false}
                    statusAction
                    statusActionOption={statusActionOption}
                />
                <SweetAlert
                    title="Are you sure"
                    text="you want to delete this location"
                    show={isAlertVisible}
                    icon="warning"
                    showCancelButton
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    setIsAlertVisible={setIsAlertVisible}
                    // showLoaderOnConfirm
                    // loading={loading}
                    onConfirmAlert={onConfirmAlert}
                />
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Service Category',
      key: 'serviceCategory',
      content: (
        <>
          <div className="card">
            <div className="card-inner card-inner px-0">
                <div className='mx-4 d-flex align-items-center justify-content-end flex-wrap'>
                    <CommonButton className="btn btn-white btn-outline-light"><em className="icon ni ni-download-cloud me-1 mb-1 mb-sm-0"></em> Export</CommonButton>
                    <CommonButton className="btn btn-primary mb-1 mb-sm-0 ms-2" onClick = { addServiceCategoryPage }>Add Service Category</CommonButton>
                    <CommonButton className="btn btn-primary mb-1 mb-sm-0 ms-2" onClick = { addExistingCategoryShow }>Add Existing Category</CommonButton>
                </div>
                <TablePlus
                    hasLimit
                    noOfPage="1"
                    sizePerPage="10"
                    page="1"
                    count="100"
                    tableData={serviceCategoryData}
                    tableColumns={serviceCategoryColumns}
                    // tableLoader={isLoading}
                    setSizePerPage=""
                    selectRow={true}
                    isSearch={false}
                    showAction={false}
                    statusAction
                    statusActionOption={statusActionOption1}
                />
                <SweetAlert
                    title="Are you sure"
                    text="you want to delete this Service Category"
                    show={isAlertVisible1}
                    icon="warning"
                    showCancelButton
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    setIsAlertVisible={setIsAlertVisible1}
                    // showLoaderOnConfirm
                    // loading={loading}
                    onConfirmAlert={onConfirmAlert1}
                />
                <ModalComponent
                    backdrop
                    show={addExistingCategory}
                    onHandleCancel={addExistingCategoryHide}
                    title="Add Existing Category"
                >
                     <FormWrapper row={true} extraClassName="g-3">
                        <FormElementWrapper
                            md={6}
                            label="Location"
                            mandatory={true}
                            element={(formik) => (
                                <Select
                                    name="Location"
                                    placeholder="Select location"
                                    options={locationData}
                                    showSearch
                                    formik={formik}
                                />
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Category"
                            mandatory={true}
                            element={(formik) => (
                                <Select
                                    name="category"
                                    placeholder="Select category"
                                    options={categoryData}
                                    showSearch
                                    formik={formik}
                                />
                            )}
                        />
                        <FormElementWrapper
                            md={12}
                            className="text-end"
                            element={() => (
                            <>
                                <CommonButton
                                    className="btn btn-primary me-2"
                                    onClick={() => addExistingCategoryHide()}
                                >
                                Add
                                </CommonButton>
                                <CommonButton
                                    className="btn btn-secondary"
                                    onClick={() => addExistingCategoryHide()}
                                >
                                Cancel
                                </CommonButton>
                                
                            </>
                            )}
                        />
                    </FormWrapper>
                </ModalComponent>
            </div>
          </div>
        </>
      ),
    },
  ];
  const multipleButton  = [
    {
      title: "Go To NCA Dashboard",
      handler: () => { DashboardPage() }
    },
  ]
  return (
    <div className="">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit NCA">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton', 'create', "multipleButton"]}
            btnText="Go To Log Report"
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
            onHandleShow={LogReportPage}
            multipleButton={multipleButton}
          />
        </div>
      </div>
      <div className="nk-block">
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          // onTabChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default EditNCA;
