import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AntSelect,
  Breadcrumb,
  ImageElement,
  InputType,
  ListingHeader,
  PageHeader,
} from '../../../../components';

function AddGeoLocation(props) {
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/manage-location',
      name: 'Geo Fence Locations',
    },
    {
      path: '#',
      name: 'Edit Location',
    },
  ];
  const countryData = [
    {
      id: '1',
      name: 'India',
    },
    {
      id: '2',
      name: 'United State',
    },
  ];
  const locationForData = [
    {
      id: '1',
      name: 'Service Type',
    },
  ];
  const backPage = () => {
    navigate('/admin/manage-location');
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Location">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <div className="row">
              <div className="col-lg-6">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="form-label">
                          Location Name <span className="text-danger">*</span>
                        </label>
                        <InputType
                          className="form-control"
                          placeholder="Enter location name"
                          name="LocationName"
                          disabled={false}
                          variant="standard"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="form-label">
                          Country <span className="text-danger">*</span>
                        </label>
                        <AntSelect
                          id="status"
                          size="medium"
                          extraClassName="form-control"
                          name="statusId"
                          disabled={false}
                          variant="standard"
                          placeholder="Select country "
                          options={countryData}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="form-label">
                          Location For <span className="text-danger">*</span>
                        </label>
                        <AntSelect
                          id="status"
                          size="medium"
                          extraClassName="form-control"
                          name="statusId"
                          disabled={false}
                          variant="standard"
                          placeholder="Select location for "
                          options={locationForData}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="form-label">
                          Pull Permit Cost (in $){' '}
                          <span className="text-danger">*</span>
                        </label>
                        <InputType
                          className="form-control"
                          placeholder="Enter Pull Permit Cost"
                          name="  PullPermitCost"
                          disabled={false}
                          variant="standard"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Link href="#" className="btn btn-primary me-2">
                        Update
                      </Link>
                      <Link href="#" className="btn btn-light
                      ">
                        Reset
                      </Link>
                    </div>
                  </div>
                </form>
                <div className="bq-note mt-3 mt-lg-4 mt-xl-5">
                  <div className="bq-note-text">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Notes:</h5>
                    </div>
                    <ul className="list list-svg">
                      <li> 
                          <ImageElement
                            source="select.svg"
                            className="p-0"
                            alt="select"
                          />
                        With use of this icon, you can edit exist polygon shape.
                      </li>
                      <li>
                        <ImageElement
                            source="polygon.svg"
                            className="p-0"
                            alt="polygon"
                          />
                        With use of this icon, you can draw new polygon shape.
                      </li>
                      <li>
                          <ImageElement
                            source="chart.svg"
                            className="p-0"
                            alt="chart"
                          />
                        With use of this icon, you can draw new polygon lines.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0">
                <p className="mb-0">
                  <b>Draw Location Point Here In Map :<span className='text-danger'>*</span></b>
                </p>
                <p>
                  Please select the area by putting the points around it. Please{' '}
                  <Link to="#"> click here </Link> to view how to select the
                  area and add it.
                </p>
                <div className="card card-bordered w-100">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004069.896900944!2d-104.65611544442767!3d37.27565371492453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1667816322585!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: '0' }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddGeoLocation;
