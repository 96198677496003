import React from 'react';
import { Dropdown } from 'react-bootstrap';
function ActionWrapper({ children }) {
  return (
    <>
      <div className="text-end">
        <Dropdown
          // onClick={(e) => e.stopPropagation()}
          className="position-static"
        >
          <Dropdown.Toggle as="a" className="btn btn-icon btn-trigger">
            <em className="icon ni ni-more-h" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" size="sm" className="wide-xs">
            <ul className="link-list-plain">{children}</ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
export default ActionWrapper;
