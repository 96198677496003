import { Outlet } from 'react-router-dom';
import { ListTag } from '../../views/Tags';

export default function route() {
  return [
    {
      path: accessRoute.TAGS.path,
      private: true,
      name: 'Tags',
      key: "tags",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.TAGS.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.TAGS.path,
        name: 'Tags',
        key: accessRoute.TAGS.key,
        belongsToSidebar: false,
        icon: accessRoute.TAGS.icon,
        element: <ListTag />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  TAGS: {
    path: '/admin/tags',
    key:"tags.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-opt-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
