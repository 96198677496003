import React from 'react';
import { IMAGE_URL } from '../../../config';

function ImageElement({ previewSource = '', source, alt = 'image', ...rest }) {
  return (
    <>
      {previewSource ? (
        <img src={previewSource} alt={alt} {...rest} />
      ) : (
        <img src={`${IMAGE_URL}/${source}`} alt={alt} {...rest} />
      )}
    </>
  );
}

export default ImageElement;
