import React from "react";
// import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CommonButton, AntSelect } from "../../../index";
// import {DatePicker, CommonButton, AntSelect} from "../../../index";

function LocalizationFilter() {
  const selectAction = [
    {
      id: "action",
      name: "Make Action",
    },
    {
      id: "Inactive",
      name: "Make Inactive",
    },
    {
      id: "delete",
      name: "Make Delete",
    },
  ];

  return (
    <form>
      <div className="dropdown-body dropdown-body-rg">
        <div className="form-group ">
          <label className="overline-title overline-title-alt">Select</label>
          <AntSelect
            size="medium"
            id="status"
            extraClassName="form-control"
            name="statusId"
            disabled={false}
            variant="standard"
            placeholder="Select action"
            options={selectAction}
          />
        </div>
      </div>
      <div className="dropdown-foot between">
        <Link to="#" type="button" className="clickable">
          Reset
        </Link>

        <CommonButton extraClassName="btn btn-sm btn-primary" htmlType="submit">
          Apply
        </CommonButton>
      </div>
    </form>
  );
}

export default LocalizationFilter;
