import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  SweetAlert,
  TablePlus,
  InputFilter,
  DiscountFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListAmountCalculation(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Amount Calculation Formula Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Amount Calculation Formula',
    },
  ];
  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Delete'
    }    
  ]
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectDiscountProps = (dataIndex) => ({
    filterDropdown: (props) => (<DiscountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'orderType',
      title: '	Order Type',
        sorter: true,
      ...getColumnSearchProps("order type")
    },
    {
      dataIndex: 'locationName',
      title: 'Location Name',
        sorter: true,
        ...getColumnSearchProps("location name")
    },
    {
      dataIndex: 'stateTax',
      title: 'State Tax (%)',
        sorter: true,
        ...getColumnSearchProps("state tax")
    },
    {
      dataIndex: 'salesTax',
      title: 'Sales Tax (%)',
        sorter: true,
        ...getColumnSearchProps("sales tax")
    },
    {
      dataIndex: 'locationProfit',
      title: 'Location Profit (% or $)',
        sorter: true,
        ...getColumnSelectDiscountProps("location profit")
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditQuoteOrderShow()},
      },
      {
        name: 'Copy',
        icon: 'icon ni ni-copy',
        handler: () =>{addQuoteOrderShow()},
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const adminData = [
    {
      id: 1,
      orderType: 'Best',
      locationName: 'Indore',
      stateTax: '	15',
      salesTax: '	12',
      locationProfit: '$ 10'
    },
    {
      id: 2,
      orderType: 'Better',
      locationName: 'Indore',
      stateTax: '	12',
      salesTax: '	12',
      locationProfit: '$ 6'
    },
    {
      id: 3,
      orderType: 'Good',
      locationName: 'Indore',
      stateTax: '	15',
      salesTax: '	14',
      locationProfit: '$ 15'
    },
    {
      id: 4,
      orderType: ' 	Premium',
      locationName: 'Indianapolis',
      stateTax: '	10',
      salesTax: '	10',
      locationProfit: '$ 10'
    },
  ];
  const [addQuoteOrder, setAddQuoteOrder] = useState(false);
  const addQuoteOrderHide = () =>{
    setAddQuoteOrder(false)
  }
  const addQuoteOrderShow = () =>{
    setAddQuoteOrder(true)
  }
  const [EditQuoteOrder, setEditQuoteOrder] = useState(false);
  const EditQuoteOrderHide = () =>{
    setEditQuoteOrder(false)
  }
  const EditQuoteOrderShow = () =>{
    setEditQuoteOrder(true)
  }
  const QuoteType =[
    {
      name: 'Premium',
      value: 'Premium',
    },
    {
      name: 'Better',
      value: 'Better',
    },
  ]
  const LocationData = [
    {
      name: 'Anderson/Muncie, Indiana',
      value: 'Anderson/Muncie, Indiana',
    },
    {
      name: 'Bangalore',
      value: 'Bangalore',
    },
  ]
  const dollarData =[
    {
      name: '$ (Dollar)',
      value: '$ (Dollar)',
    },
    {
      name: '% (Percentage)',
      value: '% (Percentage)',
    },
  ]
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Amount Calculation Formula">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create',]}
            btnText="Add Calculation Formula"
            onHandleShow={addQuoteOrderShow}
            // popover={<AmountCalculationFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Amount Calculation Formula "
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Amount Calculation Formula module will list all Calulation Formula on this page.
                </li>
                <li>
                Administrator can Edit / Delete any Amount Calulation Formula.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={addQuoteOrder}
        onHandleCancel={addQuoteOrderHide}
        title="Add Amount Calculation Formula"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
            md={6}
            label="Quote Type"
            mandatory={true}
            element={(formik) => (
              <Select
                name="QuoteType"
                placeholder="Select quote type"
                options={QuoteType}
                // showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Location"
            mandatory={true}
            element={(formik) => (
              <Select
                name="location"
                placeholder="Select location"
                options={LocationData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
              md={6}
              label="State Tax (In Percentage) "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="statetax"
                  placeholder="Enter state tax (in percentage)"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Sales Tax (In Percentage) "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="salestax"
                  placeholder="Enter sales tax (in percentage)"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Location Profit "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="LocationProfit"
                  placeholder="Enter location profit"
                  formik={formik}
                  type="number"
                />
              )}
            />
            <FormElementWrapper
                md={6}
                label="In (% OR $) "
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="displayorder"
                    placeholder="Select % OR $ "
                    options={dollarData}
                    // showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                      onClick={() => addQuoteOrderHide()}
                    >
                      Add
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light me-2"
                    >
                      Reset
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => addQuoteOrderHide()}
                    >
                      Cancel
                    </CommonButton>
                  </>
                )}
              />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={EditQuoteOrder}
        onHandleCancel={EditQuoteOrderHide}
        title="Edit Amount Calculation Formula"
      >
        <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
            md={6}
            label="Quote Type"
            mandatory={true}
            element={(formik) => (
              <Select
                name="QuoteType"
                placeholder="Select quote type"
                options={QuoteType}
                // showSearch
                formik={formik}
                defaultValue="Best"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Location"
            mandatory={true}
            element={(formik) => (
              <Select
                name="location"
                placeholder="Select location"
                options={LocationData}
                showSearch
                formik={formik}
                defaultValue="Indore"
              />
            )}
          />
          <FormElementWrapper
              md={6}
              label="State Tax (In Percentage) "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="statetax"
                  placeholder="Enter state tax (in percentage)"
                  formik={formik}
                  type="number"
                  defaultValue="15"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Sales Tax (In Percentage) "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="salestax"
                  placeholder="Enter sales tax (in percentage)"
                  formik={formik}
                  type="number"
                  defaultValue="12"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Location Profit "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="LocationProfit"
                  placeholder="Enter location profit"
                  formik={formik}
                  type="number"
                  defaultValue="10"
                />
              )}
            />
            <FormElementWrapper
                md={6}
                label="In (% OR $)"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="displayorder"
                    placeholder="Select % OR $ "
                    options={dollarData}
                    // showSearch
                    formik={formik}
                    defaultValue="$ (Dollar)"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-1 me-sm-2"
                      onClick={() => addQuoteOrderHide()}
                    >
                      Update
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light me-1 me-sm-2"
                    >
                      Reset
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => addQuoteOrderHide()}
                    >
                      Cancel
                    </CommonButton>
                  </>
                )}
              />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListAmountCalculation;
