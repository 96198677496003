import { Outlet } from 'react-router-dom';
import { ViewSiteStatistics } from '../../views/SiteStatistics';

export default function route() {
  return [
    {
      path: accessRoute.SITE_STATISTICS.path,
      private: true,
      name: 'Site Statistics',
      key: "siteStatistics",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.SITE_STATISTICS.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.SITE_STATISTICS.path,
        name: 'Site Statistics',
        key: accessRoute.SITE_STATISTICS.key,
        belongsToSidebar: false,
        icon: accessRoute.SITE_STATISTICS.icon,
        element: <ViewSiteStatistics />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  SITE_STATISTICS: {
    path: '/admin/site-statistics',
    key:"siteStatistics.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-bar-chart-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
