import {   Toggle } from "../../components";
import { Link } from "react-router-dom";

const ButtonFormatter = (url, text) => {
    return (
        <>
            <Link to={url} className="btn btn-primary btn-sm">{text}</Link>
        </>
    );
};
const TextFormatter = (heading, para) => {
    return (
        <>
            <h6 className="mb-0">{heading}</h6>
            <p>{para}</p>
        </>
    );
};
const StatusFormatter = (status) => {
    return (
        <>{
            status === 'Verified' ? 
            <span class="badge badge-md badge-dim bg-success d-inline-flex align-items-center"><em className="ni ni-check-circle-fill me-1" />{status}</span>
            :
            <span class="badge badge-md badge-dim bg-danger d-inline-flex align-items-center"><em className="ni ni-cross-circle-fill me-1" />{status}</span>
        }
        </>
    );
};

export const googleGMBServiceTableColumn = ({Action}) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'business',
            title: 'Business',
            render: (cell, row) => TextFormatter( row?.business, "Bealeton, VA 22712, USA, Edinburg, VA 22824, USA, and 18 others areas"),
        },
        {
            dataIndex: 'totalReviews',
            title: 'Total Reviews',
            render: (cell, row) => ButtonFormatter("/admin/google-gmb/review", row?.totalReviews),
        },
        {
            dataIndex: 'googleRating',
            title: 'Google Rating',
        },
        {
            dataIndex: 'reviewsToRespondTo',
            title: 'Reviews To Respond To',
        },
        {
            dataIndex: 'status',
            title: 'Status',
            render:(cell, row)=>StatusFormatter(row?.status),
        },
        {
            dataIndex: 'action',
            title: 'Action',
            render: () => <Action />,
            className: 'text-end'
        },
    ]
};
