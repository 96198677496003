import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from '@ant-design/icons';
import React , {useState} from "react";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  ModalComponent,
  ImageElement,
  TablePlus,
} from "../../../../components";
import { Link } from "react-router-dom";

function BulkUpload(props) {
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Upload Bulk Data",
    },
  ];
  const [uploadFilter, setuploadFilter] = useState(false);
  const uploadFilterShow = () => {
    setuploadFilter(true);
  };
  const uploadFilterHide = () => {
    setuploadFilter(false);
  };
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const recordsColumns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'status',
      title: '	Status',
      sorter: true,
    },
    {
      dataIndex: 'productImage',
      title: 'Product Image',
      sorter: true,
      render: (cell, row) => logoFormatter(row?.productImage,"Image"),
    },
    {
      dataIndex: 'companyName',
      title: 'Company Name ',
      sorter: true,
    },
    {
      dataIndex: 'industryType',
      title: 'Industry Type',
      sorter: true,
    },
    {
      dataIndex: 'equipmentMaterialIAQ',
      title: 'Equipment, Material, IAQ',
      sorter: true,
    },
    {
      dataIndex: 'quoteType',
      title: 'Quote Type',
      sorter: true,
    },
    {
      dataIndex: 'brand',
      title: 'Brand',
      sorter: true,
    },
    {
      dataIndex: 'brand',
      title: 'Brand',
      sorter: true,
    },
    {
      dataIndex: 'systemBTUCapacity',
      title: 'System BTU/Capacity',
      sorter: true,
    },
    {
      dataIndex: 'sizeDescription',
      title: 'Size Description',
      sorter: true,
    },
    {
      dataIndex: 'directional',
      title: 'Directional',
      sorter: true,
    },
    {
      dataIndex: 'minimumBlowerDrive',
      title: 'Minimum Blower Drive',
      sorter: true,
    },
    {
      dataIndex: 'materialListType',
      title: 'Material List Type',
      sorter: true,
    },
    {
      dataIndex: 'materialName',
      title: 'Material Name',
      sorter: true,
    },
    {
      dataIndex: 'materialAdditionalCostToCustomer',
      title: 'Material Additional Cost to Customer',
      sorter: true,
    },
    {
      dataIndex: 'materialExpense',
      title: 'Material Expense',
      sorter: true,
    },
    {
      dataIndex: 'materialAutoFill',
      title: 'Material Auto Fill',
      sorter: true,
    },
    {
      dataIndex: 'optionStatus',
      title: 'Option Status',
      sorter: true,
    },
    {
      dataIndex: 'gasEfficiency',
      title: 'Gas Efficiency',
      sorter: true,
    },
    {
      dataIndex: 'wifi',
      title: 'Wifi',
      sorter: true,
    },
    {
      dataIndex: 'programmableStat',
      title: 'Programmable Stat',
      sorter: true,
    },
    {
      dataIndex: 'otherRebates',
      title: 'Other Rebates',
      sorter: true,
    },
    {
      dataIndex: 'aHRICertifiedReferenceNumber',
      title: 'AHRI Certified Reference Number',
      sorter: true,
    },
    {
      dataIndex: 'oldAHRIReferenceNumber',
      title: 'Old AHRI Reference Number',
      sorter: true,
    },
    {
      dataIndex: 'modelStatus',
      title: 'Model Status',
      sorter: true,
    },
    {
      dataIndex: 'manufacturerType',
      title: 'Manufacturer Type',
      sorter: true,
    },
    {
      dataIndex: 'aHRIType',
      title: 'AHRI Type',
      sorter: true,
    },
    {
      dataIndex: 'phase',
      title: 'Phase',
      sorter: true,
    },
    {
      dataIndex: 'seriesName',
      title: 'Series Name',
      sorter: true,
    },
    {
      dataIndex: 'outdoorUnitBrandName',
      title: 'Outdoor Unit - Brand Name',
      sorter: true,
    },
    {
      dataIndex: 'outdoorUnitModelNumber',
      title: 'Outdoor Unit - Model Number  (Condenser or Single Package)',
      sorter: true,
    },
    {
      dataIndex: 'indoorUnitBrandName',
      title: 'Indoor Unit - Brand Name',
      sorter: true,
    },
    {
      dataIndex: 'indoorUnitModelNumber',
      title: 'Indoor Unit - Model Number (Evaporator and/or Air Handler)',
      sorter: true,
    },
    {
      dataIndex: 'furnaceModelNumber',
      title: 'Furnace - Model Number',
      sorter: true,
    },
    {
      dataIndex: 'AHRICertifiedRatingsCoolingCapacity',
      title: 'AHRI Certified Ratings - Cooling Capacity (A2) - Single or High Stage (95F),btuh',
      sorter: true,
    },
    {
      dataIndex: 'AHRICertifiedRatingsEER',
      title: 'AHRI Certified Ratings - EER (A2) - Single or High Stage (95F)',
      sorter: true,
    },
    {
      dataIndex: 'AHRICertifiedRatingsIEER',
      title: 'AHRI Certified Ratings - IEER',
      sorter: true,
    },
    {
      dataIndex: 'AHRICertifiedRatingsSEER',
      title: 'AHRI Certified Ratings - SEER',
      sorter: true,
    },
    {
      dataIndex: 'IndoorFullLoadAirVolumeRate',
      title: 'Indoor Full-Load Air Volume Rate (A2 SCFM)',
      sorter: true,
    },
    {
      dataIndex: 'IndoorCoolingIntermediateAirVolumeRate',
      title: 'Indoor Cooling Intermediate Air Volume Rate (Ev SCFM)',
      sorter: true,
    },
    {
      dataIndex: 'IndoorCoolingMinimumAirVolumeRate',
      title: 'Indoor Cooling Minimum Air Volume Rate (B1 SCFM)',
      sorter: true,
    },
    {
      dataIndex: 'designatedTestedCombination',
      title: 'Designated Tested Combination',
      sorter: true,
    },
    {
      dataIndex: 'soldIn',
      title: 'Sold in?',
      sorter: true,
    },
    {
      dataIndex: 'region',
      title: 'Region',
      sorter: true,
    },
    {
      dataIndex: 'estimatedNationalAverageOperatingCoolingCost',
      title: 'Estimated National Average Operating Cooling Cost ($)',
      sorter: true,
    },
    {
      dataIndex: 'EnergyGuideLabel',
      title: 'Energy Guide Label',
      sorter: true,
    },
    {
      dataIndex: 'EligibleForFederalTaxCredit',
      title: 'Eligible For Federal Tax Credit (installed between Feb 17,2009 and Dec 31,2016)',
      sorter: true,
    },
    {
      dataIndex: 'IsRerated',
      title: 'Is Rerated',
      sorter: true,
    },
    {
      dataIndex: 'SystemPartWarranty',
      title: 'System Part Warranty',
      sorter: true,
    },
    {
      dataIndex: 'SystemHeatExchangerPartWarranty',
      title: 'System Heat Exchanger Part Warranty',
      sorter: true,
    },
    {
      dataIndex: 'Benefits',
      title: 'Benefits',
      sorter: true,
    },
    {
      dataIndex: 'image1',
      title: 'Image1',
      sorter: true,
    },
    {
      dataIndex: 'Image2',
      title: 'image2',
      sorter: true,
    },
    {
      dataIndex: 'Image3',
      title: 'image3',
      sorter: true,
    },
    {
      dataIndex: 'Image4',
      title: 'image4',
      sorter: true,
    },
    {
      dataIndex: 'Image5',
      title: 'image5',
      sorter: true,
    },
    {
      dataIndex: 'miscRebates',
      title: 'Misc Rebates',
      sorter: true,
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
    },
  ];
  const recordsData = [
    {
      id: 1,
      status: 'Good,Better,Best,Premium',
      productImage: '/assets/images/admin/img_default-placeholder.png',
      companyName: 'Good Guys',
      industryType: 'HVAC',
      equipmentMaterialIAQ: 'Equipment',
      quoteType: '90+% Furnace',
      brand: 'Heil',
      systemBTUCapacity: '40-50k BTU',
      sizeDescription: '14" Wide',
      directional: 'Upflow',
      minimumBlowerDrive: '800 CFM',
      materialListType: '90+%Furnace',
      materialName: 'Media Filter 16x25',
      materialAdditionalCostToCustomer: '4000',
      materialExpense: '800',
      materialAutoFill: 'Yes',
      optionStatus: 'Optional2',
      gasEfficiency: '95',
      wifi: 'Yes',
      programmableStat: '-',
      otherRebates: 'Yes',
      aHRICertifiedReferenceNumber: '5839968',
      oldAHRIReferenceNumber: '-',
      modelStatus: 'Active',
      manufacturerType: 'Systems',
      aHRIType: 'SP-A (Single-Package Air-Conditioner, Air-Cooled)',
      phase: '1',
      seriesName: 'R410A PAC SPP',
      outdoorUnitBrandName: 'HEIL',
      outdoorUnitModelNumber: 'PAR524***K***A*',
      indoorUnitBrandName: '-',
      indoorUnitModelNumber: '-',
      furnaceModelNumber: '-',
      AHRICertifiedRatingsCoolingCapacity: '23000',
      AHRICertifiedRatingsEER: '12.00	',
      AHRICertifiedRatingsIEER: '-',
      AHRICertifiedRatingsSEER: '15.00',
      IndoorFullLoadAirVolumeRate: '800',
      IndoorCoolingIntermediateAirVolumeRate: '-',
      IndoorCoolingMinimumAirVolumeRate: '600',
      designatedTestedCombination: '-',
      soldIn: 'USA, Canada	',
      region: 'All',
      estimatedNationalAverageOperatingCoolingCost: '202',
      EnergyGuideLabel: 'Yes	',
      EligibleForFederalTaxCredit: 'Yes',
      IsRerated: '-',
      SystemPartWarranty: '10',
      SystemHeatExchangerPartWarranty: '20',
      Benefits: '-',
      image1: '-',
      Image2: '-',
      Image3: '-',
      Image4: '-',
      Image5: '-',
      miscRebates: '400',
      description: 'quote description',
    },
  ];
  const [uploadedFileReviewSection, setUploadedFileReviewSection] = useState(false);
  const uploadedFileReview = () => {
    setUploadedFileReviewSection(true);
  }
  const deleteImportData = () => {
    setUploadedFileReviewSection(false);
  }
  return (
    <>
      <div className="">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Upload Bulk Data">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={[ 'create', 'csvExport']}
              btnText="Upload Bulk Images"
              onHandleShow={uploadFilterShow}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-8">
                <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={12}
                label="Upload Bulk Data (Please upload only .xlsx file.)"
                element={(formik) => (
                  <TextInput
                    name="firstName"
                    placeholder="Select Image"
                    formik={formik}
                    type="file"
                  />
                )}
              />     
              <FormElementWrapper
                md={12}
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                      onClick={uploadedFileReview}
                    >
                      Upload Bulk Data
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
                </FormWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        uploadedFileReviewSection && 
        <div className='mt-3'>
          <div className='card mb-2'>
            <div className='card-inner'>
              <div className='row align-items-center'>
                <div className='col-xxl-7'>
                  <ul className='d-md-flex justify-content-between'>
                    <li className='color-upload me-3 me-lg-4 me-xxl-5'>Total Record  :  <b>565</b></li>
                    <li className='color-update me-3 me-lg-4 me-xxl-5'>Success Record :  <b>0</b></li>
                    <li className='color-insert me-3 me-lg-4 me-xxl-5'>Error Record  :  <b>5</b></li>
                  </ul>
                </div>
                <div className='col-xxl-5 text-xxl-end mt-2 mt-lg-3 mt-xxl-0'>
                  <CommonButton className="btn btn-secondary" onClick ={ deleteImportData }>Delete Import Data</CommonButton>
                </div>
              </div>
            </div>
          </div>
          <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={recordsData}
            tableColumns={recordsColumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isResetBtn= {false}
            showAction={false}
          />
        </div>
      }
      
      <ModalComponent
        backdrop
        show={uploadFilter}
        onHandleCancel={uploadFilterHide}
        title="Upload Bulk Images"
      >
        <div className="row uploadBulkImages">
          <div className="col-md-12">
            <Dragger multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Drop files here to upload
              </p>
              <p className="ant-upload-hint">Only JPG, JPEG, PNG files types are <br/> supported. Maximum file size is 1MB.</p>
            </Dragger>
          </div>
          <div className="col-md-12">
            <div className="nk-upload-list mt-2">
              <div className="nk-upload-item">
                <div className="nk-upload-icon">
                  <ImageElement source="uploadfile.svg" alt="img" />
                </div>
                <div className="nk-upload-info">
                  <div className="nk-upload-title">
                    <span className="title">Work History/Resume</span>
                  </div>
                  <div className="nk-upload-size">0.49 MB</div>
                </div>
                <div className="nk-upload-action">
                  <Link to="#" className="btn btn-icon btn-trigger">
                    <em className="icon ni ni-trash"></em>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-end mt-2">
            <CommonButton onClick={() => uploadFilterHide()} className="btn btn-primary me-2">
                Upload
            </CommonButton>
            <CommonButton onClick={() => uploadFilterHide()} className="btn btn-secondary">
              Cancel
            </CommonButton>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

export default BulkUpload;
