import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ListingHeader,
  PageHeader,
  DashboardFilterForm,
  ImageElement,
  Charts,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  Select,
  DatePicker,
  CommonButton,
} from '../../components';

export default function Dashboard() {
  const [state, setState] = useState({shouldVisible : false});
  const {shouldVisible} = state;
  const jobData = {
    labels: [
      'All',
      'Completed',
      'Cancelled',
      'Pending',
      'Accepted',
      'Expired',
      'NAC',
      'Will Call List',
    ],
    datasets: [
      {
        label: '# of jobData',
        data: [14832, 9111, 3197, 1000, 29, 2224, 270, 250],
        backgroundColor: [
          '#09C2DE',
          '#1EE0AC',
          '#E5E9F2',
          '#F4BD0E',
          '#854FFF',
          '#E85347',
          '#364A63',
          '#BE0411',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 70,
        // cutoutPercentage: 92
      },
    ],
  };
  const usersData = {
    labels: ['All', 'iOS', 'Android', 'Web', 'Web Manual', 'SP Manual'],
    datasets: [
      {
        label: '# of usersData',
        data: [4, 858, 1817, 353, 178, 2901],
        backgroundColor: [
          '#09C2DE',
          '#1EE0AC',
          '#E5E9F2',
          '#F4BD0E',
          '#854FFF',
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 70,
      },
    ],
  };
  const providerData = {
    labels: [
      'Online',
      'Work In Progress',
      'Enroute to Job',
      'Reached Job',
      'Offline',
    ],
    datasets: [
      {
        label: '# of providerData',
        data: [1, 2, 3, 2, 10],
        backgroundColor: [
          '#1EE0AC',
          '#E5E9F2',
          '#F4BD0E',
          '#854FFF',
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 70,
      },
    ],
  };
  const chartOptionsData = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
    }
  } 
  const [graphModal, setgraphModal] = useState(false);
  const graphModalShow = () => {
    setgraphModal(true);
  };
  const graphModalHide = () => {
    setgraphModal(false);
  };
  const salesData = {
    labels: [
      'Jan 21',
      'Mar 21',
      'May 21',
      'Jul 21',
      'Sep 21',
      'Nov 21',
    ],
    datasets: [
      {
        label: '# of salesData',
        data: [100000, 75000, 50000, 20000, 5000, '10000'],
        backgroundColor: [
          '#09C2DE',
          '#1EE0AC',
          '#E5E9F2',
          '#F4BD0E',
          '#854FFF',
        ],
        borderWidth: 0,
      },
    ],
  };
  const navigate = useNavigate();
  const ProviderView = (e) => {
    e.preventDefault();
    navigate('/admin/provider');
  }
  const RatingView = (e) => {
    e.preventDefault();
    navigate('/admin/review');
  }
  const serviceView = (e) => {
    e.preventDefault();
    navigate('/admin/service');
  }
  const PaymentReportView = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report');
  }
  const ServiceCategoryReport = (e) => {
    e.preventDefault();
    navigate('/admin/service-category/report')
  }
  const QuoteManagementEstimates = (e) => {
    e.preventDefault();
    navigate('/admin/estimates')
  }
  const ScheduledBookings = (e) => {
    e.preventDefault();
    navigate('/admin/scheduled-bookings')
  }
  const ProviderSales = (e) => {
    e.preventDefault();
    navigate('/admin/provider-sales')
  }
  const HeatMapPage = (e) => {
    e.preventDefault();
    navigate('/admin/heatmap');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }

  window.addEventListener("resize",(size)=>{
    // console.log("Size", window.screen.width);
    const {width} = window.screen;
    if(width===1200){
      setState({...state ,shouldVisible:true})
    }
  })

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Dashboard"></PageHeader>
          <ListingHeader
            btnArray={[]}
            popover={<></>}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="nk-block">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="state"
                        placeholder="Select location"
                        options={locationData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
        <div className="row g-gs">
          <div className="col-lg-6 col-xl-6 col-xxl-4">
            <div className="card card-full">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-briefcase"></em></span> Job Status</h6>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div style={{ maxWidth: '310px', margin: '0 auto' }}>
                    <Charts type="doughnut" data={jobData} options={chartOptionsData}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 col-xxl-4">
            <div className="card card-full">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user"></em></span> Registered Users</h6>
                    </div>
                    <div className="card-tools">
                      <Link className="link text-uppercase" onClick={ (e) => HeatMapPage(e) }>Heatmap</Link>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                    <Charts type="doughnut" data={usersData} options={chartOptionsData}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-xxl-4">
            <div className="card card-full">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-crosshair"></em></span> Current Provider Status</h6>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                    <Charts type="doughnut" data={providerData} options={chartOptionsData}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-xxl-4">
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="card card-full">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-clock"></em></span> Emergency Service Response Time Average</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner">
                      <ul className="nk-store-statistics">
                        <li className="item pt-0">
                          <div className="info">
                            <div className="title">Shortest Time</div>
                            <div className="count">06s</div>
                          </div>
                          <em className="icon bg-purple-dim ni ni-cpu"></em>
                        </li>
                        <li className="item">
                          <div className="info">
                            <div className="title">Longest Time</div>
                            <div className="count">7099h 55m 36s</div>
                          </div>
                          <em className="icon bg-info-dim ni ni-loader"></em>
                        </li>
                        <li className="item pb-0">
                          <div className="info">
                            <div className="title">Average Time</div>
                            <div className="count">155h 15m 59s</div>
                          </div>
                          <em className="icon bg-pink-dim ni ni-clock"></em>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="card card-full">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user-cross"></em></span> Active / Inactive Providers</h6>
                      </div>
                      <div className="card-tools">
                        <Link className="link" onClick={  (e) => ProviderView(e) }>View All</Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner border-top">
                    <div className="nk-ecwg nk-ecwg3">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title text-info">Active</h6>
                        </div>
                      </div>
                      <div className="data mt-0">
                        <div className="data-group">
                          <div className="amount">300</div>
                          <div className="info text-end">
                            <span className="change up text-success">
                              <em className="icon ni ni-arrow-long-up"></em>
                              4.63%
                            </span>
                            <br />
                            <span>vs. last week</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-ecwg nk-ecwg3 mt-4">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title text-info">Inactive</h6>
                        </div>
                      </div>
                      <div className="data mt-0">
                        <div className="data-group">
                          <div className="amount">150</div>
                          <div className="info text-end">
                            <span className="change up text-danger">
                              <em className="icon ni ni-arrow-long-down"></em>
                              2.05%
                            </span>
                            <br />
                            <span>vs. last week</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-8">
            <div className="row g-gs">
              <div className="col-lg-6">
                <div className="card card-full">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-star"></em></span> Provider Rating Average</h6>
                        </div>
                        <div className="card-tools">
                          <Link className="link" onClick={ (e) =>  RatingView(e) }>View All</Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">Westfield Indiana</span>
                            <span className="sub-text">15 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">5.0</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">
                              Anderson/Muncie, Indiana
                            </span>
                            <span className="sub-text">12 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">Central Indiana</span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">Indianapolis</span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">Indore</span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">Central Indiana</span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.9</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">
                              The Villages, Florida
                            </span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.5</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <div className="user-info">
                            <span className="lead-text">LOC</span>
                            <span className="sub-text">10 Reviews</span>
                          </div>
                        </div>
                        <div className="review-status">
                          <ul className="review-stars d-flex">
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-fill text-warning"></em>
                            </li>
                            <li>
                              <em className="icon ni ni-star-half-fill text-warning"></em>
                            </li>
                          </ul>
                          <div className="review-count">4.7</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-full">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user-check"></em></span> Registered Providers</h6>
                        </div>
                        <div className="card-tools">
                          <Link className="link" onClick={  (e) => ProviderView(e) }>View All</Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Indore</span>
                        </div>
                        <div>137</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">LOC</span>
                        </div>
                        <div>137</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Indore</span>
                        </div>
                        <div>134</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">
                            Indore, Madhya Pradesh, India
                          </span>
                        </div>
                        <div>134</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Central Indiana</span>
                        </div>
                        <div>24</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Indianapolis</span>
                        </div>
                        <div>24</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Westfield Indiana</span>
                        </div>
                        <div>5</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">
                            Anderson/Muncie, Indiana
                          </span>
                        </div>
                        <div>3</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">
                            The Villages, Florida
                          </span>
                        </div>
                        <div>3</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Central Indiana</span>
                        </div>
                        <div>137</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">LOC</span>
                        </div>
                        <div>30</div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm">
                      <div className="review-item d-flex justify-content-between">
                        <div className="user-card">
                          <span className="lead-text">Indore</span>
                        </div>
                        <div>5</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-sign-dollar"></em></span> Sales</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => serviceView(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner border-top">
                <ul className="nk-store-statistics">
                  <li className="item pt-0">
                    <div className="info">
                      <div className="title">Sales (Last Year)</div>
                      <div className="count">
                        + $0{' '}
                        <span className="change up">
                          <em className="ni ni-arrow-long-up"></em>+ 0 %
                        </span>
                      </div>
                    </div>
                    <Link to="#" onClick={graphModalShow}>
                      <em className="icon bg-warning-dim ni ni-activity-round"></em>
                    </Link>
                  </li>
                  <li className="item pt-0">
                    <div className="info">
                      <div className="title">Sales Total</div>
                      <div className="count">
                        $24,682,452.74{' '}
                        <span className="change down">
                          <em className="ni ni-arrow-long-down"></em>9111
                          Jobs
                        </span>
                      </div>
                    </div>
                    <Link to="#" onClick={graphModalShow}>
                      <em className="icon bg-info-dim ni ni-activity-round"></em>
                    </Link>
                  </li>
                  <li className="item pt-0">
                    <div className="info">
                      <div className="title">Scheduled Sales</div>
                      <div className="count">
                        $7,224,045.21{' '}
                        <span className="change up">
                          <em className="ni ni-arrow-long-up"></em>7917
                          Jobs
                        </span>
                      </div>
                    </div>
                    <Link to="#" onClick={graphModalShow}>
                      <em className="icon bg-purple-dim ni ni-activity-round"></em>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Location</th>
                        <th>Job Count</th>
                        <th>Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Indore</td>
                        <td>1344</td>
                        <td>$19,375,588.75</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>LOC</td>
                        <td>1344</td>
                        <td>$19,375,588.75</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Indore</td>
                        <td>1338</td>
                        <td>$19,358,509.85</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Indore, Madhya Pradesh, India </td>
                        <td>1323</td>
                        <td>$19,350,155.28</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Central Indiana</td>
                        <td>7341</td>
                        <td>$5,205,467.11</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Indianapolis</td>
                        <td>7232</td>
                        <td>$5,101,697.30</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Westfield Indiana</td>
                        <td>1094</td>
                        <td>$860,385.70</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Anderson/Muncie, Indiana</td>
                        <td>212</td>
                        <td>$266,149.27</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>The Villages, Florida</td>
                        <td>374</td>
                        <td>$86,383.33</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Lafayette</td>
                        <td>14</td>
                        <td>$17,584.80</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-reports"></em></span> Invoice Total</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => PaymentReportView(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Payment Method</th>
                        <th>Job Count</th>
                        <th>Payment</th>
                        <th>Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Cash</td>
                        <td>1086</td>
                        <td>$17,953,630.86</td>
                        <td>73.07%</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Credit Card</td>
                        <td>4245</td>
                        <td>$2,930,646.90</td>
                        <td>11.93%</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Financing</td>
                        <td>1753</td>
                        <td>$1,529,946.15</td>
                        <td>6.23%</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Check</td>
                        <td>1314</td>
                        <td>$1,427,520.66</td>
                        <td>5.81%</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Warranty/No Charge</td>
                        <td>19</td>
                        <td>$1,069.65</td>
                        <td>0%</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Not Paid Request Payment</td>
                        <td>446</td>
                        <td>$623,919.02</td>
                        <td>2.54%</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Paid Request Payment</td>
                        <td>41</td>
                        <td>$21,652.28</td>
                        <td>0.09%</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Admin Settled Payment</td>
                        <td>18</td>
                        <td>$51,338.73</td>
                        <td>0.21%</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>Admin Attention Required</td>
                        <td>18</td>
                        <td>$29,289.45</td>
                        <td>0.12%</td>
                      </tr>
                      <tr>
                        <td></td>
                        <th>Total</th>
                        <th>8940</th>
                        <th>$24,569,013.70</th>
                        <th>100%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-setting-alt"></em></span> Service Category</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => ServiceCategoryReport(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Service Type Booked</th>
                        <th>Quantity</th>
                        <th>Average Ticket</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Have A Technician Contact You Today</td>
                        <td>42</td>
                        <td>$216,415.15</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Service Repair</td>
                        <td>81</td>
                        <td>$97,326.69</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Testing Zero value Sales Quote YES Selected</td>
                        <td>1</td>
                        <td>$38,450.58</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          Schedule A Replacement Quote (Heating and Cooling
                          Equipment)
                        </td>
                        <td>334</td>
                        <td>$7,338.36</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Same Day Free Emergency - Sales Quote Yes</td>
                        <td>9</td>
                        <td>$6,433.68</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-calc"></em></span> Estimates</h6>
                  </div>
                  <div className="card-tools">
                    <span className="badge rounded-pill badge-dim bg-outline-success me-1 ms-1">
                      Won 83.28%
                    </span>
                    <Link className="link" onClick={ (e) => QuoteManagementEstimates(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Location</th>
                        <th>Job Count</th>
                        <th>Payment</th>
                        <th>Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Expired</td>
                        <td>204</td>
                        <td>$918,382.71</td>
                        <td>2.63%</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Accepted</td>
                        <td>1215</td>
                        <td>$22,156,139.13</td>
                        <td>63.42%</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Declined</td>
                        <td>40</td>
                        <td>$11,861,941.42</td>
                        <td>33.95%</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Open</td>
                        <td>0</td>
                        <td>$0.00</td>
                        <td>0%</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <th>Total Quotes</th>
                        <th>1459</th>
                        <th>$34,936,463.26</th>
                        <th>100%</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-users"></em></span> Provider Sales Leaders</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => ProviderSales(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Leader</th>
                        <th>Job Count</th>
                        <th>Invoice Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="a-sm.jpg" alt="user-img" />
                              <div className="status dot dot-xxl dot-primary">
                                1
                              </div>
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">MARK SERVICE</span>
                            </div>
                          </div>
                        </td>
                        <td>204</td>
                        <td>$918,382.71</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="b-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Mp Androids</span>
                            </div>
                          </div>
                        </td>
                        <td>104</td>
                        <td>$721,508.64</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="c-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Matt Parsons</span>
                            </div>
                          </div>
                        </td>
                        <td>824</td>
                        <td>$681,221.22</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="d-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Tom Patterson</span>
                            </div>
                          </div>
                        </td>
                        <td>603</td>
                        <td>$552,744.48</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="a-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Mark Hildebrandt</span>
                            </div>
                          </div>
                        </td>
                        <td>626</td>
                        <td>$401,358.58</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-briefcase"></em></span> Latest Jobs</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => serviceView(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner border-top">
                <div className="row g-gs">
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">QA NCA</h6>
                          <div className="title">Same Day Paid</div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-success mb-1">
                            On Going Trip
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>125.00
                          </div>
                          <div className="subtitle">11 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">HVAC</h6>
                          <div className="title">
                            Same Day Emergency Service1
                          </div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-danger mb-1">
                            Finished
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>69.00
                          </div>
                          <div className="subtitle">14 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">HVAC</h6>
                          <div className="title">
                            Same Day Emergency Service1
                          </div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-danger mb-1">
                            Finished
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>69.00
                          </div>
                          <div className="subtitle">14 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">HVAC</h6>
                          <div className="title">
                            Same Day Emergency Service1
                          </div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-danger mb-1">
                            Finished
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>69.00
                          </div>
                          <div className="subtitle">14 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">HVAC</h6>
                          <div className="title">
                            Same Day Emergency Service1
                          </div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-danger mb-1">
                            Finished
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>69.00
                          </div>
                          <div className="subtitle">14 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="analytic-wp-group g-3">
                      <div className="analytic-data analytic-wp-data">
                        <div className="analytic-wp-graph w-auto">
                          <h6 className="mb-1">HVAC</h6>
                          <div className="title">
                            Same Day Emergency Service1
                          </div>
                          <span className="badge rounded-pill bg-danger">
                            Emergency
                          </span>
                        </div>
                        <div className="analytic-wp-text">
                          <span className="badge rounded-pill badge-dim bg-outline-danger mb-1">
                            Finished
                          </span>
                          <div className="amount amount-sm">
                            <span className="green-color">$</span>69.00
                          </div>
                          <div className="subtitle">14 Days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user-list"></em></span> Average Ticket Leaderboard</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link"  onClick={ (e) => ProviderSales(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Leader</th>
                        <th>Invoice Amount/Job Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="a-sm.jpg" alt="user-img" />
                              <div className="status dot dot-xxl dot-primary">
                                1
                              </div>
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">MARK SERVICE</span>
                            </div>
                          </div>
                        </td>
                        <td>$918,382.71</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="b-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">DC</span>
                            </div>
                          </div>
                        </td>
                        <td>$721,508.64</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="c-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Matt Parsons</span>
                            </div>
                          </div>
                        </td>
                        <td>$681,221.22</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="d-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Tom Patterson</span>
                            </div>
                          </div>
                        </td>
                        <td>$552,744.48</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          <div className="user-card">
                            <div className="user-avatar bg-warning">
                              <ImageElement source="a-sm.jpg" alt="user-img" />
                            </div>
                            <div className="user-name">
                              <span className="tb-lead">Mark Hildebrandt</span>
                            </div>
                          </div>
                        </td>
                        <td>$401,358.58</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-offer"></em></span> Promo Code</h6>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 border-top">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Promo Code Name</th>
                        <th>Quantity </th>
                        <th>Average Ticket</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>first</td>
                        <td>49</td>
                        <td>$184,893.92</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>MAVES</td>
                        <td>4</td>
                        <td>$2,673.00</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>SHANE</td>
                        <td>3</td>
                        <td>$1,821.65</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ERIC7</td>
                        <td>4</td>
                        <td>$1,585.24</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>GGHS1</td>
                        <td>66</td>
                        <td>$1,383.42</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>SHANE</td>
                        <td>3</td>
                        <td>$1,821.65</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>ERIC7</td>
                        <td>4</td>
                        <td>$1,585.24</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xxl-6">
            <div className="card card-full">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-opt"></em></span> Available Providers</h6>
                  </div>
                  <div className="card-tools">
                    <Link className="link" onClick={ (e) => ScheduledBookings(e) }>View All</Link>
                  </div>
                </div>
              </div>
              <div className="card-inner border-top">
                <div className="timeline">
                  <ul className="timeline-list d-flex flex-wrap">
                    <li className="timeline-item">
                      <div className="timeline-data">
                        <h6 className="timeline-title mb-1">0</h6>
                        <div className="timeline-des">
                          <p>05 AM-08 AM</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-data">
                        <h6 className="timeline-title mb-1">0</h6>
                        <div className="timeline-des">
                          <p>08 AM-11 AM</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-data">
                        <h6 className="timeline-title mb-1">0</h6>
                        <div className="timeline-des">
                          <p>11 AM-02 PM</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-data">
                        <h6 className="timeline-title mb-1">76</h6>
                        <div className="timeline-des">
                          <p>02 PM-05 PM</p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-data">
                        <h6 className="timeline-title mb-1">64</h6>
                        <div className="timeline-des">
                          <p>05 PM-08 PM</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={graphModal}
        onHandleCancel={graphModalHide}
        title="Graph"
      >
        <div style={{ maxWidth: '500px', width: '100%', margin: '0 auto' }}>
          <Charts type="bar" data={salesData} />
        </div>
        <ul className="nk-store-statistics mt-4">
          <li className="item pt-0">
            <div className="info">
              <div className="title">Sales (Last Year)</div>
              <div className="count">
                + $0
                <span className="change down">
                  <em className="ni ni-arrow-long-up"></em>1 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-warning-dim ni ni-activity-round"></em>
          </li>
          <li className="item pt-0">
            <div className="info">
              <div className="title">Sales Total</div>
              <div className="count">
                $20115773.28
                <span className="change down">
                  <em className="ni ni-arrow-long-down"></em>2696 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-info-dim ni ni-activity-round"></em>
          </li>
          <li className="item pt-0">
            <div className="info">
              <div className="title">Scheduled Sales</div>
              <div className="count">
                $751732.75
                <span className="change up">
                  <em className="ni ni-arrow-long-up"></em>534 jobs
                </span>
              </div>
            </div>
            <em className="icon bg-purple-dim ni ni-activity-round"></em>
          </li>
        </ul>
      </ModalComponent>
    </>
  );
}
