import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  Toggle,
  ModalComponent,
  InputType,
  LocalizationFilterForm,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../components';
import { modalNotification } from '../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListIndustry(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Industry Update Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Industry',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'industry',
      title: 'Industry',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("industry")
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () => {
          editbannerFilterShow(true);
        },
      },
    ]);
  const pageData = [
    {
      id: 1,
      industry: 'A',
      status: 'Active',
    },
    {
      id: 2,
      industry: 'Automobiles & Auto Components',
      status: 'Inactive',
    },
    {
      id: 3,
      industry: 'Construction & Engineering',
      status: 'Active',
    },
    {
      id: 4,
      industry: 'Financial Services',
      status: 'Inactive',
    },
  ];
  const [bannerFilter, setbannerFilter] = useState(false);
  const bannerFilterShow = () => {
    setbannerFilter(true);
  };
  const bannerFilterHide = () => {
    setbannerFilter(false);
  };
  const [editbannerFilter, seteditbannerFilter] = useState(false);
  const editbannerFilterShow = () => {
    seteditbannerFilter(true);
  };
  const editbannerFilterHide = () => {
    seteditbannerFilter(false);
  };
  const industryFilterUpdate = () => {
    seteditbannerFilter(false);
    onConfirmAlert();
  };
  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
  ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Industry">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            popover={<LocalizationFilterForm />}
            btnText="Add Industry"
            onHandleShow={bannerFilterShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        showAction={false}
        isSearch={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <ModalComponent
        backdrop
        show={bannerFilter}
        onHandleCancel={bannerFilterHide}
        title="Add Industry"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                  Industry Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter industry name"
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => bannerFilterHide()}
                className="btn btn-primary me-2"
              >
                Add
              </Link>
              <Link
                href="#"
                className="btn btn-light me-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => bannerFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editbannerFilter}
        onHandleCancel={editbannerFilterHide}
        title="Edit Industry"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                  Industry Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter industry name"
                  defaultValue="Automobiles & Auto Components"
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => industryFilterUpdate()}
                className="btn btn-primary me-1 me-sm-2"
              >
                Update
              </Link>
              <Link
                href="#"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => editbannerFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}

export default ListIndustry;
