
import { CommonButton } from "../../components";






export const MarketingReviewTemplateTableColumn = ({viewTagsModalShow,viewModulesModalShow,viewMessageModalShow,approveModalShow,declineModalShow,reportModalShow,viewServicesModalShow}) => {
    return [
        {
            dataIndex: 'id',
            title: 'S.No.',
            className: 'w_70',
        },
        {
            dataIndex: 'dateOfSubmission',
            title: 'Date of submission',
        },
        {
            dataIndex: 'senderName',
            title: 'Sender Name',
            
        },
        {
            dataIndex: 'typeOfMessageSent',
            title: 'Type of Message Sent',
        },
        {
            dataIndex: 'audience',
            title: 'Audience',
        },
        {
            dataIndex: 'moduleServicesTags',
            title: 'Module/Services/Tags',
            render:(cell, row)=>{
              if(cell=== "viewTags"){ 
                return <> <CommonButton onClick= { () => viewTagsModalShow() } className="btn btn-primary btn-sm">View Tags</CommonButton></>
              }else{
                if(cell=== "viewModules"){
                  return <><CommonButton onClick= { () => viewModulesModalShow() } className="btn btn-primary btn-sm">View Modules</CommonButton></>
                }
                if(cell=== "viewServices"){
                  return <><CommonButton onClick= { () => viewServicesModalShow() } className="btn btn-primary btn-sm">View Services</CommonButton></>
                }
              }
            }
        },
        {
            dataIndex: 'message',
            title: 'Message',
            render:(cell, row)=>{
                return <> <CommonButton onClick= { () => viewMessageModalShow() } className="btn btn-primary btn-sm">View Message</CommonButton></>
            }
        },
        {
            dataIndex: 'approve',
            title: 'Approve',
            render:(cell, row)=>{
              if(cell=== "Approved"){ 
                return <> <span className="text-success">{cell}</span>  </>
              }else{
                if(cell=== "Approve"){
                  return <><CommonButton onClick= { () => approveModalShow() } className="btn btn-success btn-sm">Approve</CommonButton></>
                }
              }
            }
        },
        {
            dataIndex: 'decline',
            title: 'Decline',
            render:(cell, row)=>{
              if(cell=== " "){ 
                return <> -- </>
              }else if(cell=== "decline"){
                return <> <span className="text-danger">Declined</span> </>
              }
              else{
                if(cell=== "button"){
                  return <><CommonButton onClick= { () => declineModalShow() } className="btn btn-danger btn-sm">Decline</CommonButton></>
                }
              }
            }
        },
        {
            dataIndex: 'viewReport',
            title: 'View Report',
            render:(cell, row)=>{
              if(cell=== " "){ 
                return <> -- </>
              }else{
                if(cell=== "view"){
                  return <><CommonButton onClick= { reportModalShow } className="btn btn-primary btn-sm">View</CommonButton></>
                }
              }
            }
        },
        {
            dataIndex: 'approvedDeclinedDate',
            title: 'Approved/Declined Date',
        },
        
      ];
};
