import { DatePicker, Radio } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AntSelect,
  Breadcrumb,
  FormElementWrapper,
  InputType,
  ListingHeader,
  PageHeader,
  Tabs,
} from '../../../../components';

function EditProviderProfile(props) {
  const navigate = useNavigate();
  const [defaultKey, setDefaultKey] = useState('profile');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/provider',
      name: 'Provider',
    },
    {
      path: '#',
      name: 'Edit Provider animesh',
    },
  ];
  const countryData = [
    {
      id: '1',
      name: 'India',
    },
    {
      id: '2',
      name: 'United State',
    },
  ];
  const stateData = [
    {
      id: '1',
      name: 'Alabama',
    },
    {
      id: '2',
      name: 'Alaska',
    },
    {
      id: '3',
      name: 'American Samoa',
    },
  ];
  const cityData = [
    {
      id: '1',
      name: 'Adak',
    },
    {
      id: '2',
      name: 'Afognak',
    },
    {
      id: '3',
      name: 'Aguikchuk',
    },
  ];
  const scheduleJobData = [
    {
      id: '1',
      name: '5',
    },
    {
      id: '2',
      name: '10',
    },
    {
      id: '3',
      name: '15',
    },
    {
      id: '4',
      name: 'Other',
    },
  ];
  const emergencyJobData = [
    {
      id: '1',
      name: '5',
    },
    {
      id: '2',
      name: '10',
    },
    {
      id: '3',
      name: '15',
    },
    {
      id: '4',
      name: 'Other',
    },
  ];
  const accountData = [
    {
      id: '1',
      name: ' Good Guys Heating and Air Conditioning of Lafayette(1897973164) ',
    },
    {
      id: '2',
      name: ' Tekhne of Fort Worth, Texas(3134076415) ',
    },
    {
      id: '3',
      name: ' Good Guys Heating & Air Conditioning(3257228609) ',
    },
    {
      id: '4',
      name: ' Good Guys Heating & Air Conditioning(3404387068) ',
    },
  ];
  const businessTypeData = [
    {
      id: '1',
      name: ' Individual',
    },
    {
      id: '2',
      name: ' Company-Sole Proprietorship',
    },
    {
      id: '3',
      name: ' Company-Single Member LLC',
    },
  ];
  const googleCampaignData = [
    {
      id: 'Sales-Search-1',
      name: ' Sales-Search-1',
    },
    {
      id: 'GGHS App promotion-App-1',
      name: 'GGHS App promotion-App-1',
    },
    {
      id: 'GGHS App Download',
      name: 'GGHS App Download',
    },
  ]
  const backPage = () => {
    navigate('/admin/provider');
  };
  const [value, setValue] = useState("Percentage");
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const tabContent = [
    {
      name: 'Profile',
      key: 'profile',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter first name"
                        name="fName"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter last name"
                        name="lName"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Password</label>
                      <InputType
                        className="form-control"
                        placeholder="Enter password"
                        name="password"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Country <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="Country"
                        disabled={false}
                        variant="standard"
                        placeholder="Select country"
                        options={countryData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="State"
                        disabled={false}
                        variant="standard"
                        placeholder="Select state"
                        options={stateData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="City"
                        disabled={false}
                        variant="standard"
                        placeholder="Select city "
                        options={cityData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter address "
                        name="Address"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Zip Code <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter zip code  "
                        name="zipCode"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group groupnum">
                      <label className="form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                        <div className="input-group flex-nowrap">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              1
                            </span>
                          </div>
                          <InputType
                            className="form-control"
                            placeholder="Enter phone  "
                            name="phone"
                            disabled={false}
                            variant="standard"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Profile Picture</label>
                      <div className="form-control-wrap">
                        <div className="form-file">
                          <input
                            type="file"
                            className="form-file-input"
                            id="customFile"
                          />
                          <label
                            className="form-file-label"
                            htmlFor="customFile"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label w-100">
                        Gender  
                        </label>
                        <Radio.Group value={value} onChange={onChange}>
                          <Radio value={1}>Male</Radio>
                          <Radio value={2}>Female</Radio>
                        </Radio.Group>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Schedule Job Radius{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="ScheduleJobRadius"
                        disabled={false}
                        variant="standard"
                        placeholder="Select schedule job radius "
                        options={scheduleJobData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Emergency Job Radius{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="EmergencyJobRadius"
                        disabled={false}
                        variant="standard"
                        placeholder="Select emergency job radius "
                        options={emergencyJobData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label w-100">Fee  </label>
                        <Radio.Group value={value} onChange={onChange}>
                          <Radio value={ 'Percentage' }>Percentage</Radio>
                          <Radio value={ 'Hourly' }>Hourly (In Flat USD)</Radio>
                        </Radio.Group>
                    </div>
                  </div>
                  {value === 'Hourly' && (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Service Provider fees per Minute (In USD)
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Commission (Per Minute In USD) "
                        name="ServiceProviderFees"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  )}
                  {value === 'Percentage' && (
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Admin Commission fees (In percentage)
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Admin Commission fees (In percentage)"
                        name="ServiceProviderFees"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Google Ad Account</label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="GoogleAdAccount"
                        disabled={false}
                        variant="standard"
                        placeholder="Select google ad account "
                        options={accountData}
                      />
                    </div>
                  </div>
                  <FormElementWrapper
                md={6}
                label="Customer Tag "
                element={(formik) => (
                  <AntSelect
                        mode="multiple"
                        id="status"
                        extraClassName="form-control"
                        name="googleCampaignData"
                        disabled={false}
                        variant="standard"
                        placeholder="Select google campaign"
                        options={googleCampaignData}
                      />
                )}
              />
                  {/* <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Google Campaign</label>
                      <AntSelect
                        mode="multiple"
                        id="status"
                        extraClassName="form-control"
                        name="googleCampaignData"
                        disabled={false}
                        variant="standard"
                        placeholder="Select google campaign"
                        options={googleCampaignData}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary me-2">
                      Save
                    </Link>
                    <Link href="#" className="btn btn-light">
                      Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   name: 'Setup Company Profile',
    //   key: 'companyprofile',
    //   content: (
    //     <>
    //       <div className="card">
    //         <div className="card-inner">
    //           <form>
    //             <div className="row g-3">
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Company Name <span className="text-danger">*</span>
    //                   </label>
    //                   <InputType
    //                     className="form-control"
    //                     placeholder="Enter company name"
    //                     value=""
    //                     name="documentType"
    //                     disabled={false}
    //                     variant="standard"
    //                     type="text"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Date Of Birth <span className="text-danger">*</span>
    //                   </label>
    //                   <DatePicker
    //                     className="form-control"
    //                     placeholder="Enter Date"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Home Address <span className="text-danger">*</span>
    //                   </label>
    //                   <InputType
    //                     className="form-control"
    //                     placeholder="Enter home address"
    //                     value=""
    //                     name="documentType"
    //                     disabled={false}
    //                     variant="standard"
    //                     type="text"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Employer Id Number{' '}
    //                     <span className="text-danger">*</span>
    //                   </label>
    //                   <InputType
    //                     className="form-control"
    //                     placeholder="Enter employer id number"
    //                     value=""
    //                     name="documentType"
    //                     disabled={false}
    //                     variant="standard"
    //                     type="text"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Upload Document1 <span className="text-danger">*</span>
    //                   </label>
    //                   <div className="form-control-wrap">
    //                     <div className="form-file">
    //                       <input
    //                         type="file"
    //                         multiple=""
    //                         className="form-file-input"
    //                         id="customFile"
    //                       />
    //                       <label
    //                         className="form-file-label"
    //                         htmlFor="customFile"
    //                       >
    //                         Choose file
    //                       </label>
    //                     </div>
    //                   </div>
    //                   <div className="imgPreviewBox">
    //                     <ImageElement source="uploadImg.png" alt="img" />
    //                     <div className="imgPreviewBox_icon">
    //                       <Link href="#" className="btn btn-primary">
    //                         <em className="icon ni ni-cross"></em>
    //                       </Link>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="form-group ">
    //                   <label className="form-label">
    //                     Upload Document2 <span className="text-danger">*</span>
    //                   </label>
    //                   <div className="form-control-wrap">
    //                     <div className="form-file">
    //                       <input
    //                         type="file"
    //                         multiple=""
    //                         className="form-file-input"
    //                         id="customFile"
    //                       />
    //                       <label
    //                         className="form-file-label"
    //                         htmlFor="customFile"
    //                       >
    //                         Choose file
    //                       </label>
    //                     </div>
    //                   </div>
    //                   <div className="imgPreviewBox">
    //                     <ImageElement source="uploadImg.png" alt="img" />
    //                     <div className="imgPreviewBox_icon">
    //                       <Link href="#" className="btn btn-primary">
    //                         <em className="icon ni ni-cross"></em>
    //                       </Link>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-md-12 text-end">
    //                 <Link href="#" className="btn btn-primary">
    //                   Save Changes
    //                 </Link>
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      name: 'Setup Business Details',
      key: 'businessdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">Business Type</label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select business type "
                        options={businessTypeData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter first name"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter last name"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter email"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Job Title <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter job title "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Country <span className="text-danger">*</span>
                      </label>
                      <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="country"
                        disabled={false}
                        variant="standard"
                        placeholder="Select country"
                        options={countryData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Address Line 1 <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter address line1 "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Address Line 2 <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter address line 2 "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter state  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter city  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Zip Code <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter zip code  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter phone number  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-control-wrap">
                    <label className="form-label">
                      Last 4 Digit Of Social Security Number{' '}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group flex-nowrap">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">***-**-</span>
                      </div>
                      <InputType
                        className="form-control"
                        placeholder="Enter last 4 digit of social security number  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Upload Identity Document Front{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Upload identity document front  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="file"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Upload Identity Document Back{' '}
                        <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Upload identity document back  "
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="file"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Date Of Birth <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        className="form-control"
                        placeholder="Enter Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary">
                      Save Changes
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Setup Banking Details ',
      key: 'bankingdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Bank Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter bank name"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Bank Account Holder Name <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter bank account holder name"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Account Number <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter account number"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Bank Account Routing Number <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter bank account routing number"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Bank Location <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter bank location"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                      Email Address <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter email address"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary">
                      Save Changes
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Procedure Lessons Details',
      key: 'lessonsdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <div className="bq-note">
                <div className="bq-note-text">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Pending Required Procedure:</h5>
                  </div>
                  <ul className="list list-sm list-checked">
                    <li>
                      What is an HVAC System and How Does it Work? -77.7°C
                    </li>
                    <li>TEST Notifications SP Apps side</li>
                    <li>Title NOTIFICATION after complete Lesson</li>
                    <li>Test ARJ</li>
                    <li>Test-JITARJ</li>
                    <li>Title</li>
                    <li>Test Lesson mac safari</li>
                  </ul>
                </div>
                <div className="bq-note-text mt-3">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Completed</h5>
                  </div>
                  <ul className="list list-sm list-checked">
                    <li>No data found.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="wide-md mx-auto">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Provider animesh">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className="nk-block">
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          // onTabChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default EditProviderProfile;
