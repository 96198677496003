export const getCityService = () => {
  return [
    {
      id: 1,
      city: "Ain-ad-Dar",
      state: "Jidd Hafs",
      country: "Bahrain",
      status: "Active",
    },
    {
      id: 2,
      city: "Anaizah",
      state: "Al Qasim",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 3,
      city: "Aqur",
      state: "Sitrah",
      country: "	Bahrain",
      status: "Active",
    },
    {
      id: 4,
      city: "Arjah",
      state: "Ar Riyad",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 5,
      city: "Ashairah",
      state: "Ar Riyad",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 6,
      city: "Audah",
      state: "Ar Riyad",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 7,
      city: "Awainidh",
      state: "Ar Riyad",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 8,
      city: "Awamiyah",
      state: "Ar Riyad",
      country: "Saudi Arabia",
      status: "Active",
    },
  ];
};
