import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  AntTextArea,
  CheckBox,
  TextInput,
  CommonButton,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

function GMBPostAdd(props) {

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '/admin/google-gmb/post',
      name: 'Google Post List',
    },
    {
      path: '#',
      name: 'Create Post',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/google-gmb/post')
  }
  const [learnUrl, setLearnUrl] = useState(false) 
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Create  Post">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={12}
                label="Add Photo"
                element={(formik) => (
                  <Dragger>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                )}
              />
                <FormElementWrapper
                    md={12}
                    label="Write your post"
                    mandatory="true"
                    element={(formik) => (
                    <AntTextArea
                        name="description"
                        className="form-control"
                        placeholder="Enter post"
                    />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    element={(formik) => (
                    <CheckBox
                        name="agree"
                        element={[
                        'Want to add learn more URL?',
                        ]}
                        onChange={(e) => setLearnUrl(!learnUrl)}
                    />
                    )}
                />
                {
                    learnUrl &&
                    <FormElementWrapper
                        md={12}
                        label="Learn More URL"
                        mandatory="true"
                        element={(formik) => (
                        <TextInput
                            name="email"
                            inputType="text"
                            placeholder="Enter URL"
                            formik={formik}
                        />
                        )}
                    />
                }
                <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                    <>
                        <CommonButton
                        type="submit"
                        className="btn btn-primary me-2"
                        >
                        Publish
                        </CommonButton>
                        <CommonButton
                        type="submit"
                        className="btn btn-light"
                        >
                        Cancel
                        </CommonButton>
                    </>
                    )}
                />  
            </FormWrapper>  
                    
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default GMBPostAdd;
