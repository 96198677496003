import i18next from 'i18next';
import * as yup from 'yup';

export const initValues = () => {
  return {
    bankName: '',
    accountHolder: '',
    accountNumber: '',
    routingNumber: '',
    bankLocation: '',
    email: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    bankName: yup.string().required(i18next.t('validation.signup.bankName')),
    accountHolder: yup
      .string()
      .required(i18next.t('validation.signup.accountHolder')),
    accountNumber: yup
      .number()
      .required(i18next.t('validation.signup.accountNumber')),
    routingNumber: yup
      .number()
      .required(i18next.t('validation.signup.routingNumber')),
    bankLocation: yup
      .string()
      .required(i18next.t('validation.signup.bankLocation')),
    email: yup
      .string()
      .required(i18next.t('validation.login.email'))
      .email(i18next.t('validation.login.validEmail')),
  });
};
