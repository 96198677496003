import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  Toggle,
  TablePlus,
  InputFilter,
  SelectFilter,
  MultiSelectFilter,
  ModalComponent,
  CommonButton,
} from '../../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListQuoteSystem(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Quote Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Quote Management',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }    
  ]
  const getQuoteTypeData = () => {
    return [
      {
        name: '80% Gas Furnace',
        value: '80% Gas Furnace',
      },
      {
        name: 'Gas Eff - Rebate',
        value: 'Gas Eff - Rebate',
      },
      {
        name: '	Apple MAC',
        value: '	Apple MAC',
      },
    ];
  }
  const getBrandData = () => {
    return [
      {
        name: 'BRAND',
        value: 'BRAND',
      },
      {
        name: 'Heil',
        value: 'Heil',
      },
    ];
  }
  const getSystemCapacityData = () => {
    return [
      {
        name: '40-50k BTU',
        value: '40-50k BTU',
      },
      {
        name: '50-80k BTU',
        value: '50-80k BTU',
      },
    ];
  }
  const getSizeDescriptionData = () => {
    return [
      {
        name: '14" Wide',
        value: '14" Wide',
      },
      {
        name: '15" Wide',
        value: '15" Wide',
      },
    ];
  }
  const getDirectionalData = () => {
    return [
      {
        name: 'Upflow',
        value: 'Upflow',
      },
      {
        name: 'Multi & Single',
        value: 'Multi & Single',
      },
    ];
  }
  const getMinimumBlowerDriveData = () => {
    return [
      {
        name: '800 CFM',
        value: '800 CFM',
      },
      {
        name: '6 Ton & 5 ton',
        value: '6 Ton & 5 ton',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'companyName',
      title: '	Company Name',
      ...getColumnSearchProps("company name")
    },
    {
      dataIndex: 'industryType',
      title: 'Industry Type ',
      ...getColumnSearchProps("industry type")
    },
    {
      dataIndex: 'equipmentMaterial',
      title: 'Equipment, Material, IAQ',
      ...getColumnSearchProps("equipment, material,IAQ")
    },
    {
      dataIndex: 'quoteType',
      title: 'Quote Type',
      ...getColumnMultiSelectSearchProps("quote type", getQuoteTypeData())
    },
    {
      dataIndex: 'brand',
      title: 'Brand',
      ...getColumnMultiSelectSearchProps("brand", getBrandData())
    },
    {
      dataIndex: 'systemBTUCapacity',
      title: 'System BTU/Capacity',
      ...getColumnMultiSelectSearchProps("system BTU capacity", getSystemCapacityData())
    },
    {
      dataIndex: 'sizeDescription',
      title: 'Size Description',
      ...getColumnMultiSelectSearchProps("size description", getSizeDescriptionData())
    },
    {
      dataIndex: 'directional',
      title: '	Directional',
      ...getColumnMultiSelectSearchProps("directional", getDirectionalData())
    },
    {
      dataIndex: 'minimumBlowerDrive',
      title: 'Minimum Blower Drive',
      ...getColumnMultiSelectSearchProps("minimum blower drive", getMinimumBlowerDriveData())
    },
    {
      dataIndex: 'materialListType',
      title: 'Material List Type',
      ...getColumnSearchProps("material list type")
    },
    {
      dataIndex: 'materialName',
      title: 'Material Name',
      ...getColumnSearchProps("material name")
    },
    {
      dataIndex: 'materialAdditionalCost',
      title: 'Material Additional Cost to Customer',
      ...getColumnSearchProps("material additional cost")
    },
    {
      dataIndex: 'materialExpense',
      title: 'Material Expense',
      ...getColumnSearchProps("material expense")
    },
    {
      dataIndex: 'materialAutoFill',
      title: 'Material Auto Fill',
      ...getColumnSearchProps("material auto fill")
    },
    {
      dataIndex: 'optionStatus',
      title: 'Option Status',
      ...getColumnSearchProps("option status")
    },
    {
      dataIndex: 'gasEfficiency',
      title: 'Gas Efficiency',
      ...getColumnSearchProps("gas efficiency")
    },
    {
      dataIndex: 'oldAHRIReference',
      title: 'Old AHRI Reference Number',
      ...getColumnSearchProps("old AHRI reference number")
    },
    {
      dataIndex: 'ahriType',
      title: 'AHRI Type',
      ...getColumnSearchProps("AHRI type")
    },
    {
      dataIndex: 'seriesName',
      title: 'Series Name',
      ...getColumnSearchProps("series name")
    },
    {
      dataIndex: 'aCoolingCapacity',
      title: 'AHRI Certified Ratings - Cooling Capacity (A2) - Single or High Stage (95F),btuh',
      ...getColumnSearchProps("AHRI certified ratings - cooling capacity")
    },
    {
      dataIndex: 'aEER',
      title: 'AHRI Certified Ratings - EER (A2) - Single or High Stage (95F)',
      ...getColumnSearchProps("AHRI certified ratings - EER")
    },
    {
      dataIndex: 'aIEER',
      title: 'AHRI Certified Ratings - IEER',
      ...getColumnSearchProps("AHRI certified ratings - IEER")
    },
    {
      dataIndex: 'aSEER',
      title: 'AHRI Certified Ratings - SEER',
      ...getColumnSearchProps("AHRI certified ratings - SEER")
    },
    {
      dataIndex: 'systemPartWarranty',
      title: 'System Part Warranty',
      ...getColumnSearchProps("system part warranty")
    },
    {
      dataIndex: 'systemHeatExchanger',
      title: 'System Heat Exchanger Part Warranty',
      ...getColumnSearchProps("system heat exchanger part warranty")
    },
    {
      dataIndex: 'benefits',
      title: '	 Benefits',
      ...getColumnSearchProps("benefits")
    },
    {
      dataIndex: 'quoteTypeDescription',
      title: 'Quote Type Description',
      ...getColumnSearchProps("quote type description")
    },
    {
      dataIndex: 'miscRebate',
      title: 'Misc Rebate',
      ...getColumnSearchProps("misc rebate")
    },
    {
      dataIndex: 'availabilityStatus',
      title: 'Availability Status',
      render: () => <Toggle />,
      ...getColumnSelectSearchProps("availability status", getStatusData())
    },
    {
      dataIndex: 'Status',
      title: 'status',
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditQuote()},
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      companyName: '	Good Guys',
      industryType: 'HVAC',
      equipmentMaterial: 'Equipment',
      quoteType: '90+% Furnace',
      brand: 'Heil',
      systemBTUCapacity: '40-50k BTU',
      sizeDescription: '14" Wide',
      directional:'Upflow',
      minimumBlowerDrive:'800 CFM',
      materialListType:'90+%Furnace',
      materialName:'Media Filter 16x25',
      materialAdditionalCost:'4000.00',
      materialExpense:'800',
      materialAutoFill:'Yes',
      optionStatus:'Good',
      gasEfficiency:'95	',
      oldAHRIReference:'',
      ahriType:'SP-A (Single-Package Air-Conditioner, Air-Cooled)',
      seriesName: 'R410A PAC SPP',
      aCoolingCapacity:'23000',
      aEER:'12',
      aIEER:'',
      aSEER:'15',
      systemPartWarranty:'10',
      systemHeatExchanger:'20',
      benefits:'',
      quoteTypeDescription:'quote description',
      miscRebate:'400',
    },
    {
      id: 2,
      companyName: 'Tekhne',
      industryType: 'HVAC',
      equipmentMaterial: 'Equipment',
      quoteType: '90+% Furnace',
      brand: 'Heil1 & TR',
      systemBTUCapacity: '60000 & 80000',
      sizeDescription: '15" Wide',
      directional:'Upflow',
      minimumBlowerDrive:'800 CFM',
      materialListType:'90+%Furnace',
      materialName:'Media Filter 16x25',
      materialAdditionalCost:'4000.00',
      materialExpense:'800',
      materialAutoFill:'Yes',
      optionStatus:'Good',
      gasEfficiency:'95	',
      oldAHRIReference:'',
      ahriType:'SP-A (Single-Package Air-Conditioner, Air-Cooled)',
      seriesName: 'R410A PAC SPP',
      aCoolingCapacity:'23000',
      aEER:'12',
      aIEER:'',
      aSEER:'15',
      systemPartWarranty:'10',
      systemHeatExchanger:'20',
      benefits:'',
      quoteTypeDescription:'quote description',
      miscRebate:'400',
    },
    {
      id: 3,
      companyName: '	Good Guys',
      industryType: 'HVAC',
      equipmentMaterial: 'Equipment',
      quoteType: '90+% Furnace',
      brand: 'Heil',
      systemBTUCapacity: '40-50k BTU',
      sizeDescription: '14" Wide',
      directional:'Upflow',
      minimumBlowerDrive:'800 CFM',
      materialListType:'90+%Furnace',
      materialName:'Media Filter 16x25',
      materialAdditionalCost:'4000.00',
      materialExpense:'800',
      materialAutoFill:'Yes',
      optionStatus:'Good',
      gasEfficiency:'95	',
      oldAHRIReference:'',
      ahriType:'SP-A (Single-Package Air-Conditioner, Air-Cooled)',
      seriesName: 'R410A PAC SPP',
      aCoolingCapacity:'23000',
      aEER:'12',
      aIEER:'',
      aSEER:'15',
      systemPartWarranty:'10',
      systemHeatExchanger:'20',
      benefits:'',
      quoteTypeDescription:'quote description',
      miscRebate:'400',
    },
  ];

  const navigate = useNavigate();
  const AddQuote = () =>{
    navigate('/admin/quote-management/add')
  }
  const EditQuote = () =>{
    navigate('/admin/quote-management/edit')
  }
  const viewImages = () => {
    navigate('/admin/quote-management/view-image')
  }
  const [lineItemsModalRecord, setLineItemsModalRecord] = useState(false);
  const lineItemsModalRecordHide = () =>{
    setLineItemsModalRecord(false)
  }
  const lineItemsModalRecordShow = () =>{
    setLineItemsModalRecord(true);
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Quote Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport', 'fileImport', 'extraButton']}
            btnText="Add Quote"
            onHandleShow={AddQuote}
            // popover={<QuoteManagementFilterForm />}
            extraBtnText="View All Images"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={viewImages}
            onFileHandleShow={ lineItemsModalRecordShow }
          />
        </div>
      </div>
      <div className="tableScrollHide">
        <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={adminData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          selectRow={true}
          scrollTopPosition
          isSearch={false}
          showAction={false}
          statusAction
          statusActionOption={statusActionOption}
        />
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Quote "
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={lineItemsModalRecord}
        onHandleCancel={lineItemsModalRecordHide}
        title="Import Records"
        size="sm"
      >
        <ul className='list list-sm list-checked'>
          <li>Total Records :  <b>565</b></li>
          <li>Record Inserted :  <b>0</b></li>
          <li>Record Updated :  <b>5</b></li>
        </ul>
        <div className='text-end'>
          <CommonButton className="btn btn-primary" onClick ={ () => lineItemsModalRecordHide() }>Ok</CommonButton>
        </div>
      </ModalComponent>
    </>
  );
}

export default ListQuoteSystem;
