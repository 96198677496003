import React, {useState} from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  PaymentReportFilterForm,
  TablePlus,
  SweetAlert,
  CommonButton,
  FormWrapper,
  FormElementWrapper,
  Select,
  DatePicker,
  ModalComponent,
} from "../../../components";
import {
  Columns,
   EquipmentOrderServices,
} from "../../../services/index.service";
import { modalNotification } from "../../../utils";

function ListEquipmentOrder(props) {
  const EquipmentOrderData = EquipmentOrderServices();
  const columns = Columns.EquipmentOrderTableColumn({saveUpdates,cancelUpdates, equipmentModalShow, materialListModalShow});

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Equipment Order",
    },
  ];
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Data Updated Successfully", })
        return true;
    };
    const [isAlertVisible1, setIsAlertVisible1] = useState(false);
    const onConfirmAlert1 = () => {
        setIsAlertVisible1(false);
        return true;
    };
    function saveUpdates() {
        setIsAlertVisible(true);
    }
    function cancelUpdates() {
        setIsAlertVisible1(true);
    }
    const filterData = [
      {
        name: 'Today',
        value: 'Today',
      },
      {
        name: 'Yesterday',
        value: 'Yesterday',
      },
      {
        name: 'Current Week',
        value: 'Current Week',
      },
      {
        name: 'Previous Week',
        value: 'Previous Week',
      },
      {
        name: 'Current Month',
        value: 'Current Month',
      },
      {
        name: 'Previous Month',
        value: 'Previous Month',
      },
      {
        name: 'Current Year',
        value: 'Current Year',
      },
      {
        name: 'Previous Year',
        value: 'Previous Year',
      },
    ]
    const[globalFilter, setGlobalFilter ] = useState(false);
    const BackBtnFilter = () => {
      setGlobalFilter(false)
    }
    const OpenBtnFilter = () => {
      setGlobalFilter(true)
    }
    if (globalFilter) {
      document.querySelector('body').classList.add('toggle-shown');
    } else {
      document.querySelector('body').classList.remove('toggle-shown');
    }
    const [equipmentModal, setEquipmentModal] = useState(false);
    const equipmentModalHide = () =>{
      setEquipmentModal(false)
    }
    function equipmentModalShow() {
      setEquipmentModal(true)
    }
    const [materialListModal ,setMaterialListModal ] = useState(false);
    const materialListModalHide = () =>{
      setMaterialListModal(false)
    }
    function materialListModalShow() {
      setMaterialListModal(true)
    }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Equipment Order">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[""]}
            popover={<></>}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tableScrollHide">
        <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={EquipmentOrderData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          isSearch={false}
          showAction={false}
          scrollTopPosition
        />
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to save changes ?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <SweetAlert
        title="Are you sure"
        text=" You want to discard changes ?"
        show={isAlertVisible1}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible1}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert1}
      />
      <ModalComponent
        backdrop
        show={equipmentModal}
        onHandleCancel={equipmentModalHide}
        title="Equipment"
      >
        <div className="modalTableContent">
          <div className="card shadow-none">
            <h6 className="title mb-2">90+% Furnace</h6>
            <div className='table-responsive'>
              <Table bordered size="sm">
                <tbody>
                  <tr>
                    <th>Quote Type</th>
                    <td>90+% Furnace</td>
                  </tr>
                  <tr>
                    <th>Brand Name</th>
                    <td>	Heil</td>
                  </tr>
                  <tr>
                    <th>Series Name</th>
                    <td>	R410A PAC SPP</td>
                  </tr>
                  <tr>
                    <th>Outdoor Unit - Model Number</th>
                    <td>PAR524***K***A*</td>
                  </tr>
                  <tr>
                    <th>System BTU/Capacity</th>
                    <td>40-50k BTU</td>
                  </tr>
                  <tr>
                    <th>System Physical Size</th>
                    <td>14" Wide</td>
                  </tr>
                  <tr>
                    <th>Directional</th>
                    <td>	Upflow</td>
                  </tr>
                  <tr>
                    <th>Minimum Blower Drive</th>
                    <td>	800 CFM</td>
                  </tr>
                  <tr>
                    <th>Efficiency</th>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>Parts Warranty</th>
                    <td>	10</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div> 
          <div className="card shadow-none">
            <h6 className="title mb-2">90+% Furnace</h6>
            <div className='table-responsive'>
              <Table bordered size="sm">
                <tbody>
                  <tr>
                    <th>Quote Type</th>
                    <td>90+% Furnace</td>
                  </tr>
                  <tr>
                    <th>Brand Name</th>
                    <td>	Heil</td>
                  </tr>
                  <tr>
                    <th>Series Name</th>
                    <td>R410A PAC SPP</td>
                  </tr>
                  <tr>
                    <th>Outdoor Unit - Model Number</th>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>System BTU/Capacity</th>
                    <td>40-50k BTU</td>
                  </tr>
                  <tr>
                    <th>System Physical Size</th>
                    <td>14" Wide</td>
                  </tr>
                  <tr>
                    <th>Directional</th>
                    <td>	Upflow</td>
                  </tr>
                  <tr>
                    <th>Minimum Blower Drive</th>
                    <td>	800 CFM</td>
                  </tr>
                  <tr>
                    <th>Efficiency</th>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>Parts Warranty</th>
                    <td>	10</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="text-end mt-2">
            <CommonButton className="btn btn-secondary" onClick={ () => equipmentModalHide() }>Close</CommonButton>
        </div>
      </ModalComponent> 
      <ModalComponent
        backdrop
        show={materialListModal}
        onHandleCancel={materialListModalHide}
        title="Material List"
      >
        <div className="modalTableContent">
          <div className="card shadow-none">
            <h6 className="title mb-2">Quote Type: High-Efficiency Gas Furnace & Air Conditioner</h6>
            <div className='table-responsive'>
                <Table bordered size="sm">
                <tbody>
                    <tr>
                    <th>21" / 4 Ton Evap Coil</th>
                    <td>0.00</td>
                    </tr>
                    <tr>
                    <th>3 Inch to 2 Inch Reducer PVC</th>
                    <td>	0.00</td>
                    </tr>
                    <tr>
                    <th>8 OZ CLEAR PRIMER - NSF LISTED - PVC & CPVC</th>
                    <td>	0.00</td>
                    </tr>
                    <tr>
                    <th>Condenser Pad</th>
                    <td>100.00</td>
                    </tr>
                    <tr>
                    <th>Disconnect</th>
                    <td>75.00</td>
                    </tr>
                    <tr>
                    <th>Electrical Whip</th>
                    <td>75.00</td>
                    </tr>
                </tbody>
                </Table>
            </div>
          </div>
        </div>
        <div className="text-end mt-2">
            <CommonButton className="btn btn-secondary" onClick={ () => materialListModalHide() }>Close</CommonButton>
        </div>
      </ModalComponent>  
    </>
  );
}

export default ListEquipmentOrder;
