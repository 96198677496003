import React, {useState} from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  SweetAlert,
  ActionWrapper,
} from "../../../../components";
import {
  Columns,
   promoCodeService,
} from "../../../../services/index.service";
import { Link, useNavigate } from "react-router-dom";
import { modalNotification } from "../../../../utils";

function StoreListing(props) {
  const EquipmentOrderData = promoCodeService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Promo Code",
    },
  ];

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.promoCodeTableColumn({ handleJobTypeChange, 
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{ EditPromoCode()},
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]), 
  });
  const navigate = useNavigate()
  const AddPromoCode = () => {
    navigate("/admin/promo-code/add");
  }
  const EditPromoCode = () => {
    navigate("/admin/promo-code/edit");
  }
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Promo Code Deleted Successfully", })
        return true;
    };

    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Active'
      },
      {
      id: 3,
      name: 'Make Inactive'
      },
      {
      id: 4,
      name: 'Make Delete'
      }
    ]  

    function handleJobTypeChange(value){
      if(value === 'Active'){
        modalNotification({ type: "success", message: "Promo Code Active Successfully", })
      }
    }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Promo Code">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport',]}
            btnText="Add Promo Code"
            onHandleShow={AddPromoCode}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Coupon module will list all coupons on this page.</li>
                <li>Administrator can Activate / Deactivate / Delete any coupon.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to delete this promo code"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
    </>
  );
}

export default StoreListing;
