export const GoogleConnectGMBServices = () => {
    return [
      {
        id: 1,
        business: "Tékhnē Heating & Air Conditioning",
        totalReviews: "View(11)",
        googleRating: '5 Stars',
        reviewsToRespondTo: "NA",
        status: "Verified"
      },
      {
        id: 2,
        business: "Good Guys Heating & Air Conditioning, LLC",
        totalReviews: "View(15)",
        googleRating: '4.9 Stars',
        reviewsToRespondTo: "NA",
        status: "Verified"
      },
      {
        id: 3,
        business: "Good Guys Heating & Air Conditioning",
        totalReviews: "View(20)",
        googleRating: '5 Stars',
        reviewsToRespondTo: "NA",
        status: "Verified"
      },
      {
        id: 4,
        business: "Tékhnē Heating & Air Conditioning",
        totalReviews: "View(113",
        googleRating: '5 Stars',
        reviewsToRespondTo: "NA",
        status: "Verified"
      },
    ];
  };
  