import { createSlice } from '@reduxjs/toolkit';
// import routesMap from "../../routeControl";
import {
  removeLocalStorageToken,
  removeSessionStorageToken,
} from '../../../utils';
import logger from '../../../utils/logger';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: {},
    sideBarKey: '0',
  },
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state) => {
      return (state = {
        ...state,
        userData: {},
      });
    },
    updateUserDataAction: (state, action) => {
      return (state = {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      });
    },
    sideBarAction: (state, action) => {
      return (state = {
        ...state,
        sideBarKey: action.payload,
      });
    },
  },
});

export const {
  loginAction,
  updateUserDataAction,
  logoutAction,
  sideBarAction,
} = authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = (remember, navigate) => async (dispatch) => {
  try {
    if (!remember) {
      removeSessionStorageToken();
    }
    removeLocalStorageToken();
    dispatch(logoutAction());
    // navigate(routesMap.LOGIN.path);
  } catch (error) {
    logger(error);
  }
};

export const updateUserData = (data) => async (dispatch) => {
  try {
    dispatch(updateUserDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateSidebarKey = (data) => async (dispatch) => {
  try {
    dispatch(sideBarAction(data));
  } catch (error) {
    logger(error);
  }
};

export const selectUserData = (state) => state.auth.userData;
export const getSidebarKey = (state) => state.auth.sideBarKey;

export default authSlice.reducer;
