export const getStateService = () => {
  return [
    {
      id: 1,
      state: "Jidd Hafs",
      statecode: "04",
      country: "Bahrain",
      status: "Active",
    },
    {
      id: 2,
      state: "Al Qasim",
      statecode: "01",
      country: "Saudi Arabia",
      status: "Inactive",
    },
    {
      id: 3,
      state: "Dubai",
      statecode: "03",
      country: "	Bahrain",
      status: "Active",
    },
    {
      id: 4,
      state: "Ras Al Khaimah",
      statecode: "05",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 5,
      state: "	Umm Al Quwain",
      statecode: "07",
      country: "Saudi Arabia",
      status: "Active",
    },
    {
      id: 6,
      state: "Sharjah",
      statecode: "06",
      country: "United Arab Emirates",
      status: "Inactive",
    },
    {
      id: 7,
      state: "Ajman",
      statecode: "02",
      country: "United Arab Emirates",
      status: "Active",
    },
    {
      id: 8,
      state: "Jidd Hafs",
      statecode: "01",
      country: "Saudi Arabia",
      status: "Active",
    },
  ];
};
