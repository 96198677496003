import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  CommonButton,
  TablePlus,
} from '../../../../components';

function ReturnAmountDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/payment-report',
      name: 'Payment Report',
    },
    {
      path: '#',
      name: 'Return Amount Details',
    },
  ];
  const columns = [
    {
       dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
       dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
    },
    {
       dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
    },
    {
       dataIndex: 'serviceType',
      title: 'Service Type',
      sorter: true,
    },
    {
       dataIndex: 'servicePrice',
      title: 'Service Price',
      sorter: true,
    },
    {
       dataIndex: 'name',
      title: 'Name',
      sorter: true,
    },
    {
       dataIndex: 'totalFees',
      title: 'Total Fees',
      sorter: true,
    },
    {
       dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
      sorter: true,
    },
  ];
  const adminData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      name: "trgj86433",
      totalFees: "$ 400.00",
      paymentTransferStatus: "Pending",
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Return Amount Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/payment-report')}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
      <div className='card'>
        <div className='card-inner pt-0'>
          <div className="text-end">
            <CommonButton className="btn btn-primary">Add </CommonButton>
            <CommonButton className="btn btn-success ms-2">Submit</CommonButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReturnAmountDetails;
