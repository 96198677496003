import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  TablePlus,
} from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns, orderManagementService,
} from "../../../../services/index.service";
import { modalNotification } from "../../../../utils";


function OrderManagementList(props) {
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Order Management",
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Order Deleted Successfully", })
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }
  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
      id: 4,
      name: 'Make Delete'
    }
  ]

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.orderManagementTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "View",
          icon: "icon ni ni-eye",
          handler: () =>{navigate('/admin/order-management/invoice');},
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler: () => {
            setIsAlertVisible(true);
            document.body.click()
          }
        },
      ]),
  });

  const orderManagementData = orderManagementService();
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Order Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          {/* <ListingHeader
            btnArray={["csvExport", 'csvImport']}
          /> */}
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={orderManagementData}
        tableColumns={columns}
        showAction={false}
        isSearch={true}
        // tableLoader={isLoading}
        setSizePerPage=""
      />
      <SweetAlert
        title="Are you sure"
        text=" you want to delete this Order"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        onConfirmAlert={onConfirmAlert}
      />
    </>
  );
}

export default OrderManagementList;
