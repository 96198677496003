export const VariantManagementService = () => {
    return [
      {
        id: 1,
        variantName: "Size",
        attributeName: 'Small, Medium, Large'
      },
      {
        id: 2,
        variantName: "Size",
        attributeName: 'Medium'
      },
      {
        id: 3,
        variantName: "Size",
        attributeName: 'Large'
      },
      {
        id: 4,
        variantName: "Color",
        attributeName: 'Red'
      },
      {
        id: 3,
        variantName: "Company Name",
        attributeName: 'XYZ'
      },
    ];
  };
  