export const smsTemplatesService = () => {
    return [
      {
        id: 1,
        smsPurpose: "When admin book a job on behalf of customer",
        smsBody: "Good Guys #PARENT_NAME# - #SERVICE_NAME# - appointment confirmed for #BOOKING_DATE#. Your service provider #PROVIDER_NAME# #CUSTOMER_NAME# #PROVIDER_NAME# #PROVIDER_PHONE_NUMBER# #SERVICE_NAME# #PARENT_NAME# #JOB_NO# #BOOKING_DATE# #STATUS#",
      },
      {
        id: 2,
        smsPurpose: "When admin book a job on behalf of customer",
        smsBody: "#CUSTOMER_NAME# has requested #SERVICE_NAME# - Claim this job. #PROVIDER_NAME# #CUSTOMER_NAME# #PROVIDER_NAME# #PROVIDER_PHONE_NUMBER# #SERVICE_NAME# #PARENT_NAME# #JOB_NO# #BOOKING_DATE# #STATUS#",
      },
      {
        id: 3,
        smsPurpose: "To notify User upon job request accepted by Provider",
        smsBody: "Provider #PROVIDER_NAME# has accepted your job request for job# #BOOKING_NUMBER#. You may call tech directly at #PROVIDER_PHONE_NUMBER#",
      },
      {
        id: 4,
        smsPurpose: "When booking is cancelled by user",
        smsBody: "#CUSTOMER_NAME# has cancelled the service request #JOB_NO# - #SERVICE_NAME# - #CANCEL_REASON#. @GGHS ADMIN",
      },
    ];
  };
  