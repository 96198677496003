
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea
} from "../../../../../../components";

function AddBank(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/financing-bank')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/financing-bank",
      name: "Financing Bank",
    },
    {
      path: "#",
      name: "Add Financing Bank",
    },
  ];
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Financing Bank">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              md={12}
              label="Bank Logo"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="BankLogo "
                  placeholder="Enter bank logo"
                  formik={formik}
                  type="file"
                />
              )}
              imgPreviewBox={true}
            />
            <FormElementWrapper
              md={6}
              label="Bank Name  "
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="BankName "
                  placeholder="Enter bank name "
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Bank URL"
              mandatory={true}
              element={(formik) => (
                <TextInput
                  name="BankURL"
                  placeholder="Enter bank URL"
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label="Description  "
              mandatory={true}
              element={(formik) => (
                <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Enter description"
                  />
              )}
            />
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Add
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
        </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default AddBank;
