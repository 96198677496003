

import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  ListingHeader,
  PageHeader,
  ImageElement
} from "../../../../components";

function DocumentsView(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/store')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/store",
      name: "Promo Code Good Guys Affiliate",
    },
    {
      path: "#",
      name: "Documents of Test Company",
    },
  ];
  return (
    <div className="">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Documents of Test Company">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-fmg-quick-list nk-block">
              <div className="nk-files nk-files-view-grid documentsBoxSec">
                <div className="nk-files-list">
                  <div className="nk-file-item nk-file p-0">
                    <div className="nk-file-info">
                      <div className="nk-file-title">
                        <div className="nk-file-name my-3 nk-file-name my-3 mx-1">
                          <h6 className="nk-file-name-text">
                            Driver License
                          </h6>
                        </div>
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <ImageElement source="no-data-found.svg" alt="img" />
                          </span>
                          <p className="mt-2">Driver License not found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-file-item nk-file p-0">
                    <div className="nk-file-info">
                      <div className="nk-file-title">
                        <div className="nk-file-name my-3 nk-file-name my-3 mx-1">
                          <h6 className="nk-file-name-text">
                            Driver License
                          </h6>
                        </div>
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type nk-file-icon-type-showImage p-0">
                            <ImageElement source="uploadImg.png" alt="img" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
  );
}

export default DocumentsView;
