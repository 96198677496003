export const QuoteManagementEstimatesServices = () => {
    return [
        {
            id: 1,
            location: "Vijay Nagar, Indore",
            jobDate: "Wed, December 28, 2022 05:00 AM",
            provider: "Randy NCA1",
            user: 'jorge neon',
            jobAmount: "$ 5,040.00	",
            type: "Schedule Free Old Quote (Free - Scheduled)",
            jobStatus: "Open",
            sendQuote: "send",
          },
          {
            id: 2,
            location: "Vijay Nagar, Indore",
            jobDate: "Wed, December 28, 2022 05:00 AM",
            provider: "Randy NCA1",
            user: "Levis NCACUS",
            jobAmount: "$ 5,040.00	",
            type: "Schedule Free Old Quote (Free - Scheduled)",
            jobStatus: "Accepted",
            sendQuote: "",
          },
          {
            id: 3,
            bookingNo: "jorge neon",
            location: "Vijay Nagar, Indore",
            jobDate: "Wed, December 28, 2022 05:00 AM",
            provider: "Rone visli",
            user: "Levis NCACUS",
            jobAmount: "$ 5,040.00	",
            type: "Schedule Free Old Quote (Free - Scheduled)",
            jobStatus: "Accepted",
            sendQuote: "",
          },
          {
            id: 4,
            bookingNo: "jorge neon",
            location: "Vijay Nagar, Indore",
            jobDate: "Wed, December 28, 2022 05:00 AM",
            provider: "Randy NCA1",
            user: "Levis NCACUS",
            jobAmount: "$ 5,040.00	",
            type: "Schedule Free Old Quote (Free - Scheduled)",
            jobStatus: "Accepted",
            sendQuote: "",
          },
    ];
  };
  