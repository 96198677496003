import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FireAlert, removeLocalStorageToken, textFormatter } from '../../utils';
import { Notification, HeaderProfileMenu } from '../index.feature';
import { getLoginRoute } from '../../routes/index.routes';
import {
  ModalComponent,
  Password as TextPassword,
  ModalWrapper,
  ChangePasswordForm,
  CommonButton,
  ImageElement,
  TextInput,
} from '../../components';
import { Input, Tooltip } from 'antd';

function AdminHeader({ menuToggle }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.userData);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const onLogout = async (e) => {
    try {
      e.preventDefault();
      const result = await FireAlert({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ee0ac',
        cancelButtonColor: '#e85347',
        confirmButtonText: 'Yes, Logout!',
      });
      if (result?.isConfirmed) {
        removeLocalStorageToken();
        navigate(getLoginRoute('admin'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changePasswordModal = () => {
    setShowChangePassword(true);
  };
  const closeChangePasswordModal = () => {
    setShowChangePassword(false);
  };
  const [getEmbedCode, setgetEmbedCode] = useState(false);
  const getEmbedCodeShow = () => {
    setgetEmbedCode(true);
  };
  const getEmbedCodeHide = () => {
    setgetEmbedCode(false);
  };
  return (
    <>
      <ModalWrapper
        show={showChangePassword}
        onCancel={closeChangePasswordModal}
        content={<ChangePasswordForm onCancel={closeChangePasswordModal} />}
        closeButton={true}
        title="Change Password"
        extraTitleClassName="justify-content-center"
      />
      <div className="nk-header nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <Link onClick={() => menuToggle()} to='#' className="nk-nav-toggle nk-quick-nav-icon"><em
                          className="icon ni ni-menu"/></Link>
            </div>
            <div className='nk-header-news d-none d-sm-block medicalStatus'>
              <ul className='list-unstyled d-flex align-items-center medicalStatus_list'>
                <li>Main Administrator</li>
                <li>
                  <Link to="/admin/chat/nodata-chat">
                    <Tooltip title="Positive: 124" placement="bottom">
                      <ImageElement
                        source="positive.png"
                        className="img-fluid medicalStatus_img position-relative"
                        alt="positive"
                      />
                      <span className='badge badge-circle d-flex justify-content-center align-items-center bg-success medicalStatus_count'>9+</span>
                    </Tooltip>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/chat/nodata-chat">
                    <Tooltip title="Medical: 4" placement="bottom">
                      <ImageElement
                        source="medical.png"
                        className="img-fluid medicalStatus_img position-relative"
                        alt="medical"
                      />
                      <span className='badge badge-circle d-flex justify-content-center align-items-center bg-primary medicalStatus_count'>4</span>
                    </Tooltip>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/chat/nodata-chat">
                    <Tooltip title="Struggling: 1" placement="bottom">
                      <ImageElement
                        source="struggle.png"
                        className="img-fluid medicalStatus_img position-relative"
                        alt="struggle"
                      />
                      <span className='badge badge-circle d-flex justify-content-center align-items-center bg-secondary medicalStatus_count'>1</span>
                    </Tooltip>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/chat/nodata-chat">
                    <Tooltip title="Help: 1" placement="bottom">
                      <ImageElement
                        source="help.png"
                        className="img-fluid medicalStatus_img position-relative"
                        alt="help"
                      />
                      <span className='badge badge-circle d-flex justify-content-center align-items-center bg-danger medicalStatus_count'>1</span>
                    </Tooltip>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/chat/nodata-chat">
                    <Tooltip title="Vacation: 3" placement="bottom">
                      <ImageElement
                        source="vacation.png"
                        className="img-fluid medicalStatus_img position-relative"
                        alt="vacation"
                      />
                      <span className='badge badge-circle d-flex justify-content-center align-items-center bg-info medicalStatus_count'>3</span>
                    </Tooltip>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <Dropdown as="li" className="medicalStatus d-block d-sm-none">
                  <Dropdown.Toggle
                    as="a"
                    className="nk-quick-nav-icon"
                  >
                    <em class="icon ni ni-linux-server"></em>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="dropdown-menu-md">
                    <div className=''>
                        <ul className='list-unstyled medicalStatus_dropdown'>
                          <Link to="/admin/chat/nodata-chat">
                              <li className='d-flex align-items-center'>
                                  <ImageElement
                                    source="positive.png"
                                    className="img-fluid medicalStatus_img position-relative"
                                    alt="positive"
                                  />
                                  <span>125</span>
                                  <h6>Positive</h6>
                              </li>
                            </Link>
                            <Link to="/admin/chat/nodata-chat">
                              <li className='d-flex align-items-center'>
                                  <ImageElement
                                    source="medical.png"
                                    className="img-fluid medicalStatus_img position-relative"
                                    alt="medical"
                                  />
                                  <span>50</span>
                                  <h6>Medical</h6>
                              </li>
                            </Link>
                            <Link to="/admin/chat/nodata-chat">
                              <li className='d-flex align-items-center'>
                                  <ImageElement
                                    source="struggle.png"
                                    className="img-fluid medicalStatus_img position-relative"
                                    alt="struggle"
                                  />
                                  <span>10</span>
                                  <h6>Struggle</h6>
                              </li>
                            </Link>
                            <Link to="/admin/chat/nodata-chat">
                              <li className='d-flex align-items-center'>
                                  <ImageElement
                                    source="help.png"
                                    className="img-fluid medicalStatus_img position-relative"
                                    alt="help"
                                  />
                                  <span>5</span>
                                  <h6>Help</h6>
                              </li>
                            </Link>
                            <Link to="/admin/chat/nodata-chat">
                              <li className='d-flex align-items-center'>
                                  <ImageElement
                                    source="vacation.png"
                                    className="img-fluid medicalStatus_img position-relative"
                                    alt="vacation"
                                  />
                                  <span>3</span>
                                  <h6>Vacation</h6>
                              </li>
                            </Link>
                        </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as="li" className="d-none">
                  <Dropdown.Toggle
                    as="a"
                    className="nk-quick-nav-icon"
                  >
                    <em class="icon ni ni-cmd"></em>
                  </Dropdown.Toggle>
                  {/* <Dropdown.Toggle
                    as="a"
                    className="btn btn-primary"
                  >
                    Company Code
                  </Dropdown.Toggle> */}
                  <Dropdown.Menu align="end" className="dropdown-menu-lg">
                    <div className='dropdown-head'>
                      <div className='inputCopyBtn'>
                        <Input.Group compact>
                          <div className='d-flex'>
                            <TextInput
                              name="companycode"
                              placeholder="copy company code"
                              defaultValue="5BaVQ"
                              extraClassName="inputCopyBtn--code"
                            />
                            <CommonButton className="btn btn-primary flex-shrink-0">Copy company code</CommonButton>
                          </div>
                        </Input.Group>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as="li" className="d-none">
                  <Dropdown.Toggle
                    as="a"
                    className="nk-quick-nav-icon"
                  >
                    <em class="icon ni ni-unlink"></em>
                  </Dropdown.Toggle>
                  {/* <Dropdown.Toggle
                    as="a"
                    className="btn btn-primary"
                  >
                    Copy Link
                  </Dropdown.Toggle> */}
                  <Dropdown.Menu align="end" className="dropdown-menu-lg">
                    <div className='dropdown-head'>
                      <div className='inputCopyBtn'>
                        <Input.Group compact>
                          <div className='d-flex'>
                            <TextInput
                              name="companycode"
                              placeholder="copy company code"
                              defaultValue="http://3.131.211.25/customer/index.php?code=5BaVQ"
                              extraClassName="inputCopyBtn--link"
                            />
                            <CommonButton className="btn btn-primary flex-shrink-0">Copy link</CommonButton>
                          </div>
                        </Input.Group>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <li className='d-none'>
                  <div className='inputCopyBtn'>
                    <Input.Group compact>
                      <div className='d-flex'>
                        <TextInput
                          name="companycode"
                          placeholder="copy company code"
                          defaultValue="5BaVQ"
                          extraClassName="inputCopyBtn--code"
                        />
                        <CommonButton className="btn btn-primary flex-shrink-0">Copy company code</CommonButton>
                      </div>
                    </Input.Group>
                  </div>
                </li>
                <li className='d-none'>
                  <div className='inputCopyBtn'>
                  <Input.Group compact>
                    <div className='d-flex'>
                      <TextInput
                        name="companycode"
                        placeholder="copy company code"
                        defaultValue="http://3.131.211.25/customer/index.php?code=5BaVQ"
                        extraClassName="inputCopyBtn--link"
                      />
                      <CommonButton className="btn btn-primary flex-shrink-0">Copy link</CommonButton>
                    </div>
                  </Input.Group>
                  </div>
                </li>
                <li className='d-none d-md-block'><CommonButton className="btn btn-primary" onClick= { getEmbedCodeShow }>Get Embed Code</CommonButton></li>
                <li className='d-block d-md-none'><Link to="#" className="nk-quick-nav-icon"  onClick= { getEmbedCodeShow }><em class="icon ni ni-file-code"></em></Link></li>
                {/* <Dropdown as="li" className="notification-dropdown">
                  <Dropdown.Toggle
                    as="a"
                    className="nk-quick-nav-icon btn-icon btn-icon-break"
                  >
                    <div className="badge badge-circle align-items-center bg-primary">
                      5
                    </div>
                    <em className="ni ni-bell" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="dropdown-menu-xl">
                    <Notification />
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <Dropdown as="li" className="user-dropdown">
                  <Dropdown.Toggle as="a" className="me-n1" id="dropdown-basic">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt" />
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-status user-status-unverified">
                          Super Admin
                        </div>
                        <div className="user-name dropdown-indicator">Abu Bin Ishityak</div>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="dropdown-menu-md">
                    <HeaderProfileMenu
                      onLogout={onLogout}
                      data={user}
                      onPasswordChange={changePasswordModal}
                    />
                  </Dropdown.Menu>
                </Dropdown> */}
                <li><Link to="/login" className="btn btn-light"><em class="icon ni ni-power me-1"></em> Logout</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
          backdrop
          show={getEmbedCode}
          onHandleCancel={getEmbedCodeHide}
          title="Embed Code"
        >
          <p><b>How to use embed link:-</b> <br/>To embed "Online Booking" form on your website, copy and paste the code below into HTML code for your website where you would like to appear the form. Please follow the below mention steps.</p>
          <p><b>Step1:-</b>Paste tag under the tag in your HTML page.</p>
          <p><b>Step2:-</b>Paste  tag in the tag where you would like to appear the form.</p>
        </ModalComponent>
    </>
  );
}

export default AdminHeader;
