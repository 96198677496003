import { Radio, Select } from "antd";
import React, {useState} from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea
} from "../../../components";

function EditMembership(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/membership')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/membership",
      name: "Membership Plan",
    },
    {
      path: "#",
      name: "Edit Membership Plan",
    },
  ];
  const statusData = [
    {
      name: 'HVAC',
      value: 'HVAC',
    },
    {
      name: 'Electrician',
      value: 'Electrician',
    },
    {
      name: 'Plumbing',
      value: 'Plumbing',
    },
    {
      name: 'Handyman',
      value: 'Handyman',
    },
    {
      name: 'Free Delivery',
      value: 'Free Delivery',
    },
    {
      name: 'Satellite',
      value: 'Satellite',
    },
  ];
  const adminData = [
    {
      name: 'Site Statistics',
      value: 'Site Statistics',
    },
    {
      name: 'Admin',
      value: 'Admin',
    },
    {
      name: 'Provider',
      value: 'Provider',
    },
    {
      name: 'Service Category',
      value: 'Service Category',
    },
    {
      name: 'Services',
      value: 'Services',
    },
    {
      name: 'Customers',
      value: 'Customers',
    },
  ];
  const [yearlyRedioValue, setYearlyRedioValue] = useState('Yes');
  const [monthlyRedioValue, setMonthlyRedioValue] = useState('MonthlyYes');
  const [value, setValue] = useState(1); 
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [valuereq, setValue1] = useState(1);
  const onChanges = (e) => {
    setValue1(e.target.valuereq);
  };
  const [valuePrice, setValue2] = useState(1);
  const onPriceChange = (e) => {
    setValue1(e.target.valuePrice);
  };
  const messageTypeData = [
    {
    name: 'subCategoryService',
    value: 'Sub Category Service',
    },
    {
    name: 'lineItems',
    value: 'Line Items',
    },
    {
    name: 'quotes',
    value: 'Quotes',
    },
  ]
  const [subCategoryService, setSubCategoryService] = useState(true);
  const [lineItems, setLineItems] = useState(false);
  const [quotes, setQuotes] = useState(false);
  const handleMessageTypeChange = (value) => {
    if(value === 'Sub Category Service'){
        setSubCategoryService(true);
        setLineItems(false);
        setQuotes(false);
    }else if(value === 'Line Items'){
        setLineItems(true);
        setSubCategoryService(false);
        setQuotes(false);
    }
    else {
        setQuotes(true);
        setSubCategoryService(false);
        setLineItems(false);
    }
  }
  const serviceProviderData = [
    {
        name: 'Sub Category 1',
        value: 'Sub Category 1',
    },
    {
        name: 'Sub Category 2',
        value: 'Sub Category 2',
    },
    {
        name: 'Sub Category 3',
        value: 'Sub Category 3',
    },
  ]
  const discountData = [
    {
        name: '10%',
        value: '10%',
    },
    {
        name: '30%',
        value: '30%',
    },
    {
        name: '70%',
        value: '70%',
    },
  ]
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Membership Plan">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="Plan Name"
                  element={(formik) => (
                    <TextInput
                      name="plan-name"
                      placeholder="Enter plan name"
                      formik={formik}
                      defaultValue="Plan 1"
                    />
                  )}
                /> 
                <FormElementWrapper
                  md={6}
                  label="Display Order"
                  element={(formik) => (
                    <TextInput
                      name="display-order"
                      placeholder="Enter display order"
                      formik={formik}
                      defaultValue="1"
                    />
                  )}
                /> 
                <FormElementWrapper
                  md={6}
                  label="Monthly option"
                  element={(formik) => (
                      <Radio.Group  onChange={onChange} defaultValue='MonthlyYes'>
                          <Radio value={'MonthlyYes'}
                            onClick={() => setMonthlyRedioValue('MonthlyYes')}>Yes</Radio>
                            <Radio value={'MonthlyNo'}
                            onClick={() => setMonthlyRedioValue('MonthlyNo')}>No</Radio>
                      </Radio.Group>
                  )}
                />              
                {monthlyRedioValue === 'MonthlyYes' && (
                    <FormElementWrapper
                        md={6}
                        label="Monthly Cost"
                        element={(formik) => (
                            <TextInput
                            name="cost"
                            placeholder="Enter monthly cost"
                            formik={formik}
                            defaultValue="14.50"
                            />
                        )}
                    />
                )}
                {monthlyRedioValue === 'MonthlyNo' && (
                  <></>
                )}
                <FormElementWrapper
                  md={6}
                  label="Yearly Options"
                  element={(formik) => (
                    <Radio.Group onChange={onChanges} defaultValue='Yes'>
                      <Radio value={'Yes'}
                      onClick={() => setYearlyRedioValue('Yes')}>Yes</Radio>
                      <Radio value={'No'}
                      onClick={() => setYearlyRedioValue('No')}>No</Radio>
                    </Radio.Group>
                  )}
                />
                {yearlyRedioValue === 'No' && (
                  <></>
                )}
                {yearlyRedioValue === 'Yes' && (
                  <>
                    <FormElementWrapper
                        md={6}
                        label="Yearly Cost"
                        element={(formik) => (
                            <TextInput
                            name="cost"
                            placeholder="Enter Yearly cost"
                            formik={formik}
                            defaultValue="$150"
                            />
                        )}
                    />
                  </>
                )}                
                <FormElementWrapper
                    md={12}
                    label="Type of Benefit"
                    mandatory={true}
                    element={(formik) => (
                    <Select
                        name="messageType"
                        placeholder="Select type of benefit"
                        options={messageTypeData}
                        formik={formik}
                        onChange={handleMessageTypeChange}
                        defaultValue="Sub Category Service"
                       
                    />
                    )}
                />
                {
                    subCategoryService && 
                    <>
                        <FormElementWrapper
                            md={6}
                            label="Select the Sub Category Service"
                            element={(formik) => (
                            <Select
                                name="categoryService"
                                placeholder="Select the sub category service"
                                options={serviceProviderData}
                                formik={formik}
                                defaultValue="Sub Category 1"
                            />
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Type of Sub Category Saving"
                            element={(formik) => (
                                <Radio.Group  onChange={onPriceChange} defaultValue='1'>
                                <Radio value={'1'}>Dollar Amount</Radio>
                                <Radio value={'2'}>Percentage</Radio>
                                </Radio.Group>
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Saving Amount"
                            element={(formik) => (
                                <TextInput
                                name="cost"
                                placeholder="Enter saving amount"
                                formik={formik}
                                defaultValue="15"
                                />
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Description"
                            element={(formik) => (
                                <AntTextArea
                                name="description"
                                className="form-control"
                                placeholder="description"
                                defaultValue="Furnace cleaning plan"
                            />
                            )}
                        />
                    </>
                }
                {
                    lineItems && 
                    <>
                        <FormElementWrapper
                            md={6}
                            label="Line item Saving"
                            element={(formik) => (
                                <Radio.Group  onChange={onPriceChange} defaultValue='1'>
                                <Radio value={'1'}>Dollar Amount</Radio>
                                <Radio value={'2'}>Percentage</Radio>
                                </Radio.Group>
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Saving Amount"
                            element={(formik) => (
                                <TextInput
                                name="cost"
                                placeholder="Enter saving amount"
                                formik={formik}
                                defaultValue="15"
                                />
                            )}
                        />
                        <FormElementWrapper
                            md={12}
                            label="Description"
                            element={(formik) => (
                                <AntTextArea
                                name="description"
                                className="form-control"
                                placeholder="description"
                                defaultValue="Furnace cleaning plan"
                            />
                            )}
                        /> 
                    </>
                }
                {
                    quotes &&
                    <>
                        <FormElementWrapper
                            md={6}
                            label="Select the Discount from Quote System"
                            element={(formik) => (
                            <Select
                                name="discount"
                                placeholder="Select the discount from quote system"
                                options={discountData}
                                formik={formik}
                                defaultValue="10%"
                            />
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Quote Saving"
                            element={(formik) => (
                                <Radio.Group onChange={onPriceChange} defaultValue='1'>
                                <Radio value={'1'}>Dollar Amount</Radio>
                                <Radio value={'2'}>Percentage</Radio>
                                </Radio.Group>
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Saving Amount"
                            element={(formik) => (
                                <TextInput
                                name="cost"
                                placeholder="Enter saving amount"
                                formik={formik}
                                defaultValue="15"
                                />
                            )}
                        />
                        <FormElementWrapper
                            md={6}
                            label="Description"
                            element={(formik) => (
                                <AntTextArea
                                name="description"
                                className="form-control"
                                placeholder="description"
                                defaultValue="Furnace cleaning plan"
                            />
                            )}
                        />
                    </>
                }
                <FormElementWrapper
                  md={12}
                  className="text-end"
                  element={() => (
                    <>
                      <CommonButton
                        type="submit"
                        className="btn btn-primary me-2"
                      >
                        Update
                      </CommonButton>
                      <CommonButton
                        type="submit"
                        className="btn btn-light"
                      >
                        Reset
                      </CommonButton>
                    </>
                  )}
                />
              </FormWrapper>
            </div>
          </div>
        </div>
    </div>
  );
}

export default EditMembership;
