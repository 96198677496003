import { Outlet } from 'react-router-dom';
import { TimeSlot } from '../../views/TimeSlot';

export default function route() {
  return [
    {
      path: accessRoute.TIME_SLOT.path,
      private: true,
      name: 'Manage Slots',
      key: "timeSlot",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.TIME_SLOT.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.TIME_SLOT.path,
        name: 'Time Slot',
        key: accessRoute.TIME_SLOT.key,
        belongsToSidebar: false,
        icon: accessRoute.TIME_SLOT.icon,
        element: <TimeSlot />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  TIME_SLOT: {
    path: '/admin/time-slot',
    key:"timeSlot.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-calendar-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
