import React from 'react';
import { Link } from 'react-router-dom';
import { CommonButton, ImageElement } from '../../../../components';

export default function SuccessFullRegister() {
  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head text-center">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">AWESOME</h5>
              <p>You are well on your way. We are excited you have decided to signup. You will receive a confirmation email that confirms we have received your information.</p>
              <p>In the email you will have a link to click on to schedule a time and finalize your account.</p>
              <h6 className='mb-3 mb-xl-4'>THANK YOU!</h6>
              <Link to="/admin/dashboard" className="btn btn-primary btn-lg">Ok</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
