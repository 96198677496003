/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Col, Row } from 'react-bootstrap';
import Pagination from '../Pagination';
import { GlobalLoader, NoDataFound,CommonButton } from '../index.element';
import { AntTooltip } from '../../';
import { Skeleton, Table } from "antd";

function TablePlus(props) {
  const {
    pagination = true,
    hasLimit,
    handleLimitChange,
    noOfPage,
    sizePerPage,
    page,
    count,
    tableData,
    tableColumns,
    param,
    isSearch=true,
    isResetBtn = true,
    showTableCountNumber=true,
    onTableChange,
    handleActionSelect,
    handleSelectedRows,
    defaultSort,
    header = true,
    showAction = true,
    statusAction = false,
    statusActionOption,
    bordered = false,
    tableLoader = false,
    searchPlaceholder = '',
    isCard = true,
    selectRow = false,
    searchField = true,
    scrollTopPosition = false,
    onModal = false,
    skeletonAnimation = false,
    scroll= false,
  } = props;
  const query = new URLSearchParams(useLocation()?.search);
  const [search, setSearch] = useState(query.get('search') || '');
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const [actionValue, setActionValue] = useState('-');

  useEffect(() => {
    // setSelected([ta]);
  }, [tableData]);

  const goToPage = (pageNo) => {
    const newParams = { ...param };
    if (pageNo) {
      newParams.page = pageNo;
      searchParams.set('page', pageNo);
    }
    setSearchParams(searchParams);
  };

  const handleSelect = (e) => {
    searchParams.set('page', 1);
    searchParams.set('pageSize', e.target.value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if ((search.length >= 3 || search.length === 0) && firstTimeFetch) {
        searchParams.set('search', search);
        setSearchParams(searchParams);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    setFirstTimeFetch(true);
  }, []);

  const handleSearchValue = (val) => {
    setSearch(val);
  };

  const options = {
    page,
    sizePerPage,
  };

  const indicationLoading = () => <GlobalLoader />;

  const indicationNoRecords = () => {
    return <NoDataFound text="No Data Found" source="nodata-found.svg" />;
  };

  function onTableCheckboxChange(e) {
    try {
      handleSelectedRows(e);
      setActionValue('-');
    } catch (err) {
      console.log(err);
    }
  }

  async function onTableActionChange(e) {
    try {
      await handleActionSelect(e);
      setActionValue('-');
    } catch (err) {
      console.log(err);
    }
  }

  const handleOnSelect = (row, isSelect) => {
    let selectedNew = [...selected];

    if (isSelect) {
      selectedNew = [...selectedNew, row.id];
    } else {
      selectedNew = selectedNew.filter((x) => x !== row.id);
    }
    setSelected([...selectedNew]);
    onTableCheckboxChange([...selectedNew]);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    let selectedNew = [...selected];

    const ids = rows.map((r) => r.id);
    if (isSelect) {
      selectedNew = ids;
    } else {
      selectedNew = [];
    }
    setSelected([...selectedNew]);
    onTableCheckboxChange([...selectedNew]);
  };

  const onTableSelectChange = (rows) => {
    try {
      setSelected([...rows]);
      onTableCheckboxChange(rows)
    } catch (err) {
      console.log(err);
    }
  }

  const rowSelection = {
    mode: 'checkbox',
    clickToSelect: false,
    onChange: onTableSelectChange,
    // onSelectAll: handleOnSelectAll,
  };

  const paginationOptions = {
    total: count,
    current: page,
    pageSize: sizePerPage
  }

  const handleChange = (pagination, filters, sorter) => {
    console.log("Filter", filters, sorter)
    // const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    // const limit = pagination.pageSize;
    // const params = {};

    // if (sorter.hasOwnProperty("column")) {
    //   params.order = { field: sorter.field, dir: sorter.order };
    // }

    // getData(offset, limit, params);
  };

  let divScroll = document.getElementById('scrollView');
  let table = scroll ? document.querySelector('.ant-table .ant-table-body table') : document.querySelector('.ant-table table');
  let scrollWraper = document.getElementById('scrollWraper');
  let tableWraper = scroll ? document.querySelector('.ant-table-body') : document.querySelector('.ant-table-content');
  const isFirefox = typeof InstallTrigger !== 'undefined';
  let extraSpace = isFirefox ? 20 : 6;
  if (divScroll && table?.scrollWidth) {
    setTimeout(() => {
      if (divScroll) {
        divScroll.style.width = scroll ? table?.scrollWidth + extraSpace + 'px' : table.scrollWidth + 'px';
      }
    }, 500);
  }
  if (scrollWraper && tableWraper) {
    scrollWraper.onscroll = function () {
      tableWraper.scrollLeft = scrollWraper.scrollLeft;
    };
    tableWraper.onscroll = function () {
      scrollWraper.scrollLeft = tableWraper.scrollLeft;
    };
  }
  const [active, setActive] = useState(false);

  return (
    <div className="nk-block">
      <div className={`${isCard ? 'card' : ''} position-static ${onModal && 'shadow-none'}`}>
        <div className={`card-inner ${onModal && 'p-0'}`}>
          <div className="common-table">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              {header && (
                <Row className="justify-between g-2 mb-3">
                  <Col sm="4" md={6} className="text-start">
                    {isSearch && <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      {searchPlaceholder ? (
                        <AntTooltip
                          placement="topLeft"
                          promptText={`Search by ${searchPlaceholder}`}
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-md"
                              placeholder="Type 3 or more characters"
                              aria-controls="DataTables_Table_0"
                              onChange={(e) =>
                                handleSearchValue(e.target.value)
                              }
                              value={search}
                            />
                          </label>
                        </AntTooltip>
                      ) : (
                        searchField && (
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-md"
                              placeholder="Search..."
                              aria-controls="DataTables_Table_0"
                              onChange={(e) =>
                                handleSearchValue(e.target.value)
                              }
                              value={search}
                            />
                          </label>
                        )
                      )}
                    </div>}
                  </Col>
                  <Col sm="8" md={6} className="text-end">
                    <div className="d-flex justify-content-sm-end filterButtons">
                      {/* <CommonButton className="btn btn-outline-light bg-white"> <span> Reset All</span></CommonButton> */}
                      {
                        isResetBtn && <CommonButton variant='outline-light' className="filterButtons_btnLight"> <span> Reset Filter</span></CommonButton>
                      }
                      {showAction && (
                        <div className="datatable-filter status">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <div className="form-control-select">
                              <select
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={handleSelect}
                              >
                                <option value="active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="delete">Delete </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {statusAction && (
                        <div className="datatable-filter status">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <div className="form-control-select">
                              <select
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={handleSelect}
                              >
                                {statusActionOption.map(option => <option value={option.name}>{option.name}</option>)}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {
                        showTableCountNumber && 
                        <div className="datatable-filter">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <div className="form-control-select">
                                {' '}
                                <select
                                  value={query.get('pageSize') || '10'}
                                  name="DataTables_Table_0_length"
                                  aria-controls="DataTables_Table_0"
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                  onChange={handleSelect}
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>{' '}
                              </div>
                          </div>
                        </div>
                      }
                      
                    </div>
                  </Col>
                </Row>
              )}
              {scrollTopPosition && <div id="scrollWraper" className='topScroll'>
                  <div id="scrollView" style={{height: '10px'}}>
                  </div>
              </div>}
              {
                skeletonAnimation &&
                <div className='skeletonTable'>
                  <Skeleton.Input active size='large' />
                </div>
              }
              <Table
                rowSelection={selectRow?rowSelection:selectRow}
                loading={tableLoader}
                pagination={false}
                rowKey="id"
                 onChange={handleChange}
                //  pagination={false}
                columns={tableColumns}
                dataSource={tableData}
                scroll = { scroll}
              />
              {pagination && (
                <Pagination
                  count={count}
                  page={parseInt(page)}
                  sizePerPage={sizePerPage}
                  noOfPage={noOfPage}
                  goToPage={goToPage}
                  handleLimitChange={handleLimitChange}
                  hasLimit={hasLimit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablePlus;
