export const MarketingReviewTemplateService = () => {
    return [
        {
            id: 1,
            dateOfSubmission: "May 12, 2023",
            senderName: "Arpit Jai",
            typeOfMessageSent: "Email",
            audience: "Customer (2)",
            moduleServicesTags: "viewTags",
            approve: "Approve",
            decline: "button",
            viewReport: " ",
            approvedDeclinedDate: "--",
          },
          {
            id: 2,
            dateOfSubmission: "May 12, 2023",
            senderName: "Kam Jacobs",
            typeOfMessageSent: "Email",
            audience: "Customer (1)",
            moduleServicesTags: "viewTags",
            approve: "Approve",
            decline: "button",
            viewReport: " ",
            approvedDeclinedDate: "--",
          },
          {
            id: 3,
            dateOfSubmission: "May 13, 2023",
            senderName: "	Ios Pro",
            typeOfMessageSent: "Sms",
            audience: "Customer (5)",
            moduleServicesTags: "viewTags",
            approve: "Approve",
            decline: "button",
            viewReport: " ",
            approvedDeclinedDate: "--",
          },
          {
            id: 4,
            dateOfSubmission: "May 14, 2023",
            senderName: "Main Administrator",
            typeOfMessageSent: "Email",
            audience: "Admin (5)",
            moduleServicesTags: "viewModules",
            approve: "Approved",
            decline: "decline",
            viewReport: "view",
            approvedDeclinedDate: "Apr 17, 2023",
          },
          {
            id: 5,
            dateOfSubmission: "Apr 19, 2023",
            senderName: "Main Administrator",
            typeOfMessageSent: "Email",
            audience: "Admin (7)",
            moduleServicesTags: "viewModules",
            approve: "Approved",
            decline: "decline",
            viewReport: "view",
            approvedDeclinedDate: "Apr 19, 2023",
          },
          {
            id: 6,
            dateOfSubmission: "Apr 19, 2023",
            senderName: "Main Administrator",
            typeOfMessageSent: "Email",
            audience: "Admin (7)",
            moduleServicesTags: "viewModules",
            approve: "Approved",
            decline: " ",
            viewReport: "view",
            approvedDeclinedDate: "Apr 19, 2023",
          },
          {
            id: 7,
            dateOfSubmission: "Apr 19, 2023",
            senderName: "Ios Pro",
            typeOfMessageSent: "Email",
            audience: "Provider (219)",
            moduleServicesTags: "viewServices",
            approve: "Approved",
            decline: " ",
            viewReport: "view",
            approvedDeclinedDate: "Apr 19, 2023",
          },
          {
            id: 8,
            dateOfSubmission: "Apr 19, 2023",
            senderName: "Ios Pro",
            typeOfMessageSent: "Email",
            audience: "Provider (10)",
            moduleServicesTags: "viewServices",
            approve: "Approved",
            decline: " ",
            viewReport: "view",
            approvedDeclinedDate: "Apr 19, 2023",
          },
    ];
  };
  