import React, { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import { Breadcrumb, PageHeader } from '../../../../../components';
import {
  InformationForm,
  PageContainerWrapper,
} from '../../../../../components';
import {
  Columns,
  informationService,
} from '../../../../../services/index.service';
import { modalNotification } from '../../../../../utils';
function Information(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  async function onSubmit(e) {
    try {
      const res = await informationService({
        ...e,
      });

      const { message, success } = res;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        navigate('');
      }
    } catch (err) {
      console.log(err);
    }
  }
  const breadcrumb = Columns.importantInformationBreadcrumb();
  const onCancle = () => {
    // console.log('cancle button');
  };
  return (
    <>
      <div className="wide-md mx-auto">
        <PageContainerWrapper
          breadcrumb={breadcrumb}
          btnArray={[]}
          addBtnText="Add Role"
          heading={t('text.appText.infoHeading')}
        >
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                <InformationForm onSubmit={onSubmit} onCancle={onCancle} />
              </div>
            </div>
          </div>
        </PageContainerWrapper>
      </div>
    </>
  );
}

export default Information;
