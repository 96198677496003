import { Radio, Select } from "antd";
import React, {useState} from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea
} from "../../../components";

function CreateProcedureLesson(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/standard-operating-procedures')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/standard-operating-procedures",
      name: "Standard Operating Procedures",
    },
    {
      path: "#",
      name: "Add Lesson",
    },
  ];
  const statusData = [
    {
      name: 'HVAC',
      value: 'HVAC',
    },
    {
      name: 'Electrician',
      value: 'Electrician',
    },
    {
      name: 'Plumbing',
      value: 'Plumbing',
    },
    {
      name: 'Handyman',
      value: 'Handyman',
    },
    {
      name: 'Free Delivery',
      value: 'Free Delivery',
    },
    {
      name: 'Satellite',
      value: 'Satellite',
    },
  ];
  const adminData = [
    {
      name: 'Site Statistics',
      value: 'Site Statistics',
    },
    {
      name: 'Admin',
      value: 'Admin',
    },
    {
      name: 'Provider',
      value: 'Provider',
    },
    {
      name: 'Service Category',
      value: 'Service Category',
    },
    {
      name: 'Services',
      value: 'Services',
    },
    {
      name: 'Customers',
      value: 'Customers',
    },
  ];
  const [someDaysRediovalue, setRequiredRediovalue] = useState();
  const [OperatingRedioRediovalue, setOperatingRediovalue] = useState();
  const [value, setValue] = useState(1); 
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [valuereq, setValue1] = useState(1);
  const onChanges = (e) => {
    setValue1(e.target.valuereq);
  };
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Lesson">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <FormWrapper row={true} extraClassName="g-3">               
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label w-100">
                    Type of Operating Procedure  
                    </label>
                    <Radio.Group value={value} onChange={onChange}>
                      <Radio value={'Service Provider'}
                      onClick={() => setOperatingRediovalue('Service Provider')}>Service Provider</Radio>
                      <Radio value={'Admin'}
                      onClick={() => setOperatingRediovalue('Admin')}>Admin</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {OperatingRedioRediovalue === 'Service Provider' && (
                  <FormElementWrapper
                    md={12}
                    label="Manage Services"
                    element={(formik) => (
                      <Select
                        name="manage-services"
                        mode="multiple"
                        placeholder="Select service option"
                        options={statusData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                )}
                {OperatingRedioRediovalue === 'Admin' && (
                  <FormElementWrapper
                    md={12}
                    label="Admin Modules"
                    element={(formik) => (
                      <Select
                        name="admin-modules"
                        mode="multiple"
                        placeholder="Select admin modules"
                        options={adminData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label w-100">
                    Required
                    </label>
                    <Radio.Group valuereq={valuereq} onChange={onChanges}>
                      <Radio value={'No'}
                      onClick={() => setRequiredRediovalue('No')}>No</Radio>
                      <Radio value={'Yes'}
                      onClick={() => setRequiredRediovalue('Yes')}>Yes</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {someDaysRediovalue === 'No' && (
                  <></>
                )}
                {someDaysRediovalue === 'Yes' && (
                  <>
                    <FormElementWrapper
                      md={12}
                      label="Required passing score %"
                      element={(formik) => (
                        <TextInput
                          name="score"
                          placeholder="Passing score value"
                          formik={formik}
                        />
                      )}
                    />
                  </>
                )}                
                <FormElementWrapper
                  md={12}
                  label="Lesson Title"
                  element={(formik) => (
                    <TextInput
                      name="lesson-title"
                      placeholder="Lesson title"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={12}
                  label="Lesson Description"
                  element={(formik) => (
                    <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Lesson description"
                  />
                  )}
                />
                <FormElementWrapper
                  md={12}
                  className="text-end"
                  element={() => (
                    <>
                      <CommonButton
                        type="submit"
                        className="btn btn-primary me-2"
                      >
                        Save
                      </CommonButton>
                      <CommonButton
                        type="submit"
                        className="btn btn-light"
                      >
                        Reset
                      </CommonButton>
                    </>
                  )}
                />
              </FormWrapper>
            </div>
          </div>
        </div>
    </div>
  );
}

export default CreateProcedureLesson;
