
import { DatePicker, Radio } from "antd";
import React, {useState} from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea,
  Select
} from "../../../../components";

function EditPromoCode(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/promo-code')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/promo-code",
      name: "Promo Code",
    },
    {
      path: "#",
      name: "Edit Promo Code",
    },
  ];
  const HardwareStoreTypeData = [
    {
      name: 'Test Company',
      value: 'Test Company',
    },
    {
      name: 'GGHS ADMIN COMPANY FOR MAIL TEST',
      value: 'GGHS ADMIN COMPANY FOR MAIL TEST',
    },
    {
      name: 'Todd Scott',
      value: 'Todd Scott',
    },
  ]
  const StatusTypeData = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ]
  const discountTypeData = [
    {
      name: '%',
      value: '%',
    },
    {
      name: 'Flat Amount',
      value: 'Flat Amount',
    },
  ]
  const [value, setValue] = useState("Permanent");
  const onChanges = (e) => {
    setValue(e.target.value);
  };
  const [validityRediovalue, setValidityRediovalue] = useState();
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Promo Code">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6}
                label="Coupon Code"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="code"
                    placeholder="Enter coupon code"
                    formik={formik}
                    defaultValue="LV1UFBX"
                    disabled
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Discount"
                mandatory={true}
                element={(formik) => (
                  <>
                    <div className="d-flex">
                      <TextInput
                        name="discount"
                        placeholder="Enter discount"
                        formik={formik}
                        type="number"
                        defaultValue="10"
                      />
                      <Select
                        name="adminType"
                        placeholder="Select discount"
                        options={discountTypeData}
                        showSearch
                        formik={formik}
                        className="ms-1"
                        defaultValue="Flat Amount"
                      />
                    </div>
                  </>
                )}
              />
              <FormElementWrapper
                md={12}
                label="Description"
                mandatory={true}
                element={(formik) => (
                  <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Enter description"
                    defaultValue="For test disabled promo code"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                label="Validity"
                mandatory={true}
                element={(formik) => (
                  <Radio.Group value={value} onChange={onChanges}>
                      <Radio value={'Permanent'}
                      onClick={() => setValidityRediovalue('Permanent')}> Permanent</Radio>
                      <Radio value={'Custom'}
                      onClick={() => setValidityRediovalue('Custom')}>Custom</Radio>
                    </Radio.Group>
                )}
              />
              {validityRediovalue === 'Permanent' && (
                  <></>
              )}
                {validityRediovalue === 'Custom' && (
                  <>
                <FormElementWrapper
                  md={6}
                  mandatory={true}
                  label="Activation Date"
                  element={(formik) => (
                    <DatePicker
                      name="startDate"
                      className="form-control date-picker shadow-none"
                      placeholder="Select activation date"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  mandatory={true}
                  label="Expiry Date"
                  element={(formik) => (
                    <DatePicker
                      name="startDate"
                      className="form-control date-picker shadow-none"
                      placeholder="Select expiry date"
                    />
                  )}
                />
              </>
            )} 
            <FormElementWrapper
                md={6}
                label="Usage Limit"
                tooltip={true}
                tooltipText="Promo code can be used one time only for each user. So if you set Usage limit to 100 then 100 unique user can use this promo code."
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="UsageLimit"
                    placeholder="Enter usage limit"
                    formik={formik}
                    defaultValue="50"
                  />
                )}
              />
            <FormElementWrapper
              md={6}
              label="Status"
              mandatory={true}
              element={(formik) => (
                <Select
                  name="Status"
                  placeholder="Select status"
                  options={StatusTypeData}
                  showSearch
                  formik={formik}
                  defaultValue="Active"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Hardware Store"
              element={(formik) => (
                <Select
                  name="Status"
                  placeholder="Select hardware store"
                  options={HardwareStoreTypeData}
                  showSearch
                  formik={formik}
                  defaultValue="Test Company"
                />
              )}
            />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light"
                >
                   Reset  
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditPromoCode;
