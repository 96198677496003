export const APP_TEXT_ENDPOINT = {
  importantInformation: {
    url: '/v1/admin/important-information',
    method: 'PUT',
  },
  financingInformation: {
    url: '/v1/admin/financing-information',
    method: 'PUT',
  },
};
