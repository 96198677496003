import { Outlet } from 'react-router-dom';
import { ListNotification } from '../../views/Notification';


export default function route() {
  return [
    {
      path: accessRoute.NOTIFICATIONS.path,
      private: true,
      name: 'Notifications',
      key: "notifications",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.NOTIFICATIONS.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.NOTIFICATIONS.path,
        name: 'notifications',
        key: accessRoute.NOTIFICATIONS.key,
        belongsToSidebar: false,
        icon: accessRoute.NOTIFICATIONS.icon,
        element: <ListNotification />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  NOTIFICATIONS: {
    path: '/admin/notifications',
    key:"notifications.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-bell-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
