import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { ImageElement } from '../../../components';

function Login() {
  // const { t } = useTranslation();
  // const location = useLocation();
  // const navigate = useNavigate();

  // const currentPath = location?.pathname;

  // function onRouteChange(e) {
  //   try {
  //     e.preventDefault();
  //     navigate(e.target.value);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <>
      <div className="nk-split nk-split-page nk-split-lg">
        <div className="nk-split-content bg-dark is-dark p-2 p-lg-5 d-none d-lg-flex justify-between flex-column text-center">
          <Link className="logo-link nk-sidebar-logo" to="#">
            <ImageElement
              source="small-logo-white.svg"
              className="logo-light logo-img logo-img-lg"
              alt="logo-dark"
            />
          </Link>
          <div className="text-block wide-xs mx-auto">
            <h3 className="text-white">Hello Admins! Welcome to GGHS.</h3>
            <p className="text-white">
            Together, let’s simplify and shape exceptional home service experiences for our valued customers with our powerful management tool.
            </p>
            <ImageElement
              source="job-survey.svg"
              className="nk-survey-gfx mt-5"
              alt="logo-dark"
            />
          </div>
          <p className="text-white">© 2022 Good Guys Home Services</p>
        </div>
        <div className="nk-split-content nk-split-stretch bg-lighter p-2 p-lg-5 d-flex justify-center align-center flex-column">
          <div className="nk-block nk-block-middle wide-xs-fix">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
