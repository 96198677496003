import React from 'react';
import { Link } from 'react-router-dom';
import {
  AntTextArea,
  Breadcrumb,
  InputType,
  PageHeader,
} from '../../../../../components';

function Tips(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/Tips',
      name: 'tips',
    },
  ];
  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Tips">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip1 Title <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip title"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip1 Url <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip Url"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="url"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip1 Description <span className="text-danger"> *</span>
                      </label>
                      <AntTextArea
                        className="form-control"
                        placeholder="Enter tip description"
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip2 Title <span className="text-danger"> *</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip title"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip2 Url <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip Url"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="url"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip2 Description <span className="text-danger"> *</span>
                      </label>
                      <AntTextArea
                        className="form-control"
                        placeholder="Enter tip description"
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="nk-divider divider xs"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Tip3 Title <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip title"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip3 Url <span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter tip Url"
                        
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="url"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Tip3 Description <span className="text-danger"> *</span>
                      </label>
                      <AntTextArea
                        className="form-control"
                        placeholder="Enter tip description"
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary me-2">
                      Update
                    </Link>
                    <Link href="#" className="btn btn-light">
                      Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tips;
