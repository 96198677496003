export const GoogleConnectServiceList = () => {
    return [
      {
        id: "1897973164",
        business: "Good Guys Heating and Air Conditioning",
        averageWeeklyBudget: '$ 999,999.00',
        totalReview: "6",
        averageFiveStarRating: "5",
        currentPeriodChargedLeads: "0",
        currentPeriodConnectedPhoneCalls: "0",
        currentPeriodPhoneCalls: "0",
        currentPeriodTotalCost: "$ 0.00",
        impressionsLastTwoDays: "0",
        phoneLeadResponsiveness: "0",
        previousPeriodChargedLeads: "0",
        previousPeriodConnectedPhoneCalls: "0",
        previousPeriodPhoneCalls: "0",
        previousPeriodTotalCost: '$ 5.00',
        new: "NA",
        active: "NA",
        booked: "NA",
        completed: "NA",
        archive: "NA",
        biddingMode: "NA",
        currentBalance: "NA",
      },
      {
        id: "3134076415",
        business: "Tekhne of Fort Worth, Texas",
        averageWeeklyBudget: '$ 700.00',
        totalReview: "10",
        averageFiveStarRating: "4.5",
        currentPeriodChargedLeads: "0",
        currentPeriodConnectedPhoneCalls: "0",
        currentPeriodPhoneCalls: "0",
        currentPeriodTotalCost: "$ 0.00",
        impressionsLastTwoDays: "0",
        phoneLeadResponsiveness: "0",
        previousPeriodChargedLeads: "0",
        previousPeriodConnectedPhoneCalls: "0",
        previousPeriodPhoneCalls: "0",
        previousPeriodTotalCost: '$ 5.00',
        new: "NA",
        active: "NA",
        booked: "NA",
        completed: "NA",
        archive: "NA",
        biddingMode: "NA",
        currentBalance: "NA",
      },
      {
        id: "3257228609",
        business: "Tekhne Home Services",
        averageWeeklyBudget: '$ 900.00',
        totalReview: "15",
        averageFiveStarRating: "4.5",
        currentPeriodChargedLeads: "0",
        currentPeriodConnectedPhoneCalls: "0",
        currentPeriodPhoneCalls: "0",
        currentPeriodTotalCost: "$ 0.00",
        impressionsLastTwoDays: "0",
        phoneLeadResponsiveness: "0",
        previousPeriodChargedLeads: "0",
        previousPeriodConnectedPhoneCalls: "0",
        previousPeriodPhoneCalls: "0",
        previousPeriodTotalCost: '$ 5.00',
        new: "NA",
        active: "NA",
        booked: "NA",
        completed: "NA",
        archive: "NA",
        biddingMode: "NA",
        currentBalance: "NA",
      },
      {
        id: "7198054105",
        business: "Tekhne of Port Orange, Florida",
        averageWeeklyBudget: '$ 1900.00',
        totalReview: "08",
        averageFiveStarRating: "4.9",
        currentPeriodChargedLeads: "0",
        currentPeriodConnectedPhoneCalls: "0",
        currentPeriodPhoneCalls: "0",
        currentPeriodTotalCost: "$ 0.00",
        impressionsLastTwoDays: "0",
        phoneLeadResponsiveness: "0",
        previousPeriodChargedLeads: "0",
        previousPeriodConnectedPhoneCalls: "0",
        previousPeriodPhoneCalls: "0",
        previousPeriodTotalCost: '$ 5.00',
        new: "NA",
        active: "NA",
        booked: "NA",
        completed: "NA",
        archive: "NA",
        biddingMode: "NA",
        currentBalance: "NA",
      },
    ];
  };
  