import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Toggle,
  ActionWrapper,
  SweetAlert,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../components';
import { Link,useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListServiceCategory(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Category Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Service Category',
    },
  ];
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const ButtonFormatter = (url, text) => {
    return (
      <>
        <div className="">
          <Link to={url} className="btn btn-primary">{text} <span className='badge ms-1 badge-circle align-items-center'>5</span></Link>
        </div>
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'icon',
      title: 'Icon',
      headerClasses: 'sorting',
      render: (cell, row) => logoFormatter(row?.iconImg,"Image"),
    },
    {
      dataIndex: 'name',
      title: 'Name ',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("name")
    },
    {
      dataIndex: 'subcategoriestype',
      title: 'Sub Categories',
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/subservice-category", "View"),
    },
    {
      dataIndex: 'displayOrder',
      title: 'Display Order',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/service-category/Edit',
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);

    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Active'
      },
      {
      id: 3,
      name: 'Make Inactive'
      },
    ] 

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      iconImg: '/assets/images/admin/icons.png',
      name: 'Category',
      displayOrder: '1',
    },
    {
      id: 2,
      iconImg: '/assets/images/admin/icons.png',
      name: 'CALL BOOKING',
      displayOrder: '1',
    },
    {
      id: 3,
      iconImg: '/assets/images/admin/icons.png',
      name: 'HVAC',
      displayOrder: '2',
    },
    {
      id: 4,
      iconImg: '/assets/images/admin/icons.png',
      name: '	Electrician',
      displayOrder: '5',
    },
    {
      id: 5,
      iconImg: '/assets/images/admin/icons.png',
      name: '	Plumbing',
      displayOrder: '3',
    },
  ];
  const navigate = useNavigate()
  const AddServiceCategory = () =>{
    navigate('/admin/service-category/add');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Service Category">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport']}
            btnText="Add Service Category"
            onHandleShow={AddServiceCategory}
            // popover={<ServiceCategoryFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}  
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Category"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
       
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Main Category module will list all Main Category on this page.
                </li>
                <li>
                Administrator can Activate / Deactivate / Delete anyMain Category
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListServiceCategory;
