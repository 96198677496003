
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea,
  Select
} from "../../../../components";
import { Radio } from "antd";
import { Col, Row } from "react-bootstrap";

function EditProducts(props) {
  const navigate = useNavigate();
  const backPage = () => {
    navigate('/admin/product-management')
  }
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/product-management",
      name: "Product Management",
    },
    {
      path: "#",
      name: "Edit Product",
    },
  ];
  const productCategory = [
    {
      name: 'Fashion',
      value: 'Fashion',
    },
    {
      name: 'Electronics',
      value: 'Electronics',
    },
    {
      name: 'Toys',
      value: 'Toys',
    },
  ]
  const attributesVariant = [
    {
      name: 'Small',
      value: 'Small',
    },
    {
      name: 'Medium',
      value: 'Medium',
    },
    {
      name: 'Large',
      value: 'Large',
    },
  ]
  const productVariant = [
    {
      name: 'Size',
      value: 'Size',
    },
    {
      name: 'Color',
      value: 'Color',
    },
  ]
  const couponCodeData = [
    {
      name: 'Yes',
      value: 'Yes',
    },
    {
      name: 'No',
      value: 'No',
    },
  ]
  const [radioValue, setRadioValue] = useState(1);
  return (
    <div className="">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Product">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6} lg={4}
                label="Product Images 1"
                element={(formik) => (
                  <TextInput
                    name="Image1"
                    placeholder="Select Image"
                    formik={formik}
                    type="file"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product Images 2"
                element={(formik) => (
                  <TextInput
                    name="Image2"
                    placeholder="Select Image"
                    formik={formik}
                    type="file"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product Images 3"
                element={(formik) => (
                  <TextInput
                    name="Image3"
                    placeholder="Select Image"
                    formik={formik}
                    type="file"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product Title"
                element={(formik) => (
                  <TextInput
                    name="title"
                    placeholder="Enter product title"
                    formik={formik}
                    defaultValue="Air purifier"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product Category"
                element={(formik) => (
                  <Select
                    name="adminType"
                    mode="multiple"
                    allowClear
                    placeholder="Select product category"
                    options={productCategory}
                    showSearch
                    formik={formik}
                    defaultValue="Electronics"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product Quantity"
                element={(formik) => (
                  <TextInput
                    name="quantity"
                    placeholder="Enter product quantity"
                    formik={formik}
                    defaultValue="10"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Product SKU"
                element={(formik) => (
                  <TextInput
                    name="SKU"
                    placeholder="Enter product SKU"
                    formik={formik}
                    defaultValue="50"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Regular  Price"
                element={(formik) => (
                  <TextInput
                    name="price"
                    placeholder="Enter regular price"
                    formik={formik}
                    defaultValue="$100"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Sale Price"
                element={(formik) => (
                  <TextInput
                    name="price"
                    placeholder="Enter sale price"
                    formik={formik}
                    defaultValue="$10"
                  />
                )}
              />
              <FormElementWrapper
                md={6} lg={4}
                label="Coupon code"
                element={(formik) => (
                  <Select
                    name="couponCode"
                    placeholder="Select coupon code"
                    options={couponCodeData}
                    showSearch
                    formik={formik}
                    defaultValue="Yes"
                  />
                )}
              />
              {/* <FormElementWrapper
                md={6} lg={4}
                label="Coupon code"
                element={(formik) => (
                  <Radio.Group value={radioValue}>
                    <Radio value={1} onClick={() => setRadioValue(1)}>Yes </Radio>
                    <Radio value={2} onClick={() => setRadioValue(2)}>No</Radio>
                  </Radio.Group>
                )}
              /> */}
              <div className="productVariantAttribute form-group col-lg-12 mt-2 gy-3">
                <Row className="align-items-end g-3">
                  <Col sm={5} lg={4}>
                    <FormElementWrapper
                      label="Product Variant"
                      element={(formik) => (
                        <Select
                          name="adminType"
                          placeholder="Select product variant"
                          options={productVariant}
                          showSearch
                          formik={formik}
                          defaultValue="Size"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={5} lg={4}>
                    <FormElementWrapper
                      label="Product Attributes"
                      element={(formik) => (
                        <Select
                          name="adminType"
                          mode="multiple"
                          allowClear
                          placeholder="Select product attributes"
                          options={attributesVariant}
                          showSearch
                          formik={formik}
                          defaultValue="Small"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={2} lg={4}>
                    <a class="btn btn-primary btn-md" href="#!"><em class="icon ni ni-plus"></em></a>
                  </Col>
                </Row>
                <Row className="align-items-end g-3">
                  <Col sm={5} lg={4}>
                    <FormElementWrapper
                      label="Product Variant"
                      element={(formik) => (
                        <Select
                          name="adminType"
                          placeholder="Select product variant"
                          options={productVariant}
                          showSearch
                          formik={formik}
                          defaultValue="Color"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={5} lg={4}>
                    <FormElementWrapper
                      label="Product Attributes"
                      element={(formik) => (
                        <Select
                          name="adminType"
                          mode="multiple"
                          allowClear
                          placeholder="Select product attributes"
                          options={attributesVariant}
                          showSearch
                          formik={formik}
                          defaultValue="Red"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={2} lg={4}>
                    <a class="btn btn-danger btn-md" href="#!"><em class="icon ni ni-trash"></em></a>
                  </Col>
                </Row>
              </div>
              <FormElementWrapper
                md={12}
                label="Product Description"
                element={(formik) => (
                  <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Enter product description "
                    defaultValue="for test promo code"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Update
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
            </FormWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProducts;
