import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImageElement } from "../../../components";

const targetTime = new Date("2023-10-12").getTime();

function WebsiteUnderMaintenance(props) {
    
    const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetTime - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);

  useEffect(() => {
    let navbar = document.querySelector(".websiteMaintenance_header").clientHeight;
    document.querySelector("body").style.paddingTop = `${navbar}px`;
    let navbarheader = document.querySelector(".websiteMaintenance_header");
    let navbarHeight = navbarheader?.clientHeight;
    let authPage = document.querySelector(".websiteMaintenance_inner");
    authPage.style.minHeight = `${window.innerHeight - navbarHeight}px`;
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
    return(
        <>
            <div className="websiteMaintenance">
                <div class="websiteMaintenance_header text-center bg-white">
                    <Link
                        to="/admin/dashboard"
                    >
                        <ImageElement
                            source="logo.svg"
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="websiteMaintenance_inner d-flex flex-column align-items-center justify-content-center">
                    <ImageElement
                        source="website-maintenance.png"
                        alt="website-maintenance"
                    />
                    <ul className="d-flex align-items-center justify-content-center">
                        <li className="text-center">
                            <div class="box">{hours}</div>
                            <p>Hours</p>
                        </li>
                        <li className="text-center">
                            <div class="box">{minutes}</div>
                            <p>Minutes</p>
                        </li>
                        <li className="text-center">
                            <div class="box">{seconds}</div>
                            <p>Seconds</p>
                        </li>
                    </ul>
                    <h1>We're Down For Maintenance</h1>
                    <p className="text-center">This page is undergoing maintenance and<br/> will be back sooo.</p>
                </div>
            </div>
        </>
    )
}

export default WebsiteUnderMaintenance;