import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { CommonButton, ImageElement } from '../../../components';
import { accessRoute } from '../../../routes/Auth/login';

function Login() {
  const navigate = useNavigate();
  const [state, setState] = React.useState('');

  const onChange = (e) => {
    if (e.target.checked) {
      setState(e.target.value);
    }
  };

  const onSubmit = () => {
    try {
      if (!!state) {
        navigate(state);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <Row>
            <Col sm={6}>
              <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full">
                <input
                  type="radio"
                  name="admins"
                  id="super-admin"
                  onChange={onChange}
                  value={accessRoute.ADMIN_LOGIN.path}
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label bg-white px-1"
                  htmlFor="super-admin"
                >
                  <span className="d-flex flex-column text-center px-sm-3 h-100 justify-content-center">
                    <em className="icon icon-circle icon-circle-lg bg-teal ni ni-user"></em>
                    <span className="lead-text mb-1 mt-3">
                      Super Administrator
                    </span>
                    <span className="sub-text">
                      Hello, Super Admin! Take charge and transform home services.
                    </span>
                  </span>
                </label>
              </div>
            </Col>
            <Col sm={6} className="mt-3 mt-sm-0">
              <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full">
                <input
                  type="radio"
                  name="admins"
                  onChange={onChange}
                  id="company-admin"
                  value={accessRoute.NCA_LOGIN.path}
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label bg-white px-1"
                  htmlFor="company-admin"
                >
                  <span className="d-flex flex-column text-center px-sm-3 h-100 justify-content-center">
                    <em className="icon icon-circle icon-circle-lg bg-orange ni ni-briefcase"></em>
                    <span className="lead-text mb-1 mt-3">
                      Company Administrator
                    </span>
                    <span className="sub-text">
                      Welcome! Start managing your exclusive home services effortlessly.
                    </span>
                  </span>
                </label>
              </div>
            </Col>
            <Col xs={12}>
              <CommonButton
                onClick={() => onSubmit()}
                className="btn btn-primary mt-3 mt-xl-4 btn-block btn-lg text-uppercase"
              >
                Submit
              </CommonButton>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Login;
