import React from 'react';
import { Link } from 'react-router-dom';
import {TextInput, CommonButton, FormElementWrapper, FormWrapper} from "../../../components";

function ForgetPassword(props) {
    return (
        <div className="card card-bordered">         
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h5 className="nk-block-title">Forgot Password?</h5>
                  <div className="nk-block-des">
                    <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                  </div>
                </div>
              </div>
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  xs={12}
                  label="Email"
                  element={(formik) => (
                    <TextInput
                      name="emailOrMobile"
                      extraClassName="form-control-lg"
                      formik={props}
                      placeholder="Enter your email address"
                    />
                  )}
                />
                <div className="form-group">
                  <CommonButton
                    type="submit"
                    className="btn btn-lg btn-primary btn-block"
                  >
                    Recover Password 
                  </CommonButton>
                </div>
                <div className="form-note-s2 text-center">                       
                  <Link to="/nca-login">Back to Sign In</Link>
                </div>
              </FormWrapper>
            </div>
          </div> 

    );
}

export default ForgetPassword;