import React from 'react';
import { Table } from 'react-bootstrap';
import {   useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
} from '../../../components';

function EquipmentList(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/equipment-order',
      name: 'Equipment Order',
    },
    {
      path: '#',
      name: 'Equipment',
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Equipment">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/equipment-order')}
          />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <h6 className="title mb-3">90+% Furnace</h6>
            <div className='table-responsive'>
            <Table bordered size="sm">
              <tbody>
                <tr>
                  <th>Quote Type</th>
                  <td>90+% Furnace</td>
                </tr>
                <tr>
                  <th>Brand Name</th>
                  <td>	Heil</td>
                </tr>
                <tr>
                  <th>Series Name</th>
                  <td>	R410A PAC SPP</td>
                </tr>
                <tr>
                  <th>Outdoor Unit - Model Number</th>
                  <td>PAR524***K***A*</td>
                </tr>
                <tr>
                  <th>System BTU/Capacity</th>
                  <td>40-50k BTU</td>
                </tr>
                <tr>
                  <th>System Physical Size</th>
                  <td>14" Wide</td>
                </tr>
                <tr>
                  <th>Directional</th>
                  <td>	Upflow</td>
                </tr>
                <tr>
                  <th>Minimum Blower Drive</th>
                  <td>	800 CFM</td>
                </tr>
                <tr>
                  <th>Efficiency</th>
                  <td>-</td>
                </tr>
                <tr>
                  <th>Parts Warranty</th>
                  <td>	10</td>
                </tr>
              </tbody>
            </Table>
            </div>
            
            </div>
          </div>
          <div className="card">
            <div className="card-inner">
            <h6 className="title mb-3">90+% Furnace</h6>
            <div className='table-responsive'>
            <Table bordered size="sm">
              <tbody>
                <tr>
                  <th>Quote Type</th>
                  <td>90+% Furnace</td>
                </tr>
                <tr>
                  <th>Brand Name</th>
                  <td>	Heil</td>
                </tr>
                <tr>
                  <th>Series Name</th>
                  <td>R410A PAC SPP</td>
                </tr>
                <tr>
                  <th>Outdoor Unit - Model Number</th>
                  <td>-</td>
                </tr>
                <tr>
                  <th>System BTU/Capacity</th>
                  <td>40-50k BTU</td>
                </tr>
                <tr>
                  <th>System Physical Size</th>
                  <td>14" Wide</td>
                </tr>
                <tr>
                  <th>Directional</th>
                  <td>	Upflow</td>
                </tr>
                <tr>
                  <th>Minimum Blower Drive</th>
                  <td>	800 CFM</td>
                </tr>
                <tr>
                  <th>Efficiency</th>
                  <td>-</td>
                </tr>
                <tr>
                  <th>Parts Warranty</th>
                  <td>	10</td>
                </tr>
              </tbody>
            </Table>
            </div>
            
            </div>
          </div>
        </div>
    </>
  );
}

export default EquipmentList;