import { Outlet } from 'react-router-dom';
import { AddUser, EditUser, ListUser, ViewUser } from '../../views/index.view';
import { getActions } from '../../services/Auth/index.service';
import CreateUser from '../../views/Customers/Create';

export default function route() {
  return [
    {
      path: accessRoute.CUSTOMERS.path,
      private: true,
      name: 'Customers',
      key: "customers",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.CUSTOMERS.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.CUSTOMERS.path,
          name: 'List User',
          key: accessRoute.CUSTOMERS.key,
          belongsToSidebar: false,
          icon: accessRoute.CUSTOMERS.icon,
          element: <ListUser />,
        },
        {
          private: true,
          path: accessRoute.CUSTOMERS_ADD.path,
          name: 'List User',
          key: accessRoute.CUSTOMERS_ADD.key,
          belongsToSidebar: false,
          icon: accessRoute.CUSTOMERS_ADD.icon,
          element: <CreateUser />,
        },
        {
          private: true,
          path: accessRoute.CUSTOMERS_ADD.path,
          name: 'List User',
          key: accessRoute.CUSTOMERS_ADD.path,
          belongsToSidebar: false,
          icon: accessRoute.CUSTOMERS_ADD.icon,
          element: <AddUser />,
        },
        {
          private: true,
          path: accessRoute.CUSTOMERS_EDIT.path,
          name: 'List User',
          key: accessRoute.CUSTOMERS_EDIT.key,
          belongsToSidebar: false,
          icon: accessRoute.CUSTOMERS_EDIT.icon,
          element: <EditUser />,
        },
        {
          private: true,
          path: accessRoute.CUSTOMERS_VIEW.path,
          name: 'List User',
          key: accessRoute.CUSTOMERS_VIEW.key,
          belongsToSidebar: false,
          icon: accessRoute.CUSTOMERS_VIEW.icon,
          element: <ViewUser />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  CUSTOMERS: {
    path: '/admin/customers',
    action: getActions('LIST'),
    key: 'customers.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users-fill" />
      </span>
    ),
  },
  CUSTOMERS_EDIT: {
    path: '/admin/customers/:id',
    action: getActions('EDIT'),
    key: 'customers.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CUSTOMERS_VIEW: {
    path: '/admin/customers/edit/:id',
    action: getActions('VIEW'),
    key: 'customers.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  CUSTOMERS_ADD: {
    path: '/admin/customers/add',
    action: getActions('CREATE'),
    key: 'customers.list',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};
export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
