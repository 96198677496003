import { InputFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const smsTemplatesTableColumn = ({ Action }) => {

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const TextFormatter = (text) => {
    return (
        <>
         <div className='postDescription'>{text}</div>
        </>
    );
};

  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "smsPurpose",
      title: "SMS Purpose",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("sms purpose")
    },
    {
      dataIndex: "smsBody",
      title: "SMS Body",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("sms body"),
      render: (cell, row) => TextFormatter(row?.smsBody),
    },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
