export const scheduledBookingsServices = () => {
    return [
      {
        id: 1,
        bookingNumber: "75833147",
        users: "Fread WEB",
        bookingDate: 'Mon, December 5, 2022 01:26 PM',
        expectedSourceLocation: "Minneapolis, MN, USA",
        specialInstructions: 'test',
        provider: "",
        jobDetails: "",
        status: "",
        sendQuote: "Send Quote($255.00)",
        jobCompletionTime: ""
      },
      {
        id: 2,
        bookingNumber: "75833150",
        users: "Test Lname",
        bookingDate: 'Fri, December 2, 2022 03:00 PM',
        expectedSourceLocation: "Indianapolis, IN, USA 46204",
        specialInstructions: 'test',
        provider: "( Car Type : Cooling System Tune-up)",
        jobDetails: "",
        status: "NAC",
        sendQuote: "",
        jobCompletionTime: ""
      },
      {
        id: 3,
        bookingNumber: "75833147",
        users: "Praveen Joshi",
        bookingDate: 'Thu, December 1, 2022 03:00 PM',
        expectedSourceLocation: "Minneapolis, MN, USA",
        specialInstructions: 'test',
        provider: "( Service Type : Cooling System Tune-up)",
        jobDetails: "view",
        status: "Completed",
        sendQuote: "",
        jobCompletionTime: "12m 47s"
      },
      {
        id: 4,
        bookingNumber: "75833147",
        users: "Fread WEB",
        bookingDate: 'Mon, December 5, 2022 01:26 PM',
        expectedSourceLocation: "Minneapolis, MN, USA",
        specialInstructions: 'test',
        provider: "( Service Type : Recheck 20)",
        jobDetails: "",
        status: "Expired",
        sendQuote: "",
        jobCompletionTime: ""
      },
    ];
  };
  