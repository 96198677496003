import {  AntSelect, CommonButton, DateFilter, InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

const getColumnSelectDateProps = (dataIndex) => ({
  filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});


const getProviderListData = () => {
  return [
    {
      name: 'abhishek Yadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditi Test',
      value: 'Aditi Test',
    },
  ];
};
const getEquipmentInstalledData = () => {
  return [
    {
      name: 'Air Conditioner',
      value: 'Air Conditioner',
    },
    {
      name: '80% Gas Furnace',
      value: '80% Gas Furnace',
    },
  ];
};
const getJobStatusData = () => {
  return [
    {
      name: 'Canceled',
      value: 'Canceled',
    },
    {
      name: 'Finished',
      value: 'Finished',
    },
    {
      name: 'In Progress',
      value: 'In Progress',
    },
  ];
};
const getPermitStatus = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'Needs Attention',      
    },
    {
      name: 'Requested',
      value: 'Requested',
    },
    {
      name: 'Completed',
      value: 'Completed',
    },
    {
      name: 'N/A',
      value: 'NA',
    }
  ];
};
const getWarrantyRegisteredData = () => {
  return [
    {
      name: ' Needs Attention',
      value: ' Needs Attention',
    },
    {
      name: 'Submitted for Processing',
      value: 'Submitted for Processing',
    },
    {
      name: 'Warranty Completed',
      value: 'Warranty Completed',
    },
    {
      name: 'N/A',
      value: 'NA',
    },
  ];
};
const getLaborWarrantyPurchasedData = () => {
    return [
      {
        name: 'No',
        value: 'No',
    },
        {
            name: 'Yes - (1 year) ',
            value: 'Yes - (1 year) ',
        },
        {
            name: 'Yes - (5 year) ',
            value: 'Yes - (5 year) ',
        },
        {
          name: 'Yes - (10 year)',
          value: 'Yes - (10 year)',
      },
      {
        name: 'Yes - (See Invoice)',
        value: 'Yes - (See Invoice)',
    },
    ];
}

const ButtonFormatter = (url, text) => {
    return (
        <>
        <Link to={url} className="">{text}</Link>
        </>
    );
};
const ModalOpenFormatter = ( url , text) => {
    return (
        <>
            <Link to="#" onClick={url} className="text-primary">{text}</Link>
        </>
    );
};
  const PermitStatus = [
    {
      id: ' needsAttention ',
      name: ' Needs Attention ',
    },
    {
      id: ' requested ',
      name: ' Requested ',
    },
    {
      id: 'completed',
      name: 'completed',
    },
  ];
  const WarrantyRegistered = [
    {
        id: ' needsAttention ',
        name: ' Needs Attention ',
      },
      {
        id: 'submittedForProcessing  ',
        name: ' Submitted for Processing  ',
      },
      {
        id: 'warrantyCompleted',
        name: 'Warranty Completed',
      },
  ]
  const LaborWarrantyPurchased= [
    {
        id: ' yes1Year ',
        name: ' Yes - (1 year) ',
      },
      {
        id: 'yes5Year  ',
        name: ' Yes - (5 year)  ',
      },
      {
        id: 'yes10Year',
        name: ' Yes - (10 year) ',
      },
  ]
export const NewEquipmentInstallTableColumn = ({equipmentViewModal, saveUpdates, cancelUpdates}) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'jobNumber',
            title: 'Job No',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ButtonFormatter("/admin/new-equipment/booking-log", "75833147"),
            ...getColumnSearchProps("job number"),
        },
        {
            dataIndex: "jobStatus",
            title: "Job Status",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("job status", getJobStatusData()),
            sorter: true,
        },
        {
          dataIndex: "jobDate",
          title: "Job Date",
          headerClasses: "sorting",
          sorter: true,
          ...getColumnSelectDateProps("date"),
        },
        {
            dataIndex: 'provider',
            title: 'Provider',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnMultiSelectSearchProps("provider name", getProviderListData()),
        },
        {
          dataIndex: "customerName",
          title: "Customer Name",
          headerClasses: "sorting",
          ...getColumnMultiSelectSearchProps("customer name", getProviderListData()),
          sorter: true,
        },
        {
            dataIndex: "customerNumber",
            title: "Customer Phone Number",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer phone number"),
            sorter: true,
        },
        {
            dataIndex: "customerEmail",
            title: "Customer Email",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer email"),
            sorter: true,
        },
        {
            dataIndex: "customerAddress",
            title: "Customer Address",
            headerClasses: "sorting",
            ...getColumnSearchProps("customer address"),
            sorter: true,
        },
        {
            dataIndex: "totalCost",
            title: "Total Cost",
            headerClasses: "sorting",
            sorter: true,
        },
        {
        dataIndex: "equipmentInstalled",
        title: "Equipment Installed",
        headerClasses: "sorting",
        render: (cell, row) => ModalOpenFormatter(equipmentViewModal, row?.equipmentInstalled),
        ...getColumnMultiSelectSearchProps("equipment installed", getEquipmentInstalledData()),
        sorter: true,
        },
        {
            dataIndex: "permitLocation",
            title: "Permit Location",
            headerClasses: "sorting",
            sorter: true,
        },
        {
            dataIndex: "permitStatus",
            title: "Permit Status",
            headerClasses: "sorting",
            sorter: true,
            ...getColumnSelectSearchProps("permit status", getPermitStatus()),
            render:(cell, row)=>{
                console.log("Details", cell)
                return <>
                    <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select status"
                        options={PermitStatus}
                    />
              </>
            }
        },
        {
            dataIndex: "warrantyRegistered",
            title: "Warranty Registered",
            headerClasses: "sorting",
            sorter: true,
            ...getColumnSelectSearchProps("warranty registered", getWarrantyRegisteredData()),
            render:(cell, row)=>{
                console.log("Details", cell)
                return <>
                    <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select options"
                        options={WarrantyRegistered}
                    />
              </>
            }
        },
        {
            dataIndex: "laborWarrantyPurchased",
            title: "Labor Warranty Purchased",
            headerClasses: "sorting",
            sorter: true,
            ...getColumnSelectSearchProps("labor warranty purchased", getLaborWarrantyPurchasedData()),
            render:(cell, row)=>{
                console.log("Details", cell)
                return <>
                    <AntSelect
                        size="medium"
                        id="status"
                        extraClassName="form-control"
                        name="statusId"
                        disabled={false}
                        variant="standard"
                        placeholder="Select options"
                        options={LaborWarrantyPurchased}
                    />
              </>
            }
        },
        {
            dataIndex: "lastUpdatedSave",
            title: "Last Updated/Save",
            render:(cell, row)=>{
                console.log("Details", cell)
                if(cell !== ""){ 
                  return <>{cell} </>
                }else{
                  if(cell=== ""){
                    return <> <CommonButton onClick={ saveUpdates } className="btn btn-primary btn-sm">Save</CommonButton>
                        <CommonButton onClick={cancelUpdates} className="btn btn-secondary btn-sm ms-1">Cancel</CommonButton>
                    </>
                  }
                }
              }

        },
      ];
};
