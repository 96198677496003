import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ImageElement,
  CommonButton,
  ModalComponent,
  FormElementWrapper,
  FormWrapper,
  AntTextArea,
  CheckBox,
  TextInput,
} from '../../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { Dropdown } from 'react-bootstrap';

function GMBPost(props) {
  const onConfirmAlert = () => {
    modalNotification({ type: 'success', message: 'Post Delete Successfully' });
    return true;
  };

  const [learnUrl, setLearnUrl] = useState(false);

  {/* CreatePost Modal */}
  const [createPostModal, setCreatePostModal] = useState(false);
  const createPostModalShow = () => {
    setCreatePostModal(true);
  };
  const createPostModalHide = () => {
    setCreatePostModal(false);
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '#',
      name: 'Google Post List',
    },
  ];
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  

  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/google-gmb')
  } 
  return (
    <div className="wide-md mx-auto reviewSec">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Google Post List">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'extraButton']}
            btnText="Create Post"
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onHandleShow={createPostModalShow}
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
            <div className="row">
              {/* Post List Col */}
                <div className="col-lg-7 mx-auto">
                    <div className=" card card-bordered card-preview reviewSec_list">
                      <ImageElement
                        source="product-image.jpg"
                        className="img-fluid"
                        alt="google-post-list-photo"
                      />
                      <div className="card-inner p-3 p-3">
                          <p className="card-text">New post: HVAC SERVICES- Winter Care for Your AC - Without proper protection during winter, your air conditioner can get easily damaged. You may have to spend a lot of money on repairs or untimely replacements if you don’t look after your unit. To learn how to properly care for your AC during winter, read on!   Winter Care for Your AC 1. RUN YOUR AC IN THE WINTER, EVEN IF IT’S JUST FOR A FEW MINUTES EVERY FEW WEEKS. You might be wondering if it’s safe and sensible to run your AC in the winter. But doing so comes with many benefits. Aside from helping maintain comfortable temperatures inside your home, running your air conditioner in frigid temperatures can keep oil circulating throughout it and prevent refrigerant leaks.   This also ensures you won’t have to wait until the warmer months to recognize any problem with your AC.      But operate your air conditioner only when the temperature outdoors is 60F and above. Continually running your unit below this temperature may cause the AC coil to actually freeze. 2. USE PROTECTIVE COVERING. Even if your AC is built to withstand harsh temperatures, snow and ice buildup can still cause damage to the components of your outdoor HVAC system.   Your AC may need an extra layer of protection: covers made only with breathable material that won’t restrict airflow. (Any type of plastic may result in rusting and internal moisture damage.)   Already covered your unit? Always be on the lookout for rodents and pests may nest ins...</p>
                          </div>
                          <div className="card-footer border-top d-flex justify-content-between">
                          <CommonButton className="btn btn-primary">Learn more</CommonButton>
                          <Dropdown
                            className="position-static" >
                            <Dropdown.Toggle as="a" className="btn btn-icon btn-trigger">
                              <em className="icon ni ni-more-v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" size="sm" className="wide-xs">
                              <ul className="link-list-plain"><li><Link to="" onClick={() => setIsAlertVisible(true)}>Delete</Link></li></ul>
                            </Dropdown.Menu>
                          </Dropdown>
                          </div>
                    </div>
                </div>
                {/* Post List Col */}
                <div className="col-lg-7 mx-auto">
                    <div className=" card card-bordered card-preview reviewSec_list">
                      <ImageElement
                        source="product-image.jpg"
                        className="img-fluid"
                        alt="google-post-list-photo"
                      />
                      <div className="card-inner p-3">
                          <p className="card-text">New post: HVAC SERVICES- Winter Care for Your AC - Without proper protection during winter, your air conditioner can get easily damaged. You may have to spend a lot of money on repairs or untimely replacements if you don’t look after your unit. To learn how to properly care for your AC during winter, read on!   Winter Care for Your AC 1. RUN YOUR AC IN THE WINTER, EVEN IF IT’S JUST FOR A FEW MINUTES EVERY FEW WEEKS. You might be wondering if it’s safe and sensible to run your AC in the winter. But doing so comes with many benefits. Aside from helping maintain comfortable temperatures inside your home, running your air conditioner in frigid temperatures can keep oil circulating throughout it and prevent refrigerant leaks.   This also ensures you won’t have to wait until the warmer months to recognize any problem with your AC.      But operate your air conditioner only when the temperature outdoors is 60F and above. Continually running your unit below this temperature may cause the AC coil to actually freeze. 2. USE PROTECTIVE COVERING. Even if your AC is built to withstand harsh temperatures, snow and ice buildup can still cause damage to the components of your outdoor HVAC system.   Your AC may need an extra layer of protection: covers made only with breathable material that won’t restrict airflow. (Any type of plastic may result in rusting and internal moisture damage.)   Already covered your unit? Always be on the lookout for rodents and pests may nest ins...</p>
                          </div>
                          <div className="card-footer border-top d-flex justify-content-between">
                          <CommonButton className="btn btn-primary">Learn more</CommonButton>
                          <Dropdown
                            className="position-static" >
                            <Dropdown.Toggle as="a" className="btn btn-icon btn-trigger">
                              <em className="icon ni ni-more-v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" size="sm" className="wide-xs">
                              <ul className="link-list-plain"><li><Link to="" onClick={() => setIsAlertVisible(true)}>Delete</Link></li></ul>
                            </Dropdown.Menu>
                          </Dropdown>
                          </div>
                    </div>
                </div>{/* Post List Col */}
                <div className="col-lg-7 mx-auto">
                    <div className=" card card-bordered card-preview reviewSec_list">
                      <ImageElement
                        source="product-image.jpg"
                        className="img-fluid"
                        alt="google-post-list-photo"
                      />
                      <div className="card-inner p-3">
                          <p className="card-text">New post: HVAC SERVICES- Winter Care for Your AC - Without proper protection during winter, your air conditioner can get easily damaged. You may have to spend a lot of money on repairs or untimely replacements if you don’t look after your unit. To learn how to properly care for your AC during winter, read on!   Winter Care for Your AC 1. RUN YOUR AC IN THE WINTER, EVEN IF IT’S JUST FOR A FEW MINUTES EVERY FEW WEEKS. You might be wondering if it’s safe and sensible to run your AC in the winter. But doing so comes with many benefits. Aside from helping maintain comfortable temperatures inside your home, running your air conditioner in frigid temperatures can keep oil circulating throughout it and prevent refrigerant leaks.   This also ensures you won’t have to wait until the warmer months to recognize any problem with your AC.      But operate your air conditioner only when the temperature outdoors is 60F and above. Continually running your unit below this temperature may cause the AC coil to actually freeze. 2. USE PROTECTIVE COVERING. Even if your AC is built to withstand harsh temperatures, snow and ice buildup can still cause damage to the components of your outdoor HVAC system.   Your AC may need an extra layer of protection: covers made only with breathable material that won’t restrict airflow. (Any type of plastic may result in rusting and internal moisture damage.)   Already covered your unit? Always be on the lookout for rodents and pests may nest ins...</p>
                          </div>
                          <div className="card-footer border-top d-flex justify-content-between">
                          <CommonButton className="btn btn-primary">Learn more</CommonButton>
                          <Dropdown
                            className="position-static" >
                            <Dropdown.Toggle as="a" className="btn btn-icon btn-trigger">
                              <em className="icon ni ni-more-v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" size="sm" className="wide-xs">
                              <ul className="link-list-plain"><li><Link to="" onClick={() => setIsAlertVisible(true)}>Delete</Link></li></ul>
                            </Dropdown.Menu>
                          </Dropdown>
                          </div>
                    </div>
                </div>
                {/* Post List Col */}
                <div className="col-lg-7 mx-auto">
                    <div className=" card card-bordered card-preview reviewSec_list">
                      <ImageElement
                        source="product-image.jpg"
                        className="img-fluid"
                        alt="google-post-list-photo"
                      />
                      <div className="card-inner p-3">
                          <p className="card-text">New post: HVAC SERVICES- Winter Care for Your AC - Without proper protection during winter, your air conditioner can get easily damaged. You may have to spend a lot of money on repairs or untimely replacements if you don’t look after your unit. To learn how to properly care for your AC during winter, read on!   Winter Care for Your AC 1. RUN YOUR AC IN THE WINTER, EVEN IF IT’S JUST FOR A FEW MINUTES EVERY FEW WEEKS. You might be wondering if it’s safe and sensible to run your AC in the winter. But doing so comes with many benefits. Aside from helping maintain comfortable temperatures inside your home, running your air conditioner in frigid temperatures can keep oil circulating throughout it and prevent refrigerant leaks.   This also ensures you won’t have to wait until the warmer months to recognize any problem with your AC.      But operate your air conditioner only when the temperature outdoors is 60F and above. Continually running your unit below this temperature may cause the AC coil to actually freeze. 2. USE PROTECTIVE COVERING. Even if your AC is built to withstand harsh temperatures, snow and ice buildup can still cause damage to the components of your outdoor HVAC system.   Your AC may need an extra layer of protection: covers made only with breathable material that won’t restrict airflow. (Any type of plastic may result in rusting and internal moisture damage.)   Already covered your unit? Always be on the lookout for rodents and pests may nest ins...</p>
                          </div>
                          <div className="card-footer border-top d-flex justify-content-between">
                          <CommonButton className="btn btn-primary">Learn more</CommonButton>
                          <Dropdown
                            className="position-static" >
                            <Dropdown.Toggle as="a" className="btn btn-icon btn-trigger">
                              <em className="icon ni ni-more-v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" size="sm" className="wide-xs">
                              <ul className="link-list-plain"><li><Link to="" onClick={() => setIsAlertVisible(true)}>Delete</Link></li></ul>
                            </Dropdown.Menu>
                          </Dropdown>
                          </div>
                    </div>
                  <CommonButton className="btn btn-primary float-end">Next </CommonButton>
                </div>
            </div>
          </div>
          
        </div>
        
      </div>

      <SweetAlert
        title="Are you sure"
        text="you want to delete this post?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />

       {/* CreatePost Modal */}
       <ModalComponent
              backdrop
              show={createPostModal}
              onHandleCancel={createPostModalHide}
              title="Create Post" extraClassName="modal-md"
            >
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={12}
                label="Add Photo"
                
                element={(formik) => (
                  <Dragger>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                )}
              />
                <FormElementWrapper
                    md={12}
                    label="Write your post"
                    mandatory="true"
                    element={(formik) => (
                    <AntTextArea
                        name="description"
                        className="form-control"
                        placeholder="Enter post"
                    />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    element={(formik) => (
                    <CheckBox
                        name="agree"
                        element={[
                        'Want to add learn more URL?',
                        ]}
                        onChange={(e) => setLearnUrl(!learnUrl)}
                    />
                    )}
                />
                {
                    learnUrl &&
                    <FormElementWrapper
                        md={12}
                        label="Learn More URL"
                        mandatory="true"
                        element={(formik) => (
                        <TextInput
                            name="email"
                            inputType="text"
                            placeholder="Enter URL"
                            formik={formik}
                        />
                        )}
                    />
                }
                <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                    <>
                        <CommonButton
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => createPostModalHide()}
                        >
                        Publish
                        </CommonButton>
                       
                    </>
                    )}
                />  
            </FormWrapper>  
                  
      </ModalComponent>
    </div>
  );
}

export default GMBPost;
