import CryptoJS from 'crypto-js';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { APP_KEY, LANGUAGE, APP_DATE_FORMAT } from '../config';

const getItem = (path, label, key, icon, children, submenu) => {
  if (children) {
    return { label, key, icon, children, path, submenu };
  }
  return { label, key, icon, path, submenu };
};

export const getHeaderData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToHeader) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToHeader) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ''
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const getFooterLink = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToFooter) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToFooter) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ''
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const removeSessionStorageToken = () => {
  if (sessionStorage.getItem(`${APP_KEY}:token`)) {
    sessionStorage.setItem(`${APP_KEY}:token`, null);
  }
};

export const setSessionStorageToken = (token) => {
  sessionStorage.setItem(
    `${APP_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${APP_KEY}-token`).toString()
  );
};

export const removeLocalStorageToken = (navigate) => {
  if (localStorage.getItem(`${APP_KEY}:token`)) {
    localStorage.setItem(`${APP_KEY}:token`, null);
  }
  if (navigate) {
    navigate('/');
  }
};

export const getSessionStorageToken = () => {
  const ciphertext = sessionStorage.getItem(`${APP_KEY}:token`);
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, `${APP_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${APP_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${APP_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${APP_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${APP_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${APP_KEY}:language`);
  if (language) {
    return ['en', 'hi'].includes(language) ? language : LANGUAGE;
  }
  return LANGUAGE;
};

export const getCompleteUrl = (url) => {
  return url;
};

export function decodeQueryData(data) {
  return JSON.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace('?', '')}"}`
  );
}

export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function getSortType(data) {
  return data === 'ASC' ? 'asc' : 'desc';
}

export function dateFormatter(params, format) {
  return moment(params).format(format ?? APP_DATE_FORMAT);
}

export function filterDateFormatter(param, format) {
  return moment(param).format(format);
}

export function momentDateFormatter(param, format) {
  return moment(param, format);
}

export function readMoreTextShow(data, showMoreText) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data.length < 50) {
    return <>{data}</>;
  }

  return (
    <p className="mb-0">
      {data.substring(0, 50)}...
      {showMoreText ? (
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            showMoreText({ data });
          }}
        >
          Read More
          {/* {t("common.readMore")} */}
        </Link>
      ) : (
        ''
      )}
    </p>
  );
}

export function PhoneNumber({ countryCode, contactNumber }) {
  if (countryCode && contactNumber) {
    return <>{`${countryCode}-${contactNumber}`}</>;
  }
  return <span className="center">-</span>;
}

export function otpRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}

export function phoneRegex() {
  let regex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return regex;
}

export function getSideBarData(arr) {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    '',
                    currentSubChild?.submenu
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(
              item?.path,
              item?.name,
              item?.key,
              item?.icon,
              children,
              item.submenu
            )
          );
        } else {
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, item.submenu)
          );
        }
      }
      console.log('Pre', prev);
      return prev;
    }, []);
  }
  return [];
}
