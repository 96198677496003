import React from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
    Breadcrumb,
    PageHeader,
    ImageElement,
    ListingHeader,
  } from "../../../components";
  import {
    Columns,
     HomeInventoryServices,
  } from "../../../services/index.service";
  
  
  function ImagesInventory(props) {
    const HomeInventoryData = HomeInventoryServices();
    const columns = Columns.HomeInventoryTableColumn();
  
    const breadcrumb = [
      {
        path: "/admin/dashboard",
        name: "Dashboard",
        
      },
      {
        path: "/admin/home-inventory",
        name: "Home Inventory",
      },
      {
        path: "#",
        name: "Installed Equipment Image",
      },
    ];
  
    const navigate = useNavigate()
    return (
        <>
        <div className='wide-md mx-auto'>
          <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Installed Equipment Image">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/home-inventory')}
          />
        </div>
      </div>
      <div className="nk-block">
      <div className='card'>
            <div className='card-inner'>
              <Image.PreviewGroup>
                
              <h6 className="mb-3">80% Gas Furnace</h6>
                <div className='row g-3 g-xl-4'>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                        </div>
                    </div>
                </div>
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
        </div>
        </>
      );
}

export default ImagesInventory;
