import { Button } from 'react-bootstrap';

function ImportButton({
  children,
  extraClassName = '',
  onFileHandleShow,
  ...rest
}) {
  return (
    <label
      className={`${extraClassName}`}
      {...rest}
    >
      <input onChange={ onFileHandleShow } className='d-none' type="file"/>
      {children} 
    </label>
  );
}
export default ImportButton;
