import { SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const NCAPaymentReportTableColumn = () => {
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getPaymentData = () => {
    return [
      {
        name: 'Cash',
        value: 'Cash',
      },
      {
        name: 'Card',
        value: 'Card',
      },
      {
        name: 'Financing',
        value: 'Financing',
      },
      {
        name: 'Check',
        value: 'Check',
      },
    ];
  };
  const getPaymentstatus = () => {
    return [
      {
        name: 'Pending',
        value: 'Pending',
      },
      {
        name: 'Complete',
        value: 'Complete',
      },
    ];
  };
  const getPaymentSettleStatus = () => {
    return [
      {
        name: 'Unsettled',
        value: 'Unsettled',
      },
      {
        name: 'Settled',
        value: 'Settled',
      },
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "jobId",
      title: "Job ID",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "typeofpayment",
      title: " Type of payment",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("payment type", getPaymentData()),
    },
    {
        dataIndex: "amountCollected",
        title: "Amount Collected",
        headerClasses: "sorting",
        sorter: true,
      },
      {
        dataIndex: "transactionFee",
        title: "Transaction Fee",
        headerClasses: "sorting",
        sorter: true,
      },
      {
        dataIndex: "totalPayAmount",
        title: "Total Pay Amount",
        headerClasses: "sorting",
        sorter: true,
      },
      {
        dataIndex: "paymentStatus",
        title: "Payment Status",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectSearchProps("payment status", getPaymentstatus()),
      },
      {
        dataIndex: "paymentSettleStatus",
        title: "Payment Settle Status",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectSearchProps("payment settle status", getPaymentSettleStatus()),
      },
      {
        dataIndex: "selectAll",
        title: "Select All",
        headerClasses: "sorting",
        sorter: true,
      },
      {
        dataIndex: "paymentTransferStatus",
        title: "Payment Transfer Status",
        headerClasses: "sorting",
        sorter: true,
      },
  ];
};
