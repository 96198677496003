import {   InputFilter,  SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";


const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});


  const adminLogoFormatter = (path) => {
    return (
      <>
        <div className="user-card">
            <div className="user-avatar bg-light">
                <img src={path} alt="img" />
            </div>
        </div>
      </>
    );
  };

const getStatusData = () => {
  return [
    {
        id: 'All',
        name: 'All ',
      },
    {
        id: 'On',
        name: 'On ',
      },
      {
        id: 'Off',
        name: 'Off',
      }
  ];
};


export const WillCallListTableColumn = () => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'icon',
            title: 'Icon',
            render: (cell, row) => adminLogoFormatter(row?.icon),    
        },
        {
            dataIndex: 'categoryName',
            title: 'Category Name',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'subCategoryName',
            title: 'Sub Category Name',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSearchProps("sub category name"),
        },
        {
            dataIndex: 'locations',
            title: 'Locations',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'willCallList',
            title: 'Will Call List',
            headerClasses: 'sorting',
            render:()=><Toggle/>,
            ...getColumnSelectSearchProps("status", getStatusData())
        },
    ];
};
