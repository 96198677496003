import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  GeoLocationFilterForm,
  TablePlus,
} from '../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import {
  Columns,
  geoLocationService,
} from '../../../../services/index.service';
import { modalNotification } from '../../../../utils';

function ListGeoLocation(props) {
  const navigate = useNavigate();

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/manage-location',
      name: 'Geo Fence Locations',
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({
      type: 'success',
      message: 'Locations Deleted Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.geoLocationTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: 'Edit',
          icon: 'icon ni ni-edit',
          handler: EditLocationPage,
        },
        {
          name: 'Delete',
          icon: 'icon ni ni-trash',
          handler: () => {
            setIsAlertVisible(true);
            document.body.click();
          },
        },
      ]),
  });

  const customerData = geoLocationService();
  const AddLocationPage = () => {
    navigate('/admin/manage-location/add');
  };
  const EditLocationPage = () => {
    navigate('/admin/manage-location/edit')
  }
  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
  ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Geo Fence Locations">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'create']}
            btnText="Add Location"
            // popover={<GeoLocationFilterForm />}
            onHandleShow={AddLocationPage}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this locations"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
    </>
  );
}

export default ListGeoLocation;
