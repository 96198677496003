import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  TablePlus,
  InputFilter,
  MultiSelectFilter,
  AmountFilter,
  ModalComponent,
  CommonButton,
} from '../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListLineItem(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Line Items Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  
    
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Line Items',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
    
  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getNameData = () => {
    return [
      {
        name: '	All',
        value: '	All',
      },
      {
        name: '	Material - 1 Roll UL Silver Tape3',
        value: '	Material - 1 Roll UL Silver Tape3',
      },
      {
        name: 'Material - 1/2" CPVC 90 ELBOW',
        value: 'Material - 1/2" CPVC 90 ELBOW',
      },
    ];
  }
  const getPartNumberData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Material - 20 Feet 2 Inch PVC ',
        value: 'Material - 20 Feet 2 Inch PVC ',
      },
      {
        name: 'Material - 3 Inch to 2 Inch Reducer PVC',
        value: 'Material - 3 Inch to 2 Inch Reducer PVC',
      },
    ];
  }
  const getCustomerTagData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Air Quality',
        value: 'Air Quality',
      },
      {
        name: 'Cooling',
        value: 'Cooling',
      },
      {
        name: 'Electrician',
        value: 'Electrician',
      },
      {
        name: 'Heating System',
        value: 'Heating System',
      },
      {
        name: 'Home Warranty',
        value: 'Home Warranty',
      },
      {
        name: 'Humidifier',
        value: 'Humidifier',
      },
    ];
  }
  const getJobTagData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Generator',
        value: 'Generator',
      },
      {
        name: 'Heating Tune-Up',
        value: 'Heating Tune-Up',
      },
      {
        name: 'Air Purifier',
        value: 'Air Purifier',
      },
      {
        name: 'Humidifier J',
        value: 'Humidifier J',
      },
      {
        name: 'Qwerty1',
        value: 'Qwerty1',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'name',
      title: '	Name',
      sorter: true,
      ...getColumnMultiSelectSearchProps("name",getNameData()),
    },
    {
      dataIndex: 'price',
      title: 'Price ',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
      ...getColumnSearchProps("description")
    },
    {
      dataIndex: 'partNumber',
      title: 'Part Number',
      sorter: true,
      ...getColumnMultiSelectSearchProps("part number",getPartNumberData()),
    },
    {
      dataIndex: 'partCost',
      title: 'Part Cost',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'customerTag',
      title: 'Customer Tag',
      sorter: true,
      ...getColumnMultiSelectSearchProps("customer tag",getCustomerTagData()),
    },
    {
      dataIndex: 'jobTag',
      title: 'Job Tag',
      sorter: true,
      ...getColumnMultiSelectSearchProps("job tag",getJobTagData()),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditLineItem()},
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      name: '	Material - 1 Roll UL Silver Tape3',
      price: '$0.00',
      description: 'Material Used',
      partNumber: 'Material - 1 Roll UL Silver Tape - 552',
      partCost: '$40.00',
      customerTag: 'Home Warranty',
      jobTag: 'Heating Tune-Up'
    },
    {
      id: 2,
      name: '	Material - 1/2" CPVC 90 ELBOW',
      price: '$0.00',
      description: 'Material Used',
      partNumber: 'Material - 1/2" CPVC 90 ELBOW -',
      partCost: '$43.00',
      customerTag: '-',
      jobTag: '-'
    },
    {
      id: 3,
      name: '		Material - 1/2" CPVC Tee',
      price: '$1.00',
      description: 'Material Used',
      partNumber: 'Material - 1/2" CPVC Tee -',
      partCost: '$1.00',
      customerTag: 'Home Warranty',
      jobTag: 'Heating Tune-Up'
    },
    {
      id: 4,
      name: '	Material - 10 Feet 3 Inch PVC',
      price: '$0.00',
      description: 'Material Used',
      partNumber: '	Material - 10 Feet 3 Inch PVC -',
      partCost: '$30.00',
      customerTag: 'Home Warranty',
      jobTag: 'Heating Tune-Up'
    },
  ];

  const recordsColumns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'name',
      title: '	Name',
    },
    {
      dataIndex: 'price',
      title: '	Price',
    },
    {
      dataIndex: 'description',
      title: 'Description ',
    },
    {
      dataIndex: 'partNumber',
      title: 'Part Number',
    },
    {
      dataIndex: 'customerTag',
      title: 'Customer Tag',
    },
    {
      dataIndex: 'jobTag',
      title: 'Job Tag',
    },
    {
      dataIndex: 'partCost',
      title: 'Part Cost',
    },
  ];
  const recordsData = [
    {
      id: 1,
      name: 'Cordles Drill',
      price: '$21.00',
      description: 'Material Used',
      partNumber: 'Material - 1 Roll UL Silver Tape - 552',
      customerTag: 'Home Warranty',
      jobTag: 'Heating Tune-Up',
      partCost: '$40.00',
    },
    {
      id: 2,
      name: 'INHVAC - Clean Blower Wheel',
      price: '$50.00',
      description: 'Dirty blower',
      partNumber: '-',
      customerTag: '-',
      jobTag: 'Heating Tune-Up',
      partCost: '$50.00',
    },
  ];

  const navigate = useNavigate();
  const AddLineItem = () =>{
    navigate('/admin/line-items/add')
  }
  const EditLineItem = () =>{
    navigate('/admin/line-items/edit')
  }
  const [lineItemsModalRecord, setlineItemsModalRecord] = useState(false);
  const lineItemsModalRecordHide = () =>{
    setlineItemsModalRecord(false)
  }
  const lineItemsModalRecordShow = () =>{
    setlineItemsModalRecord(true);
  }
  const [ lineItemsRecord, setLineItemsRecord ] = useState(false);
  const lineItemsRecordOpen =() => {
    setLineItemsRecord(true);
    lineItemsModalRecordHide();
  }
  const lineItemsRecordClose = () => {
    setLineItemsRecord(false);
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Line Items">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport', 'fileImport']}
            btnText="Add Line Items"
            onHandleShow={AddLineItem}
            onFileHandleShow={ lineItemsModalRecordShow }
          />
        </div>
      </div>
      {
        lineItemsRecord && 
        <div className='mb-3'>
          <div className='card mb-2'>
            <div className='card-inner'>
              <div className='row align-items-center'>
                <div className='col-xxl-7'>
                  <ul className='d-md-flex justify-content-between'>
                    <li className='color-upload me-3 me-lg-4 me-xxl-5'>Total Record Upload  :  <b>565</b></li>
                    <li className='color-update me-3 me-lg-4 me-xxl-5'>Record to Update :  <b>0</b></li>
                    <li className='color-insert me-3 me-lg-4 me-xxl-5'>Record to Insert  :  <b>5</b></li>
                  </ul>
                </div>
                <div className='col-xxl-5 text-xxl-end mt-2 mt-lg-3 mt-xxl-0'>
                  <CommonButton className="btn btn-primary me-2 mt-1 mt-sm-0">Save uploaded data</CommonButton>
                  <CommonButton className="btn btn-secondary mt-1 mt-sm-0" onClick = { () => lineItemsRecordClose() } >Discard & Back</CommonButton>
                </div>
              </div>
            </div>
          </div>
          <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={recordsData}
            tableColumns={recordsColumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}
            isResetBtn= {false}
            showAction={false}
            showTableCountNumber={false}
          />
        </div>
      }
      
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Line Items"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={lineItemsModalRecord}
        onHandleCancel={lineItemsModalRecordHide}
        title="Import Records"
        size="sm"
      >
        <ul className='list list-sm list-checked'>
          <li>Total Records :  <b>565</b></li>
          <li>Record Inserted :  <b>0</b></li>
          <li>Record Updated :  <b>5</b></li>
        </ul>
        <div className='text-end'>
          <CommonButton className="btn btn-primary" onClick ={ () => lineItemsRecordOpen() }>Ok</CommonButton>
        </div>
      </ModalComponent>  
    </>
  );
}

export default ListLineItem;
