import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AcountSidebar,
  // Input,
  ModalComponent,
  PageHeader,
  TextInput as Input,
} from '../../../components';

export default function AdminProfile() {
  const [asideView, setAsideView] = useState(false);
  const asideToggle = () => {
    setAsideView(!asideView);
  };
  if (asideView) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const [showEdit, setShowEdit] = useState(false);
  const editProfileModal = () => {
    setShowEdit(true);
  };
  const closeEditProfileModal = () => {
    setShowEdit(false);
  };
  return (
    <>
      {/* <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
            <PageHeader heading="Profile">Profile</PageHeader>
        </div>      
        </div> */}
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <PageHeader heading="Personal Information">
                    <p>
                      Basic info, like your name and address, that you use on
                      Nio Platform.
                    </p>
                  </PageHeader>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <Link
                      to="#"
                      onClick={() => asideToggle()}
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        asideView ? 'active' : ''
                      }`}
                    >
                      <em className="icon ni ni-menu-alt-r" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Basics</h6>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Full Name</span>
                      <span className="data-value">Abu Bin Ishtiyak</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-user-alt" />
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Display Name</span>
                      <span className="data-value">Ishtiyak</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-user-alt" />
                      </span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Email</span>
                      <span className="data-value">info@goodguys.com</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more disable">
                        <em className="icon ni ni-lock-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AcountSidebar
              asideView={asideView}
              asideToggle={asideToggle}
              editProfileModal={editProfileModal}
            />
          </div>
        </div>
      </div>
      <ModalComponent
        onHandleCancel={() => {
          setShowEdit(false);
        }}
        backdrop
        show={showEdit}
        title="Update Profile"
      >
        <form>
          <div className="g-3 row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  Full Name
                </label>
                <Input
                  id="full-name"
                  className="form-control"
                  name="fullName"
                  disabled={false}
                  variant="standard"
                  type="text"
                  placeholder="Enter full name"
                  value="Abu Bin Ishtiyak"
                  icon=""
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="display-name">
                  Display Name
                </label>
                <Input
                  id="display-name"
                  className="form-control"
                  name="displayName"
                  disabled={false}
                  variant="standard"
                  type="text"
                  placeholder="Enter display name"
                  value="Ishtiyak"
                  icon=""
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <Input
                  id="email"
                  className="form-control"
                  name="email"
                  disabled
                  variant="standard"
                  type="email"
                  placeholder="Enter email"
                  value="info@goodguys.com"
                  icon=""
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-end">
                <Link
                  href="#"
                  onClick={() => closeEditProfileModal()}
                  className="btn btn-primary me-2"
                >
                  Update
                </Link>
                <Link
                    href="#"
                    onClick={() => closeEditProfileModal()}
                    className="btn btn-light"
                  >
                    Cancel
                  </Link>
              </div>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}
