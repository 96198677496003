import React from 'react';
import { PageHeader, Breadcrumb, ListingHeader } from '../../';
import PropTypes from 'prop-types';
/**
 *
 */
function PageContainer({
  children,
  breadcrumb,
  btnArray,
  handleFilterShow,
  form,
  addBtnText,
  heading,
}) {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading={heading}>
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={btnArray}
            btnText={addBtnText}
            onHandleShow={handleFilterShow}
            popover={form}
          />
        </div>
      </div>
      {children}
    </>
  );
}

PageContainer.propTypes = {
  btnArray: PropTypes.array,
  heading: PropTypes.string,
  handleFilterShow: PropTypes.func,
  addBtnText: PropTypes.string,
};

export default PageContainer;
