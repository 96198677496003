import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  ActionWrapper,
  SweetAlert,
  TablePlus,
} from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { modalNotification } from "../../../utils";

function ViewUser(props) {
  const onConfirmAlert = () => {
    modalNotification({ type: "success", message: " Customer address Deleted successfully", })
    return true;
  };
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/customers",
      name: "Customers",
    },
    {
      path: "#",
      name: "Customer Address",
    },
  ];

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const Action = () => actionFormatter([
    {
      name: "Edit",
      icon: "icon ni ni-edit",
      handler: () =>{editUserAddressShow()},
      // path: "/admin/dashboard"
      // type:"redirect",
      //   handler:()=>{ setIsAlertVisible(true);
      //     document.body.click()}
    },
  ])

  const columns = [
    {
      dataIndex: "id",
      title: "Id",
      headerClasses: "w_70",
    },
    {
      dataIndex: "name",
      title: "Name",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "address",
      title: "Address",
      headerClasses: "sorting",
      sorter: true,
    },
    
    {
      dataIndex: "state",
      title: "State",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "city",
      title: "City",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "zipCode",
      title: "ZipCode",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "Action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
  const customerData = [
    {
      id: 1,
      name: "joe testing",
      address: "19000 Grand Park Blvd, Westfield, IN 46074, USA",
      state: "United States	",
      city: "Westfield",
      zipCode : "46074"
    },
    // {
    //   id: 2,
    //   name: "	User",
    //   address: "19000 Grand Park Blvd, Westfield, IN 46074, USA",
    //   state: "Indian",
    //   city: "Westfield",
    //   zipCode : "46074"
    // },
    // {
    //   id: 3,
    //   name: "Deepak Yadav",
    //   address: "19000 Grand Park Blvd, Westfield, IN 46074, USA",
    //   state: "United States	",
    //   city: "Westfield",
    //   zipCode : "46074"
    // },
    // {
    //   id: 4,
    //   name: "vinod customer",
    //   address: "19000 Grand Park Blvd, Westfield, IN 46074, USA",
    //   state: "United States	",
    //   city: "Westfield",
    //   zipCode : "46074"
    // }
  ];
  const [addUserAddress, setAddUserAddress] = useState(false);
  const addUserAddressHide = () =>{
    setAddUserAddress(false)
  }
  const addUserAddressShow = () =>{
    setAddUserAddress(true)
  }
  const [editUserAddress, setEditUserAddress] = useState(false);
  const editUserAddressHide = () =>{
    setEditUserAddress(false)
  }
  const editUserAddressShow = () =>{
    setEditUserAddress(true)
  }
  const navigate = useNavigate()
  const backPage = () =>{
    navigate('/admin/customers')
  }
  const [isAlertVisible, setIsAlertVisible ] = useState(false);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="John Deo Address">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create", "extraButton"]}
            btnText="Add Customer Address"
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
            onHandleShow={addUserAddressShow}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        isResetBtn= {false}
        showAction={false}
        showTableCountNumber={false}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Customer address"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addUserAddress}
        onHandleCancel={addUserAddressHide}
        title=" Add Customer Address"
      >
      <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={12}
            label="Address Line 1"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="AddressLine1"
                placeholder="Enter address line 1"
                formik={formik}
              />
            )}
          />          
          <h6 className="title mb-0">Service Address</h6>
          <FormElementWrapper
            md={6}
            label="Name"
            element={(formik) => (
              <TextInput
                name="name"
                placeholder="Enter name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Address Line 2"
            element={(formik) => (
              <TextInput
                name="AddressLine2"
                placeholder="Enter address line 2"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Suite Number"
            element={(formik) => (
              <TextInput
                name="suiteNumber"
                placeholder="Enter suite number"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="State"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="state"
                placeholder="Enter state"
                formik={formik}
              />
            )}
          />  
          <FormElementWrapper
            md={6}
            label="City"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="city"
                placeholder="Enter city"
                formik={formik}
              />
            )}
          />  
          <FormElementWrapper
            md={6}
            label="Zip Code"
            element={(formik) => (
              <TextInput
                name="zipCode"
                placeholder="Enter zip code"
                formik={formik}
              />
            )}
          />        
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addUserAddressHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addUserAddressHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>   
      <ModalComponent
        backdrop
        show={editUserAddress}
        onHandleCancel={editUserAddressHide}
        title="Update Address"
      >
      <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={12}
            label="Address Line 1"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="addressLine1"
                placeholder="Enter address line 1"
                formik={formik}
              />
            )}
          />          
          <h6 className="title mb-0">Service Address</h6>
          <FormElementWrapper
            md={6}
            label="Name"
            element={(formik) => (
              <TextInput
                name="name"
                placeholder="Enter name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Address Line 2"
            element={(formik) => (
              <TextInput
                name="addressLine2"
                placeholder="Enter address line 2"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Suite Number"
            element={(formik) => (
              <TextInput
                name="SuiteNumber"
                placeholder="Enter suite number"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="State"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="state"
                placeholder="Enter state"
                formik={formik}
              />
            )}
          />  
          <FormElementWrapper
            md={6}
            label="City"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="city"
                placeholder="Enter city"
                formik={formik}
              />
            )}
          />  
          <FormElementWrapper
            md={6}
            label="Zip Code"
            element={(formik) => (
              <TextInput
                name="zipCode"
                placeholder="Enter zip code"
                formik={formik}
              />
            )}
          />        
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => editUserAddressHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editUserAddressHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>    
    </>
  );
}

export default ViewUser;
