import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Pagination from '../Pagination';
import { useTranslation } from 'react-i18next';
import { navigateWithParam } from '../../../utils';
import { GlobalLoader } from '../index.element';
import { AntTooltip } from '../../';

function DataTable(props) {
  const {
    pagination = true,
    hasLimit,
    handleLimitChange,
    noOfPage,
    sizePerPage,
    page,
    count,
    tableReset,
    tableData,
    tableColumns,
    param,
    // search="",
    onPageChange,
    defaultSort,
    header = true,
    bordered = false,
    setSizePerPage,
    tableLoader = false,
    getSearchValue,
    searchPlaceholder = '',
    isCard = true,
    selectRow = false,
  } = props;
  const t = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(useLocation()?.search);
  const navigate = useNavigate();
  const { pathname } = location;
  const [search, setSearch] = useState(query.get('search') || '');
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // const  =

  // useEffect(() => {
  //   const element = document.getElementsByClassName("datatable-wrap");
  //   element.forEach((item) => {
  //     item.classList.remove("react-bootstrap-table");
  //   });
  //   // element.classList.remove("react-bootstrap-table");
  // }, [tableData]);

  const goToPage = (pageNo) => {
    const newParams = { ...param };
    if (pageNo) {
      newParams.page = pageNo;
      searchParams.set('page', pageNo);
    }
    setSearchParams(searchParams);
    // tableReset();
  };

  // const queries = {}
  // for(const [key, value] of query.entries()){
  //   queries =
  // }

  const handleSelect = (e) => {
    // const newParams = { ...param, page:1, pageSize:e.target.value };
    searchParams.set('page', 1);
    searchParams.set('pageSize', e.target.value);
    setSearchParams(searchParams);
    //  navigateWithParam(newParams, navigate, pathname);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if ((search.length >= 3 || search.length === 0) && firstTimeFetch) {
        searchParams.set('search', search);
        setSearchParams(searchParams);
        // const newParam = {...param, page:1, search:`${search}`}
        // navigateWithParam(newParam, navigate, pathname);
        // tableReset();
        // getSearchValue(search);
        // goToPage(1);
      }
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    setFirstTimeFetch(true);
  }, []);

  const handleSearchValue = (val) => {
    setSearch(val);
  };

  const options = {
    page,
    sizePerPage,
  };

  const indicationLoading = () => <GlobalLoader />;

  const indicationNoRecords = () => {
    // return <div className="text-center">{t("text.common.noRecordFound")} </div>;
  };

  const rowSelection = {
    mode: 'checkbox',
    clickToSelect: false,
  };

  return (
    <div className="nk-block">
      <div className={`${isCard ? 'card' : ''} position-static`}>
        <div className="card-inner">
          <div className="common-table">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              {header && (
                <Row className="justify-between g-2">
                  <Col xs="5" sm="6" className="text-start">
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      {searchPlaceholder ? (
                        <AntTooltip
                          placement="topLeft"
                          promptText={`Search by ${searchPlaceholder}`}
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control form-control-md"
                              placeholder="Type 3 or more characters"
                              aria-controls="DataTables_Table_0"
                              onChange={(e) =>
                                handleSearchValue(e.target.value)
                              }
                              value={search}
                            />
                          </label>
                        </AntTooltip>
                      ) : (
                        <label>
                          <input
                            type="search"
                            className="form-control form-control-md"
                            placeholder="Search"
                            aria-controls="DataTables_Table_0"
                            onChange={(e) => handleSearchValue(e.target.value)}
                            value={search}
                          />
                        </label>
                      )}
                    </div>
                  </Col>
                  <Col xs="7" sm="6" className="text-end">
                    <div className='d-flex justify-content-end'>
                      <div className="datatable-filter">
                        <div
                          className="dataTables_length"
                          id="DataTables_Table_0_length"
                        >
                          <label>
                            <span className="d-sm-inline-block">
                              {/* {t("text.common.show")} */}
                            </span>
                            <div className="form-control-select">
                              <select
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={handleSelect}
                              >
                                <option value="active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="delete">Delete </option>
                              </select>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="datatable-filter">
                        <div
                          className="dataTables_length"
                          id="DataTables_Table_0_length"
                        >
                          <label>
                            <span className="d-sm-inline-block">
                              {/* {t("text.common.show")} */}
                            </span>
                            <div className="form-control-select">
                              {' '}
                              <select
                                value={query.get('pageSize') || '10'}
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={handleSelect}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                      
                    
                  </Col>
                </Row>
              )}
              {selectRow ? (
                <BootstrapTable
                  classes="table dataTable tableTooltip"
                  keyField="id"
                  data={tableData}
                  options={options}
                  columns={tableColumns}
                  defaultSorted={param?.sortType ? defaultSort : []}
                  bordered={bordered}
                  selectRow={rowSelection}
                  wrapperClasses="datatable-wrap my-3"
                  loading={tableLoader}
                  noDataIndication={
                    tableLoader ? indicationLoading : indicationNoRecords()
                  }
                />
              ) : (
                <BootstrapTable
                  classes="table dataTable tableTooltip"
                  keyField="id"
                  data={tableData}
                  options={options}
                  columns={tableColumns}
                  defaultSorted={param?.sortType ? defaultSort : []}
                  bordered={bordered}
                  wrapperClasses="datatable-wrap my-3"
                  loading={tableLoader}
                  noDataIndication={
                    tableLoader ? indicationLoading : indicationNoRecords()
                  }
                />
              )}
              {pagination && (
                <Pagination
                  count={count}
                  page={parseInt(page)}
                  sizePerPage={sizePerPage}
                  noOfPage={noOfPage}
                  goToPage={goToPage}
                  handleLimitChange={handleLimitChange}
                  hasLimit={hasLimit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTable;
