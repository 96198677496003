import React from 'react';

function AdminFooter() {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            &copy; <span>{ new Date().getFullYear() }</span>  All Rights Good Guys.
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminFooter;
