import React  from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from '../../../../components';
import { useNavigate } from 'react-router-dom';

function Leads(props) {

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-service',
      name: 'Google Guaranteed List',
    },
    {
      path: '#',
      name: 'Google Local Service Management',
    },
  ];

  const columns = [
    {
      dataIndex: 'leadId',
      title: 'Lead Id',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'leadType',
      title: 'Lead Type',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'customerPhone',
      title: 'Customer Phone',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'chargeStatus',
      title: 'Charge Status',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'geoLocation',
      title: 'Geo Location',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'leadCategory',
      title: 'Lead Category',
      headerClasses: 'sorting',
      sorter: false,
    },
    {
      dataIndex: 'dateTime',
      title: 'Date/Time',
    },
  ];
  const customerData = [
    {
      leadId: "5796654",
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: "Yes",
      leadCategory: "abc",
      dateTime: "Fri, October 28, 2022 12:06 PM"
    },
    {
      leadId: "	75833147",
      leadType: 'XYZ',
      customerPhone: '(+91) 8369995023',
      chargeStatus: 'Yes',
      geoLocation: "Yes",
      leadCategory: "abc",
      dateTime: "Sat, October 30, 2022 10:06 PM"
    },
    {
      leadId: "5796654",
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: "Yes",
      leadCategory: "abc",
      dateTime: "Fri, October 28, 2022 12:06 PM"
    },
    {
      leadId: "75833147",
      leadType: 'ABC',
      customerPhone: '(+91) 8769995023',
      chargeStatus: 'Yes',
      geoLocation: "Yes",
      leadCategory: "abc",
      dateTime: "Fri, October 28, 2022 12:06 PM"
    },
  ];

  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/google-service')
  } 
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Google Local Service Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        isSearch={false}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        isResetBtn= {false}
        selectRow={false}
        showTableCountNumber={false}
      />
    </>
  );
}

export default Leads;
