import { DatePicker, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormElementWrapper, FormWrapper,TextInput,CommonButton } from '../../../../index';

function PaymentReportFilterForm() {
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]

  
  
  const filterData = [
    {
      name: 'today',
      value: 'Today',
    },
    {
      name: 'yesterday',
      value: 'Yesterday',
    },
    {
      name: 'currentWeek',
      value: 'Current Week',
    },
    {
      name: 'previousWeek',
      value: 'Previous Week',
    },
    {
      name: 'currentMonth',
      value: 'Current Month',
    },
    {
      name: 'previousMonth',
      value: 'Previous Month',
    },
    {
      name: 'currentYear',
      value: 'Current Year',
    },
    {
      name: 'previousYear',
      value: 'Previous Year',
    },
  ]
  return (
    <>
      <div className='dropdown-body-rg'>
      <FormWrapper row={true} extraClassName="g-3">
         <FormElementWrapper
          md={12}
          label="Search By Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="filter"
              placeholder="Select"
              options={filterData}
              showSearch
              formik={formik}
            />
          )}
        />
        {/* <FormElementWrapper
          md={6}
          label="Location"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="state"
              placeholder="Select location"
              options={locationData}
              showSearch
              formik={formik}
            />
          )}
        /> */}
        <FormElementWrapper
          md={6}
          label="From Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="startDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="To Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="EndDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
        {/* <FormElementWrapper
            md={6}
            label="Job No"
            extraLabelClass="overline-title overline-title-alt"
            element={(formik) => (
              <TextInput
                name="JobNo"
                placeholder="Enter job no"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
          md={6}
          label="Provider"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="provider"
              placeholder="Select provider"
              options={providerData}
              showSearch
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="User"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="user"
              placeholder="Enter user"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Total Fees"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="totalfees"
              placeholder="Enter total fees"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Platform Fees"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="PlatformFees"
              placeholder="Enter platform fees"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Promo Code"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="promoCodeDiscount"
              placeholder="Enter promo code discount"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Provider Discount"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="JobProviderDiscount"
              placeholder="Enter job provider discount amount"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Purchase Order "
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="PurchaseOrder"
              placeholder="Enter purchase order "
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Sublabor Fees"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="PurchaseOrder"
              placeholder="Enter sublabor total fees "
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Returned PO"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="returnedPO"
              placeholder="Enter returned PO "
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Provider Pay"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="ProviderPay"
              placeholder="Enter provider pay"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Payment method"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="Paymentmethod"
              placeholder="Enter payment method"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Payment Type"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="PaymentType"
              placeholder="Enter payment type"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Payment Status"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="PaymentStatus"
              placeholder="Enter payment status"
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Transfer Status"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <TextInput
              name="TransferStatus"
              placeholder="Enter transfer status"
              formik={formik}
            />
          )}
        /> */}
      </FormWrapper>
        </div>
        <div className="dropdown-foot between">
          <Link to="#" type="button" className="clickable">
            Reset
          </Link>

          <CommonButton extraClassName="btn btn-sm btn-primary" htmlType="submit">
            Apply
          </CommonButton>
        </div>    
    </>
  );
}

export default PaymentReportFilterForm;
