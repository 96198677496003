import {   Toggle } from "../../components";
import { Link } from "react-router-dom";

const ModalOpenFormatter = ( url , text) => {
    return (
        <>
            {text} <Link to="#" onClick={url} className="text-primary"><em className="icon ni ni-edit"></em></Link>
        </>
    );
};

export const googleAdwordsServiceTableColumn = ({budgetViewModal}) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'name',
            title: 'Name',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'click',
            title: 'Click',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'avgCost',
            title: 'Avg. Cost',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'cost',
            title: 'Cost',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'impressions',
            title: 'Impressions',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'budget',
            title: 'Budget',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ModalOpenFormatter(budgetViewModal, row?.budget),
        },
        {
            dataIndex: 'conversionValue',
            title: 'Conversion Value',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'status',
            title: 'Status',
            headerClasses: 'sorting',
            sorter: true,
            render:()=><Toggle isDropDown/>,
        },
        {
            dataIndex: 'spInControl',
            title: 'SP in control',
            headerClasses: 'sorting',
            sorter: true,
            render:()=><Toggle/>,
        },
    ]
};
