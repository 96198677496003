import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActionWrapper,
  Breadcrumb,
  DateFilter,
  InputFilter,
  ListingHeader,
  PageHeader,
  SelectFilter,
  TablePlus,
} from '../../../../components';
import { FilterFilled  } from "@ant-design/icons";

function NCAListing(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Billing Report',
    },
  ];
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const Action = () =>
  actionFormatter([
    {
      name: 'View',
      icon: 'icon ni ni-eye',
      path: '/admin/nca-billing-details'
    },
  ]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectDateProps = (dataIndex) => ({
    filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getPlanFailedData = () => {
    return [
      {
        name: 'Daily Cycle',
        value: 'Daily Cycle',
      },
      {
        name: 'Cancel Plan',
        value: 'Cancel Plan',
      },
      {
        name: 'Update Plan',
        value: 'Update Plan',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      sorter: true,
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'nca',
      title: 'NCA',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("nca"),
    },
    {
      dataIndex: 'role',
      title: 'Role',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("role"),
    },
    {
      dataIndex: 'fourdigitsofcard',
      title: 'Last Four digits of Card',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'billType',
      title: 'Bill Type',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'planAmount',
      title: 'Plan Amount',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'totalAmount',
      title: '	Total Amount',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'paymentStatus',
      title: 'Payment Status',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("payment status"),
      render:(cell, row)=>{
        console.log("Details", cell)
        if(cell === "Pending"){ 
          return <> {cell} <span className='btn btn-light'>Mark As Settled</span>  </>
        }else{
          if(cell !== ""){
            return <>
                {cell}
            </>
          }
        }
     }
    },
    {
      dataIndex: 'planFailedType',
      title: 'Plan Failed Type',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSelectSearchProps("plan failed type", getPlanFailedData()),
    },
    {
      dataIndex: 'date',
      title: 'Date',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSelectDateProps("date"),
    },
    {
      dataIndex: 'action',
      title: 'Action',
      sorter: true,
      headerClasses: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const adminData = [
    {
      id: 1,
      nca: "Rekha Test LTD",
      role: "India make One",
      fourdigitsofcard: "4242",
      billType: "Monthly",
      planAmount: "$ 200.00",
      totalAmount: "$ 200.00",
      paymentStatus: "Pending",
      planFailedType: "-",
      date: "20th November, 2022, 11:00 PM	",
    },
    {
      id: 2,
      nca: "JAY pvt lmt",
      role: "India make One",
      fourdigitsofcard: "4242",
      billType: "Monthly",
      planAmount: "$ 200.00",
      totalAmount: "$ 200.00",
      paymentStatus: "Success  ",
      planFailedType: "-",
      date: "20th November, 2022, 11:00 PM	",
    },
    {
      id: 3,
      nca: "Teena Company",
      role: "India make One",
      fourdigitsofcard: "4242",
      billType: "Monthly",
      planAmount: "$ 200.00",
      totalAmount: "$ 200.00",
      paymentStatus: "Success  ",
      planFailedType: "-",
      date: "20th November, 2022, 11:00 PM	",
    },
  ];

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Billing Report">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['csvExport']}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        isSearch={false}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
      />
    </>
  );
}


export default NCAListing;
