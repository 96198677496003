export const couponManagementService = () => {
    return [
      {
        id: 1,
        couponCode: 'disk',
        discount: 'Percent',
        validity: 'Permanent',
        maximumUse: '1',
        startDate: '04/21/2022',
        endDate: '04/27/2022',
      },
      {
        id: 2,
        couponCode: 'flat100',
        discount: 'Flat',
        validity: 'Custom',
        maximumUse: '5',
        startDate: '04/21/2022',
        endDate: '04/27/2022',
      },
      {
        id: 3,
        couponCode: 'GET12',
        discount: '10%',
        validity: 'Custom',
        maximumUse: '2',
        startDate: '04/21/2022',
        endDate: '04/27/2022',
      },
      {
        id: 4,
        couponCode: 'ntest28',
        discount: '30%',
        validity: 'Permanent',
        maximumUse: '4',
        startDate: '04/21/2022',
        endDate: '04/27/2022',
      },
      {
        id: 5,
        couponCode: 'Ocen',
        discount: '10%',
        validity: 'Permanent',
        maximumUse: '3',
        startDate: '04/21/2022',
        endDate: '04/27/2022',
      },
    ];
  };
  