import { Outlet } from 'react-router-dom';
import { AdminPaymentMethod, BookingLog, Invoice, JobProviderDiscountAmountAdd, PaymentMethod, ProviderInvoiceUpdateDetails, PurchaseOrderDetails, PurchaseOrderDetailsAdd, ReturnAmountDetails, ReturnAmountDetailsAdd, ServiceProviderSubLaborPayDetails, SplitPaymentDetails, SubLaborPayDetails, SubLaborPayDetailsAdd } from '../../views/Reports';
import NCABillingDetails from '../../views/Reports/NCABilling/Details';
import NCAListing from '../../views/Reports/NCABilling/Listing';
import PaymentReport from '../../views/Reports/Payment/Listing';
import Reviews from '../../views/Reports/Review/Listing';
import ProviderDetails from '../../views/Reports/ServiceProviderPayment/Details';
import ProviderPayment from '../../views/Reports/ServiceProviderPayment/Listing';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Reports',
      key: "report",
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.PAYMENT_REPORT.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.PAYMENT_REPORT.path,
          name: 'Payment Report',
          key: "report.list",
          belongsToSidebar: true,
          // icon: accessRoute.PAYMENT_REPORT.icon,
          element: <PaymentReport />,
        },
        {
          private: true,
          path: accessRoute.SERVICE_PROVIDER_PAYMENT.path,
          name: 'Service Provider Payment Report',
                 key: "report.provider",
          belongsToSidebar: true,
          // icon: accessRoute.SERVICE_PROVIDER_PAYMENT.icon,
          element: <ProviderPayment />,
        },
        {
          private: true,
          path: accessRoute.SERVICE_PROVIDER_PAYMENT_DETAILS.path,
          name: 'Service Provider Payment Details',
                 key: "report.provider",
          belongsToSidebar: false,
          // icon: accessRoute.SERVICE_PROVIDER_PAYMENT_DETAILS.icon,
          element: <ProviderDetails />,
        },
        {
          private: true,
          path: accessRoute.SERVICE_PROVIDER_SUB_LABOR_DETAILS.path,
          name: 'Service Provider Payment Report',
          key: "report.provider",
          belongsToSidebar: false,
          // icon: accessRoute.SERVICE_PROVIDER_SUB_LABOR_DETAILS.icon,
          element: <ServiceProviderSubLaborPayDetails />,
        },
        {
          private: true,
          path: accessRoute.PURCHASE_ORDER_DETAILS.path,
          name: 'Purchase Order Details',
                  key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.PURCHASE_ORDER_DETAILS.icon,
          element: <PurchaseOrderDetails />,
        },
        {
          private: true,
          path: accessRoute.PURCHASE_ORDER_DETAILS_ADD.path,
          name: 'Purchase Order Details Add',
                  key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.PURCHASE_ORDER_DETAILS_ADD.icon,
          element: <PurchaseOrderDetailsAdd />,
        },
        {
          private: true,
          path: accessRoute.SUBLABOR_PAY_DETAILS.path,
          name: 'Sub-Labor Pay Details',
                             key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.SUBLABOR_PAY_DETAILS.icon,
          element: <SubLaborPayDetails />,
        },
        {
          private: true,
          path: accessRoute.SUBLABOR_PAY_DETAILS_ADD.path,
          name: 'Sub-Labor Pay Details Add',
                             key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.SUBLABOR_PAY_DETAILS_ADD.icon,
          element: <SubLaborPayDetailsAdd />,
        },
        {
          private: true,
          path: accessRoute.RETURN_AMOUNT_DETAILS.path,
          name: 'Return Amount Details',
                          key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.RETURN_AMOUNT_DETAILS.icon,
          element: <ReturnAmountDetails />,
        },
        {
          private: true,
          path: accessRoute.RETURN_AMOUNT_DETAILS_ADD.path,
          name: 'Return Amount Details Add',
                          key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.RETURN_AMOUNT_DETAILS_ADD.icon,
          element: <ReturnAmountDetailsAdd />,
        },
        {
          private: true,
          path: accessRoute.SPLIT_PAYMENT_DETAILS.path,
          name: 'Split Payment Details',
                   key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.SPLIT_PAYMENT_DETAILS.icon,
          element: <SplitPaymentDetails />,
        },
        {
          private: true,
          path: accessRoute.PROVIDER_INVOICE_UPDATE.path,
          name: 'Provider Invoice Update Details',
                   key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.PROVIDER_INVOICE_UPDATE.icon,
          element: <ProviderInvoiceUpdateDetails />,
        },
        {
          private: true,
          path: accessRoute.BOOKING_LOG.path,
          name: 'Booking Log',
                   key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.BOOKING_LOG.icon,
          element: <BookingLog />,
        },
        {
          private: true,
          path: accessRoute.INVOICE.path,
          name: 'Invoice Details',
              key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.INVOICE.icon,
          element: <Invoice/>,
        },
        {
          private: true,
          path: accessRoute.PAYMENT_METHOD.path,
          name: 'Payment Method',
              key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.PAYMENT_METHOD.icon,
          element: <PaymentMethod/>,
        },
        {
          private: true,
          path: accessRoute.ADMIN_PAYMENT_METHOD.path,
          name: 'Admin Payment Method',
              key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.ADMIN_PAYMENT_METHOD.icon,
          element: <AdminPaymentMethod/>,
        },
        {
          private: true,
          path: accessRoute.JOB_PROVIDER_DISCOUNT.path,
          name: 'Job Provider Discount',
              key: "report.list",
          belongsToSidebar: false,
          // icon: accessRoute.JOB_PROVIDER_DISCOUNT.icon,
          element: <JobProviderDiscountAmountAdd/>,
        },
        {
          private: true,
          path: accessRoute.REVIEWS.path,
          name: 'Reviews',
          belongsToSidebar: true,
          key: "report.review",
          // icon: accessRoute.REVIEWS.icon,
          element: <Reviews />,
        },
        {
          private: true,
          path: accessRoute.NCA_BILLING_REPORT.path,
          name: 'NCA Billing Report',
               key: "report.nca",
          belongsToSidebar: true,
          // icon: accessRoute.NCA_BILLING_REPORT.icon,
          element: <NCAListing />,
        },
        {
          private: true,
          path: accessRoute.NCA_BILLING_DETAILS.path,
          name: 'NCA Billing Details',
              key: "report.nca",
          belongsToSidebar: false,
          // icon: accessRoute.NCA_BILLING_DETAILS.icon,
          element: <NCABillingDetails />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  PAYMENT_REPORT: {
    path: '/admin/payment-report',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-file-text-fill" />
      </span>
    ),
  },
  SERVICE_PROVIDER_PAYMENT: {
    path: '/admin/service-provider-payment',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  SERVICE_PROVIDER_PAYMENT_DETAILS: {
    path: '/admin/service-provider-payment/payment-details',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  SERVICE_PROVIDER_SUB_LABOR_DETAILS: {
    path: '/admin/service-provider-payment/sub-labor-details',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  PURCHASE_ORDER_DETAILS: {
    path: '/admin/payment-report/purchase-order',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  PURCHASE_ORDER_DETAILS_ADD: {
    path: '/admin/payment-report/purchase-order-add',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  SUBLABOR_PAY_DETAILS: {
    path: '/admin/payment-report/sublabor-pay',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  SUBLABOR_PAY_DETAILS_ADD: {
    path: '/admin/payment-report/sublabor-pay-add',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  RETURN_AMOUNT_DETAILS: {
    path: '/admin/payment-report/return-amount',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  RETURN_AMOUNT_DETAILS_ADD: {
    path: '/admin/payment-report/return-amount-add',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  SPLIT_PAYMENT_DETAILS: {
    path: '/admin/payment-report/split-payment',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  PROVIDER_INVOICE_UPDATE: {
    path: '/admin/payment-report/provider-invoice-update',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  BOOKING_LOG: {
    path: '/admin/payment-report/booking-log',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  INVOICE: {
    path: '/admin/payment-report/invoice',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  PAYMENT_METHOD: {
    path: '/admin/payment-report/payment-method',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  ADMIN_PAYMENT_METHOD: {
    path: '/admin/payment-report/admin-payment-method',
  },
  JOB_PROVIDER_DISCOUNT: {
    path: '/admin/payment-report/job-provider-discount',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  REVIEWS: {
    path: '/admin/review',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  NCA_BILLING_REPORT: {
    path: '/admin/nca-billing-report',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
  NCA_BILLING_DETAILS: {
    path: '/admin/nca-billing-details',
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-file-text-fill" />
    //   </span>
    // ),
  },
};



export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
