import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';


function InputFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex }) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  return (
    <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          className="ant-input form-control"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className='btn btn-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 90,
            }}
          >
             SEARCH
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            className='btn btn-info'
            style={{
              width: 90,
            }}
          >
            RESET
          </Button>
        </Space>
      </div>
  );
}

export default InputFilter;
