import {Radio, Select } from 'antd';
import React, { useState } from 'react';
import { Form, Row, Col, FormGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  ImageElement,
  ListingHeader,
  FormElementWrapper,
  FormWrapper,
  PageHeader,
  TextInput,
  CommonButton,
  InputType,
  ModalComponent,
  Toggle,
  Tabs,
  SweetAlert,
} from '../../../components';
import { Checkbox } from '../../../components/Antd';
import { modalNotification } from '../../../utils';

function EditUser(props) {
  const [asideView, setAsideView] = useState(false);
  const asideToggle = () => {
    setAsideView(!asideView);
  };
  if (asideView) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/customers",
      name: "Customers",
    },
    {
      path: "#",
      name: "Edit Customer",
    },
  ];
  const navigate = useNavigate()
  const backPage = () => {
    navigate('/admin/customers')
  }
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  }
  const UserAddressPage = () => {
    navigate('/admin/customers/edit/:id')
  }
  const countryData = [
    {
      name: 'india',
      value: 'India',
    },
    {
      name: 'unitedstates',
      value: 'United States',
    },
  ];
  const monthData = [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
  ];
  const membershipData = [
    {
      name: 'paid',
      value: 'Paid',
    },
    {
      name: 'unpaid',
      value: 'Unpaid',
    },
  ]
  const statusData = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ]
  const CustomerTagData = [
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'Cooling',
      value: 'Cooling',
    }
  ]
  const jobsIDData = [
    {
      name: ' 2023/01/11 Job# 93138812',
      value: ' 2023/01/11 Job# 93138812',
    },
    {
      name: ' 2023/01/06 Job# 23701126',
      value: ' 2023/01/06 Job# 23701126',
    },
    {
      name: '  2023/01/20 Job# 93657671',
      value: '  2023/01/20 Job# 93657671',
    },
    {
      name: '  2023/01/19 Job# 57146453',
      value: '  2023/01/19 Job# 57146453',
    }
  ]
  const JobTagData = [
    {
      name: 'Air Purifier',
      value: 'Air Purifier',
    },
    {
      name: 'Qwerty1',
      value: 'Qwerty1',
    },
  ]
  const BookNowPage = () => {
    navigate('/admin/add-booking')
  }
  const BookDetailsPage = () => {
    navigate('/admin/service')
  }
  const HomeInventoryPage = () => {
    navigate('/admin/home-inventory')
  }
  const multipleButton = [
    {
      title: "Home inventory",
      handler: () => { HomeInventoryPage() }
    },
    {
      title: "View Customer Address",
      handler: () => { UserAddressPage() },
    },
    {
      title: "View Booking Details",
      handler: () => { BookDetailsPage() },
    },
  ]
  const [customerTagAddEdit, setCustomerTagAddEdit] = useState(false);
  const customerTagAddEditShow = () => {
    setCustomerTagAddEdit(true);
  };
  const customerTagAddEditHide = () => {
    setCustomerTagAddEdit(false);
  };
  const [jobTagAddEdit, setJobTagAddEdit] = useState(false);
  const jobTagAddEditShow = () => {
    setJobTagAddEdit(true);
  };
  const jobTagAddEditHide = () => {
    setJobTagAddEdit(false);
  };
  const [memberShipUpgradeModal, setMemberShipUpgradeModal] = useState(false);
  const memberShipUpgradeModalShow = () => {
    setMemberShipUpgradeModal(true);
  };
  const memberShipUpgradeModalHide = () => {
    setMemberShipUpgradeModal(false);
  };
  const [defaultKey, setDefaultKey] = useState('customerDetails');
  const tabContent = [
    {
      name: 'Customer Details',
      key: 'customerDetails',
      content: (
        <>
          <h5 className='mb-3'>Customer Notes</h5>
          <div className='customerNotesSection'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={12}
                element={(formik) => (
                  <>
                    <div className='d-flex align-items-center'>
                      <TextInput
                        name="CustomerNotes"
                        placeholder="Enter notes"
                        formik={formik}
                      />
                      <CommonButton className="btn btn-primary ms-2">Enter</CommonButton>
                    </div>
                  </>
                )}
              />
            </FormWrapper>
            <div className='customerNotesSection_list'>
              <ul className='list-unstyled'>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>Enter by Admin</h6>
                      <p>Added by sub-admin</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>SP Note by Ios Pro</h6>
                      <p>Test</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>SP Note by Ron TestA</h6>
                      <p>Test</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>SP Note by Ron TestA</h6>
                      <p>Test</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
              </ul>
            </div>
            <h5 className='my-3'>Provider Contact Logs</h5>
            <div className='customerNotesSection_list'>
              <ul className='list-unstyled'>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>Ron TestA</h6>
                      <p className='mb-0'><b>Subject:</b>hello</p>
                      <p><b>Message:</b>test message</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
                <li className='notesBox'>
                  <div className='d-sm-flex align-items-start justify-content-between'>
                    <div>
                      <h6 className='mb-1'>Ios Pro</h6>
                      <p className='mb-0'><b>Subject:</b>hi ios pro</p>
                      <p><b>Message:</b>test msg</p>
                    </div>
                    <p className='text-soft time'>Wed, January 11, 2023 09:17 AM</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='divider divider-sm'></div>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              md={6}
              label="First Name"
              mandatory="true"
              element={(formik) => (
                <TextInput
                  name="firstName"
                  placeholder="Enter first name"
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Last Name"
              mandatory="true"
              element={(formik) => (
                <TextInput
                  name="lastName"
                  placeholder="Enter last name"
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Email"
              mandatory="true"
              element={(formik) => (
                <TextInput
                  name="email"
                  placeholder="Enter email"
                  formik={formik}
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Password"
              mandatory="true"
              element={(formik) => (
                <TextInput
                  name="password"
                  placeholder="Enter password"
                  formik={formik}
                />
              )}
            />
            {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label w-100">
                        Gender
                      </label>
                      <Radio.Group value={value} onChange={onChange}>
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </div>
                  </div> */}
            <FormElementWrapper
              md={6}
              label="Profile Picture"
              element={(formik) => (
                <TextInput
                  name="ProfilePicture"
                  placeholder="Select profile picture"
                  formik={formik}
                  type="file"
                />
              )}
            />
            <FormElementWrapper
              md={6}
              label="Country"
              mandatory="true"
              element={(formik) => (
                <Select
                  name="country"
                  placeholder="Select country"
                  options={countryData}
                  showSearch
                  formik={formik}
                />
              )}
            />
            {/* <FormElementWrapper
                    md={6}
                    label="Number of Month"
                    mandatory="true"
                    element={(formik) => (
                      <Select
                        name="months"
                        placeholder="Select months"
                        options={monthData}
                        // showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    label="Membership"
                    mandatory="true"
                    element={(formik) => (
                      <Select
                        name="Membership"
                        placeholder="Select membership "
                        options={membershipData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  /> */}
            <FormElementWrapper
              md={6}
              label="Phone"
              mandatory="true"
              element={(formik) => (
                <div className="form-control-wrap">
                  <div className="input-group flex-nowrap">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">1</span>
                    </div>
                    <InputType
                      className="form-control"
                      placeholder="Enter phone  "
                      name="PhoneNumber"
                      type="text"
                    />
                  </div>
                </div>
              )}
            />
            <FormElementWrapper
              md={6}
              label="Status"
              // mandatory="true"
              element={(formik) => (
                <Toggle />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-2"
                  >
                    Update
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light"
                  >
                    Reset
                  </CommonButton>
                </>
              )}
            />
          </FormWrapper>
        </>
      ),
    },
    {
      name: 'Membership Plan',
      key: 'membershipPlan',
      content: (
        <>
          <h5 className='mb-3'>Membership Plan</h5>
          <div className="membershipPlan">
            <ul>
              <li className="membershipPlan_box" onClick={() => memberShipUpgradeModalShow()}>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">Free Trial Plan</h6>
                  <span className="badge badge-upgrade">Upgrade</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>$5.00</p>
                </div>
              </li>
              <li className="membershipPlan_box active" onClick={ () => memberShipActiveModalShow() }>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">Standard Plan</h6>
                  <span className="badge badge-active">Active</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>10%</p>
                </div>
              </li>
              <li className="membershipPlan_box" onClick={() => memberShipUpgradeModalShow()}>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">Premium Plan</h6>
                  <span className="badge badge-upgrade">Upgrade</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>$7.00</p>
                </div>
              </li>
              <li className="membershipPlan_box" onClick={() => memberShipUpgradeModalShow()}>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">Enterprice Plan</h6>
                  <span className="badge badge-upgrade">Upgrade</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>$5.00</p>
                </div>
              </li>
              <li className="membershipPlan_box" onClick={() => memberShipUpgradeModalShow()}>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">Basic Plan</h6>
                  <span className="badge badge-upgrade">Upgrade</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>$5.00</p>
                </div>
              </li>
              <li className="membershipPlan_box" onClick={() => memberShipUpgradeModalShow()}>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                  <h6 className="mb-0">10% Saving Plan </h6>
                  <span className="badge badge-upgrade">Upgrade</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p>Monthly</p>
                  <p>$20.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Yearly</p>
                  <p>$240.00</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className='text-black'>Save</p>
                  <p className='text-black'>$5.00</p>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  const [paymentDefaultKey, setPaymentDefaultKey] = useState('check');
  const monthsData = [
    {
      name: '01',
      value: '01',
    },
    {
      name: '02',
      value: '02',
    },
    {
      name: '03',
      value: '03',
    },
    {
      name: '04',
      value: '04',
    },
  ]
  const yearsData = [
    {
      name: '2019',
      value: '2019',
    },
    {
      name: '2020',
      value: '2020',
    },
    {
      name: '2021',
      value: '2021',
    },
    {
      name: '2022',
      value: '2022',
    },
  ]
  const paymentTabContent = [
    {
      name: 'Check',
      key: 'check',
      content: (
        <div className='card shadow-none'>
          <div className='card-inner px-0 pb-0'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={4}
                label="Amount To Pay"
                element={(formik) => (
                  <TextInput
                    name="amountToPay"
                    placeholder="Enter amount to pay"
                    formik={formik}
                    type="number"
                    defaultValue="100.01"
                    disabled={true}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Bank Name"
                element={(formik) => (
                  <TextInput
                    name="bankName"
                    placeholder="Enter bank name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Check Number"
                element={(formik) => (
                  <TextInput
                    name="CheckNumber"
                    placeholder="Enter check number"
                    formik={formik}
                  />
                )}
              />
              <div className='mt-4 text-end'>
                <CommonButton
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </CommonButton>
              </div>
            </FormWrapper>
          </div>
        </div>
      ),
    },
    {
      name: 'Financing',
      key: 'financing',
      content: (
        <div className='card shadow-none'>
          <div className='card-inner px-0 pb-0'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={4}
                label="Amount To Pay"
                element={(formik) => (
                  <TextInput
                    name="amountToPay"
                    placeholder="Enter amount to pay"
                    formik={formik}
                    type="number"
                    defaultValue="100.01"
                    disabled={true}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Bank Name"
                element={(formik) => (
                  <TextInput
                    name="bankName"
                    placeholder="Enter bank name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Financing Confirmation Number"
                element={(formik) => (
                  <TextInput
                    name="CheckNumber"
                    placeholder="Enter financing confirmation number"
                    formik={formik}
                  />
                )}
              />
              <div className='mt-4 text-end'>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Submit
                </CommonButton>
              </div>
            </FormWrapper>
          </div>
        </div>
      ),
    },
    {
      name: 'Credit Card',
      key: 'creditCard',
      content: (
        <div className='card shadow-none'>
          <div className='card-inner px-0 pb-0'>
            <div className='cardList'>
              <h6 className='mb-0'>Card List</h6>
              <div className="cardList_inner">
                <Form>
                  <Radio.Group value={value} onChange={onChange} className='w-100'>
                    <Row className='g-2'>
                      <Col xl={6}>
                        <div className="cardList_list bg-lighter">
                          <div className="d-flex align-items-center justify-content-between"> 
                            <FormGroup className="form-group mb-0 me-xxl-3 me-sm-2 me-1 ">
                              <Radio value={1}></Radio>
                            </FormGroup>  
                            <div className="flex-grow-1 cardList_detail">Steven Hurd<span className="cardList_list_no d-block ">**** **** **** 1234</span> </div>
                            <ImageElement className="img-fluid cardList_list_logo" source="mastercard-logo.png" alt="logo" />
                            <div className="cardList_delete">
                              <em className="icon ni ni-trash" />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="cardList_list bg-lighter">
                          <div className="d-flex align-items-center justify-content-between">    
                            <FormGroup className="form-group mb-0 me-xxl-3 me-sm-2 me-1 ">
                              <Radio value={2}></Radio>
                            </FormGroup>  
                            <div className="flex-grow-1 cardList_detail">Steven Hurd<span className="cardList_list_no d-block">**** **** **** 1234</span> </div>
                            <ImageElement className="img-fluid cardList_list_logo" source="visa-logo.png" alt="logo" />
                            <div className="cardList_delete">
                                <em className="icon ni ni-trash" />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form>
              </div>              
            </div>
            <FormWrapper row={true} extraClassName="g-3">
              <h6 className='mb-0'>Add Card</h6>
              <FormElementWrapper
                md={4}
                label="Amount To Pay"
                element={(formik) => (
                  <TextInput
                    name="amountToPay"
                    placeholder="Enter amount to pay"
                    formik={formik}
                    type="number"
                    defaultValue="100.01"
                    disabled={true}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Card Holder"
                element={(formik) => (
                  <TextInput
                    name="cardHolder"
                    placeholder="Enter card holder"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label=" Your Card Number"
                element={(formik) => (
                  <TextInput
                    name="YourCardNumber"
                    placeholder="Enter your card number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Exp. Month"
                element={(formik) => (
                  <Select
                    name="expMonth"
                    placeholder="Select exp. month"
                    options={monthsData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="Exp. Year"
                element={(formik) => (
                  <Select
                    name="expYear"
                    placeholder="Select exp. year"
                    options={yearsData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={4}
                label="CVV"
                element={(formik) => (
                  <TextInput
                    name="CVV"
                    placeholder="Enter cvv"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <div className='mt-4 text-end'>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Submit
                </CommonButton>
              </div>
            </FormWrapper>
          </div>
        </div>
      ),
    },
    {
      name: 'Cash',
      key: 'cash',
      content: (
        <div className='card shadow-none'>
          <div className='card-inner px-0 pb-0'>
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={4}
                label="Amount To Pay"
                element={(formik) => (
                  <TextInput
                    name="amountToPay"
                    placeholder="Enter amount to pay"
                    formik={formik}
                    type="number"
                    defaultValue="100.01"
                    disabled={true}
                  />
                )}
              />
              <h6 className='title text-primary mb-0'>Collecting Cash</h6>
              <div className='mt-4 text-end'>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Submit
                </CommonButton>
              </div>
            </FormWrapper>
          </div>
        </div>
      ),
    },
  ];
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);
  const paymentOptionModalShow = () => {
    memberShipUpgradeModalHide()
    setPaymentOptionModal(true);
  };
  const paymentOptionModalHide = () => {
    setPaymentOptionModal(false);
  };

  const [memberShipActiveModal, setMemberShipActiveModal] = useState(false);
  const memberShipActiveModalShow = () => {
    setMemberShipActiveModal(true)
  }
  const memberShipActiveModalHide = () => {
    setIsAlertVisible(true)
    setMemberShipActiveModal(false)
  }
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    modalNotification({ type: 'success', message: 'Active plan cancel Successfully' });
    return true;
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Customer">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create", "extraButton", "multipleButton"]}
            btnText="Book Now"
            onHandleShow={BookNowPage}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
            multipleButton={multipleButton}
          />
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-head-content align-self-start d-lg-none text-end">
                  <Link
                    to="#"
                    onClick={() => asideToggle()}
                    className={`toggle btn btn-icon btn-trigger mt-n1 ${asideView ? 'active' : ''
                      }`}
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </Link>
                </div>
                <div className='tabsonModal'>
                  <Tabs
                    tabContent={tabContent}
                    tabsFor="table"
                    activeKey={defaultKey}
                    setActiveKey={setDefaultKey}
                    extraClassName='shadow-none mb-4'
                  // onTabChange={onTabChange}
                  />
                </div>


              </div>
            </div>
            <div className={` min-vh-auto card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${asideView ? 'content-active' : ''
              }`}
            >
              <div className="card-inner-group" data-simplebar>
                <div className="card-inner">
                  <div className="user-card user-card-s2">
                    <div className="user-avatar xl bg-primary">
                      <ImageElement source="uploadImg.png" alt="img" />
                    </div>
                    <div className="user-info">
                      <h5>joe </h5>
                      <div>
                        <span className='sub-text'>Member</span>
                        <span className="sub-text">Plan-1 (October 10, 2022)</span>
                      </div>
                      <span className="sub-text">joe@tekhne.app</span>
                      <span className='sub-text'>(+1) 3178214104</span>
                    </div>
                  </div>
                </div>
                <div className='card-inner'>
                  <Row className="g-3">
                    <Col md={12}>
                      <span className="sub-text mb-1">Customer Tag <Link to="#" className='ms-2' onClick={() => customerTagAddEditShow()}>Add/Edit</Link> </span>
                      <div className='d-flex flex-wrap'>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Air Cleaner</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Humidifier</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Home Warranty</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Cooling</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <span className="sub-text mb-1">Job Tag <Link to="#" className='ms-2' onClick={() => jobTagAddEditShow()}>Add/Edit</Link></span>
                      <div className='d-flex flex-wrap'>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Air Purifier</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Heating Tune-Up</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Qwerty1</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Humidifier J</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>Generator</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>test</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <span className="sub-text mb-1">Booking Tag </span>
                      <div className='d-flex flex-wrap'>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>kam test</span>
                        <span className='badge badge-xs bg-gray me-1 mb-1'>SP Manual</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <FormWrapper
                        row={true}
                        extraClassName="g-3"
                      >
                        <FormElementWrapper
                          md={12}
                          label="Select By Job Number"
                          element={(formik) => (
                            <Select
                              name="jobNumber"
                              placeholder="Select job number"
                              options={jobsIDData}
                              showSearch
                              formik={formik}
                            />
                          )}
                        />
                      </FormWrapper>
                    </Col>
                    <Col md={12}>
                      <span className="sub-text mb-1"><Link to="/admin/service">View Job details</Link> </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {asideView ? <div onClick={() => asideToggle()} className="toggle-overlay" data-target="userAside" /> : ""}
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={customerTagAddEdit}
        onHandleCancel={customerTagAddEditHide}
        title="Customer Tag"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={12}
            label="Customer Tag"
            mandatory="true"
            element={(formik) => (
              <Select
                name="CustomerTag"
                placeholder="Select customer tag"
                options={CustomerTagData}
                showSearch
                formik={formik}
                mode="multiple"
                allowClear
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="Save"
                  className="btn btn-primary"
                  onClick={() => customerTagAddEditHide()}
                >
                  Save
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={jobTagAddEdit}
        onHandleCancel={jobTagAddEditHide}
        title="Job Tag"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={12}
            label="Job Tag"
            mandatory="true"
            element={(formik) => (
              <Select
                name="JobTag"
                placeholder="Select job tag"
                options={JobTagData}
                showSearch
                formik={formik}
                mode="multiple"
                allowClear
              />
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="Save"
                  className="btn btn-primary"
                  onClick={() => jobTagAddEditHide()}
                >
                  Save
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
      {/* upgrade Modal */}
      <ModalComponent
        backdrop
        show={memberShipUpgradeModal}
        onHandleCancel={memberShipUpgradeModalHide}
        title="Membership Plan Upgrade"
      >
        <h5>Basic Plan</h5>
        <p><b>Save - $5.00</b></p>
        <div className='mt-3 mt-xl-4'>
          <h6>Includes</h6>
          <ul className="list list-sm list-circle">
            <li>Furnace Cleaning </li>
            <li>A/C Cleaning  </li>
            <li>Bonus Cleaning </li>
            <li>Additional Saving Of 10% On Repairs </li>
          </ul>
          <div className="mb-3 mb-lg-4 mb-xxl-5">
            <Checkbox checked>$14.99 Monthly </Checkbox>
            <Checkbox>$14.99 Yearly </Checkbox>
          </div>
          <div className="text-end">
            <CommonButton
              type="button"
              className="btn btn-primary"
              onClick={() => paymentOptionModalShow()}
            >
              Pay Now
            </CommonButton>
          </div>
        </div>
      </ModalComponent>
      {/* Payment Methods Modal */}
      <ModalComponent
        backdrop
        show={paymentOptionModal}
        onHandleCancel={paymentOptionModalHide}
        title="Payment Methods" extraClassName="modal-xxl"
      >
        <div className="tabsonModal">
          <Tabs
            tabContent={paymentTabContent}
            activeKey={paymentDefaultKey}
            setActiveKey={setPaymentDefaultKey}
            tabsOnModal
          // onTabChange={onTabChange}
          />
        </div>
      </ModalComponent>
       {/* Active Modal */}
       <ModalComponent
        backdrop
        show={memberShipActiveModal}
        onHandleCancel={memberShipActiveModalHide}
        title="Membership Plan Active"
      >
        <h5>Basic Plan</h5>
        <p><b> Save - 10%</b></p>
        <div className='mt-3 mt-xl-4'>
          <h6>Includes</h6>
          <ul className="list list-sm list-circle">
            <li>Furnace Cleaning </li>
            <li>A/C Cleaning  </li>
            <li>Bonus Cleaning </li>
            <li>Additional Saving Of 10% On Repairs </li>
          </ul>
          <div className="mb-3 mb-lg-4 mb-xxl-5">
            <Checkbox checked>$14.99 Monthly </Checkbox>
            <Checkbox>$14.99 Yearly </Checkbox>
          </div>
          <div className="text-end">
            <CommonButton
              type="button"
              className="btn btn-secondary"
              onClick={() => memberShipActiveModalHide()}
            >
              Cancel
            </CommonButton>
          </div>
        </div>
      </ModalComponent>
      <SweetAlert
        title="Are you sure"
        text="you want to cancel it?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
    </>

  );
}

export default EditUser;