import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ImageElement,
} from '../../../../components';

function NCABillingDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/nca-billing-report',
      name: 'Billing Report',
    },
    {
      path: '#',
      name: 'Billing Report Details',
    },
  ];
  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Billing Report Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/nca-billing-report')}
          />
        </div>
      </div>
      <div className="nk-block">
        <div className="invoice">
            <div className="invoice-wrap p-2 p-lg-3 p-xl-4">
                <div className="invoice-brand text-center pb-1">
                    <ImageElement source="logo.svg"/>
                </div>
                <div className="invoice-head">
                    <div className="invoice-contact">
                        <p className='fw-bold mb-1'>Billed to</p>
                        <div className="invoice-contact-info">
                            <h6 className="title mb-0">Rekha Patel</h6>
                            <p className='mb-0'>Rekha Test LTD</p>
                            <p>Demo, 205-H Block, Mahalaxmi nagar indore.</p>
                        </div>
                        <ul className="list-plain mt-3">
                            <li>
                                <p className='fw-bold mb-1'>Payment Method</p>
                                <p>Visa ending in 4242 </p>
                            </li>
                            <li>
                                <p className='fw-bold mb-1'>Payment Status</p>
                                <span className="badge badge-sm mt-1 rounded-pill badge-dim bg-outline-success">Success</span>
                            </li>
                        </ul>
                    </div>
                    <div className="invoice-desc">
                        <ul className="list-plain">
                            <li className="invoice-date"><span>Receipt Date</span>:<span>11/21/2022</span></li>
                        </ul>
                    </div>
                </div>
                <div className="invoice-bills">
                    <h6 className="title">Your Plan</h6>
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <tr>
                                    <th className='text-capitalize'>NCA Role Name</th>
                                    <th className='text-capitalize'>Billed</th>
                                    <th className='text-capitalize'>Active Service Provider ($0)</th>
                                    <th className='text-capitalize text-start'>QTY</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>India make One</td>
                                    <td>$200 monthly</td>
                                    <td>$0</td>
                                    <td className='text-start'>0</td>
                                </tr>
                            </tbody>
                        </table>
                       
                    </div>
                    <h6 className="title mt-3">Transaction Processing Fee(s) Expense Report</h6>
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <tr>
                                    <th className='text-capitalize'>Cost Paid</th>
                                    <th className='text-capitalize'>Transaction Count</th>
                                    <th className='text-capitalize text-start'>Transaction Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> Credit Card Transaction (0%+$0 per) <br/> $0</td>
                                    <td>0</td>
                                    <td className='text-start'>$0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h6 className="title mt-3">Plan Billed</h6>
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <tr>
                                    <th className="text-start text-capitalize">Plan Amount Paid (Monthly)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-start">$200</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="nk-notes ff-italic fs-12px text-soft">Rekha Test LTD subscription will automatically renew on this date (12/21/2022) </div>
                    </div>
                </div>
                <div className="invoice-contact text-start mt-2">
                    <p className='fw-bold mb-1'>Powerd By</p>
                    <div className="invoice-contact-info mt-1">                       
                        <ImageElement source="logo.svg"/>
                        <h6 className="title mb-0 mt-1">Good Guys Home Services, LLC</h6>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default NCABillingDetails;
