import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {

  Breadcrumb,
  CommonButton,
  ImageElement,
  ListingHeader,
  ModalComponent,
  PageHeader,
} from '../../../../components';
import { DatePicker, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Col, Row } from 'react-bootstrap';

function DocumentList(props) {
  const { Dragger } = Upload;
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/provider',
      name: 'Providers',
    },
    {
      path: '#',
      name: 'Document of animesh',
    },
  ];
  const backPage = () => {
    navigate('/admin/provider');
  };
  const [uploadFilterWithDate, setUploadFilterWithDate] = useState(false);
  const uploadFilterWithDateShow = () => {
    setUploadFilterWithDate(true);
  };
  const uploadFilterWithDateHide = () => {
    setUploadFilterWithDate(false);
  };
  const [uploadFilterWithOutDate, setUploadFilterWithOutDate] = useState(false);
  const uploadFilterWithOutDateShow = () => {
    setUploadFilterWithOutDate(true);
  };
  const uploadFilterWithOutDateHide = () => {
    setUploadFilterWithOutDate(false);
  };
  return (
    <>
      <div className="">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Document of animesh">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton']}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className='nk-block documentUploadSec'>
            <Row className='g-gs'>
                <Col sm={6} lg={4} xxl={3}>
                    <div className="card documentUploadSec_card text-center h-100">
                        <div className="card-header border px-3">Work History/Resume</div>
                        <div className="card-inner h-100 p-3">
                            <div className='documentUploadSec_imgBox d-flex flex-column align-items-center justify-content-center mb-3'>
                                <div className="nk-file-icon">
                                    <span className="nk-file-icon-type">
                                        <ImageElement source="uploadfile.svg" alt="img" />
                                    </span>
                                </div>
                                <h6 className='mt-3'>Work History/Resume</h6>
                            </div>
                            <CommonButton className="btn btn-primary" onClick= { () => uploadFilterWithOutDateShow() }>Work History/Resume</CommonButton>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={4} xxl={3}>
                    <div className="card documentUploadSec_card text-center h-100">
                        <div className="card-header border px-3">Driver License Back</div>
                        <div className="card-inner h-100 p-3">
                            <div className='documentUploadSec_imgBox d-flex flex-column align-items-center justify-content-center mb-3'>
                                <div className='documentUploadSec_imgBox-img'>
                                    <ImageElement source="uploadImg.png" alt="document" />
                                </div>
                                <h6 className='mt-3'>Driver License Back</h6>
                            </div>
                            <CommonButton className="btn btn-primary" onClick= { () => uploadFilterWithOutDateShow() }>Driver License Back</CommonButton>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={4} xxl={3}>
                    <div className="card documentUploadSec_card text-center h-100">
                        <div className="card-header border px-3">Driver License</div>
                        <div className="card-inner h-100 p-3">
                            <div className='documentUploadSec_imgBox d-flex flex-column align-items-center justify-content-center mb-3'>
                                <div className='documentUploadSec_imgBox-img'>
                                    <ImageElement source="a-sm.jpg" alt="document" />
                                </div>
                                <h6 className='mt-3'>Driver License</h6>
                            </div>
                            <CommonButton className="btn btn-primary" onClick= { () => uploadFilterWithOutDateShow() }>Driver License</CommonButton>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={4} xxl={3}>
                    <div className="card documentUploadSec_card text-center h-100">
                        <div className="card-header border px-3">Certification 1 (EPA, NATE Certification, Any Cert</div>
                        <div className="card-inner h-100 p-3">
                            <div className='documentUploadSec_imgBox d-flex flex-column align-items-center justify-content-center mb-3'>
                                <div className="nk-file-icon">
                                    <span className="nk-file-icon-type">
                                        <ImageElement source="uploadfile.svg" alt="img" />
                                    </span>
                                </div>
                                <h6 className='mt-3'>Certification 1 (EPA, NATE Certification, Any Cert</h6>
                            </div>
                            <CommonButton className="btn btn-primary" onClick= { () => uploadFilterWithDateShow() }>Certification 1 (EPA, NATE Certification, Any Cert</CommonButton>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={4} xxl={3}>
                    <div className="card documentUploadSec_card text-center h-100">
                        <div className="card-header border px-3">Test Doc</div>
                        <div className="card-inner h-100 p-3">
                            <div className='documentUploadSec_imgBox d-flex flex-column align-items-center justify-content-center mb-3'>
                                <div className='documentUploadSec_imgBox-img'>
                                    <ImageElement source="product-image.jpg" alt="document" />
                                </div>
                                <h6 className='mt-3'>Test Doc</h6>
                            </div>
                            <CommonButton className="btn btn-primary" onClick= { () => uploadFilterWithDateShow() }>Test Doc</CommonButton>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={uploadFilterWithDate}
        onHandleCancel={uploadFilterWithDateHide}
        title="Upload File"
      >
        <div className="row">
          <div className="col-md-12">
            <Dragger>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Work History/Resume</p>
            </Dragger>
          </div>
          <div className="col-md-12">
            <div className="nk-upload-list mt-2">
              <div className="nk-upload-item">
                <div className="nk-upload-icon">
                  <ImageElement source="uploadfile.svg" alt="img" />
                </div>
                <div className="nk-upload-info">
                  <div className="nk-upload-title">
                    <span className="title">Work History/Resume</span>
                  </div>
                  <div className="nk-upload-size">25.49 MB</div>
                </div>
                <div className="nk-upload-action">
                  <Link to="#" className="btn btn-icon btn-trigger">
                    <em className="icon ni ni-trash"></em>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
          <div className="form-group ">
              <label className="overline-title overline-title-alt">
              EXP. DATE
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  name="startDate"
                  className="form-control date-picker shadow-none"
                  placeholder="DD/MM/YY"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 text-end mt-2">
            <Link
              href="#"
              onClick={() => uploadFilterWithDateHide()}
              className="btn btn-primary me-2"
            >
              Save
            </Link>
            <Link
              href="#"
              onClick={() => uploadFilterWithDateHide()}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={uploadFilterWithOutDate}
        onHandleCancel={uploadFilterWithOutDateHide}
        title="Upload File"
      >
        <div className="row">
          <div className="col-md-12">
            <Dragger>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Work History/Resume</p>
            </Dragger>
          </div>
          <div className="col-md-12">
            <div className="nk-upload-list mt-2">
              <div className="nk-upload-item">
                <div className="nk-upload-icon">
                  <ImageElement source="uploadfile.svg" alt="img" />
                </div>
                <div className="nk-upload-info">
                  <div className="nk-upload-title">
                    <span className="title">Work History/Resume</span>
                  </div>
                  <div className="nk-upload-size">25.49 MB</div>
                </div>
                <div className="nk-upload-action">
                  <Link to="#" className="btn btn-icon btn-trigger">
                    <em className="icon ni ni-trash"></em>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-end mt-2">
            <Link
              href="#"
              onClick={() => uploadFilterWithOutDateHide()}
              className="btn btn-primary me-2"
            >
              Save
            </Link>
            <Link
              href="#"
              onClick={() => uploadFilterWithOutDateHide()}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

export default DocumentList;
