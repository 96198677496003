import { DatePicker, Select, Avatar} from 'antd';
import React, { useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  ListingHeader,
  FormElementWrapper,
  FormWrapper,
  PageHeader,
  TextInput,
  CommonButton,
  InputType,
  ModalComponent,
  ImageElement,
} from '../../../components';

function CreateManualBooking(props) {
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Manual Booking",
    }
  ];
  const countryData = [
    {
      name: 'india',
      value: 'India',
    },
    {
      name: 'unitedstates',
      value: 'United States',
    },
    {
      name: 'Srilanka',
      value: 'Srilanka',
    },
  ];
  const addressData = [
    {
      name: 'usa',
      value: '	19000 Grand Park Blvd, Westfield, IN 46074, USA',
    },
    {
      name: 'park',
      value: '	19000 Grand Park Blvd, Westfield, IN 46074',
    },
  ]
  const serviceData = [
    {
      name: 'heating',
      value: '	HVAC-FL - Heating System Tuneup ($128.98 - Schedule Later)',
    },
    {
      name: 'timer',
      value: 'HVAC-FREE TIMER YES QOUTE (free - Emergency)',
    },
  ]
  const jobData = [
    {
      name: 'Select Provider',
      value: 'Select Service Provider',
    },
    {
      name: 'provider',
      value: 'Send to All Available Providers (NAC) ',
    },
  ]
  const avaiabilityData = [
    {
      name: 'Available',
      value: 'Available',
    },
    {
      name: 'enroutetoJob',
      value: 'Enroute to Job',
    },
  ]
  const zoomLevel =[
    {
      name: '25',
      value: '25%',
    },
    {
      name: '50',
      value: '50%',
    },
  ];

  const [howWorksModal, sethowWorksModal] = useState(false);
  const howWorksModalShow = () => {
    sethowWorksModal(true);
  };
  const howWorksModalHide = () => {
    sethowWorksModal(false);
  };
  const [serviceInfoModal, setserviceInfoModal] = useState(false);
  const serviceInfoModalShow = () => {
    setserviceInfoModal(true);
  };
  const serviceInfoModalHide = () => {
    setserviceInfoModal(false);
  };
  const [bookingDateInfoModal, setbookingDateInfoModal] = useState(false);
  const bookingDateInfoModalShow = () => {
    setbookingDateInfoModal(true);
  };
  const bookingDateInfoModalHide = () => {
    setbookingDateInfoModal(false);
  };
  const [instructionInfoModal, setinstructionInfoModal] = useState(false);
  const instructionInfoModalShow = () => {
    setinstructionInfoModal(true);
  };
  const instructionInfoModalHide = () => {
    setinstructionInfoModal(false);
  };
  const [jobTypeInfoModal, setjobTypeInfoModal] = useState(false);
  const jobTypeInfoModalShow = () => {
    setjobTypeInfoModal(true);
  };
  const jobTypeInfoModalHide = () => {
    setjobTypeInfoModal(false);
  };
  const [bookingTimeInfoModal, setbookingTimeInfoModal] = useState(false);
  const bookingTimeInfoModalShow = () => {
    setbookingTimeInfoModal(true);
  };
  const bookingTimeInfoModalHide = () => {
    setbookingTimeInfoModal(false);
  };

  const [jobType, setJobType] = useState(true);
  const handleJobTypeChange = (value) => {
    if(value === 'Select Service Provider'){
      setJobType(true)
    }else{
      setJobType(false)
    }
  };

  const { Option } = Select;
  const items = [
    {
      label: 'Available', 
      color: '#78ac2c',
    },
    {
      label: ' Enroute to Job', 
      color: '#be0411',
    },
    {
      label: ' Reached Job', 
      color: '#4d93c7',
    },
    {
      label: ' Journey Started', 
      color: '#ffd50b',
    },
    {
      label: ' Offline', 
      color: '#6e6e6e',
    },
    ];

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Manual Booking">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
              btnArray={["create"]}
              btnText="How it works?"
              onHandleShow={howWorksModalShow}
            />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={3}
                  label="Country"
                  element={(formik) => (
                    <Select
                      name="Country"
                      placeholder="Select country"
                      options={countryData}
                      // showSearch
                      formik={formik}
                    />
                  )}
                />
            <FormElementWrapper
                  md={3}
                  label="Phone Number"
                  infoLabel="Plan-1"
                  element={(formik) => (
                    <div className="form-control-wrap">
                      <div className="input-group flex-nowrap">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">1268</span>
                        </div>
                        <InputType
                          className="form-control"
                          placeholder="Enter phone number "
                          name="documentType"
                          disabled={false}
                          variant="standard"
                          type="text"
                        />
                      </div>
                  </div>
                  )}
                />
                <FormElementWrapper
                  md={3}
                  label="First Name"
                  element={(formik) => (
                    <TextInput
                      name="fname"
                      placeholder="Enter first name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={3}
                  label="Last Name"
                  element={(formik) => (
                    <TextInput
                      name="lname"
                      placeholder="Enter last name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={3}
                  label="Email"
                  element={(formik) => (
                    <TextInput
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={3}
                  label="Address"
                  element={(formik) => (
                    <Select
                      name="state"
                      placeholder="Select address"
                      options={addressData}
                      // showSearch
                      formik={formik}
                    />
                  )}
                />
            </FormWrapper>
            <Row className='mt-3'>
              <Col lg={6}>
                <div className='border p-2 mt-lg-3'>
                  <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                      md={12}
                      label="Enter the location where user have requested for job"
                      element={(formik) => (
                        <TextInput
                          name="location"
                          placeholder="Enter job location"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      label="Address Line 2"
                      element={(formik) => (
                        <TextInput
                          name="AddressLine2"
                          placeholder="Enter address line 2"
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      label="Suite Number"
                      element={(formik) => (
                        <TextInput
                          name="SuiteNumber"
                          placeholder="Enter suite number"
                          formik={formik}
                          extraClassName="mb-4 mb-xl-5"
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      actionHandler={serviceInfoModalShow}
                      link="Info"
                      label="What type of service are you looking for?"
                      element={(formik) => (
                        <Select
                          name="serviceAreYouLookingFor"
                          placeholder="Select service Type"
                          options={serviceData}
                          // showSearch
                          formik={formik}
                        />
                      )}
                    />
                    <FormElementWrapper
                      actionHandler={bookingDateInfoModalShow}
                      link="Info"
                      md={12}
                      label="When would you like us to set your appointment for?"
                      element={(formik) => (
                        <DatePicker
                          name="date"
                          className="form-control date-picker shadow-none"
                          placeholder="DD/MM/YY"
                        />
                      )}
                    />
                    <FormElementWrapper
                      actionHandler={bookingTimeInfoModalShow}
                      link="Info"
                      md={12}
                      label="Which time frame would work best for you?"
                      element={(formik) => (
                        <ul className="custom-control-group">
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnCheck"
                                  id="btnCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnCheck1"
                                >
                                  05 AM - 08 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnCheck"
                                  id="btnCheck2"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnCheck2"
                                >
                                  08 AM - 11 AM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnCheck"
                                  id="btnCheck3"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnCheck3"
                                >
                                  11 AM - 02 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnCheck"
                                  id="btnCheck4"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnCheck4"
                                >
                                  02 PM - 05 PM
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnCheck"
                                  id="btnCheck5"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnCheck5"
                                >
                                  05 PM - 08 PM
                                </label>
                              </div>
                            </li>
                          </ul>
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      label="Special instructions"
                      actionHandler={instructionInfoModalShow}
                      link="Info"
                      element={(formik) => (
                        <textarea
                          name="textarea"
                          placeholder="Enter special instructions*"
                          formik={formik}
                          className="form-control"
                        />
                      )}
                    />
                    <FormElementWrapper
                      md={12}
                      label="Select job type should change to"
                      actionHandler={jobTypeInfoModalShow}
                      link="Info"
                      element={(formik) => (
                        <Select
                          name="SelectJobTypeShouldChangeTo"
                          placeholder="Select job Type"
                          options={jobData}
                          // showSearch
                          onChange={handleJobTypeChange}
                          formik={formik}
                          defaultValue="Select Service Provider"
                        />
                      )}
                    />
                    {jobType &&
                      <>
                      <FormElementWrapper
                        md={12}
                        label="Select a specific technician or first available technician"
                        element={(formik) => (
                          <TextInput
                            name="address"
                            placeholder="Type provider name to search from below list"
                            formik={formik}
                            showSearch
                          />
                        )}
                      />   
                      <div className="mt-4 col-md-12">
                        <div  className='table-responsive border'>
                          <Table size="lg">
                            <thead>
                              <tr>
                                <th className='w-30'>Provider</th>
                                <th className='w-30'>Number</th>
                                <th className='w-40'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                <div className="user-card">
                                          <div className="user-avatar bg-warning">
                                            <ImageElement source="a-sm.jpg" alt="user-img" />
                                            <span className="status dot dot-xxl dot-available"></span>
                                          </div>
                                          <div className="user-name">
                                            <span className="tb-lead">SP Android</span>
                                          </div>
                                        </div>
                                </td>
                                <td>+13528050035</td>
                                <td>
                                  <CommonButton className="btn btn-success">Assign Provider</CommonButton>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                <div className="user-card">
                                          <div className="user-avatar bg-warning">
                                            <ImageElement source="b-sm.jpg" alt="user-img" />
                                            <span className="status dot dot-xxl dot-offline"></span>
                                          </div>
                                          <div className="user-name">
                                            <span className="tb-lead">Matt Parsons </span>
                                          </div>
                                        </div>
                                </td>
                                <td>+19540784569</td>
                                <td>
                                <CommonButton className="btn btn-success">Assign Provider</CommonButton>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                <div className="user-card">
                                          <div className="user-avatar bg-warning">
                                            <ImageElement source="c-sm.jpg" alt="user-img" />
                                            <span className="status dot dot-xxl dot-enroute"></span>
                                          </div>
                                          <div className="user-name">
                                            <span className="tb-lead">Todd Scott </span>
                                          </div>
                                        </div>
                                </td>
                                <td>+17652010371</td>
                                <td>
                                  <CommonButton className="btn btn-success">Assign Provider</CommonButton>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                <div className="user-card">
                                          <div className="user-avatar bg-warning">
                                            <ImageElement source="d-sm.jpg" alt="user-img" />
                                            <span className="status dot dot-xxl dot-reached"></span>
                                          </div>
                                          <div className="user-name">
                                            <span className="tb-lead">Tom Patterson </span>
                                          </div>
                                        </div>
                                </td>
                                <td>+13528050035</td>
                                <td>
                                {/* <CommonButton className="btn btn-success">Assign Provider</CommonButton> */}
                                  <CommonButton className="btn btn-secondary">Will Call List</CommonButton>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          </div>
                      </div>
                      </>
                    }     
                  </FormWrapper>
                </div>
              </Col>
              <Col lg={6} className="mt-3 mt-lg-0">
                <div className='border p-2 mt-lg-3'>
                  <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={12}
                        label="Select Provider Availability"
                        element={(formik) => (
                          <Select
                            name="state"
                            placeholder="Select provider availability"
                            // options={avaiabilityData}
                            // showSearch
                            formik={formik}
                          >                          
                            {items.map((item) => (
                          <Option key={item.label} value={item.label} label={item.label}>
                            <div>
                              <Avatar size="small" className="me-1" style={{ background: `${item.color}` }}>
                              </Avatar>
                              {item.label}
                            </div>
                          </Option>
                        ))}
                        </Select>
                        )}
                      />
                        <FormElementWrapper
                        md={12}
                        label="Map Zoom Level"
                        element={(formik) => (
                          <Select
                            name="state"
                            placeholder="Select map zoom level"
                            options={zoomLevel}
                            showSearch
                            formik={formik}
                            disabled
                          />
                        )}
                      />
                  </FormWrapper>
                  <div className="card card-bordered w-100 mt-4">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004069.896900944!2d-104.65611544442767!3d37.27565371492453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1667816322585!5m2!1sen!2sin"  
                    width="100%" height="450" style={{ border:"0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='mt-4 text-end'>
              <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Book Now
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light"
                >
                  Reset
                </CommonButton>
            </div>
            </div>
          </div>
        </div>
        <div className='card mt-3'>
          <div className='card-inner'>
          <div className="bq-note">
              <div className="bq-note-text">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Notes:</h5>
                  </div>
                  <ul className="list list-sm list-checked">
                      <li>Administrator can Add / Edit Job later booking on this page.</li>
                      <li>Provider current availability is not connected with booking being made. Please confirm future avaialbility of Provider before doing booking.</li>
                      <li>Adding booking from here will not send request to Provider immediately.</li>
                      <li>In case of "Auto Assign Provider" option selected, Provider(s) get automatic request before 8-12 minutes of actual booking time.</li>
                      <li>In case of "Auto Assign Provider" option not selected, Provider(s) get booking confirmation sms as well as reminder sms before 30 minutes of actual booking. Provider has to start the scheduled Job by going to "Your Job" Upcoming section from Provider App.</li>
                      <li>In case of "Auto Assign Provider", the competitive algorithm will be followed instead of one you have selected in settings.</li>
                  </ul>
              </div>
          </div>
          </div>
        </div>
        <ModalComponent
          backdrop
          show={howWorksModal}
          onHandleCancel={howWorksModalHide}
          title="How It Works?"
          extraClassName="modal-lg"
        >
          <p>Flow : Through "Manual Booking" Feature, you can book providers for users who ordered for a Service by calling you. There will be users who may not have iPhone or Android Phone or may not have app installed on their phone. In this case, they will call Company (your company) and order service which may be needed immediately or after some time later.</p>
          <ul className="list list-sm list-checked">
            <li>Here, you will fill their info in the form and dispatch a service provider for them.</li>
            <li> If the user is already registered with us, just enter his phone number and his info will be fetched from the database when "Get Details" button is clicked. Else fill the form.</li>
            <li>Once the Job detail is added, estimate will be calculated based on Service or Service provider selected.</li>
            <li>In case of "Auto Assign Provider" option selected, Provider(s) get automatic request before 8-12 minutes of actual booking time.</li>
            <li>Clicking on "Book Now" Button, the Booking detail will be saved and will take Administrator to the "Scheduled Booking" Section. This page will show all such bookings.</li>
            <li>Both Provider and User will receive the booking details through Email and SMS as soon as the form is submitted. Based on this booking details, Provider will go to user's location at the scheduled time.</li>
            <li>They both will get the reminder SMS and Email as well before 30 minutes of actual job</li>
            <li>The assigned provider can see the upcoming Bookings from his App under "My Jobs" section.</li>
            <li>Provider will have option to "Start Job" when he reaches the Job Location at scheduled time or "Cancel Job" if he cannot take the job for some reason. If the provider clicks on "Cancel Job", a notification will be sent to Administrator so he can make alternate arrangements.</li>
            <li>Upon clicking on "Start Job", the service will start in provider's App in regular way.</li>
        </ul>
        <Link
                href="#"
                onClick={() => howWorksModalHide() }
                className="btn btn-secondary float-end"
            >
            Close
            </Link>
        </ModalComponent>
        <ModalComponent
          backdrop
          show={serviceInfoModal}
          onHandleCancel={serviceInfoModalHide}
          title="Service Type Info"
          extraClassName="modal-lg"
        >
          <p>Cost is associated next to each service types.No discounts apply if bookings via phone.For customer to get special promo pricing they should book online or use the Good Guys Home Services App Scheduled Bookings will provide an option to set a date and time. Emergency Same-day appointments will show you if any service techs are available.If technicians are available let the customer Know.We will add you to our will call list for today and call you 20-30 minutes before we arrive. If no techs are available recommend scheduling for the next day and time.</p>
          <CommonButton
                  type="submit"
                  className="btn btn-secondary float-end"
                  onClick={() => serviceInfoModalHide()}
                >
                  Close
                </CommonButton>
        </ModalComponent>
        <ModalComponent
          backdrop
          show={bookingDateInfoModal}
          onHandleCancel={bookingDateInfoModalHide}
          title="Booking Date Info"
        >
          <p>After selecting a date.The system will show you any available time frames for that day.</p>
          <CommonButton
                  type="submit"
                  className="btn btn-secondary float-end"
                  onClick={() => bookingDateInfoModalHide()}
                >
                  Close
                </CommonButton>
        </ModalComponent>
        <ModalComponent
          backdrop
          show={instructionInfoModal}
          onHandleCancel={instructionInfoModalHide}
          title="Special Instruction Info"
        >
          <p>Please include any information that should be communicated directly to the tech from the customer. Example: Gate codes, additional contact person or phone number,warranties,etc.</p>          
          <CommonButton
                  type="submit"
                  className="btn btn-secondary float-end"
                  onClick={() => instructionInfoModalHide()}
                >
                  Close
                </CommonButton>
        </ModalComponent>
        <ModalComponent
          backdrop
          show={jobTypeInfoModal}
          onHandleCancel={jobTypeInfoModalHide}
          title="Job Type Info"
        >
          <p>At the time some customers may request a specific technician.If it shows that technician as available,then assign the job to that person.If is doesn't show that technician available try a different date or time frame.Or confirm with the customer it is OK to set it up or the first available technician. If customer wants a specific technician and matching up times doesn't work.Recommend customer contacting technician directly and provide the customer with technicians phone number. First Available will send the job to all technicians open for that time frame. The first available tech will contact customer ASAP.</p>
              <CommonButton
              type="submit"
              className="btn btn-secondary float-end"
              onClick={() => jobTypeInfoModalHide()}
            >
              Close
            </CommonButton>
        </ModalComponent>
        <ModalComponent
          backdrop
          show={bookingTimeInfoModal}
          onHandleCancel={bookingTimeInfoModalHide}
          title="Booking Time Info"
        >
          <p>We provide 3 hour time windows but we will call or text 20-30 minutes before arrival.Pick the window that is closet to availability and type in time issues in the special instructions.</p>
          <CommonButton
                  type="submit"
                  className="btn btn-secondary float-end"
                  onClick={() => bookingTimeInfoModalHide()}
                >
                  Close
                </CommonButton>
        </ModalComponent>
    </>
  );
}

export default CreateManualBooking;
