import React from "react";
import {
  Breadcrumb,
  PageHeader,
  TablePlus,
} from "../../../components";
import {
  Columns,
   RequestsService,
} from "../../../services/index.service";

function ListRequest(props) {
  const RequestsOrderData = RequestsService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Unaccepted Requests",
    },
  ];
  const columns = Columns.RequestsTableColumn();

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Unaccepted Requests">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={RequestsOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isResetBtn= {false}
        showAction={false}
      />
    </>
  );
}

export default ListRequest;
