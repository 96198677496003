import { Button, Space } from 'antd';
import Input from 'antd/lib/input/Input';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../UIElements/DatePicker';
import { FormElementWrapper, FormWrapper } from '../../Wrappers';


function DateFilter({ dateType, setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex }) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  return (
    <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DatePicker
          name="StartDate"
          className="form-control date-picker shadow-none"
          placeholder={`From ${dataIndex}`}
          picker={dateType}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
        />
        <DatePicker
          name="EndDate"
          className="form-control date-picker shadow-none"
          placeholder={`To ${dataIndex}`}
          picker={dateType}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className='btn btn-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 90,
            }}
          >
            SEARCH
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            className='btn btn-info'
            style={{
              width: 90,
            }}
          >
            RESET
          </Button>
        </Space>
      </div>
    // <FormWrapper row={true} extraClassName="g-3">
    //     <FormElementWrapper
    //       md={6}
    //       label="From Date"
    //       extraLabelClass="overline-title overline-title-alt"
    //       element={(formik) => (
    //         <DatePicker
    //           name="startDate"
    //           className="form-control date-picker shadow-none"
    //           placeholder="DD/MM/YY"
    //         />
    //       )}
    //     />
    //     <FormElementWrapper
    //       md={6}
    //       label="To Date"
    //       extraLabelClass="overline-title overline-title-alt"
    //       element={(formik) => (
    //         <DatePicker
    //           name="EndDate"
    //           className="form-control date-picker shadow-none"
    //           placeholder="DD/MM/YY"
    //         />
    //       )}
    //     />
        
    // </FormWrapper>
  );
}

export default DateFilter;
