export const HomeInventoryServices = () => {
    return [
      {
        id: 1,
        providerCompleted: "jorge neon",
        dateEquipmentAdded: "Wed, November 16, 2022 03:14 PM",
        customerName: "Shane Watson",
        customerNumber: "(+91) 4564564564",
        customerEmail: "shanewatson@mailinator.com",
        equipmentInstalled: 'Air Conditioner',
        brand: "Tuhygh",
        model: "gygfhj",
        serial: "guittui",
        installationDate: "	2022",
        applicationType: "Residential-Single-Family",
        typeOfPurchase: 'Replacement',
        systemCondition: 'New',
        systemNotes: 'Fytjhyuujj',
        photosUploaded: '/admin/home-inventory/view-images'
      },
      {
        id: 2,
        providerCompleted: "kam test",
        dateEquipmentAdded: "Wed, November 16, 2022 03:20 PM",
        customerName: "John Testing",
        customerNumber: "(+91) 7869995023",
        customerEmail: "johnt@mailinator.com",
        equipmentInstalled: 'Air Conditioner',
        brand: "80% Gas",
        model: "63737",
        serial: "guittui",
        installationDate: "	2022",
        applicationType: "Residential-Single-Family",
        typeOfPurchase: 'Replacement',
        systemCondition: 'Average',
        systemNotes: 'Fytjhyuujj',
        photosUploaded: '/admin/home-inventory/view-images'
      },
      {
        id: 3,
        providerCompleted: "Arpit Jai",
        dateEquipmentAdded: "Wed, November 16, 2022 03:14 PM",
        customerName: "	Fread WEB",
        customerNumber: "(+91) 5555555553",
        customerEmail: "cusweb@yopmail.com",
        equipmentInstalled: 'Air Conditioner',
        brand: "80% Gas Furnace",
        model: "gygfhj",
        serial: "guittui",
        installationDate: "	2022",
        applicationType: "Residential-Single-Family",
        typeOfPurchase: 'Replacement',
        systemCondition: 'New',
        systemNotes: 'Fytjhyuujj',
        photosUploaded: '/admin/home-inventory/view-images'
      },
      {
        id: 4,
        providerCompleted: "Test Provider",
        dateEquipmentAdded: "Fri, July 8, 2022 11:29 AM",
        customerName: "Test Customer",
        customerNumber: "(+1) 9977997799",
        customerEmail: "testc@mailinator.com",
        equipmentInstalled: '80% Gas Furnace',
        brand: "Test",
        model: "Test",
        serial: "Test",
        installationDate: "	2021",
        applicationType: "Residential-Single-Family",
        typeOfPurchase: 'Replacement',
        systemCondition: 'New',
        systemNotes: 'Test',
        photosUploaded: '/admin/home-inventory/view-images'
      },
      {
        id: 5,
        providerCompleted: "jorge neon",
        dateEquipmentAdded: "Wed, November 16, 2022 03:14 PM",
        customerName: "Shane Watson",
        customerNumber: "(+91) 4564564564",
        customerEmail: "shanewatson@mailinator.com",
        equipmentInstalled: 'Air Conditioner',
        brand: "Tuhygh",
        model: "gygfhj",
        serial: "guittui",
        installationDate: "	2022",
        applicationType: "Residential-Single-Family",
        typeOfPurchase: 'Replacement',
        systemCondition: 'New',
        systemNotes: 'Fytjhyuujj',
        photosUploaded: '/admin/home-inventory/view-images'
      },
    ];
  };
  