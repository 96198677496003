
import { DatePicker } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormElementWrapper,FormWrapper,CommonButton,Select } from '../../../index';

function ServiceCategoryFilterForm() {
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  return (
    <>
      <div className='dropdown-body-rg'>
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
          md={12}
          label="Location"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="state"
              placeholder="Select location"
              options={locationData}
              showSearch
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="From Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="startDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="To Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="EndDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
        
    </FormWrapper>
      </div>
      <div className="dropdown-foot between">
        <Link to="#" type="button" className="clickable">
          Reset
        </Link>

        <CommonButton extraClassName="btn btn-sm btn-primary" htmlType="submit">
          Apply
        </CommonButton>
      </div>    
    </>
  );
}

export default ServiceCategoryFilterForm;
