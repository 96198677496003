
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import {  Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Charts,
  CommonButton,
  TablePlus,
  FormWrapper,
  FormElementWrapper,
  AntTextArea,
  ServiceProviderPaymentFilterForm,
  Select,
  DatePicker,
  TextInput,
  Tabs,
  SweetAlert,
} from '../../../../../components';
import { Columns, MarketingReviewTemplateService } from '../../../../../services/index.service';
import { PaymentReportServices } from '../../../../../services/PaymentReport';
import { modalNotification } from '../../../../../utils';


function ViewMarketingReviewTemplate(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Marketing Review Template',
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
      setIsAlertVisible(false);
      modalNotification({ type: "success", message: "Message Approved Successfully", })
      return true;
  };
  const [isAlertVisible1, setIsAlertVisible1] = useState(false);
  const onConfirmAlert1 = () => {
      setIsAlertVisible1(false);
      modalNotification({ type: "success", message: "Message Declined Successfully", })
      return true;
  };

  //Job tags
  const [TagsModalShow, setTagsModalShow] = useState(false);
  const viewTagsModalShow = () => {
    setTagsModalShow(true)
  }
  const hideTagsModalShow = () => {
    setTagsModalShow(false)
  }
  //View Modules
  const [modulesModalShow, setModulesModalShow] = useState(false);
  const viewModulesModalShow = () => {
    setModulesModalShow(true)
  }
  const hideModulesModalShow = () => {
    setModulesModalShow(false)
  }
  //View Services
  const [servicesModalShow, setServicesModalShow] = useState(false);
  const viewServicesModalShow = () => {
    setServicesModalShow(true)
  }
  const hideServicesModalShow = () => {
    setServicesModalShow(false)
  }
  //Message
  const [messageModalShow, setMessageModalShow] = useState(false);
  const viewMessageModalShow = () => {
    setMessageModalShow(true)
  }
  const hideMessageModalShow = () => {
    setMessageModalShow(false)
  }
  //approve
    function approveModalShow() {
        setIsAlertVisible(true);
    }
    //Decline
    function declineModalShow() {
        setIsAlertVisible1(true);
    }
    // View Report
    const navigate = useNavigate()
    const reportModalShow = (e) =>{
        e.preventDefault();
        navigate('/admin/marketing-template/marketing-review/report');
      }

  const PaymentReportData = MarketingReviewTemplateService();
  const columns = Columns.MarketingReviewTemplateTableColumn({viewTagsModalShow,viewModulesModalShow,viewMessageModalShow,approveModalShow,declineModalShow,reportModalShow,viewServicesModalShow});
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]

  
    
    
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Marketing Review Template">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            btnText="Unsubscribe User List"
            onHandleShow={ () => navigate('/admin/marketing-template/marketing-review/unsubscribe-user') }
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
        
                <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
      </div>
        <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={PaymentReportData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          selectRow={false}
          isSearch={false}
          showAction={false}
          isResetBtn = {false}
        />
        <SweetAlert
        title="Are you sure"
        text="you want to approve this message?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to decline this message?"
        show={isAlertVisible1}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible1}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert1}
      />
        <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Marketing Review Templates module will list all templates on this page.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        <ModalComponent
            backdrop
            show={TagsModalShow}
            onHandleCancel={hideTagsModalShow}
            title="Customer & Job Tags"
        >
            <ul className="list list-sm list-checked">
            <li><b>Customer Tags:- </b> Air Cleaner, Humidifier</li>
            <li><b>Job Tags:- </b> Humidifier J, service J tag, test</li>
            </ul>
            <div className='text-end'>
            <CommonButton className="btn btn-secondary" onClick= { () => hideTagsModalShow() }>Close</CommonButton>
            </div>
        </ModalComponent>
        <ModalComponent
            backdrop
            show={modulesModalShow}
            onHandleCancel={hideModulesModalShow}
            title="Modules"
        >
            <ul className="list list-sm list-checked">
            <li><b>Modules:- </b> Will Call List</li>
            </ul>
            <div className='text-end'>
            <CommonButton className="btn btn-secondary" onClick= { () => hideModulesModalShow() }>Close</CommonButton>
            </div>
        </ModalComponent>
        <ModalComponent
            backdrop
            show={servicesModalShow}
            onHandleCancel={hideServicesModalShow}
            title="Services"
        >
            <ul className="list list-sm list-checked">
            <li><b>Services:- </b> N/A</li>
            </ul>
            <div className='text-end'>
            <CommonButton className="btn btn-secondary" onClick= { () => hideServicesModalShow() }>Close</CommonButton>
            </div>
        </ModalComponent>
        <ModalComponent
            backdrop
            show={messageModalShow}
            onHandleCancel={hideMessageModalShow}
            title="Message Template"
        >
            <h6><b>Subject:</b> Customer re-engagement email</h6>
            <p>Hey there!
                I noticed you haven’t logged into your account in quite some time, so I just wanted to take a moment to check in with you and see if there’s anything I can help you with.
                Feel free to continue working on your account at www.companywebsite.com and please reach out to me if I can be of any assistance.
                If you need a refresher on how [Product/ Service] works, check out our quick user guide.
                Hope to see you back again soon,
                [YOUR SIGNATURE]
            </p>
            <div className='text-end'>
                <CommonButton className="btn btn-secondary" onClick= { () => hideMessageModalShow() }>Close</CommonButton>
            </div>
        </ModalComponent>
    </>
  );
}

export default ViewMarketingReviewTemplate;
