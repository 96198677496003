export const promoCodeService = () => {
  return [
    {
      id: 1,
      giftCertificateCode: "TEST1234",
      discount: "15.00 %",
      companyName: "My Store",
      validity: "Custom",
      activationDate: "22nd June, 2021",
      expiryDate: "30th June, 2021",
      usageLimit: "25",
      used: "2",
      status:"Toggle"
    },
    {
      id: 2,
      giftCertificateCode: "first",
      discount: "50.00 $",
      companyName: "ABC Company",
      validity: "Permanent",
      activationDate: "22nd June, 2021",
      expiryDate: "30th June, 2021",
      usageLimit: "1000",
      used: "0",
      status:"Toggle"
    },
    {
      id: 3,
      giftCertificateCode: "10POFF",
      discount: "15.00 %",
      companyName: "My Store",
      validity: "Permanent",
      activationDate: "22nd June, 2021",
      expiryDate: "30th June, 2021",
      usageLimit: "500",
      used: "5",
      status:"Toggle"
    },
    {
      id: 4,
      giftCertificateCode: "Hurray",
      discount: "20.00 $",
      companyName: " My Store",
      validity: "Custom",
      activationDate: "22nd June, 2021",
      expiryDate: "30th June, 2021",
      usageLimit: "25",
      used: "0",
      status:"Select"
    }
  ];
};
