import { Outlet } from 'react-router-dom';
import { AddProducts, CategoryList, CouponManagementList, EditProducts, OrderInvoice, OrderManagementList, ProductsList, SubCategoryList, VariantList } from '../../views/ShoppingCart';


export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Shopping Cart',
      key: 'shoppingCart',
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.SHOPPING_CART_CATEGORY.icon,
      element: <Outlet />,
      children: [
        {
            private: true,
            name: 'Manage Category',
            key: 'shoppingCart.categoryManagement',
            belongsToSidebar: true,
            element: <Outlet />,
            submenu: true,
            children: [
                {
                    private: true,
                    path: accessRoute.SHOPPING_CART_CATEGORY.path,
                    name: 'Category ',
                    key: 'shoppingCart.categoryManagement.category',
                    belongsToSidebar: true,
                    element: <CategoryList />,
                },
                // {
                //     private: true,
                //     path: accessRoute.SHOPPING_CART_SUB_CATEGORY.path,
                //     name: 'Sub Category',
                //     key: 'shoppingCart.categoryManagement.subCategory',
                //     belongsToSidebar: true,
                //     element: <SubCategoryList />,
                // },
            ],
        },
        {
            private: true,
            name:accessRoute.VARIANTS_LIST.name,
            key:"shoppingCart.variants",
            path:accessRoute.VARIANTS_LIST.path,
            belongsToSidebar: true,
            element: <VariantList/>
        },
        {
            private: true,
            name:accessRoute.PRODUCTS_LIST.name,
            key:"shoppingCart.products",
            path:accessRoute.PRODUCTS_LIST.path,
            belongsToSidebar: true,
            element: <ProductsList/>
        },
        {
            private: true,
            name:accessRoute.PRODUCTS_ADD.name,
            key:"shoppingCart.products",
            path:accessRoute.PRODUCTS_ADD.path,
            belongsToSidebar: false,
            element: <AddProducts/>
        },
        {
          private: true,
          name:accessRoute.PRODUCTS_EDIT.name,
          key:"shoppingCart.products",
          path:accessRoute.PRODUCTS_EDIT.path,
          belongsToSidebar: false,
          element: <EditProducts/>
      },
      {
        private: true,
        name:accessRoute.COUPON_MANAGEMENT_LIST.name,
        key:"shoppingCart.coupon",
        path:accessRoute.COUPON_MANAGEMENT_LIST.path,
        belongsToSidebar: true,
        element: <CouponManagementList/>
        },
        {
          private: true,
          name:accessRoute.ORDER_MANAGEMENT_LIST.name,
          key:"shoppingCart.orders",
          path:accessRoute.ORDER_MANAGEMENT_LIST.path,
          belongsToSidebar: true,
          element: <OrderManagementList/>
        },
        {
          private: true,
          name:accessRoute.ORDER_MANAGEMENT_INVOICE.name,
          key:"shoppingCart.orders",
          path:accessRoute.ORDER_MANAGEMENT_INVOICE.path,
          belongsToSidebar: false,
          element: <OrderInvoice/>
        },
      ],
    },
  ];
}

export const accessRoute = {
  SHOPPING_CART_CATEGORY: {
    path: '/admin/category-management',
    name: 'Category',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-cart-fill" />
      </span>
    ),
  },
  SHOPPING_CART_SUB_CATEGORY: {
    path: "/admin/subcategory-management",
    name: "Sub Category",
  },
  VARIANTS_LIST: {
    path: "/admin/variant-management",
    name: "Variants Management",
  },
  PRODUCTS_LIST: {
    path: "/admin/product-management",
    name: "Product Management",
  },
  PRODUCTS_ADD: {
    path: "/admin/product-management/add",
    name: "Add Product",
  },
  PRODUCTS_EDIT: {
    path: "/admin/product-management/edit",
    name: "Edit Product",
  },
  COUPON_MANAGEMENT_LIST: {
    path: "/admin/coupon-management",
    name: "Coupon Management",
  },
  ORDER_MANAGEMENT_LIST: {
    path: "/admin/order-management",
    name: "Order Management",
  },
  ORDER_MANAGEMENT_INVOICE: {
    path: "/admin/order-management/invoice",
    name: "Order Management Invoice",
  },
};

/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
 return [...route()[0]?.children];
};
