import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  ModalComponent,
  ImageElement,
  TablePlus,
} from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns,
  providerService,
} from "../../../../services/index.service";
import { modalNotification } from "../../../../utils"; 

function ProviderListing(props) {
  const navigate = useNavigate()

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Providers",
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Providers Deleted Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };


  const customerData = providerService();
  const AddProviders = () =>{
    navigate('/admin/provider/add')
  }
  const EditProviders = () =>{
    navigate('/admin/provider/edit')
  }
  const [profileView, setprofileView] = useState(false);
  const profileViewShow = () => {
    setprofileView(true);
  };
  const profileViewHide = () => {
    setprofileView(false);
  };
  const ViewEditProviders = (e) =>{
    e.preventDefault();
    navigate('/admin/provider/edit');
    setprofileView(false);
    // EditProviders();
  }
  const columns = Columns.providerTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{EditProviders()},
        },
        // {
        //   name: "View",
        //   icon: "icon ni ni-eye",
        //   handler: () =>{profileViewShow(true)},
        // },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
      profileViewShow
  });
  
    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Inactive'
      },
      {
      id: 3,
      name: 'Make Delete'
      }
    ]  

  
  return(
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Providers">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create", "csvExport"]}
            btnText="Add Provider"            
            onHandleShow={AddProviders}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}

      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Provider module will list all Provider on this page.</li>
                <li> Administrator can Activate / Deactivate / Delete any Provider .</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Providers"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={profileView}
        onHandleCancel={profileViewHide}
        title="Provider Details"
      >
      <div>
        <div className="user-card user-card-s2 p-0 mb-2">
            <div className="user-avatar lg bg-primary">
                <ImageElement source="uploadImg.png" alt="img"/>
            </div>
        </div>
        <div className="row g-3">
            <div className="col-6">
                <span className="sub-text">Provider Name</span>
                <span>animesh singh</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Email</span>
                <span className="text-break">animesh@mailinator.com</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Phone Number</span>
                <span>+91-9865328754</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Rating</span>
                <span>	No ratings received</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Status</span>
                <span className="badge badge-xs rounded-pill bg-danger display-inline-block">Inactive</span>
                
            </div>
            <div className="col-6">
                <span className="sub-text">Registration Date</span>
                <span>Monday, Nov 07 th 2022</span>
            </div>
            <div className="col-12">
                <span className="sub-text">Address</span>
                <span>India</span>
            </div>
        </div>
        <div className="text-end mt-2">
            <Link
                href="#"
                onClick={(e) => ViewEditProviders(e) }
                className="btn btn-primary me-2"
            >
            Edit
            </Link>
            <Link
                href="#"
                onClick={() => profileViewHide() }
                className="btn btn-secondary"
            >
            Cancel
            </Link>
        </div>
      </div>
      </ModalComponent>       
    </>
  );
}

export default ProviderListing;
