import { Button, Select, Space } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function MultiSelectFilter({ placeholderValue=false, setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex, list }) {
  const { t } = useTranslation();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  return (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
    <div style={{ position: 'relative' }}>
      <Select getPopupContainer={trigger => trigger.parentElement}
        ref={searchInput}
        placeholder={placeholderValue ? `Select ${dataIndex}` : `Search ${dataIndex}`}
        value={selectedKeys}
        onChange={(e) => setSelectedKeys(e ?  e: [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        mode="multiple"
        showArrow={true}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      >
        {list.map((item, key) => (
          <Option
            key={key}
            value={item.value || item.id}
            disabled={item?.disabled || false}
          >
            {item.name}
          </Option>
        ))}
      </Select>
      </div>
      <Space>
        <Button
          type="primary"
          className='btn btn-primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 90,
          }}
        >
          SEARCH
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          className='btn btn-info'
          style={{
            width: 90,
          }}
        >
          RESET
        </Button>
      </Space>
    </div>
  );
}

export default MultiSelectFilter;
