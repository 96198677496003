import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  ServiceCategoryFilterForm,
} from '../../../../../components';

function ServiceCategoryReport(props) {

  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service-category',
      name: 'Service Category',
    },
    {
      path: '#',
      name: 'Service Category Report',
    },
  ];
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'serviceCategory',
      title: 'Service Category',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'quantity',
      title: 'Quantity  ',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'averageTicket',
      title: 'Average Ticket',
      headerClasses: 'sorting',
      sorter: true,
    },
  ];
  const adminData = [
    {
      id: 1,
      serviceCategory: 'Have A Technician Contact You Today',
      quantity: '	49',
      averageTicket: '	185498.45',
    },
    {
      id: 2,
      serviceCategory: '	Service Repair',
      quantity: '	40',
      averageTicket: '	97326.69',
    },
    {
      id: 3,
      serviceCategory: 'Testing Zero value Sales Quote YES Selected',
      quantity: '	20',
      averageTicket: '	185498.45',
    },
    {
      id: 4,
      serviceCategory: 'Subcategory Q Y SCH',
      quantity: '	14',
      averageTicket: '	9043.09',
    },
    {
      id: 5,
      serviceCategory: '	Install Blocker',
      quantity: '	10',
      averageTicket: '	205498.45',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Service Category Report">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['filter']}
            popover={<ServiceCategoryFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
    </>
  );
}

export default ServiceCategoryReport;
