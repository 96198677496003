import { InputFilter, SelectFilter, Toggle } from '../../components';
import { FilterFilled  } from "@ant-design/icons";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getLocationForData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Vehicle Type',
      value: 'Vehicle Type',
    },
  ];
};

const getStatusData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
}

export const geoLocationTableColumn = ({ Action }) => {
  console.log('Action', Action);
  return [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'locationName',
      title: 'Location Name',
      headerClasses: 'sorting',
        sorter: true,
      ...getColumnSearchProps("location name")
    },
    {
      dataIndex: 'country',
      title: 'Country',
      headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("country")
    },
    {
      dataIndex: 'locationFor',
      title: 'Location For',
      headerClasses: 'sorting',
        sorter: true,
        ...getColumnSelectSearchProps("location for", getLocationForData())
    },
    {
      dataIndex: 'permitCost',
      title: 'Pull Permit Cost ($)',
      headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("Pull Permit Cost ($)")
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
        sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
};
