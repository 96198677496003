import React from 'react';
import { Form, Checkbox as AntCheckbox } from 'antd';

function CheckBox({ element, ...rest }) {
  return (
    <Form.Item valuePropName="checked" {...rest}>
      <AntCheckbox>{element}</AntCheckbox>
    </Form.Item>
  );
}

export default CheckBox;
