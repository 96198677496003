import { Select } from 'antd';
import React, {useState} from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Tabs,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  AntTextArea,
} from '../../../../components';

function AdminPaymentMethod(props) {
    const [defaultKey, setDefaultKey] = useState('check');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/payment-report',
      name: 'Payment Report',
    },
    {
      path: '#',
      name: 'Admin Payment Methods',
    },
  ];
  const monthsData = [
    {
      name: '01',
      value: '01',
    },
    {
      name: '02',
      value: '02',
    },
    {
    name: '03',
    value: '03',
    },
    {
    name: '04',
    value: '04',
    },
  ]
  const yearsData = [
    {
      name: '2019',
      value: '2019',
    },
    {
      name: '2020',
      value: '2020',
    },
    {
    name: '2021',
    value: '2021',
    },
    {
    name: '2022',
    value: '2022',
    },
  ]
  const tabContent = [
    {
      name: 'Check',
      key: 'check',
      content: (
        <div className='card'>
            <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Bank Name"
                        element={(formik) => (
                            <TextInput
                            name="bankName"
                            placeholder="Enter bank name"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Check Number"
                        element={(formik) => (
                            <TextInput
                            name="CheckNumber"
                            placeholder="Enter check number"
                            formik={formik}
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
      ),
    },
    {
      name: 'Financing',
      key: 'financing',
      content: (
        <div className='card'>
            <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                                name="amountToPay"
                                placeholder="Enter amount to pay"
                                formik={formik}
                                type="number"
                                defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Bank Name"
                        element={(formik) => (
                            <TextInput
                            name="bankName"
                            placeholder="Enter bank name"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Financing Confirmation Number"
                        element={(formik) => (
                            <TextInput
                            name="CheckNumber"
                            placeholder="Enter financing confirmation number"
                            formik={formik}
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
      ),
    },
    {
        name: 'Credit Card',
        key: 'creditCard',
        content: (
        <div className='card'>
            <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Card Holder"
                        element={(formik) => (
                            <TextInput
                            name="cardHolder"
                            placeholder="Enter card holder"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label=" Your Card Number"
                        element={(formik) => (
                            <TextInput
                            name="YourCardNumber"
                            placeholder="Enter your card number"
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Exp. Month"
                        element={(formik) => (
                            <Select
                            name="expMonth"
                            placeholder="Select exp. month"
                            options={monthsData}
                            showSearch
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="Exp. Year"
                        element={(formik) => (
                            <Select
                            name="expYear"
                            placeholder="Select exp. year"
                            options={yearsData}
                            showSearch
                            formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={4}
                        label="CVV"
                        element={(formik) => (
                            <TextInput
                            name="CVV"
                            placeholder="Enter cvv"
                            formik={formik}
                            type="number"
                            />
                        )}
                    />
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
      {
        name: 'Cash',
        key: 'cash',
        content: (
        <div className='card'>
            <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <h6 className='title text-primary mb-0'>Collecting Cash</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
      {
        name: 'Warranty/No Charge',
        key: 'warrantyNoCharge',
        content: (
            <div className='card'>
            <div className='card-inner'>
                    <p>This would allow admin to close out the job if the job has a zero for total.</p>
                    <h6 className='title text-primary mb-0'>Warranty/No Charge</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
            </div>
        </div>
        ),
      },
      {
        name: 'Admin Waive Cost',
        key: 'adminWaiveCost',
        content: (
            <div className='card'>
            <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={4}
                        label="Amount To Pay"
                        element={(formik) => (
                            <TextInput
                            name="amountToPay"
                            placeholder="Enter amount to pay"
                            formik={formik}
                            type="number"
                            defaultValue="100.01"
                                disabled={true}
                            />
                        )}
                    />
                    <h6 className='title text-primary mb-0'>Admin Waived</h6>
                    <div className='mt-4 text-end'>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                        Submit
                        </CommonButton>
                    </div>
                </FormWrapper>
            </div>
        </div>
        ),
      },
  ];
  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Admin Payment Methods">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/payment-report')}
          />
        </div>
      </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
                <h5 className='mb-2'>Admin Attention Required Reason</h5>
              <FormWrapper row={true} extraClassName="g-3">               
                <FormElementWrapper
                    md={12}
                    element={(formik) => (
                        <AntTextArea
                        name="description"
                        className="form-control"
                        placeholder="admin attention required reason"
                        defaultValue="test"
                    />
                    )}
                />
              </FormWrapper>
            </div>
          </div>
        </div>
        <br />
        <Tabs
            tabContent={tabContent}
            tabsFor="table"
            activeKey={defaultKey}
            setActiveKey={setDefaultKey}
            // onTabChange={onTabChange}
        />
    </>
  );
}

export default AdminPaymentMethod;
