import axios from 'axios';
import { notification } from 'antd';

// axios.interceptors.request.use(function (config) {
//     console.log("Config", config);
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    notification['error']({
      message: error?.response?.data?.message || error?.code || '',
      // description: error?.response?.data?.message error?.message
    });
    return Promise.reject(error);
  }
);
