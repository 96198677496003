import React from 'react';
import {
  FormWrapper,
  FormElementWrapper,
  CommonButton,
  Password,
} from '../../../index';
import { initValues, validation } from './validation';

function ChangePasswordForm({ onSubmit, onCancel }) {
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validation}
      initialValues={initValues()}
    >
      <FormElementWrapper
        label="Current Password"
        element={(formik) => (
          <Password name="currentPassword" formik={formik} />
        )}
      />
      <FormElementWrapper
        label="New Password"
        element={(formik) => <Password name="newPassword" formik={formik} />}
      />
      <FormElementWrapper
        label="Confirm Password"
        element={(formik) => (
          <Password name="confirmPassword" formik={formik} />
        )}
      />
      <div className="align-center justify-content-center flex-wrap flex-sm-nowrap gx-4 gy-2">
        <CommonButton type="submit" className="btn btn-lg btn-primary">
          Update
        </CommonButton>
        <CommonButton onClick={onCancel} className="btn btn-lg btn-light">
          Cancel
        </CommonButton>
      </div>
    </FormWrapper>
  );
}

export default ChangePasswordForm;
