export const NewEquipmentInstallServices = () => {
    return [
      {
        id: 1,
        jobNumber: "75833147",
        jobStatus: "Finished",
        jobDate: "Wed, November 2, 2022 04:10 PM",
        provider: "kam test",
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerEmail: "johnt@mailinator.com",
        customerAddress: "LIG Square Indore",
        totalCost: "$ 90.00",
        equipmentInstalled: "	80% Gas Furnace & Air Conditioner",
        permitLocation: "-",
        permitStatus: '',
        warrantyRegistered: '',
        laborWarrantyPurchased: '',
        lastUpdatedSave: "",
      },
      {
        id: 2,
        jobNumber: "75833147",
        jobStatus: "Canceled",
        jobDate: "Wed, November 2, 2022 04:10 PM",
        provider: "kam test",
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerEmail: "johnt@mailinator.com",
        customerAddress: "LIG Square Indore",
        totalCost: "$ 90.00",
        equipmentInstalled: "Air Conditioner",
        permitLocation: "-",
        permitStatus: '',
        warrantyRegistered: '',
        laborWarrantyPurchased: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
      {
        id: 3,
        jobNumber: "75833147",
        jobStatus: "In Progress",
        jobDate: "Wed, November 2, 2022 04:10 PM",
        provider: "kam test",
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerEmail: "johnt@mailinator.com",
        customerAddress: "LIG Square Indore",
        totalCost: "$ 90.00",
        equipmentInstalled: "90+% Furnace",
        permitLocation: "-",
        permitStatus: '',
        warrantyRegistered: '',
        laborWarrantyPurchased: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
      {
        id: 4,
        jobNumber: "75833147",
        jobStatus: "Finished",
        jobDate: "Wed, November 2, 2022 04:10 PM",
        provider: "kam test",
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerEmail: "johnt@mailinator.com",
        customerAddress: "LIG Square Indore",
        totalCost: "$ 90.00",
        equipmentInstalled: "	80% Gas Furnace & Air Conditioner",
        permitLocation: "-",
        permitStatus: '',
        warrantyRegistered: '',
        laborWarrantyPurchased: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
    ];
  };
  