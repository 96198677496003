export const productManagementService = () => {
    return [
        {
            id: 1,
            productName: "AC",
            productCategory: "Electrical",
            productQuantity: "5",
            productPrice: '$100',
        },
        {
            id: 2,
            productName: "freeze",
            productCategory: "Electronics",
            productQuantity: "10",
            productPrice: '$120',
        },
        {
            id: 3,
            productName: "cooler",
            productCategory: "Electrical",
            productQuantity: "1",
            productPrice: '$50',
        },
        {
            id: 4,
            productName: "Air purifier",
            productCategory: "Electronics",
            productQuantity: "1",
            productPrice: '$10',
        },
        {
            id: 5,
            productName: "Bluetooth speaker",
            productCategory: "Electrical",
            productQuantity: "15",
            productPrice: '$12',
        },
    ];
  };
  