import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TextEditor,
} from '../../../../components';
import {  useNavigate } from 'react-router-dom';



function EditSMS(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/sms-templates',
      name: 'SMS Templates',
    },
    {
      path: '#',
      name: 'Edit SMS Templates',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/sms-templates')
  }
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit SMS Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                label=" Purpose"
                mandatory={true}
                element={(formik) => (
                    <TextInput
                        name="purpose"
                        placeholder="Enter purpose"
                        formik={formik}
                        defaultValue="When admin book a job on behalf of customer"
                    />
                )}
            />
            <FormElementWrapper
                md={12}
                label="Body"
                mandatory={true}
                element={(formik) => (
                    <TextEditor>
                         Good Guys #PARENT_NAME# - #SERVICE_NAME# - appointment confirmed for #BOOKING_DATE#. Your service provider #PROVIDER_NAME# 
                          #CUSTOMER_NAME#
                          #PROVIDER_NAME#
                          #PROVIDER_PHONE_NUMBER#
                          #SERVICE_NAME#
                          #PARENT_NAME#
                          #JOB_NO#
                          #BOOKING_DATE#
                          #STATUS#
                    </TextEditor> 
                )}
                formnote="Note :- Please Don't Replace Variable Between # Sign."
            />
            <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                    <>
                          <CommonButton
                              type="submit"
                              className="btn btn-primary me-2"
                              >
                              Update
                          </CommonButton>
                        <CommonButton
                            type="submit"
                            className="btn btn-light"
                            >
                            Reset
                        </CommonButton>
                    </>
                )}
            />
        </FormWrapper>
                    
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default EditSMS;
