import {MultiSelectFilter, Toggle, SelectFilter, InputFilter, AmountFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const membershipTableColumn = ({ Action }) => {
  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getActionData = () => {
    return [      
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "title",
      title: "Title",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("title"),
    },
    {
      dataIndex: "monthlyCost",
      title: "Monthly Cost",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectAmountProps("monthly cost")
    },
    {
      dataIndex: "yearlyOneTimeCost",
      title: "Yearly One Time Cost",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectAmountProps("yearly one time cost")
    },
    {
      dataIndex: "additionalDiscount",
      title: "Additional Discount",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("additional discount"),
    },
      // {
      //   dataIndex: "lengthOfMembership",
      //   title: "Length of Membership",
      //   headerClasses: "sorting",
      //   sorter: true,
      //   ...getColumnSearchProps("length of membership"),
      // },
      {
        dataIndex: "displayOrder",
        title: "Display Order ",
        headerClasses: "sorting",
        ...getColumnSearchProps("display order"),
        sorter: true,
      },
      {
        dataIndex: "description",
        title: "Description",
        headerClasses: "sorting",
        ...getColumnSearchProps("description"),
        sorter: true,
      },
      {
        dataIndex: "status",
        title: "Status",
        headerClasses: "sorting",
        render:()=><Toggle isDropDown/>,
        sorter: true,
        ...getColumnSelectSearchProps("status", getActionData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
