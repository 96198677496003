export const tagsService = () => {
  return [
    {
      id: 1,
      tagName: "Air Cleaner",
      tagType: "Customer",
    },
    {
      id: 2,
      tagName: "Air Purifier",
      tagType: "Job",
    },
    {
      id: 3,
      tagName: "Air Quality",
      tagType: "Customer",
    },
    {
      id: 4,
      tagName: "Cooling",
      tagType: "Customer",
    },
    {
      id: 5,
      tagName: "Electrician",
      tagType: "Customer",
    },
    {
      id: 6,
      tagName: "Generator",
      tagType: "	Job",
    },
    {
      id: 7,
      tagName: "	Heating System",
      tagType: "Customer",
    }
  ];
};
