export const geoLocationService = () => {
  return [
    {
      id: 1,
      locationName: 'Anderson/Muncie, Indiana',
      country: 'United States',
      locationFor: 'Service Type',
      permitCost: '-',
    },
    {
      id: 2,
      locationName: 'Arizona- Texas',
      country: 'United States',
      locationFor: 'Service Type',
      permitCost: '0.00',
    },
    {
      id: 3,
      locationName: 'Bangalore',
      country: '	India',
      locationFor: 'Service Type',
      permitCost: '-',
    },
    {
      id: 4,
      locationName: 'Central Indiana',
      country: 'United States',
      locationFor: 'Service Type',
      permitCost: '12.00',
    },
    {
      id: 5,
      locationName: 'Cincinnati Metro area',
      country: 'United States',
      locationFor: 'Service Type',
      permitCost: '500.00',
    },
  ];
};
