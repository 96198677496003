import React, { useState } from "react";
import {
  Breadcrumb,  
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  TablePlus,
} from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns,
  proceduresService,
} from "../../../services/index.service";
import { modalNotification } from "../../../utils"; 

function ListProcedureLesson(props) {
  const navigate = useNavigate()

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Standard Operating Procedures",
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Record Deleted Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }

  const statusActionOption = [
    {
      id: 1,
        name: 'Select Action'
      },
      {
        id: 2,
        name: 'Make Active'
      },
      {
        id: 3,
        name: 'Make Inactive'
      },
      {
        id: 4,
        name: 'Make Delete'
      }
  ]

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.proceduresTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{EditLessonPage()},
        },
        {
          name: "Add Question",
          icon: "icon ni ni-plus-round",
          handler: () =>{AddQuestionsPage()},
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
  });

  const proceduresData = proceduresService();
  const EditLessonPage = () =>{
    navigate('/admin/standard-operating-procedures/edit')
  }
  const AddQuestionsPage = () => {
    navigate('/admin/standard-operating-procedures/add-question');
  }
  const AddLessonPage = () =>{
    navigate('/admin/standard-operating-procedures/add');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Standard Operating Procedures">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ "create", ""]}
            btnText="Add Lesson"            
            // popover={<CustomersFilterForm/>}
            onHandleShow={AddLessonPage}
          />
        </div>
      </div>      
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={proceduresData}
        tableColumns={columns}
        showAction={false}
        isSearch={false}
        statusAction
        statusActionOption={statusActionOption}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />      
      <SweetAlert
        title="Delete Records?"
        html="Are you sure you want to delete? <div class='mt-2 text-danger'><small> Caution: Once a record is deleted it cannot be recovered</small> </div>"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        onConfirmAlert={onConfirmAlert}
      />          
    </>
  );
}

export default ListProcedureLesson;
