

import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  Select
} from "../../../../components";

function AddStore(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/store')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/store",
      name: "Promo Code Good Guys Affiliate",
    },
    {
      path: "#",
      name: "Add Promo Code Good Guys Affiliate",
    },
  ];
  const locationTypeData = [
    {
      name: 'Indore',
      value: 'Indore',
    },
    {
      name: 'Dewas',
      value: 'Dewas',
    },
    {
      name: 'Bhopal',
      value: 'Bhopal',
    },
  ]
  const countryTypeData = [
    {
      name: 'India',
      value: 'India',
    },
    {
      name: 'United States',
      value: 'United States',
    },
  ]
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Promo Code Good Guys Affiliate">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6}
                label="Name or Company Name"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="companyName"
                    placeholder="Enter name or company name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Email"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="code"
                    placeholder="Enter email"
                    formik={formik}
                    type="email"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Password"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="password"
                    placeholder="Enter password"
                    formik={formik}
                    type="password"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Profile Picture"
                element={(formik) => (
                  <TextInput
                    name="ProfilePicture"
                    placeholder="Select profile picture"
                    formik={formik}
                    type="file"
                  />
                )}
                imgPreviewBox={true}
              />
              <FormElementWrapper
                md={6}
                label="Country"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="country"
                    placeholder="Select country"
                    options={countryTypeData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Location"
                mandatory={true}
                element={(formik) => (
                  <Select
                    name="location"
                    placeholder="Select location"
                    options={locationTypeData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Phone"
                mandatory={true}
                element={(formik) => (
                  <div className="form-control-wrap">
                    <div className="input-group flex-nowrap">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">1</span>
                      </div>
                      <TextInput
                        name="phone"
                        placeholder="Enter phone"
                        formik={formik}
                        type="number"
                      />
                    </div>
                </div>
                )}
              />
              <FormElementWrapper
                md={6}
                label="Commission Fee (In Dollar)"
                element={(formik) => (
                  <TextInput
                    name="fee"
                    placeholder="Enter commission fee (in Dollar)"
                    formik={formik}
                    type="number"
                  />
                )}
              /> 
              <FormElementWrapper
                md={6}
                label="Payment Email"
                element={(formik) => (
                  <TextInput
                    name="PaymentEmail"
                    placeholder="Enter payment email"
                    formik={formik}
                    type="email"
                  />
                )}
              />  
              <FormElementWrapper
                md={6}
                label="Account Holder Name"
                element={(formik) => (
                  <TextInput
                    name="AccountHolderName"
                    placeholder="Enter account holder name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Account Number"
                element={(formik) => (
                  <TextInput
                    name="AccountNumber"
                    placeholder="Enter account number"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Name of Bank"
                element={(formik) => (
                  <TextInput
                    name="BankName"
                    placeholder="Enter name of bank"
                    formik={formik}
                  />
                )}
              /> 
              <FormElementWrapper
                md={6}
                label="Bank Location"
                element={(formik) => (
                  <TextInput
                    name="BankLocation"
                    placeholder="Enter bank location"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="BIC/SWIFT Code"
                element={(formik) => (
                  <TextInput
                    name="BICSWIFTCode"
                    placeholder="Enter BIC/SWIFT code"
                    formik={formik}
                  />
                )}
              />  
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Add
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
            </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default AddStore;
