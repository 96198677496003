export const storeService = () => {
    return [
      {
        id: 1,
        companyName: "Test Company",
        email: "aditi1@yopmail.com",
        mobile: "(+91) 8978451203",
        totalAmount: "100",
        pendingAmount: "0.00",
        transferAmount: "70",
        viewEditDocument: "icon ni ni-file-doc",
        documentURL: "/admin/store/documents"
      },
      {
        id: 2,
        companyName: "Test1",
        email: "Test@goodguyshomeservices.com",
        mobile: "(+) 11122321232",
        totalAmount: "150",
        pendingAmount: "50.00",
        transferAmount: "20",
        viewEditDocument: "icon ni ni-file-doc",
        documentURL: "/admin/store/documents"
      },
      {
        id: 3,
        companyName: "Aditi Company Test",
        email: "aditig@mailinator.com",
        mobile: "(+91) 8978451203",
        totalAmount: "300",
        pendingAmount: "50.00",
        transferAmount: "250",
        viewEditDocument: "icon ni ni-file-doc",
        documentURL: "/admin/store/documents"
      },
      {
        id: 4,
        companyName: "Stephen Famolaro",
        email: "thefamolaros@yahoo.com",
        mobile: "(+1) 18152452231",
        totalAmount: "0",
        pendingAmount: "0.00",
        transferAmount: "0.00",
        viewEditDocument: "icon ni ni-file-doc",
        documentURL: "/admin/store/documents"
      }
    ];
  };
  