import React from 'react';
import { Link } from 'react-router-dom';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
} from '../../../../index';
import { initValues, validation } from './validation';

function AdminBankDetails({ onSubmit }) {
  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={validation}
      initialValues={initValues()}
      row={true}
      extraClassName="g-3"
    >
      <FormElementWrapper
        md={6}
        label="Bank Name  *"
        element={(formik) => (
          <TextInput name="bankName" placeholder="Bank name " formik={formik} extraClassName="form-control-lg"/>
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Account Holder Name "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="accountHolder"
            placeholder="Bank account holder name"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Account Number "
        mandatory="true"
        element={(formik) => (
          <TextInput
            inputType="number"
            name="accountNumber"
            placeholder="Account number"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Account Routing Number"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="routingNumber"
            placeholder="Bank account routing number"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Bank Location"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="bankLocation"
            placeholder="Bank location"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Email Address "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="email"
            inputType="email"
            placeholder="Email address"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <div className='text-center'>
        <div className="form-group">
          <Link to="/nca/successfully-register"
            type="submit"
            className="btn btn-lg btn-primary"
          >
            Save & Next
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
}

export default AdminBankDetails;
