import React, {useState} from "react";
import { Table } from "react-bootstrap";
import {  Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  ListingHeader,
  ModalComponent,
  PageHeader,

} from "../../../components";

function BookingView(props) {
  const navigate = useNavigate()
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/service",
      name: "Service Management",
    },
    {
      path: "#",
      name: "Booking Log",
    },
  ];
  const backPage = () =>{
    navigate('/admin/service')
  }
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const viewNoteModalShow = () => {
    setViewNoteModal(true);
  };
  const viewNoteModalHide = () => {
    setViewNoteModal(false);
  };
  return (
    <>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Booking Log">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <h5 className="title mb-4">Schedule Booking No: 14940636</h5>
              <div className="table-responsive">
                <Table bordered size="lg">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Log</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>11/11/2022 at 03:50 PM</td>
                      <td>	Job Booked by Customer at 11/11/2022 at 03:50 PM (Shane Watson by Web)</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>11/11/2022 at 03:51 PM</td>
                      <td>Job sent to NAC by User for jorge neon <Link to="#" onClick={ viewNoteModalShow }>(View Note)</Link> </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>11/11/2022 at 03:51 PM</td>
                      <td>Send to NAC Notification sent to Provider jorge neon</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>11/11/2022 at 03:51 PM</td>
                      <td>Send to NAC SMS sent to Provider jorge neon</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>11/11/2022 at 04:00 PM</td>
                      <td>	System sent email to Admin notifying of 5 minutes job has been in NAC for jorge neon</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          backdrop
          show={viewNoteModal}
          onHandleCancel={viewNoteModalHide}
          title="Reschedule Note"
        >
          <p>test</p>
          <CommonButton
                  type="submit"
                  className="btn btn-secondary float-end"
                  onClick={() => viewNoteModalHide()}
                >
                  Close
                </CommonButton>
        </ModalComponent>
      </>
  );
}

export default BookingView;
