import { Form, Input as FormInput, Select } from 'antd';
import { useField } from 'formik';

function Input({
  name,
  icon,
  setFieldValue,
  formik,
  inputType = 'text',
  extraClassName = '',
  ...rest
}) {
  const [field, meta] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta?.touched && meta?.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <FormInput.Group compact style={{ display: 'flex' }}>
      <Select defaultValue="+91">
        <Select.Option value="+91">+1</Select.Option>
      </Select>
      <Form.Item
        label={rest?.label}
        name={name}
        help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
        validateStatus={config?.error ? 'error' : 'success'}
        style={{ width: '100%' }}
      >
        <FormInput
          onChange={formik.handleChange}
          type="number"
          {...rest}
          className={`form-control ${extraClassName && extraClassName}`}
        />
      </Form.Item>
    </FormInput.Group>
  );
}

export default Input;
