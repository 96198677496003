import { Outlet } from 'react-router-dom';
import { ListRequest } from '../../views/Request';

export default function route() {
  return [
    {
      path: accessRoute.REQUEST.path,
      private: true,
      name: 'Requests',
      key: "requests",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.REQUEST.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.REQUEST.path,
        name: 'requests',
        key: accessRoute.REQUEST.key,
        belongsToSidebar: false,
        icon: accessRoute.REQUEST.icon,
        element: <ListRequest />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  REQUEST: {
    path: '/admin/requests',
    key:"requests.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-reply-all-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
