import {MultiSelectFilter, Toggle, SelectFilter, InputFilter, AmountFilter, AntSelect } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const productsManagementTableColumn = ({ Action }) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getActionData = () => {
    return [      
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
      {
        name: 'Out of stock',
        value: 'Out Of Stock',
      },
    ];
  };
  const statusData = [
    {
      id: 'Active',
      name: 'Active',
    },
    {
      id: 'Inactive',
      name: 'Inactive',
    },
    {
      id: 'OutOfStock',
      name: 'Out of stock',
    },
  ];
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
        dataIndex: "productName",
        title: "Product Name",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("product name"),
    },
    {
        dataIndex: "productCategory",
        title: "Product Category",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("product category"),
    },
    {
        dataIndex: "productQuantity",
        title: "Product Quantity",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("product quantity"),
    },
    {
        dataIndex: "productPrice",
        title: "Product Price",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("product price"),
    },
    // {
    //     dataIndex: "productImage",
    //     title: "Product Image",
    //     headerClasses: "sorting",
    //     sorter: true,
    //     render: (cell, row) => {
    //         return <>
    //             <div className="table-img">
    //                 <img src='/assets/images/admin/product-image.jpg' alt="img" />
    //             </div>
    //     </>
    //     }
    // },
    // {
    //     dataIndex: "companyName",
    //     title: "Company Name",
    //     headerClasses: "sorting",
    //     sorter: true,
        
    // },
    // {
    //     dataIndex: "category",
    //     title: "Category",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "variants",
    //     title: "Variants",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "price",
    //     title: "Price",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "availableQty",
    //     title: "Available Qty.",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    // {
    //     dataIndex: "productStatus",
    //     title: "Product Status",
    //     headerClasses: "sorting",
    //     sorter: true,
    // },
    {
    dataIndex: "status",
    title: "Status",
    headerClasses: "sorting",
    sorter: true,
    ...getColumnSelectSearchProps("status", getActionData()),
    render:(cell, row)=>{
      console.log("Details", cell)
      return <>
          <AntSelect
              size="medium"
              id="status"
              extraClassName="form-control"
              name="statusId"
              disabled={false}
              variant="standard"
              placeholder="Select status"
              options={statusData}
          />
    </>
  }
    },
    {
    dataIndex: "action",
    title: "Action",
    className: "nk-tb-col-tools text-end",
    render: () => <Action />
    },
  ];
};
