import { Outlet } from 'react-router-dom';
import ListGeoLocation from '../../views/ManageLocation/GeoLocation/List';
import EditGeoLocation from '../../views/ManageLocation/GeoLocation/Edit';
import { AddLocation } from '../../views/ManageLocation';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Manage Locations',
      key: "location",
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.MANAGE_LOCATION.icon,
      element: <Outlet />,
      children: [
        {
          private: true,
          path: accessRoute.MANAGE_LOCATION.path,
          name: 'Geo Fence Location',
          belongsToSidebar: true,
          key: "location.list",
          // icon: accessRoute.MANAGE_LOCATION.icon,
          element: <ListGeoLocation />,
        },
        {
          private: true,
          path: accessRoute.ADD_LOCATION.path,
          name: 'Add Location',
          belongsToSidebar: false,
          key: "location.list",
          // icon: accessRoute.MANAGE_LOCATION.icon,
          element: <EditGeoLocation />,
        },
        {
          private: true,
          path: accessRoute.EDIT_LOCATION.path,
          name: 'Add Location',
          belongsToSidebar: false,
          key: "location.list",
          // icon: accessRoute.MANAGE_LOCATION.icon,
          element: <AddLocation />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  MANAGE_LOCATION: {
    path: '/admin/manage-location',
    isSecure: true,
    belongsToSidebar: true,
    name: 'Manage Location',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-map-pin-fill" />
      </span>
    ),
  },
  ADD_LOCATION: {
    path: '/admin/manage-location/add',
    isSecure: true,
    belongsToSidebar: true,
    name: 'Add Location',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
  EDIT_LOCATION: {
    path: '/admin/manage-location/edit',
    isSecure: true,
    belongsToSidebar: true,
    name: 'Edit Location',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
