import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Toggle,
  ActionWrapper,
  SweetAlert,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TablePlus,
  SelectFilter,
  InputFilter,
} from '../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../utils';
import Select from '../../../../components/UIElements/Select';
import { Radio } from 'antd';
import { FilterFilled  } from "@ant-design/icons";

function DocumentListing(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Delete Documents Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Manage Documents',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getDocumentForData = () => {
    return [
      {
        name: 'Provider',
        value: 'Provider',
      },
      {
        name: '	Store',
        value: '	Store',
      }
    ];
  };
  
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'country',
      title: 'Country ',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSearchProps("country")
    },
    {
      dataIndex: 'documentName',
      title: 'Document Name ',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSearchProps("document name")
    },
    {
      dataIndex: 'documentFor',
      title: 'Document For ',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectSearchProps("document for", getDocumentForData())
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
       sorter: true,
      render: () => <Toggle isDropDown />,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () => {
          editAdminModalShow(true);
        },
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      country: '	India',
      documentName: '	Certification 1 (EPA, NATE Certification, Any Cert',
      documentFor: 'Provider',
    },
    {
      id: 2,
      country: '	India',
      documentName: '	Driver License',
      documentFor: 'Provider',
    },
    {
      id: 3,
      country: '	United States',
      documentName: '	Driver License Front',
      documentFor: 'Store',
    },
    {
      id: 4,
      country: '	United States',
      documentName: '	Work History/Resume',
      documentFor: 'Store',
    },
    {
      id: 5,
      country: '	India',
      documentName: '	Workers Comp Waiver',
      documentFor: 'Provider',
    },
  ];
  const [addAdminModal, setaddAdminModal] = useState(false);
  const addAdminModalShow = () => {
    setaddAdminModal(true);
  };
  const addAdminModalHide = () => {
    setaddAdminModal(false);
  };
  const [editAdminModal, seteditAdminModal] = useState(false);
  const editAdminModalShow = () => {
    seteditAdminModal(true);
  };
  const editAdminModalHide = () => {
    seteditAdminModal(false);
  };
  const documentForData = [
    {
      name: 'store',
      value: 'Store',
    },
    {
      name: 'provider',
      value: 'Provider',
    },
  ];
  const countryData = [
    {
      name: 'india',
      value: 'India',
    },
    {
      name: 'unitedState',
      value: 'United Stattes',
    },
  ];

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
    id: 3,
    name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }
  ]  
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Manage Documents">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport']}
            btnText="Add Document Name"
            onHandleShow={addAdminModalShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}

      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Documents"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                  Document List module will list all document lists on this
                  page.
                </li>
                <li>
                  Administrator can Activate / Deactivate / Delete any document
                  list.
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={addAdminModal}
        onHandleCancel={addAdminModalHide}
        title="Add Document Name"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={6}
            label="Document For"
            mandatory="true"
            element={(formik) => (
              <Select
                name="doumentFor"
                placeholder="Select document for "
                options={documentForData}
                // showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Country"
            mandatory="true"
            element={(formik) => (
              <Select
                name="country"
                placeholder="Select country  "
                options={countryData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Expire On Date"
            mandatory="true"
            tooltip={true}
            tooltipText="Yes option will ask for Date"
            element={(formik) => (
              <Radio.Group value={value} onChange={onChange}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            )}
          />
          <FormElementWrapper
            md={6}
            label="Document Name"
            mandatory="true"
            tooltip={true}
            tooltipText="Name of Document for admin use. e.g. Insurance, Driving Licence... etc"
            element={(formik) => (
              <TextInput
                name="documentName"
                placeholder="Enter document name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Document Name (English)"
            mandatory="true"
            tooltip={true}
            tooltipText="Name of Document as per language. e.g. Insurance, Driving Licence... etc"
            element={(formik) => (
              <TextInput
                name="documentNameEnglish"
                placeholder="Enter document name"
                formik={formik}
              />
            )}
          />
          
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addAdminModalHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addAdminModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editAdminModal}
        onHandleCancel={editAdminModalHide}
        title="Edit Document Name"
      >
        <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={6}
            label="Document For"
            mandatory="true"
            element={(formik) => (
              <Select
                name="doumentFor"
                placeholder="Select document for "
                options={documentForData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Country"
            mandatory="true"
            element={(formik) => (
              <Select
                name="country"
                placeholder="Select country  "
                options={countryData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Document Name"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="documentName"
                placeholder="Enter document name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Document Name (English)"
            mandatory="true"
            element={(formik) => (
              <TextInput
                name="documentNameEnglish"
                placeholder="Enter document name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Expire On Date"
            mandatory="true"
            element={(formik) => (
              <Radio.Group value={value} onChange={onChange}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            )}
          />
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => editAdminModalHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editAdminModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default DocumentListing;
