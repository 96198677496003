export const PaymentReportServices = () => {
    return [
        {
            id: 1,
            provider: "jorge neon",
            user: "Shane Watson",
            jobDate: "15th November, 2022 06:35 PM",
            location: "Vijay Nagar, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "$0.00",
            purchaseOrderTotal: "",
            sublaborTotalFees: "",
            returnedPO: "",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Request",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "",
          },
          {
            id: 2,
            provider: "jorge neon",
            user: "Shane Watson",
            jobDate: "17th November, 2022 04:16 PM",
            location: "Vijay Nagar, Indore",
            totalFees: "$ 99.95",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "$0.00",
            purchaseOrderTotal: "",
            sublaborTotalFees: "",
            returnedPO: "",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Request",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "",
          },
          {
            id: 3,
            provider: "jorge neon",
            user: "Shane Watson",
            jobDate: "19th November, 2022 06:35 PM",
            location: "Vijay Nagar, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "$0.00",
            purchaseOrderTotal: "",
            sublaborTotalFees: "",
            returnedPO: "",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Card",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "",
          },
          {
            id: 4,
            provider: "jorge neon",
            user: "Shane Watson",
            jobDate: "19th November, 2022 06:35 PM",
            location: "Vijay Nagar, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Cash",
            jobPaymentType: "Split Payment",
            providerPaymentStatus: "Settled",
            selectAll : "",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 5,
            provider: "Fread WEB",
            user: "	Chris ios",
            jobDate: "2nd January, 2023 01:34 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Check",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 6,
            provider: "Ron TestA",
            user: "Fread WEB",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Financing",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 7,
            provider: "Ron TestA",
            user: "Chrissy Test",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Warranty",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 8,
            provider: "Ron TestA",
            user: "Chrissy Test",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Admin",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 9,
            provider: "Ron TestA",
            user: "Chrissy Test",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Admin",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 10,
            provider: "Ron TestA",
            user: "Chrissy Test",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Admin",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
          {
            id: 11,
            provider: "Ron TestA",
            user: "Chrissy Test",
            jobDate: "30th December, 2022 03:18 PM",
            location: "Rau, Indore",
            totalFees: "$ 5,400.00",
            platformFees: "-",
            promoCodeDiscount: "$ 0.00	",
            providerDiscountAmount: "",
            purchaseOrderTotal: "$5.00",
            sublaborTotalFees: "$ 500.00",
            returnedPO: "$ 200.00",
            providerPayAmount: "$ 4,700.00	",
            jobStatus: "Finished",
            jobStartTime: "15th November, 2022 06:35:59	",
            jobEndTime: "16th November, 2022 03:15:54",
            jobCompletionTime: "20h 39m 55s",
            paymentMethod: "Admin",
            jobPaymentType: "Full Payment",
            providerPaymentStatus: "Unsettled",
            selectAll : "View Invoice",
            paymentTransferStatus: "Transfered",
          },
    ];
  };
  