import { Link } from "react-router-dom";
import {  DateFilter, InputFilter, SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnSelectDateProps = (dataIndex) => ({
  filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});

const getProviderNCAData = () => {
  return [
    {
      name: 'Good Guys Admin',
      value: 'Good Guys Admin',
    },
    {
      name: 'Adhistha Company',
      value: 'Adhistha Company',
    },
  ];
};

const getStatusData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
}

export const logoFormatter = (path, text,url) => {
    return (
      <>
        {path ? (
          <div className="text-center">
            <Link to={url}><em className={path} style={{fontSize: '30px'}}/></Link>
          </div>
        ) : (
          text
        )}
      </>
    );
  };

   const ButtonFormatter = (url, text) => {
    return (
      <>
        <div className="text-center">
          <Link to={url} className="btn btn-primary">{text}</Link>
        </div>
      </>
    );
  };

  const ModalOpenFormatter = ( url , text) => {
    return (
        <>
            <Link to="#" onClick={url} className="text-primary">{text}</Link>
        </>
    );
  };


export const providerTableColumn = ({ Action, profileViewShow }) => {
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "providerName",
      title: "Provider Name",
      headerClasses: "sorting",
      sorter: true,
      render: (cell, row) => ModalOpenFormatter(profileViewShow, row?.providerName),
      ...getColumnSearchProps("provider name")
    },
    {
      dataIndex: "email",
      title: "Email",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email")
    },
    {
      dataIndex: "signupDate",
      title: "Signup Date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("date")
    },
    {
        dataIndex: "mobile",
        title: "Mobile",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("mobile")
      },
      {
        dataIndex: "providerNCA",
        title: "Provider NCA ",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectSearchProps("provider NCA", getProviderNCAData())
      },
      {
        dataIndex: "viewEditDocument",
        title: "Documents",
        headerClasses: "sorting",
        sorter: true,
        render: (cell, row) => logoFormatter(row?.viewEditDocument,"Image", row?.documentURL),
      },
      {
        dataIndex: "manageServices",
        title: "Manage Services",
        headerClasses: "sorting",
        sorter: true,
        render: (cell, row) => logoFormatter(row?.manageServices,"Image", row?.servcieURL),
      },
      {
        dataIndex: "viewEditAvailabileUnavailable",
        title: "Availability/Unavailability",
        headerClasses: "sorting",
        sorter: true,
        render: (cell, row) => ButtonFormatter("/admin/provider/availability", "View / Edit"),
      },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
      sorter: true,
      render:()=><Toggle isDropDown shouldDeleteInclude/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: "action",
      title: "Action",
      headerClasses: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
};
