import React from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  CommonButton,
  ImageElement,
} from '../../../../components';

function OrderInvoice(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/order-management',
      name: 'Order Management',
    },
    {
      path: '#',
      name: 'Order Invoice',
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Order Invoice">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/order-management')}
          />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
                <div className='table-responsive'>
                    <table style={{"width":"100%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{"width":"100%"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        Jorge Neon
                                                    </span>
                                                    <span>jorge@mailinator.com</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{"width":"100%", "marginTop": "10px", "marginBottom": "10px"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>
                                                        Order ID:</span>
                                                    75833147<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>
                                                        Order Date:</span>
                                                    10/10/2023<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>
                                                        Order Type: </span>
                                                        Shipping<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>Shipping Address </span> : Vijay Nagar, Indore, Madhya
                                                    Pradesh 452010, India<br />
                                                    
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}} >Payment Mode </span>: Cash <br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}} >Order Status </span>: Placed
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 mt-3'>
                                        <tbody>
                                            <tr>
                                                <td style={{"color": "#364a63" , "fontWeight": "bold"}}>Products Items (2 product)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th width="80" style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Image</th>
                                                <th width="750" style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Name</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Variant</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Quantity</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Price</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9", "textAlign": "right"}}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{"margin": "0 0 7px"}}>
                                                        <ImageElement
                                                        source="jacket.jpg"
                                                        className="img-fluid"
                                                        alt="jacket"
                                                        width="80"
                                                    />
                                                </td>
                                                <td style={ {"paddingLeft" : "15px"} }>
                                                    <span
                                                        style={{"padding" :"10px 0" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>Jacket </span>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </td>
                                                <td style={{"paddingLeft" : "15px", "color": "#364a63"}}>L</td>
                                                <td style={{"paddingLeft" : "15px", "color": "#364a63"}}>1</td>
                                                <td style={{"paddingLeft" : "15px"}}>$500.00</td>
                                                <td style={{"textAlign":"right", "paddingLeft" : "15px"}}>$500.00</td>
                                            </tr>
                                            <tr>
                                                <td style={{"margin": "0 0 7px"}}>
                                                        <ImageElement
                                                        source="jacket.jpg"
                                                        className="img-fluid"
                                                        alt="jacket"
                                                        width="80"
                                                    />
                                                </td>
                                                <td style={ {"paddingLeft" : "15px"} }>
                                                    <span
                                                        style={{"padding" :"10px 0" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>T-shirt </span>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </td>
                                                <td style={{"paddingLeft" : "15px", "color": "#364a63"}}>M, XL, Blue</td>
                                                <td style={{"paddingLeft" : "15px", "color": "#364a63"}}>1</td>
                                                <td style={{"paddingLeft" : "15px"}}>$100.00</td>
                                                <td style={{"textAlign":"right", "paddingLeft" : "15px"}}>$100.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='w-100 mt-2'>
                                        <tbody>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63", "fontWeight": "bold"}}>Coupon Discount</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>-$5</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Shipping Charges</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$2</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Total
                                                    </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$597</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>SP Notes
                                                    </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>                        
            </div>
          </div>
          <div className='text-center mt-3 mt-lg-4'>
              <CommonButton className="btn btn-primary">E-mail</CommonButton>
              <CommonButton className="btn btn-secondary ms-2">Print</CommonButton>
            </div>
        </div>
    </>
  );
}

export default OrderInvoice;
