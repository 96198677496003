import { AmountFilter, DateFilter, InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
// import {getTupleStatusList} from "../../services/index.service";
import { Checkbox } from '../../components/Antd';

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnSelectDateProps = (dataIndex) => ({
  filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});

const getColumnSelectAmountProps = (dataIndex) => ({
  filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});

const getProviderListData = () => {
  return [
    {
      name: 'abhishek Yadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditi Test',
      value: 'Aditi Test',
    },
  ];
};
const getPartTitleData = () => {
  return [
    {
      name: 'American Home ShieldR22',
      value: 'American Home Shield - Repair R22',
    },
    {
      name: 'FLHVAC',
      value: 'FLHVAC - Replace Capacitor on Tune-up',
    },
  ];
};
const getPartNumberData = () => {
  return [
    {
      name: 'QA 125',
      value: 'QA 125',
    },
    {
      name: '	QA 126',
      value: '	QA 126',
    },
  ];
};
const getOrderData = () => {
  return [
    {
      name: 'Yes',
      value: 'Yes',
    },
    {
      name: 'No',
      value: 'No',
    },
  ];
};
const getSupplyHouseData = () => {
  return [
    {
      name: '	MP House',
      value: 'MP House',
    },
    {
      name: 'Chillers And Compressor',
      value: 'Chillers and Compressor',
    },
  ];
};
const getPOOptionData = () => {
  return [
    {
      name: 'N/A ',
      value: 'N/A ',
    },
    {
      name: 'New Part',
      value: 'New Part',
    },
    {
      name: 'Warranty',
      value: 'Warranty',
    },
    {
      name: 'Tools/ Misc',
      value: 'Tools/ Misc',
    },
  ];
};
const getPaymentTypeData = () => {
  return [
    {
      name: 'N/A ',
      value: 'N/A ',
    },
    {
      name: 'Credit Account',
      value: 'Credit Account',
    },
    {
      name: 'Cash',
      value: 'Cash',
    },
    {
      name: 'Warranty',
      value: 'Warranty',
    },
  ];
}

export const TruckOrderTableColumn = () => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
          dataIndex: "orderNo",
          title: "Order No.",
          headerClasses: "sorting",
          sorter: true,
          ...getColumnSearchProps("order number"),
        },
        {
          dataIndex: "orderDate",
          title: "Order Date",
          headerClasses: "sorting",
          sorter: true,
          ...getColumnSelectDateProps("date"),
        },
        {
          dataIndex: "providerName",
          title: "Provider Name",
          headerClasses: "sorting",
          ...getColumnMultiSelectSearchProps("provider name", getProviderListData()),
          sorter: true,
        },
        {
          dataIndex: 'partTitle',
          title: 'Part Title',
          headerClasses: 'sorting',
          ...getColumnMultiSelectSearchProps("part title", getPartTitleData()),
          sorter: true,
        },
        {
          dataIndex: 'partNumber',
          title: 'Part Number',
          headerClasses: 'sorting',
          ...getColumnMultiSelectSearchProps("part number", getPartNumberData()),
          sorter: true,
        },
        {
          dataIndex: 'partCost',
          title: 'Part Cost',
          headerClasses: 'sorting',
          sorter: true,
          ...getColumnSelectAmountProps("price")
        },
        {
          dataIndex: 'ordered',
          title: 'Ordered',
          headerClasses: 'sorting',
          ...getColumnSelectSearchProps("order", getOrderData()),
          sorter: true,
        },
        {
          dataIndex: 'adminOrder',
          title: 'Admin Order',
          headerClasses: 'sorting',
          sorter: true,
          render:(cell, row)=>{
            console.log("Details", cell)
            if(cell !== ""){ 
              return <>{cell}</>
            }
            else{
              return <><Checkbox /></>
            }
          }
        },
        {
          dataIndex: 'supplyHouse',
          title: 'Supply House',
          headerClasses: 'sorting',
          sorter: true,
          ...getColumnMultiSelectSearchProps("supply house", getSupplyHouseData()),
        },
        {
          dataIndex: 'poOption',
          title: 'PO Option',
          headerClasses: 'sorting',
          sorter: true,
          ...getColumnSelectSearchProps("PO option", getPOOptionData()),
        },
        {
          dataIndex: 'paymentType',
          title: 'Payment Type',
          headerClasses: 'sorting',
          sorter: true,
          ...getColumnSelectSearchProps("payment type", getPaymentTypeData()),
        },
        {
          dataIndex: 'removeParts',
          title: 'Remove Parts',
          className: 'sorting',
          sorter: true,
          render:(cell, row)=>{
            console.log("Details", cell)
            if(cell !== ""){ 
              return <> - </>
            }
            else{
              return <><Checkbox /></>
            }
          }
        },
      ];
};
