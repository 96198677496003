export const getCountryService = () => {
  return [
    {
      id: 1,
      country: "Albania",
      code: "355",
      unit: "KMs",
      status: "Active",
    },
    {
      id: 2,
      country: "Algeria",
      code: "213",
      unit: "Miles",
      status: "Inactive",
    },
    {
      id: 3,
      country: "American Samoa",
      code: "1684",
      unit: "KMs",
      status: "Inactive",
    },
    {
      id: 4,
      country: "Albania",
      code: "355",
      unit: "KMs",
      status: "Active",
    },
    {
      id: 5,
      country: "Andorra",
      code: "376",
      unit: "KMs",
      status: "Inactive",
    },
    {
      id: 6,
      country: "	Angola",
      code: "244",
      unit: "KMs",
      status: "Active",
    },
    {
      id: 7,
      country: "Anguilla",
      code: "1264",
      unit: "Miles",
      status: "Inactive",
    },
    {
      id: 8,
      country: "Antarctica",
      code: "672",
      unit: "KMs",
      status: "Active",
    },
  ];
};
