export const getPermissionObjectFromPermissionArray = (
  permissions,
  userType = 'admin'
) => {
  try {
    const per = permissions.reduce(
      (previous, row) => {
        const { modules, subModules, permissions } = previous;
        const moduleKeys = row.permission.split('.');

        if (moduleKeys.length > 2) {
          /***
           * Format:-admin:connection-point.connection-point-list.view
           */

          const module = moduleKeys[0];
          const subModule = moduleKeys[1];
          const permissions = moduleKeys[2];

          modules[module] = {
            ...(modules[module] ? modules[module] : {}),

            [subModule]: true,
          };

          subModules[subModule] = {
            ...(subModules[subModule] ? subModules[subModule] : {}),

            [permissions]: true,
          };
        } else if (moduleKeys.length === 1) {
          /***
           * Format:-admin:dashboard
           */

          const module = moduleKeys[0];
          modules[module] = false;
          subModules[module] = {
            ...(subModules[module] ? subModules[module] : {}),

            all: true,
          };
        } else {
          /***
           * Format:inventory.view
           */

          const module = moduleKeys[0];
          const permissions = moduleKeys[1];
          modules[module] = false;

          subModules[module] = {
            ...(subModules[module] ? subModules[module] : {}),

            [permissions]: true,
          };
        }

        permissions[row.permission] = row.id;
        return previous;
      },

      { modules: {}, subModules: {}, permissions: {} }
    );

    return per;
  } catch (error) {
    console.log(error);
  }
};

export const getFlatRoutes = (routes) => {
  try {
    if (Array.isArray(routes)) {
      // routes.reduce((pre, current) => {
      //   if (current?.children && current.children.length > 0) {
      //     /******Extract children base routes if routes have a
      //      * children because parent is artificial routes******** */
      //     const level2Children = current?.children?.reduce((pre, children2) => {
      //       if (children2?.children && children2.children.length > 0) {
      //         const level3cChildren = children
      //       }
      //     }, []);
      //     // pre = [...pre, ]
      //   }
      // }, []);

      const children = routes.reduce((pre, curr) => {
        if (curr?.children && curr.children.length > 0) {
          const arr = getFlatRoutes(curr?.children);
          return [...pre, ...arr];
        }
        pre = [...pre, curr];
        return pre;
      }, []);
      return children;
    } else {
      return routes;
    }
  } catch (err) {
    console.log(err);
  }
};
