import {MultiSelectFilter, Toggle, SelectFilter, InputFilter, AmountFilter, DateFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const couponManagementTableColumn = ({ Action }) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });

  const getColumnSelectDateProps = (dataIndex, dateType) => ({
    filterDropdown: (props) => (<DateFilter dateType={dateType} dataIndex={dataIndex} {...props}/> ),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getActionData = () => {
    return [      
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getValidityData = () => {
    return [      
      {
        name: 'Permanent',
        value: 'Permanent',
      },
      {
        name: 'Custom',
        value: 'Custom',
      },
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "couponCode",
      title: "Coupon code",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("coupon code"),
    },
    {
      dataIndex: "discount",
      title: "Discount",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("discount"),
    },
    {
      dataIndex: "validity",
      title: "Validity",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("validity"),
      ...getColumnSelectSearchProps("validity", getValidityData())
    },
    {
      dataIndex: "maximumUse",
      title: "Maximum Use",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("maximum use"),
    },
    {
      dataIndex: "startDate",
      title: "Start date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("start date", ""),
    },
    {
      dataIndex: "endDate",
      title: "End date",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectDateProps("end date", ""),
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
      render:()=><Toggle isDropDown/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", getActionData())
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />
    },
  ];
};
