import { Outlet } from 'react-router-dom';
import { DontHavePermission } from '../../views/DontPermission';

export default function route() {
  return [
    {
      path: accessRoute.DONT_PERMISSION.path,
      private: true,
      name: 'DontPermission',
      key: "dontPermission",
      belongsToSidebar: false,
      submenu: false,
      icon: accessRoute.DONT_PERMISSION.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.DONT_PERMISSION.path,
        name: 'DontPermission',
        key: accessRoute.DONT_PERMISSION.key,
        belongsToSidebar: false,
        icon: accessRoute.DONT_PERMISSION.icon,
        element: <DontHavePermission />,
      },
    ]
    },
  ];
}

export const accessRoute = {
    DONT_PERMISSION: {
    path: '/admin/permission',
    key:"dontPermission.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-chat-circle-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
