import React, {useState} from 'react';
import {
  Breadcrumb,
  PageHeader,
  FormWrapper,
  FormElementWrapper,
  CommonButton,
  TextEditor,
  ModalComponent,
  AntTextArea,
} from '../../../../../components';
import { Table } from 'react-bootstrap';
import { Select } from 'antd';

function MarketingMessageSend(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Marketing Message Send',
    },
  ];
  const messageTypeData = [
    {
    name: 'email',
    value: 'Email',
    },
    {
    name: 'sMS',
    value: 'SMS',
    },
    {
    name: 'pushNotification',
    value: 'Push Notification',
    },
  ]
  const templateData = [
    {
        name: 'marketingEmailTemplate',
        value: 'Marketing  Email Template',
    },
    {
        name: 'mailSubject',
        value: 'Mail Subject',
    },
    {
        name: 'customerWelcomeMarketing',
        value: 'Customer welcome marketing email template',
    },
    {
        name: 'newsletterSubscriptionEmail',
        value: 'Newsletter subscription email',
    },
  ]
  const audienceTypeData = [
    {
        name: 'Customer',
        value: 'Customer',
    },
    {
        name: 'Provider',
        value: 'Provider',
    },
    {
        name: 'Admin',
        value: 'Admin',
    },
  ]
  const [audienceOptionsData, setAudienceOptionsData] = useState(false);
  const audienceTypeWithOutAdminData = [
    {
        name: 'Customer',
        value: 'Customer',
    },
    {
        name: 'Provider',
        value: 'Provider',
    }
  ]
  const serviceProviderData = [
    {
        name: 'Abhishek Yadav (abhishek@yopmail.com)',
        value: 'Abhishek Yadav (abhishek@yopmail.com)',
    },
    {
        name: 'aditi test (aditi741@mailinator.com)',
        value: 'aditi test (aditi741@mailinator.com)',
    },
    {
        name: 'Akansha Jain (akanshajain01pimr1820@gmail.com)',
        value: 'Akansha Jain (akanshajain01pimr1820@gmail.com)',
    },
  ]
  const bookingModeData = [
    {
        name: 'Select/Deselect All',
        value: 'Select/Deselect All'
    },
    {
        name: 'Web Manual',
        value: 'Web Manual',
    },
    {
        name: 'SP Manual',
        value: 'SP Manual',
    },
    {
        name: 'Voice AI',
        value: 'Voice AI',
    },
    {
        name: 'iOS',
        value: 'iOS',
    },
  ]
  const CustomerTagData = [
    {
        name: 'Select/Deselect All',
        value: 'Select/Deselect All'
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'Cooling',
      value: 'Cooling',
    },
    {
        name: 'Plumbing',
        value: 'Plumbing',
    },
    {
        name: 'Heating System',
        value: 'Heating System',
    }
  ]
  const jobTagsData = [
    {
        name: 'Select/Deselect All',
        value: 'Select/Deselect All'
    },
    {
      name: 'Heating Tune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'Humidifier J',
      value: 'Humidifier J',
    },
    {
        name: 'Generator',
        value: 'Generator',
    },
  ]
  const ServicesTagData = [
    {
        name: 'HVAC',
        value: 'HVAC',
    },
    {
        name: 'Electrician',
        value: 'Electrician',
    },
    {
        name: 'Plumbing',
        value: 'Plumbing',
    },
    {
        name: 'Handyman',
        value: 'Handyman',
    },
  ]
  const ModuleData = [
    {
        name: 'Site Statistics',
        value: 'Site Statistics',
    },
    {
        name: 'Provider',
        value: 'Provider',
    },
    {
        name: 'Service Category',
        value: 'Service Category',
    },
  ]
  //Template change
  const [templateChange, setTemplateChange] = useState(false);
  const handleTemplateChange = (value) => {
    if(value === ''){
      setTemplateChange(false)
    }else{
      setTemplateChange(true)
    }
  };
   //Message Type change
   const [messageTypeChange, setMessageTypeChange] = useState(false);
  const handleMessageTypeChange = (value) => {
    if(value === 'Push Notification'){
        setMessageTypeChange(true)
        setAudienceOptionsData(false);
        setTemplateChange(false);
    }else if(value === 'SMS'){
        setAudienceOptionsData(false);
        setMessageTypeChange(false)
    }
    else{
        setMessageTypeChange(false)
        setAudienceOptionsData(true);
      }
  }
  //Audience Type change
  const [audienceTypeChange, setAudienceTypeChange] = useState(true);
  const [providerAudience, setProviderAudience] = useState(false);
  const [adminAudience, setAdminAudience] = useState(false);
  const handleAudienceTypeChange = (value) => {
    if(value === 'Provider'){
        setAudienceTypeChange(false);
        setProviderAudience(true);
        setAdminAudience(false)
    }else if(value === 'Admin'){
        setAudienceTypeChange(false)
        setAdminAudience(true);
        setProviderAudience(false);
    }else{
        setAudienceTypeChange(true)
        setProviderAudience(false);
        setAdminAudience(false);
    }
  }
  //short code modal
  const [shortCodeModal, setShortCodeModal] = useState(false);
  const shortCodes = () =>{
    setShortCodeModal(true)
  }
  const shortCodesHide = () =>{
    setShortCodeModal(false)
  }
  return (
    <>
        <div className='wide-lg mx-auto'>
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <PageHeader heading="Marketing Message Send">
                        <Breadcrumb breadcrumb={breadcrumb} />
                    </PageHeader>
                </div>
            </div>
            <div className="nk-block">
                <div className="card">
                    <div className="card-inner">
                        <div className='row'>
                            <div className='col-md-5'>
                                <FormWrapper row={true} extraClassName="g-3">               
                                    <FormElementWrapper
                                        md={12}
                                        label="Message Type"
                                        mandatory={true}
                                        element={(formik) => (
                                        <Select
                                            name="messageType"
                                            placeholder="Select message type"
                                            options={messageTypeData}
                                            formik={formik}
                                            onChange={handleMessageTypeChange}
                                        />
                                        )}
                                    />
                                    {
                                        messageTypeChange ? '' : 
                                        <FormElementWrapper
                                            md={12}
                                            label="Select Template"
                                            mandatory={true}
                                            element={(formik) => (
                                            <Select
                                                name="template"
                                                placeholder="Select template"
                                                options={templateData}
                                                formik={formik}
                                                onChange={handleTemplateChange}
                                            />
                                            )}
                                        />
                                    }
                                    
                                    <FormElementWrapper
                                        md={12}
                                        label="Select Audience Type"
                                        mandatory={true}
                                        element={(formik) => (
                                        <Select
                                            name="audienceType"
                                            placeholder="Select audience type"
                                            options={audienceOptionsData ? audienceTypeData : audienceTypeWithOutAdminData }
                                            formik={formik}
                                            onChange={handleAudienceTypeChange}
                                        />
                                        )}
                                    />
                                    {
                                        audienceTypeChange &&
                                        <>
                                            <FormElementWrapper
                                                md={12}
                                                label="Select Service Provider"
                                                mandatory={true}
                                                element={(formik) => (
                                                <Select
                                                    name="serviceProvider"
                                                    placeholder="Select service provider"
                                                    options={serviceProviderData}
                                                    formik={formik}
                                                />
                                                )}
                                            />
                                            <FormElementWrapper
                                                md={12}
                                                label="Select Booking Mode"
                                                mandatory="true"
                                                element={(formik) => (
                                                    <Select
                                                        name="bookingMode"
                                                        placeholder="Select booking mode"
                                                        options={bookingModeData}
                                                        showSearch
                                                        formik={formik}
                                                        mode="multiple"
                                                    allowClear
                                                    />
                                                )}
                                            />
                                            <FormElementWrapper
                                                md={12}
                                                label="Select Customer Tags"
                                                element={(formik) => (
                                                <Select
                                                    name="CustomerTag"
                                                    placeholder="Select customer tags"
                                                    options={CustomerTagData}
                                                    showSearch
                                                    formik={formik}
                                                    mode="multiple"
                                                allowClear
                                                />
                                                )}
                                            />
                                            <FormElementWrapper
                                                md={12}
                                                label="Select Job Tags"
                                                element={(formik) => (
                                                <Select
                                                    name="JobTag"
                                                    placeholder="Select job tag"
                                                    options={jobTagsData}
                                                    showSearch
                                                    formik={formik}
                                                    mode="multiple"
                                                allowClear
                                                />
                                                )}
                                            />
                                        </>
                                    }
                                    {
                                        providerAudience &&
                                        <FormElementWrapper
                                            md={12}
                                            label="Select Services"
                                            mandatory={true}
                                            element={(formik) => (
                                            <Select
                                                name="ServicesTag"
                                                placeholder="Select services tag"
                                                options={ServicesTagData}
                                                showSearch
                                                formik={formik}
                                                mode="multiple"
                                            allowClear
                                            />
                                            )}
                                        />
                                    }
                                    {
                                        adminAudience && 
                                        <FormElementWrapper
                                            md={12}
                                            label="Select Module"
                                            mandatory={true}
                                            element={(formik) => (
                                            <Select
                                                name="Module"
                                                placeholder="Select module"
                                                options={ModuleData}
                                                showSearch
                                                formik={formik}
                                                mode="multiple"
                                            allowClear
                                            />
                                            )}
                                        />
                                    }
                                    <FormElementWrapper
                                        md={12}
                                        element={(formik) => (
                                            <h6>Total Recipient: 0</h6>
                                        )}
                                    />
                                    <FormElementWrapper
                                    md={12}
                                    className="text-end"
                                    element={() => (
                                        <>
                                        <CommonButton
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Send
                                        </CommonButton>
                                        </>
                                    )}
                                    />
                                </FormWrapper>
                            </div>
                            <div className='col-md-7 mt-3 mt-lg-0'>
                                {
                                    messageTypeChange &&
                                    <FormWrapper row={true} extraClassName="g-3">
                                        <FormElementWrapper
                                            md={12}
                                            label="Notification"
                                            actionButtonHandler={ shortCodes}
                                            button=" Short Codes"
                                            element={(formik) => (
                                                <AntTextArea
                                                    className="form-control"
                                                />
                                            )}
                                        />
                                    </FormWrapper>
                                }
                                {
                                    templateChange &&
                                    <FormWrapper row={true} extraClassName="g-3">
                                        <FormElementWrapper
                                            md={12}
                                            label="Template"
                                            actionButtonHandler={ shortCodes}
                                            button=" Short Codes"
                                            element={(formik) => (
                                                <TextEditor>
                                                Dear #CUSTOMER_NAME#,
                                                    Check out [name of product] discounts
                                                    [Name of product] is now available
                                                    New [name of product or service] from [company name]
                                                    [X]% off [product or service name] today only
                                                </TextEditor> 
                                            )}
                                            textCount="124/320"
                                        />
                                    </FormWrapper>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent
        backdrop
        show={shortCodeModal}
        onHandleCancel={shortCodesHide}
        title="Email Short Codes"
        extraClassName="modal-xl"
      >
       <div className="table-responsive">
        <Table bordered size="md" className="mb-0">
              <thead>
                <tr>
                  <th>Short Codes</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#CUSTOMER_NAME#</td>
                  <td>Name of the Customer</td>
                </tr>
                <tr>
                  <td>#PROVIDER_NAME#</td>
                  <td>	Name of the Provider</td>
                </tr>
                <tr>
                  <td>##PROVIDER_PHONE_NUMBER#</td>
                  <td>Phone Number of the Provider</td>
                </tr>
              </tbody>
        </Table>
        </div>
        <div className="text-end mt-3">
          <CommonButton className="btn btn-secondary" onClick = { () => shortCodesHide() }>Close</CommonButton>
        </div>
      </ModalComponent>  
    </>
  );
}

export default MarketingMessageSend;
