export const EquipmentOrderServices = () => {
    return [
      {
        id: 1,
        jobNumber: "75833147",
        serviceProviderName: "kam test",
        serviceProviderNumber: '(+91) 8769995023',
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerAddress: "LIG Square Indore",
        needInstallPermit: "Yes",
        CityCountyName: "	Indore",
        floodZone: "Yes",
        amountJobApprovedFor: "$ 13,153.13",
        supplyHouseName: "Chillers and Compressor",
        supplyHouseLocation: "LIG Indore",
        equipmentPickupDate: "11/30/2022",
        equipment: "",
        materialList: "",
        otherNotes: "test note",
        permitStatus: '',
        ordered: '',
        lastUpdatedSave: "",
      },
      {
        id: 2,
        jobNumber: "78833147",
        serviceProviderName: "Customer Support",
        serviceProviderNumber: '(+91) 8369995023',
        customerName: "Test Customer",
        customerNumber: '	(+91) 7869995023',
        customerAddress: "LIG Square Indore",
        needInstallPermit: "Yes",
        CityCountyName: "	Indore",
        floodZone: "No",
        amountJobApprovedFor: "$ 13,153.13",
        supplyHouseName: "MP House",
        supplyHouseLocation: "LIG Indore",
        equipmentPickupDate: "10/11/2022",
        equipment: "",
        materialList: "",
        otherNotes: "test note",
        permitStatus: '',
        ordered: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
      {
        id: 3,
        jobNumber: "79833147",
        serviceProviderName: "Service test",
        serviceProviderNumber: '(+91) 8869995023',
        customerName: "Test Customer",
        customerNumber: '	(+91) 7869995023',
        customerAddress: "LIG Square Indore",
        needInstallPermit: "Yes",
        CityCountyName: "	Indore",
        floodZone: "No",
        amountJobApprovedFor: "$ 13,153.13",
        supplyHouseName: "IND House",
        supplyHouseLocation: "LIG Indore",
        equipmentPickupDate: "12/11/2022",
        equipment: "",
        materialList: "",
        otherNotes: "test note",
        permitStatus: '',
        ordered: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
      {
        id: 4,
        jobNumber: "75833147",
        serviceProviderName: "Test KT",
        serviceProviderNumber: '(+91) 9969995023',
        customerName: "John Testing",
        customerNumber: '	(+91) 7869995023',
        customerAddress: "LIG Square Indore",
        needInstallPermit: "No",
        CityCountyName: "	Indore",
        floodZone: "Yes",
        amountJobApprovedFor: "$ 13,153.13",
        supplyHouseName: "Chillers and Compressor",
        supplyHouseLocation: "LIG Indore",
        equipmentPickupDate: "11/30/2022",
        equipment: "",
        materialList: "",
        otherNotes: "test note",
        permitStatus: '',
        ordered: '',
        lastUpdatedSave: 'Fri, October 28, 2022 12:06 PM'
      },
    ];
  };
  