import React, {useState} from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TextEditor,
  ModalComponent,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';

function EditMarketingEmailTemplate(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/email',
      name: 'Marketing Email Template',
    },
    {
      path: '#',
      name: 'Edit Marketing Email Template',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/marketing-template/email')
  }
  const [shortCodeModal, setShortCodeModal] = useState(false);
  const shortCodes = () =>{
    setShortCodeModal(true)
  }
  const shortCodesHide = () =>{
    setShortCodeModal(false)
  }
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Marketing Email Template">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                label="Email Subject"
                mandatory={true}
                element={(formik) => (
                    <TextInput
                        name="Email Subject"
                        placeholder="Enter email subject"
                        formik={formik}
                        defaultValue="Brand Awareness Email Campaigns"
                    />
                )}
            />
            <FormElementWrapper
                md={12}
                label="Email Body"
                mandatory={true}
                actionButtonHandler={ shortCodes}
                button=" Short Codes"
                element={(formik) => (
                    <TextEditor>
                    The gist of brand awareness email campaigns is to inform the receivers about what you have to offer. You can focus on your products, services, team, company culture, employment opportunities, ongoing discounts, freebies, and upcoming upgrades. http://3.131.211.25/admin Brand awareness campaigns can be in the form of regular email newsletters or short campaigns. Your aim here is to filter and find the people interested in knowing more about you. If a reader:
                    </TextEditor> 
                )}
                formnote="Note : Please Don't Replace Variable Between # Sign."
            />
            <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                    <>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                            Update
                        </CommonButton>
                        <CommonButton
                            type="submit"
                            className="btn btn-light"
                            >
                            Reset
                        </CommonButton>
                    </>
                )}
            />
        </FormWrapper>
                    
          </div>
        </div>
      </div>
      </div>
      <ModalComponent
        backdrop
        show={shortCodeModal}
        onHandleCancel={shortCodesHide}
        title="Email Short Codes"
        extraClassName="modal-xl"
      >
       <div className="table-responsive">
        <Table bordered size="md" className="mb-0">
              <thead>
                <tr>
                  <th>Short Codes</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#CUSTOMER_NAME#</td>
                  <td>Name of the Customer</td>
                </tr>
                <tr>
                  <td>#PROVIDER_NAME#</td>
                  <td>	Name of the Provider</td>
                </tr>
                <tr>
                  <td>##PROVIDER_PHONE_NUMBER#</td>
                  <td>Phone Number of the Provider</td>
                </tr>
              </tbody>
        </Table>
        </div>
        <div className="text-end mt-3">
          <CommonButton className="btn btn-secondary" onClick = { () => shortCodesHide() }>Close</CommonButton>
        </div>
      </ModalComponent>  
    </>
  );
}

export default EditMarketingEmailTemplate;
