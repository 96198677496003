import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  TablePlus,
  InputFilter,
} from '../../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListHouseLocation(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Supply House Location Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Supply House Location',
    },
  ];

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Delete'
    }    
  ]

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const columns = [
    {
      dataIndex: 'id',
     title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'supplyHouseName',
     title: '	Supply House Name',
      sorter: true,
      ...getColumnSearchProps("equipment name")
    },
    {
      dataIndex: 'supplyHouseLocation',
     title: 'Supply House Location',
      sorter: true,
      ...getColumnSearchProps("supply house location")
    },
    {
      dataIndex: 'email',
     title: 'Email',
      sorter: true,
      ...getColumnSearchProps("email")
    },
    {
      dataIndex: 'action',
     title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{ editHouseLocation()},
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const adminData = [
    {
      id: 1,
      supplyHouseName: 'Fuller Shelf',
      supplyHouseLocation: '1500 E Michigan St, Indianapolis, IN 46201, USA',
      email: 'nitish.yadav@codiant.com',
    },
    {
      id: 2,
      supplyHouseName: '	Johnnies House',
      supplyHouseLocation: '15251 Smithfield Dr, Westfield, IN 46074, USA',
      email: 'johnnie@goodguysheatandair.com,jfirari@goodguyshomeservices.com',
    },
    {
      id: 3,
      supplyHouseName: 'Chillers and Compressor',
      supplyHouseLocation: '2450 N Meridian St, Indianapolis, IN 46208, USA',
      email: 'nitish.yadav@getnada.com,nitish.yadav1@getnada.com,nitish.',
    },
  ];
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const navigate = useNavigate();
  const addSupplyHouseLocation = () =>{
    navigate('/admin/equipment-location/add')
  }
  const editHouseLocation = () =>{
    navigate('/admin/equipment-location/edit')
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Supply House Location">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'create',]}
            btnText="Add Supply House Location"
            onHandleShow={addSupplyHouseLocation}
            // popover={<HouseLocationFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Supply House Location"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Supply House Location module will list all Supply House Location on this page.</li>
                <li>Administrator can Edit / Delete any Supply House Location </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListHouseLocation;
