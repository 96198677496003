import React from 'react';
import { Link } from 'react-router-dom';
import { ImageElement, AdminCreateProfileForm } from '../../../../components';
import { formSubmit } from '../../../../utils';

export default function CreateProfile() {
  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Create your profile</h5>
              {/* <div className="nk-block-des">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.
                </p>
              </div> */}
            </div>
          </div>
          <AdminCreateProfileForm onSubmit={(e) => formSubmit(e)} />
          <div className="form-note-s2 text-center mt-4">
          Already Registered? 
            <Link to="/nca-login" className="ms-1">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
