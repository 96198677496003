import { Outlet } from 'react-router-dom';
import { NewEquipmentBookingLog, NewEquipmentList, NewEquipmentViewQuote } from '../../views/NewEquipment';



export default function route() {
  return [
    {
      path: accessRoute.NEW_EQUIPMENT.path,
      private: true,
      name: 'New Equipment',
      key: "newEquipment",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.NEW_EQUIPMENT.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.NEW_EQUIPMENT.path,
        name: 'New Equipment',
        key: accessRoute.NEW_EQUIPMENT.key,
        belongsToSidebar: false,
        icon: accessRoute.NEW_EQUIPMENT.icon,
        element: <NewEquipmentList />,
      },
      {
        private: true,
        path: accessRoute.BOOKING_LOG.path,
        name: 'Booking Log',
        key: accessRoute.BOOKING_LOG.key,
        belongsToSidebar: false,
        icon: accessRoute.BOOKING_LOG.icon,
        element: <NewEquipmentBookingLog />,
      },
      {
        private: true,
        path: accessRoute.VIEW_QUOTE.path,
        name: 'View Quote',
        key: accessRoute.VIEW_QUOTE.key,
        belongsToSidebar: false,
        icon: accessRoute.VIEW_QUOTE.icon,
        element: <NewEquipmentViewQuote />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  NEW_EQUIPMENT: {
    path: '/admin/new-equipment',
    key:"newEquipment.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-briefcase" />
      </span>
    ),
  },
  BOOKING_LOG: {
    path: '/admin/new-equipment/booking-log',
    key:"newEquipment.booking",
  },
  VIEW_QUOTE: {
    path: '/admin/new-equipment/view-quote',
    key:"newEquipment.viewQuote",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
