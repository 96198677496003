import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import {
  CsvExport,
  FilterButton,
  CreateButton,
  ActionButton,
  CsvImport,
  ImportButton,
  CommonButton,
} from '../../UIElements/Button';


function ListingHeader({
  btnText,
  btnIcons,
  btnArray,
  onHandleShow,
  onFileHandleShow,
  popover,
  responsiveBtn=()=><></>,
  extraBtnText,
  extraBtnClass,
  onExtraButtonHandleShow,
  dropDownBtnTxt,
  dropDownOptions = [],
  multipleButton = [],
  // setVisible,
  // visible,
  // csvData = [],
  // fileName = "",
}) {
  const [filterToggle, setFilterToggle] = useState(false);
  return (
    <>
      {/* <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between"> */}
      <div className="nk-block-head-content">
        <div className="toggle-wrap nk-block-tools-toggle">
          <Link
            to="#"
            className="btn btn-icon btn-trigger toggle-expand mr-n1"
            data-target="more-options"
            onClick={() => setFilterToggle(!filterToggle)}
          >
            <em className="icon ni ni-more-v" />
          </Link>
          <div
            className={`toggle-expand-content ${
              filterToggle ? 'expanded' : ''
            }`}
            // className="toggle-expand-content"
            data-content="more-options"
          >
            <ul className="nk-block-tools g-2 g-sm-3">
              <li>
                {responsiveBtn()}
              </li>
              {btnArray.includes('filter') && (
                <li>
                  <FilterButton
                    className="bg-black border-transparent"
                    popover={popover}
                    // setVisible={setVisible}
                    // visible={visible}
                  />
                </li>
              )}
              {btnArray.includes('uploadImagesDropdown') && (
                <li>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className='dropdown-toggle-bootstrap'>
                    Upload Images
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/admin/upload-data">Upload Bulk Image</Dropdown.Item>
                    <Dropdown.Item href="/admin/quote-management/view-image">View Images</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </li>
              )}
              {btnArray.includes('csvExport') && (
                <li>
                  <CsvExport
                    // data={csvData}
                    // fileName={fileName}
                    extraClassName={`btn btn-white btn-outline-light `}
                  />
                </li>
              )}
              {btnArray.includes('fileImport') && (
                <li>
                  <ImportButton
                    extraClassName={`btn btn-white btn-outline-light `}
                    onFileHandleShow={onFileHandleShow}
                  >
                    <em className="icon ni ni-upload-cloud" />
                    <span>Import</span>
                  </ImportButton>
                </li>
              )}
              
              {btnArray.includes('csvImport') && (
                <li>
                  <CsvImport
                    // data={csvData}
                    // fileName={fileName}
                    extraClassName={`btn btn-white btn-outline-light `}
                  />
                </li>
              )}
              {btnArray.includes('downloadSample') && (
                <li>
                  <CommonButton
                    extraClassName={`btn btn-white btn-outline-light `}
                  >
                    <em className="icon ni ni-download" />
                    <span>Download Sample</span>
                  </CommonButton>
                </li>
              )}
              {btnArray.includes('create') && (
                <li>
                  <CreateButton onHandleShow={onHandleShow} btnText={btnText} btnIcons={btnIcons}/>
                </li>
              )}
              {/* Temporally Button */}
              {
                multipleButton && multipleButton?.length ?
                multipleButton.map((item,index) => (
                  <li key={index}>
                  <CreateButton onHandleShow={item?.handler} btnText={item?.title} />
                </li>
                      ))
                :null 
              }
              {btnArray.includes('extraButton') && (
                <li>
                  <ActionButton
                    extraBtnClass={extraBtnClass}
                    onHandleAction={onExtraButtonHandleShow}
                    btnText={extraBtnText}
                  />
                </li>
              )}
              {btnArray.includes('dropdownBtn') && (
                <li>
                <Dropdown>
                  <Dropdown.Toggle variant="primary">{dropDownBtnTxt}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      dropDownOptions && dropDownOptions?.length  ?
                      dropDownOptions.map((item,index) => (
                        <Dropdown.Item kry={index} onClick={item?.handler}>
                            {item?.title}
                        </Dropdown.Item>
                      )) :null
                      }
                  </Dropdown.Menu>
                </Dropdown>
                </li>
              )}
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListingHeader;
