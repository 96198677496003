
import React, { useState } from 'react';
import {
  Breadcrumb,
  FormElementWrapper,
  FormWrapper,
  PageHeader,
  Tabs,
  TextInput,
  Select,
  CommonButton
} from '../../../../components';


function CompanySetting(props) {
  const [defaultKey, setDefaultKey] = useState('general');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'General Settings',
    },
  ];
  const distanceUnitTypeData =[
    {
        name: 'Miles',
        value: 'Miles',
    },
  ]
  const countryCodeTypeData = [
    {
        name: 'India (IN)',
        value: 'India (IN)',
    },
    {
        name: 'United States (US)',
        value: 'United States (US)',
    },
  ]
  const paymentModTypeData = [
    {
        name: 'Card',
        value: 'Card',
    },
  ]
  const appPaymentMethodTypeData = [
    {
        name: 'Stripe',
        value: 'Stripe',
    },
  ]
  const ProvidersCabRequestData = [
    {
        name: 'All (COMPETITIVE ALGORITHM)',
        value: 'All (COMPETITIVE ALGORITHM)',
    },
    {
        name: 'Distance (Nearest 1st Algorithm)',
        value: 'Distance (Nearest 1st Algorithm)',
    },
    {
        name: 'Time (FIFO Algorithm)',
        value: 'Time (FIFO Algorithm)',
    },
  ]
  const tabContent = [
    {
      name: 'General',
      key: 'general',
      content: (
        <>
          <div className='card'>
            <div className='card-inner'>
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                    md={6}
                    label="Project Name For Admin/Front"
                    tooltip={true}
                    tooltipText="This will appear as a Project name at all places like front and admin panel"
                    element={(formik) => (
                        <TextInput
                            name="projectName"
                            placeholder="Enter project name for admin/front"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Default Distance Unit"
                    tooltip={true}
                    tooltipText="Admin country distance unit"
                    element={(formik) => (
                        <Select
                            name="adminType"
                            placeholder="Select default distance unit"
                            options={distanceUnitTypeData}
                            showSearch
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Admin Country ISD Code"
                    tooltip={true}
                    tooltipText="This code will be used for calling and sending SMS from application."
                    element={(formik) => (
                        <TextInput
                            name="CountryISD"
                            placeholder="Enter admin country ISD code"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Display Record Per Page In Admin Panel"
                    tooltip={true}
                    tooltipText="Number of records to display per page in admin panel"
                    element={(formik) => (
                        <TextInput
                            name="DisplayRecord"
                            placeholder="Enter display record per page in admin panel"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Country Code"
                    tooltip={true}
                    tooltipText="Country selected here will appear as selected by default at all the forms for the website/applications."
                    element={(formik) => (
                        <Select
                            name="CountryCode"
                            placeholder="Select country code"
                            options={countryCodeTypeData}
                            showSearch
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                        <CommonButton
                        type="submit"
                        className="btn btn-primary"
                        >
                        Save Changes
                        </CommonButton>
                    )}
                />
              </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Email',
      key: 'email',
      content: (
        <>
          <div className='card'>
            <div className='card-inner'>
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                    md={6}
                    label="Email From Name"
                    tooltip={true}
                    tooltipText="This will appear as a email sender name from system generated emails."
                    element={(formik) => (
                        <TextInput
                            name="EmailFromName"
                            placeholder="Enter email from name"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Admin Email"
                    tooltip={true}
                    tooltipText="System generated emails like contact us form, provider signup etc... emails will go to this email account."
                    element={(formik) => (
                        <TextInput
                            name="AdminEmail"
                            placeholder="Enter admin email"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="No Reply Email"
                    tooltip={true}
                    tooltipText="Sender email will be set to this one for all system generated emails."
                    element={(formik) => (
                        <TextInput
                            name="NoReplyEmail"
                            placeholder="Enter no reply email"
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={6}
                    label="Admin Email (For NAC Jobs) "
                    tooltip={true}
                    tooltipText="Service Request Attention (NAC) jobs related emails will go to this email account."
                    element={(formik) => (
                        <TextInput
                            name="AdminEmailForNAC"
                            placeholder="Enter admin email (for NAC jobs) "
                            formik={formik}
                        />
                    )}
                />
                <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                        <CommonButton
                        type="submit"
                        className="btn btn-primary"
                        >
                        Save Changes
                        </CommonButton>
                    )}
                />
              </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
        name: 'Appearance',
        key: 'appearance',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                      md={6}
                      label="Company Name In Email "
                      tooltip={true}
                      tooltipText="Company name to show in emails"
                      element={(formik) => (
                          <TextInput
                              name="companyNameInEmail"
                              placeholder="Enter company name in email"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Android App Link in Website Footer"
                      tooltip={true}
                      tooltipText="Link will be applied in Android App Download image given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="AndroidAppLink"
                              placeholder="Enter android app link in website footer"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Copyright text to show in admin footer"
                      tooltip={true}
                      tooltipText="Copyright text to show in admin footer "
                      element={(formik) => (
                          <TextInput
                              name="copyrightText"
                              placeholder="Enter copyright text to show in admin footer"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Iphone App Link in Website Footer"
                      tooltip={true}
                      tooltipText="Link will be applied in iOS App Download image given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="IphoneAppLink"
                              placeholder="Enter iphone app link in website footer"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Copyright text to show in website footer"
                      tooltip={true}
                      tooltipText="Copyright text to show in website footer "
                      element={(formik) => (
                          <TextInput
                              name="CopyrightText"
                              placeholder="Enter copyright text to show in website footer"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'Payment',
        key: 'payment',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={6}
                        label="Payment Mode"
                        tooltip={true}
                        tooltipText="Payment methods can be set through this option. Selecting Cash will give user to pay using cash only. Card will give option of only credit card and Cash-card option will give both cash and card option. Please note that enabling card option will require the Payment gateway to be configured properly. "
                        element={(formik) => (
                            <Select
                                name="paymentMod"
                                placeholder="Select payment mode"
                                options={paymentModTypeData}
                                showSearch
                                formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="App Payment Method"
                        tooltip={true}
                        tooltipText="App Payment Method"
                        element={(formik) => (
                            <Select
                                name="appPaymentMod"
                                placeholder="Select app payment method"
                                options={appPaymentMethodTypeData}
                                showSearch
                                formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Stripe Publisher Key"
                        tooltip={true}
                        tooltipText="Stripe account publisher api key."
                        element={(formik) => (
                            <TextInput
                              name="stripePublisherKey"
                              placeholder="Enter stripe publisher key"
                              formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Stripe Secret Key"
                        tooltip={true}
                        tooltipText="Stripe account secret api key."
                        element={(formik) => (
                            <TextInput
                              name="stripeSecretKey"
                              placeholder="Enter stripe secret key"
                              formik={formik}
                            />
                        )}
                    />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'Social Media',
        key: 'socialMedia',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                      md={6}
                      label="Facebook Application Id"
                      tooltip={true}
                      tooltipText="Apply the Facebook application id here after creating the Facebook Application in Facebook Developer Account. This will be used for Facebook Login Feature."
                      element={(formik) => (
                          <TextInput
                              name="FacebookApplicationId"
                              placeholder="Enter facebook application id"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Website Footer - Instagram Link "
                      tooltip={true}
                      tooltipText="Link will be applied on Instagram icon given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="WebsiteFooterInstagramLink"
                              placeholder="Enter website footer - instagram link "
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Website Footer - FaceBook Link"
                      tooltip={true}
                      tooltipText="Link will be applied on Facebook icon given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="websiteFooterFaceBookLink"
                              placeholder="Enter website footer - faceBook link"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Website Footer - Google Link"
                      tooltip={true}
                      tooltipText="Link will be applied on Google Plus icon given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="WebsiteFooterGoogleLink"
                              placeholder="Enter website footer - google link"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Website Footer - Twitter Link"
                      tooltip={true}
                      tooltipText="Link will be applied on Twitter icon given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="WebsiteFooterTwitterLink"
                              placeholder="Enter website footer - twitter link"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Facebook Review Link "
                      tooltip={true}
                      tooltipText="URL's for leave a review on Facebook after Rating Review done."
                      element={(formik) => (
                          <TextInput
                              name="facebookReviewLink"
                              placeholder="Enter facebook review link "
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Website Footer - LinkedIn Link"
                      tooltip={true}
                      tooltipText="Link will be applied on Linked In icon given in front website footer"
                      element={(formik) => (
                          <TextInput
                              name="WebsiteFooterLinkedInLink"
                              placeholder="Enter website footer - linkedIn link"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Google Review Link "
                      tooltip={true}
                      tooltipText="URL's for leave a review on Google after Rating Review done."
                      element={(formik) => (
                          <TextInput
                              name="GoogleReviewLink"
                              placeholder="Enter google review link "
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'App Settings',
        key: 'appSettings',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                      md={6}
                      label="Set Minimum job amount in case of Split Payment"
                      tooltip={true}
                      tooltipText="Set Minimum job amount in case of Split Payment"
                      element={(formik) => (
                          <TextInput
                              name="SetMinimumjobamount"
                              placeholder="Enter set minimum job amount in case of split payment"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Provider timeout in seconds"
                      tooltip={true}
                      tooltipText="Seconds to be displayed in acceptance of user's request - value must be in seconds"
                      element={(formik) => (
                          <TextInput
                              name="ProvidertimeoutInSeconds"
                              placeholder="Enter provider timeout in seconds"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Miles radius around user to show online providers"
                      tooltip={true}
                      tooltipText="This will show the providers around X miles radius of User location in User application when User go to request a ride."
                      element={(formik) => (
                          <TextInput
                              name="milesRadiusProviders"
                              placeholder="Enter miles radius around user to show online providers"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="User Android App Version"
                      element={(formik) => (
                          <TextInput
                              name="UserAndroidAppVersion"
                              placeholder="Enter user android app version"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Approx time for Provider to Reach User per Miles ( In minute/s )"
                      tooltip={true}
                      tooltipText="Set the approximate time in minute for provider to reach to User to pickup. This will be calculated and shown in User application as a ETA of the provider."
                      element={(formik) => (
                          <TextInput
                              name="ApproxTimeForProvider"
                              placeholder="Enter approx time for provider to reach user per miles ( in minute/s )"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Provider Android App Version"
                      element={(formik) => (
                          <TextInput
                              name="ProviderAndroidAppVersion"
                              placeholder="Enter provider android app version"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Online provider List limit by distance (integer value)"
                      tooltip={true}
                      tooltipText="Value in KM: Create list of online providers around User's location. (Only integer value)"
                      element={(formik) => (
                          <TextInput
                              name="OnlineProviderList"
                              placeholder="Enter online provider list limit by distance (integer value)"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="User IOS App Version"
                      element={(formik) => (
                          <TextInput
                              name="UserIOSAppVersion"
                              placeholder="Enter user IOS app version"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Provider's cab request method"
                      tooltip={true}
                      tooltipText="How User app sends request to available providers 
                            1. All :: Competitive Algorithm 
                            2. Distance :: Nearest 1st Algorithm
                            3. Time :: FIFO Algorithm"
                        element={(formik) => (
                          <Select
                            name="Providercabrequestmethod"
                            placeholder="Select provider's cab request method"
                            options={ProvidersCabRequestData}
                            showSearch
                            formik={formik}
                            />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Provider IOS App Version"
                      element={(formik) => (
                          <TextInput
                              name="ProviderIOSAppVersion"
                              placeholder="Enter provider IOS app version"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'Installation Settings',
        key: 'installationSettings',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                      md={6}
                      label="Google Project Id"
                      tooltip={true}
                      tooltipText="Google Project ID - for all process of application: DO not change"
                      element={(formik) => (
                          <TextInput
                              name="GoogleProjectId"
                              placeholder="Enter google project id"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Zendesk chat API key"
                      tooltip={true}
                      tooltipText="zendesk chat api key for ask the expert feature of customer app and web."
                      element={(formik) => (
                          <TextInput
                              name="ZendeskChatAPIKey"
                              placeholder="Enter zendesk chat API key"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Google Server API Key for push notification"
                      element={(formik) => (
                          <TextInput
                              name="pushNotification"
                              placeholder="Enter google server API key for push notification"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Zillow API key for App"
                      tooltip={true}
                      tooltipText="Zillow Api key for for provider app. Zillow Web Services Identification (ZWSID)"
                      element={(formik) => (
                          <TextInput
                              name="ZillowAPIKeyForApp"
                              placeholder="Enter zillow API key for app"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Google Server API Key for Web panel "
                      tooltip={true}
                      tooltipText="Google Server API Key for Admin HeatMap "
                      element={(formik) => (
                          <TextInput
                              name="WebsiteFooterTwitterLink"
                              placeholder="Enter google server API key for web panel "
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={6}
                      label="Bitly Short URL Token"
                      tooltip={true}
                      tooltipText="Access Token for Shorten URL (Long URL short) with the help of Bitly."
                      element={(formik) => (
                          <TextInput
                              name="BitlyShortURLToken"
                              placeholder="Enter bitly short url token"
                              formik={formik}
                          />
                      )}
                  />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'Report an Issue',
        key: 'reportAnIssue',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={6}
                        label="App Issue"
                        tooltip={true}
                        tooltipText="When service provider create an issue report related to the app issue then we will send email to this emails."
                        element={(formik) => (
                            <Select
                                name="AppIssue"
                                placeholder="Enter app issue"
                                 open={false}
                                showSearch
                                formik={formik}
                                mode="tags"
                                allowClear
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Payment Issue"
                        tooltip={true}
                        tooltipText="When service provider create an issue report related to the Payment issue then we will send email to this emails."
                        element={(formik) => (
                            <Select
                                name="PaymentIssue"
                                placeholder="Enter payment issue"
                                 open={false}
                                showSearch
                                formik={formik}
                                mode="tags"
                                allowClear
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Damaged Equipment Issue"
                        tooltip={true}
                        tooltipText="When service provider create an issue report related to the damaged equipment issue then we will send email to this emails."
                        element={(formik) => (
                            <Select
                                name="DamagedEquipmentIssue"
                                placeholder="Enter damaged equipment issue"
                                 open={false}
                                showSearch
                                formik={formik}
                                mode="tags"
                                allowClear
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Other Issue"
                        tooltip={true}
                        tooltipText="When service provider create an issue report related to some other type of issue then we will send email to this emails."
                        element={(formik) => (
                            <Select
                                name="OtherIssue"
                                placeholder="Enter other issue"
                                 open={false}
                                showSearch
                                formik={formik}
                                mode="tags"
                                allowClear
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Supply House Issue"
                        tooltip={true}
                        tooltipText="When service provider create an issue report related to the supply house issue then we will send email to this emails."
                        element={(formik) => (
                            <Select
                                name="SupplyHouseIssue"
                                placeholder="Enter supply house issue"
                                 open={false}
                                showSearch
                                formik={formik}
                                mode="tags"
                                allowClear
                            />
                        )}
                    />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
    {
        name: 'Shopping Cart ',
        key: 'shoppingCart',
        content: (
          <>
            <div className='card'>
              <div className='card-inner'>
                <FormWrapper row={true} extraClassName="g-3">
                    <FormElementWrapper
                        md={6}
                        label="Shipping flat rate cost"
                        element={(formik) => (
                            <TextInput
                                name="EmailFromName"
                                placeholder="Enter shipping flat rate cost"
                                formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Stripe verification key (for NCA)"
                        element={(formik) => (
                            <TextInput
                                name="EmailFromName"
                                placeholder="Enter Stripe verification key"
                                formik={formik}
                            />
                        )}
                    />
                    <FormElementWrapper
                        md={6}
                        label="Email Address"
                        element={(formik) => (
                            <TextInput
                                name="EmailFromName"
                                placeholder="Enter email address"
                                formik={formik}
                            />
                        )}
                    />
                  <FormElementWrapper
                      md={12}
                      className="text-end"
                      element={() => (
                          <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          >
                          Save Changes
                          </CommonButton>
                      )}
                  />
                </FormWrapper>
              </div>
            </div>
          </>
        ),
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="General Settings">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          {/* <ListingHeader
            btnArray={['csvExport']}
          /> */}
        </div>
      </div>
      <div className="tabsonModal_nav">
      <Tabs
        tabContent={tabContent}
        tabsFor="table"
        activeKey={defaultKey}
        setActiveKey={setDefaultKey}
        // onTabChange={onTabChange}
      />
      </div>
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Please close the application and open it again to see the settings reflected after saving the new setting values above.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySetting;
