import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from '../index.layout';

export default function LoginLayout() {
  return (
    // <AppLayout>
      <div className="nk-body bg-lighter npc-default pg-survey no-touch nk-nio-theme">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content ">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    // </AppLayout>
  );
}
