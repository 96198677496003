import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Toggle,
  ActionWrapper,
  SweetAlert,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../../components';
import { Link,useNavigate } from 'react-router-dom';
import { modalNotification } from '../../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";


function ListSubServiceCategory(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Sub Category Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service-category',
      name: 'Service Category',
    },
    {
      path: '#',
      name: 'Sub Service Category',
    },
  ];
  const ButtonFormatter = (url, text) => {
    return (
      <>
        <div className="">
          <Link to={url} className="btn btn-primary">{text} <span className='badge ms-1 badge-circle align-items-center'>0</span></Link>
        </div>
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Yes',
        value: 'Yes',
      },
      {
        name: 'No',
        value: 'No',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'name',
      title: 'Name ',
      sorter: true,
      ...getColumnSearchProps("name")
    },
    {
      dataIndex: 'servicetypes',
      title: 'Service types',
      sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/services-type", "View"),
    },
    {
      dataIndex: 'displayOrder',
      title: 'Display Order',
      sorter: true,
    },
    {
      dataIndex: 'salesQuote',
      title: 'Sales Quote ',
      sorter: true,
      ...getColumnSelectSearchProps("sales quote", getStatusData())
    },
    {
      dataIndex: 'callingService',
      title: 'Calling Service (For Amazon Alex)',
      sorter: true,
      ...getColumnSelectSearchProps("calling service", getStatusData())
    },
    {
      dataIndex: 'technicianService',
      title: 'Talk to technician Service (For Amazon Alex)',
      sorter: true,
      ...getColumnSelectSearchProps("technician service", getStatusData())
    },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: () => <Toggle isDropDown/>,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/subservice-category/edit',
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      name: 'Same Day Emergency Service1',
      displayOrder: '1',
      salesQuote: 'No',
      callingService: 'No',
      technicianService: 'No'
    },
    {
      id: 2,
      name: 'System Install',
      displayOrder: '2',
      salesQuote: 'Yes',
      callingService: 'Yes',
      technicianService: 'No'
    },
    {
      id: 3,
      name: 'Time Blocker',
      displayOrder: '1',
      salesQuote: 'No',
      callingService: 'No',
      technicianService: 'No'
    }
  ];
  const navigate = useNavigate()
  const AddSubServiceCategory = () =>{
    navigate('/admin/subservice-category/add');
  }

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
    id: 3,
    name: 'Make Inactive'
    },
    {
    id: 3,
    name: 'Make Delete'
    },
  ] 

  const backPage = () =>{
    navigate('/admin/service-category');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Sub Service Category (HVAC)">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport','extraButton']}
            btnText="Add Sub Category"
            onHandleShow={AddSubServiceCategory}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Sub Category"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Sub Category module will list all Sub Categories on this page.
                </li>
                <li>
                Administrator can Activate / Deactivate / Delete any Sub Category
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListSubServiceCategory;
