import {
  LoginRoute,
  ncaRegisterRoute,
  getLoginRoutes,
  getRegisterRoutes,
} from './Auth/index';
import {
  DashboardRoute,
  getChildrenRoutes as dashboardRoutes,
} from './Dashboard/index';
import {
  HeatMapRoute,
  getChildrenRoutes as heatMapRoute,
} from './HeatMap/index';
import {
  GoogleConnect,
  getChildrenRoutes as googleConnect,
} from './GoogleConnect';
import {
  MarketingRoute,
  getChildrenRoutes as marketingRoute,
} from './Marketing';
import {
  CustomerRoute,
  getChildrenRoutes as customerRoutes,
} from './Customers';
import {
  ManualBooking,
  getChildrenRoutes as manualBooking,
} from './ManualBooking';
import {
  HomeInventory,
  getChildrenRoutes as homeInventory,
} from './HomeInventory';
import {
  NewEquipment,
  getChildrenRoutes as newEquipment,
} from './NewEquipment';
import {
  EquipmentOrderRoute,
  getChildrenRoutes as equipmentOrderRoute,
} from './EquipmentOrder';
import {
  WillCallListRoute,
  getChildrenRoutes as willCallListRoute,
} from './WillCallList';
import {
  TimeSlotRoute,
  getChildrenRoutes as timeSlotRoute,
} from './TimeSlot';
import {
  ScheduledBookingRoute,
  getChildrenRoutes as scheduledBookingRoute,
} from './ScheduledBooking';
import {
  OperatingProcedures,
  getChildrenRoutes as operatingProcedures,
} from './OperatingProcedures';
import {
  MembershipRoute,
  getChildrenRoutes as membershipRoute,
} from './Membership';
import {
  TagsRoute,
  getChildrenRoutes as tagsRoute,
} from './Tags';
import {
  CMSRoute,
  getChildrenRoutes as cmsRoute,
} from './CMS';
import {
  ChatRoute,
  getChildrenRoutes as chatRoute,
} from './Chat';
import {
  DontPermissionRoute,
  getChildrenRoutes as dontPermissionRoute,
} from './DontPermission';
import {
  NCAProfileRoute,
  getChildrenRoutes as ncaProfileRoute,
} from './NCAProfile';
import {
  LessonListRoute,
  getChildrenRoutes as lessonListRoute,
} from './LessonList';
import {
  SiteStatisticsRoute,
  getChildrenRoutes as siteStatisticsRoute,
} from './SiteStatistics';
import {
  NotificationRoute,
  getChildrenRoutes as notificationRoute,
} from './Notification';
import {
  RequestRoute,
  getChildrenRoutes as requestRoute,
} from './Request';
import {
  TruckOrdering,
  getChildrenRoutes as truckOrdering,
} from './TruckOrdering';
import {
  ServiceManagement,
  getChildrenRoutes as serviceManagement,
} from './ServiceManagement';
import {
  CompanySettingRoute,
  getChildrenRoutes as companySettingRoute,
} from './CompanySetting';
import {
  EmailSettingRoute,
  getChildrenRoutes as emailSettingRoute,
} from './EmailSetting';
import {
  SuperAdminRoute,
  getChildrenRoutes as superAdminRoutes,
} from './AdminSetting';
import { AdminLayout, LoginLayout } from '../layouts/index.layout';
import {
  AdminProfileRoute,
  getChildrenRoutes as profileRoute,
} from './AdminProfile';

import {
  TeamSettingRoute,
  getChildrenRoutes as teamSettingRoutes,
} from './TeamSetting';
import { getFlatRoutes } from '../helpers/index.helper';
import {
  ManageLocation,
  getChildrenRoutes as locationRoutes,
} from './ManageLocation';
import {
  Reports,
  getChildrenRoutes as reports,
} from './Reports';
import {
  PriceManagementRoute,
  getChildrenRoutes as priceManagementRoute,
} from './PriceManagement';
import { PageNotFoundPage } from '../views/PageNotFound';
import { WebsiteUnderMaintenance } from '../views/WebsiteUnderMaintenance';
import { ShoppingCartRoute, getChildrenRoutes as shoppingCartRoute } from './ShoppingCart';


export const routes = () => {
  return [
    {
      element: <LoginLayout />,
      children: [...LoginRoute(), ...ncaRegisterRoute()],
    },
    {
      path: '/admin',
      isParent: true,
      belongsToSidebar: true,
      element: <AdminLayout />,
      children: [
        ...DashboardRoute(),
        ...HeatMapRoute(),
        ...TruckOrdering(),
        ...HomeInventory(),
        ...NewEquipment(),
        ...EquipmentOrderRoute(),
        ...GoogleConnect(),
        ...WillCallListRoute(),
        ...OperatingProcedures(),
        ...ChatRoute(),
        ...SiteStatisticsRoute(),
        ...ManualBooking(),
        ...CustomerRoute(),
        ...ScheduledBookingRoute(),
        ...AdminProfileRoute(),
        ...ServiceManagement(),
        ...Reports(),
        ...TeamSettingRoute(),
        ...TimeSlotRoute(),
        ...MarketingRoute(),
        ...CompanySettingRoute(),
        ...EmailSettingRoute(),
        ...ManageLocation(),
       ...TagsRoute(),
        ...PriceManagementRoute(),
        ...NotificationRoute(),
        ...RequestRoute(),
        ...SuperAdminRoute(),
        ...DontPermissionRoute(),
        // ...NCAProfileRoute(),
        // ...CMSRoute(),
        // ...LessonListRoute(),
        ...MembershipRoute(),
        // ...ProductManagementRoute(),
        ...ShoppingCartRoute()
      ],
    },
    {
      path: '*',
      element: <PageNotFoundPage />,
    },
    {
      path: '/maintenance',
      element: <WebsiteUnderMaintenance />,
    },
  ];
};

export const getSidebarItems = () => {
  try {
    return routes().find((e) => e?.isParent).children;
  } catch (err) {
    console.log(err);
  }
};

/********Get Auth Route for redirection if user try to access public page on login state********** */
export const getDefaultAuthRoute = (user) => {
  return '/admin/dashboard';
};

/********Get Non Auth Route for redirection if user try to access private page on non login state********** */
export const getDefaultPublicRoute = (user) => {
  return '/login';
};

export const getLoginRoute = (user) => {
  return '/login';
};

/*******Define all nested routes which have routes path********** */
export const routesList = () => {
  return [
    ...dashboardRoutes(),
    ...heatMapRoute(),
    ...truckOrdering(),
    ...chatRoute(),
    ...siteStatisticsRoute(),
    ...manualBooking(),
    ...customerRoutes(),
    ...scheduledBookingRoute(),
    ...homeInventory(),
    ...newEquipment(),
    ...equipmentOrderRoute(),
    ...googleConnect(),
    ...willCallListRoute(),
    ...operatingProcedures(),
    ...profileRoute(),
    ...locationRoutes(),
    ...serviceManagement(),
    ...reports(),
    ...teamSettingRoutes(),
    ...timeSlotRoute(),
    ...marketingRoute(),
    ...companySettingRoute(),
    ...emailSettingRoute(),
    ...tagsRoute(),
    ...priceManagementRoute(),
    ...notificationRoute(),
    ...requestRoute(),
    ...superAdminRoutes(),
    ...dontPermissionRoute(),
    // ...ncaProfileRoute(),
    // ...cmsRoute(),
    // ...lessonListRoute(),
    ...membershipRoute(),
    // ...productManagementRoute(),
    ...shoppingCartRoute(),
  ];
};

export const moduleRoutesList = () => {
  let adminRouteArr = {
    admin: [...LoginRoute()[0].children, ...LoginRoute()[1].children],
  };
  return adminRouteArr;
};

export const getCompletePathList = () => {
  // console.log(getFlatRoutes(routesList()));
  return getFlatRoutes(routesList());
};
