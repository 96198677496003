import {Toggle, SelectFilter, InputFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const TagTableColumn = ({ Action }) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };

  const geTagTypeData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Customer',
        value: 'Customer',
      },
      {
        name: 'Job',
        value: 'Job',
      },
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "tagName",
      title: "Tag Name",
      sorter: true,
      ...getColumnSearchProps("tag name")
    },
    {
      dataIndex: "tagType",
      title: "Tag Type",
      sorter: true,
      ...getColumnSelectSearchProps("tag type", geTagTypeData())
    },
      {
        dataIndex: "status",
        title: "Status",
        sorter: true,
        render:()=><Toggle isDropDown />,
        ...getColumnSelectSearchProps("status", getStatusData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
