export const text = {
  home: 'Home',
  dashboard: 'Dashboard',
  superAdminSetting: 'Super Admin Setting',
  noRecordFound: 'No Record Found',
  addCity: 'Add City',
  addCountry: 'Add Country',
  addState: 'Add State',
  submit: 'Submit',
  cancle: 'Cancel',
  countryName: 'Country Name',
  country: 'Country',

  stateName: 'State Name',
  state: 'State',
  cityName: 'City Name',
  status: 'Status',
  selectStatus: ' Select Status',
  countryCode: 'Country Code',
  stateCode: 'State Code',
  cityCode: 'City Code',
  countryPhoneCode: 'Country Phone Code',
  unit: 'Unit',
  selectCountry: 'Select Country',
  selectState: 'Select State',
  emergencyNumber: 'Emergency number',
  selectAction: 'Select Action',
  reset: 'Reset',
  filter: 'Filter',
  select: 'Select',
  show: 'Show',
  cgst: 'Cgst (IN %)',
};
