import React from 'react';
import { useState } from 'react';
import {  Link, useNavigate, } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  CommonButton,
  ModalComponent,
  FormElementWrapper,
  TextInput,
  ImageElement,
} from '../../../components';

function ServiceInvoice(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service',
      name: 'Service Management',
    },
    {
      path: '#',
      name: 'Invoice',
    },
  ];

  const [showEdit, setEmail] = useState(false);
  const EmailModal = () => {
    setEmail(true);
  };
  const closeEmailModal = () => {
    setEmail(false);
  };


  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Invoice">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/service')}
          />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
                <div className='table-responsive'>
                    <table style={{"width":"100%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{"width":"100%"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                      <ImageElement
                                                            source="provider-img.png"
                                                            className="img-fluid logo-img"
                                                            alt="logo"
                                                            style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                        />
                                                        <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                            Provider
                                                        </span>
                                                        <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                            Jorge neon
                                                        </span>
                                                        <span>jorge@mailinator.com</span>
                                                    </td>
                                                        <td>
                                                        <ImageElement
                                                        source="user-img.png"
                                                        className="img-fluid logo-img"
                                                        alt="logo"
                                                        style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                    />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        User</span>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>Shane
                                                        Watson </span>
                                                    <span>shanewatson@mailinator.com</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{"width":"100%", "marginTop": "10px", "marginBottom": "10px"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>Booking
                                                        Address </span> : Vijay Nagar, Indore, Madhya
                                                    Pradesh 452010, India<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>
                                                        Job ID:</span>
                                                    75833147<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}} >Job
                                                        Date
                                                        Timing </span>: Your Job 06:35 PM on 15 Nov 2022
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{"width":"100%", "background": "#f1f3f9", "textAlign": "left", "padding": '10px', "marginBottom": "10px"}}>
                                    <table className='w-100 mt-3'>
                                        <thead>
                                            <tr>
                                                <th style= {{ "color": "#364a63" }}>Service Details</th>
                                                <th style= {{ "color": "#364a63" }}>Service Name</th>
                                                <th style= {{ "color": "#364a63" }}>QTY</th>
                                                <th style= {{ "color": "#364a63", "textAlign": "right" }}>Service Charge</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>HVAC</td>
                                                <td>Same Day Emergency Service1</td>
                                                <td>1</td>
                                                <td className='text-end'>99.95</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 mt-3'>
                                        <tbody>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63", "fontWeight": "bold"}}>Service Price</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>5000.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Subtotal</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>5408.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Discount(By
                                                        Provider)</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>8.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Purchase
                                                        Order</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>400.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Sublabor
                                                        Fee</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$500.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Returned Amount
                                                    </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$200.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Commission
                                                    </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$0</td>
                                            </tr>

                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Total
                                                    </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$4700</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Notes
                                                </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>Arrived</td>
                                            </tr>

                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Invoice Message
                                                </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>-</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Other Notes
                                                </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{"paddingTop":" 100px", "textAlign": "center", "width": "300px", "marginLeft": "auto", "marginRight":"auto"}}>
                                        <tbody>
                                            <tr>
                                                <td style={{"paddingBottom": "10px", "borderBottom": "1px solid #e1e1e1"}}>
                                                <ImageElement
                                                        source="signature.jpg"
                                                        className="img-fluid"
                                                        alt="signature"
                                                        width="80"
                                                        height="80"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>                        
            </div>
          </div>
          <div className='text-center mt-3 mt-lg-4'>
              <CommonButton className="btn btn-primary" onClick={() => EmailModal()}>E-mail</CommonButton>
              <CommonButton className="btn btn-secondary ms-2">Print</CommonButton>
            </div>
        </div>
        {/* Email Modal */}
        <ModalComponent
        closeButton={true}
        extraTitleClassName="justify-content-center"
        onHandleCancel={() => {
          setEmail(false);
        }}
        backdrop
        show={showEdit}
        title="Email"
      >
        <form className="w-100">
          <FormElementWrapper
            label="Email"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="Enter email"
                placeholder="Enter email"
                formik={formik}
              />
            )}
          />                
          <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2 mt-2">
            <li>
              <Link
                href="#"
                onClick={() => closeEmailModal()}
                className="btn btn-primary"
              >
                Send
              </Link>
            </li>
            <li>
              <Link
                href="#"
                onClick={() => closeEmailModal()}
                className="btn btn-light"
              >
                Cancel
              </Link>
            </li>
          </ul>
        </form>
      </ModalComponent>
    </>
  );
}

export default ServiceInvoice;
