import i18next from 'i18next';
import * as yup from 'yup';

export const initValues = () => {
  return {
    description: '',
    documentType: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    description: yup
      .string()
      .required(i18next.t('validation.appText.description')),
    documentType: yup
      .string()
      .required(i18next.t('validation.appText.infoUrl')),
  });
};
