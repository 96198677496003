import { Outlet } from 'react-router-dom';
import { ListTruckOrdering } from '../../views/TruckOrdering';


export default function route() {
  return [
    {
      path: accessRoute.TRUCK_ORDERING.path,
      private: true,
      name: 'PO / Truck Stock Ordering',
      key: "truckStockOrdering",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.TRUCK_ORDERING.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.TRUCK_ORDERING.path,
        name: 'Truck Stock Ordering',
        key: accessRoute.TRUCK_ORDERING.key,
        belongsToSidebar: false,
        icon: accessRoute.TRUCK_ORDERING.icon,
        element: <ListTruckOrdering />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  TRUCK_ORDERING: {
    path: '/admin/truck-stock-ordering',
    key:"truckStockOrdering.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-truck" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
