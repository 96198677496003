import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  Toggle,
  ActionWrapper,
  TablePlus,
  InputFilter,
} from '../../../../../components';
import { Link,useNavigate } from 'react-router-dom';
import { FilterFilled  } from "@ant-design/icons";

function ViewMarketingSMSTemplate(props) {

  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Marketing SMS Templates',
    },
  ];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });

  const TextFormatter = (text) => {
    return (
        <>
         <div className='postDescription'>{text}</div>
        </>
    );
};
  
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'SMSPurpose',
      title: 'SMS Purpose',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("SMS purpose")
    },
    {
        dataIndex: 'SMSBody',
        title: 'SMS Body',
        headerClasses: 'sorting',
        sorter: true,
        ...getColumnSearchProps("SMS body"),
        render: (cell, row) => TextFormatter(row?.SMSBody),
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: () => <Toggle isDropDown/>,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        path: '/admin/marketing-template/sms/edit',
      },
    ]);

    const statusActionOption = [
      {
      id: 1,
      name: 'Select Action'
      },
      {
      id: 2,
      name: 'Make Active'
      },
      {
      id: 3,
      name: 'Make Inactive'
      },
    ] 

  const adminData = [
    {
      id: 1,
      SMSPurpose: 'Marketing SMS Template1',
      SMSBody: 'Dear Customer, We have an some offer for you, So please contact with me this contact number #PROVIDER_PHONE_NUMBER#. Thanks!',
    },
    {
      id: 2,
      SMSPurpose: 'Identify your goals',
      SMSBody: '	When it comes to running a successful Email Marketing, the first and foremost step is to identify your goals. Ask yourself the question- What do you want to achieve from your email marketing campaign? Is it boosting your sales, or do you wish to remain in touch with your customers? Many companies opt for Email Mark',
    },
    {
      id: 3,
      SMSPurpose: 'Opening',
      SMSBody: 'Hi #CUSTOMER_NAME#, hope you can join us at the opening of Tékhnēs new LOCATION store at 5 PM, 04/15/2023. Msg frequency varies. Reply HELP for help, STOP to cancel. Privacy: attnl.tv/p/JsPBandier: Inboxes are overrated, use code MOBILE15 at checkout for 15% off your next order #PROVIDER_NAME# #PROVIDER_PHONE_NUMBER#',
    },
    {
      id: 4,
      SMSPurpose: 'Birthday Discount',
      SMSBody: '	Happy birthday, #CUSTOMER_NAME#! To help you celebrate, enjoy 12% off storewide this month. Use discount code LL3AQLH',
    },
    {
      id: 5,
      SMSPurpose: 'Assign SP',
      SMSBody: 'Hi. #CUSTOMER_NAME#. We have received your inquiry. Your SP is #PROVIDER_NAME#. Please do not hesitate to contact us again if any questions arise.',
    },
  ];
  const navigate = useNavigate()
  const AddServiceCategory = () =>{
    navigate('/admin/marketing-template/sms/add');
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Marketing SMS Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            btnText="Add Template"
            onHandleShow={AddServiceCategory}
            // popover={<ServiceCategoryFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}  
        showAction={false}
        statusAction={false}
        statusActionOption={statusActionOption}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Marketing SMS Templates module will list all sms on this page.
                </li>
                <li>
                Administrator can add/edit any sms template.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMarketingSMSTemplate;
