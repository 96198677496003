import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  SweetAlert,
  Toggle,
  DatePicker,
  TablePlus,
  InputFilter,
  DateFilter,
  DiscountFilter,
  SelectFilter
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import {  Radio } from 'antd';
import { FilterFilled  } from "@ant-design/icons";

function ListDiscount(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Discount Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Discount',
    },
  ];

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }    
  ]
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectDateProps = (dataIndex) => ({
    filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectDiscountProps = (dataIndex) => ({
    filterDropdown: (props) => (<DiscountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getQuoteOrderData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Premium',
        value: 'Premium',
      },
      {
        name: 'Better',
        value: 'Better',
      },
      {
        name: 'Best',
        value: 'Best',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'quoteOrder',
      title: '	Quote Order',
       sorter: true,
       ...getColumnSelectSearchProps("quote order", getQuoteOrderData())
    },
    {
      dataIndex: 'title',
      title: 'Title',
       sorter: true,
      ...getColumnSearchProps("title")
    },
    {
      dataIndex: 'cost',
      title: 'Cost',
       sorter: true,
      ...getColumnSelectDiscountProps("cost")
    },
    {
      dataIndex: 'minJobAmount',
      title: 'Min Job Amount',
       sorter: true,
       ...getColumnSearchProps("min job amount")
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
       sorter: true,
      ...getColumnSelectDateProps("date")
    },
    {
      dataIndex: 'endDate',
      title: 'End Date',
       sorter: true,
       ...getColumnSelectDateProps("date")
    },
    {
      dataIndex: 'description',
      title: 'Description',
       sorter: true,
       ...getColumnSearchProps("description")
    },
    {
      dataIndex: 'status',
      title: 'Status',
       sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{EditDiscountShow()},
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const adminData = [
    {
      id: 1,
      quoteOrder: 'Premium,Better,Best,Good',
      title: 'Test1',
      cost: '	$ 50.00',
      minJobAmount: '	500.00',
      startDate: '	Tue, November 16, 2021',
      endDate: '	Wed, December 1, 2021',
      description: 'Test Discount'
    },
  ];
  const [addDiscount, setAddDiscount] = useState(false);
  const addDiscountHide = () =>{
    setAddDiscount(false)
  }
  const addDiscountShow = () =>{
    setAddDiscount(true)
  }
  const [EditDiscount, setEditDiscount] = useState(false);
  const EditDiscountHide = () =>{
    setEditDiscount(false)
  }
  const EditDiscountShow = () =>{
    setEditDiscount(true)
  }
  const QuoteOrderData =[
    {
      name: 'Premium',
      value: 'Premium',
    },
    {
      name: 'Better',
      value: 'Better',
    },
  ]
  const [value, setValue] = useState('percent');
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Discount">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create',]}
            btnText="Add Discount"
            onHandleShow={addDiscountShow}
            // popover={<DiscountFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Discount"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addDiscount}
        onHandleCancel={addDiscountHide}
        title="Add Discount"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Discount Title  "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="discounttitle"
                    placeholder="Enter discount title "
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                  md={6}
                  label="Discount Type"
                  mandatory={true}
                  element={(formik) => (
                      <Radio.Group value={value} onChange={onChange}>
                          <Radio value={'dollor'}>Flat (in $)</Radio>
                          <Radio value={'percent'}>Percent (%)</Radio>
                      </Radio.Group>
                  )}
                />
                <FormElementWrapper
                md={6}
                label="Cost "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="cost"
                    placeholder="Enter cost"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Min Job Amount"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="MinJobAmount"
                    placeholder="Enter min job amount "
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Start Date"
                mandatory={true}
                element={(formik) => (
                  <DatePicker
                    name="StartDate"
                    className="form-control date-picker shadow-none"
                    placeholder="Select start date"
                    disabled
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="End Date"
                mandatory={true}
                element={(formik) => (
                  <DatePicker
                    name="EndDate"
                    className="form-control date-picker shadow-none"
                    placeholder="Select end date"
                    disabled
                  />
                )}
              />
          <FormElementWrapper
              md={12}
              label="Quote Order"
              mandatory={true}
              element={(formik) => (
                <Select
                  name="quoteOrder"
                  placeholder="Select quote order"
                  options={QuoteOrderData}
                  showSearch
                  formik={formik}
                  mode="multiple"
                  allowClear
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label="Description"
              mandatory={true}
              element={(formik) => (
                <textarea
                  name="Description"
                  placeholder="Enter description*"
                  formik={formik}
                  className="form-control"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() => addDiscountHide()}
                  >
                    Add
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => addDiscountHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={EditDiscount}
        onHandleCancel={EditDiscountHide}
        title="Edit Discount"
      >
        <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={6}
                label="Discount Title  "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="discounttitle"
                    placeholder="Enter discount title "
                    formik={formik}
                    defaultValue="Premium"
                  />
                )}
              />
              <FormElementWrapper
                  md={6}
                  label="Discount Type"
                  mandatory={true}
                  element={(formik) => (
                      <Radio.Group value={value} onChange={onChange}>
                          <Radio value={'dollor'}>Flat (in $)</Radio>
                          <Radio value={'percent'}>Percent (%)</Radio>
                      </Radio.Group>
                  )}
                />
                <FormElementWrapper
                md={6}
                label="Cost "
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="cost"
                    placeholder="Enter cost"
                    formik={formik}
                    type="number"
                    defaultValue="50"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Min Job Amount"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="MinJobAmount"
                    placeholder="Enter min job amount "
                    formik={formik}
                    type="number"
                    defaultValue="500"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Start Date"
                mandatory={true}
                element={(formik) => (
                  <DatePicker
                    name="StartDate"
                    className="form-control date-picker shadow-none"
                    placeholder="Select start date"
                    disabled
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="End Date"
                mandatory={true}
                element={(formik) => (
                  <DatePicker
                    name="EndDate"
                    className="form-control date-picker shadow-none"
                    placeholder="Select end date"
                    disabled
                  />
                )}
              />
          <FormElementWrapper
              md={12}
              label="Quote Order"
              mandatory={true}
              element={(formik) => (
                <Select
                  name="quoteOrder"
                  placeholder="Select quote order"
                  options={QuoteOrderData}
                  showSearch
                  formik={formik}
                  defaultValue="Best"
                  mode="multiple"
                  allowClear
                />
              )}
            />
            <FormElementWrapper
              md={12}
              label="Description"
              mandatory={true}
              element={(formik) => (
                <textarea
                  name="Description"
                  placeholder="Enter description*"
                  formik={formik}
                  className="form-control"
                  defaultValue="Test Discount"
                />
              )}
            />
            <FormElementWrapper
              md={12}
              className="text-end"
              element={() => (
                <>
                  <CommonButton
                    type="submit"
                    className="btn btn-primary me-1 me-sm-2"
                    onClick={() => EditDiscountHide()}
                  >
                    Update
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-light me-1 me-sm-2"
                  >
                    Reset
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    className="btn btn-secondary"
                    onClick={() => EditDiscountHide()}
                  >
                    Cancel
                  </CommonButton>
                </>
              )}
            />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListDiscount;
