
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea,
  Select,
  CheckBox,
  RadioComponent as Radio,
} from "../../../../../components";

function EditHouseLocation(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/equipment-location')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/equipment-location",
      name: "Equipment Location",
    },
    {
      path: "#",
      name: "Edit Equipment Location",
    },
  ];

  const GeoFenceLocationData = [
    {
      name: 'Atlantic',
      value: 'Atlantic',
    },
    {
      name: 'Central Indiana',
      value: 'Central Indiana',
    },
  ]


  const RolodexList = [
    {
      label:'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ]

  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Equipment Location">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6}
                label="Equipment Name"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="EquipmentName"
                    placeholder="Enter equipment name"
                    formik={formik}
                    defaultValue="Fuller Shelf"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Equipment Location"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="EquipmentLocation"
                    placeholder="Enter equipment location"
                    formik={formik}
                    defaultValue="1500 E Michigan St, Indianapolis, IN 46201, USA"
                  />
                )}
              />
              
              <FormElementWrapper
                md={12}
                label="Email"
                mandatory={true}
                link="Add More"
                // actionHandler={}
                element={(formik) => (
                  <TextInput
                    name="email"
                    placeholder="Enter email"
                    formik={formik}
                  />
                )}
              />
              {/* <FormElementWrapper
                md={12}
                label="Email"
                mandatory={true}
                link="Remove"
                // actionHandler={}
                element={(formik) => (
                  <TextInput
                    name="email"
                    placeholder="Enter email"
                    formik={formik}
                  />
                )}
              /> */}
              <FormElementWrapper
                md={6}
                
                label="Geo Fence Location Served"
                element={(formik) => (
                  <Select
                    name="GeoFenceLocationServed"
                    placeholder="Select geo fence location served"
                    options={GeoFenceLocationData}
                    showSearch
                    formik={formik}
                    mode="multiple"
                    allowClear
                    defaultValue="All"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Office Number"
                element={(formik) => (
                  <TextInput
                    name="OfficeNumber"
                    placeholder="Enter office number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                element={(formik) => (
                  <CheckBox
                    element="Use same number for SMS number also"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="SMS text Number"
                element={(formik) => (
                  <TextInput
                    name="SMSTextNumber"
                    placeholder="Enter SMS text number"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Email Contact "
                element={(formik) => (
                  <TextInput
                    name="EmailContact "
                    placeholder="Enter email"
                    formik={formik}
                    type="email"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Show on Rolodex"
                element={(formik) => (
                  <Radio radioGroupArray={RolodexList} name="Rolodex" />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Show in Equipment Order Location And Truck Stock Ordering"
                element={(formik) => (
                  <Radio radioGroupArray={RolodexList} name="equipmentOrder" />
                )}
              />
              <FormElementWrapper
                md={12}
                label="Notes"
                element={(formik) => (
                  <AntTextArea
                    name="Notes"
                    className="form-control"
                    placeholder="Enter Equipment note"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Update
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
            </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditHouseLocation;
