import React from "react";
import {
  Breadcrumb,
  PageHeader,
  TablePlus,
} from "../../../components";
import {
  Columns,
   WillCallListServices,
} from "../../../services/index.service";

function ListWillCall(props) {
  const EquipmentOrderData = WillCallListServices();
  const columns = Columns.WillCallListTableColumn();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Will Call List",
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Will Call List">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          {/* <ListingHeader
            btnArray={["filter"]}
            popover={<PaymentReportFilterForm />}
          /> */}
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        isSearch={false}
        showAction={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Will Call List module will list all Same-day Emergency Services of all Main Category on this page.</li>
                <li>Administrator can Activate / Deactivate Service.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListWillCall;
