import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Toggle,
  ActionWrapper,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TablePlus,
  InputFilter,
  SelectFilter,
  ServiceCategoryFilterForm,
} from "../../../../components";
import { Link } from "react-router-dom";
import { Radio } from "antd";
import { FilterFilled  } from "@ant-design/icons";

function ProviderSales(props) {

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Providers",
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });  
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  }
  const columns = [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "providerName",
      title: "Provider Name ",
      headerClasses: "sorting",
       sorter: true,
       ...getColumnSearchProps("provider name")
    },
    {
      dataIndex: "locationName",
      title: "Location Name",
      headerClasses: "sorting",
       sorter: true,
    },
    {
        dataIndex: "jobCount",
        title: "Job Count",
        headerClasses: "sorting",
         sorter: true,
      },
      {
        dataIndex: "totalSales",
        title: "Total Sales",
        headerClasses: "sorting",
         sorter: true,
      },
      {
        dataIndex: "averageTicket",
        title: "Average Ticket ",
        headerClasses: "sorting",
         sorter: true,
      },
  ];
  const adminData = [
    {
      id: 1,
      providerName: 'MARK SERVICE MAN',
      locationName: 'Indore',
      jobCount: '	55',
      totalSales: '	9558844.30',
      averageTicket: '	173797.17'
    },
    {
      id: 2,
      providerName: 'Ryan Sterrett',
      locationName: 'Indore',
      jobCount: '	120',
      totalSales: '	8858844.30',
      averageTicket: '	163797.17'
    },
    {
      id: 3,
      providerName: 'shane anthony',
      locationName: 'Indore',
      jobCount: '	49',
      totalSales: '	758844.30',
      averageTicket: '	273797.17'
    },
    {
      id: 4,
      providerName: 'Tom Patterson',
      locationName: 'Indore',
      jobCount: '		603',
      totalSales: '		401358.58',
      averageTicket: '641.15'
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Providers">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['filter']}
            popover={<ServiceCategoryFilterForm />}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
    </>
  );
}

export default ProviderSales;
