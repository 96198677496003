import React, { useState, useEffect, memo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {
  useLocation,
  Link,
  useNavigate,
  generatePath,
  useParams,
} from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { CommonButton, ImageElement } from '../../components';
import { getCompletePathList } from '../../routes/index.routes';

function Sidebar({ routes, sidebarOpen, menuToggle }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    collapsed: false,
    menu: [],
    current: location.pathname,
  });
  const [sideBarCompact, setSideBarCompact] = useState(false);
  const [currentKey, setCurrentKey] = useState(getSidebarPath()?.key);

  useEffect(() => {
    setCurrentKey(getSidebarPath()?.key);
  }, [location]);

  useEffect(() => {
    if (routes() && routes() instanceof Array) {
      setState({ ...state, menu: routes().filter((e) => e.belongsToSidebar) });
    }
  }, []);

  // useEffect(() => {
  //   setCurrentActive(location.pathname);
  // }, [location.pathname]);

  /************
   * These line is commented due to causing performance issue
   */
  // let menuLink = document.querySelectorAll(
  //   '.nk-menu-link:not(.nk-menu-toggle)'
  // );
  // menuLink?.forEach((link)=> {
  //   link.addEventListener('click', function () {
  //     menuToggle(false);
  //   })
  // });

  const sideBarToggle = () => {
    setSideBarCompact(!sideBarCompact);
  };

  function getSidebarPath() {
    const path = getCompletePathList().find((e) => {
      return getGeneratedPath(e.path) === location.pathname;
    });

    if (!path) {
      navigate('/page-not-found');
    }
    return path;
  }

  function getGeneratedPath(path) {
    try {
      return generatePath(path, params);
    } catch (error) {
      return path;
    }
  }

  return (
    <div
      className={`nk-sidebar nk-sidebar-fixed is-dark ${
        sideBarCompact ? 'is-compact' : ''
      } ${sidebarOpen ? 'nk-sidebar-active' : ''}`}
      data-content="sidebarMenu"
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <Link
            to="/admin/dashboard"
            className="logo-link nk-sidebar-logo"
          >
          <ImageElement
                  source="logo-white.svg"
                  className="logo-light logo-img"
                  alt="logo"
                />
          <ImageElement
                  source="small-logo-white.svg"
                  className="logo-small logo-img logo-img-small"
                  alt="logo"
                />
         
           
           
          </Link>
        </div>
        <div className="nk-menu-trigger me-n2">
          <Link
            onClick={() => menuToggle()}
            role="button"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left" />
          </Link>
          <Link
            onClick={() => sideBarToggle()}
            role="button"
            className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-menu" />
          </Link>
        </div>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <SimpleBar forceVisible="y" className="nk-sidebar-menu" autoHide>
            <Accordion
              className="bg-transparent rounded-0"
              defaultActiveKey={currentKey?.split('.')[0]}
              onSelect={(e) => {
                if (e !== null) {
                  setCurrentKey(e);
                }else {
                  setCurrentKey(getSidebarPath()?.key);
                }
              }}
              activeKey={currentKey?.split('.')[0]}
            >
              <ul className="nk-menu">
                {state.menu.map((item, i) => {
                  let routeData;
                  if (Array.isArray(item.children) && item?.submenu) {
                    routeData = (
                      <React.Fragment key={item.name}>
                        <Accordion.Item
                          as="li"
                          className={`bg-transparent nk-menu-item ${
                            currentKey.split('.')[0] === item.key
                              ? 'active'
                              : ''
                          }`}
                          key={item.name}
                          eventKey={item.key}
                        >
                          <Accordion.Header>
                            <Link
                              to="#"
                              onClick={(e) => e.preventDefault()}
                              className="nk-menu-link nk-menu-toggle"
                            >
                              {item.icon}
                              <span className="nk-menu-text">{item.name}</span>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <ul className="nk-menu-sub">
                              <Accordion
                                as="li"
                                key={`${currentKey.split('.')[0]}.${
                                  currentKey.split('.')[1]
                                }`}
                                defaultActiveKey={`${
                                  currentKey.split('.')[0]
                                }.${currentKey.split('.')[1]}`}
                                // activeKey={`${currentKey.split('.')[0]}.${currentKey.split('.')[1]}`}
                                className="bg-transparent rounded-0 nk-menu-item"
                                // defaultActiveKey={currentKey}
                              >
                                {item.children
                                  .filter((e) => e.belongsToSidebar)
                                  .map((child, key) => {
                                    if (child?.children && child?.submenu) {
                                      return (
                                        // <Accordion
                                        //   as="li"
                                        //   key={`${currentKey.split('.')[0]}.${currentKey.split('.')[1]}`}
                                        //   defaultActiveKey={`${currentKey.split('.')[0]}.${currentKey.split('.')[1]}`}
                                        //   // activeKey={`${currentKey.split('.')[0]}.${currentKey.split('.')[1]}`}
                                        //   className="bg-transparent rounded-0 nk-menu-item active"
                                        // // defaultActiveKey={currentKey}
                                        // >
                                        <Accordion.Item
                                          as="li"
                                          className={`bg-transparent ${
                                            currentKey === child.key
                                              ? 'active'
                                              : ''
                                          }`}
                                          key={child.key}
                                          eventKey={child.key}
                                        >
                                          <Accordion.Header>
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                              className="nk-menu-link nk-menu-toggle"
                                            >
                                              <span className="nk-menu-text">
                                                {child.name}
                                              </span>
                                            </Link>
                                          </Accordion.Header>
                                          <Accordion.Body className="p-0">
                                            <ul className="nk-menu-sub">
                                              {child.children
                                                .filter(
                                                  (e) => e.belongsToSidebar
                                                )
                                                .map((item2, i) => {
                                                  if (
                                                    item2?.children &&
                                                    item2.children.length > 0 &&
                                                    item2?.submenu
                                                  ) {
                                                    return (
                                                      <Accordion
                                                        // as="li"
                                                        key={i}
                                                        className="nk-menu-item bg-transparent rounded-0"
                                                        defaultActiveKey={
                                                          currentKey
                                                        }
                                                      >
                                                        <Accordion.Item
                                                          // as="li"
                                                          className={`bg-transparent ${
                                                            currentKey ===
                                                            item2.key
                                                              ? 'active'
                                                              : ''
                                                          }`}
                                                          key={item2.name}
                                                          eventKey={item2.key}
                                                        >
                                                          <Accordion.Header>
                                                            <Link
                                                              to="#"
                                                              onClick={(e) =>
                                                                e.preventDefault()
                                                              }
                                                              className="nk-menu-link nk-menu-toggle"
                                                            >
                                                              {item2.icon}
                                                              <span className="nk-menu-text">
                                                                {item2.name}
                                                              </span>
                                                            </Link>
                                                          </Accordion.Header>
                                                          <Accordion.Body className="p-0">
                                                            <ul className="nk-menu-sub">
                                                              {item2.children
                                                                .filter(
                                                                  (e) =>
                                                                    e.belongsToSidebar
                                                                )
                                                                .map(
                                                                  (
                                                                    item3,
                                                                    i
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <li
                                                                          className={`nk-menu-item ${
                                                                            item3.key ===
                                                                            currentKey
                                                                              ? 'active'
                                                                              : ''
                                                                          }`}
                                                                          key={
                                                                            item3.key
                                                                          }
                                                                        >
                                                                          <Link
                                                                            to={
                                                                              item3.path
                                                                            }
                                                                            className="nk-menu-link"
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              menuToggle(
                                                                                e
                                                                              )
                                                                            }
                                                                          >
                                                                            <span className="nk-menu-text">
                                                                              {
                                                                                item3.name
                                                                              }
                                                                            </span>
                                                                          </Link>
                                                                        </li>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                            </ul>
                                                          </Accordion.Body>
                                                        </Accordion.Item>{' '}
                                                      </Accordion>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <li
                                                          className={`nk-menu-item bg-transparent ${
                                                            item2.key ===
                                                            currentKey
                                                              ? 'active'
                                                              : ''
                                                          }`}
                                                          key={item2.key}
                                                        >
                                                          <Link
                                                            to={item2.path}
                                                            className="nk-menu-link"
                                                            onClick={(e) =>
                                                              menuToggle(e)
                                                            }
                                                          >
                                                            {item2.icon}
                                                            <span className="nk-menu-text">
                                                              {item2.name}
                                                            </span>
                                                          </Link>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                })}
                                            </ul>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        // </Accordion>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <Accordion.Item
                                            className={`nk-menu-item bg-transparent ${
                                              child.key === currentKey
                                                ? 'active'
                                                : ''
                                            }`}
                                            key={child.key}
                                          >
                                            <Link
                                              to={child.path}
                                              className="nk-menu-link"
                                              onClick={(e) => menuToggle(e)}
                                            >
                                              {child.icon}
                                              <span className="nk-menu-text">
                                                {child.name}
                                              </span>
                                            </Link>
                                          </Accordion.Item>
                                        </>
                                      );
                                    }
                                  })}
                              </Accordion>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </React.Fragment>
                    );
                  } else {
                    routeData = (
                      <Accordion.Item
                        as="li"
                        eventKey={item?.key}
                        className={`bg-transparent nk-menu-item ${
                          item.key === currentKey ? 'active' : ''
                        }`}
                      >
                        <Accordion.Button className="nk-menu-link-normal">
                          <Link
                            to={item.path}
                            className="nk-menu-link"
                            onClick={(e) => menuToggle(e)}
                          >
                            {item.icon}
                            <span className="nk-menu-text">{item.name}</span>
                          </Link>
                        </Accordion.Button>
                      </Accordion.Item>
                    );
                  }
                  return routeData;
                })}
                <li className='nk-menu-item'><Link to="/login" className="nk-menu-link"><span className='nk-menu-icon'><em class="icon ni ni-signout"></em></span> Logout</Link></li>
              </ul>
            </Accordion>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default memo(Sidebar);
