import {MultiSelectFilter, Toggle, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const proceduresTableColumn = ({ Action }) => {
  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getProviderListData = () => {
    return [
      {
        name: 'What is an HVAC System and How Does it Work? -77.7°C',
        value: 'What is an HVAC System and How Does it Work? -77.7°C',
      },
      {
        name: 'TEST Notifications SP Apps side',
        value: 'TEST Notifications SP Apps side',
      },
      {
        name: 'TEST051 52',
        value: 'TEST051 52',
      },
      {
        name: 'TEST  54100152',
        value: 'TEST  54100152',
      }
    ];
  };
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Admin',
        value: 'Admin',
      },
      {
        name: 'Service Provider',
        value: 'Service Provider',
      },
    ];
  };
  const getRequiredData = () => {
    return [      
      {
        name: 'Yes',
        value: 'Yes',
      },
      {
        name: 'No',
        value: 'No',
      },
    ];
  };
  const getActionData = () => {
    return [      
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getManageData = () => {
    return [
      {
        name: 'American Home Shield',
        value: 'American Home Shield',
      },
      {
        name: 'CALL BOOKING',
        value: 'CALL BOOKING',
      },
      {
        name: 'Category',
        value: 'Category',
      },
      {
        name: 'Electrician',
        value: 'Electrician',
      }
    ];
  };
  const getModulesData = () => {
    return [
      {
        name: 'Site Statistics',
        value: 'Site Statistics',
      },
      {
        name: 'Provider',
        value: 'Provider',
      },
      {
        name: 'Admin',
        value: 'Admin',
      },
      {
        name: 'Services',
        value: 'Services',
      }
    ];
  };
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "lessonTitle",
      title: "Lesson Title",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnMultiSelectSearchProps("lesson title", getProviderListData())
    },
    {
      dataIndex: "typeSop",
      title: "Type of SOP",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("SOP", getStatusData())
    },
    {
      dataIndex: "companyServices",
      title: "Manage Services",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnMultiSelectSearchProps("manage services", getManageData())
    },
    {
      dataIndex: "adminModules",
      title: "Admin Modules",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnMultiSelectSearchProps("admin modules", getModulesData())
    },
      {
        dataIndex: "questionsNo",
        title: "No. of Questions",
        headerClasses: "sorting",
        sorter: true,
      },
      {
        dataIndex: "requiredNo",
        title: "Required ",
        headerClasses: "sorting",
        ...getColumnSelectSearchProps("required", getRequiredData()),
        sorter: true,
      },
      {
        dataIndex: "status",
        title: "Status",
        headerClasses: "sorting",
        render:()=><Toggle isDropDown/>,
        sorter: true,
        ...getColumnSelectSearchProps("status", getActionData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
