import React from 'react';
import { ModalComponent } from '../..';

function ModalWrapper({ content, show, onCancel, ...rest }) {
  return (
    <ModalComponent backdrop show={show} onHandleCancel={onCancel} {...rest}>
      {content}
    </ModalComponent>
  );
}

export default ModalWrapper;
