export const customersService = () => {
  return [
    {
      id: 1,
      userName: "joe testing",
      email: "joe@tekhne.app",
      usersCompany: "Good Guys Admin",
      signUpDate: "Mon, October 31, 2022 03:00 PM",
      mobile: "(+1) 3178214104",
      location: "United States",
      membershipStatus: 'Member',
      memberRenewalDate: 'Mon, October 25, 2022 05:00 PM'
    },
    {
      id: 2,
      userName: "User",
      email: "	user@gmail.com",
      usersCompany: "Good Guys Admin",
      signUpDate: "Wed, October 26, 2022 05:50 AM",
      mobile: "(+91) 1234567890",
      location: "	India",
      membershipStatus: 'Non-Member',
      memberRenewalDate: 'Tue, October 10, 2022 03:00 PM'
    },
    {
      id: 3,
      userName: "Deepak Yadav",
      email: "deepak.yadav@codiant.com",
      usersCompany: "Good Guys Admin",
      signUpDate: "Fri, October 14, 2022 11:31 AM",
      mobile: "(+91) 5245245241",
      location: "	India",
      membershipStatus: 'Non-Member Expired',
      memberRenewalDate: 'Wed, October 05, 2022 04:00 PM'
    },
    {
      id: 4,
      userName: "vinod customer",
      email: "vinodcus@mailinator.com",
      usersCompany: "Good Guys Admin",
      signUpDate: "Thu, October 13, 2022 02:24 AM",
      mobile: "(+91) 7894561311",
      location: "United States",
      membershipStatus: 'Non-Member',
      memberRenewalDate: 'Sat, April 10, 2022 08:00 PM'
    },
  ];
};
