import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Charts,
  ActionWrapper,
  ServiceProviderPaymentFilterForm,
  CommonButton,
  TablePlus,AmountFilter,InputFilter,SelectFilter, FormWrapper, FormElementWrapper, Select, DatePicker, MultiSelectFilter
} from '../../../../components';
import { FilterFilled  } from "@ant-design/icons";


function ProviderPayment(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Service Provider Payment Report',
    },
  ];
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const Action = () =>
  actionFormatter([
    {
      name: 'View Detail',
      icon: 'icon ni ni-eye',
      // path: '/admin/service-provider-payment/payment-details'
      path: '/admin/payment-report'
    },
  ]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const getProviderListData = () => {
    return [
      {
        name: 'abhishekYadav',
        value: 'Abhishek Yadav',
      },
      {
        name: 'aditiTest',
        value: 'Aditi Test',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnMultiSelectSearchProps("provider name", getProviderListData()),
    },
    {
      dataIndex: 'providerAccountName',
      title: 'Provider Account Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("account name"),
    },
    {
      dataIndex: 'bankName',
      title: 'Bank Name',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("bank name"),
    },
    {
      dataIndex: 'accountNumber',
      title: 'Account Number',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("account number"),
    },
    {
      dataIndex: 'routingNumber',
      title: 'Routing Number',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("routing number"),
    },
    {
      dataIndex: 'location',
      title: 'Location',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'totalJobTiming',
      title: 'Total Job Timing',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'totalJobFees',
      title: 'A=Total Job Fees',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'totalPlatformFees',
      title: 'B=Total Platform Fees',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'totalSublaborPay',
      title: 'C=Total Sublabor Pay',
      headerClasses: 'sorting',
      ...getColumnSelectAmountProps(),
      sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        if(cell=== "$ 165.00"){ 
          return <>{cell} <br/> <CommonButton onClick= { (e) => subLabourModalShow(e) } className="btn btn-primary btn-sm">View Details</CommonButton></>
        }else{
          if(cell=== ""){
            return <>$ 0.00</>
          }
        }
      }
    },
    {
      dataIndex: 'totalDeductAmountSublabor',
      title: 'D=Total Deduct Amount (Sublabor Pay)',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'totalDeductAmountPurchase',
      title: 'E=Total Deduct Amount (Purchase Order)',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'totalProviderPayment',
      title: 'F= Total Provider Payment(((A-B)+C)-D)-E',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'providerPaymentStatus',
      title: 'Provider Payment Status',
      // headerClasses: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const adminData = [
    {
      id: 1,
      providerName: 'jorge neon',
      providerAccountName: '	jorge',
      bankName: '	sniiii',
      accountNumber: '000999999991',
      routingNumber: '110000000',
      location: 'New Palasia, Indore',
      totalJobTiming: '20h 39m 55s	',
      totalJobFees: '$ 5,400.00',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 4,500.00	'
    },
    {
      id: 2,
      providerName: 'Yash Dev',
      providerAccountName: 'Dev',
      bankName: '	Sbbii',
      accountNumber: '000999999991',
      routingNumber: '110000000',
      location: '101, Bansi Trade Centre',
      totalJobTiming: '19m 33s	',
      totalJobFees: '$ 279.85',
      totalPlatformFees: '$ 27.99',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 0.00',
      totalDeductAmountPurchase: '$ 0.00',
      totalProviderPayment: '$ 251.86		'
    },
    {
      id: 3,
      providerName: 'Jacob butt	',
      providerAccountName: 'Jacob Butt	',
      bankName: '	fbi',
      accountNumber: '000123456789',
      routingNumber: '011401533',
      location: 'Indore',
      totalJobTiming: '05m 36s	',
      totalJobFees: '$ 89.95	',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 89.95	'
    },
    {
      id: 4,
      providerName: 'Jacob butt	',
      providerAccountName: 'Jacob Butt	',
      bankName: '	fbi',
      accountNumber: '000123456789',
      routingNumber: '011401533',
      location: 'Indore',
      totalJobTiming: '05m 36s	',
      totalJobFees: '$ 89.95	',
      totalPlatformFees: '$ 0.00',
      totalSublaborPay: '$ 165.00',
      totalDeductAmountSublabor: '$ 500.00',
      totalDeductAmountPurchase: '$ 400.00',
      totalProviderPayment: '$ 89.95	'
    },
  ];

  /* Sub Labour Data*/
  const subLabourData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      serviceType: "HVAC - Same Day Emergency Service1",
      servicePrice: "$99.95",
      sublaborPayName: "akash provider",
      total: "$5.00"
    },
    {
      id: 2,
      jobNumber: "75833148",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$0.00",
      sublaborPayName: "akash provider",
      total: "$50.00"
    },
    {
      id: 3,
      jobNumber: "27881123",
      providerName: "Ron Test",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$10.00",
      sublaborPayName: "akash provider",
      total: "$500.00"
    },
    {
      id: 4,
      jobNumber: "36974681",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Service Repair",
      servicePrice: "$20.00",
      sublaborPayName: "akash provider",
      total: "$55.00"
    },
  ];
  
  const subLabourcolumns = [
    {
      dataIndex: 'id',
      title: 'Id',
      sorter: true,
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'sublaborPayName',
      title: 'Sublabor Pay Name',
      sorter: true,
      headerClasses: 'sorting',
    },
    {
      dataIndex: 'total',
      title: 'Total',
      sorter: true,
      headerClasses: 'sorting',
    },
  ];
  const navigate = useNavigate()
  // const TotalSubLaborPayDetails = (e) =>{
  //   e.preventDefault();
  //   navigate('/admin/service-provider-payment/sub-labor-details');
  // }
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  const [ShowHideGraph, setShowHideGraph] = useState(false)
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  }
  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
    }
  }
  const jobData = {
    labels: [
      'Jan 21',
      'Feb 21',
      'Mar 21',
      'Apr 21',
      'May 21',
      'Jun 21',
      'Jul 21',
      'Aug 21',
      'Sep 21',
      'Oct 21',
      'Nov 21',
      'Dec 21',
    ],
    datasets: [
      {
        label: 'COLLECTED',
        data: [9, 20, 15,17,22,17,12,18,13,11,3,7],
        backgroundColor: [
          '#1EE0AC',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'UNCOLLECTED',
        data: [19, 13, 17,12,8,9,11,16,17,22,5,11,10],
        backgroundColor: [
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  // Sub Labour Modal
  const [subLabourModal, setSubLabourModal] = useState(false);
  const subLabourModalShow = () => {
    setSubLabourModal(true);
  };
  const subLabourModalHide = () => {
    setSubLabourModal(false);
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Service Provider Payment Report">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'create', 'csvExport']}
            btnText="Show / Hide Graph"
            onHandleShow={ () => ShowHideGraphShow() }
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
            
                <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="state"
                        placeholder="Select location"
                        options={locationData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
      </div>
      {
        ShowHideGraph &&
        <div className='card mb-3'>
          <div className='card-inner'>
            <div className='d-lg-flex align-items-center'>
              <div style={{ maxWidth: '1100px', width: '100%', height: '350px'}}>
                <Charts type="bar" data={jobData} options={ chartOptionsData}/>
              </div>
              <ul className="nk-store-statistics mt-0 mt-lg-4 ms-lg-4">
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">COLLECTED</div>
                    <div className="count  d-flex flex-wrap align-items-center">
                      $20115773.28
                      <span className="change down">
                        <em className="ni ni-arrow-long-down"></em>2696 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-info-dim ni ni-activity-round"></em>
                </li>
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">UNCOLLECTED</div>
                    <div className="count  d-flex flex-wrap align-items-center">
                    $751732.75
                      <span className="change up">
                        <em className="ni ni-arrow-long-up"></em>534 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-purple-dim ni ni-activity-round"></em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}
        showAction={false}
      />

      {/* Sub Labour Pay Details Modal */}
      <ModalComponent
        backdrop
        show={subLabourModal}
        onHandleCancel={subLabourModalHide} extraClassName="modal-xxl"
        title="Sublabor Pay Details"
      > 
          <TablePlus
          onModal
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={subLabourData}
          tableColumns={subLabourcolumns}
          // tableLoader={isLoading}
          setSizePerPage=""
          selectRow={false}
          isResetBtn= {false}
          showAction={false}

        />
        <div className='text-end'>
          <CommonButton className="btn btn-secondary" onClick= { () => subLabourModalHide() }>Close</CommonButton>
        </div>
      </ModalComponent>
    </>
    
  );
}

export default ProviderPayment;
