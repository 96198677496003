import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  AntTextArea,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";


function ListService(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Service Type Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Service Type',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Yes',
        value: 'Yes',
      },
      {
        name: 'No',
        value: 'No',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'salesQuote',
      title: '	Sales Quote',
      sorter: true,
      ...getColumnSelectSearchProps("sales quote", getStatusData())
    },
    {
      dataIndex: 'displayOrder',
      title: 'Display Order ',
      sorter: true,
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
      ...getColumnSearchProps("description")
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{editServiceTypeShow()},
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      salesQuote: '	Free Same Day - Quote Service',
      displayOrder: '1',
      description: 'Quote (Electrical Panel, Whole House Generator, Etc.)',
    },
    {
      id: 2,
      salesQuote: '	Home Warranty of America',
      displayOrder: '0',
      description: 'This Area Is Only For Home Warranty Of America',
    },
    {
      id: 3,
      salesQuote: '	North American Contractors - HVAC Heating Tune-Up',
      displayOrder: '2',
      description: 'North American Contractors - HVAC Heating Tune-Up',
    },
    {
      id: 4,
      salesQuote: '	Free - Scheduled - Quote Service',
      displayOrder: '1',
      description: 'Quote (Electrical Panel, Whole House Generator, Etc.)',
    },
    {
      id: 5,
      salesQuote: '	Free - Scheduled - Replacement Quote (plumbing rel',
      displayOrder: '1',
      description: 'Free - Scheduled - Replacement Quote (Plumbing Related)',
    },
  ];
  const [addServiceType, setAddServiceType] = useState(false);
  const addServiceTypeHide = () =>{
    setAddServiceType(false)
  }
  const addServiceTypeShow = () =>{
    setAddServiceType(true)
  }
  const [editServiceType, setEditServiceType] = useState(false);
  const editServiceTypeHide = () =>{
    setEditServiceType(false)
  }
  const editServiceTypeShow = () =>{
    setEditServiceType(true)
  }
  const ServiceCategory = [
    {
      name: 'HVAC',
      value: 'HVAC',
    },
    {
      name: 'electrician',
      value: 'Electrician',
    },
  ];
  const displayOrderData =[
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
  ]

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Delete'
    },
  ] 


  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Service Type">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport']}
            btnText="Add Service Type"
            onHandleShow={addServiceTypeShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Service Type"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>
                Service Type module will list all Service types on this page.
                </li>
                <li>
                Administrator can Edit / Delete any Service type.
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={addServiceType}
        onHandleCancel={addServiceTypeHide}
        title=" Add Service Type"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
            md={6}
            label="Service Category "
            mandatory={true}
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select service category "
                options={ServiceCategory}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Service Type"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="ServiceType"
                placeholder="Enter Service Type"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description "
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description *"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Display Order "
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select display order "
                options={displayOrderData}
                // showSearch
                formik={formik}
              />
            )}
          />   
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addServiceTypeHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addServiceTypeHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>  
      <ModalComponent
        backdrop
        show={editServiceType}
        onHandleCancel={editServiceTypeHide}
        title="Edit Service Type"
      >
      <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
            md={6}
            label="Service Category "
            mandatory={true}
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select service category "
                options={ServiceCategory}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Service Type"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="ServiceType"
                placeholder="Enter Service Type"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description "
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description *"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Display Order "
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select display order "
                options={displayOrderData}
                // showSearch
                formik={formik}
              />
            )}
          />   
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-1 me-sm-2"
                  onClick={() => editServiceTypeHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-1 me-sm-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editServiceTypeHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>  
    </>
  );
}

export default ListService;
