import {   CheckBox, InputFilter, SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});




const getRolesData = () => {
  return [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'DefaultPlan',
      value: 'Default Plan',
    },
    {
        name: 'NCAPRE',
        value: 'NCA PRE',
      },
  ];
};
const getPlanStatusData = () => {
  return [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Cancelled',
      value: 'Cancelled',
    },
  ];
};
const getNcaLinksData = () => {
  return [
    {
        name: 'All',
        value: 'All',
      },
    {
      name: 'Yes',
      value: 'Yes',
    },
    {
      name: 'No',
      value: 'No',
    },
  ];
};
const getStatusData = () => {
  return [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
};


const ButtonFormatter = (url) => {
    return (
        <>
        <Link to={url} className="text-primary">Click Here</Link>
        </>
    );
};

const ModalOpenFormatter = ( url , text) => {
  return (
      <>
          <Link to="#" onClick={url} className="text-primary">{text}</Link>
      </>
  );
};

const logoFormatter = (url) => {
  return (
    <>
      <div className="text-center">
        <Link to={url}><em className="icon ni ni-file-doc" style={{fontSize: '30px'}}/></Link>
      </div>
    </>
  );
};

export const NCATableColumn = ({ Action, userViewModal, profileStatusModal }) => {
    return [
        {
          dataIndex: "id",
          title: "S.No.",
          headerClasses: "w_70",
        },
        {
            dataIndex: 'name',
            title: 'Name',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ModalOpenFormatter(userViewModal, row?.name),
            ...getColumnSearchProps("name"),
        },
        {
            dataIndex: 'email',
            title: 'Email',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSearchProps("email"),
        },
        {
            dataIndex: "roles",
            title: "Roles",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("roles", getRolesData()),
            sorter: true,
        },
        {
            dataIndex: 'companyname',
            title: 'Company Name',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSearchProps("company name"),
        },
        {
            dataIndex: 'companycode',
            title: 'Company Code',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSearchProps("company code"),
        },
        {
            dataIndex: 'profileStatus',
            dataIndex1: 'profileStatusColor',
            title: 'Profile Status',
            headerClasses: 'sorting',
            sorter: true,
            render:(cell, row)=>{
              console.log("Details", cell)
              if(cell=== "Incomplete"){ 
                return <> <Link to="#" class="badge bg-danger text-white" onClick={ profileStatusModal }> Incomplete </Link> </>
              }else{
                if(cell=== "Complete"){
                  return <><span class="badge bg-success"> Complete </span> </>
                }
              }
            }
        },
        {
            dataIndex: "planStatus",
            title: "Plan Status",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("plan status", getPlanStatusData()),
            sorter: true,
        },
        {
            dataIndex: 'paymentReport',
            title: 'Payment Report',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell,row) => ButtonFormatter("/admin/nca/payment-report")
        },
        {
            dataIndex: 'viewEditDocument',
            title: 'View/Edit Document(s)',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => logoFormatter("/admin/nca/documents"),
        },
        {
            dataIndex: "ncalink",
            title: "NCA Link",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("NCA link", getNcaLinksData()),
            sorter: true,
            render: () => <CheckBox />,
        },
        {
            dataIndex: "status",
            title: "Status",
            headerClasses: "sorting",
            ...getColumnSelectSearchProps("status", getStatusData()),
            sorter: true,
            render: () => <Toggle isDropDown />,
        },
        {
          dataIndex: "action",
          title: "Action",
          className: "nk-tb-col-tools text-end",
          render: () => <Action />,
        },
      ];
};
