import { Image } from "antd";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  PaymentReportFilterForm,
  TablePlus,
  ModalComponent,
  ImageElement,
  SweetAlert,
  CommonButton,
  FormWrapper,
  FormElementWrapper,
  Select,
  DatePicker,
} from "../../../components";
import {
  Columns,
   NewEquipmentInstallServices,
} from "../../../services/index.service";
import { modalNotification } from "../../../utils";

function NewEquipmentList(props) {
    const NewEquipmentInstallData = NewEquipmentInstallServices();
  const columns = Columns.NewEquipmentInstallTableColumn({equipmentViewModal,saveUpdates,cancelUpdates});

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "New Equipment Install & Warranty Registration",
    },
  ];
    const [addModal, setAddModal] = useState(false);
    const addModalHide = () =>{
        setAddModal(false)
    };
    function equipmentViewModal(){
        setAddModal(true)
    }
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onConfirmAlert = () => {
        setIsAlertVisible(false);
        modalNotification({ type: "success", message: "Data Updated Successfully", })
        return true;
    };
    const [isAlertVisible1, setIsAlertVisible1] = useState(false);
    const onConfirmAlert1 = () => {
        setIsAlertVisible1(false);
        return true;
    };
    function saveUpdates() {
        setIsAlertVisible(true);
    }
    function cancelUpdates() {
        setIsAlertVisible1(true);
    }
    const filterData = [
      {
        name: 'Today',
        value: 'Today',
      },
      {
        name: 'Yesterday',
        value: 'Yesterday',
      },
      {
        name: 'Current Week',
        value: 'Current Week',
      },
      {
        name: 'Previous Week',
        value: 'Previous Week',
      },
      {
        name: 'Current Month',
        value: 'Current Month',
      },
      {
        name: 'Previous Month',
        value: 'Previous Month',
      },
      {
        name: 'Current Year',
        value: 'Current Year',
      },
      {
        name: 'Previous Year',
        value: 'Previous Year',
      },
    ]
    const[globalFilter, setGlobalFilter ] = useState(false);
    const BackBtnFilter = () => {
      setGlobalFilter(false)
    }
    const OpenBtnFilter = () => {
      setGlobalFilter(true)
    }
    if (globalFilter) {
      document.querySelector('body').classList.add('toggle-shown');
    } else {
      document.querySelector('body').classList.remove('toggle-shown');
    }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="New Equipment Install & Warranty Registration">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[""]}
            popover={<></>}
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className="">
        <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tableScrollHide">
        <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={NewEquipmentInstallData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          isSearch={false}
          showAction={false}
          scrollTopPosition
        />
      </div>
      <SweetAlert
        title="Are you sure"
        text="you want to save changes ?"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <SweetAlert
        title="Are you sure"
        text=" You want to discard changes ?"
        show={isAlertVisible1}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible1}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert1}
      />
      <ModalComponent
        backdrop
        show={addModal}
        onHandleCancel={addModalHide}
        title="Installed Equipment"
      >
        <div>
        {/* <div className="user-card user-card-s2 p-0 mb-2">
            <div className="user-avatar lg bg-primary">
                <ImageElement source="uploadImg.png" alt="img"/>
            </div>
        </div> */}
        <div className="row g-3">
            <div className="col-6">
                <span className="sub-text">Brand</span>
                <span>ywuw</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Model#</span>
                <span>63737</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Serial#</span>
                <span>62717</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Installation Date</span>
                <span>2022</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Application Type</span>
                <span>Residential-Single-Family</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Type of Purchase</span>
                <span>New</span>
            </div>
            <div className="col-6">
                <span className="sub-text">System Condition</span>
                <span>Average</span>
            </div>
            <div className="col-6">
                <span className="sub-text">Register Warranty For New Equipment?</span>
                <span>Yes</span>
            </div>
            <div className="col-12">
                <span className="sub-text">System Notes</span>
                <span>Test</span>
            </div>
            <div className="col-12">
                <span className="sub-text">Photos</span>
                <div className="imgPreviewBox">
                  <Image src="/assets/images/admin/uploadImg.png" />
                </div>
            </div>
        </div>
        <div className="text-end mt-2">
            <Link
                href="#"
                className="btn btn-secondary"
                onClick={ () => addModalHide() }
            >
            Close
            </Link>
        </div>
      </div>
      </ModalComponent>  
    </>
  );
}

export default NewEquipmentList;