import React, { useState } from "react";
// import Input from "../../../../../../components/UIElements/TextInput/index.element";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  AntSelect,
  InputType,
  SweetAlert,
  ActionWrapper,
  TablePlus,
  Toggle,
} from "../../../../../../components";

import { Link } from "react-router-dom";
import { getStateService } from "../../../../../../services/Localization";
import { Columns } from "../../../../../../services/index.service";
import { modalNotification } from "../../../../../../utils";

function ListState(props) {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "State Deleted Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.stateTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler:editstateActionFilterShow,
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          type:"redirect",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
  });

  const customerData = getStateService();
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "State",
    },
  ];

  const [stateActionFilter, setstateActionFilter] = useState(false);
  const stateActionFilterShow = () => {
    setstateActionFilter(true);
  };
  const stateActionFilterHide = () => {
    setstateActionFilter(false);
  };

  const [editstateActionFilter, seteditstateActionFilter] = useState(false);
  const editstateActionFilterShow = () => {
    seteditstateActionFilter(true);
  };
  const editstateActionFilterHide = () => {
    seteditstateActionFilter(false);
  };
  const countryData = [
    {
      id: "1",
      name: "India",
    },
    {
      id: "2",
      name: "United State",
    },
  ];
  const statusData = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Inactive",
    },
  ];
  const statusActionOption = [
    {
      id: 1,
        name: 'Select Action'
      },
      {
        id: 2,
        name: 'Make Active'
      },
      {
        id: 3,
        name: 'Make Inactive'
      },
      {
        id: 4,
        name: 'Make Delete'
      }
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="State">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create", "csvExport"]}
            btnText="Add State"
            onHandleShow={stateActionFilterShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        showAction={false}
        isSearch={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this state"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>State module will list all countries on this page.</li>
                <li>Can Activate / Deactivate / Delete any state.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={stateActionFilter}
        onHandleCancel={stateActionFilterHide}
        title="Add State"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Country Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select country"
                  options={countryData}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="State name"
                  name="StateName"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Code <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="State code"
                  name="StateCode"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Status
                </label>
                <Toggle />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => stateActionFilterHide()}
                className="btn btn-primary me-2"
              >
                Add
              </Link>
              <Link
                href="#"
                className="btn btn-light me-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => stateActionFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editstateActionFilter}
        onHandleCancel={editstateActionFilterHide}
        title="Edit State"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Country Name <span className="text-danger"> *</span>
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select country"
                  options={countryData}
                  defaultValue="India"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Name <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="State name"
                  name="StateName"
                  disabled={false}
                  variant="standard"
                  type="text"
                  defaultValue="MP"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  State Code <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="State code"
                  name="StateCode"
                  disabled={false}
                  variant="standard"
                  type="text"
                  defaultValue="01"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                  Status
                </label>
                <Toggle />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => editstateActionFilterHide()}
                className="btn btn-primary me-1 me-sm-2"
              >
                Update
              </Link>
              <Link
                href="#"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => editstateActionFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}

export default ListState;
