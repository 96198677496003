import React from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  ActionWrapper,
} from "../../../../components";
import {
  Columns, emailTemplatesService,
} from "../../../../services/index.service";
import { Link, useNavigate } from "react-router-dom";

function ListEmail(props) {
  const EquipmentOrderData = emailTemplatesService();

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Email Templates",
    },
  ];

  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.emailTemplatesTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{  EditEmailPage() },
        },
      ]),
  });

  const navigate = useNavigate();
  const EditEmailPage = () => {
    navigate("/admin/email-templates/edit")
  }

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Email Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['csvExport',]}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EquipmentOrderData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}
        showAction={false}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Email Templates module will list all emails on this page.</li>
                <li>Administrator can edit any email template.</li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListEmail;
