export const NCAServices = () => {
    return [
      {
        id: 1,
        name: "abdul Hannan",
        email: "ab@gghs.com",
        roles: "India make One",
        companyname: "testcompany",
        companycode: "	166d780i0109",
        profileStatus: 'Incomplete',
        planStatus: "Active",
      },
      {
        id: 2,
        name: "Ravi Singh",
        email: "ravi@gghs.com",
        roles: "NCA PRE",
        companyname: "Codiant",
        companycode: "	166q738k3736",
        profileStatus: 'Incomplete',
        planStatus: "Active",
      },
      {
        id: 3,
        name: "test testing",
        email: "test@gghs.com",
        roles: "India make One",
        companyname: "testcompany",
        companycode: "	166d780i0109",
        profileStatus: 'Incomplete',
        planStatus: "Inactive",
      },
      {
        id: 4,
        name: "Testing NCA 17-10-2022",
        email: "test@gghs.com",
        roles: "India make One",
        companyname: "Test NCA 17-10-2022",
        companycode: "	166g599h3382",
        profileStatus: 'Incomplete',
        planStatus: "Inactive",
      },
      {
        id: 5,
        name: "test testing",
        email: "test@gghs.com",
        roles: "India make One",
        companyname: "testcompany",
        companycode: "	166d780i0109",
        profileStatus: 'Complete',
        planStatus: "Inactive",
      },
    ];
  };
  