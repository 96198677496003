import React from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TextEditor,
} from '../../../../components';
import {  useNavigate } from 'react-router-dom';

function EditEmail(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/email-templates',
      name: 'Email Templates',
    },
    {
      path: '#',
      name: 'Edit Email Templates',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/email-templates')
  }
  return (
    <>
      <div className='wide-md mx-auto'>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit Email Templates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className='card'>
          <div className='card-inner'>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                label="English Subject "
                mandatory={true}
                element={(formik) => (
                    <TextInput
                        name="English subject "
                        placeholder="Enter subject"
                        formik={formik}
                        defaultValue="Bill generated successfully"
                    />
                )}
            />
            <FormElementWrapper
                md={12}
                label="English Body"
                mandatory={true}
                element={(formik) => (
                    <TextEditor>
                      
                         Hello Administrator,

                          #NCA# bill for plan #PLAN# (#PAYMODE#) has been generated successfully. The next billing date will be #NEXT_BILL_DATE#..

                          Thank You.

                          #MAILFOOTER#
                    </TextEditor> 
                )}
            />
            <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                    <>
                        <CommonButton
                            type="submit"
                            className="btn btn-primary me-2"
                            >
                            Update
                        </CommonButton>
                        <CommonButton
                            type="submit"
                            className="btn btn-light"
                            >
                            Reset
                        </CommonButton>
                    </>
                )}
            />
        </FormWrapper>
                    
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default EditEmail;
