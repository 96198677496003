import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  AntSelect,
  //   TextInput,
  InputType,
  Toggle,
  ActionWrapper,
  SweetAlert,
  TextEditor,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListFaq(props) {
  const onConfirmAlert = () => {
    modalNotification({ type: 'success', message: 'Delete Faq Successfully' });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const statusActionOption = [
    {
    id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
      id: 4,
      name: 'Make Delete'
    }
  ]
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/faq',
      name: 'Faq',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'title',
      title: 'Title',
      headerClasses: 'sorting',
      sorter: true,
      ...getColumnSearchProps("title"),
    },
    {
      dataIndex: 'order',
      title: 'Order',
      headerClasses: 'sorting',
      sorter: true,
    },
    {
      dataIndex: 'status',
      title: 'Status',
      headerClasses: 'sorting',
      render: () => <Toggle isDropDown/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", getStatusData())
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () => {
          editfaqFilterShow(true);
        },
        // path: "/admin/dashboard"
        // type:"redirect",
        //   handler:()=>{ setIsAlertVisible(true);
        //     document.body.click()}
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const customerData = [
    {
      id: 1,
      title: '	Do I have to enter my credit card in the App?',
      order: '4',
      status: 'Active',
    },
    {
      id: 2,
      title: 'Do you have liability insurance?',
      order: '6',
      status: 'Active',
    },
    {
      id: 3,
      title: 'How does the Good Guys Home Services App work?',
      order: '2',
      status: 'Active',
    },
    {
      id: 4,
      title: 'How often should I service my HVAC and plumbing system?',
      order: '3',
      status: 'Inactive',
    },
    {
      id: 5,
      title:
        'If I just have a question about something in my home, who can I ask?',
      order: '5',
      status: 'Active',
    },
    {
      id: 6,
      title: 'What happens when I need a repair on a holiday or weekend?',
      order: '3',
      status: 'Inactive',
    },
  ];
  const [faqFilter, setfaqFilter] = useState(false);
  const faqFilterShow = () => {
    setfaqFilter(true);
  };
  const faqFilterHide = () => {
    setfaqFilter(false);
  };
  const [editfaqFilter, seteditfaqFilter] = useState(false);
  const editfaqFilterShow = () => {
    seteditfaqFilter(true);
  };
  const editfaqFilterHide = () => {
    seteditfaqFilter(false);
  };
  const statusData = [
    {
      id: '1',
      name: 'Active',
    },
    {
      id: '2',
      name: 'Inactive',
    },
  ];
  const OrderData = [
    {
      id: '1',
      name: '1',
    },
    {
      id: '2',
      name: '2',
    },
    {
      id: '3',
      name: '3',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Faq">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'create', 'csvExport']}
            btnText="Add Faq"
            onHandleShow={faqFilterShow}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={customerData}
        tableColumns={columns}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this faq"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>FAQ module will list all faqs on this page.</li>
                <li>
                  Administrator can Activate / Deactivate / Delete any faq.
                </li>
                <li>Administrator can export data in XLS or PDF format.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        backdrop
        show={faqFilter}
        onHandleCancel={faqFilterHide}
        title="Add Faq"
        extraClassName="modal-lg"
      >
        <form>
          <div className="row g-3">
          <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">Status</label>
                {/* <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select status"
                  options={statusData}
                /> */}
                <Toggle  />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">Order</label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select order"
                  options={OrderData}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Question <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter question"
                  name="Question"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Answer <span className="text-danger"> *</span>
                </label>
                <TextEditor />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => faqFilterHide()}
                className="btn btn-primary me-2"
              >
                Add
              </Link>
              <Link
                href="#"
                className="btn btn-light me-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => faqFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editfaqFilter}
        onHandleCancel={editfaqFilterHide}
        title="Edit Faq"
        extraClassName="modal-lg"
      >
        <form>
          <div className="row g-3">
          <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">Status</label>
                {/* <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select status"
                  options={statusData}
                  defaultValue="Active"
                /> */}
                <Toggle />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">Order</label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select order"
                  options={OrderData}
                  defaultValue="1"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Question <span className="text-danger"> *</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter question"
                  defaultValue="Do I have to enter my credit card in the App?"
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                English Answer <span className="text-danger"> *</span>
                </label>
                <TextEditor>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </TextEditor> 
              </div>
            </div>           
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => editfaqFilterHide()}
                className="btn btn-primary me-1 me-sm-2"
              >
                Update
              </Link>
              <Link
                href="#"
                className="btn btn-light me-1 me-sm-2"
              >
                Reset
              </Link>
              <Link
                href="#"
                onClick={() => editfaqFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}

export default ListFaq;
