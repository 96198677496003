import React from 'react';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Password,
} from '../../../index';
import { initValues, validation } from './validation';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function NCALoginForm() {
  // const [showPassword, setShowPassword] = useState(false);
  // const navigate = useNavigate();
  // const loginPage = () => {
  //   navigate('/login');
  // };
  const navigate = useNavigate();
  const loginPage = () => {
    navigate('/admin/dashboard');
  };
  const onBackPage = () =>{
    navigate('/login');
  }
  return (
    <FormWrapper
      // onSubmit={onSubmit}
      // validation={validation}
      // initialValues={initValues()}
    >
      {(props) => {
        return (
          <>
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
              xs={12}
              label="Company Administrator E-mail"
              element={(formik) => (
                <TextInput
                  name="emailOrMobile"
                  formik={props}
                  placeholder="Enter your email address"
                  extraClassName="form-control-lg"
                />
              )}
            />
            <FormElementWrapper
              xs={12}
              label="Password"
              extraTopRightElement={<div className="form-label-group">
                    <Link to="/forget-password" className="link link-primary link-sm">Forgot Password?</Link>
                  </div>
              }
              element={(formik) => (
                <Password
                extraClassName="form-control-lg"
                  name="password"
                  formik={props}
                  placeholder="Enter your password"
                />
              )}
            />
            <div className="form-group">
              <CommonButton
                // type="submit"
                className="btn btn-lg btn-primary btn-block"
                onClick = { loginPage()}
              >
               LOGIN
              </CommonButton>
            </div>
            <div className="form-note-s2 text-center">
              Don&apos;t have an account?{' '}
              <Link to="/nca/register"> SIGN UP</Link>
            </div>
            <div className="form-note-s2 text-center">
              <Link to="#" onClick={onBackPage()} className="text-uppercase ms-1">
              Back
              </Link>
            </div>
            </FormWrapper>
          </>
        );
      }}
    </FormWrapper>
  );
}

export default NCALoginForm;
