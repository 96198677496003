import React from 'react';
import { Col } from 'react-bootstrap';
import AntTooltip from '../../UIElements/Tooltip';
import { Link } from 'react-router-dom';
import ImageElement from '../../UIElements/ImageElement';

function FormElementWrapper({
  element,
  label,
  formik,
  extraTopRightElement=<></>,
  visible = true,
  tooltip = false,
  tooltipText = '',
  link= false,
  button= false,
  actionHandler,
  actionButtonHandler,
  mandatory = false,
  extraLabelClass = false,
  formnote=false,
  imgPreviewBox=false,
  textCount=false,
  infoLabel=false,
  ...rest
}) {
  return (
    <>
      {visible && (
       <Col className="form-group" {...rest}>
            {label && (
              <div className="form-label-group">
                <label className={`form-label pe-none w-100 ${extraLabelClass ? extraLabelClass : ''}`}>
                  {label} {mandatory && <span className='text-danger'>*</span>} {' '}
                  {tooltip && (
                    <AntTooltip promptText={tooltipText} className="icon ni ni-info-fill pe-auto"></AntTooltip>
                  )}
                  {link && <Link to="#" className='float-end pe-auto' onClick={actionHandler}>{link}</Link> }
                  {
                    button && <button className='btn btn-primary btn-sm pe-auto' onClick={actionButtonHandler}><em class="icon ni ni-info-fill me-1"></em>{button}</button>
                  }
                  {
                    infoLabel && <span className='text-primary pe-none'>({infoLabel})</span>
                  }
                </label>
                {extraTopRightElement}
              </div>
            )}
            {element && (
              <div className="form-control-wrap">{element(formik)}</div>
            )}
            {
              imgPreviewBox &&
              <div className="imgPreviewBox">
                <ImageElement source="uploadImg.png" alt="img"/>
                <div className="imgPreviewBox_icon">
                  <Link href="#" className="btn btn-primary">
                      <em className="icon ni ni-cross"></em>
                  </Link>
                </div>
              </div>
            }
            {
              textCount && <small class="text-end d-block">{textCount}</small>
            }
            {formnote && <p className='form-note'><b>{formnote}</b></p> }
            
        </Col>
      )}
    </>
  );
}

export default FormElementWrapper;
