import React, {useState } from "react";
import ImageElement from "../../../components/UIElements/ImageElement";
import { SearchOutlined } from '@ant-design/icons';
import { Input, Upload } from "antd";
import { AntTextArea, CheckBox, CommonButton, FormElementWrapper, FormWrapper, ModalComponent, TextInput } from "../../../components";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import {  PlusOutlined } from '@ant-design/icons';


function ViewChat(props) {
    const [uploadItems, setUploadItems] = useState(false);
    const [chatProfile, setChatProfile] = useState(false)
    const uploadItemsHandleClick = () => setUploadItems(!uploadItems);
    const [showChat, setShowChat] = useState(false);

    const showChatHandle = (e) => {
        e.preventDefault();
        // console.log("triggered outter", window.screen)
        if(window.screen?.width<860){
            console.log("triggered")
            setShowChat(!showChat);
        }
    };
    
    const [chatWithSPModal, setChatWithSPModal] = useState(false);
    const chatWithSPModalHide = () =>{
      setChatWithSPModal(false)
    }
    const chatWithSPModalShow = () =>{
      setChatWithSPModal(true)
    };

    const [createGroupModal, setCreateGroupModal] = useState(false);
    const createGroupModalHide = () =>{
      setCreateGroupModal(false)
    }
    const createGroupModalShow = () =>{
      setCreateGroupModal(true)
    };

    const [addMemberInGroup, setAddMemberInGroup] = useState(false);
    const addMemberInGroupHide = () =>{
      setAddMemberInGroup(false)
    }
    const addMemberInGroupShow = () =>{
      setAddMemberInGroup(true)
    };
    const chatProfileHide = () => {
        setChatProfile(false)
    } 
  return (
    <>
      <div className="nk-chat">
        <div className={`nk-chat-aside position-relative ${ showChat ? 'hide-aside' : ''}`}>
            <div className="nk-chat-aside-head">
                <div className="nk-chat-aside-search px-0 w-100 mb-1">
                    <Input placeholder="Search by name" size="large" prefix={<SearchOutlined />} />
                </div>
            </div>
            <div className="nk-chat-aside-body d-flex flex-column">
                <div className="nk-chat-list">
                    <h6 className="title overline-title-alt">Messages</h6>
                    <ul className="chat-list">
                        <li className="chat-item current" onClick={ showChatHandle }>
                            <div className="chat-link">
                                <div className="chat-media user-avatar bg-purple">
                                    <ImageElement source="a-sm.jpg" alt="img"/>
                                    <span className="status status_img"><ImageElement source="struggle.png" alt="img"/></span>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Smith Provider</div>
                                        <span className="time">16 hours ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>hey smith</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="chat-item is-unread" onClick={ showChatHandle }>
                            <div className="chat-link">
                                <div className="chat-media user-avatar">
                                    <span>AB</span>
                                    <span className="status status_img"><ImageElement source="medical.png" alt="img"/></span>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Abu Bin Ishtiyak</div>
                                        <span className="time">10 hours ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>Hi, I am Ishtiyak, can you help me with this problem</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="chat-item" onClick={ showChatHandle }>
                            <div className="chat-link">
                                <div className="chat-media user-avatar">
                                    <ImageElement source="b-sm.jpg" alt="img"/>
                                    <span className="status status_img"><ImageElement source="positive.png" alt="img"/></span>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Ron Test</div>
                                        <span className="time">2 days ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>Hi, I am Ron Test</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="chat-item" onClick={ showChatHandle }>
                            <div className="chat-link">
                                <div className="chat-media user-avatar">
                                    <span>CI</span>
                                    <span className="status status_img"><ImageElement source="help.png" alt="img"/></span>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Chris ios</div>
                                        <span className="time">4 days ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>Hi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="chat-item is-unread" onClick={ showChatHandle }>
                            <div className="chat-link">
                                <div className="chat-media user-avatar">
                                    <ImageElement source="c-sm.jpg" alt="img"/>
                                    <span className="status status_img"><ImageElement source="positive.png" alt="img"/></span>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Customer Support</div>
                                        <span className="time">10 days ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>Hi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="chat-item is-unread" onClick={ showChatHandle }>
                            <div className="chat-link chat-open">
                                <div className="chat-media user-avatar">
                                    <div className="user-avatar">
                                        <ImageElement source="d-sm.jpg" alt="img"/>
                                        <span className="status status_img"><ImageElement source="vacation.png" alt="img"/></span>
                                    </div>
                                </div>
                                <div className="chat-info">
                                    <div className="chat-from">
                                        <div className="name">Customer Support</div>
                                        <span className="time">10 days ago</span>
                                    </div>
                                    <div className="chat-context">
                                        <div className="text">
                                            <p>Hi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="nk-chat-aside-panel nk-chat-fav mt-auto position-absolute bottom-0 end-0">
                    <ul className="d-flex justify-content-end">
                        <li>
                            {/* <CommonButton className="btn btn-lg btn-icon btn-primary btn-round" onClick= {chatWithSPModalShow}>
                                <em className="icon ni ni-plus"></em>
                            </CommonButton> */}
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="dropup btn btn-lg btn-icon btn-primary btn-round">
                                    <em className="icon ni ni-plus"></em>
                                </Dropdown.Toggle>

                                <Dropdown.Menu drop='up'>
                                    <Dropdown.Item href="#" onClick= {chatWithSPModalShow}>Chat With SP</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={ createGroupModalShow }>Create Group</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="noChatData noChatData-sm d-flex flex-column align-items-center justify-content-center w-100 h-100 d-none">
                <ImageElement source="chat-img.svg" alt="chat"/>
                <h6>No Chat Data Load</h6>
                <p>Please select the chat for start conversation</p>
            </div>
        </div>
        <div className={`nk-chat-body ${ showChat ? 'show-chat' : ''} ${chatProfile ? 'profile-shown': ''}`}>
            <div className="nk-chat-head">
                <ul className="nk-chat-head-info">
                    <li className="nk-chat-body-close">
                        <Link to="#" onClick={ showChatHandle } className="btn btn-icon btn-trigger nk-chat-hide ms-n1"><em className="icon ni ni-arrow-left"></em></Link>
                    </li>
                    <li className="nk-chat-head-user">
                        <div className="user-card">
                            <div className="user-avatar">
                                <span>IH</span>
                            </div>
                            <div className="user-info">
                                <div className="lead-text">Iliash Hossain</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="nk-chat-head-tools">
                    <li>
                        <Link to="#" className="btn btn-icon btn-trigger text-primary" onClick={ () => setChatProfile( !chatProfile ) }><em className="icon ni ni-info-fill"></em></Link>
                    </li>
                </ul>
            </div>
            <div className="nk-chat-panel">
                <div className="chat is-you">
                    <div className="chat-avatar">
                        <div className="user-avatar">
                            <span>IH</span>
                        </div>
                    </div>
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> Hello! </div>
                            </div>
                            <div className="chat-bubble">
                                <div className="chat-msg"> I found an issues when try to purchase the product. </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>Iliash Hossain</li>
                            <li>29 Apr, 2020 4:28 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat is-me">
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> Thanks for inform. We just solved the issues. Please check now. </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>Abu Bin Ishtiyak</li>
                            <li>29 Apr, 2020 4:35 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat is-you">
                    <div className="chat-avatar">
                        <div className="user-avatar">
                            <span>IH</span>
                        </div>
                    </div>
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> This is really cool. </div>
                            </div>
                            <div className="chat-bubble">
                                <div className="chat-msg"> It’s perfect. Thanks for letting me know. </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>Iliash Hossain</li>
                            <li>29 Apr, 2020 4:40 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat-sap">
                    <div className="chat-sap-meta"><span>30 Apr, 2020</span></div>
                </div>
                <div className="chat is-you">
                    <div className="chat-avatar">
                        <div className="user-avatar">
                            <span>IH</span>
                        </div>
                    </div>
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> Hey, I am facing problem as i can not login into application. Can you help me to reset my password? </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>3:49 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat is-me">
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> Definately. We are happy to help you. </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>3:55 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat is-you">
                    <div className="chat-avatar">
                        <div className="user-avatar">
                            <span>IH</span>
                        </div>
                    </div>
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> Thank you! Let me know when it done. </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li>3:58 PM</li>
                        </ul>
                    </div>
                </div>
                <div className="chat is-me">
                    <div className="chat-content">
                        <div className="chat-bubbles">
                            <div className="chat-bubble">
                                <div className="chat-msg"> We just reset your account. Please check your email for verification. </div>
                            </div>
                            <div className="chat-bubble">
                                <div className="chat-msg"> Please confirm if your got email </div>
                            </div>
                        </div>
                        <ul className="chat-meta">
                            <li><span>Now</span> <em className="icon ni ni-check-circle-fill"></em></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="nk-chat-editor">
                <div className="nk-chat-editor-upload  ms-n1">
                    <Link to="#" className={`btn btn-sm btn-icon btn-trigger text-primary toggle-opt ${ uploadItems ? 'active' : ''}`} onClick={uploadItemsHandleClick}><em className="icon ni ni-plus-circle-fill"></em></Link>
                    {
                        uploadItems &&
                        <div className={`chat-upload-option ${ uploadItems ? 'expanded' : ''}`}>
                            <ul className="">
                                <li>
                                    <Upload>
                                        <Link to="#"><em className="icon ni ni-img-fill"></em></Link>
                                    </Upload>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className="nk-chat-editor-form">
                    <FormWrapper>
                        <div className="form-control-wrap">
                            <FormElementWrapper
                                element={(formik) => (
                                <AntTextArea
                                    name="description"
                                    className="form-control form-control-simple no-resize"
                                    placeholder="Type your message..."
                                />
                                )}
                            />
                        </div>
                    </FormWrapper>
                </div>
                <ul className="nk-chat-editor-tools g-2">
                    <li>
                        <CommonButton className="btn btn-round btn-primary btn-icon"><em className="icon ni ni-send-alt"></em></CommonButton>
                    </li>
                </ul>
            </div>
            {
                chatProfile && 
                <div className={`nk-chat-profile ${chatProfile ? 'visible': ''}`}>
                    <div className="user-card user-card-s2 my-4">
                        <div className="user-avatar md bg-purple">
                            <ImageElement source="a-sm.jpg" alt="img"/>
                        </div>
                        <div className="user-info">
                            <h5>BMW group</h5>
                            <span className="sub-text">Active 35m ago</span>
                        </div>
                    </div>
                    <div className="chat-profile">
                        <div className="chat-profile-group p-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="title overline-title">ALL MEMBERS (3)</h6>
                                <Link to="#" onClick={ addMemberInGroupShow } className="text-primary">ADD MEMBER</Link>
                            </div>
                            <ul className="chat-list mt-3">
                            <li className="chat-item">
                                <div className="chat-link">
                                    <div className="chat-media user-avatar bg-purple">
                                        <ImageElement source="a-sm.jpg" alt="img"/>
                                    </div>
                                    <div className="chat-info d-flex justify-content-between">
                                        <div className="chat-from">
                                            <div className="name">Smith Provider</div>
                                        </div>
                                        <Link to="#" className="text-danger">Remove</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="chat-item is-unread">
                                <div className="chat-link">
                                    <div className="chat-media user-avatar">
                                        <span>AB</span>
                                    </div>
                                    <div className="chat-info  d-flex justify-content-between">
                                        <div className="chat-from">
                                            <div className="name">Abu Bin Ishtiyak</div>
                                        </div>
                                        <Link to="#" className="text-danger">Remove</Link>
                                    </div>
                                </div>
                            </li>
                            <li className="chat-item">
                                <div className="chat-link">
                                    <div className="chat-media user-avatar">
                                        <ImageElement source="b-sm.jpg" alt="img"/>
                                    </div>
                                    <div className="chat-info  d-flex justify-content-between">
                                        <div className="chat-from">
                                            <div className="name">Ron Test</div>
                                        </div>
                                        <Link to="#" className="text-danger">Remove</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            }
            <div className={`${chatProfile ? 'nk-chat-profile-overlay': ''}`} onClick={ () => chatProfileHide() }></div>
            <div className="noChatData d-flex flex-column align-items-center justify-content-center w-100 h-100 d-none">
                <ImageElement source="chat-img.svg" alt="chat"/>
                <h4>No Chat Data Load</h4>
                <p>Please select the chat for start conversation</p>
            </div>
        </div>
    </div>
    <ModalComponent
        backdrop
        show={chatWithSPModal}
        onHandleCancel={chatWithSPModalHide}
        title="Select Service Provider"
      >
      <>
        <div className="selectServiceProviderModal">
            <div className="nk-chat-aside-search p-0 mb-3  d-flex justify-content-between">
                <Input className="me-2" placeholder="Search by name" size="large" prefix={<SearchOutlined />} />
                <Dropdown as="li" className="user-dropdown">
                  <Dropdown.Toggle as="a" className="me-n1" id="dropdown-basic">
                    <div className="user-toggle">
                      <div className="user-avatar d-inline-flex">
                        <em className="ni ni-filter" />
                            {/* <ImageElement
                                source="help.png"
                                className="img-fluid medicalStatus_img medicalStatus_img--sm position-absolute top-0 end-0 translate-middle-y"
                                alt="help"
                            /> */}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                      <ul className="link-list p-0">
                      <li className="dropdown-inner px-2">
                          <Link to="#">
                            <span>All</span>
                          </Link>
                        </li>
                        <li className="dropdown-inner px-2">
                          <Link to="#">
                            <ImageElement
                                source="positive.png"
                                className="img-fluid medicalStatus_img position-relative"
                                alt="positive"
                            />
                            <span className="ms-1">Positive</span>
                          </Link>
                        </li>
                        <li className="dropdown-inner px-2">
                          <Link to="#">
                            <ImageElement
                                source="help.png"
                                className="img-fluid medicalStatus_img position-relative"
                                alt="help"
                            />
                            <span className="ms-1">Help</span>
                          </Link>
                        </li>
                        <li className="dropdown-inner px-2">
                          <Link to="#">
                            <ImageElement
                                source="struggle.png"
                                className="img-fluid medicalStatus_img position-relative"
                                alt="struggle"
                            />
                            <span className="ms-1">Struggling</span>
                          </Link>
                        </li>
                        <li className="dropdown-inner px-2">
                          <Link to="#">
                            <ImageElement
                                source="medical.png"
                                className="img-fluid medicalStatus_img position-relative"
                                alt="medical"
                            />
                            <span className="ms-1">Medical</span>
                          </Link>
                        </li>
                        <li className="dropdown-inner px-2">
                          <Link to="#">
                            <ImageElement
                                source="vacation.png"
                                className="img-fluid medicalStatus_img position-relative"
                                alt="vacation"
                            />
                            <span className="ms-1">Vacation</span>
                          </Link>
                        </li>
                      </ul>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
            <ul className="chat-list">
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="a-sm.jpg" alt="img"/>
                                <span className="status dot dot-lg dot-available"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Smith Provider</div>
                                <p className="text-gray"><span>Struggle</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <span>AB</span>
                                <span className="status dot dot-lg dot-offline"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Abu Bin Ishtiyak</div>
                                <p className="text-gray"><span>Positive</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="b-sm.jpg" alt="img"/>
                                <span className="status dot dot-lg dot-enroute"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Ron Test</div>
                                <p className="text-gray"><span>Struggle</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar">
                                <span>CS</span>
                                <span className="status dot dot-lg dot-journey-started"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Customer Support</div>
                                <p className="text-gray"><span>Medical</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="d-sm.jpg" alt="img"/>
                                <span className="status dot dot-lg dot-journey-started"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">James Watt</div>
                                <p className="text-gray"><span>Medical</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar">
                                <span>SS</span>
                                <span className="status dot dot-lg dot-offline"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Sam sam</div>
                                <p className="text-gray"><span>Positive</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="a-sm.jpg" alt="img"/>
                                <span className="status dot dot-lg dot-offline"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">HARSHAL ASHTEKAR</div>
                                <p className="text-gray"><span>Positive</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                                <span className="status dot dot-lg dot-offline"></span>
                            </div>
                            <div className="chat-from ms-2 d-block">
                                <div className="name">Neha M</div>
                                <p className="text-gray"><span>Positive</span></p>
                            </div>
                        </div>
                        <CommonButton className="btn btn-primary mb-1 mb-sm-0">Send Message</CommonButton>
                    </div>
                </li>
            </ul>
            <div className="text-center mt-1">
                <CommonButton className="btn btn-primary">Load More</CommonButton>                     
            </div>
        </div>
      </>
    </ModalComponent>

    <ModalComponent
        backdrop
        show={createGroupModal}
        onHandleCancel={createGroupModalHide}
        title="Create Group"
      >
      <>
        <div className="selectServiceProviderModal">
            <div className="nk-chat-aside-search p-0 mb-3">
                <FormWrapper row={true} extraClassName="g-3 ">
                    <FormElementWrapper
                        md={12}
                        element={(formik) => (
                            <>
                                <div className="profileImageUpload position-relative text-center">
                                    <div className="img-box rounded-circle overflow-hidden profileImageUpload_img mx-auto">
                                        <ImageElement source="a-sm.jpg" alt="img"/>
                                    </div>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader profileImageUpload_upload"
                                        showUploadList={false}
                                        >
                                        <PlusOutlined/>
                                    </Upload>
                                </div>
                            </>
                        )}
                    />
                    <FormElementWrapper
                        md={12}
                        label="Group Name"
                        element={(formik) => (
                        <TextInput
                            name="GroupName"
                            placeholder="Enter group name"
                            formik={formik}
                            className="bg-white"
                        />
                        )}
                    />
                </FormWrapper>
                {/* <ul className="list-unstyled d-flex flex-wrap mb-0 mt-2">
                    <li><span className="badge bg-outline-secondary me-1 mb-1">John Provider</span></li>
                    <li><span className="badge bg-outline-secondary me-1 mb-1">James Watt</span></li>
                    <li><span className="badge bg-outline-secondary me-1 mb-1">Sam sam</span></li>
                    <li><span className="badge bg-outline-secondary me-1 mb-1">jDavid Wrong</span></li>
                    <li><span className="badge bg-outline-secondary me-1 mb-1">Neha M</span></li>
                </ul> */}
                <p className="mb-1 mt-3">Add Member In Group</p>
                <Input placeholder="Search by name" size="large" prefix={<SearchOutlined />} />
            </div>
            <ul className="chat-list">
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0 flex-shrink-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="a-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Smith Provider</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <span>AB</span>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Abu Bin Ishtiyak</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="b-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Ron Test</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar">
                                <span>CS</span>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Customer Support</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
            </ul>
            <div className="text-center mt-1 pb-2">
                <CommonButton className="btn btn-primary">Load More</CommonButton>                     
            </div>
            <div className="border-top text-end pt-2">
                <CommonButton className="btn btn-primary me-2">Create Group</CommonButton>
                <CommonButton className="btn btn-secondary" onClick = { createGroupModalHide }>Close</CommonButton>
            </div>
        </div>
      </>
    </ModalComponent>

    <ModalComponent
        backdrop
        show={addMemberInGroup}
        onHandleCancel={addMemberInGroupHide}
        title="Add Member In Group"
      >
      <>
        <div className="selectServiceProviderModal">
            <div className="nk-chat-aside-search p-0 mb-3">
                <Input placeholder="Search by name" size="large" prefix={<SearchOutlined />} />
            </div>
            <ul className="chat-list">
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0 flex-shrink-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="a-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Smith Provider</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <span>AB</span>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Abu Bin Ishtiyak</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="b-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Ron Test</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar">
                                <span>CS</span>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Customer Support</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Chris ios</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="a-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Sam sam</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="b-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">jDavid Wrong</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="c-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Neha M</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
                <li className="chat-item mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center pe-1 pe-sm-2 mb-1 mb-sm-0">
                            <div className="chat-media user-avatar bg-purple">
                                <ImageElement source="d-sm.jpg" alt="img"/>
                            </div>
                            <div className="chat-from ms-2">
                                <div className="name">Joey Gullion</div>
                            </div>
                        </div>
                        <div className="text-end">
                            <CheckBox />
                        </div>
                    </div>
                </li>
            </ul>
            <div className="text-center mt-1 pb-2">
                <CommonButton className="btn btn-primary">Load More</CommonButton>                     
            </div>
            <div className="border-top text-end pt-2">
                <CommonButton className="btn btn-primary me-2">Add</CommonButton>
                <CommonButton className="btn btn-secondary" onClick = { addMemberInGroupHide }>Close</CommonButton>
            </div>
        </div>
      </>
    </ModalComponent>
    </>
  );
}

export default ViewChat;
