export const NCAPaymentReportServices = () => {
    return [
      {
        id: 1,
        jobId: "123456",
        typeofpayment: "Cash",
        amountCollected: "100",
        transactionFee: "1.00",
        totalPayAmount: "50",
        paymentStatus: "Transfer",
        paymentSettleStatus: 'Unsettled',
        selectAll:'N/A',
        paymentTransferStatus: "Transfered",
      },
    ];
  };
  