import {   SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const getStatusData = () => {
    return [
      {
        name: 'Yes',
        value: 'Yes',
      },
      {
        name: 'No',
        value: 'No',
      },
    ];
  };

export const googleServiceListTableColumn = ({Action}) => {
    return [
        {
            dataIndex: 'id',
            title: 'ID',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'business',
            title: 'Business',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'averageWeeklyBudget',
            title: 'Average Weekly Budget',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'totalReview',
            title: 'Total Review',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'averageFiveStarRating',
            title: 'Average Five Star Rating',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'currentPeriodChargedLeads',
            title: 'Current Period Charged Leads ',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'currentPeriodConnectedPhoneCalls',
            title: 'Current Period Connected Phone Calls ',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'currentPeriodPhoneCalls',
            title: 'Current Period Phone Calls ',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'currentPeriodTotalCost',
            title: 'Current Period Total Cost ',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'impressionsLastTwoDays',
            title: 'Impressions Last Two Days',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'phoneLeadResponsiveness',
            title: 'Phone Lead Responsiveness',
            headerClasses: 'sorting',
            sorter: true,
        },

        {
            dataIndex: 'previousPeriodChargedLeads',
            title: 'Previous Period Charged Leads',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'previousPeriodConnectedPhoneCalls',
            title: 'Previous Period Connected Phone Calls ',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'previousPeriodPhoneCalls',
            title: 'Previous Period Phone Calls',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'previousPeriodTotalCost',
            title: 'Previous Period Total Cost',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'new',
            title: 'New',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'active',
            title: 'Active',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'booked',
            title: 'Booked',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'completed',
            title: 'Completed',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'archive',
            title: 'Archive',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'biddingMode',
            title: 'Bidding Mode',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'currentBalance',
            title: 'Current Balance',
            headerClasses: 'sorting',
            sorter: true,
        },
        {
            dataIndex: 'status',
            title: 'Status',
            headerClasses: 'sorting',
            sorter: true,
            render:()=><Toggle isDropDown/>,
            ...getColumnSelectSearchProps("status", getStatusData())
        },
        {
            dataIndex: 'spInControl',
            title: 'SP in Control',
            headerClasses: 'sorting',
            sorter: true,
            render:()=><Toggle/>,
            ...getColumnSelectSearchProps("SP in control", getStatusData())
        },
        {
            dataIndex: 'action',
            title: 'Action',
            headerClasses: 'sorting',
            sorter: true,
            render: () => <Action />,
        },
    ]
};
