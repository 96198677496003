import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  ListingHeader,
  PageHeader,
  Toggle,
} from '../../../../../components';

function EditProviderService(props) {
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/provider',
      name: 'Providers',
    },
    {
      path: '#',
      name: 'Edit Service',
    },
  ];
  const backPage = (e) => {
    e.preventDefault();
    navigate('/admin/provider');
  };
  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Service">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton']}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="card card-preview">
          <div className="card-inner">
            <div className="nk-block">
              <div className="nk-data data-list">
                <div className="data-item px-0 py-2 pt-0">
                  <div className="data-col">
                    <span className="data-label w-50">Service Restriction</span>
                    <span className="data-value w-50">
                      <Toggle />
                    </span>
                  </div>
                </div>
              </div>
              <div className="serviceList">
                <h6 className="mb-3">
                  Service Type <span className="text-danger"> *</span>
                </h6>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">HVAC</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Schedule A Repair Service Visit ($79.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Cooling System Tune-up ($99.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Indoor Air Quality Quote (Humidifier, Air Cleaner, Air
                        Purifier. Etc.) (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Schedule A Replacement Quote (Heating and Cooling
                        Equipment) (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Same Day Emergency Service1 ($99.95 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Heating System Tune-Up ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Have A Technician Contact You Today (free - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Buying or Selling Home? System Inspection ($129.00 -
                        Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Install Store Bought Thermostat ($129.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Office Booking Appointment ($99.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Time Blocker (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Install Blocker (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Lowes Quote (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Sam's Club Quote (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        FL - Heating System Tuneup ($128.98 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Ductwork or Ductless Mini Split Quote (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Same Day Free Emergency - Sales Quote Yes (free -
                        Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        QA Service For Testing Purpose ($101.15 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        FREE TIMER YES QOUTE (free - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Same day free emergency quote (free - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Free Schedule (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Return Visit ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Recheck 20 ($20.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Electrician</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Quote (Electrical Panel, Whole House Generator, etc.)
                        (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Plumbing</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Water Heater Maintenance ($99.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Handyman</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair ($89.00 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Quote (General residential request) (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Free Delivery</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Free Delivery ($5.00 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Satellite</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair ($25.00 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Satellite sub ($123.00 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">CALL BOOKING</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Same-Day Emergency Service ($99.95 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Schedule A Next Day Repair Service ($79.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Maintenance Appointment for Heating System ($99.95 -
                        Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Maintenance Appointment for Cooling System ($99.95 -
                        Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Have A Technician Contact You Today ($0.00 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        System Replacement Quote (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">SUB ($100.00 - Scheduled)</span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Home Warranty of America</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        HWA Service Appointment ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        HWA Maintenance Appointment ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">American Home Shield</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        AHS Maintenance Appointment ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        AHS Service repair ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">HSA Home Warranty</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        HSA Home Warranty Maintenance Appointment ($0.00 -
                        Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        HSA Home Warranty Service ($0.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Property Mgmt. Partners</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair ($79.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        North American Contractors - HVAC Heating Tune-Up
                        ($99.95 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        North American Contractors - HVAC Heating Repair ($79.95
                        - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Same Day Emergency Service ($99.95 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">QA & CATEGORY</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair (free - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Subcategory Quote yes Sch (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Subcategory Q Y SCH (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Free Sch no quote (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Paid SCH ($10.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Free sch quote Y (free - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Category</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair ($100.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Roofing</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Repair ($100.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Roofing Paid Same Day ($149.97 - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        TEST ROOF ($125.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        FREE ROOF (free - Emeregency)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">HVAC-demo</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                      Service Repair ($10.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">T1 category</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                      Service Repair ($25.00 - Scheduled)
                      </span>
                      <span className="data-value">
                        <Toggle />
                      </span>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="text-end">
                <Link href="#" className="btn btn-primary">
                  Edit
                </Link>
                <Link href="#" className="btn btn-light ms-2">
                  Reset
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProviderService;
