import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  duration,
  roles,
  months,
  years,
} from '../../../../../services/Register';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  Select,
  CommonButton,
} from '../../../../index';
import { initValuesStepTwo, validationStepTwo } from './validation';

function CompanyProfileTwo({ onSubmit }) {
  const durationData = duration();
  const rolesData = roles();
  const monthsData = months();
  const yearData = years();

  const [cardInfo, setCardInfo] = useState(true);

  return (
    <FormWrapper
      onSubmit={onSubmit}
      validation={() => validationStepTwo(cardInfo)}
      initialValues={initValuesStepTwo()}
      row={true}
      extraClassName="g-3"
    >
      <FormElementWrapper
        md={6}
        label="Which role would you like to use?"
        mandatory="true"
        element={(formik) => (
          <Select
            name="role"
            placeholder="Select role"
            options={rolesData}
            showSearch
            formik={formik}
            size= "large"
          />
        )}
      />
      <FormElementWrapper
        md={6}
        label="Would you like to pay monthly or yearly? "
        mandatory="true"
        element={(formik) => (
          <Select
            name="duration"
            placeholder="Select duration"
            options={durationData}
            showSearch
            formik={formik}
            size= "large"
          />
        )}
      />
      <FormElementWrapper
        md={12}
        label="Permission for this role"
        element={(formik) => (
            <ul className="custom-control-group">
                <li>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        name="btnCheck"
                        id="btnCheck1"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="btnCheck1"
                    >
                        Dashboard
                    </label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        name="btnCheck"
                        id="btnCheck2"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="btnCheck2"
                    >
                        Provider
                    </label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        name="btnCheck"
                        id="btnCheck3"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="btnCheck3"
                    >
                        Services
                    </label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        name="btnCheck"
                        id="btnCheck4"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="btnCheck4"
                    >
                        customers
                    </label>
                    </div>
                </li>
                <li>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        name="btnCheck"
                        id="btnCheck5"
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="btnCheck5"
                    >
                        Manual Bookings
                    </label>
                    </div>
                </li>
            </ul>
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={12}
        element={() => (
          <>
            {' '}
            <hr /> <h5 className="nk-block-title">Credit Card Info</h5>
            <p>Save card on file for recurring payment. No charges will be completed until your account is live.</p>
          </>
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={6}
        label="Card Holder"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="cardHolder"
            placeholder="Enter card holder"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={6}
        label="Card Number"
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="cardNumber"
            placeholder="Enter card number"
            inputType="number"
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={6}
        label="Exp. Month"
        mandatory="true"
        element={(formik) => (
          <Select
            name="expMonth"
            placeholder="Select month"
            options={monthsData}
            showSearch
            formik={formik}
            size= 'large'
          />
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={6}
        label="Exp. Year"
        mandatory="true"
        element={(formik) => (
          <Select
            name="expYear"
            placeholder="Select year"
            options={yearData}
            showSearch
            formik={formik}
            size="large"
          />
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={12}
        label="CVV "
        mandatory="true"
        element={(formik) => (
          <TextInput
            name="cvv"
            inputType="number"
            placeholder="Enter CVV "
            formik={formik}
            extraClassName="form-control-lg"
          />
        )}
      />
      <FormElementWrapper
        visible={cardInfo}
        md={12}
        element={() => (
          <div className='text-center'>
            <Link to="#" type="button" onClick={() => setCardInfo(false)}>
            Skip & Complete Later
          </Link>
          </div>
        )}
      />
      <FormElementWrapper
        md={12}
        className="text-center"
        element={() => (
          <Link to="/nca/register-business-type"
            type="submit"
            className="btn btn-lg btn-primary"
          >
            Save & Next
          </Link>
        )}
      />
    </FormWrapper>
  );
}

export default CompanyProfileTwo;
