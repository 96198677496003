import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  CommonButton,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  TablePlus,
} from '../../../../../components';

function JobProviderDiscountAmountAdd(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/payment-report',
      name: 'Payment Report',
    },
    {
      path: '#',
      name: 'Provider Discount Details',
    },
  ];
  const columns = [
    {
      dataIndex: 'id',
      title: 'Id',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
      sorter: true,
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
      sorter: true,
    },
    {
      dataIndex: 'discount',
      title: 'Discount',
      sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>{cell} <br/> 
          <FormWrapper row={true} extraClassName="g-3">
            <FormElementWrapper
                md={12}
                element={(formik) => (
                <TextInput
                  name="discount"
                  placeholder="Enter discount"
                  formik={formik}
                  type="number"
                />
              )}
            />
          </FormWrapper>
        </>
      }
    },
    {
      dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
      sorter: true,
    },
  ];
  const adminData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "Ios Pro",
      serviceType: "QA & CATEGORY - Free Sch no quote",
      servicePrice: "$0.00	",
      discount: "$",
      paymentTransferStatus: "Pending",
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Provider Discount Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/payment-report')}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
        isSearch={false}
        showAction={false}

      />
      <div className='card'>
        <div className='card-inner pt-0'>
          <div className="text-end">
            <CommonButton className="btn btn-primary">Submit </CommonButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobProviderDiscountAmountAdd;
