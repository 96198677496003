import { Outlet } from 'react-router-dom';
import {  BookingView, ListServiceManagement, ServiceInvoice, ViewQuoteDetails } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.LIST_SERVICE.path,
      private: true,
      name: 'Service Management',
      key: "service",
      belongsToSidebar: true,
      icon: accessRoute.LIST_SERVICE.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.LIST_SERVICE.path,
        name: 'Add Booking',
        key: accessRoute.LIST_SERVICE.key,
        belongsToSidebar: false,
        icon: accessRoute.LIST_SERVICE.icon,
        element: <ListServiceManagement />,
      },
      {
        private: true,
        path: accessRoute.BOOKING_VIEW.path,
        name: 'View Booking',
        key: accessRoute.BOOKING_VIEW.key,
        belongsToSidebar: false,
        icon: accessRoute.BOOKING_VIEW.icon,
        element: <BookingView />,
      },
      {
        private: true,
        path: accessRoute.SERVICE_INVOICE.path,
        name: 'Invoice',
        key: accessRoute.SERVICE_INVOICE.key,
        belongsToSidebar: false,
        icon: accessRoute.SERVICE_INVOICE.icon,
        element: <ServiceInvoice />,
      },
      {
        private: true,
        path: accessRoute.VIEW_QUOTE.path,
        name: 'View Quote',
        key: accessRoute.VIEW_QUOTE.key,
        belongsToSidebar: false,
        icon: accessRoute.VIEW_QUOTE.icon,
        element: <ViewQuoteDetails />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  LIST_SERVICE: {
    path: '/admin/service',
    key:"service.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-wallet-fill" />
      </span>
    ),
  },
  BOOKING_VIEW: {
    path: '/admin/service/booking',
    key:"service.list",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  SERVICE_INVOICE: {
    path: '/admin/service/invoice',
    key:"service.list",
  },
  VIEW_QUOTE: {
    path: '/admin/service/quote-details',
    key:"service.list",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};