import {Toggle, SelectFilter, InputFilter,  AmountFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const storeTableColumn = ({ Action }) => {
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
      {
        name: 'Deleted',
        value: 'Deleted',
      },
    ];
  };
    const logoFormatter = (path, text,url) => {
    return (
      <>
        {path ? (
          <div className="text-center">
            <Link to={url}><em className={path} style={{fontSize: '30px'}}/></Link>
          </div>
        ) : (
          text
        )}
      </>
    );
  };

  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
        dataIndex: "companyName",
        title: "Name or Company Name",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSearchProps("name or company name")
    },
    {
      dataIndex: "email",
      title: "Email",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email")
    },
    {
      dataIndex: "mobile",
      title: "Mobile",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("mobile")
    },
    {
        dataIndex: "totalAmount",
        title: "Total Amount",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectAmountProps(),
    },
    {
        dataIndex: "pendingAmount",
        title: "Pending Amount",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectAmountProps(),
    },
    {
        dataIndex: "transferAmount",
        title: "Transfer Amount",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: "viewEditDocuments",
      title: "View/Edit Documents",
      headerClasses: "sorting",
      sorter: true,
      render: (cell, row) => logoFormatter(row?.viewEditDocument,"Image", row?.documentURL),
    },
      {
        dataIndex: "status",
        title: "Status",
        headerClasses: "sorting",
        render:()=><Toggle isDropDown shouldDeleteInclude/>,
        ...getColumnSelectSearchProps("status", getStatusData())
      },
      {
        dataIndex: "action",
        title: "Action",
        className: "nk-tb-col-tools text-end",
        render: () => <Action />
      },
  ];
};
