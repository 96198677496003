import i18next from 'i18next';
import * as yup from 'yup';
import { Regex } from '../../../../helpers/index.helper';

export const initValues = () => {
  return {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
};

export const validation = () => {
  return yup.object().shape({
    currentPassword: yup.string().required('Password Required'),
    newPassword: yup.string().required('Password Required'),
    confirmPassword: yup.string().required('Password Required'),
  });
};
