
import React, { useState } from 'react';
import {
  Breadcrumb,
  DateFilter,
  InputFilter,
  ListingHeader,
  PageHeader,
  TablePlus,
  Tabs,
} from '../../../../components';
import { FilterFilled  } from "@ant-design/icons";

function Reviews(props) {
  const [defaultKey, setDefaultKey] = useState('providers');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Reviews',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  const getColumnSelectDateProps = (dataIndex) => ({
    filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      sorter: true,
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("job number"),
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name (Average Rate)',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("provider name"),
    },
    {
      dataIndex: 'userName',
      title: 'User Name',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("user name"),
    },
    {
      dataIndex: 'rate',
      title: 'Rate',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("rate"),
    },
    {
      dataIndex: 'date',
      title: 'Date',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSelectDateProps("date"),
    },
    {
      dataIndex: 'comment',
      title: 'Comment',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("comment"),
    },
  ];
  const adminData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "kam test ( 4 )",
      userName: "Shane Watson",
      rate: "4.5",
      date: "2nd November, 2022 07:30 AM",
      comment: "Good service",
    },
    {
      id: 2,
      jobNumber: "81178273",
      providerName: "	Ron Test ( 4.1 )",
      userName: "Chrissy Test",
      rate: "3.5",
      date: "2nd November, 2022 07:30 AM",
      comment: "Bad service",
    },
    {
      id: 3,
      jobNumber: "89462855",
      providerName: "Test KT ( 2.3 )",
      userName: "Shane Watson",
      rate: "4.9",
      date: "2nd November, 2022 07:30 AM",
      comment: "Excellent  service",
    },
  ];
  const Usercolumns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      sorter: true,
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("job number"),
    },
    {
      dataIndex: 'userName',
      title: '	User Name(Average Rate)',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("user name"),
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("provider name"),
    },
    {
      dataIndex: 'rate',
      title: 'Rate',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("rate"),
    },
    {
      dataIndex: 'date',
      title: 'Date',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSelectDateProps("date"),
    },
    {
      dataIndex: 'comment',
      title: 'Comment',
      sorter: true,
      headerClasses: 'sorting',
      ...getColumnSearchProps("comment"),
    },
  ];
  const UserData = [
    {
      id: 1,
      jobNumber: "75833147",
      userName: "Zia Wahab ( 4.8 )",
      providerName: "Shane Watson",
      rate: "4.5",
      date: "2nd November, 2022 07:30 AM",
      comment: "Good service",
    },
    {
      id: 2,
      jobNumber: "81178273",
      userName: "	Zoila Suarez ( 5 )",
      providerName: "Chrissy Test",
      rate: "3.5",
      date: "2nd November, 2022 07:30 AM",
      comment: "Bad service",
    },
    {
      id: 3,
      jobNumber: "89462855",
      userName: "Zachary Majors ( 5 )",
      providerName: "Shane Watson",
      rate: "4.9",
      date: "2nd November, 2022 07:30 AM",
      comment: "Excellent  service",
    },
  ];
  const tabContent = [
    {
      name: 'Providers',
      key: 'providers',
      content: (
        <>
           {/* <DataTable
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={adminData}
            tableColumns={columns}
            selectRow
            // param={param}
            // defaultSort={defaultSort}
            setSizePerPage=""
            // tableLoader={tableLoader}
            // tableReset={tableReset}
            // getSearchValue={getSearchValue}
            // searchPlaceholder={t("text.search.manageCustomers")}
          /> */}
          <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={adminData}
            tableColumns={columns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}
            showAction={false}
          />
        </>
      ),
    },
    {
      name: 'Users',
      key: 'users',
      content: (
        <>
          <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={UserData}
            tableColumns={Usercolumns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}
            showAction={false}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Reviews">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['csvExport']}
          />
        </div>
      </div>
      <Tabs
        tabContent={tabContent}
        tabsFor="table"
        activeKey={defaultKey}
        setActiveKey={setDefaultKey}
        // onTabChange={onTabChange}
      />
      <div className="card mt-3">
        <div className="card-inner">
          <div className="bq-note">
            <div className="bq-note-text">
              <div className="nk-block-head nk-block-head-sm nk-block-between">
                <h5 className="title">Notes:</h5>
              </div>
              <ul className="list list-sm list-checked">
                <li>Review module will list all reviews on this page.</li>
                <li>Administrator can Delete any review.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reviews;

