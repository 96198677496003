import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  ListingHeader,
  PageHeader,
  Tabs,
  TextInput,
} from '../../../../components';
import Select from '../../../../components/UIElements/Select';

function EditAdminProfile(props) {
  const navigate = useNavigate();
  const [defaultKey, setDefaultKey] = useState('profile');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/super-admin',
      name: 'Admins',
    },
    {
      path: '#',
      name: 'Edit SubAdmin Ravi',
    },
  ];

  const backPage = () => {
    navigate('/admin/super-admin');
  };
  const adminType = [
    {
      name: 'subadmin',
      value: 'Sub Admin',
    },
    {
      name: 'nca',
      value: 'NCA',
    },
  ];
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
  ];
  const roleData = [
    {
      name: 'admion',
      value: 'admin',
    },
  ];
  const tabContent = [
    {
      name: 'Profile',
      key: 'profile',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="Select Admin Type *"
                  element={(formik) => (
                    <Select
                      name="adminType"
                      placeholder="Select admin type"
                      options={adminType}
                      showSearch
                      formik={formik}
                      disabled={true}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="First Name *"
                  element={(formik) => (
                    <TextInput
                      name="firstName"
                      placeholder="Enter first name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Last Name *"
                  element={(formik) => (
                    <TextInput
                      name="lastName"
                      placeholder="Enter last name"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Email *"
                  element={(formik) => (
                    <TextInput
                      name="email"
                      placeholder="Enter email"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Password *"
                  element={(formik) => (
                    <TextInput
                      name="password"
                      placeholder="Enter password"
                      formik={formik}
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Select Location *"
                  element={(formik) => (
                    <Select
                      name="locationType"
                      placeholder="Select location"
                      options={locationData}
                      showSearch
                      formik={formik}
                    />
                  )}
                />                
                <FormElementWrapper
                  md={6}
                  label="Select Role  *"
                  element={(formik) => (
                    <Select
                      name="roleType"
                      placeholder="Select role"
                      options={roleData}
                      showSearch
                      formik={formik}
                    />
                  )}
                />

                <FormElementWrapper
                  md={12}
                  className="text-end"
                  element={() => (
                    <>
                      <CommonButton
                        type="submit"
                        className="btn btn-primary me-2"
                      >
                        Edit
                      </CommonButton>
                      <CommonButton
                        type="submit"
                        className="btn btn-light"
                      >
                        Reset
                      </CommonButton>
                    </>
                  )}
                />
              </FormWrapper>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Procedure Lessons Details',
      key: 'lessonsdetails',
      content: (
        <>
          <div className="card">
            <div className="card-inner">
              <div className="bq-note">
                <div className="bq-note-text">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Pending Required Procedure</h5>
                  </div>
                  <ul className="list list-sm list-checked">
                    <li>No data found.</li>
                  </ul>
                </div>
                <div className="bq-note-text mt-3">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Completed</h5>
                  </div>
                  <ul className="list list-sm list-checked">
                    <li>No data found.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="wide-md mx-auto">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Edit SubAdmin Ravi">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className="nk-block">
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          // onTabChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default EditAdminProfile;
