const email =
  /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!Gmail.com)(?!Yahoo.com)(?!Hotmail.com)([\w-]+\.)+[\w-]{2,4})?$/;

const password =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/;

const phoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const cardNumber =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

const cvv = /^[0-9]{3,4}$/;

const lastFourDigit = /^[0-9]{4}$/;

const minNineCharacter = /^(?=.*\d).{0,9}$/;

export const Regex = {
  email,
  password,
  phoneNumber,
  cardNumber,
  cvv,
  lastFourDigit,
  minNineCharacter,
};
