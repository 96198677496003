

import { Image } from "antd";
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  ListingHeader,
  PageHeader,
} from "../../../../components";

function NCADocuments(props) {
    const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/nca')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/nca",
      name: "NCA",
    },
    {
      path: "#",
      name: "Document of abdul",
    },
  ];
  return (
    <div className="">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Document of abdul">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-fmg-quick-list nk-block">
              <div className="nk-files nk-files-view-grid documentsBoxSec">
                <div className="nk-files-list">
                <div className="nk-file-item nk-file p-0">
                    <div className="nk-file-info">
                      <div className="nk-file-title">
                        <div className="nk-file-name my-3 mx-1">
                          <h6 className="mb-0">
                          Company Logo
                          </h6>
                          <p> <small>(For Company Logo)</small></p>
                        </div>
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type nk-file-icon-type-showImage p-0">
                            <Image
                                src="/assets/images/admin/uploadImg.png"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-file-item nk-file p-0">
                    <div className="nk-file-info">
                      <div className="nk-file-title">
                        <div className="nk-file-name my-3 mx-1">
                          <h6 className="mb-0">
                          Identity Document 
                          </h6>
                          <p><small>(For Business Type Identity Document )</small></p>
                        </div>
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type nk-file-icon-type-showImage p-0">
                          <Image
                                src="/assets/images/admin/uploadImg.png"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
  );
}

export default NCADocuments;