import { Select } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DatePicker, CommonButton, AntSelect, FormWrapper, FormElementWrapper } from '../../../index';
// import {DatePicker, CommonButton, AntSelect} from "../../../index";

function DashboardFilter() {
  const filterData = [
    {
      name: 'today',
      value: 'Today',
    },
    {
      name: 'yesterday',
      value: 'Yesterday',
    },
    {
      name: 'currentWeek',
      value: 'Current Week',
    },
    {
      name: 'previousWeek',
      value: 'Previous Week',
    },
    {
      name: 'currentMonth',
      value: 'Current Month',
    },
    {
      name: 'previousMonth',
      value: 'Previous Month',
    },
    {
      name: 'currentYear',
      value: 'Current Year',
    },
    {
      name: 'previousYear',
      value: 'Previous Year',
    },
  ]

  const locationData = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Anderson/Muncie, Indiana',
      value: 'Anderson/Muncie, Indiana',
    },
    {
      name: 'Arizona- Texas',
      value: 'Arizona- Texas',
    },
    {
      name: 'Atlantic',
      value: 'Atlantic',
    },
  ]

  return (
    <form>
      <div className="dropdown-body dropdown-body-rg">
        {/* <div className="row g-3">

          <div className="col-md-6">
            <div className="form-group ">
              <label className="overline-title overline-title-alt">
                Location
              </label>
              <AntSelect
                size="medium"
                id="status"
                extraClassName="form-control"
                name="statusId"
                disabled={false}
                variant="standard"
                placeholder="Select location"
                options={subscriptionData}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="overline-title overline-title-alt">
                Select by
              </label>
              <AntSelect
                size="medium"
                id="status"
                extraClassName="form-control"
                name="statusId"
                disabled={false}
                variant="standard"
                placeholder="Select"
                options={arrayOfData}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="overline-title overline-title-alt">
                Start Date
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  name="startDate"
                  className="form-control date-picker shadow-none"
                  placeholder="DD/MM/YY"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="overline-title overline-title-alt">
                End Date
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  name="endDate"
                  className="form-control date-picker shadow-none"
                  placeholder="DD/MM/YY"
                />
              </div>
            </div>
          </div>

        </div> */}
        <FormWrapper row={true} extraClassName="g-3">
         <FormElementWrapper
          md={6}
          label="Location"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="location"
              placeholder="Select location"
              options={locationData}
              showSearch
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="select By"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <Select
              name="stateBy"
              placeholder="Select"
              options={filterData}
              showSearch
              formik={formik}
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="Start Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="startDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
        <FormElementWrapper
          md={6}
          label="End Date"
          extraLabelClass="overline-title overline-title-alt"
          element={(formik) => (
            <DatePicker
              name="EndDate"
              className="form-control date-picker shadow-none"
              placeholder="DD/MM/YY"
            />
          )}
        />
      </FormWrapper>
      </div>
      <div className="dropdown-foot between">
        <Link
          to="#"
          type="button"
          className="clickable"
        >

          Reset
        </Link>

        <CommonButton
          extraClassName="btn btn-sm btn-primary"
          htmlType="submit"
        >
          Apply
        </CommonButton>
      </div>
    </form>
  );
}

export default withTranslation()(DashboardFilter);
