import { Image } from 'antd';
import React, {useState} from 'react';
import { Table } from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  CommonButton,
  ModalComponent,
  ImageElement,
} from '../../../components';

function ViewQuoteDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/service',
      name: 'Service Management',
    },
    {
      path: '#',
      name: 'View Quote',
    },
  ];
  const [discountModal, setDiscountModal] = useState(false);
  const DiscountModal = () => {
    setDiscountModal(true);
  };
  const [rebatesModal, setRebatesModal] = useState(false);
  const rebatesModalHide = () =>{
    setRebatesModal(false)
  }
  const rebatesModalShow = () =>{
    setRebatesModal(true)
  }
  const [productViewModal, setProductViewModal] = useState(false);
  const productViewModalHide = () =>{
    setProductViewModal(false)
  }
  const productViewModalShow = () =>{
    setProductViewModal(true)
  }
  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="View Quote">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/service')}
          />
        </div>
      </div>
      <div className="nk-block">
          <div className="card">
            <div className="card-inner">
                <div className='table-responsive'>
                    <table style={{"width":"100%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{"width":"100%"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                        <ImageElement
                                                            source="provider-img.png"
                                                            className="img-fluid logo-img"
                                                            alt="logo"
                                                            style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                        />
                                                        <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                            Provider
                                                        </span>
                                                        <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                            Jorge neon
                                                        </span>
                                                        <span>jorge@mailinator.com</span>
                                                    </td>
                                                        <td>
                                                        <ImageElement
                                                            source="user-img.png"
                                                            className="img-fluid logo-img"
                                                            alt="logo"
                                                            style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                        />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        User</span>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>Shane
                                                        Watson </span>
                                                    <span>shanewatson@mailinator.com</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{"width":"100%", "marginTop": "10px", "marginBottom": "10px"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>Booking
                                                        Address </span> : Vijay Nagar, Indore, Madhya
                                                    Pradesh 452010, India<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>
                                                        Job ID:</span>
                                                    75833147<br />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}} >Job
                                                        Date
                                                        Timing </span>: Your Job 06:35 PM on 15 Nov 2022
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{"width":"100%", "background": "#f1f3f9", "textAlign": "left", "padding": '10px'}}>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th style= {{ "color": "#364a63" }}>Service Details</th>
                                                <th style= {{ "color": "#364a63" }}>Service Name</th>
                                                <th style= {{ "color": "#364a63" }}>QTY</th>
                                                <th style= {{ "color": "#364a63", "textAlign": "right" }}>Service Charge</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>QA & CATEGORY</td>
                                                <td>Free Sch no quote</td>
                                                <td>1</td>
                                                <td className='text-end'>5000.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <div className='invoiceTableContent mt-2'>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr>
                                                <td width="80" style={{"margin": "0 0 7px", "verticalAlign": "top"}}>
                                                        <ImageElement
                                                        source="line-items.jpg"
                                                        className="img-fluid"
                                                        alt="line-items"
                                                        width="80"
                                                    />
                                                </td>
                                                <td width="850">
                                                    <table className='w-100'>
                                                        <tr>
                                                            <td width="850">
                                                                <span
                                                                    style={{"padding" :"0 0 5px" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>Apple - Apple MAC
                                                                    <span className='linkButton' onClick={ () => productViewModalShow() }>
                                                                        <em className="icon ni ni-info ms-1" />
                                                                    </span>
                                                                </span>
                                                                <p className='mb-1' style={{"color": "#364a63","fontWeight": "bold"}}>R410A PAC SPP</p>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                <p className='mb-0 mt-1'><b>Capacity:</b> iphone</p>
                                                                <p className='mb-0'><b>Size Description:</b> 12 Pro</p>
                                                                <p className='mb-0'><b>Directional:</b> New</p>
                                                                <p className='mb-0'><b>Minimum Blower Drive:</b> 2500 MAH</p>
                                                            </td>
                                                            <td style={{"textAlign":"right", "padding": "0 0 10px", "verticalAlign": "top"}}>$4248.09</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <span
                                                                    style={{"padding" :"15px 0 10px" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>Discounted Price
                                                                    <span className='linkButton' onClick={() => DiscountModal()}>
                                                                    <em className="icon ni ni-info ms-1" />
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td style={{"textAlign":"right", "padding": "0 0 10px"}}>
                                                                $2,228.09
                                                            </td>
                                                        </tr>
                                                        <tr> 
                                                            <td><span className='fw-bold'>Options</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>ipt</td>
                                                            <td style={{"textAlign":"right"}}>
                                                                $250.00
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Utility knife</td>
                                                            <td style={{"textAlign":"right"}}>
                                                                $10.00
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='pt-4' width="80" style={{"margin": "0 0 7px", "verticalAlign": "top"}}>
                                                <ImageElement
                                                        source="line-items.jpg"
                                                        className="img-fluid"
                                                        alt="line-items"
                                                        width="80"
                                                    />
                                                </td>
                                                <td className='pt-4' width="850">
                                                    <table className='w-100'>
                                                        <tr>
                                                            <td width="850">
                                                                <span
                                                                    style={{"padding" :"0 0 5px" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>Comfortmaker - High-Efficiency Gas Furnace & Air Conditioner
                                                                    <span className='linkButton' onClick={ () => productViewModalShow() }>
                                                                        <em className="icon ni ni-info ms-1" />
                                                                    </span>
                                                                </span>
                                                                <p className='mb-1' style={{"color": "#364a63","fontWeight": "bold"}}>G97CMN - 98 Variable-Speed Modulating Gas Furnace & CCA7 Two Stage / Up to 17 SEER</p>
                                                                Enjoy ultimate comfort even in the coldest weather with our highest efficiency gas furnace product. It features modulating gas heat performance that delivers incredible temperature control and quiet performance. The variable-speed blower also means you’ll enjoy better summer dehumidification and a possible SEER boost as your furnace works with your cooling unit to help pull moisture out of the air.
                                                                <p className='mb-0 mt-1'><b>Capacity:</b> 100000 BTU / 4 Ton Capacity</p>
                                                                <p className='mb-0'><b>Size Description:</b> 21"</p>
                                                                <p className='mb-0'><b>Directional:</b> Multi</p>
                                                                <p className='mb-0'><b>Minimum Blower Drive:</b> 5 Ton</p>
                                                            </td>
                                                            <td style={{"textAlign":"right", "padding": "0 0 10px", "verticalAlign": "top"}}>$5715.00</td>
                                                        </tr>
                                                        <tr> 
                                                            <td><span className='fw-bold'>	Material List</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>	1 Roll UL Silver Tape</td>
                                                            <td style={{"textAlign":"right"}}>
                                                            $0.00
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Condenser Pad</td>
                                                            <td style={{"textAlign":"right"}}>
                                                                $50.00
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Disconnect</td>
                                                            <td style={{"textAlign":"right"}}>
                                                                $30.00
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Electrical Whip</td>
                                                            <td style={{"textAlign":"right"}}>
                                                                $40.00
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className='mt-3' style={{"color": "#364a63"}}>Line items</h6>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th width="80" style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Image</th>
                                                <th width="850" style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Name</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Quantity</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Price</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9", "textAlign": "right"}}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="80" className='pt-3' style={{"margin": "0 0 7px", "verticalAlign": "top"}}>
                                                    <ImageElement
                                                        source="line-items.jpg"
                                                        className="img-fluid"
                                                        alt="line-items"
                                                        width="80"
                                                    />
                                                </td>
                                                <td className='pt-3' style={ {"paddingLeft" : "15px"} }>
                                                    <span
                                                        style={{"padding" :"0 0 10px" , "color": "#364a63", "fontWeight": "bold" , "display": "block"}}>Honeywell
                                                        T6 Th6320Wf2003 24V Lyric T6 Pro </span>
                                                    The T6 Pro Z-Wave Thermostat provides
                                                    time-based scheduling for 7-day, 5-1-1, and
                                                    5-2 day programmable scheduling, as
                                                    well as remote control temperature access
                                                    with impressive wireless range. Now you can
                                                    save on energy costs whether when
                                                    you're home or away. All That From A Few
                                                    Batteries
                                                </td>
                                                <td className='pt-3' style={{"paddingLeft" : "15px", "color": "#364a63","verticalAlign": "top"}}>1</td>
                                                <td className='pt-3' style={{"paddingLeft" : "15px", "verticalAlign": "top"}}>408.00</td>
                                                <td className='pt-3' style={{"textAlign":"right", "paddingLeft" : "15px", "verticalAlign": "top"}}>408.00</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 mt-2'>
                                        <tbody>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Subtotal</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>10493.09</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Discount applied 
                                                    <span className='linkButton' onClick={() => DiscountModal()}>
                                                        <em className="icon ni ni-info ms-1" />
                                                    </span>
                                                </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$50.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Commission</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$0</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Total</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$10443.09</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Rebates 
                                                    <span className='linkButton' onClick={() => rebatesModalShow()}>
                                                        <em className="icon ni ni-info ms-1" />
                                                    </span>
                                                    <span className='mb-0 d-block fw-normal'>App1 Rebate (Apple MAC-Premium)</span>
                                                </td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>$61.00</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Notes
                                                    </td>
                                                <td style={{ "padding": "0 0 10px"}}>-</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Invoice Message
                                                    </td>
                                                <td style={{ "padding": "0 0 10px"}}>-</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style={{"padding":" 0 0 10px", "color": "#364a63"}}></td>
                                                <td width="850" style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Other Notes
                                                    </td>
                                                <td style={{ "padding": "0 0 10px"}}>Test</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 text-center'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold"}}>Please Note</span>
                                                    <span style={{"display": "block", "margin": "0 0 7px" }}>
                                                    Payment is collected only after work is done. For easier payment processing please add MasterCard, VISA, Discover or American Express card . You will not be charged until work is completed.
                                                    </span>
                                                    <span style={{"margin": "0 0 7px", "color": "#364a63","fontWeight": "bold"}}>Quote
                                                        Expires</span>: 30 Days
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 text-center mt-2'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <CommonButton className="btn btn-danger" disabled>Declined</CommonButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <table style={{"width":"100%"}}>
                        <tbody>
                            <tr>
                                <td style={{"padding-bottom": "10px"}}>
                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>Your Job </span> 05:00 PM on 28 Mar 2023                                  
                                </td>
                            </tr>
                            <tr>
                                <td style={{"padding-bottom": "10px"}}>
                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "inline-block"}}>Address</span> 05:00 PM , Vijay Nagar, Indore, Madhya Pradesh, India 452010
                                </td>
                            </tr>
                            <tr>
                                <td style={{"width":"100%", "background": "#f1f3f9", "textAlign": "left", "padding": '10px', "text-align": 'center'}}>                               
                                    <span style={{"color": "#364a63","fontWeight": "bold"}}>Service Details</span><br/>
                                    <span>HVAC-Test - Scheduled Free Old Quote</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{"width":"100%", "padding-top": '10px'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{"width":"50%", "text-align": 'center', "padding": '10px'}}>
                                                    <ImageElement
                                                        source="provider-img.png"
                                                        className="img-fluid logo-img"
                                                        alt="logo"
                                                        style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                    />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        Provider
                                                    </span>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        kam test
                                                    </span>
                                                    <span>kam@mailinator.com</span>
                                                </td>
                                                <td style={{"width":"50%", "text-align": 'center', "padding": '10px'}}>
                                                        <ImageElement
                                                            source="user-img.png"
                                                            className="img-fluid logo-img"
                                                            alt="logo"
                                                            style={{"border": "1px solid #000", "borderRadius": "50%", "padding": "3px"}}
                                                        />
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>
                                                        User</span>
                                                    <span style={{"color": "#364a63" , "fontWeight": "bold", "display": "block"}}>peter parker</span>
                                                    <span>peterparker@mailinator.com</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                               <td>
                                    <h6 className='mt-3' style={{"color": "#364a63"}}>Amount Details For Job No :53949944</h6>
                                    <h6 className='mt-3' style={{"color": "#364a63"}}>Line items</h6>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th width="850" style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Name</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Description</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9"}}>Quantity</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9", "textAlign": "right"}}>Price</th>
                                                <th style={{ "color":"#364a63", "padding": "15px", "background":"#f1f3f9", "textAlign": "right"}}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='pt-2' style={ {"paddingLeft" : "15px"} }>Test'01</td>
                                                <td className='pt-2' style={{"paddingLeft" : "15px", "color": "#364a63","verticalAlign": "top"}}>sdfsdfsdfsd</td>
                                                <td className='pt-2' style={{"paddingLeft" : "15px", "verticalAlign": "top"}}>1</td>
                                                <td className='pt-2' style={{"textAlign":"right", "paddingLeft" : "15px", "verticalAlign": "top"}}>20.00</td>
                                                <td className='pt-2' style={{"textAlign":"right", "paddingLeft" : "15px", "verticalAlign": "top"}}>20.00</td>
                                            </tr>                                            
                                        </tbody>
                                    </table>
                               </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='w-100 mt-2'>
                                        <tbody>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Service Price</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>210.00</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Promo Code Discount</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>0.00</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Subtotal</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>230.00</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Discount(By Provider)</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>10.50</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Commission</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>0</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Notes</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px"}}>test</td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding":" 0 0 10px", "color": "#364a63","fontWeight": "bold"}}>Total Fees	</td>
                                                <td style={{"textAlign":"right", "padding": "0 0 10px","fontWeight": "bold", "color": "#364a63"}}>$219.5</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>                        
            </div>
          </div>
          <div className='text-center mt-3 mt-lg-4'>
              <CommonButton className="btn btn-secondary">Print</CommonButton>
            </div>
        </div>
        <ModalComponent
            closeButton={true}
            extraTitleClassName="justify-content-center"
            onHandleCancel={() => {
                setDiscountModal(false);}}
            backdrop
            show={discountModal}
            title="Discount"
        >
            <div className='table-responsive'>
                <Table className="w-100 mb-0">
                    <tr>
                        <th className='ps-0'>Applied On</th>
                        <td className='pe-0 text-end'>Apple - Apple MAC ( $2278.09 )</td>
                    </tr>
                    <tr>
                        <th className='ps-0'>	Discount</th>
                        <td className='pe-0 text-end'>	$50.00)</td>
                    </tr>
                    <tr>
                        <th className='ps-0'>Discount Amount</th>
                        <td className='pe-0 text-end'>$50.00</td>
                    </tr>
                    <tr>
                        <th className='ps-0'>Total</th>
                        <td className='pe-0 text-end fw-bold'>$50.00</td>
                    </tr>
                </Table>
            </div>
        </ModalComponent>
        <ModalComponent
            backdrop
            show={rebatesModal}
            onHandleCancel={rebatesModalHide}
            title="Rebates"
        >
            <p>Rebates are processed and honored by third parties. We cannot guarantee any rebates as there terms can change at any time</p>
        </ModalComponent>
        <ModalComponent
            backdrop
            show={productViewModal}
            onHandleCancel={productViewModalHide}
            title="Apple - Apple MAC"
        >
            <div className='invoiceModal'>
                <div className='imagePreview'>
                    <ul className='list-unstyled d-flex flex-nowrap overflow-auto mb-3'>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                        <li><Image src="/assets/images/admin/product-image.jpg" alt="product-image"/></li>
                    </ul>
                </div>
                <p className='mb-1'><b>Description</b> </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <div className='table-responsive'>
                    <Table className="w-100 mb-0">
                        <tr>
                            <th className='ps-0'>Up to SEER Rating</th>
                            <td className='pe-0 text-end'>75</td>
                        </tr>
                        <tr>
                            <th className='ps-0'>AHRI</th>
                            <td className='pe-0 text-end'># 5839968</td>
                        </tr>
                    </Table>
                </div>
            </div>
        </ModalComponent>  
    </>
  );
}

export default ViewQuoteDetails;
