
import { AmountFilter, CommonButton,  InputFilter, MultiSelectFilter, SelectFilter } from "../../components";
import { FilterFilled  } from "@ant-design/icons";
import { Checkbox } from '../../components/Antd';
import { Link } from 'react-router-dom';
import { Invoice } from "../../views/Reports";

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput.select());
    // }
  },
});

const getColumnSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getColumnSelectAmountProps = (dataIndex) => ({
  filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
  },
});

const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
  filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props}/>),
  filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {

  },
});

const getProviderListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};
const getJobStatusData = () => {
  return [
    {
      name: 'Open',
      value: 'Open',
    },
    {
      name: 'Accepted',
      value: 'Accepted',
    },
  ];
};
const getTypeData = () => {
    return [
      {
        name: 'Schedule Free Old',
        value: 'Schedule Free Old',
      },
      {
        name: 'Schedule A Replacement Quote',
        value: 'Schedule A Replacement Quote',
      },
    ];
  };

const LinkFormatter = (url, text) => {
    return (
      <>
        <Link to={url} className="">{text}</Link>
      </>
    );
  };

  const ButtonFormatter = (url, text) => {
    return (
      <>
        <Link to={url} className="btn btn-primary btn-sm">{text}</Link>
      </>
    );
  };

export const EstimatesTableColumn = ({JobProviderDiscountAdd,PurchaseOrderTotalAdd,PurchaseOrderTotal,SubLaborPayDetailsAdd,SubLaborPayDetails,ReturnAmountDetailsAdd,ReturnAmountDetails,PaymentMethodPage,SplitPaymentDetails,ProviderInvoiceUpdateDetails,InvoicePage}) => {
    return [
        {
            dataIndex: 'id',
            title: 'S.No.',
            className: 'w_70',
          },
          {
            dataIndex: 'bookingNo',
            title: 'Booking No',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => LinkFormatter("/admin/payment-report/booking-log", "75833147"),
            ...getColumnSearchProps("booking no"),
          },
          {
            dataIndex: 'location',
            title: 'Location',
            headerClasses: 'sorting',
            sorter: true,
          },
          {
            dataIndex: 'jobDate',
            title: 'Job Date',
            headerClasses: 'sorting',
            sorter: true,
          },
          {
            dataIndex: 'provider',
            title: 'Provider',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => LinkFormatter("/admin/provider/edit", row?.provider),
            ...getColumnMultiSelectSearchProps("provider", getProviderListData()),
          },
          {
            dataIndex: 'user',
            title: 'User',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => LinkFormatter("/admin/payment-report", row?.user),
            ...getColumnMultiSelectSearchProps("user", getProviderListData()),
          },
          {
            dataIndex: 'jobAmount',
            title: 'Job Amount',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectAmountProps(),
          },
          {
            dataIndex: 'type',
            title: 'Type',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectSearchProps("type", getTypeData()),
          },
          {
            dataIndex: 'quoteDetail',
            title: 'Quote Detail',
            headerClasses: 'sorting',
            sorter: true,
            render: (cell, row) => ButtonFormatter("/admin/payment-report/invoice", "View Quote"),
          },
          {
            dataIndex: 'jobStatus',
            title: 'Job Status',
            headerClasses: 'sorting',
            sorter: true,
            ...getColumnSelectSearchProps("job status", getJobStatusData()),
          },
          {
            dataIndex: 'sendQuote',
            title: 'Send Quote',
            headerClasses: 'sorting',
            render:(cell, row)=>{
                console.log("Details", cell)
                if(cell=== "send"){ 
                  return <><CommonButton className="btn btn-primary btn-sm"> Send Quote ($ 5,050.00)</CommonButton></>
                }else{
                  if(cell=== ""){
                    return <></>
                  }
                }
              }
          },
      ];
};
