
import React, { useState } from 'react';
import {
  Breadcrumb,
  ListingHeader,
  PageHeader,
  Tabs,
} from '../../../../../components';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';


function GMBPhoto(props) {
  const [defaultKey, setDefaultKey] = useState('teams');
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/google-gmb',
      name: 'Google My Business',
    },
    {
      path: '#',
      name: 'Google Media List',
    },
  ];

  const tabContent = [
    {
      name: 'Teams',
      key: 'teams',
      content: (
        <>
          <div className='card'>
            <div className='card-inner'>
              <Image.PreviewGroup>
                <div className='row g-3 g-xl-4'>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/uploadImg.png" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                </div>
              </Image.PreviewGroup>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Exterior',
      key: 'exterior',
      content: (
        <>
            <div className='card'>
            <div className='card-inner'>
              <Image.PreviewGroup>
                <div className='row g-3 g-xl-4'>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/a-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/a-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/a-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/a-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/a-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                </div>
              </Image.PreviewGroup>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Profile',
      key: 'profile',
      content: (
        <>
            <div className='card'>
            <div className='card-inner'>
              <Image.PreviewGroup>
                <div className='row g-3 g-xl-4'>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/c-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/c-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/c-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/c-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/c-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                </div>
              </Image.PreviewGroup>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'At Work',
      key: 'atWork',
      content: (
        <>
            <div className='card'>
            <div className='card-inner'>
              <Image.PreviewGroup>
                <div className='row g-3 g-xl-4'>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/d-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/d-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/d-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/d-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className='googleMediaListImage'>
                            <Image src="/assets/images/admin/d-sm.jpg" />
                            <span className='googleMediaListImage_icon'><em className="icon ni ni-eye-fill"></em> 50</span>
                        </div>
                    </div>
                </div>
              </Image.PreviewGroup>
            </div>
          </div>
        </>
      ),
    },
  ];
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/google-gmb')
  }
  const AddPostPhotosPage = () => {
    navigate('/admin/google-gmb/photo/add')
  }
  return (
    <>
      <div className='wide-md mx-auto'>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Google Media List">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton','create']}
              btnText="Add Photo"
              onHandleShow= {AddPostPhotosPage}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <Tabs
          tabContent={tabContent}
          tabsFor="table"
          activeKey={defaultKey}
          setActiveKey={setDefaultKey}
          // onTabChange={onTabChange}
        />
      </div>
    </>
  );
}

export default GMBPhoto;
