import { Outlet } from 'react-router-dom';
import { EditEmail, EditMessage, ListEmail, ListMessage } from '../../views/EmailMessage';

export default function route() {
  return [
    {
      private: true,
      name: "SMS & Email Messages",
      key: "emailSettings",
      belongsToSidebar: true,
      icon: accessRoute.EMAIL_TEMPLATES.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.EMAIL_TEMPLATES.name,
          key:"emailSettings.emailTemplates",
          path:accessRoute.EMAIL_TEMPLATES.path,
          belongsToSidebar: true,
          element: <ListEmail/>
        },
        {
          private: true,
          name:accessRoute.EDIT_EMAIL_TEMPLATES.name,
          key:"emailSettings.emailTemplates",
          path:accessRoute.EDIT_EMAIL_TEMPLATES.path,
          belongsToSidebar: false,
          element: <EditEmail/>
        },
        {
          private: true,
          name:accessRoute.SMS_TEMPLATES.name,
          key:"emailSettings.smsTemplates",
          path:accessRoute.SMS_TEMPLATES.path,
          belongsToSidebar: true,
          element: <ListMessage/>
        },
        {
          private: true,
          name:accessRoute.EDIT_SMS_TEMPLATES.name,
          key:"emailSettings.smsTemplates",
          path:accessRoute.EDIT_SMS_TEMPLATES.path,
          belongsToSidebar: false,
          element: <EditMessage/>
        },
      ]
    },
  ];
}

export const accessRoute = {
  EMAIL_TEMPLATES: {
    path: "/admin/email-templates",
    name: "Email Templates",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-mail-fill" />
      </span>
    )
  },
  EDIT_EMAIL_TEMPLATES: {
    path: "/admin/email-templates/edit",
    name: "Edit Email Templates",
  },
  SMS_TEMPLATES: {
    path: "/admin/sms-templates",
    name: "SMS Templates",
  },
  EDIT_SMS_TEMPLATES: {
    path: "/admin/sms-templates/edit",
    name: "Edit SMS Templates",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
