import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper, FormElementWrapper, CommonButton } from '../../../index';
import { initValues, validation } from './validation';
import { AntTextArea, InputType } from '../../../index';

function InformationForm({ onSubmit, onCancle }) {
  const { t } = useTranslation();

  return (
    <>
      <FormWrapper
        onSubmit={onSubmit}
        validation={validation}
        initialValues={initValues()}
      >
        <div className="row g-3">
          <div className="col-md-12">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    {t('text.appText.infoDescription')}
                    <span className="text-danger"> &lowast;</span>
                  </>
                }
                element={() => (
                  <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Enter information description"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    {t('text.appText.infoUrl')}
                    <span className="text-danger">&lowast;</span>
                  </>
                }
                element={() => (
                  <InputType
                    name="documentType"
                    className="form-control"
                    placeholder="Enter information Url"
                    disabled={false}
                    variant="standard"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12 text-end">
            <CommonButton type="submit" className="btn btn-primary me-2">
              Update
            </CommonButton>

            <CommonButton className="btn btn-light">
              {t('text.common.reset')}
            </CommonButton>
          </div>
        </div>
      </FormWrapper>
    </>
  );
}

export default InformationForm;
