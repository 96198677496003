import { AdminProfile } from '../../views/index.view';

export default function route() {
  return [
    {
      path: accessRoute.PROFILE.path,
      private: true,
      name: 'Profile',
      key: accessRoute.PROFILE.path,
      belongsToSidebar: false,
      // icon: accessRoute.PROFILE.icon,
      element: <AdminProfile />,
    },
  ];
}

export const accessRoute = {
  PROFILE: {
    path: '/admin/profile',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
