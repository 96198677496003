import React, { useState } from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  AntTextArea,
  Toggle,
  TablePlus,
  InputFilter,
  SelectFilter,
} from '../../../../../components';
import { Link } from 'react-router-dom';
import { modalNotification } from '../../../../../utils';
import { FilterFilled  } from "@ant-design/icons";

function ListOptionalItem(props) {
  const onConfirmAlert = () => {
    modalNotification({
      type: 'success',
      message: 'Optional Items Delete  Successfully',
    });
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler();
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler)}
              key={key}
              className="action_list"
            >
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Optional Items',
    },
  ];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list, key) => ({
    filterDropdown: (props) => (<SelectFilter filterKey={key} list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const statusActionOption = [
    {
    id: 1,
    name: 'Select Action'
    },
    {
    id: 2,
    name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
    id: 4,
    name: 'Make Delete'
    }    
  ]
  const getStatusData = () => {
    return [
      {
        name: 'All',
        value: 'All',
      },
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
    ];
  };
  const getQuoteTypeData = () => {
    return [
      {
        name: '80% Gas Furnace',
        value: '80% Gas Furnace',
      },
      {
        name: 'Gas Eff - Rebate',
        value: 'Gas Eff - Rebate',
      },
      {
        name: '	Apple MAC',
        value: '	Apple MAC',
      },
    ];
  }
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'title',
      title: '	Title',
      sorter: true,
      ...getColumnSearchProps("title")
    },
    {
      dataIndex: 'cost',
      title: 'Cost',
      sorter: true,
      ...getColumnSearchProps("cost")
    },
    {
      dataIndex: 'displayOrder',
      title: 'Display Order',
      sorter: true,
    },
    {
      dataIndex: 'quoteType',
      title: 'Quote Type',
      sorter: true,
      ...getColumnSelectSearchProps("quote type", getQuoteTypeData(), "quoteFilter")
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: true,
      ...getColumnSearchProps("description")
    },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: () => <Toggle isDropDown/>,
      ...getColumnSelectSearchProps("status", getStatusData(), "statusFilter")
    },
    {
      dataIndex: 'action',
      title: 'Action',
      className: 'nk-tb-col-tools text-end',
      render: () => <Action />,
    },
  ];
  const Action = () =>
    actionFormatter([
      {
        name: 'Edit',
        icon: 'icon ni ni-edit',
        handler: () =>{editOptionalItemsShow()},
      },
      {
        name: 'Delete',
        icon: 'icon ni ni-trash',
        type: 'redirect',
        handler: () => {
          setIsAlertVisible(true);
          document.body.click();
        },
      },
    ]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const adminData = [
    {
      id: 1,
      title: 'Admin op2',
      cost: '20.00',
      displayOrder: '1',
      quoteType: 'Apple MAC',
      description: 'Admin added optional item 2',
    },
    {
      id: 2,
      title: 'Admin op3',
      cost: '25.00',
      displayOrder: '1',
      quoteType: '	80% Gas Furnace',
      description: 'Admin added optional item 3',
    },
    {
      id: 3,
      title: 'Admin op4',
      cost: '27.00',
      displayOrder: '1',
      quoteType: 'Apple MAC',
      description: 'Admin added optional item 4',
    },
    {
      id: 4,
      title: 'Admin op11',
      cost: '11.00',
      displayOrder: '2',
      quoteType: 'Gas Eff - Rebate',
      description: 'Admin added optional item 11',
    },
    {
      id: 5,
      title: 'Utility knife',
      cost: '10.00',
      displayOrder: '1',
      quoteType: '	Apple MAC,Apple MAC1',
      description: '	his tool will come in handy',
    },
  ];
  const [addoptionalItems, setAddoptionalItems] = useState(false);
  const addoptionalItemsHide = () =>{
    setAddoptionalItems(false)
  }
  const addoptionalItemsShow = () =>{
    setAddoptionalItems(true)
  };
  const [editOptionalItems, setEditOptionalItems] = useState(false);
  const editOptionalItemsHide = () =>{
    setEditOptionalItems(false)
  }
  const editOptionalItemsShow = () =>{
    setEditOptionalItems(true)
  };
  const displayorderData =[
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
  ]
  const QuoteTypeData = [
    {
      name: '80%GasFurnace',
      value: '80% Gas Furnace',
    },
    {
      name: '90%Furnace',
      value: '90+% Furnace',
    },
  ]
  const customerTagData = [
    {
      name: 'AirCleaner',
      value: 'Air Cleaner',
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'HomeWarranty',
      value: 'Home Warranty',
    },
  ]
  const jobTagData = [
    {
      name: 'AirPurifier',
      value: 'Air Purifier',
    },
    {
      name: 'HeatingTune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'qwerty1',
      value: 'Qwerty1',
    },
  ]

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Optional Items">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create']}
            btnText="Add Optional Items"
            onHandleShow={addoptionalItemsShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}        
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Optional Items"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addoptionalItems}
        onHandleCancel={addoptionalItemsHide}
        title=" Add Optional Items"
      >
      <FormWrapper row={true} extraClassName="g-3">
      <FormElementWrapper
            md={6}
            label="Title"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="title"
                placeholder="Enter title"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Cost"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="cost"
                placeholder="Enter cost"
                formik={formik}
                type="number"
              />
            )}
          />
        <FormElementWrapper
            md={6}
            label="Display Order "
            mandatory={true}
            element={(formik) => (
              <Select
                name="displayorder"
                placeholder="Select display order"
                options={displayorderData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Quote Type"
            element={(formik) => (
              <Select
                name="QuoteType"
                placeholder="Select quote type"
                options={QuoteTypeData}
                showSearch
                formik={formik}
                mode="multiple"
                allowClear
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description "
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description "
              />
            )}
          />
          <h6 className="title mb-0">Auto Tag</h6>
          <FormElementWrapper
            md={6}
            label="Customer Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select customer tag "
                options={customerTagData}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Job Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select job tag "
                options={jobTagData}
                showSearch
                formik={formik}
              />
            )}
          />   
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addoptionalItemsHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addoptionalItemsHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editOptionalItems}
        onHandleCancel={editOptionalItemsHide}
        title=" Edit Optional Items"
      >
      <FormWrapper row={true} extraClassName="g-3">
      <FormElementWrapper
            md={6}
            label="Title"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="title"
                placeholder="Enter title"
                formik={formik}
                defaultValue="Admin op2"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Cost"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="cost"
                placeholder="Enter cost"
                formik={formik}
                type="number"
                defaultValue="200"
              />
            )}
          />
        <FormElementWrapper
            md={6}
            label="Display Order "
            mandatory={true}
            element={(formik) => (
              <Select
                name="displayorder"
                placeholder="Select display order"
                options={displayorderData}
                showSearch
                formik={formik}
                defaultValue="1"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Quote Type"
            
            allowClear
            element={(formik) => (
              <Select
                name="QuoteType"
                placeholder="Select quote type"
                options={QuoteTypeData}
                showSearch
                formik={formik}
                defaultValue="80% Gas Furnace"
                mandatory={true}
            mode="multiple"
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description "
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description "
                defaultValue="Admin added optional item 2"
              />
            )}
          />
          <h6 className="title mb-0">Auto Tag</h6>
          <FormElementWrapper
            md={6}
            label="Customer Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select customer tag "
                options={customerTagData}
                showSearch
                formik={formik}
                defaultValue="Air Cleaner"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Job Tag "
            element={(formik) => (
              <Select
                name="adminType"
                mode="multiple"
                allowClear
                placeholder="Select job tag "
                options={jobTagData}
                showSearch
                formik={formik}
                defaultValue="HeatingTune-Up"
              />
            )}
          />   
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-1 me-sm-2"
                  onClick={() => editOptionalItemsHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-1 me-sm-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => editOptionalItemsHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>    
    </>
  );
}

export default ListOptionalItem;
