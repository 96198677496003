import { Form, Input as FormInput, Select, Checkbox } from 'antd';
import { useField } from 'formik';

function Input({
  name,
  icon,
  setFieldValue,
  formik,
  inputType = 'text',
  extraClassName = '',
  ...rest
}) {
  // const [field, meta] = useField(name);
  // const config = { ...field, ...rest };

  // if (meta && meta?.touched && meta?.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }

  return (
    <Form.Item
      label={rest?.label}
      name={name}
      // help={meta?.error && meta?.error && meta?.touched ? meta.error : ''}
      // validateStatus={config?.error ? 'error' : 'success'}
    >
      <FormInput
        // onChange={formik.handleChange}
        type={inputType}
        {...rest}
        className={`form-control ${extraClassName && extraClassName}`}
      />
    </Form.Item>
  );
}

export default Input;
