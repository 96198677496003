import i18next from 'i18next';
export const financingInformationBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/financing',
      name: i18next.t('text.appText.financingInfoHeading'),
    },
  ];
};
