
import React from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  AntTextArea,
  Select
} from "../../../../components";

function CreateLineItem(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/line-items')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/line-items",
      name: "Line Items",
    },
    {
      path: "#",
      name: "Add Line Items",
    },
  ];
  const customerTagData = [
    {
      name: 'AirCleaner',
      value: 'Air Cleaner',
    },
    {
      name: 'Humidifier',
      value: 'Humidifier',
    },
    {
      name: 'HomeWarranty',
      value: 'Home Warranty',
    },
  ]
  const jobTagData = [
    {
      name: 'AirPurifier',
      value: 'Air Purifier',
    },
    {
      name: 'HeatingTune-Up',
      value: 'Heating Tune-Up',
    },
    {
      name: 'qwerty1',
      value: 'Qwerty1',
    },
  ]

  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Line Items">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
              <FormElementWrapper
                md={6}
                label="Name"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="name"
                    placeholder="Enter name"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Price"
                mandatory={true}
                element={(formik) => (
                  <TextInput
                    name="price"
                    placeholder="Enter Price"
                    formik={formik}
                    type="number"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                label="Image"
                element={(formik) => (
                  <TextInput
                    name="firstName"
                    placeholder="Select Image"
                    formik={formik}
                    type="file"
                  />
                )}
              />
              <FormElementWrapper
                md={12}
                label="Description"
                mandatory={true}
                element={(formik) => (
                  <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder="Enter description "
                  />
                )}
              />
              <h6 className="title mb-0">Truck Ordering</h6>
              <FormElementWrapper
                md={6}
                label="Part Number"
                element={(formik) => (
                  <TextInput
                    name="partnumber"
                    placeholder="Enter part number"
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Part Cost"
                element={(formik) => (
                  <TextInput
                    name="partcost"
                    placeholder="Enter part cost"
                    formik={formik}
                    type="number"
                    disabled
                  />
                )}
              /> 
              <h6 className="title mb-0">Auto Tag</h6>
              <FormElementWrapper
                md={6}
                label="Customer Tag "
                element={(formik) => (
                  <Select
                    name="adminType"
                    mode="multiple"
                    allowClear
                    placeholder="Select customer tag "
                    options={customerTagData}
                    showSearch
                    formik={formik}
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Job Tag "
                element={(formik) => (
                  <Select
                    name="adminType"
                    mode="multiple"
                    allowClear
                    placeholder="Select job tag "
                    options={jobTagData}
                    showSearch
                    formik={formik}
                  />
                )}
              />         
              <FormElementWrapper
                md={12}
                className="text-end"
                element={() => (
                  <>
                    <CommonButton
                      type="submit"
                      className="btn btn-primary me-2"
                    >
                      Add
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      className="btn btn-light"
                    >
                      Reset
                    </CommonButton>
                  </>
                )}
              />
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CreateLineItem;
