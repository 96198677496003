import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ModalComponent,
  Charts,
  ServiceManagementFilter,
  CommonButton,
  TablePlus,
  InputFilter,
  SelectFilter,
  AmountFilter,
  MultiSelectFilter,
  FormWrapper,
  FormElementWrapper,
  Select,
  DatePicker,
} from '../../../components';
import { FilterFilled  } from "@ant-design/icons";
import { Table } from 'react-bootstrap';

function ListServiceManagement(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Service Management',
    },
  ];
  const ButtonFormatter = (url, text) => {
    return (
      <>
        <Link to={url} className="">{text}</Link>
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getColumnSelectAmountProps = (dataIndex) => ({
    filterDropdown: (props) => (<AmountFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });
  
  const getColumnMultiSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<MultiSelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });

  const getProviderListData = () => {
    return [
      {
        name: 'abhishek Yadav',
        value: 'Abhishek Yadav',
      },
      {
        name: 'aditi Test',
        value: 'Aditi Test',
      },
    ];
  };
  const getCustomerTagData = () =>{
    return [
      {
        name: 'Air Cleaner',
        value: 'Air Cleaner',
      },
      {
        name: 'Humidifier',
        value: 'Humidifier',
      },
    ];
  }
  const getJobTagData = () =>{
    return [
      {
        name: 'Air Purifier',
        value: 'Air Purifier',
      },
      {
        name: 'Qwerty1',
        value: 'Qwerty1',
      },
    ];
  }
  const getTypeData = () =>{
    return [
      {
        name: 'Service Repair',
        value: 'Service Repair',
      },
      {
        name: 'Water Heater Maintenance',
        value: 'Water Heater Maintenance',
      },
    ];
  }
  const getJobStatusData = () => {
    return [
      {
        name: 'Completed',
        value: 'Completed',
      },
      {
        name: 'Expired',
        value: 'Expired',
      },
      {
        name: 'Accepted',
        value: 'Accepted',
      },
      {
        name: 'On Going Trip',
        value: 'On Going Trip',
      },
      {
        name: 'NAC',
        value: 'NAC',
      },
      {
        name: 'Pending',
        value: 'Pending',
      },
      {
        name: 'Cancelled',
        value: 'Cancelled',
      },
      {
        name: 'Declined',
        value: 'Declined',
      },
      {
        name: 'Will Call List',
        value: 'Will Call List',
      },
    ];
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'bookingNo',
      title: 'Booking No',
      headerClasses: 'sorting',
       sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/service/booking", "14940636"),
      ...getColumnSearchProps("booking number"),
    },
    {
      dataIndex: 'location',
      title: 'Location',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'jobDate',
      title: 'Job Date',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'provider',
      title: 'Provider',
      headerClasses: 'sorting',
       sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/provider/edit", "Ios Pro"),
      ...getColumnMultiSelectSearchProps("provider name", getProviderListData()),
    },
    {
      dataIndex: 'user',
      title: 'Customer',
      headerClasses: 'sorting',
       sorter: true,
      render: (cell, row) => ButtonFormatter("/admin/payment-report", "Radhika Shukla"),
      ...getColumnMultiSelectSearchProps("customer name", getProviderListData()),
    },
    {
      dataIndex: 'jobAmount',
      title: 'Job Amount',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectAmountProps(),
    },
    {
      dataIndex: 'customerTag',
      title: 'Customer Tag',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnMultiSelectSearchProps("customer tag", getCustomerTagData()),
    },
    {
      dataIndex: 'jobTag',
      title: 'Job Tag',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnMultiSelectSearchProps("job tag", getJobTagData()),
    },
    {
      dataIndex: 'type',
      title: 'Type',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnSelectSearchProps("type", getTypeData()),
    },
    {
      dataIndex: 'quoteDetail',
      title: 'Quote Detail',
      headerClasses: 'sorting',
       sorter: true,
      render:(cell, row)=>{
        console.log("Details", cell)
        return <><CommonButton onClick= { (e) => QuoteDetailsPage(e) } className="btn btn-primary btn-sm">View Quote</CommonButton></>
      }
    },
    {
      dataIndex: 'jobStatus',
      title: 'Job Status ',
      headerClasses: 'sorting',
       sorter: true,
       ...getColumnMultiSelectSearchProps("job status", getJobStatusData()),
      render:(cell, row)=>{
        console.log("Details", cell)
        if(cell=== "viewInvoice"){ 
          return <><CommonButton onClick= { (e) => InvoicePage(e) } className="btn btn-primary btn-sm">View Invoice</CommonButton></>
        }else if
        (cell=== "NAC"){
          return <>{cell}</>
        }else if
        (cell=== "Cancel Reason"){
          return <><CommonButton onClick= { (e) => JobCancelReasonShow(e) } className="btn btn-light btn-sm">Cancel Reason</CommonButton></>
        }
        else if
        (cell=== "Expired"){
          return <>{cell}</>
        }
        else{
          if(cell=== "Accepted"){
            return <>{cell}</>
          }
        }
      }
    },
    {
      dataIndex: 'sendQuote',
      title: 'Send Quote',
      headerClasses: 'sorting',
       sorter: true,
    },
    {
      dataIndex: 'completionTime',
      title: 'Job completion Time',
      headerClasses: 'sorting',
       sorter: true,
    },
  ];
  const navigate = useNavigate()
  const InvoicePage = (e) =>{
    e.preventDefault();
    navigate('/admin/service/invoice');
  }
  const QuoteDetailsPage = (e) => {
    e.preventDefault();
      navigate('/admin/service/quote-details');
  }
  const adminData = [
    {
      id: 1,
      bookingNo: '	14940636',
      location: 'Indore',
      jobDate: '10/10/2022',
      provider: '	animesh singh',
      user: 'Shane Watson',
      jobAmount: '$ 0.00',
      customerTag: "Humidifier",
      jobTag: "Air Purifier",
      type: "Same day",
      quoteDetail: "",
      jobStatus: "viewInvoice",
      sendQuote: "",
      completionTime: ""
    },
    {
      id: 2,
      bookingNo: '	86941331',
      location: 'Bhopal',
      jobDate: '08/11/2022',
      provider: 'Tech provider',
      user: 'Shane Watson',
      jobAmount: '$ 99.95	',
      customerTag: "Humidifier",
      jobTag: "Air Purifier",
      type: "Same Day",
      quoteDetail: "",
      jobStatus: "Accepted",
      sendQuote: "",
      completionTime: ""
    },
    {
      id: 3,
      bookingNo: '	95085075',
      location: 'Indore',
      jobDate: '11/11/2022',
      provider: 'ram raj',
      user: 'Shane Watson',
      jobAmount: '$ 99.95',
      customerTag: "Humidifier",
      jobTag: "Air Purifier",
      type: "Same day",
      quoteDetail: "",
      jobStatus: "NAC",
      sendQuote: "",
      completionTime: ""
    },
    {
      id: 4,
      bookingNo: '	95085076',
      location: 'Bhopal',
      jobDate: '13/11/2022',
      provider: 'ram raj',
      user: 'Shane Watson',
      jobAmount: '$ 109.95',
      customerTag: "Humidifier",
      jobTag: "Air Purifier",
      type: "Same day",
      quoteDetail: "",
      jobStatus: "Expired",
      sendQuote: "",
      completionTime: ""
    },
    {
      id: 5,
      bookingNo: '	77085076',
      location: 'Bhopal',
      jobDate: '15/11/2022',
      provider: 'raj',
      user: 'Watson',
      jobAmount: '$ 110.95',
      customerTag: "Humidifier",
      jobTag: "Air Purifier",
      type: "Same day",
      quoteDetail: "",
      jobStatus: "Cancel Reason",
      sendQuote: "",
      completionTime: ""
    },
  ];
  const [JobCancelReason, setJobCancelReason] = useState(false);
  const JobCancelReasonShow = () => {
    setJobCancelReason(true);
  };
  const JobCancelReasonHide = () => {
    setJobCancelReason(false);
  };
  const[globalFilter, setGlobalFilter ] = useState(false);
  const BackBtnFilter = () => {
    setGlobalFilter(false)
  }
  const OpenBtnFilter = () => {
    setGlobalFilter(true)
  }
  if (globalFilter) {
    document.querySelector('body').classList.add('toggle-shown');
  } else {
    document.querySelector('body').classList.remove('toggle-shown');
  }
  const filterData = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Yesterday',
      value: 'Yesterday',
    },
    {
      name: 'Current Week',
      value: 'Current Week',
    },
    {
      name: 'Previous Week',
      value: 'Previous Week',
    },
    {
      name: 'Current Month',
      value: 'Current Month',
    },
    {
      name: 'Previous Month',
      value: 'Previous Month',
    },
    {
      name: 'Current Year',
      value: 'Current Year',
    },
    {
      name: 'Previous Year',
      value: 'Previous Year',
    },
  ]
  const locationData = [
    {
      name: 'all',
      value: 'All',
    },
    {
      name: 'indore',
      value: 'Indore',
    },
    {
      name: 'bhopal',
      value: 'Bhopal',
    },
  ]
  const [ShowHideGraph, setShowHideGraph] = useState(false)
  const ShowHideGraphShow = () => {
    setShowHideGraph(!ShowHideGraph);
  }
  const chartOptionsData = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
    }
  }
  const jobData = {
    labels: [
      'Jan 21',
      'Feb 21',
      'Mar 21',
      'Apr 21',
      'May 21',
      'Jun 21',
      'Jul 21',
      'Aug 21',
      'Sep 21',
      'Oct 21',
      'Nov 21',
      'Dec 21',
    ],
    datasets: [
      {
        label: 'COLLECTED',
        data: [9, 20, 15,17,22,17,12,18,13,11,3,7],
        backgroundColor: [
          '#1EE0AC',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'UNCOLLECTED',
        data: [19, 13, 17,12,8,9,11,16,17,22,5,11,10],
        backgroundColor: [
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Delete selected job(s)'
    },
  ]
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Service Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['create', 'csvExport']}
            btnText="Show / Hide Graph"
            onHandleShow={ () => ShowHideGraphShow() }
            responsiveBtn = {()=><CommonButton onClick={ () => OpenBtnFilter() }  className="btn btn-primary d-block d-xl-none"><em class="icon ni ni-filter-fill me-1"></em> Filter</CommonButton>}
          />
        </div>
      </div>
      <div className='globalFilters'>
          <div className='card '>
            <div className='card-inner'>
              <div className={`globalFilters_inner ${globalFilter ? "filterToggle" : "" }`}>
                <div className='mb-3 d-block d-xl-none'>
                  <Link to="#" onClick={ () => BackBtnFilter() }><em class="icon ni ni-arrow-long-left me-1"></em> Back</Link>
                </div>
                <FormWrapper row={true} extraClassName="g-3">
                  
                <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="filter"
                        placeholder="Select by date"
                        options={filterData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="startDate"
                        className="form-control date-picker shadow-none"
                        placeholder="From date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <DatePicker
                        name="EndDate"
                        className="form-control date-picker shadow-none"
                        placeholder="To date"
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <Select
                        name="state"
                        placeholder="Select location"
                        options={locationData}
                        showSearch
                        formik={formik}
                      />
                    )}
                  />
                  <FormElementWrapper
                    sm={12} xl={3} xxl={2}
                    element={(formik) => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary me-2"
                        >
                          Search
                        </CommonButton>
                        <CommonButton
                          type="submit"
                          className="btn btn-light"
                        >
                          Reset
                        </CommonButton>
                      </>
                    )}
                  />
                </FormWrapper>
              </div>
              <div className={` ${globalFilter ? "toggle-overlay" : "" } `} onClick={() => BackBtnFilter()}></div>
            </div>
          </div>
      </div>
      {
        ShowHideGraph &&
        <div className='card mb-3'>
          <div className='card-inner'>
            <div className='d-lg-flex align-items-center'>
              <div style={{ maxWidth: '1100px', width: '100%', height: '350px'}}>
                <Charts type="bar" data={jobData} options={ chartOptionsData}/>
              </div>
              <ul className="nk-store-statistics mt-0 mt-lg-4 ms-lg-4">
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">COLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                      $20115773.28
                      <span className="change down">
                        <em className="ni ni-arrow-long-down"></em>2696 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-info-dim ni ni-activity-round"></em>
                </li>
                <li className="item pt-0">
                  <div className="info me-3">
                    <div className="title">UNCOLLECTED</div>
                    <div className="count d-flex flex-wrap align-items-center">
                    $751732.75
                      <span className="change up">
                        <em className="ni ni-arrow-long-up"></em>534 jobs
                      </span>
                    </div>
                  </div>
                  <em className="icon bg-purple-dim ni ni-activity-round"></em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
        isSearch={false}
        showAction={false}
        statusAction
        statusActionOption={statusActionOption}
      />
      <div className='nk-block'>
          <div className="card">
            <div className="card-inner">
              <h5 className="title mb-4">Customer Total Spent</h5>
                <div className='table-responsive'>
                  <Table bordered size="md">
                    <tbody>
                      <tr>
                        <th>Customer Total Spent</th>
                        <th>$2065.95</th>
                      </tr>
                    </tbody>
                  </Table>
                </div>
            </div>
          </div>
        </div>
      <ModalComponent
        backdrop
        show={JobCancelReason}
        onHandleCancel={JobCancelReasonHide}
        title="Job Cancel Reason"
      >
        <ul className="list list-sm list-checked">
          <li><b>Cancel Reason:- </b> Test</li>
        </ul>
        <div className='text-end'>
          <CommonButton className="btn btn-secondary" onClick= { () => JobCancelReasonHide() }>Close</CommonButton>
        </div>
      </ModalComponent>
    </>
  );
}

export default ListServiceManagement;
