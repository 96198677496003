import React from 'react';

export default function CreateButton({ onHandleShow, btnText,btnIcons }) {
  return (
    <>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#addForm"
        onClick={onHandleShow}
      >
        <em className={btnIcons}></em>
        <span>{btnText}</span>
      </button>
    </>
  );
}
