import HeatMap from '../../views/HeatMap';

export default function route() {
  return [
    {
      path: accessRoute.HEATMAP.path,
      private: true,
      name: 'Heatmap',
      key: accessRoute.HEATMAP.path,
      belongsToSidebar: false,
      icon: accessRoute.HEATMAP.icon,
      element: <HeatMap />,
    },
  ];
}

export const accessRoute = {
  HEATMAP: {
    path: '/admin/heatmap',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt-fill" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route();
};
