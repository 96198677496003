import { Radio, Select } from "antd";
import React, {useState} from "react";
import {  useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  CommonButton,
  FormElementWrapper,
  InputType,
  ListingHeader,
  PageHeader,
  TextInput,
  FormWrapper,
  Toggle
} from "../../../components";

function CreateUser(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/customers')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/customers",
      name: "Customers",
    },
    {
      path: "#",
      name: "Add Customer",
    },
  ];
  const countryData = [
    {
      name: 'india',
      value: 'India',
    },
    {
      name: 'unitedstates',
      value: 'United States',
    },
    {
      name: 'Afghanistan',
      value: 'Afghanistan',
    },
    {
      name: 'Algeria',
      value: 'Algeria',
    },
    {
      name: 'Albania',
      value: 'Albania',
    },
    {
      name: 'Argentina',
      value: 'Argentina',
    },
    {
      name: 'Bahamas',
      value: 'Bahamas',
    },
    {
      name: 'Bahrain',
      value: 'Bahrain',
    },
    {
      name: 'Belgium',
      value: 'Belgium',
    },
    {
      name: 'Colombia',
      value: 'Colombia',
    },
    {
      name: 'Denmark',
      value: 'Denmark',
    },
    {
      name: 'Estonia',
      value: 'Estonia',
    },
    {
      name: 'Gambia',
      value: 'Gambia',
    },
    {
      name: 'Grenada',
      value: 'Grenada',
    },
    {
      name: 'japan',
      value: 'japan',
    },
  ];
  const statusData = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ];
  const monthData = [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
  ];
  const membershipData = [
    {
      name: 'paid',
      value: 'Paid',
    },
    {
      name: 'unpaid',
      value: 'Unpaid',
    },
  ]
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Add Customer">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
            <FormWrapper row={true} extraClassName="g-3">
          <FormElementWrapper
            md={6}
            label="First Name"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="fName"
                placeholder="Enter first name"
                formik={formik}
                
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Last Name"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="lName"
                placeholder="Enter last name"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Email"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="email"
                placeholder="Enter email"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Password"
            mandatory={true}
            element={(formik) => (
              <TextInput
                name="password"
                placeholder="Enter password"
                formik={formik}
              />
            )}
          />
          {/* <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100">
              Gender  
              </label>
              <Radio.Group value={value} onChange={onChange}>
                <Radio value={1}>Male</Radio>
                <Radio value={2}>Female</Radio>
              </Radio.Group>
            </div>
          </div> */}
          
          <FormElementWrapper
            md={6}
            label="Profile Picture"
            element={(formik) => (
              <TextInput
                name="profile"
                placeholder="Select profile picture"
                formik={formik}
                type="file"
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Country"
            mandatory={true}
            element={(formik) => (
              <Select
                name="country"
                placeholder="Select country"
                options={countryData}
                showSearch
                formik={formik}
              />
            )}
          />
          {/* <FormElementWrapper
            md={6}
            label="Number of Month"
            mandatory={true}
            element={(formik) => (
              <Select
                name="months"
                placeholder="Select months"
                options={monthData}
                // showSearch
                formik={formik}
              />
            )}
          /> */}
          {/* <FormElementWrapper
            md={6}
            label="Membership"
            mandatory={true}
            element={(formik) => (
              <Select
                name="adminType"
                placeholder="Select membership "
                options={membershipData}
                // showSearch
                formik={formik}
              />
            )}
          /> */}
          <FormElementWrapper
            md={6}
            label="Phone"
            mandatory={true}
            element={(formik) => (
              <div className="form-control-wrap">
                <div className="input-group flex-nowrap">
                  <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">1</span>
                  </div>
                  <InputType
                    className="form-control"
                    placeholder="Enter phone  "
                    name="phoneNumber"
                    disabled={false}
                    type="text"
                  />
                </div>
            </div>
            )}
          />
          <FormElementWrapper
              md={6}
              label="Status"
              element={(formik) => (
                <Toggle />
              )}
            />          
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light"
                >
                  Reset
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
            </div>
          </div>
        </div>
    </div>
  );
}

export default CreateUser;
