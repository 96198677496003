import React, { useState } from "react";
import {
  Breadcrumb,  
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  TablePlus,
} from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns,
  productManagementService,
} from "../../../../services/index.service";
import { modalNotification } from "../../../../utils"; 

function ProductsList(props) {
  const navigate = useNavigate()

  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: " Product Management",
    },
  ];

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Product Deleted Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }

  const statusActionOption = [
    {
      id: 1,
        name: 'Select Action'
      },
      {
        id: 2,
        name: 'Make Active'
      },
      {
        id: 3,
        name: 'Make Inactive'
      },
      {
        id: 4,
        name: 'Make Delete'
      }
  ]

  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.productsManagementTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () =>{navigate('/admin/product-management/edit');},
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler:()=>{ setIsAlertVisible(true);
            document.body.click()}
        },
      ]),
  });

  const productsData = productManagementService();
  const AddMembershipPage = () =>{
    navigate('/admin/product-management/add');
  }

  const multipleButton  = [
    
    {
      title: "Download sample",
    },
    {
      title: "Add Product",
      handler: () => { AddMembershipPage() },
    },
  ]


  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Product Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={[ 'uploadImagesDropdown', "create","csvExport", 'csvImport', 'downloadSample']}
            btnText="Add Product"
            onHandleShow={AddMembershipPage}
          />
        </div>
      </div>      
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={productsData}
        tableColumns={columns}
        showAction={false}
        isSearch={true}
        // tableLoader={isLoading}
        setSizePerPage=""
      />      
      <SweetAlert
        title="Are you sure"
        text=" you want to delete this Product"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        onConfirmAlert={onConfirmAlert}
      />          
    </>
  );
}

export default ProductsList;
