import React from "react";
import { Link } from "react-router-dom";


function PageNotFound(props) {
    return(
        <>
            <div className="pageNotFound">
                <div className="pageNotFound_inner d-flex flex-column align-items-center justify-content-center h-100">
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                    <p className="text-center mb-3 mb-lg-4">We're sorry, the page you requested could not be found.<br className="d-none d-lg-block"/> Please go back to the homepage.</p>
                    <Link to="/admin/dashboard" className="btn btn-primary">Back To Home</Link>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;
