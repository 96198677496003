import { Outlet } from "react-router-dom";
import { ListProvider, ListAdmin, ProviderSales } from "../../views/index.view";
import EditAdminProfile from "../../views/TeamSetting/Admin/Edit";
import DocumentListing from "../../views/TeamSetting/ManageDocument/Listing";
import ProfileTab from "../../views/TeamSetting/Providers/Create/Profile";
import DocumentList from "../../views/TeamSetting/Providers/Documents";
import EditProviderProfile from "../../views/TeamSetting/Providers/Edit";
import EditAvailability from "../../views/TeamSetting/Providers/Edit/Availability";
import EditProviderService from "../../views/TeamSetting/Providers/Edit/Edit Service";
import AssignModules from "../../views/TeamSetting/Roles/Assign";
import RoleListing from "../../views/TeamSetting/Roles/Listing";

export default function route() {
  return [
    {
      private: true,
      name: "Team Setting",
      key: "teamSetting",
      belongsToSidebar: true,
      icon: accessRoute.PROVIDER.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.PROVIDER.name,
          key:"teamSetting.provider",
          path:accessRoute.PROVIDER.path,
          belongsToSidebar: true,
          element: <ListProvider/>
        },
        {
          private: true,
          name:accessRoute.ADMIN.name,
          key:"teamSetting.admin",
          path:accessRoute.ADMIN.path,
          belongsToSidebar: true,
          element: <ListAdmin/>
        },
        {
          private: true,
          name:accessRoute.MANAGE_DOCUMENT.name,
          key:"teamSetting.documents",
          path:accessRoute.MANAGE_DOCUMENT.path,
          belongsToSidebar: true,
          element: <DocumentListing/>
        },
        {
          private: true,
          name:accessRoute.ROLES.name,
          key:"teamSetting.roles",
          path:accessRoute.ROLES.path,
          belongsToSidebar: true,
          element: <RoleListing/>
        },
        {
          private: true,
          name:accessRoute.ADD_PROVIDER.name,
          key:"teamSetting.provider",
          path:accessRoute.ADD_PROVIDER.path,
          belongsToSidebar: false,
          element: <ProfileTab/>
        },
        {
          private: true,
          name:accessRoute.EDIT_PROVIDER_SERVICE.name,
          key:"teamSetting.provider",
          path:accessRoute.EDIT_PROVIDER_SERVICE.path,
          belongsToSidebar: false,
          element: <EditProviderService/>
        },
        {
          private: true,
          name:accessRoute.PROVIDER_DOCUMENT.name,
          key:"teamSetting.provider",
          path:accessRoute.PROVIDER_DOCUMENT.path,
          belongsToSidebar: false,
          element: <DocumentList/>
        },
        {
          private: true,
          name:accessRoute.EDIT_PROVIDER.name,
          key:"teamSetting.provider",
          path:accessRoute.EDIT_PROVIDER.path,
          belongsToSidebar: false,
          element: <EditProviderProfile/>
        },
        {
          private: true,
          name:accessRoute.PROVIDER_EDIT_AVAILABILITY.name,
          key:"teamSetting.provider",
          path:accessRoute.PROVIDER_EDIT_AVAILABILITY.path,
          belongsToSidebar: false,
          element: <EditAvailability/>
        },
        {
          private: true,
          name:accessRoute.EDIT_ADMIN.name,
          key:"teamSetting.admin",
          path:accessRoute.EDIT_ADMIN.path,
          belongsToSidebar: false,
          element: <EditAdminProfile/>
        },
        {
          private: true,
          name:accessRoute.ASSIGN_MODULE.name,
          key:"teamSetting.roles",
          path:accessRoute.ASSIGN_MODULE.path,
          belongsToSidebar: false,
          element: <AssignModules/>
        },
        {
          private: true,
          name:accessRoute.PROVIDER_SALES.name,
          key:"teamSetting.provider",
          path:accessRoute.PROVIDER_SALES.path,
          belongsToSidebar: false,
          element: <ProviderSales/>
        },
      ]
    },
  ];
}

export const accessRoute = {
  PROVIDER: {
    path: "/admin/provider",
    name: "Provider",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-setting-alt-fill" />
      </span>
    )
  },
  ADMIN: {
    path: "/admin/super-admin",
    name:"Admin",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  EDIT_ADMIN: {
    path: "/admin/super-admin/edit",
    name:"Edit Admin",
  },
  MANAGE_DOCUMENT: {
    path: "/admin/manage-document",
    name:"Manage Document",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  ASSIGN_MODULE: {
    path: "/admin/assign-modules",
    name:"Assign Modules",
  },
  ROLES: {
    path: "/admin/roles",
    name:"Roles",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  ADD_PROVIDER: {
    path: "/admin/provider/add",
    name:"Add Provider",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  PROVIDER_SALES: {
    path: "/admin/provider-sales",
    name:"Add Provider",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  EDIT_PROVIDER: {
    path: "/admin/provider/edit",
    name:"Edit Provider",
  },
  EDIT_PROVIDER_SERVICE: {
    path: "/admin/provider/service",
    name:"Provider Servcie",
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // )
  },
  PROVIDER_DOCUMENT: {
    path: "/admin/provider/document",
    name:"Provider Document",
  },
  PROVIDER_EDIT_AVAILABILITY: {
    path: "/admin/provider/availability",
    name:"Provider Edit Availability",
  },
};

export const getChildrenRoutes = () => {
  return route()[0].children;
};

