import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper, FormElementWrapper, CommonButton } from '../../../index';
import { initValues, validation } from './validation';
import { AntTextArea, InputType } from '../../../index';

function FinancingInformationForm(mainProps) {
  const { t } = useTranslation();
  const { onSubmit, loading, onCancle } = mainProps;

  return (
    <>
      <FormWrapper
        onSubmit={onSubmit}
        validation={validation}
        initialValues={initValues()}
      >
        <div className="row g-3">
          <div className="col-md-12">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    {t('text.appText.financingDescription')}
                    <span className="text-danger"> &lowast;</span>
                  </>
                }
                element={() => (
                  <AntTextArea
                    name="description"
                    className="form-control"
                    placeholder={t('text.appText.finDescriptionPlaceholder')}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    <label className="form-label">
                      {t('text.appText.financeBankOne')}
                      <span className="text-danger"> &lowast;</span>
                    </label>
                  </>
                }
                element={() => (
                  <InputType
                    name="first_bank_name"
                    className="form-control"
                    placeholder={t('text.appText.finBankPlaceholder')}
                    disabled={false}
                    variant="standard"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    <label className="form-label">
                      {t('text.appText.financeBankUrlOne')}
                      <span className="text-danger"> &lowast;</span>
                    </label>
                  </>
                }
                element={() => (
                  <InputType
                    name="first_bank_url"
                    className="form-control"
                    placeholder={t('text.appText.finBankUrlPlaceholder')}
                    disabled={false}
                    variant="standard"
                    type="text"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    <label className="form-label">
                      {t('text.appText.financeBankTwo')}{' '}
                      <span className="text-danger"> &lowast;</span>
                    </label>
                  </>
                }
                element={() => (
                  <InputType
                    name="second_bank_name"
                    className="form-control"
                    placeholder={t('text.appText.finBankPlaceholder')}
                    disabled={false}
                    variant="standard"
                    type="text"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <FormElementWrapper
                label={
                  <>
                    {t('text.appText.financeBankUrlTwo')}
                    <span className="text-danger"> &lowast;</span>
                  </>
                }
                element={() => (
                  <InputType
                    className="form-control"
                    placeholder={t('text.appText.finBankUrlPlaceholder')}
                    name="second_bank_url"
                    disabled={false}
                    variant="standard"
                    type="text"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12 text-end">
            <CommonButton
              type="submit"
              className="btn btn-primary me-2"
              loading={loading}
            >
              Update
            </CommonButton>

            <CommonButton onClick={onCancle} className="btn btn-light">
              {t('text.common.reset')}
            </CommonButton>
          </div>
        </div>
      </FormWrapper>
    </>
  );
}

export default FinancingInformationForm;
