import { Outlet } from 'react-router-dom';
import { CompanySetting } from '../../views/CompanySetting';

export default function route() {
  return [
    {
      private: true,
      name: "Company Settings",
      key: "companySettings",
      belongsToSidebar: true,
      icon: accessRoute.GENERAL.icon,
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name:accessRoute.GENERAL.name,
          key:"companySettings.general",
          path:accessRoute.GENERAL.path,
          belongsToSidebar: true,
          element: <CompanySetting/>
        },
      ]
    },
  ];
}

export const accessRoute = {
  GENERAL: {
    path: "/admin/general-setting",
    name: "General",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-setting-fill" />
      </span>
    )
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
