import React from 'react';
import { Link } from 'react-router-dom';
import {
  ImageElement,
  AdminCompanyProfileTwoForm,
} from '../../../../components';
import { formSubmit } from '../../../../utils';

export default function CompanyProfileTwo() {
  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Set Up Company Profile</h5>
            </div>
          </div>
          <AdminCompanyProfileTwoForm onSubmit={(e) => formSubmit(e)} />
        </div>
      </div>
    </div>
  );
}
