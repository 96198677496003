/* eslint-disable react/jsx-key */
import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Password,
  CheckBox,
  NumberPrefix,
} from '../../../../index';
import { initValues, validation } from './validation';

function AdminCreateProfile({ onSubmit }) {
  const [agree, setAgree] = useState(true);
  const PhoneNumberData = [
    {
      name: '+1',
      value: '+1',
    },
    {
      name: '+91',
      value: '+91',
    },
  ]
  return (
    <FormWrapper row={true} extraClassName="g-3">
                <FormElementWrapper
                  md={6}
                  label="First Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="FirstName"
                      placeholder="Enter first name"
                      formik={formik}
                      extraClassName="form-control-lg"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Last Name"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="LastName"
                      placeholder="Enter last name"
                      formik={formik}
                      extraClassName="form-control-lg"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Phone Number"
                  mandatory={true}
                  element={(formik) => (
                    <div className="inputAndSelect">
                      <Input.Group compact className='d-flex'>
                        <Select
                            name="PhoneNumber"
                            placeholder="Select phone number"
                            options={PhoneNumberData}
                            showSearch
                            formik={formik}
                            defaultValue=" +1 "
                            size= "large"
                            className='w-25'
                          />
                        <TextInput
                          name="PhoneNumber"
                          placeholder="Enter phone number"
                          formik={formik}
                          type="number"
                          extraClassName="form-control-lg"
                        />
                      </Input.Group>
                    </div>
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Email"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="email"
                      placeholder="Enter email"
                      formik={formik}
                      type="email"
                      extraClassName="form-control-lg"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Password"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="password"
                      placeholder="Enter password"
                      formik={formik}
                      type="password"
                      extraClassName="form-control-lg"
                    />
                  )}
                />
                <FormElementWrapper
                  md={6}
                  label="Confirm Password"
                  mandatory={true}
                  element={(formik) => (
                    <TextInput
                      name="ConfirmPassword"
                      placeholder="Enter confirm password"
                      formik={formik}
                      type="password"
                      extraClassName="form-control-lg"
                    />
                  )}
                />              
                <FormElementWrapper
                  md={6}
                  element={(formik) => (
                    <CheckBox
                      element={
                      <>
                      I agree to the
                      <Link to="#">  Terms of service <span className="text-danger">*</span> </Link> 
                       and  <Link to="#"> Privacy policy <span className="text-danger">*</span></Link>  
                      </>
                      }
                    />
                  )}
                />
                <div className='text-center'>
                  <div className="form-group">
                      <Link to="/nca/register-company-step-one"
                          className="btn btn-lg btn-primary"
                      >
                          Save & Next
                      </Link>
                  </div>
                  </div>
          </FormWrapper>
  );
}

export default AdminCreateProfile;
