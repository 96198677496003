import { Link } from "react-router-dom";
import { DateFilter, InputFilter, SelectFilter, Toggle } from "../../components";
import { FilterFilled  } from "@ant-design/icons";

export const ButtonFormatter = (url, text) => {
    return (
      <>
        <div className="text-center">
          <Link to={url} className="btn btn-primary">{text}</Link>
        </div>
      </>
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });
  
  const getColumnSelectSearchProps = (dataIndex, list) => ({
    filterDropdown: (props) => (<SelectFilter list={list} dataIndex={dataIndex} {...props} placeholderValue/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
  
    },
  });
  
  const getColumnSelectDateProps = (dataIndex) => ({
    filterDropdown: (props) => (<DateFilter  dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
    },
  });

  const userCompanyData = () => {
    return [
      {
        name: 'Adhistha Company',
        value: 'Adhistha Company',
      },
      {
        name: 'Good Guys Admin',
        value: 'Good Guys Admin',
      },
    ];
  };
  const locationData = () => {
    return [
      {
        name: '	India',
        value: '	India',
      },
      {
        name: 'United States',
        value: 'United States',
      },
    ];
  }
  const statusData = () => {
    return [
      {
        name: '	Active',
        value: '	Active',
      },
      {
        name: 'Inactive',
        value: 'Inactive',
      },
      {
        name: 'Deleted',
        value: 'Deleted',
      },
    ];
  }
  const memberData = () => {
    return [
      {
        name: '	Member',
        value: 'Member',
      },
      {
        name: 'Non-Member',
        value: 'Non-Member',
      },
      {
        name: 'Non-Member Expired	',
        value: 'Non-Member Expired	',
      },
    ];
  }

  const ModalOpenFormatter = ( url , text) => {
    return (
        <>
            <Link to="#" onClick={url} className="text-primary">{text}</Link>
        </>
    );
};

export const CustomersTableColumn = ({ Action, profileView }) => {
  // console.log("Action", Action);
  return [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "userName",
      title: "Customers Name",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("customers name"),
      render: (cell, row) => ModalOpenFormatter(profileView, row?.userName),
    },
    {
      dataIndex: "email",
      title: "Email",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSearchProps("email"),
    },
    {
      dataIndex: "usersCompany",
      title: "Customers Company",
      headerClasses: "sorting",
      sorter: true,
      ...getColumnSelectSearchProps("customers company", userCompanyData()),
    },
    {
        dataIndex: "signUpDate",
        title: "Sign Up Date",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectDateProps("Date"),
      },
      {
        dataIndex: "mobile",
        title: "Mobile ",
        headerClasses: "sorting",
        ...getColumnSearchProps("mobile"),
      },
      {
        dataIndex: "location",
        title: "Location ",
        headerClasses: "sorting",
        ...getColumnSelectSearchProps("location", locationData()),
      },
      {
        dataIndex: "viewBooking",
        title: "View Booking Detail",
        headerClasses: "sorting",
        render: (cell, row) => ButtonFormatter("/admin/service", "View"),
      },
      {
        dataIndex: "userAddress",
        title: "View Customers Address",
        headerClasses: "sorting",
        render: (cell, row) => ButtonFormatter("/admin/customers/edit/:id", "View"),
      },

      {
        dataIndex: "bookNow",
        title: "Book Now",
        headerClasses: "sorting",
        render: (cell, row) => ButtonFormatter("/admin/add-booking", "Book Now"),
      },
      {
        dataIndex: "membershipStatus",
        title: "Membership Status",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectSearchProps("membership status", memberData()),
      },
      {
        dataIndex: "memberRenewalDate",
        title: "Member Renewal Date",
        headerClasses: "sorting",
        sorter: true,
        ...getColumnSelectDateProps("Date"),
      },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
      render:()=><Toggle isDropDown shouldDeleteInclude/>,
      sorter: true,
      ...getColumnSelectSearchProps("status", statusData()),
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
};
