import i18next from 'i18next';
export const importantInformationBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '/admin/important-info',
      name: i18next.t('text.appText.infoHeading'),
    },
  ];
};
