import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function GlobalLoader({ animation = 'border', variant = 'primary', ...rest }) {
  return (
    <div className="text-center">
      <Spinner animation={animation} variant={variant} {...rest} />
    </div>
  );
}
export default GlobalLoader;