
import React from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  PaymentReportFilterForm,
  TablePlus,
} from '../../../../components';
import { Columns, QuoteManagementEstimatesServices } from '../../../../services/index.service';


function QuoteManagementEstimates(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Quote Management - Estimates',
    },
  ];
  const navigate = useNavigate()
  const PaymentMethodPage = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/payment-method');
  }
  const InvoicePage = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/invoice'); 
  }
  const JobProviderDiscountAdd = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/job-provider-discount');
  }
  const PurchaseOrderTotal = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/purchase-order');
  }
  const PurchaseOrderTotalAdd = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/purchase-order-add');
  }
  const SubLaborPayDetails = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/sublabor-pay');
  }
  const SubLaborPayDetailsAdd = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/sublabor-pay-add');
  }
  const ReturnAmountDetails = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/return-amount');
  }
  const ReturnAmountDetailsAdd = (e) =>{
    e.preventDefault();
    navigate('/admin/payment-report/return-amount-add');
  }
  const SplitPaymentDetails = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report/split-payment');
  }
  const ProviderInvoiceUpdateDetails = (e) => {
    e.preventDefault();
    navigate('/admin/payment-report/provider-invoice-update');
  }
  const EstimateData = QuoteManagementEstimatesServices();
  const columns = Columns.EstimatesTableColumn({JobProviderDiscountAdd,PurchaseOrderTotalAdd,PurchaseOrderTotal,SubLaborPayDetailsAdd,SubLaborPayDetails,ReturnAmountDetailsAdd,ReturnAmountDetails,PaymentMethodPage,SplitPaymentDetails,ProviderInvoiceUpdateDetails,InvoicePage});

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Quote Management - Estimates">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['filter','csvExport']}
            popover={ <PaymentReportFilterForm/>}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={EstimateData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
    </>
  );
}

export default QuoteManagementEstimates;
