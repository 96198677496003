import { Alert } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ImageElement,
  AdminBusinessTypeForm,
  ModalComponent,
  AdminAddPerson,
} from '../../../../components';
import { formSubmit } from '../../../../utils';

export default function BusinessType() {
  const [modalShow, setModalShow] = useState('');

  return (
    <div className="nk-block nk-block-middle nk-auth-body wide-md">
      <div className="brand-logo pb-4 text-center">
        <Link className="logo-link" to="#">
          <ImageElement
            source="logo.svg"
            className="logo-dark logo-img logo-img-lg"
            alt="logo-dark"
          />
        </Link>
      </div>
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Business Type</h5>
            </div>
          </div>
          {/* For Business Form */}
          <AdminBusinessTypeForm onSubmit={(e) => formSubmit(e)} />
          {/* For Add person modal */}
          <ModalComponent
            title="Add Person"
            closeButton={false}
            onHandleCancel={() => setModalShow('')}
            show={modalShow === 'addPerson'}
          >
            <AdminAddPerson
              onSubmit={(e) => {
                formSubmit(e);
                setModalShow('');
              }}
            />
          </ModalComponent>
          {/*For Business Type */}
          <ModalComponent
            title="Business Type"
            closeButton={false}
            onHandleCancel={() => setModalShow(false)}
            show={modalShow === 'businessType'}
            size="lg"
          >
            <Alert
              message="Note:-Please fill the form carefully because the form cannot be updated after successful submission."
              type="info"
              className="mb-3"
            />
            <AdminBusinessTypeForm
              businessModal={false}
              onSubmit={(e) => {
                formSubmit(e);
                setModalShow('');
              }}
            />
          </ModalComponent>
        </div>
      </div>
    </div>
  );
}
