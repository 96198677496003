import { Outlet } from 'react-router-dom';
import { ListWillCall } from '../../views/WillCallList';

export default function route() {
  return [
    {
      path: accessRoute.WILL_CALL_LIST.path,
      private: true,
      name: 'Will Call List',
      key: "willCallList",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.WILL_CALL_LIST.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.WILL_CALL_LIST.path,
        name: 'Will Call List',
        key: accessRoute.WILL_CALL_LIST.key,
        belongsToSidebar: false,
        icon: accessRoute.WILL_CALL_LIST.icon,
        element: <ListWillCall />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  WILL_CALL_LIST: {
    path: '/admin/will-call-list',
    key:"willCallList.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-money" />
      </span>
    ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
