export const providerService = () => {
  return [
    {
      id: 1,
      providerName: "animesh singh",
      email: "animesh@mailinator.com",
      signupDate: "7th November, 2022",
      mobile: "(+91) 9865328754",
      providerNCA: "Good Guys Admin",
      viewEditDocument: "icon ni ni-file-doc",
      servcieURL: "/admin/provider/service",
      documentURL: "/admin/provider/document",
      manageServices: "icon ni ni-file-text",
      viewEditAvailabileUnavailable: "Edit Availability, Unavailability",
    },
    {
      id: 2,
      providerName: "Tech provider",
      email: "techpro@mailinator.com",
      signupDate: "3rd November, 2022",
      mobile: "	(+91) 5638656565",
      providerNCA: "Good Guys Admin",
      viewEditDocument: "icon ni ni-file-doc",
      servcieURL: "/admin/provider/service",
      documentURL: "/admin/provider/document",
      manageServices: "icon ni ni-file-text",
      viewEditAvailabileUnavailable: "Edit Availability, Unavailability",
    },
    {
      id: 3,
      providerName: "ram raj",
      email: "harish@mailinator.com",
      signupDate: "7th November, 2022",
      mobile: "(+91) 2538800866",
      providerNCA: "Good Guys Admin",
      viewEditDocument: "icon ni ni-file-doc",
      servcieURL: "/admin/provider/service",
      documentURL: "/admin/provider/document",
      manageServices: "icon ni ni-file-text",
      viewEditAvailabileUnavailable: "Edit Availability, Unavailability",
    },
    {
      id: 4,
      providerName: "swapnil khare",
      email: "swapnilk@codiant.com",
      signupDate: "17th November, 2022",
      mobile: "(+91) 9876543211",
      providerNCA: "Good Guys Admin",
      viewEditDocument: "icon ni ni-file-doc",
      servcieURL: "/admin/provider/service",
      documentURL: "/admin/provider/document",
      manageServices: "icon ni ni-file-text",
      viewEditAvailabileUnavailable: "edit",
    },
  ];
};
