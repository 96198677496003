import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AntTextArea,
  Breadcrumb,
  InputType,
  ListingHeader,
  PageHeader,
  TextEditor,
} from "../../../../../components";

function EditPage(props) {
  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/page')
  } 
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "/admin/page",
      name: "Pages",
    },
    {
      path: "#",
      name: "Edit Static Page",
    },
  ];
  return (
    <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Edit Static Page">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={["extraButton"]}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <form>
                <div className="row g-3">
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                        Page/Section 
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter page name"
                        defaultValue="404-ERROR"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Page Title (English)<span className="text-danger">*</span>
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter page title"
                        defaultValue="404-ERROR"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Page Description <span className="text-danger">*</span>
                      </label>
                      <TextEditor>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                      </TextEditor>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Meta Title
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter meta title"
                        defaultValue="404-ERROR"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Meta Keyword
                      </label>
                      <InputType
                        className="form-control"
                        placeholder="Enter meta keyword"
                        defaultValue="Error Page"
                        name="documentType"
                        disabled={false}
                        variant="standard"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label className="form-label">
                      Meta Description
                      </label>
                      <AntTextArea
                        className="form-control"
                        placeholder="Enter meta description"
                        defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Link href="#" className="btn btn-primary">
                      Update
                    </Link>
                    <Link href="#" className="btn btn-light ms-2">
                    Reset
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditPage;
