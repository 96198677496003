
import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AssignModuleFilter,
  Breadcrumb,
  FormElementWrapper,
  FormWrapper,
  ListingHeader,
  PageHeader,
  RadioComponent,
  Toggle,
} from '../../../../components';

function AssignModules(props) {
  const navigate = useNavigate();
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: 'Assign Modules',
    },
  ];
  const backPage = () => {
    navigate('/admin/roles');
  };
  const ViewEditData = [
    {
      label:'View',
      value: 'View',
    },
    {
      label: 'Edit',
      value: 'Edit',
    },
  ]
  const ViewData = [
    {
      label:'View',
      value: 'View',
    },
  ]
  return (
    <>
      <div className="wide-md mx-auto">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <PageHeader heading="Assign Modules">
              <Breadcrumb breadcrumb={breadcrumb} />
            </PageHeader>
            <ListingHeader
              btnArray={['extraButton']}
              extraBtnText="Back"
              extraBtnClass="btn btn-outline-light bg-white"
              onExtraButtonHandleShow={backPage}
            />
          </div>
        </div>
        <div className="card card-preview">
          <div className="card-inner">
            <div className="nk-block">
              <div className="serviceList assignModuleList">
                <h6 className="mb-3">
                  Assign Modules <span className="text-danger"> *</span>
                </h6>
                <div className="nk-data data-list mt-2">
                  <div className="data-head">
                    <h6 className="overline-title">Assign Modules</h6>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Dashboard</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='text-end'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Site Statistics</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Admin</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Provider</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Service Category</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Services</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Customers</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Manual Bookings</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Service Management</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Scheduled Bookings</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Promo Code</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Reviews</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Payment Report</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Service Provider Payment Report
                      </span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Geo Fence Location</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">General</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Email Templates</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Banner</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Country</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">State</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">City</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Pages</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Edit Home Page</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Faq</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Send Push-Notification</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Information</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Financing</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Tips</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Quote Information</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Notifications</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Requests</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Line Items</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Roles</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Store</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Manage Documents</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Business Admin</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">SMS Templates</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Upload Bulk Data</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Quote System</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Amount Calculation</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Notification Templates</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Financing Bank</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Financing Option</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">
                        Quote Order & Admin Profit
                      </span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Discount</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Rebate</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Supply House Location</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Optional Items</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Tags</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Industry</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">NCA</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Truck Stock Ordering</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">NCA Billing Report</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Home Inventory</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">New Equipment</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Equipment Order</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Google Service</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Google GMB</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Google Adwords</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Will Call List</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Operating Procedure</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                  <div className="data-item p-1">
                    <div className="data-col">
                      <span className="data-label">Chat</span>
                      <span className="data-value d-flex align-items-center">
                        <Toggle />
                        <FormWrapper extraClassName='ms-2'>
                          <FormElementWrapper
                            element={(formik) => (
                              <RadioComponent radioGroupArray={ViewEditData} name="Rolodex" defaultValue="View"/>
                            )}
                          />
                        </FormWrapper>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mt-3">
                <Link href="#" className="btn btn-primary">
                  Assign Module
                </Link>
                <Link href="#" className="btn btn-light ms-2">
                  Reset
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignModules;
