import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Breadcrumb,
  Charts,
  PageHeader,
} from "../../../components";

function ViewSiteStatistics(props) {


  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      
    },
    {
      path: "#",
      name: "Site Statistics",
    },
  ];
  const JobsData = {
    labels: ['Oct - 22', 'Nov - 22', 'Dec - 22'],
    datasets: [
      {
        label: 'Finished Jobs',
        data: [9, 22, 5],
        backgroundColor: [
          '#1EE0AC',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'Cancelled Jobs',
        data: [33, 30, 5],
        backgroundColor: [
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  const UsersData = {
    labels: ['Oct - 22', 'Nov - 22', 'Dec - 22'],
    datasets: [
      {
        label: 'Providers',
        data: [7, 19, 9],
        backgroundColor: [
          '#364A63',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
      {
        label: 'Users',
        data: [20, 13, 5],
        backgroundColor: [
          '#09C2DE',
        ],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const ProviderLogData = {
    labels: ['Ios Pro', 'Chris ios', 'Arpit Jai', 'Ron Test', 'Broke Nca1'],
    datasets: [
      {
        label: ' Provider Log Report Of Current Month (In Hours)',
        data: [170, 65, 65, 10, 10],
        backgroundColor: [
          '#09C2DE',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        maintainAspectRatio: false,
      },
    ],
    
  }
  const JobsCountData = {
    labels: ['Completed Jobs', 'Cancelled Jobs', 'On Going Jobs'],
    datasets: [
      {
        label: '# of Jobs Count Data',
        data: [70, 50, 30],
        backgroundColor: [
          '#1EE0AC',
          '#E85347',
          '#09C2DE',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 80,
      },
    ],
  };
  const ProvidersCountData = {
    labels: ['Active Providers', 'Pending Providers'],
    datasets: [
      {
        label: '# of Providers Count Data',
        data: [171, 121],
        backgroundColor: [
          '#1EE0AC',
          '#E85347',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        cutout: 80,
      },
    ],
  }

  const chartOptionsData = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  } 

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Site Statistics">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
        </div>
      </div>
      <div className="nk-block">
        <Row className="g-gs">
          <Col lg={6}>
            <div className="card card-full">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-briefcase"></em></span> Jobs For last 3 Months</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Charts type="bar" data={JobsData} options={chartOptionsData}/>
                  </div>
                </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card card-full">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user-check"></em></span>Registered Users For last 3 Months</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Charts type="bar" data={UsersData} options={chartOptionsData}/>
                  </div>
                </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card card-full">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-briefcase"></em></span>Jobs</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row>
                      <Col md={6}>
                        <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                          <Charts type="doughnut" data={JobsCountData} options={chartOptionsData}/>
                        </div>
                      </Col>
                      <Col md={6}>
                        <ul className="nk-store-statistics">
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">Jobs Count</div>
                              <div className="count">14406</div>
                            </div><em className="icon bg-purple-dim ni ni-calc"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">Today</div>
                              <div className="count">0</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">This Month</div>
                              <div className="count">9</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">This Year</div>
                              <div className="count">900</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                        </ul>
                        <p>* This is count for all Jobs (Finished, ongoing, cancelled.)</p>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card card-full">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-user"></em></span>Providers</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row>
                      <Col md={6}>
                        <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                          <Charts type="doughnut" data={ProvidersCountData} options={chartOptionsData}/>
                        </div>
                      </Col>
                      <Col md={6}>
                        <ul className="nk-store-statistics">
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">Providers Count</div>
                              <div className="count">288</div>
                            </div><em className="icon bg-purple-dim ni ni-calc"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">Today</div>
                              <div className="count">0</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">This Month</div>
                              <div className="count">9</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                          <li className="item pt-0">
                            <div className="info">
                              <div className="title">This Year</div>
                              <div className="count">174</div>
                            </div><em className="icon bg-purple-dim ni ni-calender-date"></em>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="card card-full">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h6 className="title d-flex align-items-center"> <span className="iconView me-2"><em className="icon bg-primary-dim ni ni-file-text"></em></span> Provider Log Report Of Current Month (In Hours)</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div style={{"maxHeight": "450px"}}>
                      <Charts type="bar" data={ProviderLogData} options={{ maintainAspectRatio: false,plugins: {
                          legend: {
                            position: 'bottom',
                            labels: {usePointStyle: true,pointStyle: 'circle'}
                          }
                        }}}/>
                    </div>
                  </div>
                </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ViewSiteStatistics;
