import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  Toggle,
  SweetAlert,
  ModalComponent,
  InputType,
  AntSelect,
  CommonButton,
  TablePlus,
} from "../../../../../components";
import { modalNotification } from "../../../../../utils";

function Banner(props) {
  const [isAlertVisible, setIsAlertVisible ] = useState(false);
  const onConfirmAlert = () => {
    modalNotification({ type: "success", message: "Delete Banner Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: "Banner",
    },
  ];
  const columns = [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "image",
      title: "Image",
      headerClasses: "sorting",
      render: (cell, row) => logoFormatter(row?.bannerimage,"Image"),
    },
    {
      dataIndex: "title",
      title: "Title",
      headerClasses: "sorting",
      sorter: true,
    },
    {
      dataIndex: "order",
      title: "Order",
      headerClasses: "sorting",
      render:(cell, row)=>{
        console.log("Details", cell)
        return <>
            <CommonButton className="btn btn-primary btn-sm"><em className="icon ni ni-arrow-long-up"></em></CommonButton>
            <CommonButton className="btn btn-primary btn-sm ms-1"><em className="icon ni ni-arrow-long-down"></em></CommonButton>
          </>
      }
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClasses: "sorting",
      render:()=><Toggle isDropDown/>,
      sorter: true,
    },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const Action = () => actionFormatter([
    {
      name: "Edit",
      icon: "icon ni ni-edit",
      handler: () =>{editbannerFilterShow(true)}
    },
    {
      name: "Delete",
      icon: "icon ni ni-trash",
      type:"redirect",
      handler:()=>{ setIsAlertVisible(true);
        document.body.click()}
    },
  ])
  const pageData = [
    {
      id: 1,
      bannerimage: "/assets/images/admin/uploadImg.png",
      title: "Have questions ask below",
      order: "up",
      status: "Active"
    },
    {
      id: 2,
      bannerimage: "/assets/images/admin/uploadImg.png",
      title: "Have questions ask below",
      order: "Up, Down",
      status: "Active"
    },
    {
      id: 3,
      bannerimage: "/assets/images/admin/uploadImg.png",
      title: "Have questions ask below",
      order: "up",
      status: "Active"
    },
  ];
  const [bannerFilter, setbannerFilter] = useState(false);
  const bannerFilterShow = () => {
    setbannerFilter(true);
  };
  const bannerFilterHide = () => {
    setbannerFilter(false);
  };
  const [editbannerFilter, seteditbannerFilter] = useState(false);
  const editbannerFilterShow = () => {
    seteditbannerFilter(true);
  };
  const editbannerFilterHide = () => {
    seteditbannerFilter(false);
  };
  const orderData = [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
  ]
  const statusData = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Inactive",
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Banner">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create"]}
            btnText="Add Banner"
            onHandleShow={bannerFilterShow}
          />
        </div>
      </div>
       <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        showAction={false}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={false}
      />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this banner"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={bannerFilter}
        onHandleCancel= {bannerFilterHide}
        title="Add Banner"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-12">
                <div className="form-group ">
                    <label className="form-label">
                    Image  <span className="text-danger"> *</span>
                    </label>
                    <div className="form-control-wrap">
                    <div className="form-file">
                        <input type="file" className="form-file-input" id="customFile" />
                        <label className="form-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <p className="form-note  mt-2"><b>[Note:  Recommended dimension for banner image is 2880 * 1620.]</b></p>
                </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                Title 
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter title"
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                Status
                </label>
                <Toggle />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                Order
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select order"
                  options={orderData}
                />
              </div>
            </div>
            
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => bannerFilterHide()}
                className="btn btn-primary me-2"
              >
                Add
              </Link>
              <Link
                href="#"
                onClick={() => bannerFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
      <ModalComponent
        backdrop
        show={editbannerFilter}
        onHandleCancel= {editbannerFilterHide}
        title="Edit Banner"
      >
        <form>
          <div className="row g-3">
            <div className="col-md-12">
                <div className="form-group ">
                    <label className="form-label">
                    Image  <span className="text-danger"> *</span>
                    </label>
                    <div className="form-control-wrap">
                    <div className="form-file">
                        <input type="file" className="form-file-input" id="customFile" />
                        <label className="form-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <p className="form-note  mt-2"><b>[Note:  Recommended dimension for banner image is 2880 * 1620.]</b></p>
                </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="form-label">
                Title <span className="text-danger">*</span>
                </label>
                <InputType
                  className="form-control"
                  placeholder="Enter title"
                  defaultValue="Have questions ask below"
                  name="documentType"
                  disabled={false}
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                Status
                </label>
                <Toggle />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label className="form-label">
                Order
                </label>
                <AntSelect
                  size="medium"
                  id="status"
                  extraClassName="form-control"
                  name="statusId"
                  disabled={false}
                  variant="standard"
                  placeholder="Select order"
                  options={orderData}
                  defaultValue="1"
                />
              </div>
            </div>
            <div className="col-md-12 text-end">
              <Link
                href="#"
                onClick={() => editbannerFilterHide()}
                className="btn btn-primary me-2"
              >
                Update
              </Link>
              <Link
                href="#"
                onClick={() => editbannerFilterHide()}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}

export default Banner;