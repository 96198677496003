import { Outlet } from 'react-router-dom';
import { EquipmentList, EquipmentOrderBookingLog, EquipmentViewQuote, MaterialList } from '../../views/EquipmentOrder';
import ListEquipmentOrder from '../../views/EquipmentOrder/List';




export default function route() {
  return [
    {
      path: accessRoute.EQUIPMENT_ORDER.path,
      private: true,
      name: 'Equipment Order',
      key: "equipmentOrder",
      belongsToSidebar: true,
      submenu: false,
      icon: accessRoute.EQUIPMENT_ORDER.icon,
      element: <Outlet />,
      children: [
        {
        private: true,
        path: accessRoute.EQUIPMENT_ORDER.path,
        name: 'Equipment Order',
        key: accessRoute.EQUIPMENT_ORDER.key,
        belongsToSidebar: false,
        icon: accessRoute.EQUIPMENT_ORDER.icon,
        element: <ListEquipmentOrder />,
      },
      {
        private: true,
        path: accessRoute.EQUIPMENT_LIST.path,
        name: 'Equipment List',
        key: accessRoute.EQUIPMENT_LIST.key,
        belongsToSidebar: false,
        icon: accessRoute.EQUIPMENT_LIST.icon,
        element: <EquipmentList />,
      },
      {
        private: true,
        path: accessRoute.MATERIAL_LIST.path,
        name: 'Material List',
        key: accessRoute.MATERIAL_LIST.key,
        belongsToSidebar: false,
        icon: accessRoute.MATERIAL_LIST.icon,
        element: <MaterialList />,
      },
      {
        private: true,
        path: accessRoute.BOOKING_LOG.path,
        name: 'Material List',
        key: accessRoute.BOOKING_LOG.key,
        belongsToSidebar: false,
        icon: accessRoute.BOOKING_LOG.icon,
        element: <EquipmentOrderBookingLog />,
      },
      {
        private: true,
        path: accessRoute.EQUIPMENT_VIEW_QUOTE.path,
        name: 'Equipment View Quote',
        key: accessRoute.EQUIPMENT_VIEW_QUOTE.key,
        belongsToSidebar: false,
        icon: accessRoute.EQUIPMENT_VIEW_QUOTE.icon,
        element: <EquipmentViewQuote />,
      },
    ]
    },
  ];
}

export const accessRoute = {
  EQUIPMENT_ORDER: {
    path: '/admin/equipment-order',
    key:"equipmentOrder.list",
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-money" />
      </span>
    ),
  },
  EQUIPMENT_LIST: {
    path: '/admin/equipment-order/equipment-list',
    key:"equipmentOrder.list",
  },
  MATERIAL_LIST: {
    path: '/admin/equipment-order/material-list',
    key:"equipmentOrder.list",
  },
  BOOKING_LOG: {
    path: '/admin/equipment-order/booking-log',
    key:"equipmentOrder.list",
  },
  EQUIPMENT_VIEW_QUOTE: {
    path: '/admin/equipment-order/view-quote',
    key:"equipmentOrder.quote",
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
