import React from 'react';
import { Select, Switch } from 'antd';

function Toggle({ onChange,  isDropDown = false,shouldDeleteInclude = false, }) {
  const getIncludeDeleteStatus = (isDelete = true) => {
    if (isDelete) {
      return [
        {
          label: 'Deleted',
          value: 'deleted',
        },
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Inactive',
          value: 'inactive',
        },
      ];
    }
    return [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ];
  };

  if (isDropDown) {
    return (
      <div className='tabelSelectOptions'>
        <Select
          defaultValue="active"
          options={getIncludeDeleteStatus(shouldDeleteInclude)}
        />
      </div>   
    );
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Switch defaultChecked onChange={onChange} />
    </div>
  );
}

export default Toggle;
