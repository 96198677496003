import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  DataTable,
  PageHeader,
  ListingHeader,
  ActionWrapper,
  SweetAlert,
  TablePlus,
} from "../../../../../components";
import { modalNotification } from "../../../../../utils";

function ViewImages(props) {
    const [isAlertVisible, setIsAlertVisible ] = useState(false);
  const onConfirmAlert = () => {
    modalNotification({ type: "success", message: "Image Delete Successfully", })
    return true;
  };
  function onAction(e, handler){
    e.preventDefault();
    handler()
  }
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e)=>onAction(e, item.handler)} key={key} className="action_list">
              <Link to={item.path}>
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
        path: "/admin/quote-management",
        name: "Quote Management",
      },
    {
      path: "#",
      name: "All Quote Images",
    },
  ];
  const columns = [
    {
      dataIndex: "id",
      title: "S.No.",
      headerClasses: "w_70",
    },
    {
      dataIndex: "image",
      title: "Image",
      sorter: true,
      render: (cell, row) => logoFormatter(row?.bannerimage,"Image"),
    },
    {
      dataIndex: "availability",
      title: "Availability",
      sorter: true,
      render: (cell, row) => statusFormatter(row?.color , row?.text),
    },
    {
      dataIndex: "imageName",
      title: "Image Name",
      sorter: true,
    },
    {
        dataIndex: "companyName",
        title: "Company Name",
        sorter: true,
      },
      {
        dataIndex: "quoteTypeName",
        title: "Quote Type Name",
        sorter: true,
      },
    {
      dataIndex: "action",
      title: "Action",
      className: "nk-tb-col-tools text-end",
      render: () => <Action />,
    },
  ];
  const logoFormatter = (path, text) => {
    return (
      <>
        {path ? (
          <div className="table-img">
            <img src={path} alt="img" />
          </div>
        ) : (
          text
        )}
      </>
    );
  };
  const statusFormatter = (color, text) => {
    return (
      <>
        <span className={`badge bg-${color} badge-circle align-items-center`}>{text}</span>
      </>
    );
  };
  const Action = () => actionFormatter([
    {
      name: "Delete",
      icon: "icon ni ni-trash",
      type:"redirect",
      handler:()=>{ setIsAlertVisible(true);
        document.body.click()}
    },
  ])
  const pageData = [
    {
      id: 1,
      bannerimage: "/assets/images/admin/line-items.jpg",
      color: 'danger',
      text: 'Assigned',
      imageName: "1_1605667483_49069.png",
      companyName: "NA",
      quoteTypeName: "NA"
    },
    {
      id: 2,
      bannerimage: "/assets/images/admin/line-items.jpg",
      color: 'success',
      text: 'Available',
      imageName: "	1_1605484894_35920.jpg",
      companyName: "Test Company",
      quoteTypeName: "90+% Furnace	"
    },
    {
      id: 3,
      bannerimage: "/assets/images/admin/line-items.jpg",
      color: 'success',
      text: 'Available',
      imageName: "1_1605485259_19628.jpg",
      companyName: "NA",
      quoteTypeName: "NA"
    },
  ];

  const navigate = useNavigate();
  const backPage = () => {
    navigate('/admin/quote-management')
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="All Quote Images">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      {/* <DataTable
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={pageData}
        tableColumns={columns}
        // selectRow
        // param={param}
        // defaultSort={defaultSort}
        setSizePerPage=""
        // tableLoader={tableLoader}
        // tableReset={tableReset}
        // getSearchValue={getSearchValue}
        // searchPlaceholder={t("text.search.manageCustomers")}
      /> */}
      <TablePlus
          hasLimit
          noOfPage="1"
          sizePerPage="10"
          page="1"
          count="100"
          tableData={pageData}
          tableColumns={columns}
          // tableLoader={isLoading}
          setSizePerPage=""
          showAction={false}
          isResetBtn= {false}
        />
      <SweetAlert
        title="Are you sure"
        text="you want to delete this Image"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        // showLoaderOnConfirm
        // loading={loading}
        onConfirmAlert={onConfirmAlert}
      />
    </>
  );
}

export default ViewImages;
