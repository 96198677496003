import React, {useState} from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
  CommonButton,
  InputFilter,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FilterFilled  } from "@ant-design/icons";
function UnsubscribeUserList(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/marketing-review',
      name: 'Marketing Review Template',
    },
    {
      path: '#',
      name: 'Unsubscribe user list',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/marketing-template/marketing-review')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: (props) => (<InputFilter dataIndex={dataIndex} {...props}/>),
    filterIcon: filtered => <FilterFilled   type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      // if (visible) {
      //   setTimeout(() => this.searchInput.select());
      // }
    },
  });

  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'date',
      title: 'Date',
    },
    {
        dataIndex: 'name',
        title: 'Name',
        ...getColumnSearchProps("name")
    },
    {
        dataIndex: 'typeOfAudience',
        title: 'Type of audience',
    },
    {
        dataIndex: 'action',
        title: 'Action',
        render:(cell, row)=>{
            return <> <CommonButton className="btn btn-primary btn-sm">Subscribe </CommonButton></>
        }
    },
  ];
  const adminData = [
    {
      id: 1,
      date: 'Mar 24,2023',
      name: 'Ronak',
      typeOfAudience: 'Customer',
    },
    {
      id: 2,
      date: 'Mar 24,2023',
      name: 'Ravi',
      typeOfAudience: 'Admin',
    },
    {
        id: 3,
        date: 'Mar 24,2023',
        name: 'Ronak',
        typeOfAudience: 'Provider',
      },
      {
        id: 4,
        date: 'Mar 24,2023',
        name: 'Jhon',
        typeOfAudience: 'Customer',
      },
      {
        id: 5,
        date: 'Mar 24,2023',
        name: 'Jitendra',
        typeOfAudience: 'Customer',
      },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Unsubscribe user list">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
        <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={adminData}
            tableColumns={columns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}  
            showAction={false}
            statusAction={false}
            isResetBtn={false}
        />
    </>
  );
}

export default UnsubscribeUserList;
