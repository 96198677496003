import React, {useState} from 'react';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  TextEditor,
  ModalComponent,
  TablePlus,
} from '../../../../../components';
import {  useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';

function ReviewTemplateReport(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/marketing-template/marketing-review',
      name: 'Marketing Review Template',
    },
    {
      path: '#',
      name: 'Message Report',
    },
  ];


  const navigate = useNavigate();
  const backPage = () =>{
    navigate('/admin/marketing-template/marketing-review')
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'audienceName',
      title: 'Audience Name ',
    },
    {
        dataIndex: 'messageStatus',
        title: 'Message status',
    },
    {
        dataIndex: 'clickPerformance',
        title: 'Click Performance',
    },
  ];
  const adminData = [
    {
      id: 1,
      audienceName: 'Cristy hopper',
      messageStatus: 'Pending',
      clickPerformance: '0 Clicks',
    },
    {
      id: 2,
      audienceName: 'Test Lname',
      messageStatus: 'Pending',
      clickPerformance: '0 Clicks',
    },
  ];
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Message Report">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={['extraButton']}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={backPage}
          />
        </div>
      </div>
      <div className='nk-block'>
        <div className="card shadow-none">
            <div className="card-inner">
                <div className='table-responsive'>
                  <Table bordered size="md">
                    <tbody>
                      <tr>
                        <td>Message Sent Date</td>
                        <td>	May 12, 2023</td>
                      </tr>
                      <tr>
                        <td>Approved Date</td>
                        <td>May 12, 2023</td>
                      </tr>
                      <tr>
                        <td>Audience Count</td>
                        <td>	2</td>
                      </tr>
                      <tr>
                        <td>Pending</td>
                        <td>	2 (100%)</td>
                      </tr>
                      <tr>
                        <td>Opened</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Bounced</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Unsubscribed</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Complained</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Clicked on Link	</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
            </div>
        </div>
        </div>
        <TablePlus
            hasLimit
            noOfPage="1"
            sizePerPage="10"
            page="1"
            count="100"
            tableData={adminData}
            tableColumns={columns}
            // tableLoader={isLoading}
            setSizePerPage=""
            selectRow={false}
            isSearch={false}  
            showAction={false}
            statusAction={false}
            isResetBtn={false}
        />
    </>
  );
}

export default ReviewTemplateReport;
