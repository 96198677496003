import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  TablePlus,
} from '../../../../components';

function SplitPaymentDetails(props) {
  const breadcrumb = [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/admin/payment-report',
      name: 'Payment Report',
    },
    {
      path: '#',
      name: 'Split Payment Details',
    },
  ];
  const columns = [
    {
      dataIndex: 'id',
      title: 'S.No.',
      headerClasses: 'w_70',
    },
    {
      dataIndex: 'jobNumber',
      title: 'Job No',
       sorter: true,
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
       sorter: true,
    },
    {
      dataIndex: 'customerName',
      title: 'Customer Name',
       sorter: true,
    },
    {
      dataIndex: 'paidBy',
      title: 'Paid By',
       sorter: true,
    },
    {
      dataIndex: 'jobTotalAmount',
      title: 'Job Total Amount',
       sorter: true,
    },
    {
      dataIndex: 'splitPayment',
      title: 'Split Payment',
       sorter: true,
    },
    {
      dataIndex: 'paymentMethod',
      title: 'Payment Method',
       sorter: true,
    },
    {
      dataIndex: 'bankName',
      title: 'Bank Name',
       sorter: true,
    },
    {
      dataIndex: 'CheckFinancingNo',
      title: 'Check or Financing No',
       sorter: true,
    },
    {
      dataIndex: 'jobPaymentStatus',
      title: 'Job Payment Status',
       sorter: true,
    },
  ];
  const adminData = [
    {
      id: 1,
      jobNumber: "75833147",
      providerName: "jorge neon",
      customerName: "Shane Watson",
      paidBy: "Provider",
      jobTotalAmount: "$5400.00",
      splitPayment: "$400.00",
      paymentMethod: "Card",
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus : 'Complete'
    },
    {
      id: 2,
      jobNumber: "75833147",
      providerName: "jorge neon",
      customerName: "Shane Watson",
      paidBy: "Provider",
      jobTotalAmount: "$5400.00",
      splitPayment: "$400.00",
      paymentMethod: "Card",
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus : 'Complete'
    },
  ];

  const navigate = useNavigate()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Split Payment Details">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["extraButton"]}
            extraBtnText="Back"
            extraBtnClass="btn btn-outline-light bg-white"
            onExtraButtonHandleShow={ () => navigate('/admin/payment-report')}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={adminData}
        tableColumns={columns}
        // tableLoader={isLoading}
        setSizePerPage=""
        selectRow={true}
      />
    </>
  );
}

export default SplitPaymentDetails;
