export const CategoryManagementService = () => {
    return [
      {
        id: 1,
        categoryName: 'Fashion'
      },
      {
        id: 2,
        categoryName: 'Electronics'
      },
      {
        id: 3,
        categoryName: 'Toys'
      },
      {
        id: 4,
        categoryName: 'Beauty'
      },
      {
        id: 5,
        categoryName: 'Food'
      },
    ];
  };
  