import React, { useState } from "react";
import {
  Breadcrumb,
  PageHeader,
  ListingHeader,
  SweetAlert,
  ActionWrapper,
  TablePlus,
  ModalComponent,
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  Select,
  DatePicker,
  AntTextArea,
} from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  Columns, couponManagementService,
} from "../../../../services/index.service";
import { modalNotification } from "../../../../utils";

function CouponManagementList(props) {
  const breadcrumb = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
    },
    {
      path: "#",
      name: " Coupon Management",
    },
  ];
  const discountCategory = [
    {
      name: 'Flat',
      value: 'Flat',
    },
    {
      name: 'Percentage',
      value: 'Percentage',
    },
  ]
  const validity = [
    {
      name: 'Permanent',
      value: 'Permanent',
    },
    {
      name: 'Custom',
      value: 'Custom',
    },
  ]

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [subCategoryService, setSubCategoryService] = useState(false);
  const [lineItems, setLineItems] = useState(false);
  const onConfirmAlert = () => {
    setIsAlertVisible(false);
    modalNotification({ type: "success", message: "Coupon Deleted Successfully", })
    return true;
  };
  function onAction(e, handler) {
    e.preventDefault();
    handler()
  }
  const handleMessageTypeChange = (value) => {
    if(value === 'Custom'){
        setSubCategoryService(true);
        setLineItems(false);
    }
    else {
        setLineItems(true);
        setSubCategoryService(false);
      }
  }
  const statusActionOption = [
    {
      id: 1,
      name: 'Select Action'
    },
    {
      id: 2,
      name: 'Make Active'
    },
    {
      id: 3,
      name: 'Make Inactive'
    },
    {
      id: 4,
      name: 'Make Delete'
    }
  ]


  const [addCouponModal, setAddCouponModal] = useState(false);
  const addCouponModalShow = () => {
    setAddCouponModal(true);
  };
  const addCouponModalHide = () => {
    setAddCouponModal(false);
  };

  const [editCouponModal, setEditCouponModal] = useState(false);
  const editCouponModalShow = () => {
    setEditCouponModal(true);
  };
  const editCouponModalHide = () => {
    setEditCouponModal(false);
  };
  const actionFormatter = (options) => {
    return (
      <ActionWrapper>
        {options.map((item, key) => {
          return (
            <li onClick={(e) => onAction(e, item.handler)} key={key} className="action_list">
              <Link to="#">
                <em className={item.icon} />
                {item.name}
              </Link>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.couponManagementTableColumn({
    Action: () =>
      actionFormatter([
        {
          name: "Edit",
          icon: "icon ni ni-edit",
          handler: () => { editCouponModalShow() },
        },
        {
          name: "Delete",
          icon: "icon ni ni-trash",
          handler: () => {
            setIsAlertVisible(true);
            document.body.click()
          }
        },
      ]),
  });

  const couponManagementData = couponManagementService();
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <PageHeader heading="Coupon Management">
            <Breadcrumb breadcrumb={breadcrumb} />
          </PageHeader>
          <ListingHeader
            btnArray={["create"]}
            btnText="Add Coupon"
            // popover={<CustomersFilterForm/>}
            onHandleShow={addCouponModalShow}
          />
        </div>
      </div>
      <TablePlus
        hasLimit
        noOfPage="1"
        sizePerPage="10"
        page="1"
        count="100"
        tableData={couponManagementData}
        tableColumns={columns}
        showAction={false}
        isSearch={true}
        // tableLoader={isLoading}
        setSizePerPage=""
      />
      <SweetAlert
        title="Are you sure"
        text=" you want to delete this Coupon"
        show={isAlertVisible}
        icon="warning"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        setIsAlertVisible={setIsAlertVisible}
        onConfirmAlert={onConfirmAlert}
      />
      <ModalComponent
        backdrop
        show={addCouponModal}
        onHandleCancel={addCouponModalHide}
        title="Add Coupon"
      >
        <FormWrapper row={true} extraClassName="g-3">

          <FormElementWrapper
            sm={12}
            label="Coupon code"
            link="Generate Coupon Code"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter Coupon code"
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description"
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description "
              />
            )}
          />
          <FormElementWrapper
            md={6}
            label="Discount type"
            element={(formik) => (
              <Select
                name="adminType"
                allowClear
                placeholder="Select Discount type"
                options={discountCategory}
                showSearch
                formik={formik}
              />
            )}
          />
          <FormElementWrapper
            sm={6}
            label="Discount"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter discount amount"
                formik={formik}
              />
            )}
          />
           <FormElementWrapper
            sm={6}
            label="Usage limit"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter usage limit"
                formik={formik}
              />
            )}
          />
           <FormElementWrapper
            md={6}
            label="Validity"
            element={(formik) => (
              <Select
                name="adminType"
                allowClear
                placeholder="Select Validity"
                options={validity}
                showSearch
                formik={formik}
                onChange={handleMessageTypeChange}
              />
            )}
          />
          {
              subCategoryService && 
              <>
              <FormElementWrapper
                md={6}
                label="Activation date"
                element={(formik) => (
                  <DatePicker
                    name="date"
                    className="form-control date-picker shadow-none"
                    placeholder="Select activation date"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Expiry date"
                element={(formik) => (
                  <DatePicker
                    name="date"
                    className="form-control date-picker shadow-none"
                    placeholder="Select expiry date"
                  />
                )}
              />
              </>
                }
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addCouponModalHide()}
                >
                  Add
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addCouponModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>

      <ModalComponent
        backdrop
        show={editCouponModal}
        onHandleCancel={editCouponModalHide}
        title="Edit Coupon"
      >
        <FormWrapper row={true} extraClassName="g-3">
        <FormElementWrapper
            sm={12}
            label="Coupon code"
            link="Generate Coupon Code"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter Coupon code"
                formik={formik}
                defaultValue='disk'
              />
            )}
          />
          <FormElementWrapper
            md={12}
            label="Description"
            mandatory={true}
            element={(formik) => (
              <AntTextArea
                name="description"
                className="form-control"
                placeholder="Enter description "
                defaultValue="For test disabled promo code"
              />
            )}
          />
      
           <FormElementWrapper
            md={6}
            label="Discount type"
            element={(formik) => (
              <Select
                name="adminType"
                allowClear
                placeholder="Select Discount type"
                options={discountCategory}
                showSearch
                formik={formik}
                defaultValue='Flat'
              />
            )}
          />
          <FormElementWrapper
            sm={6}
            label="Discount"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter discount amount"
                formik={formik}
                defaultValue='20%'
              />
            )}
          />
          <FormElementWrapper
            sm={6}
            label="Usage limit"
            element={(formik) => (
              <TextInput
                name="categoryName"
                placeholder="Enter usage limit"
                formik={formik}
                defaultValue='5'
              />
            )}
          />
           <FormElementWrapper
            md={6}
            label="Validity"
            element={(formik) => (
              <Select
                name="adminType"
                allowClear
                placeholder="Select Validity"
                options={validity}
                showSearch
                formik={formik}
                onChange={handleMessageTypeChange}
                defaultValue='Permanent'
              />
            )}
          />
          {
              subCategoryService && 
              <>
              <FormElementWrapper
                md={6}
                label="Activation date"
                element={(formik) => (
                  <DatePicker
                    name="date"
                    className="form-control date-picker shadow-none"
                    placeholder="Select activation date"
                  />
                )}
              />
              <FormElementWrapper
                md={6}
                label="Expiry date"
                element={(formik) => (
                  <DatePicker
                    name="date"
                    className="form-control date-picker shadow-none"
                    placeholder="Select expiry date"
                  />
                )}
              />
              </>
                }
          <FormElementWrapper
            md={12}
            className="text-end"
            element={() => (
              <>
                <CommonButton
                  type="submit"
                  className="btn btn-primary me-2"
                  onClick={() => addCouponModalHide()}
                >
                  Update
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-light me-2"
                >
                  Reset
                </CommonButton>
                <CommonButton
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => addCouponModalHide()}
                >
                  Cancel
                </CommonButton>
              </>
            )}
          />
        </FormWrapper>
      </ModalComponent>
    </>
  );
}

export default CouponManagementList;
