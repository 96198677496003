import React from 'react';
import ImageElement from '../ImageElement';

function NoDataFound({ source, text }) {
  return (
    <>
      <div className="emptySec text-center position-relative d-flex flex-column justify-content-center align-items-center">
        <ImageElement
          source={source}
          className="img-fluid svg"
          alt="no-messages"
        />
        <h4 className="text-400 emptySec_text">{text}</h4>
      </div>
    </>
  );
}

export default NoDataFound;